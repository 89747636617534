// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .title{position:relative;padding:0}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .title.active{border:none}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .content.active{border-bottom:solid 1px #c7c7c7}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .content:last-child{border:none}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .title i.icon{position:absolute;top:50%;transform:translateY(-50%);right:0}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion .active.title i.icon{-webkit-transform:rotate(-180deg);transform:rotate(-180deg)}._1hZuNaoWEKBpDrzmgU-Fve.ui.accordion:not(.styled) .title ~ .content:not(.ui){padding:0}._3hgeN13Lhyj_8KGJczG6O8{border-bottom:solid 1px #c7c7c7}._3hgeN13Lhyj_8KGJczG6O8.MIj1n9v0fwtufNLl1GnD3{border-bottom:none}", ""]);
// Exports
exports.locals = {
	"accordion": "_1hZuNaoWEKBpDrzmgU-Fve",
	"title": "_3hgeN13Lhyj_8KGJczG6O8",
	"noBoarder": "MIj1n9v0fwtufNLl1GnD3"
};
module.exports = exports;
