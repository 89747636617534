import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import appReducer from './redux/reducers';
import { IAppState } from './types/';
import gaLogger from './middleware/ga-logger';
import sideEffectMiddleware from './middleware/sideEffects';

export default function configureStore(preloadedState: IAppState) {
  const middlewares = [thunkMiddleware, gaLogger, sideEffectMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const composedEnhancers = composeWithDevTools(middlewareEnhancer);

  const store = createStore(appReducer, preloadedState, composedEnhancers);

  return store;
}
