// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3ct0bARBq2Ua6A9nMuGyNn{padding:16px 24px !important;min-height:0;display:flex;align-items:center;justify-content:center}.p985vP5SBVtHxWLKxH_LJ{background-color:#ff5353}.p985vP5SBVtHxWLKxH_LJ .Toastify__close-button{position:absolute;top:8px;right:8px;color:#fff}.p985vP5SBVtHxWLKxH_LJ .Toastify__toast-body{color:#fff}._1HgaZhODDSN-Qip5ZZ36nm{padding:8px 16px;border-radius:5px;background-color:rgba(146,189,240,.5);max-width:460px}._1HgaZhODDSN-Qip5ZZ36nm i.icon{cursor:pointer;position:absolute;font-size:18px;top:16px;right:10px}._27cg8k8_H2B6Cj1yZ943iB{font-family:\"Open Sans\";font-weight:700;font-size:14px}.i5mdUxrdl81UcOlXa43dS{font-family:\"Open Sans\";font-weight:400;font-size:14px}", ""]);
// Exports
exports.locals = {
	"baseContainer": "_3ct0bARBq2Ua6A9nMuGyNn",
	"error": "p985vP5SBVtHxWLKxH_LJ _3ct0bARBq2Ua6A9nMuGyNn",
	"container": "_1HgaZhODDSN-Qip5ZZ36nm",
	"title": "_27cg8k8_H2B6Cj1yZ943iB",
	"text": "i5mdUxrdl81UcOlXa43dS"
};
module.exports = exports;
