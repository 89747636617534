import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { IAppState, ICreatePolicyProps, IPolicy, IUser } from 'types';
import { AdviserProfileForm, UserProfileForm } from 'components/forms';
import Spinner from 'components/spinner';
import Q4E from 'components/q4e';
import {
  UpdateUser,
  GetInvitationsForFamilyId,
  CreatePolicies,
  UpdatePolicy,
  DeletePolicies,
  GetUserPolicies,
  UpdateUserProfilePhoto,
  ToggleAdviserReferral,
} from 'actions';
import styles from './user.scss';
import {
  findEmployerById,
  getEmployerPolicies,
  logPageView,
  PAGE_VIEW_EVENTS,
} from 'helpers';
import { useAuth0 } from '../../react-auth0-spa';
import { UserRoles } from 'constants/user';
import { PROFILE_ROUTE, WORKPLACE_ROUTE } from 'constants/routes';
import { IFile } from 'services';

interface IDispatchProps {
  createPolicies(policies: ICreatePolicyProps[]): void;
  deletePolicies(ids: string[]): void;
  getInvitationsForFamilyId(familyId: string): (dispatch: any) => void;
  getUserPolicies(): void;
  toggleAdviserReferral(open: boolean): void;
  updatePolicy(policy: IPolicy): void;
  updateProfilePhoto(file: IFile): void;
  updateUser(user: IUser): (dispatch: any) => void;
}

const UserContainer = ({
  config,
  invitation,
  policies: { policies },
  referenceData,
  user,
  createPolicies,
  deletePolicies,
  getInvitationsForFamilyId,
  getUserPolicies,
  toggleAdviserReferral,
  updatePolicy,
  updateUser,
  updateProfilePhoto,
}: IAppState & IDispatchProps) => {
  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.User);
  }, []);

  const auth0 = useAuth0();

  const isAdviser = auth0.hasRole(UserRoles.ADVISER);

  const deleteEmployerPolicies = useCallback(() => {
    const employer = findEmployerById(referenceData.employers, user.employerId);
    const employerPolicies = getEmployerPolicies(policies, employer);
    const ids = R.pluck('id', employerPolicies);
    if (ids.length) {
      return deletePolicies(ids);
    }
  }, [referenceData.employers, policies, user.employerId]);

  return user.id ? (
    <Switch>
      <Route exact={true} path={PROFILE_ROUTE}>
        {isAdviser ? (
          <AdviserProfileForm
            user={user}
            auth0={auth0}
            updateUser={updateUser}
            updateProfilePhoto={updateProfilePhoto}
          />
        ) : (
          <UserProfileForm
            user={user}
            config={config}
            invitation={invitation}
            auth0={auth0}
            employers={referenceData.employers}
            deletePolicies={deleteEmployerPolicies}
            getInvitationsForFamilyId={getInvitationsForFamilyId}
            toggleAdviserReferral={toggleAdviserReferral}
            updateUser={updateUser}
            updateProfilePhoto={updateProfilePhoto}
          />
        )}
      </Route>
      <Route path={WORKPLACE_ROUTE}>
        <Q4E
          policyProviders={referenceData.policyProviders}
          user={user}
          employers={referenceData.employers}
          policies={policies}
          createPolicies={createPolicies}
          deletePolicies={deleteEmployerPolicies}
          updatePolicy={updatePolicy}
          updateUser={updateUser}
        />
      </Route>
    </Switch>
  ) : (
    <Spinner className={styles.loading} loading={true} />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = {
  createPolicies: CreatePolicies,
  deletePolicies: DeletePolicies,
  getInvitationsForFamilyId: GetInvitationsForFamilyId,
  getUserPolicies: GetUserPolicies,
  toggleAdviserReferral: ToggleAdviserReferral,
  updatePolicy: UpdatePolicy,
  updateUser: UpdateUser,
  updateProfilePhoto: UpdateUserProfilePhoto,
};

export default connect<IAppState, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UserContainer);
