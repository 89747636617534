import {
  AppActionTypes,
  UpdateUserLoaded,
  GetNotifications,
  DeletePolicyNotificationsLoaded,
} from '../redux/actions';
import {
  GetPolicyScanByUserId,
  GetUserPolicies,
  Types as PolicyTypes,
} from '../redux/actions/policy';
import { Types as AdviserTypes } from '../redux/actions/adviser-actions';
import { Types as InvitationTypes } from '../redux/actions/invitation-actions';
import { PolicyStatus } from 'constants/policy';
import { IPolicy, IPolicyScan, IUser, POLICY_SCAN_STATUS } from 'types';

let policyScanTimeout = {};

const createPolicyScanSideEffect = (
  dispatch,
  user: IUser,
  policyScan: IPolicyScan,
) => {
  dispatch(GetNotifications());
  if (!user.hasPolicyScan) {
    dispatch(UpdateUserLoaded(R.assoc('hasPolicyScan', true, user)));
  }

  // Fetch Market Scan and notifications every minute to get automated results for up to 5 minutes
  const refreshMS = setInterval(() => {
    // Refresh policies again to get updated status
    dispatch(GetUserPolicies(user.currentFamilyId));
    dispatch(GetPolicyScanByUserId(user.id));
    dispatch(GetNotifications());
  }, 30000);

  setTimeout(() => {
    clearInterval(refreshMS);
  }, 301000);

  policyScanTimeout = R.map((intervalId) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    return null;
  }, policyScanTimeout);

  // Set in global object so we can clear interval
  // when we receive policy scans that are READY
  policyScanTimeout[policyScan.id] = refreshMS;
};

const getPolicyScansSideEffect = (policyScans: IPolicyScan[]) => {
  policyScans.forEach((ps: IPolicyScan) => {
    if (ps.status === POLICY_SCAN_STATUS.READY && policyScanTimeout[ps.id]) {
      clearInterval(policyScanTimeout[ps.id]);
      policyScanTimeout = R.dissoc(ps.id, policyScanTimeout);
    }
  });
};

const getAdviceSideEffect = (dispatch, user: IUser) => {
  if (!user.hasGetAdvice) {
    dispatch(UpdateUserLoaded(R.assoc('hasGetAdvice', true, user)));
  }
};

const createPolicySideEffect = (dispatch, user: IUser, policy: IPolicy) => {
  if (policy.status !== PolicyStatus.MARKET_SCAN_DRAFT) {
    dispatch(GetNotifications());
  }
  if (!user.hasPolicy) {
    dispatch(UpdateUserLoaded(R.assoc('hasPolicy', true, user)));
  }
};

const createInvitationSideEffect = (dispatch) => {
  dispatch(GetNotifications());
};

const deletePolicySideEffect = (dispatch, id: string) => {
  dispatch(DeletePolicyNotificationsLoaded(id));
};

const sideEffectMiddleware = (store) => (next) => (action: AppActionTypes) => {
  const { user } = store.getState();

  switch (action.type) {
    case AdviserTypes.CREATE_ADVISER_CONNECT:
      getAdviceSideEffect(store.dispatch, user);
      break;
    case PolicyTypes.CREATE_POLICY_BUNDLE_LOADED:
    case PolicyTypes.CREATE_POLICY_LOADED:
      createPolicySideEffect(store.dispatch, user, action.data);
      break;
    case PolicyTypes.CREATE_POLICY_SCAN_LOADED:
      createPolicyScanSideEffect(store.dispatch, user, action.data);
      break;
    case PolicyTypes.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED:
      createPolicyScanSideEffect(store.dispatch, user, action.data);
      break;
    case PolicyTypes.GET_POLICY_SCAN_LOADED:
      getPolicyScansSideEffect(action.data);
      break;
    case InvitationTypes.CREATE_INVITATION_LOADED:
      createInvitationSideEffect(store.dispatch);
      break;
    case PolicyTypes.DELETE_POLICY_LOADED:
      deletePolicySideEffect(store.dispatch, action.data);
      break;
    default:
      break;
  }

  return next(action);
};

export default sideEffectMiddleware;
