import React from "react";
import DropdownBordered from "./DropdownBordered";
import DatePickerBordered from "./DatePickerBordered";
import InputBordered from "./InputBordered";
import InputMultiple from './InputMultiple';

const Field = (props) => {
  switch (props.type) {
    case 'dropdown':
      return <DropdownBordered {...props} />;
    case 'date':
      return <DatePickerBordered {...props} />;
    default:
      return props.multiple
        ? <InputMultiple {...props} />
        : <InputBordered {...props} />;
  }
};

Field.defaultProps = {
  value: ""
};

export default Field;
