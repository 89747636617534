import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Dimmer, Icon, Image, Loader, Modal } from 'semantic-ui-react';
import styles from './docViewer.scss';
import PDFViewer from './PDFViewer';

interface IDocViewerProps {
  src: string;
  onClose: () => void;
}

const ImgWrapper = ({ src, resetTransform }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    resetTransform();
    setLoading(false);
  };

  return (
    <>
      <Dimmer active={loading} inverted={true}>
        <Loader inverted={true} />
      </Dimmer>
      <Image
        className={classnames(styles.imgWrap, loading && styles.hide)}
        src={src}
        onLoad={handleLoad}
      />
    </>
  );
};

const ImgViewer = ({ src }) => (
  <Modal.Content>
    <TransformWrapper maxScale={5} centerOnInit={true}>
      {({ zoomIn, zoomOut, resetTransform, state }) => (
        <>
          <div className={classnames(styles.pageControls, styles.img)}>
            <button
              type="button"
              disabled={state.scale <= 1}
              onClick={() => zoomOut(0.5)}
            >
              <Icon name="zoom-out" size="large" />
            </button>
            <button type="button" onClick={() => resetTransform()}>
              <Icon name="refresh" size="large" />
            </button>
            <button
              type="button"
              disabled={state.scale >= 5}
              onClick={() => zoomIn(0.5)}
            >
              <Icon name="zoom-in" size="large" />
            </button>
          </div>
          <TransformComponent wrapperClass={styles.wrapper}>
            <ImgWrapper src={src} resetTransform={resetTransform} />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  </Modal.Content>
);

const DocViewer = ({ src, onClose }: IDocViewerProps) => {
  if (!src) {
    return null;
  }

  const srcUrl = new URL(src);
  const isPdf = /.+\.pdf$/i.test(srcUrl.pathname);
  const Component = isPdf ? PDFViewer : ImgViewer;

  return (
    <Modal
      className={styles.modal}
      dimmer={true}
      onClose={onClose}
      open={!!src}
    >
      <Component src={src} />
    </Modal>
  );
};

export default React.memo(DocViewer);
