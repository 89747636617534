import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './styles/ShoppingCart.module.scss';
import CartSvg from '-!svg-react-loader!icons/shared/cart.svg';
import {
  selectShoppingCartItems,
  selectShowShoppingCart,
} from 'selectors/adviserConnectSelectors';
import { CHECKOUT_ROUTE, PREMIUM_ROUTE } from 'constants/routes';

const ShoppingCart = () => {
  const addedToCart = useSelector(selectShoppingCartItems);
  const show = useSelector(selectShowShoppingCart);
  const hasCart = addedToCart.length > 0;

  return show ? (
    <Link
      to={hasCart ? CHECKOUT_ROUTE : PREMIUM_ROUTE}
      className={styles.trigger}
    >
      <CartSvg className={styles.cart} />
      <div className={cx(styles.dot, hasCart && styles.green)} />
    </Link>
  ) : null;
};

export default ShoppingCart;
