// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3kagFwh_vq_9l1C9gHvVXi.ui.button{font-family:\"Open Sans\";font-weight:400;background-color:#fff;border:solid 1px #44444f;color:#44444f;font-size:16px;overflow:hidden}._3kagFwh_vq_9l1C9gHvVXi.ui.button:hover{opacity:.9}._3kagFwh_vq_9l1C9gHvVXi.ui.labeled.icon.button > .icon{background-color:#000;color:#fff}._3kagFwh_vq_9l1C9gHvVXi.ui.labeled.icon.right.button > .icon{border-radius:0}._1fPIAHjB92FRt3ghWY3W-U{font-family:\"Open Sans\";font-weight:400;font-size:18px;height:125px;border:solid 1px #ebedf3;border-radius:5px;text-align:center;position:relative;cursor:pointer;user-select:none;display:inline-block;vertical-align:top;background-color:#fff}@media(min-width: 992px){._1fPIAHjB92FRt3ghWY3W-U{width:375px}}._1fPIAHjB92FRt3ghWY3W-U:hover{border:1px solid #34363e}._181U4rkjCUkqo-aKdYzGaj{margin:0 auto 10px}._2IsthIhvAvnTSDJYpsZVJF{height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column}._3DeuZPm7MPrEtfm0-3fa_b{position:absolute;right:0;bottom:10px}.KdfZyXunw0ZszlXYvJaIT.ui.popup{background-color:#f5f5f5;color:#000}._3G3xbo_drS1vpGXBL82Xol{font-family:\"Open Sans\";font-weight:400;border-radius:10px;position:absolute;left:50%;transform:translateX(-50%);top:-12px;white-space:nowrap;font-size:12px;color:#fff;background-color:#34363e;padding-left:40px;padding-right:40px;line-height:20px}._1pTlDVHn_ocLwqNdkwsPqM{margin-top:10px}", ""]);
// Exports
exports.locals = {
	"button": "_3kagFwh_vq_9l1C9gHvVXi",
	"cardButton": "_1fPIAHjB92FRt3ghWY3W-U",
	"image": "_181U4rkjCUkqo-aKdYzGaj",
	"content": "_2IsthIhvAvnTSDJYpsZVJF",
	"cardToggle": "_3DeuZPm7MPrEtfm0-3fa_b",
	"cardPopup": "KdfZyXunw0ZszlXYvJaIT",
	"label": "_3G3xbo_drS1vpGXBL82Xol",
	"subtext": "_1pTlDVHn_ocLwqNdkwsPqM"
};
module.exports = exports;
