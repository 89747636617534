import React from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import moment from 'moment';
import {
  AdviserTermsModal,
  AdviserOnboardingModal,
} from 'components/adviser-modals';
import { OnboardingWalkthrough } from 'components/onboarding-walkthrough';
import Spinner from 'components/spinner';
import {
  ActivateSubscription,
  CreateAdviserReferral,
  GetUserByEmail,
  ToggleAdviserReferral,
  UpdateUser,
  UpdateUserOnboardingStep,
} from 'actions';
import { IAppState, IReferralAdviser, IUser } from 'types';
import { WORKPLACE_ROUTE } from 'constants/routes';
import { SubscriptionStatus, UserRoles } from 'constants/user';
import { useAuth0 } from '../../react-auth0-spa';
import { getConfigText } from 'helpers';
import { ReferAdviser } from 'components/referral-modal';
import { OnHoliday } from 'components/on-holiday';
import Loading from 'components/loading';
import DetailsRequiredModal from 'components/details-required-modal';
import MarketScanConfirmPurchase from 'components/market-scan-confirm-purchase';
import ShoppingCartPopup from 'components/shopping-cart/ShoppingCartPopup';

interface IDispatchProps {
  activateSubscription(): void;
  createAdviserReferral(referral: IReferralAdviser): void;
  getUserByEmail(email: string): void;
  toggleAdviserReferral(open: boolean): void;
  updateUserOnboardingStep(step: number): void;
  updateUser(user: IUser): (dispatch: any) => void;
}

const shouldShowOnboarding = (user, role) => {
  // Force users to enter email when they dont have any
  return (
    (user.loaded && !user.email) ||
    (role === UserRoles.USER &&
      !user.walkthroughCompleted &&
      !user.onboardingStep &&
      // Workplace route has its own popup when user lands there
      window.location.pathname !== WORKPLACE_ROUTE)
  );
};

const shouldShowDetailsRequired = (user: IUser) =>
  user.loaded && (!user.firstName || !user.lastName);

const shouldShowOnHoliday = (
  user: IUser,
  start: string = '2021-11-23',
  end: string = '2022-01-05',
) => {
  const currentDate = moment();
  return (
    user.walkthroughCompleted &&
    !user.onHolidayCompleted &&
    currentDate.isSameOrAfter(start) &&
    currentDate.isSameOrBefore(end)
  );
};

// Lazy load payment modal so it doesnt load
// stripe package when component is not used
const AdviserPaymentModal = Loadable({
  loader: () => import('components/adviser-modals/AdviserPaymentModal'),
  loading: Loading,
});

export const RootModalContainer = ({
  config,
  payment,
  referral: { referrals, showAdviserReferral },
  user,
  activateSubscription,
  createAdviserReferral,
  getUserByEmail,
  toggleAdviserReferral,
  updateUserOnboardingStep,
  updateUser,
}: IAppState & IDispatchProps) => {
  const { getRole } = useAuth0() as any;
  const role = getRole();

  // Only show onboarding on dashboard route
  const showOnboarding = shouldShowOnboarding(user, role);
  const showDetailsRequired = shouldShowDetailsRequired(user);
  const showOnHoliday = shouldShowOnHoliday(
    user,
    config?.settings.features.holidayStart,
    config?.settings.features.holidayEnd,
  );

  const isAdviser = !!user.id && role === UserRoles.ADVISER;
  const openTerms = isAdviser && !user.q4aTermsCompleted;
  const openPayment =
    isAdviser &&
    !openTerms &&
    user.subscriptionStatus !== SubscriptionStatus.ACTIVE;
  const openQ4AOnboarding =
    isAdviser &&
    user.subscriptionStatus === SubscriptionStatus.ACTIVE &&
    !user.q4aOnboardingCompleted;

  const handleTermsSubmit = () =>
    updateUser(R.assoc('q4aTermsCompleted', true, user));
  const handleOnboardingClose = () => updateUserOnboardingStep(0);

  const handleOnHolidayClose = () =>
    updateUser(R.assoc('onHolidayCompleted', true, user));

  if (!user.id && role === UserRoles.ADVISER) {
    return <Spinner loading={true} />;
  }

  // Only mount adviser modals for advisers
  if (isAdviser) {
    return (
      <>
        <AdviserTermsModal open={openTerms} onSubmit={handleTermsSubmit} />
        <AdviserPaymentModal
          open={openPayment}
          payment={payment}
          text={getConfigText(config, 'adviserPaymentModal')}
          user={user}
          activateSubscription={activateSubscription}
        />
        <AdviserOnboardingModal
          open={openQ4AOnboarding}
          user={user}
          updateUser={updateUser}
        />
      </>
    );
  }

  return (
    <>
      <ReferAdviser
        open={showAdviserReferral}
        referrals={referrals}
        user={user}
        createAdviserReferral={createAdviserReferral}
        toggleAdviserReferral={toggleAdviserReferral}
      />
      {/* <OnboardingWalkthrough
        show={showOnboarding}
        onClose={handleOnboardingClose}
        user={user}
        getUserByEmail={getUserByEmail}
        updateUser={updateUser}
      /> */}
      <OnHoliday
        show={!showOnboarding && showOnHoliday}
        onClose={handleOnHolidayClose}
      />
      <DetailsRequiredModal
        show={showDetailsRequired}
        user={user}
        updateUser={updateUser}
      />
      <MarketScanConfirmPurchase />
      <ShoppingCartPopup />
    </>
  );
};

const mapStateToProps = (state: IAppState) => ({
  ...state,
});

const mapDispatchToProps = {
  activateSubscription: ActivateSubscription,
  createAdviserReferral: CreateAdviserReferral,
  toggleAdviserReferral: ToggleAdviserReferral,
  updateUser: UpdateUser,
  updateUserOnboardingStep: UpdateUserOnboardingStep,
  getUserByEmail: GetUserByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootModalContainer);
