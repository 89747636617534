// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1eJXkBjg3gQCbFzmxCDzIc{font-family:\"DM Sans\";font-weight:700;font-size:12px;line-height:15px;padding:0 8px;background-color:#7ba770;border-radius:2px;color:#fff;vertical-align:middle}", ""]);
// Exports
exports.locals = {
	"new": "_1eJXkBjg3gQCbFzmxCDzIc"
};
module.exports = exports;
