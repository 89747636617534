import React, { useEffect, useState } from 'react';
import { IFormDefinition } from 'types';
import Spinner from 'components/spinner';
import FormEngine, { IFormEngineProps } from './FormEngine';
import { setFormDefinition } from './utils';
import styles from './FormEngine.module.scss';

interface IProps extends Omit<IFormEngineProps, 'formDefinition'> {
  formId: string;
}

const FormEngineContainer = ({ formId, ...props }: IProps) => {
  const [formDefinition, setFormDef] = useState<IFormDefinition>(null);

  useEffect(() => {
    if (formId) {
      setFormDefinition(formId, setFormDef);
    }
  }, [formId]);

  if (!formDefinition) {
    return (
      <div className={classnames(styles.loader, props.className)}>
        <Spinner loading={true} />
      </div>
    );
  }

  return <FormEngine {...props} formDefinition={formDefinition} />;
};

export default FormEngineContainer;
