import React, { useCallback, useState } from 'react';
import FormField from '../../form-field';
import usePager from 'hooks/usePager';
import styles from './styles/income.scss';
import GuyCalculator from '-!svg-react-loader!images/people/guy-calculator.svg';
import { IUser } from 'types';
import { convertFrequency, prettifyNumber } from 'helpers';
import { FREQUENCY } from 'constants/options';

interface IProps {
  user: IUser;
  updateUser(user: IUser): void;
}

const Page1 = ({ next }) => (
  <div className={styles.page}>
    <div className={styles.left}>
      <GuyCalculator />
      <div className={styles.title}>
        Find out how much of your income you can protect with Income Protection
        insurance
      </div>
    </div>
    <button className={styles.whiteBtn} onClick={next}>
      Start
    </button>
  </div>
);

const Page2 = ({ income, calculate, onChange }) => (
  <div className={styles.page}>
    <div className={styles.left}>
      <div className={styles.bold}>What is your current monthly income?</div>
      <div className={styles.form}>
        <FormField
          name="income"
          className={classnames(styles.field)}
          labelClassName={styles.label}
          inputClassName={styles.input}
          onChange={onChange}
          value={income}
          prefix="$"
          inputType="number"
        />
        <div className={styles.bold}>Per Month</div>
      </div>
    </div>
    <button
      className={classnames(styles.whiteBtn)}
      disabled={!income}
      onClick={calculate}
    >
      Calculate
    </button>
  </div>
);

const Page3 = ({ income, reset }) => (
  <div className={styles.page}>
    <div className={styles.left}>
      <div className={styles.bold}>
        Your monthly income could be protected up to 75%
      </div>
      <div className={styles.bigNumber}>
        {`$${prettifyNumber(income * 0.75)} `}
        <span>Per Month</span>
      </div>
    </div>
    <button className={styles.blueBtn} onClick={reset}>
      Redo
    </button>
  </div>
);

const pageMap = {
  0: Page1,
  1: Page2,
  2: Page3,
};

const getDefaultIncome = ({
  income,
  incomeFrequency = FREQUENCY.YEARLY,
}: IUser) => {
  // Divide yearly income to get monthly
  return income
    ? convertFrequency(income, incomeFrequency, FREQUENCY.MONTHLY)
    : income;
};

const IncomeCalculator = ({ user, updateUser }: IProps) => {
  const { active, next, setActive } = usePager(0, true);
  const Page = pageMap[active];
  const [income, setIncome] = useState(getDefaultIncome(user));

  const handleInputChange = useCallback((e) => setIncome(e.target.value), []);
  const handleReset = useCallback(() => setActive(1), []);
  const handleCalculate = useCallback(() => {
    updateUser({
      ...user,
      income,
      // This question always asks for monthly frequency
      incomeFrequency: FREQUENCY.MONTHLY,
    });
    next();
  }, [income, user, next, updateUser]);

  return (
    Page && (
      <Page
        next={next}
        income={income}
        onChange={handleInputChange}
        reset={handleReset}
        calculate={handleCalculate}
      />
    )
  );
};

export default IncomeCalculator;
