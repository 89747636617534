import { Action } from 'redux';
import { IInvitation, IInvitationError, IUser } from '../../types/index';
import { GetUserByIdLoaded } from './user';

import {
  createInvitation,
  deleteInvitationById,
  getInvitationsByFamilyId,
  processInvitation,
  getUserById,
  getInvitationsByUserId,
  updateInvitation,
} from '../../services';
import {
  trackSharedInvitationCreated,
  trackSharedInvitationProcessed,
} from 'helpers';

export enum Types {
  INVITATION_LOADING = 'INVITATION_LOADING',
  GET_INVITATIONS_LOADED = 'GET_INVITATIONS_LOADED',
  CREATE_INVITATION_LOADED = 'CREATE_INVITATION_LOADED',
  PROCESS_INVITATION_LOADED = 'PROCESS_INVITATION_LOADED',
  DELETE_INVITATION_LOADED = 'DELETE_INVITATION_LOADED',
  SHOW_CREATE_INVITATION_FORM = 'SHOW_CREATE_INVITATION_FORM',
  INVITATION_FAILED = 'INVITATION_FAILED',
  CLEAR_INVITATION_ERRORS = 'CLEAR_INVITATION_ERRORS',
  GET_SHARED_INVITATIONS_LOADED = 'GET_SHARED_INVITATIONS_LOADED',
  UPDATE_INVITATION_LOADED = 'UPDATE_INVITATION_LOADED',
}

export interface IShowCreateInvitationForm extends Action {
  type: Types.SHOW_CREATE_INVITATION_FORM;
  data: boolean;
}

export const ShowCreateInvitationForm = (data: boolean) =>
  ({
    type: Types.SHOW_CREATE_INVITATION_FORM,
    data,
  } as IShowCreateInvitationForm);

export interface IInvitationLoading extends Action {
  type: Types.INVITATION_LOADING;
}

export const InvitationLoading = () =>
  ({
    type: Types.INVITATION_LOADING,
  } as IInvitationLoading);

export interface IGetInvitationsLoaded extends Action {
  type: Types.GET_INVITATIONS_LOADED;
  data: IInvitation[];
}

export interface IGetSharedInvitationsLoaded extends Action {
  type: Types.GET_SHARED_INVITATIONS_LOADED;
  data: IInvitation[];
}

export const GetInvitationsLoaded = (data: IInvitation[]) =>
  ({
    type: Types.GET_INVITATIONS_LOADED,
    data,
  } as IGetInvitationsLoaded);

export const GetSharedInvitationsLoaded = (data: IInvitation[]) =>
  ({
    type: Types.GET_SHARED_INVITATIONS_LOADED,
    data,
  } as IGetSharedInvitationsLoaded);

export const GetInvitationsForFamilyId = (familyId: string) => (dispatch) => {
  dispatch(InvitationLoading());
  getInvitationsByFamilyId(familyId).then((result) => {
    const data = result.data;
    dispatch(GetInvitationsLoaded(data.Items as IInvitation[]));
  });
};

export const GetInvitationsByUserId = (userId: string) => (dispatch) => {
  dispatch(InvitationLoading());
  getInvitationsByUserId(userId).then((result) => {
    const data = result.data;
    dispatch(GetSharedInvitationsLoaded(data.Items as IInvitation[]));
  });
};

export interface ICreateInvitationLoaded extends Action {
  type: Types.CREATE_INVITATION_LOADED;
  data: IInvitation;
}

export interface IUpdateInvitationLoaded extends Action {
  type: Types.UPDATE_INVITATION_LOADED;
  data: IInvitation;
}

export const CreateInvitationLoaded = (data: IInvitation) =>
  ({
    type: Types.CREATE_INVITATION_LOADED,
    data,
  } as ICreateInvitationLoaded);

export const UpdateInvitationLoaded = (data: IInvitation) =>
  ({
    type: Types.UPDATE_INVITATION_LOADED,
    data,
  } as IUpdateInvitationLoaded);

export interface IProcessInvitationLoaded extends Action {
  type: Types.PROCESS_INVITATION_LOADED;
  data: IInvitation;
}

export interface IInvitationFailed extends Action {
  type: Types.INVITATION_FAILED;
  data: IInvitationError;
}

export const ProcessInvitationLoaded = (data: IInvitation) =>
  ({
    type: Types.PROCESS_INVITATION_LOADED,
    data,
  } as IProcessInvitationLoaded);

export const InvitationFailed = ({ status, data }) =>
  ({
    type: Types.INVITATION_FAILED,
    data: { ...data, code: status },
  } as IInvitationFailed);

export const CreateInvitation = (invitation: IInvitation) => (dispatch) => {
  dispatch(InvitationLoading());
  createInvitation(invitation)
    .then((result) => {
      const data = result.data;
      dispatch(CreateInvitationLoaded(data as IInvitation));
      trackSharedInvitationCreated(data);
      dispatch(ShowCreateInvitationForm(false));
    })
    .catch(({ response }) => {
      dispatch(InvitationFailed(response));
    });
};

export const ProcessInvitation = (invitationId: string) => (dispatch) => {
  dispatch(InvitationLoading());
  processInvitation(invitationId)
    .then((result) => {
      getUserById().then((userRes) => {
        const userData: IUser = userRes.data.Items[0];
        dispatch(GetUserByIdLoaded(userData));
        const data = result.data;
        trackSharedInvitationProcessed(data);
        dispatch(ProcessInvitationLoaded(data as IInvitation));
      });
    })
    .catch(({ response }) => {
      dispatch(InvitationFailed(response));
    });
};

export interface IDeleteInvitationLoaded extends Action {
  type: Types.DELETE_INVITATION_LOADED;
  data: IInvitation;
}

export const DeleteInvitationLoaded = (data: IInvitation) =>
  ({
    type: Types.DELETE_INVITATION_LOADED,
    data,
  } as IDeleteInvitationLoaded);

export const DeleteInvitation = (invitationId: string) => (dispatch) => {
  dispatch(InvitationLoading());
  deleteInvitationById(invitationId).then((result) => {
    const data = result.data;
    data.id = invitationId;
    dispatch(DeleteInvitationLoaded(data as IInvitation));
  });
};

export interface IClearInvitationErrors extends Action {
  type: Types.CLEAR_INVITATION_ERRORS;
}

export const ClearInvitationErrorsAction = () =>
  ({
    type: Types.CLEAR_INVITATION_ERRORS,
  } as IClearInvitationErrors);

export const ClearInvitationErrors = () => (dispatch) => {
  dispatch(ClearInvitationErrorsAction());
};

export const UpdateInvitation = (invitation: IInvitation) => (dispatch) => {
  dispatch(InvitationLoading());
  dispatch(UpdateInvitationLoaded(invitation));
  updateInvitation(invitation);
};
