// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1tGiPFWSmBAEeFQ5NGG7Gx{height:65px}.Hzffl4-bzvpinNw8Lk5G_{padding:16px;background-color:#fff;display:flex}.Hzffl4-bzvpinNw8Lk5G_ ._3SY4cO32SJnNU7520WVzWX{font-family:\"DM Sans\";font-weight:700;font-size:14px;flex:1;color:#34363e}._1evZed2_mzkUcHnOpbAUY0{cursor:pointer;display:inline-block;padding:16px 40px;background-color:rgba(255,255,255,.5);border-top-left-radius:5px;border-top-right-radius:5px;flex:1;text-align:center;margin-top:10px}._1evZed2_mzkUcHnOpbAUY0._1AO5o_p_JbTtfsvwOljdW-{background-color:#fff !important;margin-top:0;opacity:1;height:65px}._1evZed2_mzkUcHnOpbAUY0 ._1UWR8JALQbiLIXiqQp2UBp._1AO5o_p_JbTtfsvwOljdW-,._1evZed2_mzkUcHnOpbAUY0 ._3SY4cO32SJnNU7520WVzWX._1AO5o_p_JbTtfsvwOljdW-,._1evZed2_mzkUcHnOpbAUY0:hover ._1UWR8JALQbiLIXiqQp2UBp,._1evZed2_mzkUcHnOpbAUY0:hover ._3SY4cO32SJnNU7520WVzWX{color:#34363e;font-family:\"DM Sans\";font-weight:700}._1UWR8JALQbiLIXiqQp2UBp{color:#848484;display:inline-block}._1UWR8JALQbiLIXiqQp2UBp i.icon{margin-right:10px;font-size:14px}._3SY4cO32SJnNU7520WVzWX{font-family:\"Open Sans\";font-weight:400;font-size:18px;display:-webkit-box;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-align:center;color:#848484;vertical-align:sub}@media(max-width: 576px){._1evZed2_mzkUcHnOpbAUY0{padding:12px}._3SY4cO32SJnNU7520WVzWX{font-size:14px}._1UWR8JALQbiLIXiqQp2UBp i.icon{margin-right:8px}}", ""]);
// Exports
exports.locals = {
	"container": "_1tGiPFWSmBAEeFQ5NGG7Gx",
	"containerSmall": "Hzffl4-bzvpinNw8Lk5G_",
	"itemName": "_3SY4cO32SJnNU7520WVzWX",
	"item": "_1evZed2_mzkUcHnOpbAUY0",
	"active": "_1AO5o_p_JbTtfsvwOljdW-",
	"itemIcon": "_1UWR8JALQbiLIXiqQp2UBp"
};
module.exports = exports;
