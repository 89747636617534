import React from 'react';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import { IFormFieldProps } from './common';
import InputWrap, { InputTooltip } from './InputWrap';
import styles from './styles/CheckboxGroup.module.scss';

export interface IProps extends IFormFieldProps {
  options: any[];
  value: string[];
}

const CheckboxGroup = ({
  label,
  className,
  labelClassName,
  inputClassName,
  rightIcon,
  error,
  disabled,
  options,
  value = [],
  onChange,
  name,
  ...inputProps
}) => {
  const handleChange = (_, data) => {
    const newValue = data.checked
      ? [...value, data.name]
      : R.reject(R.equals(data.name), value);
    const target = {
      name,
      value: newValue,
    };
    onChange({ target });
  };

  return (
    <InputWrap
      className={className}
      errorClassName={styles.error}
      error={error}
      label={label}
      labelClassName={labelClassName}
      rightIcon={rightIcon}
    >
      <div className={classnames('checkbox-group', styles.checkboxGroup)}>
        {options.map(({ text, value: optValue, tooltip }, idx) => (
          <label
            className={classnames(styles.checkbox, inputClassName)}
            key={`${idx}_${text}`}
          >
            {text && (
              <div className={styles.label}>
                <span>{text}</span>
                {tooltip && <InputTooltip tooltip={tooltip} on="hover" />}
              </div>
            )}
            <SemanticCheckbox
              id={inputProps.name}
              name={optValue}
              checked={R.includes(optValue, value)}
              className={classnames(styles.input, inputClassName)}
              onChange={handleChange}
            />
          </label>
        ))}
      </div>
    </InputWrap>
  );
};

export default CheckboxGroup;
