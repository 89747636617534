// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2o6S-CGdqS1NuXDdIAGhrT{display:flex;position:relative;overflow:hidden;max-height:100vh}._1zRrxBGj78KDqrtMk0Ydpb{z-index:0;flex:1;width:calc(100% - 250px)}._1zRrxBGj78KDqrtMk0Ydpb._4vHGa2yvJ9352SzTwPLK4{width:calc(100% - 95px)}.jWy9M28kyfs6E9UZY_qfI{margin-left:8px}._36rEh7lnUdRtSooSM6c9Fu{min-height:calc(100vh - 60px);height:calc(100% - 60px);width:100%;display:inline-block;position:relative;background:#f7f8fc;padding:40px 48px;z-index:0;overflow-y:auto}._36rEh7lnUdRtSooSM6c9Fu._1WOCL2ZDqwihUMXZ6aBDky{min-height:100vh}._36rEh7lnUdRtSooSM6c9Fu .z3K5sZKLNvxfLSUrKbnSN{display:none;padding-left:0;margin-bottom:16px}._36rEh7lnUdRtSooSM6c9Fu .z3K5sZKLNvxfLSUrKbnSN span{display:inline-block}._17_UCrgK--bAkXi-Qj80DD{position:fixed;z-index:1;background-color:#fff;opacity:.74;width:100%;height:100%}._1GtcDB2bV_J1Uuf6W1O5-4 ._36rEh7lnUdRtSooSM6c9Fu{min-height:100vh}._1GtcDB2bV_J1Uuf6W1O5-4._1zRrxBGj78KDqrtMk0Ydpb{padding-left:0}@media(max-width: 992px){._1zRrxBGj78KDqrtMk0Ydpb{padding-left:95px}.jWy9M28kyfs6E9UZY_qfI{margin-left:0}}@media(max-width: 820px){._1zRrxBGj78KDqrtMk0Ydpb{padding-left:0}._36rEh7lnUdRtSooSM6c9Fu{padding-top:24px;padding-bottom:24px}._36rEh7lnUdRtSooSM6c9Fu .z3K5sZKLNvxfLSUrKbnSN{display:block}}@media(max-width: 576px){._36rEh7lnUdRtSooSM6c9Fu{padding:24px}}", ""]);
// Exports
exports.locals = {
	"container": "_2o6S-CGdqS1NuXDdIAGhrT",
	"right": "_1zRrxBGj78KDqrtMk0Ydpb",
	"isSmall": "_4vHGa2yvJ9352SzTwPLK4",
	"new": "jWy9M28kyfs6E9UZY_qfI",
	"children": "_36rEh7lnUdRtSooSM6c9Fu",
	"fullHeight": "_1WOCL2ZDqwihUMXZ6aBDky",
	"navTitle": "z3K5sZKLNvxfLSUrKbnSN",
	"overlay": "_17_UCrgK--bAkXi-Qj80DD",
	"maximised": "_1GtcDB2bV_J1Uuf6W1O5-4"
};
module.exports = exports;
