import styles from './windcaveCallback.scss';
import QProtecta from '-!svg-react-loader!images/q/q-protecta.svg';
import { convertToNZDate } from '../../helpers/date';
import { IPaymentState, IPolicyProvider, IPolicyScan } from 'types';
import Button from 'components/button';
import { prettifyNumber } from '../../helpers/number';
import { useEffect } from 'react';
import { IUpdatePolicyScanLoaded } from 'actions';
import { trackMSPurchaseSuccess } from 'helpers';

const gstCalculator = (amount: string) => {
  return prettifyNumber(parseInt(amount, 10) * 0.15);
};

const findRetailPrice = (amount: string) => {
  return prettifyNumber(parseInt(amount, 10) - parseInt(amount, 10) * 0.15);
};

interface IWindcaveCallbackSuccessProps {
  payment: IPaymentState;
  policyProvider: IPolicyProvider;
  handleViewNewPolicy: () => void;
  updatePolicyScanLoaded: (data: IPolicyScan) => IUpdatePolicyScanLoaded;
}

const WindcaveCallbackSuccess = ({
  payment,
  policyProvider,
  handleViewNewPolicy,
  updatePolicyScanLoaded,
}: IWindcaveCallbackSuccessProps) => {
  const type = payment.windcavePaymentResult.type;
  const typeLowerCase = type.toLowerCase();

  useEffect(() => {
    updatePolicyScanLoaded({
      id: payment.windcavePaymentResult.policyScanId,
      purchasedAt: new Date().toString(),
    } as IPolicyScan);
    trackMSPurchaseSuccess({
      id: payment.windcavePaymentResult.policyScanId,
      providerName: payment.windcavePaymentResult.policyProviderName,
    });
  }, []);

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.protectaDiv}>
          <img src={policyProvider?.logoUrl} alt={policyProvider?.name} />
        </div>
        <div className={styles.title}>
          <strong>
            {' '}
            Thanks for choosing{' '}
            {payment.windcavePaymentResult.policyProviderName}, here's your
            receipt
          </strong>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.leftBodyContainer}>
          <div className={styles.topBody}>
            <div className={styles.topLeftBody}>
              <span>
                <strong>Receipt Summary</strong>
              </span>
              <p>Thank you for your payment!</p>
            </div>
            <div className={styles.topRightBody}>
              <span>
                <strong>Payment date</strong>
              </span>
              <p>{convertToNZDate(payment.windcavePaymentResult.createdAt)}</p>
            </div>
          </div>
          <div className={styles.bottomBody}>
            <div className={styles.topComponentsOfBottomBody}>
              <div className={styles.insideComponent}>
                <span className={styles.summaryCategory}>Retail total</span>
                <p className={styles.valueOfPrice}>
                  <strong>{`$${findRetailPrice(
                    payment.windcavePaymentResult.amount,
                  )}`}</strong>
                </p>
              </div>
              <div className={styles.insideComponent}>
                <span className={styles.summaryCategory}>GST</span>
                <p>
                  <strong>{`$${gstCalculator(
                    payment.windcavePaymentResult.amount,
                  )}`}</strong>
                </p>
              </div>
            </div>
            <div className={styles.gapContainer}>
              <div className={styles.divider} />
              <div className={styles.bottomComponentsOfBottomBody}>
                <div className={styles.layout}>
                  <span>Total</span>
                  <p>{`$ ${payment.windcavePaymentResult.amount}`}</p>
                </div>
                <div className={styles.priceDescription}>
                  {payment.windcavePaymentResult.subtotals[typeLowerCase]
                    .firstInstallment ? (
                    <p>
                      {' '}
                      {`Your next recurring payment will be the amount of $${payment.windcavePaymentResult.subtotals[typeLowerCase].firstInstallment} per month`}
                      <br />
                      If you have billing enquiries or any other questions
                      please contact Protecta.
                    </p>
                  ) : (
                    <p>
                      {' '}
                      If you have billing enquiries or any other questions
                      please contact Protecta.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightBody}>
          <div className={styles.withinRightBody}>
            <div className={styles.qImageDiv}>
              <QProtecta className={styles.qImage} />
            </div>
            <div className={styles.description}>
              <strong>
                Your vehicle is now covered by Protecta, view your brand new
                cover on your Quashed account now.
              </strong>
            </div>
            <div className={styles.coverButtonDiv}>
              <Button
                className={styles.coverButton}
                onClick={handleViewNewPolicy}
                text="View cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WindcaveCallbackSuccess;
