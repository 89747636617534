// Add commas and decimals to number
export const prettifyNumber = (num: string | number, decimal: number = 0) => {
  const converted = typeof num === 'number' ? num : parseFloat(num);
  return converted.toLocaleString(undefined, {
    // Dont show decimals unless its a float
    minimumFractionDigits: Number.isInteger(converted) ? decimal : 2,
    maximumFractionDigits: 2,
  });
};

export const parseAmount = (amount) => {
  const parsed = parseFloat(amount);
  return Number.isNaN(parsed) ? 0 : parsed;
};

export const isEqualNumbers = (num1, num2) =>
  parseFloat(num1) === parseFloat(num2);
