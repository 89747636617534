import { prop, sortBy } from 'ramda';

const toDropdownOption = (val) =>
  typeof val === 'string'
    ? {
        key: val,
        text: val,
        value: val,
      }
    : val;

export const prepareTypeFormQuestions = (questions) => {
  const obj = questions.reduce((acc, question) => {
    const q = { ...question };

    if (q.type === 'dropdown') {
      q.options = q.options ? q.options.map(toDropdownOption) : [];
    }

    const line = acc[q.line];
    if (line) {
      line.push(q);
    } else {
      acc[q.line] = [q];
    }
    return acc;
  }, {});

  return Object.keys(obj).map((key) => sortBy(prop('order'), obj[key]));
};

export const getDefaultValues = (q, obj) => {
  return q.reduce((acc, { altKey, key, listDefaultKey }) => {
    const value = obj[key];
    if (!value && obj[altKey]) {
      acc[altKey] = obj[altKey];
    } else {
      acc[key] = obj[key];
    }
    // We try to get default value from a list if it exists
    if (!acc[key] && listDefaultKey) {
      acc[key] = R.head(obj[listDefaultKey] || []);
    }
    return acc;
  }, {});
};

export const shouldShowQuestion = (question, questions, values) => {
  const { showKeyValue } = question;
  if (showKeyValue) {
    if (typeof showKeyValue === 'function') {
      return showKeyValue(values);
    } else {
      const [key] = Object.keys(showKeyValue);

      // Recursively check if dependent field is also shown
      const parentQuestion = questions.find(
        (q) => (q.key || q.fieldKey) === key,
      );
      if (
        parentQuestion &&
        shouldShowQuestion(parentQuestion, questions, values)
      ) {
        return values[key] === showKeyValue[key];
      }
    }

    return false;
  }

  return true;
};

export const isFormCompleted = (questions, values) => {
  return !questions.find((q) => R.isNil(values[q.key]));
};
