// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3J-KVwQwN0E3iJd6cKjgjD{font-family:\"Open Sans\";font-weight:400;border-radius:5px;background-color:#ebedf3;padding:24px;margin-top:16px}", ""]);
// Exports
exports.locals = {
	"disclaimer": "_3J-KVwQwN0E3iJd6cKjgjD"
};
module.exports = exports;
