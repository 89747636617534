import { ClientInviteStatus } from 'constants/adviser';
import { PolicyRequestTypes } from 'constants/explore-policies';
import { AppBannerContentType } from 'constants/notification';
import {
  PayoutFrequency,
  PayoutWaitPeriod,
  PayoutBenefitPeriod,
} from 'constants/options';
import { ReferralStatus } from 'constants/referral';
export * from './form';
export * from './admin';
export * from './quote';

export interface IDropdownOption {
  key: string;
  value: string;
  text: string;
}

export interface IUserQuote {
  contactInfo: string;
  contactType: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  data: string;
  frequency: string;
  id: string;
  policyType: string;
  policyTypeName: string;
  premium: number;
  sk: string;
  sumInsured: number;
}

export interface IUser {
  name: string;
  id: string;
  sk: string;
  data: string;
  email: string;
  picture: string;
  firstName: string;
  lastName: string;
  policies?: IPolicy[];
  families?: IFamilyUserMap[];
  insuranceReasons?: string;
  insuranceTypes?: string[];
  currentFamilyId: string;
  quashedBrokerCustomer?: boolean;
  // popups
  onHolidayCompleted?: boolean;
  exploreCompleted: boolean;
  quotes: IUserQuote[];
  signUpChannel?: string;
  coverageCompleted: boolean;
  coverageResultsCompleted: boolean;
  adviserOrg?: {
    [key: string]: any;
  };
  smoker?: boolean;
  subscriptionStatus?: string;
  subscription?: ISubscription;
  employment?: string;
  licenseType?: string;
  motorcycleLicenseType?: string;
  licenseConfirm?: boolean;
  holidays?: string;
  landlord?: string;
  occupation?: string;
  mortgage?: boolean;
  dateOfBirth?: string;
  cars?: string;
  accidentHistory?: string;
  drivingExp?: number;
  homeOwnership?: string;
  address?: string;
  gender?: string;
  pet?: string;
  boat?: string;
  travel?: string;
  income?: number;
  newUser?: boolean;
  adviserOrgId?: string;
  createdBy?: string;
  advisers?: IUser[];
  phone?: string;
  isPolicyOwner?: boolean;
  hasConsent?: boolean;
  defaultFamilyId?: string;
  status?: ClientInviteStatus;
  employerId?: string;
  employerName?: string;
  otherEmployerName?: string;
  q4eCompleted?: boolean;
  q4aTermsCompleted?: boolean;
  incomeFrequency?: string;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  rainyDayFund: string;

  // tracking
  hasPolicyScan?: boolean;
  hasGetAdvice?: boolean;
  hasAttachment?: boolean;
  hasPolicy?: boolean;

  // Q4A
  q4aOnboardingCompleted?: string;
  active?: boolean;
  bio?: string;
  company?: string;
  createdAt?: string;
  createdByName?: string;
  disclosure?: string;
  experence?: number;
  fspNo?: string;
  languages?: string[];
  location?: string;
  mobile?: string;

  // Q4E
  employedBy?: string;
  employmentStart?: string;
  grade?: string;
  q4eOption?: string;

  // Premium
  premium?: boolean;
  premiumOnboardingCompleted?: boolean;
  adviser?: IAdviser;

  // Onboarding
  walkthroughCompleted: boolean;
  walkthroughSkipped: boolean; // Local state - we don't save in DB
  onboardingStep: number;
}

export interface IAdviser {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  picture: string;
}

export interface IAdminSelectedUser extends IUser {
  bundles?: IBundle[];
  invitations?: IInvitation[];
  sentInvitations?: IInvitation[];
  policyScans?: IPolicyScan[];
  adviserUserConnects?: IAdviserConnect[];
}

export interface IFamilyUserMap {
  id: string; // familyId
  sk: string; // userId
  data: string;
  name: string;
  createdAt: string;
  familyOwner: boolean;
}

export interface IFamily {
  id: string;
  sk: string;
  data: string; // createdAt
  lastUpdatedAt: string;
}

export interface IInvitation {
  id?: string;
  sk?: string;
  familyId: string;
  name: string;
  invitedEmail: string;
  relationship: string;
  hide?: boolean;
  createdAt?: string;
  processed?: boolean;
  invitedUserId?: string;
  ownerName?: string;
  isUserExist?: boolean;
}

export interface IBenefit {
  name: string;
  description: string;
  covers: string[];
  excess: string;
}

export interface ICreditCard {
  providerName: string;
  sk: string;
  benefits: IBenefit[];
  id: string;
  name: string;
  data: string;
  excess: string;
  website: string;
  contact: string;
  cardImageUrl: string;
}

export interface IPolicySubType {
  id: string;
  name: string;
  description: string;
  insurer: string;
  details: string;
  policySubTypeName: string;
  type: string;
  sk: string;
}

export interface IPolicyType {
  id: string;
  name: string;
  policyTypeName: string;
  sk: string;
  details: IPolicyTypeDetails[];
  policySubTypes?: IPolicySubType[];
  description?: string;
  life?: boolean;
  quoteUrl?: string;
}

export interface IPolicyTypeDetails {
  content: string;
  header: string;
  order: number;
}

export interface IPolicyProvider {
  id: string;
  name: string;
  country: string;
  data: string;
  sk: string;
  underwriterId: string;
  underwriterName: string;
  policyTypes: IPolicyType[];
  contact?: string;
  email?: string;
  website?: string;
  logoUrl?: string;
  fullName: string;
  description1: string;
  description2: string;
}

export interface IPolicyScanReference {
  [key: string]: {
    [key: string]: IDropdownOption[];
  };
}

export interface ICheckupReference {
  [key: string]: {
    [key: string]: number;
  };
}

export interface IPolicyProviderInsurer extends IPolicyProvider {
  policyProviderName: string;
  policyTypeName: string;
  policySubTypes: IPolicySubType[];
}

export interface IBenefitLimit {
  name: string;
  value: string;
}

export interface IReferenceData {
  creditCards: ICreditCard[];
  policyTypes: IPolicyType[];
  policyProviders: IPolicyProvider[];
  policySubTypes: IPolicySubType[];
  employers: IEmployer[];
  wiki: IWiki[];
  policyScan: IPolicyScanReference;
  insuranceCheckup: ICheckupReference;
}

export enum PolicyArrangementType {
  DISCOUNT = 'DISCOUNT',
  FREE = 'FREE',
}

export interface IWiki {
  policyType: string;
  headerDesc: any;
  headerImg: string;
  moreInfoDesc: any;
  moreInfoImg: string;
  sliderHeader: string;
  sliders: any[];
  faqs: any[];
  data: any;
  isGeneral: boolean;
  title?: string;
  blurb?: string;
}

export interface IEmployerPolicy {
  arrangementType: PolicyArrangementType;
  customAttributes?: any;
  policyProviderId: string;
  policyProviderName: string;
  policyTypeId: string;
  policyTypeName: string;
  note?: string;
  showPopup?: boolean;
}

export interface IEmployer {
  employerLogoUrl: string;
  id: string;
  isActive: boolean;
  name: string;
  policies: IEmployerPolicy[];
  note?: string;
}

export interface IConfig {
  id: string;
  sk: string;
  data: string;
  updateBy: string;
  updatedAt: string;
  referenceLastUpdated: string;
  q4eEmployers: IEmployer[];
  wiki: IWiki[];
  settings: {
    features: {
      holidayStart?: string;
      holidayEnd?: string;
      appBannerEnabled: boolean;
      brokerPortalEnabled: boolean;
      coverageCheckEndabled: boolean;
      explorePolicyEnabled: boolean;
      hiddenBrokerMS: string[];
      q4eEnabled: boolean;
      lifeMSEnabled: boolean;
      brokerFirstEnabled: boolean;
    };
    [key: string]: any;
  };
}

export interface IPolicyRenewal {
  id?: string;
  sk?: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  completed: boolean;
  renewalDate: string;
  newPolicyData: IPolicy;
  oldPolicyData?: IPolicy;
  isRenewExpiredPolicy?: boolean;
  processed?: boolean;
  finished?: boolean;
  files?: number;
  premium?: number;
  policyId?: string;
  attachments?: IAttachment[];
}

export interface IPolicyInsuredAdditionalCover {
  insuredAmount: number;
  policyTypeId: string;
  policyType: string;
  excess?: string;
  payoutFrequency?: string;
  payoutWaitPeriod?: string;
  payoutBenefitPeriod?: string;
}
export interface IPolicyInsured {
  name: string;
  order: number;
  premiumCover?: boolean;
  additionalCovers: IPolicyInsuredAdditionalCover[];
}

export interface IPolicy {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  policyProviderId?: string;
  policyProviderName?: string;
  userId?: string;
  effective?: boolean;
  premium?: number;
  premiumConfirmed?: boolean;
  excess?: number;
  address?: number;
  fullAddress?: IFullAddress;
  policyTypeId?: string;
  policyType?: string;
  creditCardId?: string;
  creditCardName?: string;
  creditCardSk?: string;
  processed?: boolean;
  renewalDate?: string;
  policyRenewalAdded?: boolean;
  frequency?: string;
  policySubTypeId?: string;
  policySubTypeName?: string;
  uploadedAt?: string;
  lastPolicyScanDate?: string;
  attachmentsCount?: number;
  paymentMethod?: string;
  insured?: IPolicyInsured[];
  payoutFrequency?: PayoutFrequency;
  payoutWaitPeriod?: PayoutWaitPeriod;
  payoutBenefitPeriod?: PayoutBenefitPeriod;
  areaOfGaraging?: string;
  quashedBrokerCover?: boolean;
  brokerPolicyYearlyPremium?: number;
  [key: string]: any;
}

export interface ILifePolicy extends IPolicy {
  insured: Array<{
    additionalCovers: Array<{
      insuredAmount: number;
      policyType: string;
      policyTypeId: string;
    }>;
    name: string;
    order: number;
    premiumCover?: boolean;
  }>;
}

export interface IAttachment {
  id: string;
  sk: string;
  key: string;
  createdAt?: string;
  fileName: string;
  policyId: string;
  [key: string]: any;
}

export interface IAdmin {
  loading: boolean;
  selectedPolicyProvider?: IPolicyProvider;
  selectedPolicyType?: IPolicyType;
  selectedPolicySubtype?: IPolicySubType;
  selectedUser?: IAdminSelectedUser;

  users?: IUser[];
}

export interface IOperationsState {
  loading: boolean;
  policyScans: IPolicyScan[];
  currentPolicyScan?: IPolicyScan;
  selectedPolicy?: IPolicy;
  selectedPolicyRenew?: IPolicy;
  addPolicyModelOpen: boolean;
  addPolicyModelMode: IAddPolicyMode;
  feedbacks?: IFeedback[];
  filteredPolicies?: IFilteredPolicy[];
  error: string;
  referrals?: IReferral[];
  adviserConnects?: IAdviserConnect[];
  selectedAdviserConnect: IAdviserConnect;
  emailNotification?: IEmailNotificationState[];
  policyRenewalDetails?: {
    policy: IPolicy;
    policyRenewal: IPolicyRenewal;
  };
  marketScanFeedbacks: IMarketScanFeedback[];
  policyScanPayload?: any;
}

export interface IInvitationError {
  code: number;
  message?: string;
  [key: string]: any;
}

export interface IInvitationState {
  loading: boolean;
  showCreateInvitationPage: boolean;
  invitaitonProcessed?: IInvitation;
  // Dashboard you shared
  invitations: IInvitation[];
  // Dashboards shared with you
  sharedInvitations: IInvitation[];
  error?: IInvitationError;
}

export interface IPolicyScan {
  brokerNotesForCustomer: string;
  completed: boolean;
  createdAt: string;
  createdBy: string;
  data: string;
  id: string;
  sk: string;
  updatedAt: string;
  updatedBy: string;
  policyType: string;
  hide?: boolean;
  userName: string;
  numberPlate?: string;
  address?: string;
  fullAddress?: IFullAddress;
  user?: IUser;
  policy?: IPolicy;
  quotes?: IQuote[];
  policyTypeId: string;
  policyTypeName: string;
  status?: POLICY_SCAN_STATUS;
  summary: IPolicy;
  attachments: IAttachment[];
  feedbackVoteId?: string;
  feedbackNotesId?: string;
  keyInfo?: { [key: string]: string };
  savedQuoteInfo?: { [key: string]: string | number };
  referral?: { [key: string]: string | number };
  purchasedAt?: string;
  surveyFormSubmittedAt?: string;
  dateOfBirth?: string;
  gender?: string;
  licenseType?: string;
  licenseConfirm?: boolean;
  drivingExp?: number;
  amount: number;
  excess: number;
  premium: number;
  selectedProvider?: {
    recommended?: boolean;
    policyProviderId: string;
    policyProviderName: string;
    premium: number;
    frequency: string;
    selectedDate: string;
  };

  brokerFormStartedAt?: string;
  brokerFormCreatedAt?: string;
  brokerFormCreatedBy?: string;
  brokerFormResults?: { [key: string]: any };
  brokerPolicyAcceptedAt?: string;
  brokerPolicyAcceptedBy?: string;
  brokerPolicyProvider?: string;
  brokerPolicyProviderName?: string;

  // brokerFirst
  isBrokerFirst?: boolean;
  needRegularChecks?: boolean;
  needAdviceForCovers?: boolean;
  needHelpOnClaims?: boolean;
  adviserUserConnectId?: string;

  automationStatus?: AUTOMATION_STATUS;
  automationHasError?: boolean;

  frequency?: string;
  phone?: string;

  warningDetails?: string;
  warningTitle?: string;
}

export interface IFullAddress {
  flatNo: string;
  streetNo: string;
  streetName: string;
  suburb: string;
  city: string;
  postCode: string;
  country?: string;
  lat?: number;
  lng?: number;
}

export enum AUTOMATION_STATUS {
  MANUAL_REVIEW_NEEDED = 'MANUAL_REVIEW_NEEDED',
  MANUAL_COMPLETED_NEEDED = 'MANUAL_COMPLETED_NEEDED',
}

export interface IMarketScanFeedback {
  id: string;
  sk: string;
  data: string;
  policyId: string;
  policyType: string;
  policyScanId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  createdByEmail: string;
  hasEnoghInformation: string;
  lookingToPurchase: string;
  wouldRecomend: string;
  likeToImprove: string;
}

export interface IFilteredPolicy {
  id: string;
  sk: string;
  amount: string;
  createdAt: string;
  data: string;
  excess: string;
  frequency: string;
  policyProviderId: string;
  policyProviderName: string;
  policyType: string;
  premium: string;
  renewalDate: string;
  status: string;
  userId: string;
}

export interface IQuote {
  disabled?: boolean;
  policyProviderId: string;
  policyProviderName: string;
  currentProvider: boolean;
  weekly?: number;
  monthly?: number;
  fortnightly?: number;
  quarterly?: number;
  yearly?: number;
  amount: number;
  excess: number;
  recommended?: boolean;
  imagesKey?: string;
  imagesUrl?: string;
  error?: string;
  payload?: string;
  referral_link?: string;
  motorcycleLicenseType?: string[];
  availableCoverTypes?: string[];
}

export interface IPolicyReview {
  id: string;
  sk: string;
  data: string;
  name: string;
  policyTypeName: string;
  contactType: string;
  contactInfo: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  policyIds: string[];
}

export interface IPolicies {
  policies: IPolicy[];
  selectedPolicy?: IPolicy;
  selectedPolicyScan?: IPolicyScan;
  updating?: boolean;
  policyScans: IPolicyScan[];
  policyReviews: IPolicyReview[];
  bundles: IBundle[];
  loading: boolean;
  submitting: boolean;
  submitted: boolean;
  error: string;
}

export interface IPolicyRenewalState {
  renewals: IPolicyRenewal[];
  pendingRenewals: IPolicyRenewal[];
  submitting: boolean;
  submitted: boolean;
  loaded: boolean;
}

export interface IAdviserTotal {
  clients: number;
  policies: number;
  premiums: number;
  amount: number;
}

export interface IConsent {
  id: string;
  sk: string;
  data: string;
  email: string;
  firstName: string;
  lastName: string;
  inviteStatus: string;
  invitedUserDefaultFamilyId?: string;
  invitedUserId?: string;
  confirmedAt?: string;
  createdAt?: string;
  createdBy?: string;
  createdByName?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface IAdviserState {
  advisers: IUser[];
  adviserConnects: IAdviserConnect[];
  cart: IAdviserConnectCartItem;
  premiumAdviserConnect: IAdviserConnect;
  isConnected: boolean;
  submitted: boolean;
  submitting: boolean;
  clients: IUser[];
  total: IAdviserTotal;
  loaded: boolean;
  loading: boolean;
  isInquired: boolean;
  loadingCalendar: boolean;
  loadingConsents: boolean;
  consents: IConsent[];
  error?: any;
  calendly: {
    eventUrl?: string;
  };
  adviserConnectInfo?: {
    helpWith: string;
    interestedIn: string[];
    preferredContact: string;
    preferredContactDetails: string;
  };
}

export interface IReferralState {
  referrals: IReferral[];
  showAdviserReferral: boolean;
}

export interface ICreditCardState {
  creditCards: ICreditCard[];
  loaded: boolean;
  submitting: boolean;
  submitted: boolean;
}

export interface INotificationState {
  bannerType: AppBannerContentType;
  notifications: INotification[];
  loading: boolean;
}

export interface ILayoutState {
  expanded: boolean;
  maximised: boolean;
}

export interface IAppState {
  user: IUser;
  referenceData?: IReferenceData;
  policies: IPolicies;
  admin: IAdmin;
  operations: IOperationsState;
  invitation: IInvitationState;
  config?: IConfig;
  adviser: IAdviserState;
  referral: IReferralState;
  payment: IPaymentState;
  creditCard: ICreditCardState;
  policyRenewal: IPolicyRenewalState;
  notification: INotificationState;
  layout: ILayoutState;
}

export interface IAdminPolicyTypeStats {
  total: number;
  totalPremiums: number;
  totalSumInsured: number;
}

export interface IAdminStats {
  [key: string]: any;

  TotalNumberOfPolicies: number;
  TotalNumberOfUsers: number;
  TotalActivatedUsers: number;
  TotalPremiumsManaged: number;
  TotalSumInsuredInPlatform: number;
  AveragePoliciesPerActivatedUser: number;
  RenewalStats: string;
  RenewalUserDetails: string[];
  // totalPolicies: number;
  // totalPoliciesWithPremium: number;
  // totalYearlyPremium: string;
  // totalAttachments: number;
  // totalPoliciesWithAttachments: number;
}

export interface ICode {
  data: string;
  id: string;
  sk: string;
  used: boolean;
}

export interface ICodeSent {
  codeType: string;
  createdAt: string;
  data: string;
  id: string;
  messageId: string;
  mobile: string;
  sk: string;
  userName: string;
}

export interface IAdviserConnectUnderwritingQuestions {
  hasClaims2Years: boolean;
  // When hasClaims2Years is true
  claims2Years: Array<{
    policyType: string;
    description: string;
    date: string;
    cost: number;
  }>;
  hasInsuranceDenied: boolean;
  hasDisasterDamage: boolean;
  hasCriminalActivity: boolean;
  isInformationCorrect: boolean;
  hasAgreedToNotifyChanges: boolean;
  privacyConsent: boolean;
}

export interface IAdviserConnectCartItem {
  frequency: string;
  policyId: string;
  policyProviderId: string;
  policyProviderName: string;
  policyScanId: string;
  policyType: string;
  premium: number;
  selectedDate: string;
}

export interface IAdviserConnectCart {
  [policyScanId: string]: IAdviserConnectCartItem;
}

export interface IAdviserConnect {
  adviserName: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  createdByEmail?: string;
  data: string;
  id: string;
  sk: string;
  calendlyEvent?: ICalendyFullEvent;
  message?: string;
  note?: string;
  phoneNumber?: string;
  asapCallback?: boolean;
  meetingStatus?: string;
  updatedAt?: string;
  underwritingQuestions?: IAdviserConnectUnderwritingQuestions;
  policyScans?: {
    [key: string]: {
      count: number;
      items?: IPolicyScan[];
    };
  };
  policies?: {
    [key: string]: number;
  };
  orders?: any;
  adviserConnectAttachments?: IAttachment[];
  adviserConnectPolicies?: IPolicy[];
  addedToCart?: IAdviserConnectCart;
}

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
}

export enum POLICY_SCAN_STATUS {
  READY = 'READY',
  PENDING = 'PENDING',
  VIEWED = 'VIEWED',
  ABANDONED = 'ABANDONED',
  IN_REVIEW = 'IN_REVIEW',
  REFERRED = 'REFERRED',
}

export interface IQuoteRequest {
  age: number;
  gender: GENDER;
  smoker: boolean;
  insuredAmount: number;
  policyType: string;
}

export interface IQuoteResult {
  Yearly: number;
  Monthly: number;
}

export interface IQuoteCoverRequest {
  name: string;
  premium: number;
  sumInsured: number;
  policyTypeName: string;
  policyType: string;
  frequency: string;
  contactType: string;
  contactInfo: string;
  requestType: PolicyRequestTypes;
  DOB: string;
  gender: string;
  smoker: boolean;
  occupation: string;
}

export interface ICreatePolicyProps {
  id?: string;
  policyProviderId: string;
  policyProviderName: string;
  userId?: string;
  policyTypeId: string;
  effective: boolean;
  premium: number;
  policyType: string;
  isUpload?: boolean;
  [key: string]: any;
}

export interface ICalendlyIFrameEvent {
  event: string;
  payload: ICalendlyIFrameEventPayload;
}
interface ICalendlyIFrameEventPayload {
  event: ICalendlyIFrameEventPayloadEvent;
  invitee: Event;
}
interface ICalendlyIFrameEventPayloadEvent {
  uri: string;
}

export interface ICalendyFullEvent {
  resource: ICalendyFullEventResource;
}

interface ICalendyFullEventResource {
  start_time: string;
  event_type: string;
  updated_at: string;
  event_memberships: ICalendyFullEventResourceEventmembership[];
  end_time: string;
  invitees_counter: ICalendyFullEventResourceInviteescounter;
  name: string;
  created_at: string;
  location: ICalendyFullEventResourceLocation;
  event_guests: any[];
  uri: string;
  status: string;
}
interface ICalendyFullEventResourceLocation {
  type: string;
  location?: any;
}

interface ICalendyFullEventResourceInviteescounter {
  limit: number;
  active: number;
  total: number;
}

interface ICalendyFullEventResourceEventmembership {
  user: string;
}

export interface IReferral {
  acceptedAt: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  data: string;
  id: string;
  recipientEmail: string;
  recipientFirstName: string;
  recipientUserId: string;
  recipientUserName: string;
  sk: string;
  status: ReferralStatus;
  updatedAt: string;
}

export interface IReferralNew {
  recipientEmail: string;
  recipientFirstName: string;
}

export interface IReferralAdviser {
  email: string;
  name: string;
}

export interface ISubscription {
  id: string;
  sk: string;
  data: string;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  stripeCustomerId: string;
  updatedAt: string;
  status: string;
  createdAt: string;
  createdBy: string;
}

export interface IPaymentResult {
  amount: number;
  cardType: string;
  name: string;
  receipt_email: string;
}

export interface IPaymentState {
  loading: boolean;
  error: string;
  paymentResult: IPaymentResult;
  windcavePaymentResult: IWindcavePaymentResult;
}

export interface IDropdownOption {
  key: string;
  text: string;
  value: string;
}

export enum IAddPolicyMode {
  LIFE = 'LIFE',
  ATTACHMENT = 'ATTACHMENT',
  GENERAL = 'GENERAL',
  BUNDLE = 'BUNDLE',
  LIFE_RENEW = 'LIFE_RENEW',
}

export interface IBundle {
  id: string;
  sk: string;
  data: string;
  attachments: number;
  processed: boolean;
  createdAt: string;
  createdBy: any;
  files?: IAttachmentKeyName[];
}

export interface IAttachmentKeyName {
  key: string;
  fileName: string;
  postFileInfo?: { fields; url: string };
}

export interface IOrganization {
  createdByName: string;
  data: string;
  createdAt: string;
  sk: string;
  fspNo: string;
  id: string;
  email: string;
  phone: string;
  createdBy: string;
  name: string;
  advisers: IUser[];
}

export interface IFeedback {
  sk: string;
  feedbackType: string;
  vote?: boolean;
  notes?: string;
  createdBy: string;
  createdByName: string;
  id: string;
  data: string;
  createdAt: string;
  updatedAt: string;
}

export interface IEmailNotificationState {
  sk: string;
  id: string;
  createdAt: string;
  notificationType: string;
  status: string;
  userName: string;
  emailStatus: string;
}

export interface IPaymentLink {
  rel: string;
  method: string;
  href: string;
}

export interface IWindcavePaymentResult {
  policyProviderName: string;
  createdAt: string;
  createdBy: string;
  quoteType: string;
  policyScanId: string;
  sessionProvider: string;
  policyId: string;
  data: string;
  updatedAt: string;
  paymentCompleted: boolean;
  amount: string;
  sk: string;
  id: string;
  type: string;
  notCompletedReason: string;
  subtotals: IWindcavePaymentResultSubtotals;
  paymentLinks: IPaymentLink;
}

interface IWindcavePaymentResultSubtotals {
  mvi: IWindcavePaymentResultMvi;
}

export interface IWindcavePaymentResultMvi {
  gst: string;
  standardExcess: string;
  wholesale: string;
  retail: string;
  fsl: string;
  companyPremium: string;
  firstInstallment?: string;
  subsequentInstallment?: string;
}

export interface INotification {
  id: string;
  sk: string;
  data: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  notificationType: string;
  seen: boolean;
  seenAt: string;
  target: string;
  policyType: string;
  policyId: string;
  inviteeName: string;
  clicked: boolean;
  hide: boolean;
  isPremium?: boolean;
  startDate?: string;
  endDate?: string;
  modalInfo?: {
    title: string;
    shortTitle: string;
    imgUrl: string;
    description: string;
    shortDescription: string;
    actionText: string;
    actionUrl: string;
  };
}

export interface IGlobalNotificationCreate {
  startDate: string;
  endDate: string;
  modalInfo: {
    title: string;
    shortTitle: string;
    imgUrl: string;
    description: string;
    shortDescription: string;
    actionText: string;
    actionUrl: string;
  };
}

export interface IGlobalNotificationModalInfo {
  modalInfo: {
    title: string;
    shortTitle: string;
    description: string;
    shortDescription: string;
    imgUrl: string;
    actionText: string;
    actionUrl: string;
  };
  show: boolean;
}

export interface INotificationItem {
  user?: IUser;
  policy?: IPolicy;
  policyScan?: IPolicyScan;
  renewal?: IPolicyRenewal;
  quote?: IUserQuote;
  invitation?: IInvitation;
}

export interface IAdditionalDriver {
  atFaultIncidents5Years: boolean;
  dateOfBirth: string;
  firstName: string;
  gender: string;
  isMainDriver: boolean;
  lastName: string;
  licenseConfirm: boolean;
  licenseType: 'string';
  title: string;
  drivingExp?: number;
  drivingOffences3Years?: boolean;

  accidentClaims?: IAccidentClaim[];
  drivingOffences?: IDrivingOffence[];
}

export interface IDrivingOffence {
  date: string;
  description: string;
}
export interface IAccidentClaim {
  cost: number;
  date: string;
  isExcessClaimed: boolean;
  reason: string;
  description: string;
}
