import { AppActionTypes } from 'actions';
import { Types } from '../actions/notification-actions';
import { Types as PolicyTypes } from '../actions/policy';
import {
  batchUpdateListMerge,
  removeItemsByProps,
  sortDateToISOByPropDesc,
  updateListByItem,
} from 'helpers';
import { INotification, INotificationState } from 'types';
import { AppBannerContentType } from 'constants/notification';

// Sort by createdAt desc and filter out hide = true
const sortAndFilter = (notifications: INotification[]) => {
  const filtered = notifications.filter((n) => !n.hide);
  return sortDateToISOByPropDesc('createdAt')(filtered);
};

const updateNotification = (
  notifications: INotification[],
  notification: INotification,
) => {
  const updated = updateListByItem(notifications, notification);
  return sortAndFilter(updated);
};

const notificationReducer = (
  state: INotificationState,
  action: AppActionTypes,
): INotificationState => {
  switch (action.type) {
    case Types.NOTIFICATIONS_LOADING:
      return { ...state, loading: true };
    case Types.DELETE_POLICY_NOTIFICATIONS_LOADED:
      return {
        ...state,
        notifications: removeItemsByProps(
          state.notifications,
          ['policyId', 'target'],
          action.data,
        ),
      };
    case Types.GET_NOTIFICATIONS_LOADED:
      return {
        ...state,
        notifications: sortAndFilter(action.data),
        loading: false,
      };
    case Types.UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: updateNotification(state.notifications, action.data),
      };
    case Types.SEEN_NOTIFICATIONS:
      return {
        ...state,
        notifications: batchUpdateListMerge(state.notifications, action.data),
      };
    case PolicyTypes.CREATE_POLICY_SCAN_LOADED:
    case PolicyTypes.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED:
      return {
        ...state,
        bannerType: state.bannerType ?? AppBannerContentType.HIGH_VOL_MS,
      };
    case Types.TOGGLE_APP_BANNER:
      return {
        ...state,
        bannerType: action.data,
      };
    default:
      return state;
  }
};

export default notificationReducer;
