import { useState, useCallback } from 'react';
import { windowScrollTop } from 'helpers';

export interface IUsePager {
  active: number;
  setActive(active: number): void;
  next(): void;
  prev(): void;
  resetActive(): void;
}

const usePager = (defaultValue, disableScroll?: boolean): IUsePager => {
  const [active, setActive] = useState(defaultValue);
  const next = useCallback(() => {
    if (!disableScroll) {
      windowScrollTop();
    }
    setActive(active + 1);
  }, [active]);
  const prev = useCallback(() => {
    if (!disableScroll) {
      windowScrollTop();
    }
    setActive(Math.max(active - 1, 0));
  }, [active]);
  const resetActive = useCallback(() => setActive(0), []);

  return {
    active,
    setActive,
    next,
    prev,
    resetActive,
  };
};

export default usePager;
