import queryString from 'query-string';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { YEARLY_MULTIPLIERS } from 'constants/options';
import { OTHER_EMPLOYER_ID, UserEmployment } from 'constants/user';
import {
  createFinishSetupPolicyTemplate,
  createEmployerPolicyTemplate,
  getFamily,
  mapEmployerOptions,
  findDiscountedEmployerPolicy,
  findFreeEmployerPolicy,
  findEmployerById,
} from 'helpers';
import { PolicyArrangementType, IUser } from 'types';
import LastPage from './pages/LastPage';
import QuestionPage from './pages/QuestionPage';

const firstPageTitle = 'Add workplace';
const lastPageTitle = 'Here are your insurance benefits';

const defaultPageMap = {
  0: QuestionPage,
  1: LastPage,
};

export const getEmployerOptions = R.pipe(
  // Map employers to options
  mapEmployerOptions,
  // Append "Other" option
  R.append({
    key: 'Other',
    text: 'Other',
    value: 'Other',
  }),
);

export const getDefaultValues = ({
  employerId,
  income,
  incomeFrequency,
  otherEmployerName,
  employedBy,
  employmentStart,
  grade,
  q4eOption,
}: IUser) => ({
  q4eOption,
  employerId: employerId || (otherEmployerName ? OTHER_EMPLOYER_ID : ''),
  otherEmployerName,
  income,
  incomeFrequency,
  employedBy,
  employmentStart,
  grade,
});

export const getOtherSubmitData = (user, formData) => {
  return R.merge(user, {
    ...formData,
    employment: UserEmployment.EMPLOYED,
    // Remove employerId and name when "Other" is selected
    employerId: undefined,
    employerName: undefined,
    q4eCompleted: true,
  });
};

export const getSubmitData = (employers, user, formData) => {
  const newUser = R.merge(user, {
    ...formData,
    employerName: formData.employerName,
    employment: UserEmployment.EMPLOYED,
    otherEmployerName: undefined,
    q4eCompleted: true,
  });

  const employer = findEmployerById(employers, formData.employerId);
  if (employer) {
    newUser.employerName = employer.name;
  }

  return newUser;
};

const getIncomeValue = (user, policy) => {
  const { income, incomeFrequency } = user;
  const { multiplier, baseIncomeFrequency } = policy;
  const yearlyMultiplier = 1 / (YEARLY_MULTIPLIERS[baseIncomeFrequency] || 1);
  const yearlyIncome = income * (YEARLY_MULTIPLIERS[incomeFrequency] || 1);
  return yearlyIncome * (multiplier * yearlyMultiplier);
};

const getTemplate = (policy, user, familyId) => {
  return {
    policyProviderId: policy.policyProviderId,
    policyProviderName: policy.policyProviderName,
    policyTypeId: policy.policyTypeId,
    policyType: policy.policyTypeName,
    familyId,
    userId: user.id,
    amount: getIncomeValue(user, policy),
    // This is to combine all isLife into one life policy
    isLife: policy.policyTypeName === INSURANCE_TYPES_NAMES.LIFE,
    ...policy.customAttributes,
  };
};

// Get existing Q4E policies to update
const getPendingUpdates = (policy, existingPolicy, pendingUpdate) => {
  const updatedIdx = pendingUpdate.findIndex(
    R.propEq('policyType', INSURANCE_TYPES_NAMES.LIFE),
  );

  // When there is already a pending life policy to update,
  // we refer to that instead of the current existing policy
  const toUpdate = updatedIdx >= 0 ? pendingUpdate[updatedIdx] : existingPolicy;

  // Life policies need to be updated as additional covers
  if (policy.isLife) {
    const covers = toUpdate.insured[0]?.additionalCovers || [];
    const coverIdx = covers.findIndex(
      R.propEq('policyType', policy.policyType),
    );
    const updateData = {
      policyTypeId: policy.policyTypeId,
      policyType: policy.policyType,
      payoutFrequency: policy.payoutFrequency,
      payoutWaitPeriod: policy.payoutWaitPeriod,
      insuredAmount: policy.amount,
    };

    // If cover already exists, just update the insured amount
    if (coverIdx >= 0) {
      toUpdate.insured[0].additionalCovers[coverIdx] = updateData;
    } else {
      // Else append it
      toUpdate.insured[0].additionalCovers = [...covers, updateData];
    }
    return R.update(updatedIdx, toUpdate, pendingUpdate);
  }

  const updated = R.assocPath(['amount'], policy.amount, toUpdate);
  return updatedIdx >= 0
    ? R.update(updatedIdx, updated, pendingUpdate)
    : R.append(updated, pendingUpdate);
};

// Get Q4E policies to create
const getPendingCreate = (policy, user, employer, pendingCreate) => {
  // If isLife and theres already a pending life policy,
  // append it as an additional cover
  if (policy.isLife) {
    const lifeIdx = pendingCreate.findIndex(
      R.propEq('policyType', INSURANCE_TYPES_NAMES.LIFE),
    );
    if (lifeIdx >= 0) {
      const lifePolicy = pendingCreate[lifeIdx];
      lifePolicy.insured[0]?.additionalCovers.push({
        policyTypeId: policy.policyTypeId,
        policyType: policy.policyType,
        payoutFrequency: policy.payoutFrequency,
        payoutWaitPeriod: policy.payoutWaitPeriod,
        insuredAmount: policy.amount,
      });
      return R.update(lifeIdx, lifePolicy, pendingCreate);
    }
  }

  const newPolicy = createEmployerPolicyTemplate({
    ...policy,
    employerId: employer.id,
    employerName: employer.name,
    firstName: user.firstName,
    lastName: user.lastName,
  });

  return R.append(newPolicy, pendingCreate);
};

// Loop through pending updates and remove life covers that is not included in their scheme
const cleanLifeCovers = (pendingUpdate: any[], freePolicies: any[]) => {
  const lifeCoverIds = freePolicies.reduce((acc, p) => {
    if (
      p.policyTypeName === INSURANCE_TYPES_NAMES.LIFE ||
      p.customAttributes?.isLife
    ) {
      acc.push(p.policyTypeId);
    }
    return acc;
  }, []);

  return pendingUpdate.map((policy) => {
    if (policy.policyType === INSURANCE_TYPES_NAMES.LIFE) {
      policy.insured[0].additionalCovers = policy.insured[0].additionalCovers.filter(
        (c) => R.includes(c.policyTypeId, lifeCoverIds),
      );
    }
    return policy;
  });
};

export const createEmployerPolicies = async (
  createPolicies,
  updatePolicy,
  employers,
  currentPolicies,
  user,
) => {
  const employer = findEmployerById(employers, user.employerId);
  const employerPolicies = getEmployerPolicies(employers, user);
  const family = getFamily(user.families);

  let pendingUpdate = [];
  let pendingCreate = [];

  // Group employer policies by DISCOUNT/FREE
  const grouped = R.groupBy(R.prop('arrangementType'), employerPolicies);
  const discountedPolicies = grouped[PolicyArrangementType.DISCOUNT];
  const freePolicies = grouped[PolicyArrangementType.FREE];

  discountedPolicies?.forEach((policy) => {
    const template = getTemplate(policy, user, family.id);
    if (!findDiscountedEmployerPolicy(currentPolicies, template)) {
      pendingCreate.push(
        createFinishSetupPolicyTemplate(template, user, employer),
      );
    }
  });

  freePolicies?.forEach((policy) => {
    const template = getTemplate(policy, user, family.id);

    const existingPolicy = findFreeEmployerPolicy(
      currentPolicies,
      template,
      employer.id,
    );

    // Update policy if it exists
    if (existingPolicy) {
      pendingUpdate = getPendingUpdates(
        template,
        existingPolicy,
        pendingUpdate,
      );
    } else {
      pendingCreate = getPendingCreate(template, user, employer, pendingCreate);
    }
  });

  pendingUpdate = cleanLifeCovers(pendingUpdate, freePolicies);

  pendingUpdate.forEach((p) => updatePolicy(p, false));

  if (pendingCreate.length) {
    return createPolicies(pendingCreate);
  }
};

export const updateUserEmployer = (updateUser, employers, user, data) => {
  const submitData = getSubmitData(employers, user, data);
  updateUser(submitData);
  return submitData;
};

export const getEmployerBenefits = (employers, employerId) => {
  const employer = findEmployerById(employers, employerId);
  return R.propOr([], 'policies', employer);
};

export const getEmployerPolicies = (employers, values) => {
  const policies = getEmployerBenefits(employers, values.employerId);
  return policies.filter((p) => !p.filter || R.whereEq(p.filter, values));
};

export const getTitle = (questions = [], page) => {
  const questionPage = questions.length > 0 ? 1 : 0;
  return page > questionPage ? lastPageTitle : firstPageTitle;
};

export const shouldSkipPage = () => {
  const parsed = queryString.parse(location.search);
  return !!parsed.id;
};

export const getPage = (questions = [], active) => {
  if (active === 0) {
    return QuestionPage;
  }

  if (active === 1 && questions.length > 0) {
    return QuestionPage;
  }

  return LastPage;
};

export const getEmployerQuestions = (employers, values) => {
  if (values.employerId === OTHER_EMPLOYER_ID) {
    return [
      {
        key: 'otherEmployerName',
        placeholder: 'Enter name',
        required: true,
      },
    ];
  }

  const employer = findEmployerById(employers, values.employerId);
  if (employer?.questions) {
    return employer.questions.filter((q) => {
      if (q.showWhen) {
        // Show question when form values match spec
        return R.any((spec) => R.whereEq(spec, values), q.showWhen);
      }
      return q;
    });
  }
  return [];
};

export const getFormTitle = (employer, values, page) => {
  if (page === 0) {
    return 'Who is your employer?';
  }

  if (employer?.password) {
    return 'Enter employer code';
  }

  return values.employerId === OTHER_EMPLOYER_ID
    ? 'What is the name of your employer?'
    : `Insurance benefits offered by ${employer?.name}`;
};
