import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  convertToNotificationDate,
  getItemsCreatedMoreThanTwoDaysAgo,
  getItemsCreatedToday,
  getItemsCreatedYesterday,
  getRelativeTime,
  getTimeOnly,
  trackNotificationViewAllClick,
} from 'helpers';
import styles from './styles/NotificationList.module.scss';
import { NOTIFICATIONS_ROUTE } from 'constants/routes';
import { IAppState, IGlobalNotificationModalInfo, INotification } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationState } from 'selectors';
import { SeenNotifications, UpdateNotification } from 'actions';
import { Loader } from 'semantic-ui-react';
import { getText, handleNotificationClick } from './utils';
import { INSURANCE_TYPES_ICONS_SVG_V2 } from 'constants/insurance';
import { Star } from 'react-feather';
import { TASK_TYPES } from 'constants/notification';

interface IProps {
  showAll?: boolean;
  handleClose?: () => void;
  setGlobalModalInfo: React.Dispatch<
    React.SetStateAction<IGlobalNotificationModalInfo>
  >;
}

interface INotificationItem extends INotification {
  description: string;
  showHide: boolean;
  title: string;
  subtitle?: string;
  period?: string;
  onClick: (notification: INotification) => void;
  hideNotification: (notification: INotification) => void;
}

interface INotificationItemList {
  notificationItems: INotification[];
  period: string;
  appState: IAppState;
  showAll: boolean;
  hideNotification: (notification: INotification) => void;
  clickNotification: (notification: INotification) => void;
}

interface INotificationsGroupedDate {
  notifications: INotification[];
  appState: IAppState;
  showAll: boolean;
  hideNotification: (notification: INotification) => void;
  clickNotification: (notification: INotification) => void;
}

const POPUP_MAX_COUNT = 5;

const NotificationItem = ({
  description,
  showHide,
  title,
  subtitle,
  period,
  onClick,
  hideNotification,
  ...notification
}: INotificationItem) => {
  const { id, clicked, startDate, createdAt, policyType } = notification;
  const Icon = INSURANCE_TYPES_ICONS_SVG_V2[policyType] || R.T;
  return (
    <div
      key={id}
      onClick={(e) => {
        e.stopPropagation();
        onClick(notification);
      }}
      className={classnames(styles.item, clicked && styles.clicked)}
    >
      <div className={styles.wrap}>
        <div className={styles.content}>
          {startDate ? (
            <Star className={styles.star} />
          ) : (
            <div className={styles.dot} />
          )}

          <div className={styles.text}>
            <div className={styles.subTextOne}>
              <b className={styles.title}>{title}</b>
              <div className={styles.subtitle}>{subtitle}</div>
            </div>
            <div className={styles.subTextTwo}>
              <Icon className={styles.icon} />
              <div className={styles.description}>
                {description ? description : ''}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.time}>
          <span className={styles.relative}>
            {period === 'Today' || period === 'Yesterday'
              ? getTimeOnly(startDate || createdAt)
              : getRelativeTime(startDate || createdAt)}
          </span>
          <span className={styles.longDate}>
            {period === 'Today' || period === 'Yesterday'
              ? getTimeOnly(startDate || createdAt)
              : convertToNotificationDate(startDate || createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
};

const NotificationItemList = ({
  notificationItems,
  period,
  appState,
  showAll,
  hideNotification,
  clickNotification,
}: INotificationItemList) => (
  <div>
    {notificationItems.length > 0 && (
      <div className={styles.header}>{period}</div>
    )}
    {notificationItems.map((notification) => {
      const titleDesc = getText(notification, appState);
      return (
        <NotificationItem
          key={notification.id}
          showHide={showAll}
          period={period}
          hideNotification={hideNotification}
          onClick={clickNotification}
          {...titleDesc}
          {...notification}
        />
      );
    })}
  </div>
);

const NotificationsGroupedDate = ({
  notifications,
  appState,
  clickNotification,
  hideNotification,
  showAll,
}: INotificationsGroupedDate) => {
  const todaysNotifications = getItemsCreatedToday(notifications);
  const yesterdaysNotifications = getItemsCreatedYesterday(notifications);
  const previousNotifications = getItemsCreatedMoreThanTwoDaysAgo(
    notifications,
  );

  return (
    <>
      <NotificationItemList
        notificationItems={todaysNotifications}
        period="Today"
        appState={appState}
        clickNotification={clickNotification}
        hideNotification={hideNotification}
        showAll={showAll}
      />

      <NotificationItemList
        notificationItems={yesterdaysNotifications}
        period="Yesterday"
        appState={appState}
        clickNotification={clickNotification}
        hideNotification={hideNotification}
        showAll={showAll}
      />

      <NotificationItemList
        notificationItems={previousNotifications}
        period="Previous"
        appState={appState}
        clickNotification={clickNotification}
        hideNotification={hideNotification}
        showAll={showAll}
      />
    </>
  );
};

const NotificationList = ({
  showAll,
  handleClose,
  setGlobalModalInfo,
}: IProps) => {
  const appState: IAppState = useSelector(R.identity);
  const { loading, notifications } = useSelector(selectNotificationState);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      const toSee = notifications.filter((n) => !n.seen);
      if (toSee.length > 0) {
        dispatch(SeenNotifications(toSee));
      }
    };
  }, [notifications]);

  const hideNotification = (notification: INotification) => {
    const hidden = R.assoc('hide', true, notification);
    dispatch(UpdateNotification(hidden));
  };

  const clickNotification = (notification: INotification) => {
    if (
      !notification.clicked &&
      notification.notificationType !== TASK_TYPES.GLOBAL_NOTIFICATION
    ) {
      const clicked = R.assoc('clicked', true, notification);
      dispatch(UpdateNotification(clicked));
    }
    handleNotificationClick(
      notification,
      dispatch,
      appState,
      setGlobalModalInfo,
    );
    if (handleClose) handleClose();
  };

  const notificationsToShow = showAll
    ? notifications
    : R.take(POPUP_MAX_COUNT, notifications);
  return (
    <div className={classnames(!showAll && styles.compact)}>
      <div className={styles.items}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <Loader className={styles.spinner} active={true} />
          </div>
        ) : (
          notificationsToShow.length === 0 && (
            <div className={styles.emptyItem}>No notifications</div>
          )
        )}
      </div>
      <NotificationsGroupedDate
        notifications={notificationsToShow}
        appState={appState}
        clickNotification={clickNotification}
        hideNotification={hideNotification}
        showAll={showAll}
      />
      {!showAll && notifications.length > POPUP_MAX_COUNT && (
        <Link
          className={styles.seeAll}
          to={NOTIFICATIONS_ROUTE}
          onClick={() => trackNotificationViewAllClick()}
        >
          <span>See all notifications</span>
        </Link>
      )}
    </div>
  );
};

export default NotificationList;
