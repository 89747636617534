import React from 'react';
import { useSelector } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';
import history from 'helpers/history';
import { IUser } from 'types';
import styles from './styles/navbar.scss';
import { NOTIFICATIONS_ROUTE } from 'constants/routes';
import Logo from 'components/logo';
import { NotificationPopup } from 'components/notification-list';
import { getPageName } from './utils';
import { isPremiumRoute } from 'helpers';
import ShoppingCart from 'components/shopping-cart';
import { selectShowShoppingCart } from 'selectors/adviserConnectSelectors';

interface INavBarProps {
  openMobile?: boolean;
  maximised: boolean;
  user: IUser;
  openMobileSidebar(): void;
}

interface INavTitleProps {
  className?: string;
  title?: string;
}

export const NavTitle = ({ className, title }: INavTitleProps) => {
  const location = useLocation();
  const name = title || getPageName(location.pathname);
  return (
    <div className={classnames(styles.title, className)}>
      <span>{name}</span>
      <div className={styles.bar} />
    </div>
  );
};

const NavBar = ({ openMobile, maximised, openMobileSidebar }: INavBarProps) => {
  const location = useLocation();
  const showCart = useSelector(selectShowShoppingCart);

  return (
    <Menu
      className={classnames(
        styles.navbar,
        maximised && styles.maximised,
        isPremiumRoute(location) && styles.premium,
      )}
      pointing={true}
      secondary={true}
    >
      <Menu.Item className={styles.hamburger} position="left">
        <div
          className={classnames(styles.burger, openMobile && styles.active)}
          onClick={openMobileSidebar}
          data-ignore-outside-clicks={true}
        />
      </Menu.Item>
      <Menu.Item as={Link} header={true} className={styles.brand} to="/">
        <Logo />
      </Menu.Item>
      <Menu.Item className={styles.hamburger} position="right">
        {showCart ? (
          <ShoppingCart />
        ) : (
          <NotificationPopup
            className={styles.mobileNotif}
            onOpen={() => history.push(NOTIFICATIONS_ROUTE)}
            isMobile={true}
          />
        )}
      </Menu.Item>
      <NavTitle />
      <Menu.Item className={styles.right} position="right">
        <NotificationPopup
          popupClassName={styles.notification}
          isMobile={false}
        />
        <ShoppingCart />
      </Menu.Item>
    </Menu>
  );
};

export default NavBar;
