import React, { useState, useEffect } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {
  getMarketScanReadyNotificationCount,
  getNotificationCount,
} from 'selectors';
import NotificationList from './NotificationList';
import styles from './styles/NotificationPopup.module.scss';
import { useLocation } from 'react-router-dom';
import { NOTIFICATIONS_ROUTE } from 'constants/routes';
import { trackNotificationClick } from 'helpers';
import { NewFeatureModal } from 'components/modals';
import { IGlobalNotificationModalInfo } from 'types';

interface IProps {
  className?: string;
  onOpen?(): void;
  isMobile: boolean;
  popupClassName?: string;
}

const NotificationPopup = ({
  className,
  onOpen,
  isMobile,
  popupClassName,
}: IProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const count = useSelector(getNotificationCount);
  const msReadyCount = useSelector(getMarketScanReadyNotificationCount);
  const handleOpen = () => {
    trackNotificationClick();
    setOpen(true);
  };

  const [
    globalModalInfo,
    setGlobalModalInfo,
  ] = useState<IGlobalNotificationModalInfo>();

  const handleClose = () => setOpen(false);

  const isOpenStyle = location.pathname === NOTIFICATIONS_ROUTE || open;

  useEffect(() => {
    if (msReadyCount > 0 && !isMobile) {
      setOpen(true);
    }
  }, [msReadyCount]);
  return (
    <>
      <NewFeatureModal
        globalModalInfo={globalModalInfo}
        setGlobalModalInfo={setGlobalModalInfo}
      />
      <Popup
        className={classnames(styles.popup, popupClassName)}
        trigger={
          <div
            className={classnames(
              styles.trigger,
              isOpenStyle && styles.open,
              className,
            )}
          >
            <Icon name="bell" />
            {count > 0 && <div className={styles.count} />}
          </div>
        }
        content={
          <NotificationList
            handleClose={handleClose}
            setGlobalModalInfo={setGlobalModalInfo}
          />
        }
        position="bottom right"
        onClose={handleClose}
        onOpen={onOpen || handleOpen}
        open={isMobile ? false : open}
        on="click"
      />
    </>
  );
};

export default NotificationPopup;
