import { useEffect, useRef } from 'react';
import { matchPath } from 'react-router-dom';
import {
  POLICY_CHECK_MAX_COUNT,
  POLICY_CHECK_INTERVAL,
} from 'constants/policy';
import {
  getPolicyFromRenewal,
  getPolicyProgressCount,
  getPolicyRenewalRoute,
  trackEvent,
} from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState, IPolicyRenewal } from 'types';
import { getPolicyRenewalById } from 'services';
import { UpdatePendingRenewalLoaded } from 'actions';
import { RENEWAL_ROUTE } from 'constants/routes';
import history from 'helpers/history';
import { PolicyUploadToast } from 'components/toasts';

const notify = (renewal: IPolicyRenewal, onClick) => {
  if (matchPath(window.location.pathname, RENEWAL_ROUTE)) {
    history.push({
      pathname: getPolicyRenewalRoute(renewal.sk),
      state: { renewal },
    });
  } else {
    PolicyUploadToast.renewalSuccess({ renewal, onClick });
  }
};

const useRenewalNotification = () => {
  const dispatch = useDispatch();
  const {
    policyRenewal: { pendingRenewals },
    policies: { policies },
  } = useSelector(R.pick(['policyRenewal', 'policies'])) as IAppState;

  // Keep track of notifications so we do not keep creating multiple intervals for the same policy
  const notifications = useRef({});

  const removeNotification = (id, intervalId, renewal) => {
    notifications.current = R.dissoc(id, notifications.current);
    clearInterval(intervalId);
    const updated = R.assoc('processed', true, renewal);
    dispatch(UpdatePendingRenewalLoaded(updated));
  };

  const addNotification = (id, intervalId) => {
    notifications.current = R.assoc(id, intervalId, notifications.current);
  };

  const onClick = (renewal: IPolicyRenewal) => {
    trackEvent('Web-App-Market-Scan-Renewal-Notification-Click', renewal);
    const policy = getPolicyFromRenewal(policies, renewal);
    if (policy) {
      history.push({
        pathname: getPolicyRenewalRoute(policy.id),
        state: { renewal },
      });
    }
  };

  useEffect(() => {
    if (pendingRenewals.length > 0) {
      pendingRenewals.forEach((renewal: IPolicyRenewal) => {
        const { id, processed, createdAt, completed } = renewal;
        const currentCount = getPolicyProgressCount(createdAt);
        const policyInterval = notifications.current[id];

        if (
          !completed &&
          !processed &&
          !policyInterval &&
          currentCount < POLICY_CHECK_MAX_COUNT
        ) {
          const interval = setInterval(async () => {
            const count = getPolicyProgressCount(createdAt);
            if (count >= POLICY_CHECK_MAX_COUNT) {
              // Change processed to true to hide it from policy list
              // when its over the limit
              removeNotification(id, interval, renewal);
              notify(renewal, onClick);
            } else {
              const { data } = await getPolicyRenewalById(
                renewal.id,
                renewal.sk,
              );
              if (data.processed) {
                notify(data, onClick);
                removeNotification(id, interval, data);
              }
            }
          }, POLICY_CHECK_INTERVAL);

          if (!policyInterval) {
            addNotification(id, interval);
          }
        }
      });
    }
  }, [pendingRenewals]);
};

export default useRenewalNotification;
