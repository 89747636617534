export const OCCUPATIONS = [
  'Abattoir Worker - (non-manual, supervisory only)',
  'Abattoir Worker - manual',
  'Accountant - Qualified',
  'Accountant - Unqualified',
  'Accounts Clerk',
  'Accounts Payable',
  'Accounts Receivable',
  'Actor/Actress',
  'Actuary',
  'Acupuncturist',
  'Adjuster - Fire',
  'Adjuster - Insurance',
  'Adjuster - Marine',
  'Administrative Roles',
  'Advertising - Executive',
  'Advertising - Other',
  'Advertising - Principal',
  'Aerial Erector - >10 metres',
  'Aerial Erector - ≤10 metres',
  'Aeronautical designer/draughtsperson',
  'Aeronautical engineer',
  'Agent - Employment',
  'Agent - Entertainment',
  'Agent - House/Land (employee)',
  'Agent - House/Land (principal)',
  'Agent - Insurance (employee)',
  'Agent - Insurance (principal)',
  'Agent - Machinery',
  'Agent - Mercantile/Repossession',
  'Agent - Stock/Station',
  'Agent - TAB',
  'Agricultural Contractor',
  'Agricultural Engineer',
  'Agricultural Machinery Operator',
  'Agricultural Scientist (less than 10% field work)',
  'Agronomist',
  'Air Conditioning Engineer',
  'Air Conditioning Installer',
  'Air Safety Investigator (no accident site visits)',
  'Air Safety Investigator (with accident site visits)',
  'Air Traffic Controller',
  'Aircraft Demonstrator (no aerobatics)',
  'Aircraft Designer',
  'Aircraft Draughtsperson',
  'Aircraft Engineer (flying)',
  'Aircraft Engineer (non-flying)',
  'Aircraft Maintenance (flying)',
  'Aircraft Maintenance (non-flying)',
  'Aircraft Mechanic (flying)',
  'Aircraft Mechanic (non-flying)',
  'Airforce - all ranks.  No special hazards',
  'Airforce - civilian staff - clerical',
  'Airforce - clerical staff (no-flying)',
  'Airport - Baggage Handler',
  'Airport - Check In Staff',
  'Airport - Cleaner',
  'Airport - Maintenance',
  'Airport - Manager',
  'Airport - Radio Operator',
  'Airport - Security',
  'Alarm - Installer/Repairer',
  'Ambulance Driver/Officer',
  'Ambulance Personnel',
  'Amusement Parlour/Centre - Proprietor',
  'Anaesthetist',
  'Analyst programmer',
  'Animal Breeder - Dangerous Animals',
  'Animal Breeder - Dogs',
  'Animal Breeder - Horses',
  'Animal Breeder - Other Domestic Animals',
  'Animal Groomer - Large Animals - horses etc',
  'Animal Groomer - Small Animals - cats, dogs etc',
  'Animal Trainer - dangerous animals',
  'Animal Trainer - domestic animals',
  'Announcer - contract',
  'Announcer - salaried and full time',
  'Antenna Erector - >10 metres',
  'Antenna Erector - ≤10 metres',
  'Anthropologist',
  'Antiques Dealer',
  'Antiques Restorer',
  'Apartment Manager - No Manual Duties',
  'Apartment Manager - Some Manual Duties',
  'Apiarist',
  'Arborist',
  'Archaeologist (no field trips)',
  'Archaeologist (with field trips)',
  'Architect',
  'Architectural Draughtsperson',
  'Archive Technician',
  'Archivist',
  'Armed Forces - all ranks.  No special hazards',
  'Armed Forces - civilian staff - clerical',
  'Armed Forces - clerical staff (no-flying)',
  'Art Dealer',
  'Art Gallery Curator',
  'Artist - Commercial',
  'Artist - Freelance',
  'Artist - Graphic',
  'Artist - Sculptor',
  'Asbestos Worker',
  'Asphalt Layer',
  'Assembly Line Worker',
  'Assessor - Fire',
  'Assessor - Insurance',
  'Assessor - Marine',
  'Astronomer',
  'Auctioneer - Livestock',
  'Auctioneer - Other',
  'Audiologist',
  'Auditor - Qualified',
  'Auditor - Unqualified',
  'Author',
  'Auto Electrician',
  'Auto Teller (ATM) Repairer',
  'Automotive Painter',
  'Automotive Repairer',
  'Aviation - Advertising Pilot',
  'Aviation - Aerial Photography',
  'Aviation - Aerial surveying - Pilot and Crew - >500 hours per annum',
  'Aviation - Aerial surveying - Pilot and Crew - ≤200 hours per annum',
  'Aviation - Aerial surveying - Pilot and Crew - 201 - 500 hours per annum',
  'Aviation - Airline crew - fixed wing',
  'Aviation - Airline pilot - fixed wing',
  'Aviation - Airline pilot/crew - rotary - >500 hours per annum',
  'Aviation - Airline pilot/crew - rotary - ≤200 hours per annum',
  'Aviation - Airline pilot/crew - rotary - 201 - 500 hours per annum',
  'Aviation - Commercial Pilot - >500 hours per annum',
  'Aviation - Commercial Pilot - ≤200 hours per annum',
  'Aviation - Commercial Pilot - 201 - 500 hours per annum',
  'Aviation - Crop Spraying pilot and crew',
  'Aviation - Emergency Services pilot and crew - >500 hours per annum',
  'Aviation - Emergency Services pilot and crew - ≤200 hours per annum',
  'Aviation - Emergency Services pilot and crew - 201 - 500 hours per annum',
  'Aviation - Inspection - >500 hours per annum',
  'Aviation - Inspection - ≤200 hours per annum',
  'Aviation - Inspection - 201 - 500 hours per annum',
  'Aviation - Instructor Airline',
  'Aviation - Instructor Club - >500 hours per annum',
  'Aviation - Instructor Club - ≤200 hours per annum',
  'Aviation - Instructor Club - 201 - 500 hours per annum',
  'Aviation - Non scheduled transport pilot - >500 hours per annum',
  'Aviation - Non scheduled transport pilot - ≤200 hours per annum',
  'Aviation - Non scheduled transport pilot - 201 - 500 hours per annum',
  'Aviation - Police force pilot - >500 hours per annum',
  'Aviation - Police force pilot - ≤200 hours per annum',
  'Aviation - Police force pilot - 201 - 500 hours per annum',
  'Backhoe, Bobcat Operator',
  'Bacteriologist',
  'Bailiff',
  'Baker',
  'Bakery Employees',
  'Bandsaw Operator',
  'Bank - Manager',
  'Bank - Security staff',
  'Bank - Teller, Clerk',
  'Barber',
  'Barrister',
  'Bartender',
  'Beautician',
  'Bed and Breakfast - Proprietor/Manager (no manual duties)',
  'Bed and Breakfast - Proprietor/Manager (some manual duties)',
  'Beekeeper',
  'Beneficiary - Domestic Purposes',
  'Beneficiary - Other',
  'Beneficiary - Sickness',
  'Beneficiary - Unemployment',
  'Bicycle dealer/repairer',
  'Billiard Saloon - Employee',
  'Billiard Saloon - Proprietor',
  'Biochemist',
  'Biologist - Marine',
  'Biologist - Not Marine',
  'Blacksmith',
  'Blaster',
  'Blood Bank Technologist',
  'Boarding House - Proprietor/Manager (no manual duties)',
  'Boarding House - Proprietor/Manager (some manual duties)',
  'Boat Builder',
  'Bodyguard',
  'Boiler - Inspector',
  'Boilermaker',
  'Bookbinder',
  'Bookkeeper',
  'Bookmaker',
  'Bookseller',
  'Bootmaker/repairer',
  'Botanist',
  'Bottle shop Attendant/Manager',
  'Bouncer',
  'Brazier',
  'Breadcarter',
  'Brewery - Admin/Clerical',
  'Brewery - Chemist',
  'Brewery - Head Brewer',
  'Brewery - Manager (no brewing)',
  'Brewery - Other',
  'Bricklayer',
  'Brickmaker',
  'Broker - Finance/Mortgage (other)',
  'Broker - Finance/Mortgage (principal)',
  'Broker - Foreign Exchange',
  'Broker - Fruit',
  'Broker - Insurance (other)',
  'Broker - Insurance (principal)',
  'Broker - Livestock',
  'Broker - Money/Wool',
  'Building Industry - Administrative',
  'Building Industry - Carpenter',
  'Building Industry - Decorator',
  'Building Industry - Electrician',
  'Building Industry - Foreman',
  'Building Industry - Manager (no manual duties)',
  'Building Industry - Manual Workers/Labourers',
  'Building Industry - Superintendent',
  'Bulldozer Operator - Other',
  'Bulldozer Operator - Residential Only',
  'Bus/Coach Driver',
  'Business Analyst',
  'Business Analyst - Senior',
  'Butcher - Meat Works',
  'Butcher - Retail',
  'Butler',
  'Buyer - Livestock',
  'Buyer - Not Livestock',
  'Cabin crew',
  'Cabinetmaker',
  'Shop Owner - less than 20% manual work',
  'Café Worker/Proprietor',
  'Call Centre Manager/Supervisor',
  'Call Centre Operator',
  'Camera Operator - location work including overseas/hazardous/aerial/underwater work',
  'Camera Operator - Some location work, but no overseas/hazardous/aerial/underwater work',
  'Camera Operator - Studio Only',
  'Camera Repairer',
  'Cannery Worker',
  'Captain - ship (NZ waters only)',
  'Captain - ship (outside NZ waters)',
  'Car detailer/groomer',
  'Car salesperson',
  'Car washer',
  'Car wrecker - other',
  'Car wrecker - Proprietor',
  'Caravan Park - other',
  'Caravan Park - Proprietor',
  'Cardiologist',
  'Caregiver',
  'Caretaker/Janitor',
  'Carpark Attendant',
  'Carpenter - Cabinetmaker/Joiner',
  'Carpenter - Qualified',
  'Carpenter - Unqualified/Apprentice',
  'Carpet Cleaner',
  'Carpet Layer',
  'Carpet Sales',
  'Cartage Contractor/Carrier - Local Metropolitan, no loading or unloading',
  'Cartage Contractor/Carrier - Long Distance',
  'Cartographer',
  'Cartoonist - freelance/contractor',
  'Cartoonist - salaried',
  'Cashier',
  'Casino - Bar staff',
  'Casino - Clerical',
  'Casino - Manager',
  'Casino - Table Operators',
  'Casino - Wait staff',
  'Caterer',
  'Catering - No Food Preparation',
  'Cattery - other',
  'Cattery - Proprietor/Manager',
  'Ceiling Fixer - qualified',
  'Cement and Lime worker',
  'Chainperson (surveying)',
  'Chartered Accountant',
  'Chauffeur',
  'Chef - Restaurant',
  'Chef - Shipping (NZ water)',
  'Chef - Shipping (outside NZ waters)',
  'Chef, Cook',
  'Chemical Engineer (no hazardous materials)',
  'Chemist - industrial (no hazardous tasks)',
  'Chemist - research and analytical (qualified)',
  'Chemist (handling acids, explosive, radioactive materials)',
  'Chemist/Pharmacist',
  'Child Care Worker',
  'Chimney Sweep',
  'China - skilled worker',
  'Chiropodist',
  'Chiropractor',
  'Choreographer',
  'Cinema - Management (admin only)',
  'Cinema - Projectionist',
  'Cinema - Theatre Admin Staff',
  'Cinema - Ticket Seller/Usher',
  'Civil Engineer - Degree qualified',
  'Civil Engineer - Diploma qualified, no manual work',
  'Civil Engineer - Diploma qualified, some manual work',
  'Cleaner - Car Detailer',
  'Cleaner - Window (over 10 metres)',
  'Cleaner - Window (up to 10 metres)',
  'Clergy',
  'Clerk (Office Work only)',
  'Climatologist',
  'Clothing Industry - Dressmaker',
  'Clothing Industry - Fashion Designer',
  'Clothing Industry - Pattern maker',
  'Clothing Industry - Salesperson (no deliveries)',
  'Clothing Industry - Tailor/Milliner/Cutter',
  'Club/Bar - Bar Staff',
  'Club/Bar - Bouncer',
  'Club/Bar - Proprietor/Manager (bar work)',
  'Club/Bar - Proprietor/Manager (no bar work)',
  'Coach - Junior Sports',
  'Coach - Professional/Senior Sports',
  'Coach Builder',
  'Coal Miner - Surface/Open Cast',
  'Coal Miner - Underground',
  'Coastguard',
  'Columnist',
  'Commentator - Freelance/Contractor',
  'Commentator - Salaried',
  'Commercial Traveller',
  'Composer - Music',
  'Compositor',
  'Computer - Analyst',
  'Computer - Consultant',
  'Computer - Keyboard Operator',
  'Computer - Programmer',
  'Computer - Senior Analyst',
  'Computer - Senior Consultant',
  'Computer - Senior Programmer',
  'Computer - Senior Technician',
  'Computer - Technician',
  'Concierge',
  'Concrete Contractor',
  'Concrete Cutter',
  'Conductor - Orchestra - Freelance/Contractor',
  'Conductor - Orchestra - Salaried',
  'Conservation Worker',
  'Consultant - Computer',
  'Consultant - Interior Design',
  'Consultant - Management',
  'Consultant - Public Relations',
  'Consultant - Recruitment',
  'Consultant - Senior Recruitment',
  'Contractor - Agricultural',
  'Contractor - Concrete',
  'Contractor - Lawnmowing',
  'Copy Editor',
  'Copywriter',
  'Coroner',
  'Cosmetologist',
  'Costume Designer',
  'Counsellor - Employment',
  'Counsellor - Other',
  'Counsellor - Vocational/Rehabilitation',
  'Courier - Motorcycle/Bicycle',
  'Courier - Not using Motorcycle/Bicycle',
  'Crane, Derrick or Hoist Driver',
  'Cray fisher',
  'Crematorium Worker',
  'Crop Duster',
  'Cruise Director',
  'Curator - Art Gallery, Library, Museum',
  'Curtain Installer',
  'Customs and Shipping Agent/Officer - clerical only',
  'Dairy Owner',
  'Dairy Scientist',
  'Dairy Worker',
  'Dancer',
  'Dancing Instructor',
  'Data Entry Operator',
  'Data Processing Consultant',
  'Dealer - Antiques',
  'Dealer - Art',
  'Dealer - Furniture',
  'Dealer - Hardware',
  'Dealer - Livestock',
  'Dean - University',
  'Debt Collector',
  'Deck Hand',
  'Decorator',
  'Decorator - Building',
  'Decorator - Consultant (clerical only)',
  'Deer - Shooting and Recovery',
  'Deer Culler',
  'Delicatessen - other',
  'Delicatessen - Proprietor',
  'Delivery Person -  Light Goods',
  'Delivery Person - Heavy Goods',
  'Demolition Worker - No Explosives',
  'Demolition Worker - Use of Explosives',
  'Dental Hygienist',
  'Dental Nurse/Hygienist',
  'Dental Surgeon',
  'Dentist',
  'Deputy Principal',
  'Dermatologist',
  'Designer - clerical only',
  'Designer - Consultant',
  'Designer - Interior/Exterior with manual work over 10 Metres',
  'Designer - Interior/Exterior with manual work up to 10 Metres',
  'Diamond/Gem - Cutter/Polisher/Setter',
  'Diamond/Gem - Dealer',
  'Die Maker',
  'Diesel Mechanic - qualified',
  'Diesel Mechanic - unqualified',
  'Dietician - Not Tertiary Qualified',
  'Dietician - Tertiary Qualified',
  'Diving - Coastal',
  'Diving - Deep Sea/Saturation',
  'Diving - Instructor',
  'Diving - Marine Harvesting',
  'DOC (Department of Conservation) Worker - admin only',
  'Docker/Waterside Worker',
  'Doctor',
  'Dog Breeder',
  'Dog Groomer',
  'Dog Ranger',
  'Drainlayer',
  'Draper',
  'Draughtsperson',
  'Dredge Operator / Dredger',
  'Dress Maker - Employee',
  'Dress Maker - Self Employed, Working from home',
  'Driller - Local waters only',
  'Driller - Offshore',
  'Driller - Onshore - Rock/Soil/Water',
  'Driver - Ambulance',
  'Driver - Chauffeur',
  'Driver - Crane',
  'Driver - Earthworks - residential only',
  'Driver - Forklift',
  'Driver - Hire Cars',
  'Driver - Instructor',
  'Driver - Milk Vendor',
  'Driver - Petrol',
  'Driver - Railway',
  'Driver - Rubbish (driving duties only)',
  'Driver - Rubbish (with other manual duties)',
  'Driver - Taxi',
  'Driver - Truck - Animal Transportation',
  'Driver - Truck - Any with loading and unloading duties',
  'Driver - Truck - Armoured (money transfer)',
  'Driver - Truck - Concrete (no manual duties)',
  'Driver - Truck - Concrete (with manual duties)',
  'Driver - Truck - Crane Trucks',
  'Driver - Truck - Dump/Quarry Truck Driver (no manual duties)',
  'Driver - Truck - Dump/Quarry Truck Driver (with manual duties)',
  'Driver - Truck - Furniture Removal',
  'Driver - Truck - General Haulage, local metropolitan areas, no loading/unloading',
  'Driver - Truck - Hazardous Materials',
  'Driver - Truck - Intercity, up to 400km/day',
  'Driver - Truck - Long Haul (over 400km/day)',
  'Driver - Truck - Milk Tanker',
  'Driver - Truck - Petrol',
  'Driver - Truck - Skip Bin Delivery/Pick Up (no manual duties)',
  'Driver - Truck - Skip Bin Delivery/Pick Up (with manual duties)',
  'Driver - Truck - Street Sweeping',
  'Driver - Truck - Tow Truck/Vehicle Recovery',
  'Drycleaner - Counter Attendant',
  'Drycleaner - others',
  'Dyer',
  'Earthmoving Contractor - Other',
  'Earthmoving Contractor - residential',
  'Economist',
  'Editor',
  'Education - Department Head',
  'Education - Deputy Headmaster/Headmistress',
  'Education - Headmaster/Headmistress',
  'Electrician - Business/Domestic',
  'Electrician - Power Lines',
  'Electroplater',
  'Elevator Repairperson',
  'Emergency Services - Ambulance',
  'Emergency Services - Fire',
  'Emergency Services - Police',
  'Employment Agent',
  'Endocrinologist',
  'Engineer - Aeronautical',
  'Engineer - Aerospace',
  'Engineer - Air Conditioning',
  'Engineer - Audio Control',
  'Engineer - Civil, degree qualified',
  'Engineer - Civil, diploma qualified, no manual duties',
  'Engineer - Civil, some manual duties',
  'Engineer - Computer, degree qualified',
  'Engineer - Computer, other',
  'Engineer - Degree Qualified',
  'Engineer - Diploma Qualified, no manual duties',
  'Engineer - Diploma Qualified, some manual duties',
  'Engineer - Electrical, degree qualified',
  'Engineer - Electrical, diploma qualified, no manual duties',
  'Engineer - Electrical, some manual duties',
  'Engineer - Marine',
  'Engineer - Mining (not underground)',
  'Engineer - Mining (underground)',
  'Engineer - Recording',
  'Engineer - Refrigeration',
  'Engineer - Transmission',
  'Engineer - Video-Control',
  'Engraver',
  'Entertainer',
  'Entomologist',
  'Estate Agent - Employee',
  'Estate Agent - Principal',
  'Estimator',
  'Executive Assistant',
  'Executive Secretary',
  'Explosives Worker',
  'Exporter',
  'Exterminator',
  'Factory Worker - other',
  'Factory Worker (non-manual, supervisory only)',
  'Farm - Manager',
  'Farm - Owner',
  'Farmer - Owner/Manager',
  'Farming Labourer',
  'Farrier',
  'Fashion Designer - Working from home',
  'Fencer/Fencing Contractor',
  'Ferry Crew',
  'Fibre Optics Technician',
  'Fibreglass Worker',
  'Film Industry - Actor/Actress',
  'Film Industry - Designer',
  'Film Industry - Director',
  'Film Industry - Editor',
  'Film Industry - Producer',
  'Film Industry - Projectionist',
  'Film Industry - Studio Camera Operator',
  'Film Industry - Technician',
  'Financial Adviser - other',
  'Financial Adviser - Principal',
  'Financial Consultant',
  'Firefighter - Airport',
  'Firefighter - Chief/Senior Officers - clerical duties only',
  'Firefighter - City',
  'Firefighter - Mining',
  'Firefighter - Oil and Gas',
  'Fish Farming - Fish Farmer',
  'Fish Farming - Hatchery Worker',
  'Fish Farming - Shellfish',
  'Fisherman/fisherwoman - 1 to 20 consecutive days',
  'Fisherman/fisherwoman - day fishing',
  'Fisherman/fisherwoman - over 20 consecutive days',
  'Fisherman/fisherwoman - Tourist day trips',
  'Fisherman/fisherwoman - Tourist overnight or multi day trips',
  'Fishmonger',
  'Fitter and Turner',
  'Flight Attendant',
  'Flooring Coverer/Layer/Sander',
  'Florist - shop and delivery duties',
  'Florist - shop duties only',
  'Food technologist',
  'Forest Ranger/Warden',
  'Forestry - Onsite with no manual duties',
  'Forestry - Tree Felling',
  'Forestry Scientist',
  'Foundry Worker',
  'Freezing Worker',
  'French Polisher',
  'Fruit Grower - other',
  'Fruit Grower - Proprietor',
  'Fruit Picker',
  'Fumigator',
  'Funds Manager',
  'Funeral Director - Embalming duties',
  'Funeral Director - No embalming duties',
  'Furnaceman/furnacewoman',
  'Furniture Dealer',
  'Furniture Polisher/Restorer',
  'Furniture Remover',
  'Furrier',
  'Garage Attendant',
  'Garage Manager - clerical duties only',
  'Garage Mechanic',
  'Garbage Collector - no manual duties',
  'Gardener',
  'Gardener - Lawn Mowing',
  'Gas Fitter',
  'Gas Industry - Pipelayer',
  'Gas Mechanic',
  'Gas Meter Reader',
  'Gas Tester',
  'Gastroenterologist',
  'General Practitioner',
  'Geneticist',
  'Geologist - Explosives/Underground Work',
  'Geologist - No Explosives/Underground Work',
  'Gibstopper',
  'Glass Industry - Beveller',
  'Glass Industry - Blower',
  'Glass Industry - Polisher',
  'Glass Industry - Sales Staff',
  'Glass Industry - Skilled Worker',
  'Glazier',
  'Gold Professional - Touring/Tournaments',
  'Goldsmith',
  'Golf Professional - Shop and Tuition only',
  'Grain Elevator Worker',
  'Graphic Artist/Designer',
  'Grave Digger',
  'Greenkeeper/Groundsman',
  'Greenkeeper/Groundsman - Supervisor',
  'Grocer/Greengrocer',
  'Groomer - Car',
  'Groomer - Large Animals/Horses',
  'Groomer - Small Animals/Dogs/Cats',
  'Guard - Security',
  'Guidance Counsellor',
  'Gunsmith',
  'Gymnasium - Administrator',
  'Gymnasium - Personal Trainer (qualified)',
  'Gymnasium - Personal Trainer (unqualified)',
  'Gynaecologist',
  'Haematologist',
  'Hairdresser',
  'Handyman',
  'Hardware Dealer',
  'Herbalist',
  'Homemaker/Househusband/Housewife',
  'Homeopath',
  'Horse Racing and Trotting - Breeder (no riding)',
  'Horse Racing and Trotting - Breeder (riding duties)',
  'Horse Racing and Trotting - Jockey',
  'Horse Racing and Trotting - Riding Instructor',
  'Horse Racing and Trotting - Strapper',
  'Horse Racing and Trotting - Trainer (no riding)',
  'Horse Racing and Trotting - Trainer (riding duties)',
  'Horse Riding Instructor',
  'Horticulturalist',
  'Hospital Staff -  Maintenance',
  'Hospital Staff - Administration',
  'Hospital Staff - Doctor',
  'Hospital Staff - Healthcare Assistant',
  'Hospital Staff - Manager (clerical duties only)',
  'Hospital Staff - Nurse',
  'Hospital Staff - Orderly',
  'Hospital Staff - Porter',
  'Hospital Staff - Psychiatric Nurse',
  'Hospital Staff - Radiologist',
  'Hospital Staff - Registrar',
  'Hospital Staff - Wardperson',
  'Hotel/Motel - Duty Supervisors',
  'Hotel/Motel - Maid/Cleaner',
  'Hotel/Motel - Maintenance',
  'Hotel/Motel - Office',
  'Hotel/Motel - Other',
  'Hotel/Motel - Proprietor/Manager',
  'Hotel/Motel - Receptionist/Clerical',
  'Hotel/Motel - Wait Staff/Room Service',
  'House Removalist',
  'Housekeeper',
  'Human Resources/Personnel Manager',
  'Hypnotherapist',
  'Illustrator - Freelance',
  'illustrator - Salaried',
  'Immigration Officer',
  'Importer',
  'Industrial Chemist - No hazardous materials',
  'Inspector - Beach',
  'Inspector - Boiler',
  'Inspector - Building',
  'Inspector - Gas',
  'Inspector - Health Records',
  'Inspector - Hull',
  'Inspector - Hygiene',
  'Inspector - Insurance',
  'Inspector - Occupational Safety and Health (OSH)',
  'Installer',
  'Instrument Maker',
  'Instrument Maker - Musical',
  'Instrument Maker - Precision/Surgical',
  'Instrument Maker - Technician',
  'Insulation Installer',
  'Insurance Industry - Actuary',
  'Insurance Industry - Adjuster',
  'Insurance Industry - Broker Principal',
  'Insurance Industry - Broker/Agent',
  'insurance Industry - Clerical Staff',
  'Insurance Industry - Manager/Executive',
  'Insurance Industry - Underwriter (Junior)',
  'Insurance Industry - Underwriter (Senior)',
  'Interior Decorator - Consultant',
  'Interpreter',
  'Investigator',
  'Investment Analyst/Adviser',
  'Janitor',
  'Jeweller - Manufacturing',
  'Jeweller - Retail',
  'Joiner',
  'Journalist',
  'Journalist - Hazardous Locations',
  'Judge',
  'Kennel Operator',
  'Key Cutter',
  'Keyboard Operator/Data Entry',
  'Kiln Worker',
  'Kitchen Worker',
  'Laboratory Technician',
  'Labourer - Unskilled',
  'Land Surveyor',
  'Landscape Gardener',
  'Lathe Operator',
  'Laundry Attendant',
  'Lawyer',
  'Lecturer',
  'Legal Assistant',
  'Legal Clerk',
  'Librarian',
  'Lifeguard',
  'Lighting Designer',
  'Linesman - No overhead work',
  'Linesman - Overhead work',
  'Linotype Operator',
  'Liquor Trade - Commercial Sales',
  'Liquor Trade - Retail Sales',
  'Lithographer',
  'Livestock Broker/Buyer/Dealer',
  'Locksmith',
  'Logging Industry - Drivers',
  'Logging Industry - Manual Workers - No Tree Felling',
  'Logging Industry - Manual Workers - Tree Felling',
  'Logging Industry - Office Based Workers (clerical only)',
  'Loss Adjuster',
  'Machinery - Hire Service/Agent',
  'Machinery Retailer',
  'Machinist',
  'Magistrate',
  'Maid',
  'Mail Contractor',
  "Maître D'",
  'Make Up Artist',
  'Management Consultant',
'Manicurist',
'Marine Biologist - Field work, no diving activities',
'Marine Biologist - Field work, with diving activities',
'Marine Biologist - Laboratory based only',
'Marine Engineer',
'Marine Industry - Ferry Captain/Officer',
'Marine industry - Ferry Crew (local waters only)',
'Marine Industry - Ocean going vessel officer (local waters)',
'Marine Industry - Ocean going vessel officer (outside local waters)',
'Marine Industry - Shipyard Worker (skilled)',
'Marine Industry - Shipyard Worker (unskilled)',
'Marine Industry - Shore Personnel - clerical only',
'Market Gardener',
'Massage Therapist / Masseur / Masseuse - qualified, employee, and not working from home',
'Massage Therapist / Masseur / Masseuse - self employed or working from home',
'Mathematician',
'Meat Packer',
'Mechanic',
'Medical Laboratory Technician',
'Merchandiser',
'Merchant Banker',
'Merchant Marine Pilot',
'Metal Industry - Molten Worker',
'Metal Industry - Non-Molten Worker',
'Metallurgist - Any underground work',
'Metallurgist - No underground work',
'Meteorologist',
'Meter Reader (Gas/Electricity)',
'Microbiologist',
'Midwife',
'Milk Vendor',
'Mining Industry - Clerical',
'Mining Industry - Geologist',
'Mining Industry - Inspector',
'Mining Industry - Management',
'Mining Industry - Skilled Worker, Surface',
'Mining Industry - Underground',
'Mining Industry - Unskilled Worker, Surface',
'Mining Industry - Worker with explosives use',
'Minister of Religion',
'Model',
'Mortician',
'Motor Industry - Assembly/Production Line Workers',
'Motor Industry - Clerical',
'Motor Industry - Foreman/Forewoman',
'Motor Industry - Management',
'Motor Industry - Others',
'Motor Industry - Tester',
'Motor Trade - Dealer',
'Motor Trade - Groomer',
'Motor Trade - Manager',
'Motor Trade - Owner',
'Motor Trade - Sales',
'Motor Wrecker - clerical only',
'Motor Wrecker - other',
'Motor Wrecker - Proprietor',
'Music Teacher - qualified, not working from home',
'Music Teacher - qualified, working from home',
'Music Teacher - unqualified',
'Musician - other',
'Musician - salaried',
'Musterer',
'Nanny',
'Natural Gas - Clerical',
'Natural Gas - Diving',
'Natural Gas - Drilling Rigs',
'Natural Gas - Explosives Worker',
'Natural Gas - Management',
'Natural Gas - Refining (clerical only)',
'Natural Gas - Skilled Worker',
'Natural Gas - Underground',
'Natural Gas - Unskilled Worker',
'Naturopath',
'Navy',
'Neurologist',
'Newsagent',
'Newspaper - Compositor/Lithographer',
'Newspaper - Editor',
'Newspaper - Journalist',
'Newspaper - Office Worker',
'Newspaper - Photographer',
'Night Watchman/Watchwoman',
'Nurse - General',
'Nurse - Geriatric',
'Nurse - Midwife',
'Nurse - Primarily clerical duties e.g. matron, Director of Nursing',
'Nurse - Psychiatric',
'Nurse Aide/Assistant',
'Nursery - Proprietor/Manager',
'Nursery - Worker',
'Nutritionist',
'Obstetrician',
'Occupation Not Listed - Referred to Underwriting',
'Occupational Therapist',
'Office Machine Operator',
'Office Worker',
'Oil. Petroleum and Gas Industry - Clerical Workers',
'Oil. Petroleum and Gas Industry - Divers',
'Oil. Petroleum and Gas Industry - Drilling Rigs',
'Oil. Petroleum and Gas Industry - Offshore - Clerical Duties Only',
'Oil. Petroleum and Gas Industry - Offshore - Derrickmen',
'Oil. Petroleum and Gas Industry - Offshore - Labourer',
'Oil. Petroleum and Gas Industry - Offshore -Driller',
'Oil. Petroleum and Gas Industry - Onshore - Crane Driver',
'Oil. Petroleum and Gas Industry - Onshore - Derrickmen',
'Oil. Petroleum and Gas Industry - Onshore - Driller',
'Oil. Petroleum and Gas Industry - Onshore - Labourer',
'Oil. Petroleum and Gas Industry - Onshore - Machine Operator',
'Oil. Petroleum and Gas Industry - Pipeline worker (surface only, skilled)',
'Oil. Petroleum and Gas Industry - Refinery - Firefighter',
'Oil. Petroleum and Gas Industry - Refinery - Skilled worker, no rigging',
'Oil. Petroleum and Gas Industry - Refinery - Unskilled worker or rigging duties',
'Oil. Petroleum and Gas Industry - Skilled Workers',
'Oil. Petroleum and Gas Industry - Tanker Driver',
'Oil. Petroleum and Gas Industry - Trench Digger',
'Oil. Petroleum and Gas Industry - Underground Workers, no explosives',
'Oil. Petroleum and Gas Industry - Unskilled Workers',
'Oil. Petroleum and Gas Industry - Welder (no rigs or heights)',
'Oil. Petroleum and Gas Industry - Welder (rigs or heights)',
'Operator - Power Plant',
'Operator - Pressing Machine (apparel)',
'Operator - Printing Press',
'Operator - Sewage/Wastewater/Water Treatment - clerical only',
'Operator - Sewage/Wastewater/Water Treatment - manual duties',
'Operator - Sewing Machine',
'Operator - Switchboard',
'Operator - Telephone',
'Operator - Textile Machinery',
'Operator - Transmitter',
'Ophthalmologist',
'Optical Technician',
'Optician',
'Optometrist',
'Orchard - Fruit Picker/Orchard Worker',
'Orchardist - Proprietor',
'Orthodontist',
'Orthopaedist',
'Osteopath',
'Packer',
'Paediatrician',
'Painter',
'Panelbeater',
'Paperhanger',
'Paramedic',
'Parking Building Attendant',
'Parole Officer',
'Pastry Cook',
'Pathologist',
'Pathology Analyst',
'Pattern Maker',
'Paver',
'Pawnbroker',
'Payroll Clerk',
'Payroll Guards',
'Personal Trainer',
'Personnel Consultant',
'Pest Controller/Exterminator',
'Petrol Station Attendant',
'Pharmacist',
'Phlebotomist',
'Photo Engraver',
'Photographer - Location (no overseas, aerial or underwater work)',
'Photographer - Studio',
'Physician',
'Physicist',
'Physiotherapist',
'Piano Tuner',
'Picture Framer',
'Pilot - Harbour',
'Pipeline Worker - Surface only, skilled',
'Pipeline Worker - Unskilled or Underground',
'Plant Operator',
'Plasterer',
'Plastic Surgeon',
'Plumber',
'Podiatrist',
'Police - Armed Offenders Squad',
'Police - Bomb Disposal',
'Policeman/Policewoman',
'Pool Attendant',
'Porter',
'Postal - Delivery (Van)',
'Postal - Manager/Clerical',
'Postal - Postman/Postwoman',
'Postal - Sorter',
'Pottery - Skilled worker',
'Pottery - Unskilled Worker',
'Priest',
'Principal - School',
'Printer',
'Printer - clerical only',
'Printer - Skilled Manual (e.g. engraver, linotype operator)',
'Prison - Administrator',
'Prison - Governor',
'Prison - Guard',
'Prison - Warden',
'Private Investigator',
'Probation Officer',
'Process Worker',
'Professional Sportsperson',
'Professor',
'Programmer - Computer',
'Programmer - Television',
'Projectionist',
'Proof Reader',
'Property Industry - Consultant',
'Property Industry - Developer',
'Property Industry - Investor',
'Psychiatrist',
'Psychologist',
'Public Notary',
'Public Relations Consultant',
'Publican',
'Publisher',
'Purchasing Officer',
'Quantity Surveyor',
'Quarry Worker',
'Radio & Television Manufacturing - Repairman/Repairwoman',
'Radio & Television Manufacturing - Skilled Worker',
'Radio & Television Manufacturing - Unskilled Worker',
'Radio, Film & Television Industry - Announcer - not salaried or permanent position',
'Radio, Film & Television Industry - Announcer - salaried and permanent position',
'Radio, Film & Television Industry - Art Director - not salaried or permanent position',
'Radio, Film & Television Industry - Art Director - salaried and permanent position',
'Radio, Film & Television Industry - Cameraperson',
'Radio, Film & Television Industry - Director - not salaried or permanent position',
'Radio, Film & Television Industry - DJ/Presenter',
'Radio, Film & Television Industry - Producer - not salaried or permanent position',
'Radio, Film & Television Industry - Producer - salaried and permanent position',
'Radio, Film & Television Industry - Production Assistant',
'Radio, Film & Television Industry - Reporter (not overseas)',
'Radio, Film & Television Industry - Reporter (overseas)',
'Radio, Film & Television Industry - Skilled Worker',
'Radio, Film & Television Industry - Technician',
'Radio, Film & Television Industry - Video Editor',
'Radio, Film & Television Industry -Director - salaried and permanent position',
'Radiographer',
'Radiologist',
'Railway Industry - Clerical',
'Railway Industry - Driver',
'Railway Industry - Electrical (no overhead work)',
'Railway Industry - Electrical (overhead work over 10 metres)',
'Railway Industry - Electrical (overhead work up to 10 metres)',
'Railway Industry - Foreman/Inspector (clerical only)',
'Railway Industry - Guard',
'Railway Industry - Maintenance',
'Railway Industry - Shunter',
'Ranger (Forest/National Parks)',
'Real Estate - Agent',
'Real Estate - Auctioneer',
'Real Estate - Employee',
'Real Estate - Principal',
'Receptionist',
'Refrigeration Engineer',
'Removalist - Local',
'Removalist - Long Distance',
'Repairer/Service Technician - Heavy Manual',
'Repairer/Service Technician - Light Manual',
'Rest Home -  Owner/Manager',
'Rest Home - Carer',
'Rest Home - Clerical',
'Restaurant Staff - Administration',
'Restaurant Staff - Bar staff',
'Restaurant Staff - Cashier',
'Restaurant Staff - Cook',
'Restaurant Staff - Principal',
'Restaurant Staff - Waiter/Waitress',
'Retired',
'Reverend',
'Rigger',
'Road Worker',
'Roofer - Over 10 Metres',
'Roofer - Up to 10 Metres',
'Saddler',
'Sail Maker',
'Salesperson - Bikes',
'Salesperson - Hardware',
'Salesperson - Heavy Goods',
'Salesperson - Light Goods',
'Salesperson - Representative (no deliveries)',
'Sandblaster',
'Sawmill - Clerical only',
'Sawmill - Other',
'Scaffolder - Over 10 metres',
'Scaffolder - Up to 10 metres',
'Scientist',
'Scrapmetal Worker/Dealer',
'Seamstress',
'Search and Rescue - Paid',
'Second-hand Dealer - Heavy Goods e.g. Machinery',
'Second-hand Dealer - Light Goods e.g. Clothing',
'Secretary',
'Security Guard',
'Seismologist - Field work',
'Seismologist - No field work',
'Senior Executive/Manager',
'Service Station Attendant',
'Sharebroker',
'Sharemilker',
'Shearer/Shearing Contractor',
'Sheetmetal Worker',
'Shipping/Merchant Marine',
'Shipyard - Clerical',
'Shipyard - Management',
'Shipyard - Skilled',
'Shipyard - Unskilled',
'Shoemaker/Repairer',
'Shopfitter',
'Signwriter',
'Singer',
'Slaughterman/Slaughterwoman',
'Social Worker',
'Solderer',
'Solicitor',
'Sonographer',
'Soundmixer',
'Speech Therapist',
'Sports Centre - Clerical',
'Sports Centre - Coach (amateur sports)',
'Spraypainter',
'Spraypainter - Proprietor',
'Statistician',
'Steel Erector - Over 10 metres',
'Steel Erector - Up to 10 metres',
'Steeplejack',
'Stenographer',
'Stevedore',
'Stockbroker',
'Stonemason',
'Storeman/Storewoman',
'Student',
'Stuntman/Stuntwoman',
'Supermarket - Cashier',
'Supermarket - Manager',
'Supermarket - Night Fill',
'Supermarket - Other',
'Surgeon',
'Surveyor',
'Surveyor - Marine',
'Swimming Instructor',
'Swimming Pool Attendant',
'Swimming Pool Manager - clerical only',
'Systems Analyst',
'TAB Manager/Cashier',
'Tailor',
'Takeaways Proprietor',
'Takeaways Worker',
'Tanner',
'Tattooist/Tattoo Artist',
'Tax Consultant',
'Taxi Driver',
'Taxidermist',
'Teacher - Early Childhood',
'Teacher - School',
'Telecommunications Technician - Heights over 10 metres',
'Telecommunications Technician - Heights up to 10 metres',
'Telemarketer',
'Telephonist',
'Textile Worker',
'Theatre Industry - Cleaner',
'Theatre Industry - Manager',
'Theatre Industry - Projectionist',
'Theatre Industry - Sales Staff',
'Theatre Industry - Ticket Seller',
'Theatre Industry - Usher',
'Tiler - Floor',
'Tiler - Roof (over 10 metres)',
'Tiler - Roof (up to 10 metres)',
'Timber Merchant',
'Tobacconist',
'Tool Maker',
'Tour Guide - Hazardous activities',
'Tour Guide - No hazardous activities',
'Town Planner',
'Toxicologist',
'Traffic Officer',
'Train Driver',
'Travel Agent - Employee',
'Travel Agent - Principal',
'Trawler Captain - NZ Economic Exclusion Zone only',
'Trawler Captain - Outside NZ Economic Exclusion Zone',
'Trawlerman/Trawlerwoman - NZ Economic Exclusion zone only',
'Trawlerman/Trawlerwoman - Outside NZ Economic Exclusion zone',
'Tree Surgeon',
'Trotting Driver',
'Tugmaster',
'Tunneller/Shaft Worker',
'Tutor',
'Typist',
'Undertaker - Embalming duties',
'Undertaker - No embalming duties',
'Unemployed',
'Upholsterer',
'Urologist',
'Valuer - Livestock',
'Valuer - Other',
'Valuer - Property',
'Veterinary Nurse',
'Veterinary Surgeon - City Practice',
'Veterinary Surgeon - Country Practice',
'Viticulturalist',
'Wallpaper Hanger',
'Watchmaker/Repairer',
'Waterblaster',
'Weather Forecaster',
'Weaver',
'Web Developer',
'Web Tester',
'Webmaster',
'Welder - No Heights or Rigs',
'Welder - Rigs or Heights over 10 metres',
'Welder - Rigs or Heights up to 10 metres',
'Well Driller',
'Wharf Labourer',
'Window Cleaner - Heights over 10 metres',
'Window Cleaner - Heights up to 10 metres',
'Window Dresser',
'Window Tinter',
'Windscreen Fitter',
'Wine workers - Sales/Admin',
'Wine/Spirits Merchant',
'Winemaker',
'Wood Turner',
'Wool Broker - City',
'Wool Broker - Country',
'Wool classer',
'Wrecker - Building - Other',
'Wrecker - Building - Supervisor/Manager (clerical only)',
'Writer',
'X-Ray Technician',
'Yachtsperson',
'Zoo - Clerical',
'Zoo - Management',
'Zoo - Others',
'Zoo - Zookeeper - Petting Zoo/Non-wild animals',
'Zoo - Zookeeper - Wild animals',
'Zoologist',
'Dental Nurse',
'Electrician - Power Lines (no overhead work)',
'Property Manager',
'Horse Racing and Trotting - Stablehand (riding duties)',
'Horse Racing and Trotting - Stablehand (no riding duties)',
'Shop Manager (no manual duties)',
'Shop Manager (some manual duties)',
'Retail Assistant',
'Tyre Fitter/Retreader',
'Police Administration Staff (non-sworn)',
'Child',
'Garage Door Installer/Repairer',
'Lift or Escalator Installer/Repairer',
'Metal Fabricator',
'Cleaner - Commercial',
'Cleaner - Domestic',
'Vineyard Worker',
'Teacher Aide ',
'Fish Filleter/Fish Cutter (commercial)',
'Fish Filleter/Fish Cutter (retail)',
'Shop Owner - 10 hours or less manual work per week',
'Shop Owner - 11 hours or more manual work per week',
'Retail Owner - 10 hours or less manual work per week',
'Retail Owner - 11 hours or more manual work per week',
'Aluminium Joiner',
'Dairy Farm - Manager',
'Dairy Farm - Owner',
'Dairy Farming Labourer',
'Technician - Heavy Manual',
'Technician - Light Manual',
'Marketing/Sales Manager - no tertiary qualification',
'Marketing/Sales Manager - tertiary qualification',
'Marketing/Sales Executive',
'General Manager - 3 hours or less manual work per week',
'General Manager - 4 to 10 hours manual work per week',
'General Manager - 11 hours or more manual work per week',
'Project Manager - 10 hours or less manual work per week',
'Project Manager - 11 hours or more manual work per week',
'Manager - 10 hours or less manual work per week',
'Manager - 11 hours or more manual work per week',
'Engineer - Mechanical ',
'Quality Controller - 10 hours or less manual work per week',
'Quality Controller - 11 hours or more manual work per week',
'Logistics Manager - 10 hours or less manual work per week',
'Logistics Manager - 11 hours or more manual work per week',
'Logistics Coordinator - 10 hours or less manual work per week',
'Logistics Coordinator - 11 hours or more manual work per week',
'Finance Executive',
'Financial Controller',
'Clinical Support Worker',
'Chief Executive',
'Accounts Manager',
'IT - Analyst',
'IT - Consultant',
'IT - Keyboard Operator',
'IT - Programmer',
'IT - Senior Analyst',
'IT - Senior Consultant',
'IT - Senior Programmer',
'IT - Senior Technician',
'IT - Technician',
'IT - Support Desk',
'Computer - Support Desk',
'Consultant - Business (tertiary qualification)',
'Consultant - Business (no tertiary qualification)',
'Inspector - Motor Vehicles',
'Parks and Recreation Worker',
'Building  Maintenance',
'Sales Representative - 10 hours or less manual work per week',
'Sales Representative - 11 hours or more manual work per week',
'Customer/Client Service Representative - 10 hours or less manual work per week',
'Customer/Client Service Representative - 11 hours or more manual work per week',
'Adminstrator ',
'Credit Controller',
'Builder - Residential',
'Staff Trainer - 10 hours or less manual work per week',
'Staff Trainer - 11 hours or more manual work per week',
'Life Coach',
'Warehouse Person/Storeperson',
'Forestry - Log Harvesting',
'Learning and Development Specialist',
];

export const OCCUPATION_OPTIONS = OCCUPATIONS.map((o) => ({
  key: o,
  text: o,
  value: o,
}))