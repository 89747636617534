import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import {
  IFullAddress,
  IPolicy,
  IPolicyScan,
  IQuote,
  POLICY_SCAN_STATUS,
} from 'types';

declare const MARKETING_SITE_URL: string;

export const getAppBaseUrl = () => {
  const DEV_BASE_URL = 'https://dev-app.quashed.co.nz/';
  const TEST_BASE_URL = 'https://test-app.quashed.co.nz/';
  const PROD_BASE_URL = 'https://app.quashed.co.nz/';

  if (MARKETING_SITE_URL.includes('https://dev.quashed.co.nz')) {
    return DEV_BASE_URL;
  } else if (MARKETING_SITE_URL.includes('https://test.quashed.co.nz')) {
    return TEST_BASE_URL;
  } else {
    return PROD_BASE_URL;
  }
};

export const confirmAbandonedStatusHandled = (status: POLICY_SCAN_STATUS) => {
  if (status === POLICY_SCAN_STATUS.ABANDONED) {
    const confirmed = confirm(
      `Confirm 'I have sent an email to tell customer why the Market Scan was Abandoned' Click Ok to Confirm.`,
    );
    return confirmed;
  }
  return true;
};

export const confirmNoInvalidQuote = (quotes: IQuote[]) => {
  const candidate = findPossibleInvalidQuote(quotes);

  if (candidate) {
    const confirmed = confirm(
      `Are you sure the data for ${candidate.policyProviderName} is all correct? Click Cancel to check again. Click Ok to proceed.`,
    );
    return confirmed;
  }
  return true;
};

const findPossibleInvalidQuote = (quotes: IQuote[]) => {
  return quotes.find(possibleInvalid);
};

const possibleInvalid = (quote: IQuote) => {
  if (quote.yearly && quote.yearly > 2500) {
    return true;
  }

  if (quote.quarterly && quote.quarterly > 800) {
    return true;
  }

  if (quote.monthly && quote.monthly > 200) {
    return true;
  }

  if (quote.fortnightly && quote.fortnightly > 100) {
    return true;
  }

  if (quote.weekly && quote.weekly > 50) {
    return true;
  }

  if (quote.excess && quote.excess > 2000) {
    return true;
  }

  if (quote.amount && quote.amount > 200000) {
    return true;
  }

  if (
    quote.policyProviderName === 'Tower' ||
    quote.policyProviderName === 'Cove'
  ) {
    // tower and cove must have referral link
    return !quote.referral_link;
  }
};

export const renderBool = (val?: boolean): string =>
  val === undefined ? '-' : val ? 'Yes' : 'No';

export const numberToString = (val?: number): string =>
  val ? val.toString() : '-';

export const getPolicyDisplayKeys = (policy: IPolicy) =>
  Object.keys(
    R.omit(
      [
        'policyTypeId',
        'familyId',
        'createdBy',
        'policySubTypeId',
        'userId',
        'sk',
        'id',
        'policyProviderId',
        'data',
        'interestedPartys',
        'drivers',
        'vehicle',
        'fullAddress',
      ],
      policy,
    ),
  );

export const pushNewLifeInsured = (policy: IPolicy) => {
  const policyState = { ...policy };

  policyState.insured.push({
    name: '',
    order: policyState.insured.length,
    additionalCovers: [],
  });
  return policyState;
};

export const mapPolicyProviderOptions = (policyProviders) =>
  policyProviders.map((provider) => {
    return {
      key: provider.id,
      value: provider.id,
      text: provider.name,
    };
  });

export const isBrokerFirstPolicyScan = (policyScan: IPolicyScan) => {
  return (
    policyScan &&
    policyScan.brokerFormResults &&
    policyScan.brokerFormResults.brokerForm2
  );
};

export const getInterestedPartiesByName = (parties = []) => {
  return parties.map(R.prop('name')).join(', ');
};

export const getNewQuote = (): IQuote => {
  return {
    policyProviderId: '',
    policyProviderName: '',
    currentProvider: false,
    amount: 0,
    excess: 0,
  };
};

export const getInsuredAmountWithSpecialItems = (
  amount: number,
  items: Array<{ value: number }>,
) => {
  const itemsSum = items.reduce((sum, item) => {
    sum += item.value;
    return sum;
  }, 0);

  return amount + itemsSum;
};

export const isType = (policyType: string, insuranceType: string) =>
  policyType === insuranceType;

export const isHouseMS = (policyType: string) =>
  isType(policyType, INSURANCE_TYPES_NAMES.HOME);
export const isContentsMS = (policyType: string) =>
  isType(policyType, INSURANCE_TYPES_NAMES.CONTENT);
export const isVehicleMS = (policyType: string) =>
  isType(policyType, INSURANCE_TYPES_NAMES.VEHICLE);
export const isLandlordMS = (policyType: string) =>
  isType(policyType, INSURANCE_TYPES_NAMES.LANDLORD);

export const getAddressForPolicyScan = (
  candidate: string,
  fullAddress: IFullAddress,
  policy: IPolicy,
) => {
  let address = candidate;
  const addObj = fullAddress || policy.fullAddress;

  if (!address && address !== '' && addObj) {
    const { streetNo, flatNo, streetName, suburb, city, postCode } = addObj;
    address = `${streetNo} ${streetName}, ${suburb}, ${city}, ${postCode}`;
    if (flatNo) {
      address = `${flatNo}/${address}`;
    }
  }
  return address;
};

export const defaultKeysToShowForPolicyScansBasicTable = {
  policyType: { type: 'string' },
  'selectedProvider.policyProviderName': { type: 'string' },
  createdAt: { type: 'date' },
  status: { type: 'string' },
};

export const defaultKeysToShowForAdviserScanBasicTable = {
  createdAt: { type: 'date' },
  meetingStatus: { type: 'string' },
};

export const getPolicyScanInfo = ({
  amount,
  excess,
  frequency,
  premium,
  policy,
}: IPolicyScan) => {
  return {
    ...policy,
    amount: amount || policy.amount,
    excess: excess || policy.excess,
    frequency: frequency || policy.frequency,
    premium: premium || policy.premium,
  };
};

export const getPolicyScanDetailsUrlForOperation = (item: any) =>
  `/operations/policyScan/${item.id}/${item.sk}`;

export const getAdviserUserConnectUrlForOperation = (item: any) =>
  `/operations/adviserConnect/${item.id}/${item.sk}`;
