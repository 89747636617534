import React from 'react';
import classnames from 'classnames';
import styles from './status.scss';
import { ProgressSpinner } from 'components/progress';
import { HelpTooltip } from 'components/tooltip';

interface IStatusProps {
  text: string;
  className?: string;
  textClassName?: string;
  toolTipClassName?: string;
  tooltip?: string;
}

const Status = ({
  text,
  className,
  textClassName,
  toolTipClassName,
  tooltip,
}: IStatusProps) => (
  <div className={styles.container}>
    <div className={className} />
    <div className={classnames(styles.text, textClassName)}>
      <span>{text}</span>
    </div>
    {tooltip && (
      <HelpTooltip
        toggleClassName={classnames(styles.toggle, toolTipClassName)}
        content={tooltip}
        position="top center"
      />
    )}
  </div>
);

export const ActiveStatus = ({
  text,
  className,
  textClassName,
  toolTipClassName,
  tooltip,
}: IStatusProps) => (
  <Status
    text={text}
    className={classnames(styles.active, className)}
    toolTipClassName={toolTipClassName}
    textClassName={textClassName}
    tooltip={tooltip}
  />
);

export const ProcessingStatus = ({
  text,
  className,
  textClassName,
  tooltip,
}: IStatusProps) => (
  <Status
    text={text}
    className={classnames(styles.processing, className)}
    textClassName={textClassName}
    tooltip={tooltip}
  />
);

export const ErrorStatus = ({
  text,
  className,
  textClassName,
  tooltip,
}: IStatusProps) => (
  <Status
    text={text}
    className={classnames(styles.error, className)}
    textClassName={textClassName}
    tooltip={tooltip}
  />
);

export const DisabledStatus = ({
  text,
  className,
  textClassName,
  tooltip,
}: IStatusProps) => (
  <Status
    text={text}
    className={classnames(styles.disabled, className)}
    textClassName={textClassName}
    tooltip={tooltip}
  />
);

export const ProgressStatus = ({
  progress = 0,
  tooltip,
  text = 'Processing',
}) => {
  return (
    <div className={styles.progress}>
      <ProgressSpinner className={styles.spinner} progress={progress || 10} />
      <div className={styles.progressText}>{text}</div>
      {tooltip && (
        <HelpTooltip
          toggleClassName={styles.toggle}
          content={tooltip}
          position="top center"
        />
      )}
    </div>
  );
};

export const WarningStatus = ({
  text,
  className,
  textClassName,
  toolTipClassName,
  tooltip,
}: IStatusProps) => (
  <Status
    text={text}
    className={classnames(styles.warning, className)}
    textClassName={textClassName}
    toolTipClassName={toolTipClassName}
    tooltip={tooltip}
  />
);
