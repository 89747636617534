import { trackEventWithData } from 'helpers';
import React, { ReactNode, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './styles/WarningMessage.module.scss';

interface IProps {
  className?: string;
  eventData?: any;
  eventName?: string;
  text: ReactNode;
}

const WarningMessage = ({ className, eventData, eventName, text }: IProps) => {
  useEffect(() => {
    if (eventName) {
      trackEventWithData(eventName, eventData);
    }
  }, []);

  return (
    <div className={classnames(styles.container, className)}>
      <Icon name="exclamation circle" />
      <p>{text}</p>
    </div>
  );
};

export default WarningMessage;
