import { useEffect } from 'react';
import queryString from 'query-string';
import { base64ToString } from 'helpers';
import history from 'helpers/history';
import { IUser } from 'types';
import { UserEmployment } from 'constants/user';
import { WORKPLACE_ROUTE } from 'constants/routes';

interface IProps {
  user: IUser;
  processReferral(referralId): void;
  updateUser(user: IUser): void;
}

const processReferralId = (referralId, processReferral) => {
  const { pathname } = window.location;
  const id = base64ToString(referralId);
  processReferral(id);
  const pathWithoutId = pathname.replace(/referralId(=[^&]*)(&?)/g, '');
  history.replace(pathWithoutId); 
};

const processQ4e = (id, user, updateUser) => {
  if (!user.employerId) {
    updateUser({
      ...user,
      employerId: id,
      employment: UserEmployment.EMPLOYED
    });
  }
};

// Process url queries such as referral ID
const useProcessUrlQuery = ({ user, processReferral, updateUser }: IProps) => {
  useEffect(() => {
    if (user.id) {
      const { pathname } = window.location;
      const parsed = queryString.parse(window.location.search);
      if (parsed.referralId) {
        processReferralId(parsed.referralId, processReferral);
      } else if (parsed.id && pathname === WORKPLACE_ROUTE) {
        processQ4e(parsed.id, user, updateUser);
      }
    }
  }, [user.id]);
};

export default useProcessUrlQuery;
