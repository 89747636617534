import { connect } from 'react-redux';
import queryString from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import { IAppState, IPolicyScan } from 'types';
import { useEffect } from 'react';

import {
  VerifyWindcavePayment,
  GetNewPurchaseUrlBySessionId,
} from 'actions/payment-actions';

import {
  IUpdatePolicyScanLoaded,
  LoadPolicyByIdAndSelect,
  UpdatePolicyScanLoaded,
} from 'actions';
import { Loader } from 'semantic-ui-react';
import { WindcaveCallbackFailed } from './windcaveCallbackFailed';
import WindcaveCallbackSuccess from './WindcaveCallbackSuccess';
import history from 'helpers/history';
import { MARKET_SCAN_RESULTS_ROUTE } from 'constants/routes';

enum WINDCAVE_PAYMENT_STATUS {
  fail = 'fail',
  success = 'success',
  cancel = 'cancel',
}
interface IDispatchProps {
  getNewPurchaseUrlBySessionId: (sessionId: string) => void;
  updatePolicyScanLoaded: (data: IPolicyScan) => IUpdatePolicyScanLoaded;
  loadPolicyByIdAndSelect: (policyId: any) => void;
  verifyWindcavePayment(sessionId: string): void;
}

const WindcaveCallback = ({
  payment,
  referenceData,
  verifyWindcavePayment,
  loadPolicyByIdAndSelect,
  getNewPurchaseUrlBySessionId,
  updatePolicyScanLoaded,
}: IAppState & IDispatchProps) => {
  const { status } = useParams<{ policyProvider: string; status: string }>();
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  const policyProviderData = referenceData.policyProviders.find(
    R.propEq('name', payment.windcavePaymentResult?.policyProviderName),
  );

  useEffect(() => {
    verifyWindcavePayment(parsed.sessionId as string);
  }, []);

  const handleViewNewPolicy = () => {
    loadPolicyByIdAndSelect(payment.windcavePaymentResult.sk);
  };

  if (
    status === WINDCAVE_PAYMENT_STATUS.cancel &&
    payment.windcavePaymentResult
  ) {
    const { policyId, policyScanId } = payment.windcavePaymentResult;
    const policyScanUrl = `${MARKET_SCAN_RESULTS_ROUTE}?id=${policyScanId}&sk=${policyId}`;
    history.push(policyScanUrl);
  }

  return (
    <>
      {payment.loading && <Loader active={true} inline="centered" />}
      {!payment.loading &&
        (status === WINDCAVE_PAYMENT_STATUS.fail || payment.error) &&
        payment.windcavePaymentResult && (
          <WindcaveCallbackFailed
            handleRetry={getNewPurchaseUrlBySessionId}
            sessionId={parsed.sessionId as string}
          />
        )}

      {!payment.loading &&
        status === WINDCAVE_PAYMENT_STATUS.success &&
        payment.windcavePaymentResult &&
        payment.windcavePaymentResult.paymentCompleted && (
          <WindcaveCallbackSuccess
            payment={payment}
            handleViewNewPolicy={handleViewNewPolicy}
            updatePolicyScanLoaded={updatePolicyScanLoaded}
            policyProvider={policyProviderData}
          />
        )}
    </>
  );
};

const mapDispatchToProps = {
  verifyWindcavePayment: VerifyWindcavePayment,
  loadPolicyByIdAndSelect: LoadPolicyByIdAndSelect,
  getNewPurchaseUrlBySessionId: GetNewPurchaseUrlBySessionId,
  updatePolicyScanLoaded: UpdatePolicyScanLoaded,
};
const mapStateToProps = (state: IAppState) => ({
  ...state,
});

export default connect<IAppState>(
  mapStateToProps,
  mapDispatchToProps,
)(WindcaveCallback);
