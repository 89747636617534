import React, { useCallback, useState } from 'react';
import { Checkbox, Modal } from 'semantic-ui-react';
import Button from 'components/button';
import styles from './styles/adviserTermsModal.scss';

declare const MARKETING_SITE_URL: string;

const header = (
  <div className={styles.header}>
    <div className={styles.brand}>Quashed</div>
    <div className={styles.title}>Before we finish setting up your account</div>
  </div>
);

const fsprLabel = (
  <label>You are registered on the <a target="_blank" href="https://fsp-register.companiesoffice.govt.nz/">Financial Service Providers Register</a></label>
);

const termsAndPrivacyLabel = (
  <label>
    You have read and accept the <a target="_blank" href={`${MARKETING_SITE_URL}/terms`}>Terms & Conditions</a> and the <a target="_blank" href={`${MARKETING_SITE_URL}/privacy`}>Privacy Policy</a>
  </label>
);

const correctInfoLabel = "The information provided in this application for an Adviser Account is current, accurate and complete and not misleading in any way";

const defaultState = {
  fspr: false,
  termsAndPrivacy: false,
  correctInfo: false,
};

const enableSubmit = (form) => {
  return !Object.keys(form).find((key) => !form[key]);
};

const AdviserTermsModal = ({ open, onSubmit }) => {
  const [state, setState] = useState(defaultState);

  const handleCheckboxChange = useCallback(
    (_, data) => setState((prev) => ({
      ...prev, [data.name]: data.checked 
    })),
    [],
  );

  const handleSubmit = useCallback(
    () => {
      if (enableSubmit(state)) {
        onSubmit(state);
      }
    },
    [state, onSubmit],
  );

  return (
    <Modal
      className={styles.modal}
      size="large"
      open={open}
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Content>
        {header}
        <iframe className={styles.iframe} src={`${MARKETING_SITE_URL}/terms/q4a`} />
        <div className={styles.checkboxes}>
          <Checkbox
            label={fsprLabel}
            name="fspr"
            onChange={handleCheckboxChange}
          />
          <Checkbox
            label={termsAndPrivacyLabel}
            name="termsAndPrivacy"
            onChange={handleCheckboxChange}
          />
          <Checkbox
            label={correctInfoLabel}
            name="correctInfo"
            onChange={handleCheckboxChange}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.submit}
            text="Accept"
            disabled={!enableSubmit(state)}
            onClick={handleSubmit}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdviserTermsModal;
