import { Action } from 'redux';
import { Loading, Loaded, Types as OperationTypes } from './operation-actions';
import { GetAdminUserPolicies } from './admin-actions';

import { ICreatePolicyProps, IPolicy, IPolicyType } from 'types';
import {
  createPolicy,
  createPolicyWithAttachments,
  updatePolicy,
} from 'services';

export enum Types {
  UPDATE_SELECTED_POLICY_LOCAL = 'UPDATE_SELECTED_POLICY_LOCAL',
  UPDATE_SELECTED_POLICY_RENEW_LOCAL = 'UPDATE_SELECTED_POLICY_RENEW_LOCAL',
  NEW_SELECTED_POLICY_LOCAL = 'NEW_SELECTED_POLICY_LOCAL',
}

export interface IUpdateSelectedPolicyLocal extends Action {
  type: Types.UPDATE_SELECTED_POLICY_LOCAL;
  data: IPolicy;
}

export const UpdateSelectedPolicyRenewLocal = (data: IPolicy) =>
  ({
    type: Types.UPDATE_SELECTED_POLICY_RENEW_LOCAL,
    data,
  } as IUpdateSelectedPolicyRenewLocal);

export interface IUpdateSelectedPolicyRenewLocal extends Action {
  type: Types.UPDATE_SELECTED_POLICY_RENEW_LOCAL;
  data: IPolicy;
}

export const UpdateSelectedPolicyLocal = (data: IPolicy) =>
  ({
    type: Types.UPDATE_SELECTED_POLICY_LOCAL,
    data,
  } as IUpdateSelectedPolicyLocal);

export interface INewSelectedPolicyLocal extends Action {
  type: Types.NEW_SELECTED_POLICY_LOCAL;
  data: IPolicy;
}

export const NewSelectedPolicyLocal = (policyTypes: IPolicyType[]) => {
  const lifeType = policyTypes.find((p) => p.name === 'Life');
  const data: IPolicy = {
    policyTypeId: lifeType.id,
    policyType: lifeType.name,
    insured: [
      {
        name: '',
        order: 0,
        additionalCovers: [],
      },
    ],
  };
  return {
    type: Types.NEW_SELECTED_POLICY_LOCAL,
    data,
  } as INewSelectedPolicyLocal;
};

export const OperationCreatePolicy =
  (policy: ICreatePolicyProps) => (dispatch) => {
    dispatch(Loading());
    createPolicy({ ...policy }).then((res) => {
      dispatch(UpdateSelectedPolicyLocal(res.data));
      dispatch(GetAdminUserPolicies(policy.userId));
      dispatch(Loaded());
    });
  };

export const OperationUpdatePolicy = (policy: IPolicy) => (dispatch) => {
  dispatch(Loading());
  updatePolicy(policy).then((res) => {
    dispatch(UpdateSelectedPolicyLocal(res.data));
    dispatch(GetAdminUserPolicies(policy.userId));
    dispatch(Loaded());
  });
};

export const OperationCreatePolicyWithAttachments =
  (policy, attachments) => async (dispatch) => {
    dispatch(Loading());
    try {
      await createPolicyWithAttachments(policy, attachments);
      dispatch(GetAdminUserPolicies(policy.userId));
      dispatch(Loaded());
    } catch {
      alert(`Error OperationCreatePolicyWithAttachments`);
    }
  };

export interface IAdminUpdatePolicyLoaded extends Action {
  type: OperationTypes.ADMIN_UPDATE_POLICY_LOADED;
  data: IPolicy;
}

export const AdminUpdatePolicyLoaded = (data: IPolicy) =>
  ({
    type: OperationTypes.ADMIN_UPDATE_POLICY_LOADED,
    data,
  } as IAdminUpdatePolicyLoaded);

export const AdminUpdatePolicy = (policy: IPolicy) => (dispatch) => {
  dispatch(Loading());
  updatePolicy(policy)
    .then(({ data }) => {
      dispatch(AdminUpdatePolicyLoaded(data));
      dispatch(Loaded());
    })
    .catch((err) => {
      alert(err.message);
      console.log(err);
    });
};
