import React, { useCallback } from 'react';
import { Check, X } from 'react-feather';
import { IAttachment } from 'types';
import styles from './uploader.scss';
import JpegSvg from '-!svg-react-loader!assets/icons/jpeg.svg';
import PdfSvg from '-!svg-react-loader!assets/icons/pdf.svg';
import PngSvg from '-!svg-react-loader!assets/icons/png.svg';
import CsvSvg from '-!svg-react-loader!assets/icons/csv.svg';

interface IUploaderPreviewsProps {
  className?: string;
  itemClassName?: string;
  attachments: IAttachment[];
  onClick?(attachment: IAttachment): void;
  removeAttachment?(e: React.MouseEvent, index: number): void;
}

interface IUploaderPreviewProps {
  className?: string;
  attachment: IAttachment;
  index: number;
  onClick?(attachment: IAttachment): void;
  removeAttachment?(e: React.MouseEvent, index: number): void;
}

const iconMap = {
  jpeg: JpegSvg,
  jpg: JpegSvg,
  pdf: PdfSvg,
  png: PngSvg,
  csv: CsvSvg,
};

const UploaderPreview = ({
  attachment,
  className,
  index,
  onClick,
  removeAttachment,
}: IUploaderPreviewProps) => {
  const name = attachment.name || attachment.fileName;
  const extension = name.split('.').pop();
  const Icon = iconMap[extension];

  const handleDelete = useCallback(
    (e: React.MouseEvent) => removeAttachment(e, index),
    [index, removeAttachment],
  );

  const handleClick = () => onClick && onClick(attachment);

  return (
    <div
      className={classnames(styles.attachment, className)}
      onClick={handleClick}
    >
      <div
        className={classnames(
          styles.attachmentWrap,
          Icon && styles.transparent,
        )}
      >
        {attachment.id ? (
          <Check className={styles.close} />
        ) : (
          removeAttachment && (
            <X className={styles.close} size="20" onClick={handleDelete} />
          )
        )}
        {Icon ? (
          <Icon className={styles.icon} />
        ) : (
          <div className={styles.extension}>{extension}</div>
        )}
      </div>
      <div className={styles.name} title="name">
        {name}
      </div>
    </div>
  );
};

const UploaderPreviews = ({
  attachments,
  className,
  itemClassName,
  onClick,
  removeAttachment,
}: IUploaderPreviewsProps) => (
  <div className={classnames(styles.preview, className)}>
    {attachments.map((a, idx) => (
      <UploaderPreview
        key={`UploaderPreview_${idx}`}
        attachment={a}
        index={idx}
        removeAttachment={removeAttachment}
        onClick={onClick}
        className={itemClassName}
      />
    ))}
  </div>
);

export default UploaderPreviews;
