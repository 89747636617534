import { useCallback, useState } from 'react';
import usePager from 'hooks/usePager';
import { Icon, Loader } from 'semantic-ui-react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import useBreakpoint from 'hooks/useBreakpoint';
import styles from './docViewer.scss';

interface IPDFViewerProps {
  src: string;
  controlClassName?: string;
}

const PDFViewer = ({ src, controlClassName }: IPDFViewerProps) => {
  const breakPoint = useBreakpoint();
  const { active, next, prev } = usePager(1);
  const [pages, setPages] = useState(0);

  const handleLoadSuccess = useCallback(({ numPages }) => {
    setPages(numPages);
  }, []);

  const pageProps = { pageNumber: active, width: undefined, height: undefined };
  const isMobile = breakPoint === 'sm' || breakPoint === 'xs';
  if (isMobile) {
    pageProps.width = window.innerWidth - 40;
  } else {
    pageProps.height = window.innerHeight;
  }

  return (
    <Document
      file={src}
      onLoadSuccess={handleLoadSuccess}
      className={`${styles.document} fs-exclude`}
      loading={<Loader active={true} />}
    >
      <Page {...pageProps} />
      <div className={classnames(styles.pageControls, isMobile && styles.show, controlClassName)}>
        <button type="button" disabled={active <= 1} onClick={prev}>
          <Icon name="angle left" size="large" />
        </button>
        <span>
          {active || (pages ? 1 : '--')} of {pages || '--'}
        </span>
        <button type="button" disabled={active >= pages} onClick={next}>
          <Icon name="angle right" size="large" />
        </button>
      </div>
    </Document>
  );
};

export default PDFViewer;
