import { IAttachment, IUser } from 'types';
import { getHeaderOptions, getHttpService } from './http-service';
import { updateUserProfilePhoto } from './user-service';

const httpClient = getHttpService();

export const getGetAttachmentUrlByKey = (key?: string, fileName?: string) => {
  const data = { key };
  if (fileName) {
    data['fileName'] = fileName;
  }
  return httpClient.post<string>(`/attachment/getSignUrlForGet`, data, {
    headers: getHeaderOptions(),
  });
};

export const getPostAttachmentUrlByKey = (key?: string) => {
  return httpClient.get(`/attachment/getSignUrlForPost/${key}`, {
    headers: getHeaderOptions(),
  });
};

export const profilePhotoSignedPostUrlByKey = (key: string) => {
  return httpClient.post(
    `/attachment/profilePhotoSignedPostUrlByKey`,
    { key },
    {
      headers: getHeaderOptions(),
    },
  );
};

export interface ICreateAttachmentProps {
  policyId: string;
  key: string;
  fileName: string;
  type: string;
  fileSize?: number;
  skipTextract?: boolean;
}

export interface ICreateAdviserUserConnectAttachment {
  adviserUserConnectId: string;
  key: string;
  fileName: string;
  type: string;
  fileSize?: number;
}

export const createAttachmentForPolicy = (
  createAttachmentProps: ICreateAttachmentProps,
) => {
  return httpClient.post(`/attachment/`, createAttachmentProps, {
    headers: getHeaderOptions(),
  });
};

export const createAttachmentForAdviserUserConnect = (
  createAttachmentProps: ICreateAdviserUserConnectAttachment,
) => {
  return httpClient.post(
    '/attachment/adviserUserConnect',
    createAttachmentProps,
    {
      headers: getHeaderOptions(),
    },
  );
};

export const deleteAttachment = (
  attachmentId: string,
  policyId: string,
  key: string,
) => {
  const decoded = encodeURIComponent(key);
  return httpClient.delete(
    `/attachment/${attachmentId}/${policyId}/${decoded}`,
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getAttachmentsForPolicy = (policyId: string) => {
  return httpClient.get(`/attachment/policy/id/${policyId}`, {
    headers: getHeaderOptions(),
  });
};

export interface IFile {
  name: string;
  lastModified: number;
  lastModifiedDate: Date;
  webkitRelativePath: string;
  size: number;
  type: string;
  path: string;
  duplicate?: boolean;
}

export const uploadAttachment = (url: string, formData: FormData, type) => {
  return httpClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const batchUploadAttachments = async (
  files: IFile[] = [],
  policyId: string,
  skipTextract: boolean = false,
) => {
  const promises = files.map(async (file) => {
    const key = encodeURIComponent(`${policyId}/${file.name}`);
    const {
      data: { fields, url },
    } = await getPostAttachmentUrlByKey(key);
    const formData = new FormData();
    Object.keys(fields).forEach((keyy) => {
      formData.append(keyy, fields[keyy]);
    });
    formData.append('file', file as any);
    return uploadAttachment(url, formData, file.type).then(() => {
      return createAttachmentForPolicy({
        policyId,
        key,
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
        skipTextract,
      });
    });
  });
  return Promise.all(promises);
};

export const uploadAdviserConnectAttachments = async (
  files: IFile[] = [],
  adviserUserConnectId: string,
) => {
  const promises = files.map(async (file) => {
    const key = encodeURIComponent(`${adviserUserConnectId}/${file.name}`);
    const {
      data: { fields, url },
    } = await getPostAttachmentUrlByKey(key);
    const formData = new FormData();
    Object.keys(fields).forEach((keyy) => {
      formData.append(keyy, fields[keyy]);
    });
    formData.append('file', file as any);
    await uploadAttachment(url, formData, file.type);
    return createAttachmentForAdviserUserConnect({
      adviserUserConnectId,
      key,
      fileName: file.name,
      fileSize: file.size,
      type: file.type,
    });
  });
  return Promise.all(promises);
};

export const batchDeleteAttachments = async (attachments: IAttachment[]) => {
  const promises = attachments.map(async (attachment) => {
    const { id, policyId, key } = attachment;
    return deleteAttachment(id, policyId, key);
  });
  return Promise.all(promises);
};

export const uploadUserProfileAttachment = async (user: IUser, file: IFile) => {
  // Use timestamp to bypass cloudfront cache
  const key = `${user.id}/${new Date().getTime()}.${file.name
    .split('.')
    .pop()}`;
  const {
    data: { fields, url },
  } = await profilePhotoSignedPostUrlByKey(key);
  const formData = new FormData();
  Object.keys(fields).forEach((keyy) => {
    formData.append(keyy, fields[keyy]);
  });
  formData.append('file', file as any);
  await uploadAttachment(url, formData, file.type);
  return updateUserProfilePhoto(fields.key);
};

export const getAttachmentLinks = async (attachments: IAttachment[]) => {
  const docs = [];

  for (const att of attachments) {
    const doc = await getGetAttachmentUrlByKey(att.key, att.fileName);
    if (doc.data !== 'notag') {
      docs.push({ ...att, href: doc.data });
    }
  }

  return docs;
};

export const getAttachmentsForPolicyWithLinks = async (policyId: string) => {
  const attachments = await getAttachmentsForPolicy(policyId);
  return getAttachmentLinks(attachments.data.Items);
};
