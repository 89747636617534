// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1TnLswZ6Z8Cc7cs-8_pyPM.ui.inverted.dimmer{background-color:#f7f8fc}", ""]);
// Exports
exports.locals = {
	"loading": "_1TnLswZ6Z8Cc7cs-8_pyPM"
};
module.exports = exports;
