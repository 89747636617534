import React, { useEffect, useMemo, useState } from 'react';
import { Search } from 'semantic-ui-react';
import { debounce } from 'helpers';
import { searchOccupation } from 'services';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/TextInput.module.scss';

interface ISearchInputProps {
  api?: string;
}

const searchApiMap = {
  occupation: searchOccupation,
};

const SearchInput = ({
  api,
  basic,
  label,
  labelAfter,
  className,
  inputClassName,
  labelClassName,
  leftIcon,
  rightIcon,
  error,
  scrollIntoView,
  tooltip,
  showButton,
  buttonText = 'Search',
  onButtonClick,
  onChange,
  ...inputProps
}: IFormFieldProps & ISearchInputProps & React.HTMLProps<HTMLInputElement>) => {
  const [{ inputValue, results, searching }, setState] = useState({
    results: [],
    searching: false,
    inputValue: '',
  });

  useEffect(() => {
    setState(R.assoc('inputValue', inputProps.value));
  }, [inputProps.value]);

  const debounceSearch = useMemo(
    () =>
      debounce(async (term) => {
        setState(R.assoc('searching', true));
        const apiFn = searchApiMap[api];

        if (apiFn) {
          const res = await apiFn(term);

          setState(
            R.mergeLeft({
              results: res.data.map((r) => R.assoc('title', r.label, r)),
              searching: false,
            }),
          );
        }
      }, 500),
    [api],
  );

  const onSearchChange = async (_, data) => {
    const term = data.value;
    setState(R.assoc('inputValue', term));
    if (term.length < data.minCharacters) {
      if (results.length > 0) {
        setState(R.assoc('results', []));
      }
      return;
    }

    debounceSearch(term);
  };

  const onSearchSelect = async (_, data) => {
    onChange({
      target: {
        name: inputProps.name,
        value: data.result.title,
        result: data.result,
      },
    });
  };

  return (
    <InputWrap
      className={classnames(styles.search, className)}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      error={error}
      label={label}
      labelAfter={labelAfter}
      labelClassName={labelClassName}
      name={inputProps.name}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
      tooltip={tooltip}
    >
      <Search
        id={inputProps.name}
        className={classnames(styles.searchInput, inputClassName)}
        loading={searching}
        minCharacters={3}
        results={results}
        showNoResults={false}
        onResultSelect={onSearchSelect}
        onSearchChange={onSearchChange}
        {...inputProps}
        value={inputValue}
      />
    </InputWrap>
  );
};

export default SearchInput;
