import { POLICY_PRIORITY } from 'constants/policy';
import moment from 'moment';
import * as R from 'ramda';

export const sortByObjectKeyAscending = (key) => R.sort(R.ascend(R.prop(key)));

export const sortByRenewalDateDesc = R.sort(
  R.descend(
    (val) => R.path(['newPolicyData', 'renewalDate'], val) || val.renewalDate,
  ),
);
export const sortByRenewalDate = R.sort(
  R.ascend(
    (val) => R.path(['newPolicyData', 'renewalDate'], val) || val.renewalDate,
  ),
);

export const createdAtDescFn = R.descend(R.prop('createdAt'));
export const sortByCreatedAtDesc = R.sort(createdAtDescFn);
export const sortByCreatedAt = R.sort(R.ascend(R.prop('createdAt')));

export const sortByRenewalDt = R.sort(R.ascend(R.prop('renewalDate')));
export const sortByRenewalDtDesc = R.sort(R.descend(R.prop('renewalDate')));

export const sortDateToISOByPropDesc = (key) =>
  R.sort(R.descend((val) => moment(val[key]).toISOString()));
export const sortDateToISOByProp = (key) =>
  R.sort(R.ascend((val) => moment(val[key]).toISOString()));

export const compareStringsCaseInsensitive = (a = '', b = '') => {
  const aVal = a.toString().toLowerCase();
  const bVal = b.toString().toLowerCase();
  if (aVal > bVal) {
    return 1;
  }
  if (aVal < bVal) {
    return -1;
  }
  return 0;
};

export const compareListWithPriorities = (priorities = []) => (a, b) => {
  const aIndex = priorities.findIndex((item) => item === (a.name || a));
  const bIndex = priorities.findIndex((item) => item === (b.name || b));

  if (aIndex === -1 || bIndex === -1) {
    return aIndex > bIndex ? -1 : aIndex === bIndex ? 0 : 1;
  }
  if (aIndex > bIndex) {
    return 1;
  }
  if (bIndex > aIndex) {
    return -1;
  }
  return 0;
};

export const sortByPolicyPriorityDesc = (nameA, nameB) => {
  const priorityA = POLICY_PRIORITY[nameA] || 0;
  const priorityB = POLICY_PRIORITY[nameB] || 0;
  return priorityB - priorityA;
};
