import { IPolicy, IPolicies, IPolicyRenewal, IPolicyScan } from 'types';
import { AppActionTypes } from 'actions';
import { Types } from '../actions/policy';
import { Types as RenewalTypes } from '../actions/policy-renewal-actions';
import { Types as AdviserTypes } from '../actions/adviser-actions';
import {
  insertItem,
  isPolicyProgressError,
  removeItemsByProp,
  removeItemsByPropList,
  updateListByItem,
  updateListByItemMerged,
} from 'helpers';

const updateSelectedPolicyByRenewal = (
  renewal: IPolicyRenewal,
  policy: IPolicy,
) => {
  return renewal.isRenewExpiredPolicy
    ? R.merge(policy, renewal.newPolicyData)
    : R.assoc('policyRenewalAdded', true, policy);
};

// Hide purchased policy scans as they are now converted to a policy
const hidePurchasedPolicyScans = R.filter(
  (policyScan: IPolicyScan) => !policyScan.purchasedAt,
);

const policiesReducer = (
  state: IPolicies,
  action: AppActionTypes,
): IPolicies => {
  switch (action.type) {
    case Types.CREATE_POLICY_LOADING:
    case AdviserTypes.CREATE_ADVISER_CLIENT_POLICY_LOADING:
      return {
        ...state,
        submitting: true,
      };
    case Types.CREATE_POLICY_LOADED: {
      const { submitted = true, ...newPolicy } = action.data;
      return {
        ...state,
        policies: insertItem(state.policies, newPolicy),
        submitting: false,
        submitted,
      };
    }
    case Types.CREATE_POLICIES:
      return {
        ...state,
        policies: [...state.policies, ...action.data],
        submitting: false,
      };
    case Types.CREATE_POLICY_FAILED:
      return {
        ...state,
        submitting: false,
        error: action.data,
      };
    case Types.GET_USER_POLICIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_USER_POLICIES_LOADED:
      return {
        ...state,
        loading: false,
        policies: action.data,
      };
    case Types.DELETE_POLICY_LOADED:
      return {
        ...state,
        loading: false,
        policies: removeItemsByProp(state.policies, 'id', action.data),
        policyScans: removeItemsByProp(state.policyScans, 'sk', action.data),
      };
    case Types.DELETE_POLICIES:
      return {
        ...state,
        loading: false,
        policies: removeItemsByPropList(state.policies, 'id', action.data),
        policyScans: removeItemsByPropList(
          state.policyScans,
          'sk',
          action.data,
        ),
      };
    case Types.UPDATE_POLICY_LOADED: {
      const { submitted, ...updatedPolicy } = action.data;
      const { selectedPolicy } = state;

      return {
        ...state,
        loading: false,
        policies: updateListByItem(state.policies, updatedPolicy),
        selectedPolicy:
          selectedPolicy && selectedPolicy.id === updatedPolicy.id
            ? { ...updatedPolicy }
            : selectedPolicy,
        submitting: false,
        submitted: action.data.employerId
          ? false
          : submitted ?? state.submitted,
        updating: false,
      };
    }
    case Types.RESET_POLICY_FORM:
      return {
        ...state,
        submitting: false,
        submitted: false,
        selectedPolicy: null,
        error: '',
      };
    case Types.SELECT_POLICY:
      return {
        ...state,
        selectedPolicy: action.data,
      };
    case RenewalTypes.CREATE_RENEWAL_LOADED:
      const policyId = action.data.sk;
      return {
        ...state,
        selectedPolicy:
          state.selectedPolicy?.id === policyId
            ? updateSelectedPolicyByRenewal(action.data, state.selectedPolicy)
            : state.selectedPolicy,
        policies: updateListByItemMerged(
          state.policies,
          action.data.isRenewExpiredPolicy
            ? action.data.newPolicyData
            : { id: policyId, policyRenewalAdded: true },
        ),
      };
    case Types.GET_POLICY_SCAN_BY_POLICY_ID_LOADED:
      return {
        ...state,
        selectedPolicyScan: action.data,
        submitting: false,
      };
    case Types.CREATE_POLICY_SCAN_LOADED:
    case Types.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED:
      const policyData = {
        id: action.data.sk,
        lastPolicyScanDate: action.data.createdAt,
      };
      return {
        ...state,
        submitting: false,
        submitted: true,
        selectedPolicyScan: action.data,
        policyScans: insertItem(state.policyScans, action.data),
        policies: updateListByItemMerged(state.policies, policyData),
        selectedPolicy:
          state.selectedPolicy && state.selectedPolicy.id === policyData.id
            ? R.merge(state.selectedPolicy, policyData)
            : state.selectedPolicy,
      };
    case Types.UPDATE_POLICY_SCAN_LOADED:
      const updated = updateListByItemMerged(state.policyScans, action.data);
      return {
        ...state,
        policyScans: hidePurchasedPolicyScans(updated),
      };
    case Types.GET_POLICY_REVIEW_BY_USER:
      return {
        ...state,
        policyReviews: action.data,
      };
    case Types.CREATE_POLICY_REVIEW_LOADED:
      return {
        ...state,
        policyReviews: [...state.policyReviews, action.data],
      };
    case Types.GET_POLICY_SCAN_LOADED:
      return {
        ...state,
        policyScans: hidePurchasedPolicyScans(action.data),
      };
    case AdviserTypes.CREATE_ADVISER_CLIENT_POLICY:
    case AdviserTypes.UPDATE_ADVISER_CLIENT_POLICY:
      const { submitted = true } = action.data;
      return {
        ...state,
        submitted,
        submitting: false,
      };
    case AdviserTypes.DELETE_ADVISER_CLIENT_POLICY:
      return {
        ...state,
        policies: removeItemsByProp(state.policies, 'id', action.data.id),
      };
    case Types.CREATE_POLICY_BUNDLE_LOADED:
      return {
        ...state,
        submitted: true,
        submitting: false,
      };
    case Types.DELETE_POLICY_BUNDLE_LOADED:
      return {
        ...state,
        bundles: removeItemsByProp(state.bundles, 'id', action.data),
      };
    case Types.GET_POLICY_BUNDLES_LOADED:
      return {
        ...state,
        bundles: R.reject(
          (bundle) =>
            bundle.processed || isPolicyProgressError(bundle.createdAt),
          action.data,
        ),
      };
    case Types.UPDATE_POLICY_LOADING:
      return {
        ...state,
        updating: true,
      };
    case Types.CREATE_POLICY_SCAN_LOADING:
      return {
        ...state,
        submitting: true,
      };
    case Types.CREATE_POLICY_SCAN_FAILED:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

export default policiesReducer;
