import { Input, TextArea, Form, Icon } from 'semantic-ui-react';
import styles from './styles/adviser-connect.scss';
import Button from 'components/button';
import { TypeFormField } from 'components/type-form';
import { PREFERRED_CONTACT_QUESTION } from 'constants/adviser';
import React from 'react';

const ConnectForm = ({
  form,
  handleConnectClick,
  hideButtons,
  hideTitle,
  disable,
}) => (
  <>
    <div className={styles.formContainer}>
      {!hideTitle && <div className={styles.title}>Find an Adviser</div>}
      <div className={styles.text}>Preferred contact method</div>
      <TypeFormField
        className={styles.typeform}
        form={form}
        question={PREFERRED_CONTACT_QUESTION}
      />
      <Form className={styles.form}>
        <div className={`${styles.inputRow}`}>
          <Input
            name="name"
            onChange={form.handleInputChange}
            value={form.values.name}
            placeholder="Name"
          />
          <Input
            name="preferredContactDetails"
            onChange={form.handleInputChange}
            value={form.values.preferredContactDetails}
            placeholder={
              form.values.preferredContact === 'email' ? 'Email' : 'Phone'
            }
          />
        </div>
        <TextArea
          name="message"
          onChange={form.handleInputChange}
          value={form.values.message}
          rows={11}
          placeholder="Message"
          disabled={disable.message}
        />
      </Form>
    </div>
    {!hideButtons && (
      <div className={styles.btnContainer}>
        <span className={styles.pager}>2 of 3</span>
        <Button
          className={styles.proceed}
          text="Connect me"
          onClick={handleConnectClick}
        />
      </div>
    )}
  </>
);

ConnectForm.defaultProps = {
  disable: {
    message: false,
  },
};

export default ConnectForm;
