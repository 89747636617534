import { IConfig } from 'types';
import moment from 'moment';

export enum StorageKeys {
  USER_CLAIMS_TOKEN = 'USER_CLAIMS_TOKEN',
  CONFIG = 'CONFIG',
  REFERENCE_DATA = 'REFERENCE_DATA',
  USER_SETTING = 'USER_SETTING',
  SIDEBAR = 'showSidebar',
  OVERVIEW_COLLAPSE = 'overview_collapse',
}

export enum ReferenceDataKeys {
  POLICY_PROVIDERS = 'policyProviders',
  POLICY_TYPES = 'policyTypes',
}

export const setClaimsToken = (claims) => {
  const stringified = JSON.stringify(claims);
  localStorage.setItem(StorageKeys.USER_CLAIMS_TOKEN, stringified);
};

export const getClaimsToken = () => {
  const stringified = localStorage.getItem(StorageKeys.USER_CLAIMS_TOKEN);
  return stringified ? JSON.parse(stringified) : '';
};

export const getConfig = () => {
  const stringified = localStorage.getItem(StorageKeys.CONFIG);
  return stringified ? JSON.parse(stringified) : undefined;
};

export const setConfig = (config: IConfig) => {
  const stringified = JSON.stringify(config);
  localStorage.setItem(StorageKeys.CONFIG, stringified);
};

export const getReferenceData = (prop) => {
  const stringified = localStorage.getItem(
    `${StorageKeys.REFERENCE_DATA}_${prop}`,
  );
  return stringified ? JSON.parse(stringified) : {};
};

export const setReferenceData = (items, prop) => {
  const referenceWithDate = {
    items,
    lastUpdated: moment().format('YYYY-MM-DD'),
  };
  const stringified = JSON.stringify(referenceWithDate);
  localStorage.setItem(`${StorageKeys.REFERENCE_DATA}_${prop}`, stringified);
};

export const resolveReferenceData = async (config: IConfig, prop, asyncFn) => {
  const referenceLastUpdated = R.propOr(
    new Date(),
    'referenceLastUpdated',
    config,
  );
  const localReferenceData = getReferenceData(prop);
  // We fetch from API if data is not in local storage
  // or referenceLastUpdated is more recent than local lastUpdated
  if (
    !localReferenceData.items ||
    moment(referenceLastUpdated).isAfter(localReferenceData.lastUpdated)
  ) {
    const result = await asyncFn();
    const data = result.data.Items || result.data;
    setReferenceData(data, prop);
    return data;
  }
  return localReferenceData.items;
};

export const setUserSetting = (name, value) => {
  localStorage.setItem(`${StorageKeys.USER_SETTING}_${name}`, value);
};

export const getUserSetting = (name) => {
  return JSON.parse(
    localStorage.getItem(`${StorageKeys.USER_SETTING}_${name}`),
  );
};

export const getSidebarState = () => {
  const value = getUserSetting(StorageKeys.SIDEBAR);
  if (value || value === false) {
    // Minimise sidebar on smaller screens by default
    return window.innerWidth > 992;
  }
  return getUserSetting(StorageKeys.SIDEBAR);
};

export const getPolicyTypeByName = (name: string) => {
  const { items = [] } = getReferenceData(ReferenceDataKeys.POLICY_TYPES);
  return items.find(R.propEq('name', name));
};

export const getPolicyProviderById = (id: string) => {
  const { items = [] } = getReferenceData(ReferenceDataKeys.POLICY_PROVIDERS);
  return items.find(R.propEq('id', id));
};

export const getPolicyProviderByName = (name: string) => {
  const { items = [] } = getReferenceData(ReferenceDataKeys.POLICY_PROVIDERS);
  return items.find((p) => p.name.toLowerCase() === name.toLowerCase());
};

export const setDashOverviewCollapse = (value: boolean) => {
  setUserSetting(StorageKeys.OVERVIEW_COLLAPSE, value.toString());
};

export const getDashOverviewCollapse = () => {
  return getUserSetting(StorageKeys.OVERVIEW_COLLAPSE);
};
