// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "body,button,input{font-family:\"Open Sans\";font-weight:400}#app,body,._1O8OeAbZUsHRj3IYRt7x_A{height:100%}._3UKnfF5yPf8oXDobgQvtKt{display:none;width:250px;vertical-align:top;align-self:stretch;position:relative}@media(min-width: 992px){._3UKnfF5yPf8oXDobgQvtKt{display:inline-block}}.ui.form input:not([type]),.ui.form input[type]{font-family:\"Open Sans\";font-weight:400}.ui.form .field > label{font-weight:normal}.ui.dimmer{background:rgba(52,54,62,.4)}.Toastify .Toastify__toast-container{padding:0;width:auto}.Toastify .Toastify__toast-container.Toastify__toast-container--top-center{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);background-color:rgba(52,54,62,.6);width:100%;height:100%;display:flex;align-items:center;justify-content:center}.Toastify .Toastify__toast-container.Toastify__toast-container--top-center>*{margin:auto;width:100%}@media only screen and (max-width: 480px){.Toastify .Toastify__toast-container{left:1em;bottom:1em}}.Toastify .Toastify__toast,.Toastify .Toastify__toast-body{padding:0;color:#34363e}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}[type=reset],[type=submit],button,html [type=button],input:not([type=radio]){-webkit-appearance:none}", ""]);
// Exports
exports.locals = {
	"container": "_1O8OeAbZUsHRj3IYRt7x_A",
	"sidebar": "_3UKnfF5yPf8oXDobgQvtKt"
};
module.exports = exports;
