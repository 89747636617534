import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'semantic-ui-react';
import { IUser } from 'types';
import Button from 'components/button';
import { TextInput } from 'components/form-fields-v2';
import styles from './detailsRequiredModal.scss';
import QFoldedArms from '-!svg-react-loader!images/q/q-folded-arms.svg';

interface IProps {
  user: IUser;
  show: boolean;
  updateUser(user: IUser): (dispatch: any) => void;
}

const validation = (v: string) => v && !!v.trim();

const rules = {
  required: true,
  validate: validation,
};

const DetailsRequiredModal = ({ show, user, updateUser }: IProps) => {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: { firstName: user.firstName, lastName: user.lastName },
  });

  const { errors } = formState;
  const { loading } = user;

  const submit = (data: any) => {
    const updatedData = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
    };
    updateUser({ ...user, ...updatedData });
  };

  return (
    <Modal
      open={show}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      className={styles.modal}
    >
      <Modal.Content>
        <form className={styles.container} onSubmit={handleSubmit(submit)}>
          <div className={styles.header}>
            <QFoldedArms />
            <h2>We need one more thing from you</h2>
            Please enter your name
          </div>
          <div className={styles.questions}>
            <Controller
              key="firstName"
              control={control}
              name="firstName"
              rules={rules}
              render={({ field }) => {
                return (
                  <TextInput
                    error={!!errors['firstName']}
                    className={styles.field}
                    placeholder="First name"
                    {...field}
                  />
                );
              }}
            />
            <Controller
              key="lastName"
              control={control}
              name="lastName"
              rules={rules}
              render={({ field }) => {
                return (
                  <TextInput
                    error={!!errors['lastName']}
                    className={styles.field}
                    placeholder="Last name"
                    {...field}
                  />
                );
              }}
            />
          </div>
          <div className={styles.actions}>
            <Button
              className={styles.submit}
              type="submit"
              text="Get started"
              disabled={!R.isEmpty(errors) || loading}
            />
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default DetailsRequiredModal;
