import React from 'react';
import { ChevronUp } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleAppBanner } from 'actions';
import { getAppBannerContent } from './utils';
import styles from './AppBanner.module.scss';
import { useFeature } from '@growthbook/growthbook-react';
import { Features } from 'constants/features';

const AppBanner = () => {
  const showAppBanner = useFeature(Features.SHOW_APP_BANNER).on;
  const dispatch = useDispatch();
  const handleClose = () => dispatch(ToggleAppBanner(null));

  const content = useSelector(
    getAppBannerContent({ onClose: handleClose, dispatch }),
  );

  return (
    <div
      className={classnames(
        styles.container,
        showAppBanner && content && styles.open,
      )}
    >
      <div className={styles.content}>
        {content}
        <ChevronUp
          onClick={handleClose}
          size={20}
          className={styles.closeIcon}
        />
      </div>
    </div>
  );
};

export default React.memo(AppBanner);
