import { getHeaderOptions, getHttpService } from './http-service';
import { getClaimsToken, getFileExtensionFromString } from '../helpers';
import { IFile, uploadAttachment } from './attachment-service';
import { IAttachmentKeyName, IBundle } from 'types';

const httpClient = getHttpService();

const createBundle = (attachments: IAttachmentKeyName[]) => {
  return httpClient.post<IBundle>(
    `/bundle`,
    { files: attachments },
    {
      headers: getHeaderOptions(),
    },
  );
};

interface ICreateAttachmentBundleProps {
  bundleId: string;
  fileName: string;
  fileSize: number;
  key: string;
  type: string;
}

interface IUpdateBundleProps {
  id: string;
  sk: string;
  attachments: number;
}

const updateBundle = (bundle: IUpdateBundleProps) => {
  return httpClient.put<IBundle>(`/bundle`, bundle, {
    headers: getHeaderOptions(),
  });
};

const createAttachmentForBundle = (
  attachment: ICreateAttachmentBundleProps,
) => {
  return httpClient.post<IBundle>(`/bundle/attachment`, attachment, {
    headers: getHeaderOptions(),
  });
};

export const createBundleWithAttachments = async (
  files: IFile[],
  userId?: string,
) => {
  const attachments = files.map((file, index) => {
    const extensionName = getFileExtensionFromString(file.name);
    // create a new uniq key for file
    const key = `${new Date().getTime()}-${index}.${extensionName}`;
    return {
      key,
      fileName: file.name,
      userId,
    };
  });

  const { data } = await createBundle(attachments);

  const bundleAttachmentResults = await uploadBundleAttachments(files, data);

  // Handle upload errors by updating bundle to match success count
  const successfulUploads = bundleAttachmentResults.filter(R.prop('success'));
  if (successfulUploads.length === data.attachments) {
    return bundleAttachmentResults;
  } else {
    await updateBundle(
      R.assoc('attachments', bundleAttachmentResults.length, data),
    );
  }

  return bundleAttachmentResults;
};

export const uploadBundleAttachments = async (
  files: IFile[],
  bundle: IBundle,
) => {
  const results = [];

  for (const file of files) {
    try {
      const fileWithUploadInfo = bundle.files.find(
        (f) => f.fileName === file.name,
      );
      const { fields, url } = fileWithUploadInfo.postFileInfo;
      const formData = new FormData();
      Object.keys(fields).forEach((keyy) => {
        formData.append(keyy, fields[keyy]);
      });
      formData.append('file', file as any);
      await uploadAttachment(url, formData, file.type);
      const resp = await createAttachmentForBundle({
        bundleId: bundle.id,
        key: fileWithUploadInfo.key,
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
      });
      results.push({ success: true, file, data: resp.data });
    } catch (err) {
      results.push({ success: false, file });
    }
  }

  return results;
};

export const getBundlesForUser = (userId?: string) => {
  const claims = getClaimsToken();
  const id = userId || `user:${claims.sub}`;
  return httpClient.post(
    `/bundle/bundles`,
    {
      userId: id,
    },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getBundleById = (
  bundleId: string,
  withUserPolicies: boolean,
  withAttachments?: boolean,
) => {
  const data = {
    bundleId,
    withUserPolicies,
    withAttachments,
  };
  return httpClient.post(`/bundle/id`, data, {
    headers: getHeaderOptions(),
  });
};
