import { Action } from 'redux';
import {
  createCreditCardPolicy,
  deleteCreditCardPolicy,
  getCreditCardPolicies,
} from 'services';
import { ICreditCard } from 'types';

export enum Types {
  CREDIT_CARD_RESET_FORM = 'CREDIT_CARD_RESET_FORM',
  GET_CREDIT_CARD_POLICIES = 'GET_CREDIT_CARD_POLICIES',
  CREATE_CREDIT_CARD_POLICY_LOADED = 'CREATE_CREDIT_CARD_POLICY_LOADED',
  DELETE_CREDIT_CARD_POLICY_LOADED = 'DELETE_CREDIT_CARD_POLICY_LOADED',
  CREDIT_CARD_SUBMITTING = 'CREDIT_CARD_SUBMITTING',
}

export interface ICreditCardSubmitting extends Action {
  type: Types.CREDIT_CARD_SUBMITTING;
}

export interface IGetCreditCardPoliciesLoaded extends Action {
  type: Types.GET_CREDIT_CARD_POLICIES;
  data: ICreditCard[];
}

export interface ICreateCreditCardPolicyLoaded extends Action {
  type: Types.CREATE_CREDIT_CARD_POLICY_LOADED;
  data: ICreditCard;
}

export interface IDeleteCreditCardPolicyLoaded extends Action {
  type: Types.DELETE_CREDIT_CARD_POLICY_LOADED;
  data: { id: string; sk: string };
}

export interface ICreditCardResetForm extends Action {
  type: Types.CREDIT_CARD_RESET_FORM;
}

const CreditCardSubmitting = () =>
  ({
    type: Types.CREDIT_CARD_SUBMITTING,
  } as ICreditCardSubmitting);

export const GetCreditCardPoliciesLoaded = (data: ICreditCard[]) =>
  ({
    type: Types.GET_CREDIT_CARD_POLICIES,
    data,
  } as IGetCreditCardPoliciesLoaded);

const CreateCreditCardPolicyLoaded = (data: ICreditCard) =>
  ({
    type: Types.CREATE_CREDIT_CARD_POLICY_LOADED,
    data,
  } as ICreateCreditCardPolicyLoaded);

const DeleteCreditCardPolicyLoaded = (data: { id: string; sk: string }) =>
  ({
    type: Types.DELETE_CREDIT_CARD_POLICY_LOADED,
    data,
  } as IDeleteCreditCardPolicyLoaded);

export const GetCreditCardPolicies = (id: string) => async (dispatch) => {
  const result = await getCreditCardPolicies(id);
  dispatch(GetCreditCardPoliciesLoaded(result.data));
};

export const CreateCreditCardPolicy =
  (creditCard: ICreditCard) => async (dispatch) => {
    dispatch(CreditCardSubmitting());

    const result = await createCreditCardPolicy({
      creditCardId: creditCard.id,
      creditCardProviderId: creditCard.sk,
    });

    dispatch(CreateCreditCardPolicyLoaded(result.data));
  };

export const DeleteCreditCardPolicy =
  (id: string, sk: string) => async (dispatch) => {
    const data = { id, sk };
    deleteCreditCardPolicy(data);
    dispatch(DeleteCreditCardPolicyLoaded(data));
  };

export const CreditCardResetForm = () =>
  ({
    type: Types.CREDIT_CARD_RESET_FORM,
  } as ICreditCardResetForm);
