import React from 'react';

const styles = require("./formField.scss");

export interface IPlaceholderProps {
  placeholder: string;
  required?: boolean;
  className?: string;
}

const Placeholder = ({ required, placeholder, className }: IPlaceholderProps) => (
  <div className={className}>
    {required && <span className={styles.required}>{"* "}</span>}
    {placeholder}
  </div>
);

export default Placeholder;