import React from 'react';
import styles from './styles/helpTooltip.scss';
import { Popup, PopupProps } from 'semantic-ui-react';
import { HelpCircle, AlertCircle } from 'react-feather';

const HelpTooltip = ({
  toggleContainerClassName,
  toggleClassName,
  isAlert = false,
  triggerSize = 16,
  children,
  ...props
}: PopupProps) => {
  const IconComponent = isAlert ? AlertCircle : HelpCircle;
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Popup
      hideOnScroll={true}
      offset={[0, 0]}
      {...props}
      trigger={
        <div
          className={classnames(
            styles.toggleContainer,
            toggleContainerClassName,
          )}
        >
          {children}
          <IconComponent
            size={triggerSize}
            className={classnames(styles.toggle, toggleClassName)}
            onClick={handleClick}
          />
        </div>
      }
    />
  );
};

export default HelpTooltip;
