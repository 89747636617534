import { getHeaderOptions, getHttpService } from './http-service';

const BASE_ROUTE = '/reference';

const httpClient = getHttpService();

export enum VehicleCoverTypes {
  CAR = 'MVI',
  MOTORCYCLE = 'MCI',
}

export enum VehicleTypeNames {
  CAR = 'motorvehicle',
  MOTORCYCLE = 'motorcycle',
}

export enum SundryCategories {
  CurrentInsurer = 'CurrentInsurer',
  LicenceClass = 'LicenceClass',
  LicenceCountry = 'LicenceCountry',
  LicenceYears = 'LicenceYears',
  GeneralQuestions = 'GeneralQuestions',
  GeneralQuestionAnswers = 'GeneralQuestionAnswers',
}

export const getPolicyScanReference = () => {
  return httpClient.get(`${BASE_ROUTE}/policyscan`, {
    headers: getHeaderOptions(),
  });
};

export const getInsuranceCheckupReference = () => {
  return httpClient.get(`${BASE_ROUTE}/checkup`, {
    headers: getHeaderOptions(),
  });
};

export const getVehicleInfo = (numberPlate: string) => {
  return httpClient.post(
    `${BASE_ROUTE}/getVehicleInfo`,
    { numberPlate },
    { headers: getHeaderOptions() },
  );
};

export const getMakes = (type: VehicleCoverTypes) => {
  return httpClient.post(
    `${BASE_ROUTE}/getMakes`,
    { type },
    { headers: getHeaderOptions() },
  );
};

export const getModels = (type: VehicleCoverTypes, make: string) => {
  return httpClient.post(
    `${BASE_ROUTE}/getModels`,
    { type, make },
    { headers: getHeaderOptions() },
  );
};

export const getSubmodels = (
  type: VehicleCoverTypes,
  make: string,
  model: string,
) => {
  return httpClient.post(
    `${BASE_ROUTE}/getSubmodels`,
    { type, make, model },
    { headers: getHeaderOptions() },
  );
};

export const getSundryLookup = async (
  type: VehicleCoverTypes,
  category: string,
) => {
  const { data } = await httpClient.post(
    `${BASE_ROUTE}/getSundryLookup`,
    { type, category },
    { headers: getHeaderOptions() },
  );

  return { data: data.options || [] };
};

export const getAreaOfGaraging = (type: VehicleCoverTypes) => {
  return httpClient.post(
    `${BASE_ROUTE}/getAreaOfGaraging`,
    { type },
    { headers: getHeaderOptions() },
  );
};

export const getFinanceCompanies = (type: VehicleCoverTypes) => {
  return httpClient.post(
    `${BASE_ROUTE}/getFinanceCompanies`,
    { type },
    { headers: getHeaderOptions() },
  );
};

export const searchOccupation = (term: string) => {
  return httpClient.get(`${BASE_ROUTE}/searchOccupation?term=${term}`, {
    headers: getHeaderOptions(),
  });
};
