import { IPaymentState } from "../../types";
import { AppActionTypes } from "../actions";
import { Types } from "../actions/payment-actions";

const paymentReducer = (state: IPaymentState, action: AppActionTypes): IPaymentState => {
  switch (action.type) {
    case Types.PAYMENT_LOADING:
      return { 
        ...state,
        loading: action.data,
      };
    case Types.CREATE_PAYMENT_INTENT_ERROR:
      return { 
        ...state,
        error: action.data,
      };
    case Types.CREATE_PAYMENT_INTENT_LOADED:
      return {
        ...state,
        paymentResult: action.data,
        error: '',
        loading: false,
      };
    case Types.WINDCAVE_VERIFY_PAYMENT_LOADED:
      return {
        ...state,
        windcavePaymentResult: action.data, // would this action.data be the data that is fethed from the backend /database
        loading: false,
      };
    case Types.WINDCAVE_VERIFY_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data, // would this refer back to `Verify Payment Failed`)
      };  
    default:
      return state;
  }
};

export default paymentReducer;
