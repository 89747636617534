// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1uV3aGKdjkzZB6PhP0eHAh{text-align:center;padding:24px 40px}._1uV3aGKdjkzZB6PhP0eHAh h3{font-family:\"Open Sans\";font-weight:700;color:#4187f5}._2LMe_isYQgsSCCmZpUNCxH{cursor:pointer;position:absolute;right:24px;color:#34363e}._3Z2Ii3rUtSSLDB70O-zoTH{display:flex;flex-direction:column;color:#34363e}._3Z2Ii3rUtSSLDB70O-zoTH textarea{width:100%;margin-top:24px;margin-bottom:24px}._2KrVPwY7btef-DrQvxvu6c{display:flex;justify-content:flex-end;align-items:center}._3gXrxvpl4UnKL34Pm-DbAE.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;margin-left:8px}@media(max-width: 576px){._3gXrxvpl4UnKL34Pm-DbAE.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 576px){._2LMe_isYQgsSCCmZpUNCxH{position:relative;right:0;display:block;margin-left:auto}._3Z2Ii3rUtSSLDB70O-zoTH textarea{margin-bottom:8px}._2KrVPwY7btef-DrQvxvu6c{flex-direction:column}._1-FipVCYG9BObDbs00PZg6{margin-bottom:8px}._3gXrxvpl4UnKL34Pm-DbAE.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;width:100%;margin-left:0}}@media(max-width: 576px)and (max-width: 576px){._3gXrxvpl4UnKL34Pm-DbAE.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}", ""]);
// Exports
exports.locals = {
	"submitted": "_1uV3aGKdjkzZB6PhP0eHAh",
	"close": "_2LMe_isYQgsSCCmZpUNCxH",
	"form": "_3Z2Ii3rUtSSLDB70O-zoTH",
	"askSubmitBtns": "_2KrVPwY7btef-DrQvxvu6c",
	"askSubmit": "_3gXrxvpl4UnKL34Pm-DbAE",
	"count": "_1-FipVCYG9BObDbs00PZg6"
};
module.exports = exports;
