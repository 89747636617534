import { Action } from 'redux';
import { IPaymentResult } from 'types';

import {
  createMonthlySubscription,
  verifyTransactionBySessionId,
  getNewPurchaseUrlBySessionId
} from '../../services';

export enum Types {
  PAYMENT_LOADING = 'PAYMENT_LOADING',
  CREATE_PAYMENT_INTENT_LOADED = 'CREATE_PAYMENT_INTENT_LOADED',
  CREATE_PAYMENT_INTENT_ERROR = 'CREATE_PAYMENT_INTENT_ERROR',
  WINDCAVE_VERIFY_PAYMENT_LOADED = `WINDCAVE_VERIFY_PAYMENT_LOADED`,
  WINDCAVE_VERIFY_PAYMENT_ERROR = `WINDCAVE_VERIFY_PAYMENT_ERROR`
}

export interface IPaymentLoading extends Action {
  type: Types.PAYMENT_LOADING;
  data: boolean;
}

export const PaymentLoading = (isLoading: boolean) =>
  ({
    type: Types.PAYMENT_LOADING,
    data: isLoading
  } as IPaymentLoading);

export interface ICreatePaymentIntentError extends Action {
  type: Types.CREATE_PAYMENT_INTENT_ERROR;
  data: string;
}

export const CreatePaymentIntentError = (data: string) =>
  ({
    type: Types.CREATE_PAYMENT_INTENT_ERROR,
    data
  } as ICreatePaymentIntentError);
export interface ICreatePaymentIntentLoaded extends Action {
  type: Types.CREATE_PAYMENT_INTENT_LOADED;
  data: IPaymentResult;
}

export const CreatePaymentIntentLoaded = (data: IPaymentResult) =>
  ({
    type: Types.CREATE_PAYMENT_INTENT_LOADED,
    data
  } as ICreatePaymentIntentLoaded);

export const CreateSubscription =
  ({ cardElement, billing_details }: any, stripe) =>
  async (dispatch) => {
    dispatch(PaymentLoading(true));
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details
    });

    if (error) {
      dispatch(CreatePaymentIntentError(error.message));
      dispatch(PaymentLoading(false));
    } else {
      try {
        const createSubResult = await createMonthlySubscription({
          paymentMethodId: paymentMethod.id
        });

        const resultData = createSubResult.data;

        const confirmPaymentResult = await stripe.confirmCardPayment(
          resultData.clientSecret,
          {
            payment_method: {
              card: cardElement,
              billing_details
            }
          }
        );

        if (confirmPaymentResult.error) {
          dispatch(
            CreatePaymentIntentError(confirmPaymentResult.error.message)
          );
          dispatch(PaymentLoading(false));
        } else {
          // Successful subscription payment
          dispatch(
            CreatePaymentIntentLoaded({
              ...confirmPaymentResult.paymentIntent,
              cardType: paymentMethod.card.brand,
              name: paymentMethod.billing_details.name
            })
          );
        }
      } catch (err) {
        dispatch(CreatePaymentIntentError(err.message));
        dispatch(PaymentLoading(false));
      }
    }
  };

export interface IVerifyWindcavePaymentLoaded extends Action {
  type: Types.WINDCAVE_VERIFY_PAYMENT_LOADED;
  data: any;
}

export const VerifyWindcavePaymentLoaded = (data: any) =>
  ({
    type: Types.WINDCAVE_VERIFY_PAYMENT_LOADED,
    data
  } as IVerifyWindcavePaymentLoaded);

export interface IVerifyWindcavePaymentFailed extends Action {
  type: Types.WINDCAVE_VERIFY_PAYMENT_ERROR;
  data: any;
}

export const VerifyWindcavePaymentFailed = (data: any) =>
  ({
    type: Types.WINDCAVE_VERIFY_PAYMENT_ERROR,
    data
  } as IVerifyWindcavePaymentFailed);

export const VerifyWindcavePayment =
  (sessionId: string) => async (dispatch) => {
    dispatch(PaymentLoading(true));
    try {
      const res = await verifyTransactionBySessionId(sessionId); // fetches to the database depeneding on what the user id is going to be,
      dispatch(VerifyWindcavePaymentLoaded(res.data)); // this is my reducuer(action(database)) dispatch allows to go to the reducer
      dispatch(PaymentLoading(false)); // after transaction completes then we remove the loading spinner
    } catch (err) {
      console.log(err);
      dispatch(PaymentLoading(false));
      dispatch(VerifyWindcavePaymentFailed(`Verify Payment Failed`));
    }
  };
export const GetNewPurchaseUrlBySessionId =
  (sessionId: string) => async (dispatch) => {
    dispatch(PaymentLoading(true));
    try {
      const res = await getNewPurchaseUrlBySessionId(sessionId);
      const paymentLink = res.data.paymentLinks.find(
        (l) => l.method === 'REDIRECT'
      );
      window.location.replace(paymentLink.href);
    } catch (err) {
      console.log(err);
      dispatch(PaymentLoading(false));
      dispatch(VerifyWindcavePaymentFailed(`Verify Payment Failed`));
    }
  };
