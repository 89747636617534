import styles from './disclaimer.scss';

interface IDisclaimerProps {
  boldText: string;
  normalText: string;
}

const Disclaimer = ({ boldText, normalText }: IDisclaimerProps) => {
  return (
    <>
      <div className={styles.disclaimer}>
        <b>{boldText}</b> {normalText}
      </div>
    </>
  );
};

export const AdviseDisclaimer = () => {
  return (
    <Disclaimer
      boldText="Quashed does not provide you with advice, we connect you with an
          insurance adviser that can."
      normalText="        If you purchase a policy through the adviser, Quashed gets a fee from the adviser. It’s free for you. By clicking scheduling a call, you agree for us to pass on your contact information to the adviser to get in touch. Our promise is to not spam you with emails or calls."
    />
  );
};

export default Disclaimer;
