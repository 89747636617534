import { getHeaderOptions, getHttpService } from "./http-service";

const PROPS_TO_IGNORE = ['wiki', 'q4eEmployers']

const httpClient = getHttpService();

export const getConfig = () => {
  return httpClient.get("/config", {
    headers: getHeaderOptions(),
  });
};

export const putConfig = (config: any) => {
  const sanitized = R.omit(PROPS_TO_IGNORE, config)
  return httpClient.put("/config", sanitized, {
    headers: getHeaderOptions(),
  });
};
