import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import styles from './button-toolbar.scss';
import { isBreakpoint } from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';

interface IButtonToolbarItem {
  name: string;
  itemClassName?: string;
  icon?: SemanticICONS | React.FC;
  isActive?: boolean;
  onClick?: (...args) => void;
}

export interface IButtonToolbarProps {
  items: IButtonToolbarItem[];
  active: string;
  onClick?: (...args) => void;
  className?: string;
  itemClassName?: string;
  responsive?: boolean;
}

const ButtonItem = (item: IButtonToolbarItem) => {
  const { name, icon: CustomIcon, onClick, isActive, itemClassName } = item;

  const handleClick = useCallback(() => onClick(item), [onClick]);
  const activeClassName = classnames(
    isActive && styles.active,
    isActive && 'active',
  );

  return (
    <div
      id={`PolicyDetails_Tab_${name}`}
      className={classnames(styles.item, itemClassName, activeClassName)}
      onClick={handleClick}
    >
      {CustomIcon && (
        <div className={classnames(styles.itemIcon, activeClassName)}>
          {typeof CustomIcon === 'string' ? (
            <Icon name={CustomIcon} />
          ) : (
            <CustomIcon />
          )}
        </div>
      )}
      <div
        className={classnames(styles.itemName, activeClassName)}
        title={name}
      >
        {name}
      </div>
    </div>
  );
};

const SmallButtonToolbar = (props) => {
  const { items, active, onClick } = props;

  const handlePrev = useCallback(() => {
    const activeIdx = R.findIndex(R.propEq('name', active), items);
    if (activeIdx >= 0) {
      const prevIdx = activeIdx - 1;
      // Loop to the last item if we click previous on the first item
      const item = prevIdx < 0 ? items[items.length - 1] : items[prevIdx];
      onClick(item);
    }
  }, [active, items, onClick]);

  const handleNext = useCallback(() => {
    const activeIdx = R.findIndex(R.propEq('name', active), items);
    if (activeIdx >= 0) {
      const nextIdx = activeIdx + 1;
      // Loop to the first item if we click next on the last item
      const item = nextIdx > items.length - 1 ? items[0] : items[nextIdx];
      onClick(item);
    }
  }, [active, items, onClick]);

  return items.length > 1 ? (
    <div className={styles.containerSmall}>
      <Icon name="chevron left" onClick={handlePrev} />
      <div className={styles.itemName}>{active}</div>
      <Icon name="chevron right" onClick={handleNext} />
    </div>
  ) : (
    <div className={styles.containerSmall}>
      <div className={styles.itemName}>{active}</div>
    </div>
  );
};

const ButtonToolbar = (props: IButtonToolbarProps) => {
  const {
    className,
    items,
    active,
    itemClassName,
    responsive,
    onClick,
  } = props;

  useBreakpoint();
  const isSmall = isBreakpoint('md');

  return isSmall && responsive ? (
    <SmallButtonToolbar {...props} />
  ) : (
    <div className={classnames(styles.container, className)}>
      {items.map((item) => (
        <ButtonItem
          key={item.name}
          onClick={onClick}
          isActive={active === item.name}
          itemClassName={itemClassName}
          {...item}
        />
      ))}
    </div>
  );
};

export default ButtonToolbar;
