import { Action } from 'redux';
import { Types } from 'actions';
import {
  IPolicyProvider,
  IPolicyType,
  IAppState,
  IPolicySubType,
  IPolicy,
  IAttachment,
  IPolicyRenewal,
  IInvitation,
  IAdminSelectedUser,
  IPolicyScan,
} from 'types';
import {
  getAllPolicyTypesByProviderId,
  createProviderPolicyMapping,
  deleteProviderPolicyTypeMapping,
  getAllPolicySubTypesByPolicyTypeId,
  createPolicyTypeSubTypeMapping,
  updatePolicyProvider,
  getUsers,
  getAttachmentsForPolicy,
  deleteUserById,
  updateProviderPolicyMapping,
  getUserById,
  updateUser,
  getAllPoliciesByUserId,
  getPolicyRenewalsByPolicyId,
  getInvitationsByUserId,
  getInvitationsByFamilyId,
  getPolicyScanByUserId,
} from 'services';
import { nullifyEmptyStrings } from 'helpers';

export interface IAdminLoading extends Action {
  type: Types.ADMIN_LOADING;
}

export const AdminLoading = () =>
  ({
    type: Types.ADMIN_LOADING,
  } as IAdminLoading);

export interface ISelectPolicySubType extends Action {
  type: Types.SELECT_POLICY_SUBTYPE;
  data: {
    policySubtypes: IPolicySubType[];
    id: string;
  };
}

export const SelectPolicySubType = (
  policySubtypes: IPolicySubType[],
  id: string,
) =>
  ({
    type: Types.SELECT_POLICY_SUBTYPE,
    data: {
      policySubtypes,
      id,
    },
  } as ISelectPolicySubType);

export interface IUpdateSelectedPolicySubType extends Action {
  type: Types.UPDATE_SELECTED_POLICY_SUBTYPE;
  data: IPolicySubType;
}

export const UpdateSelectedPolicySubType = (policySubtype: IPolicySubType) =>
  ({
    type: Types.UPDATE_SELECTED_POLICY_SUBTYPE,
    data: policySubtype,
  } as IUpdateSelectedPolicySubType);

export interface ISelectPolicyProvider extends Action {
  type: Types.SELECT_POLICY_PROVIDER;
  data: {
    policyProviders: IPolicyProvider[];
    id: string;
  };
}

export const SelectPolicyProvider = (
  policyProviders: IPolicyProvider[],
  id: string,
) =>
  ({
    type: Types.SELECT_POLICY_PROVIDER,
    data: {
      policyProviders,
      id,
    },
  } as ISelectPolicyProvider);

export interface ISelectUser extends Action {
  type: Types.SELECT_USER;
  data: {
    users: IAdminSelectedUser[];
    id: string;
  };
}

export const SelectUser = (users: IAdminSelectedUser[], id: string) =>
  ({
    type: Types.SELECT_USER,
    data: {
      users,
      id,
    },
  } as ISelectUser);

export interface ISelectPolicyType extends Action {
  type: Types.SELECT_POLICY_TYPE;
  data: {
    policyTypes: IPolicyType[];
    id: string;
  };
}

export const SelectPolicyType = (policyTypes: IPolicyType[], id: string) =>
  ({
    type: Types.SELECT_POLICY_TYPE,
    data: {
      policyTypes,
      id,
    },
  } as ISelectPolicyType);

export interface IUpdatePolicyProviderLoading extends Action {
  type: Types.UPDATE_POLICY_PROVIDER_LOADING;
}

export const UpdatePolicyProviderLoading = () =>
  ({
    type: Types.UPDATE_POLICY_PROVIDER_LOADING,
  } as IUpdatePolicyProviderLoading);

export interface IUpdatePolicyProviderLoaded extends Action {
  type: Types.UPDATE_POLICY_PROVIDER_LOADED;
  data: IPolicyProvider;
}

export const UpdatePolicyProviderLoaded = (data: IPolicyProvider) =>
  ({
    type: Types.UPDATE_POLICY_PROVIDER_LOADED,
    data,
  } as IUpdatePolicyProviderLoaded);

export const UpdatePolicyProvider = (provider: IPolicyProvider) => (
  dispatch,
) => {
  dispatch(UpdatePolicyProviderLoading());
  updatePolicyProvider(provider).then((result) => {
    dispatch(UpdatePolicyProviderLoaded(result.data));
    // getAllPolicyProviders().then((aresult: any) => {
    //   dispatch(
    //     GetAllPolicyProvidersLoaded(aresult.data.Items as IPolicyProvider[]),
    //   );
    // });
  });
};

export interface IGetPolicyTypesByProviderLoading extends Action {
  type: Types.GET_POLICY_TYPES_BY_PROVIDER_LOADING;
}

export const GetPolicyTypesByProviderLoading = () =>
  ({
    type: Types.GET_POLICY_TYPES_BY_PROVIDER_LOADING,
  } as IGetPolicyTypesByProviderLoading);

export interface IGetPolicyTypesByProviderLoaded extends Action {
  type: Types.GET_POLICY_TYPES_BY_PROVIDER_LOADED;
  data: IPolicyType[];
}

export const GetPolicyTypesByProviderLoaded = (data: IPolicyType[]) =>
  ({
    type: Types.GET_POLICY_TYPES_BY_PROVIDER_LOADED,
    data,
  } as IGetPolicyTypesByProviderLoaded);

export const GetPolicyTypesByProvider = (providerId) => (dispatch) => {
  dispatch(GetPolicyTypesByProviderLoading());
  getAllPolicyTypesByProviderId(providerId).then((result) => {
    const data: IPolicyType[] = result.data.Items;
    dispatch(GetPolicyTypesByProviderLoaded(data));
  });
};

export interface IGetPolicySubTypeByPolicyTypeLoaded extends Action {
  type: Types.GET_POLICY_SUB_TYPES_BY_POLICY_TYPE_LOADED;
  data: IPolicySubType[];
}

export const GetPolicySubTypeByPolicyTypeLoaded = (data: IPolicySubType[]) =>
  ({
    type: Types.GET_POLICY_SUB_TYPES_BY_POLICY_TYPE_LOADED,
    data,
  } as IGetPolicySubTypeByPolicyTypeLoaded);

export const GetPolicySubTypeByPolicyType = (policyTypeId) => (dispatch) => {
  getAllPolicySubTypesByPolicyTypeId(policyTypeId).then((result) => {
    const data: IPolicySubType[] = result.data.Items;
    dispatch(GetPolicySubTypeByPolicyTypeLoaded(data));
  });
};

export interface ICreateProviderPolicyMappingLoading extends Action {
  type: Types.CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING;
}

export const CreateProviderPolicyMappingLoading = () =>
  ({
    type: Types.CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING,
  } as ICreateProviderPolicyMappingLoading);

export interface ICreateProviderPolicyMappingLoaded extends Action {
  type: Types.CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED;
  data: IPolicyType;
}

export const CreateProviderPolicyMappingLoaded = (data: IPolicyType) =>
  ({
    type: Types.CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED,
    data,
  } as ICreateProviderPolicyMappingLoaded);

export const CreateProviderPolicyMapping = (policyTypeName) => (
  dispatch,
  getState,
) => {
  dispatch(CreateProviderPolicyMappingLoading());
  const appState: IAppState = getState();
  const policyType = appState.referenceData.policyTypes.find(
    (a) => a.name === policyTypeName || a.policyTypeName === policyTypeName,
  );
  const provider = appState.admin.selectedPolicyProvider;

  const newMapping = {
    id: provider.id,
    sk: policyType.id,
    data: 'type-provider-map',
    policyProviderName: provider.name,
    policyTypeName: policyType.name || policyType.policyTypeName,
  };

  createProviderPolicyMapping(newMapping).then((result) => {
    const data: IPolicyType = result.data;
    dispatch(CreateProviderPolicyMappingLoaded(data)); // todo: make it better than this
  });
};

export interface IUpdateProviderPolicyMappingLoaded extends Action {
  type: Types.UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED;
  data: any;
}

export const UpdateProviderPolicyMappingLoaded = (data: any) =>
  ({
    type: Types.UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED,
    data,
  } as IUpdateProviderPolicyMappingLoaded);

export const UpdateProviderPolicyMapping = (policyTypeProviderMap) => (
  dispatch,
) => {
  dispatch(CreateProviderPolicyMappingLoading());
  updateProviderPolicyMapping(policyTypeProviderMap).then((result) => {
    const data: IPolicyType = result.data;
    dispatch(UpdateProviderPolicyMappingLoaded(data));
  });
};

export interface IDeleteProviderPolicyTypeMappingLoaded extends Action {
  type: Types.DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADED;
  data: string;
}

export const DeleteProviderPolicyTypeMappingLoaded = (data: string) =>
  ({
    type: Types.DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADED,
    data,
  } as IDeleteProviderPolicyTypeMappingLoaded);

// todo: policyTypeId and providerId are swapped
export const DeleteProviderPolicyTypeMapping = (policyTypeId, providerId) => (
  dispatch,
) => {
  deleteProviderPolicyTypeMapping(policyTypeId, providerId).then(() => {
    dispatch(DeleteProviderPolicyTypeMappingLoaded(providerId));
  });
};

export interface ICreatePolicyTypeSubTypeMappingLoaded extends Action {
  type: Types.CREATE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED;
  data: IPolicySubType;
}

export const CreatePolicyTypeSubTypeMappingLoaded = (data: IPolicySubType) =>
  ({
    type: Types.CREATE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED,
    data,
  } as ICreatePolicyTypeSubTypeMappingLoaded);

export const CreatePolicyTypeSubTypeMapping = (policySubTypeName) => (
  dispatch,
  getState,
) => {
  const appState: IAppState = getState();
  const policySubType = appState.referenceData.policySubTypes.find(
    (a) =>
      a.name === policySubTypeName || a.policySubTypeName === policySubTypeName,
  );
  const policyType = appState.admin.selectedPolicyType;

  const newMapping = {
    id: policyType.id,
    sk: policySubType.id,
    data: 'policy-type-sub-type-map',
    policySubTypeName: policySubType.name || policySubType.policySubTypeName,
    policyTypeName: policyType.name || policyType.policyTypeName,
  };

  createPolicyTypeSubTypeMapping(newMapping).then((result) => {
    const data: IPolicySubType = result.data;
    dispatch(CreatePolicyTypeSubTypeMappingLoaded(data));
  });
};

export interface IDeletePolicyTypeSubTypeMappingLoaded extends Action {
  type: Types.DELETE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED;
  data: string;
}

export const DeletePolicyTypeSubTypeMappingLoaded = (data: string) =>
  ({
    type: Types.DELETE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED,
    data,
  } as IDeletePolicyTypeSubTypeMappingLoaded);

export interface IGetUsersLoaded extends Action {
  type: Types.GET_USERS_LOADED;
  data: IAdminSelectedUser[];
}

export const GetUsersLoaded = (data: IAdminSelectedUser[]) =>
  ({
    type: Types.GET_USERS_LOADED,
    data,
  } as IGetUsersLoaded);

export const GetUsers = (days: string) => (dispatch) => {
  dispatch(AdminLoading());
  getUsers(days).then((result) => {
    const data: IAdminSelectedUser[] = result.data.Items;
    dispatch(GetUsersLoaded(data));
  });
};

export interface IGetRenewalForPolicyLoaded extends Action {
  type: Types.GET_RENEWAL_FOR_POLICY_LOADED;
  data: { renewals: IPolicyRenewal[]; policyId: string };
}

export const IGetRenewalForPolicyLoaded = (data: {
  renewals: IPolicyRenewal[];
  policyId: string;
}) =>
  ({
    type: Types.GET_RENEWAL_FOR_POLICY_LOADED,
    data,
  } as IGetRenewalForPolicyLoaded);

export const GetRenewalForPolicy = (policyId: string) => (dispatch) => {
  getPolicyRenewalsByPolicyId(policyId).then((result) => {
    const data: IPolicyRenewal[] = result.data.Items;
    dispatch(IGetRenewalForPolicyLoaded({ renewals: data, policyId }));
  });
};

export interface IGetAttachmentForPolicyLoaded extends Action {
  type: Types.GET_ATTACHMENT_FOR_POLICY_LOADED;
  data: { attachments: IAttachment[]; policyId: string };
}

export const GetAttachmentForPolicyLoaded = (data: {
  attachments: IAttachment[];
  policyId: string;
}) =>
  ({
    type: Types.GET_ATTACHMENT_FOR_POLICY_LOADED,
    data,
  } as IGetAttachmentForPolicyLoaded);

export const GetAttachmentForPolicy = (policyId: string) => (dispatch) => {
  getAttachmentsForPolicy(policyId).then((result) => {
    const data: IAttachment[] = result.data.Items;
    dispatch(GetAttachmentForPolicyLoaded({ attachments: data, policyId }));
  });
};

export interface IAdminDeleteUserByIdLoaded extends Action {
  type: Types.ADMIN_DELETE_USER_BY_ID_LOADED;
  data: string;
}

export const AdminDeleteUserByIdLoaded = (data: string) => {
  return {
    type: Types.ADMIN_DELETE_USER_BY_ID_LOADED,
    data,
  } as IAdminDeleteUserByIdLoaded;
};

export const AdminDeleteUserById = (id: string) => () => {
  deleteUserById(id).then(() => {
    close();
  });
};

export interface IAdminGetUserLoaded extends Action {
  type: Types.ADMIN_GET_USER_BY_ID_LOADED;
  data: IAdminSelectedUser;
}

export const AdminGetUserLoaded = (user: IAdminSelectedUser) =>
  ({
    type: Types.ADMIN_GET_USER_BY_ID_LOADED,
    data: user,
  } as IAdminGetUserLoaded);

export const AdminGetUserById = (id: string) => (dispatch) => {
  getUserById(id).then((userRes) => {
    const userData: IAdminSelectedUser = userRes.data.Items[0];
    dispatch(AdminGetUserLoaded(userData));
  });
};

export interface IAdminUpdateUserLoaded extends Action {
  type: Types.ADMIN_UPDATE_USER_LOADED;
  data: IAdminSelectedUser;
}

export const AdminUpdateUserLoaded = (data: IAdminSelectedUser) =>
  ({
    type: Types.ADMIN_UPDATE_USER_LOADED,
    data,
  } as IAdminUpdateUserLoaded);

export const OperationUpdateUser = (data: IAdminSelectedUser) => (dispatch) => {
  const sanitized = nullifyEmptyStrings(data);
  updateUser(sanitized)
    .then(() => {
      dispatch(AdminUpdateUserLoaded(sanitized));
    })
    .catch(() => alert('Update user failed'));
};

export interface IAdminGetUserPolicies extends Action {
  type: Types.ADMIN_GET_USER_POLICIES;
  data: IPolicy[];
}

export const GetAdminUserPoliciesLoaded = (data: IPolicy[]) =>
  ({
    type: Types.ADMIN_GET_USER_POLICIES,
    data,
  } as IAdminGetUserPolicies);

export const GetAdminUserPolicies = (userId: string) => async (dispatch) => {
  const result = await getAllPoliciesByUserId(userId);
  const data: IPolicy[] = result.data.Items;
  dispatch(GetAdminUserPoliciesLoaded(data));
};

export interface IAdminGetUserInvitationsLoaded extends Action {
  type: Types.ADMIN_GET_USER_INVITATIONS_LOADED;
  data: IInvitation[];
}

export const AdminGetUserInvitationsLoaded = (invitations: IInvitation[]) => ({
  type: Types.ADMIN_GET_USER_INVITATIONS_LOADED,
  data: invitations,
});

export const AdminGetUserInvitations = (userId: string) => async (
  dispatch: any,
) => {
  try {
    const result = await getInvitationsByUserId(userId);
    dispatch(AdminGetUserInvitationsLoaded(result.data.Items));
  } catch (err) {
    alert('Admin - Get user invitations failed.');
  }
};

export interface IAdminGetUserSentInvitationsLoaded extends Action {
  type: Types.ADMIN_GET_USER_SENT_INVITATIONS_LOADED;
  data: IInvitation[];
}

export const AdminGetUserSentInvitationsLoaded = (
  invitations: IInvitation[],
) => ({
  type: Types.ADMIN_GET_USER_SENT_INVITATIONS_LOADED,
  data: invitations,
});

export const AdminGetUserSentInvitations = (familyId: string) => async (
  dispatch: any,
) => {
  try {
    const result = await getInvitationsByFamilyId(familyId);
    dispatch(AdminGetUserSentInvitationsLoaded(result.data.Items));
  } catch (err) {
    alert('Admin - Get user sent invitations failed');
  }
};

export interface IAdminGetUserPolicyScansLoaded extends Action {
  type: Types.ADMIN_GET_USER_POLICY_SCANS_LOADED;
  data: IPolicyScan[];
}

export const AdminGetUserPolicyScansLoaded = (marketScans: IPolicyScan[]) => ({
  type: Types.ADMIN_GET_USER_POLICY_SCANS_LOADED,
  data: marketScans,
});

export const AdminGetUserPolicyScans = (userId: string) => async (
  dispatch: any,
) => {
  try {
    const result = await getPolicyScanByUserId(userId);
    dispatch(AdminGetUserPolicyScansLoaded(result.data.Items));
  } catch (err) {
    alert('Admin - Get user Market Scans failed');
  }
};
