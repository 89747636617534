export const CALCULATOR_FIELDS = [
  {
    key: 'outstandingMortgage',
    label: 'Outstanding Mortgage',
    legend: 'for your outstanding mortgage',
    color: '#FBDC8E',
  },
  {
    key: 'otherDebts',
    label: 'Debts',
    legend: 'for your other debts',
    color: '#90A5C8',
  },
  {
    key: 'income',
    label: 'Dependents',
    legend: 'for your dependents',
    color: '#E1E2E8',
    calculateValue: (value, formValues) => value * (formValues.familyDependentYears || 1),
  },
];

export const getChartOptions = (formValues) => {
  const series = [];
  const labels = [];
  const colors = [];

  CALCULATOR_FIELDS.forEach((f) => {
    let value = formValues[f.key];

    if (value) {
      value = f.calculateValue ? f.calculateValue(value, formValues) : value;
      series.push(value);
      labels.push(f.label);
      colors.push(f.color);
    }
  });

  return {
    series,
    options: {
      labels,
      colors,
      chart: {
        type: 'donut',
        width: '100%',
        height: '100%'
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            background: 'white',
            labels: {
              show: true,
              value: {
                offsetY: -5,
                fontSize: '18px',
                fontFamily: 'Open Sans',
                fontWeight: '700'
              },
              total: {
                show: true,
                showAlways: true,
                label: '',
                formatter: (w) => {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                  return `$${total.toLocaleString()}`;
                }
              }
            }
          }
        }
      },
      tooltip: {
        y: {
          formatter: (x) => `$${x.toLocaleString()}`,
        }
      }
    },
  };
};