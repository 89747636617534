import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styles from './styles/MenuDropdown.module.scss';

interface IProps {
  className?: string;
  containerId?: string;
  items: Array<{
    icon: React.FC;
    actionId?: string;
    text: string;
    onClick(props: any): void;
  }>;
  [key: string]: any;
}

const MenuDropdown = (props: IProps) => {
  const { className, containerId, items } = props;

  return (
    <Dropdown
      id={containerId}
      icon="ellipsis vertical"
      floating={true}
      labeled={true}
      className={classnames(styles.action, className)}
    >
      <Dropdown.Menu>
        <Dropdown.Header>View option</Dropdown.Header>
        {items.map(({ icon: Icon, actionId, text, getText, onClick }, idx) => (
          <Dropdown.Item
            key={`${containerId}_${actionId}_${idx}`}
            id={`${containerId}_${actionId}`}
            className={styles.actionItem}
            onClick={() => onClick(props)}
          >
            <Icon />
            {getText ? getText(props) : text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuDropdown;
