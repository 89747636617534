import { useState } from 'react';
import styles from './index.scss';
import { triggerPasswordReset } from 'services';
import { Message } from 'semantic-ui-react';

export interface IPasswordResetProps {
  email: string;
  className?: string;
  children?: string;
}

const DEFAULT_TEXT = 'Change Password';

const PasswordReset = ({ email, className, children = DEFAULT_TEXT }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const handleClick = () => {
    triggerPasswordReset(email).then(() => {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 5000);
    });
  };

  return (
    <div className={classnames(styles.passwordReset, className)}>
      {showSuccess && (
        <Message color="green">{`A reset password email has been sent to ${email}.`}</Message>
      )}
      <div className={styles.text} onClick={handleClick}>
        {children}
      </div>
    </div>
  );
};

export default React.memo(PasswordReset);
