// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2d6OyCxuHVOqZVu5HtLCxB.ui.modal{padding:40px;width:480px}._2d6OyCxuHVOqZVu5HtLCxB.ui.modal > .content{padding:0 !important}.QNfm8rPh_OyDTfLeVpKpk{font-family:\"DM Sans\";font-weight:400;font-size:18px;margin:auto;display:flex;flex-direction:column;text-align:left}._2HW-Ecd6vGTeCwiLIVgwvf{width:40px;height:40px;margin-bottom:16px}._24nh9fXPFawx3JZ7o7_GM_{font-size:20px;font-weight:700;margin-bottom:0;padding-bottom:0;margin-bottom:8px}._1YplTexyHsCxo_3fTLWIr1{font-family:\"Open Sans\";font-size:12px;font-weight:400;margin-bottom:24px}.JkpTFboSWeKXY0I2u0qqo.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;align-self:center;width:305px;background-color:#3d67ab}@media(max-width: 576px){.JkpTFboSWeKXY0I2u0qqo.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}.pbeK6TSN__oJejXgEx1KZ{display:none;cursor:pointer}@media(max-width: 576px){.pbeK6TSN__oJejXgEx1KZ{display:block;position:absolute;top:24px;right:24px}.JkpTFboSWeKXY0I2u0qqo.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;width:100%}}@media(max-width: 576px)and (max-width: 576px){.JkpTFboSWeKXY0I2u0qqo.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 576px){._2d6OyCxuHVOqZVu5HtLCxB.ui.modal{width:100%;padding:24px}._1YplTexyHsCxo_3fTLWIr1{margin-bottom:48px}}", ""]);
// Exports
exports.locals = {
	"modal": "_2d6OyCxuHVOqZVu5HtLCxB",
	"content": "QNfm8rPh_OyDTfLeVpKpk",
	"svg": "_2HW-Ecd6vGTeCwiLIVgwvf",
	"title": "_24nh9fXPFawx3JZ7o7_GM_",
	"subText": "_1YplTexyHsCxo_3fTLWIr1",
	"btn": "JkpTFboSWeKXY0I2u0qqo",
	"xBtn": "pbeK6TSN__oJejXgEx1KZ"
};
module.exports = exports;
