import moment from 'moment';
import { Action } from 'redux';

import { IAppState, IQuoteCoverRequest, IUser, IUserQuote } from 'types';
import {
  getUserById,
  updateUser,
  deleteUserFromFamily,
  updateQuote,
  requestCover,
  getAdvisersByOrgId,
  getReferrals,
  uploadUserProfileAttachment,
  IFile,
} from 'services';
import {
  nullifyEmptyStrings,
  trackEvent,
  FB_EVENTS,
  getClaimsToken,
} from 'helpers';
import { SubscriptionStatus } from 'constants/user';

const LAST_ONBOARDING_STEP = 1;

export enum Types {
  GET_USER_BY_ID_LOADING = 'GET_USER_BY_ID_LOADING',
  GET_USER_BY_ID_LOADED = 'GET_USER_BY_ID_LOADED',
  GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED',
  UPDATE_USER_LOADING = 'UPDATE_USER_LOADING',
  UPDATE_USER_LOADED = 'UPDATE_USER_LOADED',
  UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
  SET_CURRENT_FAMILY = 'SET_CURRENT_FAMILY',
}

export interface IGetUserByIdLoading extends Action {
  type: Types.GET_USER_BY_ID_LOADING;
}

export interface IGetUserByIdLoaded extends Action {
  type: Types.GET_USER_BY_ID_LOADED;
  data: IUser;
}

export interface IGetUserByIdFailed extends Action {
  type: Types.GET_USER_BY_ID_FAILED;
  data: string;
}

export interface IUpdateUserLoading extends Action {
  type: Types.UPDATE_USER_LOADING;
}

export interface IUpdateUserLoaded extends Action {
  type: Types.UPDATE_USER_LOADED;
  data: IUser;
}

export interface ISetCurrentFamily extends Action {
  type: Types.SET_CURRENT_FAMILY;
  data?: string;
}

export const SetCurrentFamily = (data: string) => {
  return {
    type: Types.SET_CURRENT_FAMILY,
    data,
  } as ISetCurrentFamily;
};

export const GetUserByIdLoading = () =>
  ({
    type: Types.GET_USER_BY_ID_LOADING,
  } as IGetUserByIdLoading);

export const GetUserByIdLoaded = (data: IUser) =>
  ({
    type: Types.GET_USER_BY_ID_LOADED,
    data,
  } as IGetUserByIdLoaded);

export const GetUserByIdFailed = (data?: string) =>
  ({
    type: Types.GET_USER_BY_ID_FAILED,
    data,
  } as IGetUserByIdFailed);

export const UpdateUserLoading = () =>
  ({
    type: Types.UPDATE_USER_LOADING,
  } as IUpdateUserLoading);

export const UpdateUserLoaded = (data: IUser) =>
  ({
    type: Types.UPDATE_USER_LOADED,
    data,
  } as IUpdateUserLoaded);

export const GetUserByEmail = (email) => async (dispatch) => {
  dispatch(GetUserByIdLoading());
  try {
    const result = await getUserById(null, email);
    const data: IUser = result.data.Items[0];
    dispatch(GetUserByIdLoaded(data));
  } catch {
    dispatch(GetUserByIdFailed('Email address is incorrect'));
  }
};

export const UpdateUser = (data: IUser) => async (dispatch) => {
  dispatch(UpdateUserLoading());
  const sanitized = nullifyEmptyStrings(data);
  dispatch(UpdateUserLoaded(sanitized));
  updateUser(sanitized);
};

export const GetLoggedInUser = () => async (dispatch) => {
  dispatch(GetUserByIdLoading());
  try {
    const result = await getUserById();
    const data: IUser = result.data.Items[0];

    // Get current user's adviser
    if (data.adviserOrgId) {
      const orgResults = await getAdvisersByOrgId(data.adviserOrgId);
      data.advisers = R.pathOr([], ['data', 'advisers'], orgResults);
    }

    const user = {
      ...data,
      lastUpdatedAt: moment.utc().format(),
    };

    if (user.newUser) {
      setTimeout(() => {
        const userClaims = getClaimsToken();
        trackEvent(FB_EVENTS.Subscribe, {
          userId: userClaims.sub,
          value: '0.00',
          currency: 'USD',
          predicted_ltv: '0.00',
        });
      }, 1000);

      user.newUser = false;
      updateUser(user);
    }
    dispatch(GetUserByIdLoaded(user));
  } catch {
    dispatch(GetUserByIdFailed());
  }
};

export const DeleteUserFromFamily = (
  userId: string,
  familyId: string,
) => () => {
  if (userId) {
    deleteUserFromFamily(userId, familyId);
  }
};

export const UpdateUserOnboardingStep = (step = 0) => (dispatch, getState) => {
  const { user }: IAppState = getState();
  if (user.id) {
    const updated = R.assoc('onboardingStep', step + 1, user);

    if (step >= LAST_ONBOARDING_STEP) {
      updated.walkthroughCompleted = true;
    }

    updateUser(updated);
    dispatch(UpdateUserLoaded(updated));
  }
};

export const UpdateUserProfilePhoto = (file: IFile) => async (
  dispatch,
  getState,
) => {
  const { user }: IAppState = getState();
  const { data } = await uploadUserProfileAttachment(user, file);
  // We use the actual picture src so it doesnt save as blob:localhost:7777/....
  const updated = R.assoc('picture', data.picture, user);
  dispatch(UpdateUserLoaded(updated));
};

export const UpdateUserQuote = (quote: IUserQuote) => (dispatch, getState) => {
  const { user }: IAppState = getState();
  const quoteIdx = R.findIndex(R.propEq('id', quote.id), user.quotes);
  if (quoteIdx >= 0) {
    updateQuote(quote);
    const updatedQuotes = R.update(quoteIdx, quote, user.quotes);
    dispatch(UpdateUserLoaded(R.assoc('quotes', updatedQuotes, user)));
  }
};

export const CreateUserQuote = (payload: IQuoteCoverRequest) => (
  dispatch,
  getState,
) => {
  const { user }: IAppState = getState();
  requestCover(payload).then(({ data }) => {
    const updatedQuotes = [data[1], ...user.quotes];
    dispatch(UpdateUserLoaded(R.assoc('quotes', updatedQuotes, user)));
  });
};

export const ActivateSubscription = () => (dispatch, getState) => {
  const { user }: IAppState = getState();
  dispatch(
    UpdateUserLoaded(
      R.assoc('subscriptionStatus', SubscriptionStatus.ACTIVE, user),
    ),
  );
};
