// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".FnZYn5rO_Ci5aX8GTvJV0{display:inline;text-align:left;color:#34363e}._1RxOZc41b7xWkrzBKnFa6O{font-family:\"Open Sans\";font-weight:400;font-size:14px;color:#34363e;text-align:left;margin-top:4px;margin-bottom:24px}._2ihbKOerCR4LS-XyczRuMD.ui.image{display:inline-block;margin-right:16px;width:36px}._1PvhHXpVNLSl27hEjGvjQG{width:24px;height:auto;margin-right:16px}._29yKfABIUICiiSTv6HkSWe.ui.popup{max-width:calc(100% - 32px);background-color:#ebedf3;padding:16px 24px}._2oJyxE4v-uxNF18jdFYMFY{margin-left:24px;fill:#c4c4c4}@media(max-width: 992px){._29yKfABIUICiiSTv6HkSWe.ui.popup{max-width:calc(100vw - 32px)}}@media(max-width: 820px){.FnZYn5rO_Ci5aX8GTvJV0{margin-top:0;max-width:none}._2EifnncCS4ALYcjsb1iS4w{width:100%}}@media(max-width: 576px){.FnZYn5rO_Ci5aX8GTvJV0{font-size:20px !important}._2oJyxE4v-uxNF18jdFYMFY{margin-left:16px;vertical-align:sub}}", ""]);
// Exports
exports.locals = {
	"title": "FnZYn5rO_Ci5aX8GTvJV0",
	"subtitle": "_1RxOZc41b7xWkrzBKnFa6O",
	"image": "_2ihbKOerCR4LS-XyczRuMD",
	"svg": "_1PvhHXpVNLSl27hEjGvjQG",
	"tooltip": "_29yKfABIUICiiSTv6HkSWe",
	"toggle": "_2oJyxE4v-uxNF18jdFYMFY",
	"right": "_2EifnncCS4ALYcjsb1iS4w"
};
module.exports = exports;
