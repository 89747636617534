import React from 'react';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/TextArea.module.scss';

interface IProps {
  hideCount?: boolean;
}

const TextArea = (
  {
    label,
    className,
    inputClassName,
    labelClassName,
    leftIcon,
    rightIcon,
    error,
    rows = 2,
    hideCount,
    ...inputProps
  }: IProps & IFormFieldProps & React.HTMLProps<HTMLTextAreaElement>,
  ref: React.Ref<HTMLTextAreaElement>,
) => {
  return (
    <InputWrap
      className={classnames(styles.textarea, className)}
      errorClassName={styles.error}
      error={error}
      label={label}
      labelClassName={labelClassName}
      name={inputProps.name}
      rightIcon={rightIcon}
    >
      <textarea
        ref={ref}
        id={inputProps.name}
        {...inputProps}
        rows={rows}
        className={classnames(styles.input, inputClassName)}
      />
      {inputProps.maxLength && !hideCount && (
        <span className={styles.length}>
          {inputProps.value?.length || 0} / {inputProps.maxLength}
        </span>
      )}
    </InputWrap>
  );
};

export default React.forwardRef(TextArea);
