import { INSURANCE_TYPES_NAMES } from './insurance';

export const POLICY_CHECK_INTERVAL = 3000;
export const POLICY_CHECK_MAX_COUNT = 40; // 2 mins max
export const POLICY_CHECK_MAX_LENGTH =
  POLICY_CHECK_INTERVAL * POLICY_CHECK_MAX_COUNT;

export enum PolicyStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  FINISH_SETUP = 'FINISH_SETUP',
  IN_REVIEW = 'IN_REVIEW',
  PROCESSING = 'PROCESSING',
  RENEWING = 'RENEWING',
  INCOMPLETE = 'INCOMPLETE',
  MARKET_SCAN_DRAFT = 'MARKET_SCAN_DRAFT',
  MARKET_SCAN_HOLDER = 'MARKET_SCAN_HOLDER',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  PENDING_SCAN = 'PENDING_SCAN',
  READY_SCAN = 'READY_SCAN',
  ORDER_PLACED = 'ORDER_PLACED',
}

export const POLICY_STATUS = {
  [PolicyStatus.ACTIVE]: 'Active',
  [PolicyStatus.EXPIRED]: 'Expired',
  [PolicyStatus.FINISH_SETUP]: 'Finish Setup',
  [PolicyStatus.IN_REVIEW]: 'In Review',
  [PolicyStatus.PROCESSING]: 'Processing',
  [PolicyStatus.RENEWING]: 'Renewing',
  [PolicyStatus.INCOMPLETE]: 'Incomplete',
  [PolicyStatus.MARKET_SCAN_DRAFT]: 'Market Scan Draft',
  [PolicyStatus.MARKET_SCAN_HOLDER]: 'Market Scan Holder',
  [PolicyStatus.MAKE_PAYMENT]: 'Make Payment',
  [PolicyStatus.PENDING_SCAN]: 'Pending Scan',
  [PolicyStatus.READY_SCAN]: 'Scan Ready',
  [PolicyStatus.ORDER_PLACED]: 'Order Placed',
};

export const POLICY_STATUS_TOOLTIPS = {
  [PolicyStatus.ACTIVE]: 'Cover is up-to-date.',
  [PolicyStatus.EXPIRED]:
    'You have an upcoming renewal or your policy is out of date. Check your policy.',
  [PolicyStatus.FINISH_SETUP]:
    'Add your policy to your dashboard. Click to start.',
  [PolicyStatus.IN_REVIEW]: {
    in:
      'This shouldn’t take long, we’ll drop you an email when it’s ready in just a few moments.',
    out:
      'It’s just past office hours so this might take a little longer than usual. We’ll drop you an email as soon as it’s ready. ',
  },
  [PolicyStatus.INCOMPLETE]: 'Some information is missing. Complete it now.',
  [PolicyStatus.PROCESSING]:
    "We're picking up the information. Stick around, it's almost done.",
  [PolicyStatus.MARKET_SCAN_DRAFT]:
    "You've started a Market Scan, return to Market Scan tab to complete.",
  [PolicyStatus.RENEWING]:
    'Great, your upcoming policy changes will be displayed on the renewal date.',
  [PolicyStatus.MAKE_PAYMENT]: 'Please pay invoice for this cover',
  [PolicyStatus.PENDING_SCAN]:
    "We're scanning the market for you. You'll get an email when it's done in just a few moments.",
  [PolicyStatus.READY_SCAN]:
    'Hooray! Your scan results are back - check out your cover options.',
  [PolicyStatus.ORDER_PLACED]: `Our adviser is reviewing your order for this policy. Once it's ready, the status will become "Active".`,
};

export const POLICY_EMPLOYER_VALUE_MAP = {
  premium: 'Covered',
  frequency: 'With employment',
  policyNumber: 'With employment',
  renewalDate: 'With employment',
  premiumCover: 'With employment',
  paymentMethod: 'With employment',
  excess: 'With employment',
};

export const BROKER_POLICY_TYPES = [
  INSURANCE_TYPES_NAMES.VEHICLE,
  INSURANCE_TYPES_NAMES.CONTENT,
  INSURANCE_TYPES_NAMES.HOME,
];

export const POLICY_PRIORITY = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: 15,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: 14,
  [INSURANCE_TYPES_NAMES.CONTENT]: 12,
  [INSURANCE_TYPES_NAMES.DISABILITY]: 5,
  [INSURANCE_TYPES_NAMES.HEALTH]: 7,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: 9,
  [INSURANCE_TYPES_NAMES.HOME]: 17,
  [INSURANCE_TYPES_NAMES.INCOME]: 3,
  [INSURANCE_TYPES_NAMES.LIFE]: 8,
  [INSURANCE_TYPES_NAMES.LANDLORD]: 16,
  [INSURANCE_TYPES_NAMES.MORTGAGE]: 2,
  [INSURANCE_TYPES_NAMES.PET]: 11,
  [INSURANCE_TYPES_NAMES.RENTERS]: 10,
  [INSURANCE_TYPES_NAMES.TRAUMA]: 6,
  [INSURANCE_TYPES_NAMES.BOAT]: 13,
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: 1,
  [INSURANCE_TYPES_NAMES.TRAVEL]: 4,
  [INSURANCE_TYPES_NAMES.OTHER]: 0,
};
