import React from 'react';
import styles from '../styles/PremiumIntro.module.scss';
import cx from 'classnames';

const BulletPoint = ({ number, heading, content }) => {
  return (
    <div className={styles.flexRow}>
      <div className={cx(styles.subHeading, styles.bulletPoint)}>
        <div className={styles.bulletPointNumber}>{number}</div>
      </div>
      <div className={styles.flexCol}>
        <div className={cx(styles.subHeading, styles.paddingT4)}>{heading}</div>
        <div
          className={cx(
            styles.content,
            styles.charcoal,
            styles.paddingBulletpoint,
          )}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default BulletPoint;
