import { Action } from 'redux';

declare const close: () => void;

import {
  IAddPolicyMode,
  IBundle,
  IFeedback,
  IPolicyScan,
  IFilteredPolicy,
  IReferral,
  IAdviserConnect,
  IPolicy,
  IEmailNotificationState,
  IMarketScanFeedback,
} from 'types';
import {
  createBundleWithAttachments,
  deletePolicyScan,
  getAllPolicyScans,
  getBundlesForUser,
  getPolicyScanById,
  rerunPolicyScan,
  updatePolicyScanAdmin,
  getFeedbacks,
  getAllPolicies,
  getAllReferralList,
  getAllAdviserUserConnects,
  getAdviserConnectByIdSk,
  updateAdviserConnect,
  createPolicyRenewal,
  getEmailNotifications,
  getFormSubmissionDataByDataType,
  getAllPolicyScansByEmail,
  getPolicyScanPayload,
  getAllAdviserUserConnectsForUser,
} from 'services';
import { convertToDateOnlyString, sortDateDesc } from 'helpers';
import { GetRenewalForPolicy } from './admin-actions';
import moment from 'moment';
import { AdminUpdatePolicy } from './operation-policy-actions';
import { MeetingStatus } from 'constants/adviser';

export enum Types {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
  POLICY_SCANS_LOADED = 'POLICY_SCANS_LOADED',
  POLICY_SCAN_LOADED = 'POLICY_SCAN_LOADED',
  POLICY_SCAN_LOCAL_UPDATED = 'POLICY_SCAN_LOCAL_UPDATED',
  FILTERED_POLICYS_LOADED = 'FILTERED_POLICYS_LOADED',
  ADD_POLICY_MODEL_OPEN = 'ADD_POLICY_MODEL_OPEN',
  USER_BUNDLES_LOADED = 'USER_BUNDLES_LOADED',
  FEEDBACK_LOADED = 'FEEDBACK_LOADED',
  REFERRAL_LOADED = 'REFERRAL_LOADED',
  ADVISER_CONNECT_LOADED = 'ADVISER_CONNECT_LOADED',
  SELECTED_ADVISER_CONNECT_LOADED = 'SELECTED_ADVISER_CONNECT_LOADED',
  SELECTED_USER_ADVISER_CONNECTS_LOADED = 'SELECTED_USER_ADVISER_CONNECTS_LOADED',
  ADMIN_UPDATE_POLICY_LOADED = 'ADMIN_UPDATE_POLICY_LOADED',
  EMAIL_NOTIFICATION = 'EMAIL_NOTIFICATION',
  MARKET_SCAN_FEEDBACKS_LOADED = 'MARKET_SCAN_FEEDBACKS_LOADED',
  POLICY_SCAN_PAYLOAD_LOADED = 'POLICY_SCAN_PAYLOAD_LOADED',
  UPDATE_ADVISER_CONNECT_STATUS_LOCAL = 'UPDATE_ADVISER_CONNECT_STATUS_LOCAL',
  UPDATE_ADVISER_CONNECT_POLICY_SCANS_LOCAL = 'UPDATE_ADVISER_CONNECT_POLICY_SCANS_LOCAL',
}

export interface ILoading extends Action {
  type: Types.LOADING;
}

export interface ILoaded extends Action {
  type: Types.LOADED;
}

export interface IFailed extends Action {
  type: Types.FAILED;
  data?: string;
}

export const Loading = () =>
  ({
    type: Types.LOADING,
  } as ILoading);

export const Loaded = () =>
  ({
    type: Types.LOADED,
  } as ILoaded);

export const Failed = (data?: string) =>
  ({
    type: Types.FAILED,
    data,
  } as IFailed);

export interface IUserBundlesLoaded extends Action {
  type: Types.USER_BUNDLES_LOADED;
  data: IBundle[];
}

export const UserBundlesLoaded = (bundles: IBundle[]) =>
  ({
    type: Types.USER_BUNDLES_LOADED,
    data: bundles,
  } as IUserBundlesLoaded);

export interface IAddPolicyModelOpen extends Action {
  type: Types.ADD_POLICY_MODEL_OPEN;
  data: { isOpen: boolean; mode: IAddPolicyMode };
}

export const AddPolicyModelOpen = (data: {
  isOpen: boolean;
  mode: IAddPolicyMode;
}) =>
  ({
    type: Types.ADD_POLICY_MODEL_OPEN,
    data,
  } as IAddPolicyModelOpen);

export interface IPolicyScansLoaded extends Action {
  type: Types.POLICY_SCANS_LOADED;
  data: IPolicyScan[];
}

export interface IPolicyScanLocalUpdated extends Action {
  type: Types.POLICY_SCAN_LOCAL_UPDATED;
  data: IPolicyScan;
}

export const PolicyScanLocalUpdated = (data: IPolicyScan) =>
  ({
    type: Types.POLICY_SCAN_LOCAL_UPDATED,
    data,
  } as IPolicyScanLocalUpdated);

export const PolicyScansLoaded = (data: IPolicyScan[]) => ({
  type: Types.POLICY_SCANS_LOADED,
  data,
});

export interface IPolicyScanLoaded extends Action {
  type: Types.POLICY_SCAN_LOADED;
  data: IPolicyScan;
}

export const PolicyScanLoaded = (data: IPolicyScan) => ({
  type: Types.POLICY_SCAN_LOADED,
  data,
});

export interface IFeedbackLoaded extends Action {
  type: Types.FEEDBACK_LOADED;
  data: IFeedback[];
}

export const FeedbackLoaded = (data: IFeedback[]) =>
  ({
    type: Types.FEEDBACK_LOADED,
    data,
  } as IFeedbackLoaded);

export const GetFeedbacks = (type?: string, loadAll?: boolean) => async (
  dispatch,
) => {
  dispatch(Loading());
  try {
    const response = await getFeedbacks(type, loadAll);
    dispatch(FeedbackLoaded(response.data));
    dispatch(Loaded());
  } catch (err) {
    dispatch(Failed());
  }
};

export interface IEmailNotificationLoaded extends Action {
  type: Types.EMAIL_NOTIFICATION;
  data: IEmailNotificationState[];
}

export const EmailNotificationLoaded = (data: IEmailNotificationState[]) =>
  ({
    type: Types.EMAIL_NOTIFICATION,
    data,
  } as IEmailNotificationLoaded);

export const GetEmailNotifications = (
  caStart?: string,
  caEnd?: string,
) => async (dispatch) => {
  try {
    const response = await getEmailNotifications(caStart, caEnd);
    dispatch(EmailNotificationLoaded(response.data.sort(sortDateDesc)));
  } catch (err) {
    dispatch(Failed());
  }
};

export interface IReferralLoaded extends Action {
  type: Types.REFERRAL_LOADED;
  data: IReferral[];
}

export const ReferralLoaded = (data: IReferral[]) =>
  ({
    type: Types.REFERRAL_LOADED,
    data,
  } as IReferralLoaded);

export const GetReferralList = (caStart?: string, caEnd?: string) => async (
  dispatch,
) => {
  dispatch(Loading());
  try {
    const response = await getAllReferralList(caStart, caEnd);
    dispatch(ReferralLoaded(response.data));
    dispatch(Loaded());
  } catch (err) {
    dispatch(Failed());
  }
};

export interface IFilteredPoliciesLoaded extends Action {
  type: Types.FILTERED_POLICYS_LOADED;
  data: IFilteredPolicy[];
}

export const FilteredPoliciesLoaded = (data: IFilteredPolicy[]) =>
  ({
    type: Types.FILTERED_POLICYS_LOADED,
    data,
  } as IFilteredPoliciesLoaded);

export const GetFilteredPolicies = (
  status?: string,
  policyType?: string,
  caStart?: string,
  caEnd?: string,
  rdStart?: string,
  rdEnd?: string,
) => async (dispatch) => {
  dispatch(Loading());
  try {
    const response = await getAllPolicies(
      status,
      policyType,
      caStart,
      caEnd,
      rdStart,
      rdEnd,
    );
    dispatch(FilteredPoliciesLoaded(response.data.Items));
    dispatch(Loaded());
  } catch (err) {
    dispatch(Failed(err.message));
  }
};

export const GetPolicyScan = (id: string, sk?: string) => async (dispatch) => {
  dispatch(Loading());
  try {
    const response = await getPolicyScanById(id, sk);
    dispatch(PolicyScanLoaded(response.data));
    dispatch(Loaded());
  } catch (err) {
    dispatch(Failed(err.message));
  }
};

export const GetPolicyScans = (caStart?: string, caEnd?: string) => async (
  dispatch,
) => {
  dispatch(Loading());
  try {
    const response = await getAllPolicyScans(caStart, caEnd);
    dispatch(PolicyScansLoaded(response.data));
    dispatch(Loaded());
  } catch (err) {
    dispatch(Failed());
  }
};

export const GetPolicyScansByEmail = (email: string) => async (dispatch) => {
  dispatch(Loading());
  try {
    const response = await getAllPolicyScansByEmail(email);
    dispatch(PolicyScansLoaded(response.data));
    dispatch(Loaded());
  } catch (err) {
    dispatch(
      Failed(`Unable to match the email "${email}", to any in the system.`),
    );
  }
};

export const UpdatePolicyScanAdmin = (policyScan: IPolicyScan) => (
  dispatch,
) => {
  dispatch(Loading());
  updatePolicyScanAdmin(policyScan)
    .then((result) => {
      const updated = R.merge(policyScan, result.data);
      dispatch(PolicyScanLoaded(updated));
      dispatch(Loaded());
    })
    .catch(() => dispatch(Failed()));
};

export const RerunPolicyScan = (
  id: string,
  sk: string,
  policyProviderName: string,
) => (dispatch) => {
  dispatch(Loading());
  rerunPolicyScan(id, sk, policyProviderName)
    .then((result) => {
      dispatch(Loaded());
    })
    .catch(() => dispatch(Failed()));
};

export const GetPolicyScanPayloadForProvider = (
  id: string,
  sk: string,
  policyProviderName: string,
) => (dispatch) => {
  dispatch(Loading());
  getPolicyScanPayload(id, sk, policyProviderName)
    .then((result) => {
      dispatch(Loaded());
      dispatch(PolicyScanPayloadLoaded(result.data));
    })
    .catch(() => dispatch(Failed()));
};

export const DeletePolicyScan = (id: string, sk: string) => (dispatch) => {
  dispatch(Loading());
  deletePolicyScan(id, sk)
    .then(() => {
      dispatch(Loaded());
      close();
    })
    .catch(() => dispatch(Failed()));
};

export const OperationCreateBundleWithAttachments = (
  attachmentFiles,
  userId?: string,
) => async (dispatch) => {
  dispatch(Loading());
  try {
    await createBundleWithAttachments(attachmentFiles, userId);
    dispatch(Loaded());
  } catch {
    alert(`Error OperationCreateBundleWithAttachments`);
  }
};

export const OperationGetUserBundles = (userId: string) => async (dispatch) => {
  dispatch(Loading());
  const result = await getBundlesForUser(userId);
  dispatch(UserBundlesLoaded(result.data));
  dispatch(Loaded());
};

export interface IOperationGetAdviserConnectLoaded extends Action {
  type: Types.ADVISER_CONNECT_LOADED;
  data: IAdviserConnect[];
}

export const OperationGetAdviserConnectsLoaded = (
  invitations: IAdviserConnect[],
) => ({
  type: Types.ADVISER_CONNECT_LOADED,
  data: invitations,
});

export const OperationGetAdviserConnects = () => async (dispatch: any) => {
  dispatch(Loading());
  try {
    const result = await getAllAdviserUserConnects();
    console.log(result);
    dispatch(OperationGetAdviserConnectsLoaded(result.data));
    console.log('got here...');
  } catch (err) {
    alert('Operation - getAllAdviserUserConnects failed');
  }
};

export interface IOperationGetSelectedAdviserConnectLoaded extends Action {
  type: Types.SELECTED_ADVISER_CONNECT_LOADED;
  data: IAdviserConnect;
}

export const OperationGetSelectedAdviserConnectLoaded = (
  adviserConnect: IAdviserConnect,
) => ({
  type: Types.SELECTED_ADVISER_CONNECT_LOADED,
  data: adviserConnect,
});

export const OperationGetSelectedAdviserConnect = (
  id: string,
  sk: string,
) => async (dispatch: any) => {
  try {
    dispatch(Loading());
    const result = await getAdviserConnectByIdSk(id, sk);
    dispatch(OperationGetSelectedAdviserConnectLoaded(result.data));
    dispatch(Loaded());
  } catch (err) {
    alert('Operation - Get adviser connects failed');
  }
};

export interface IOperationGetAdviserConnectsForUserLoaded extends Action {
  type: Types.SELECTED_USER_ADVISER_CONNECTS_LOADED;
  data: IAdviserConnect[];
}

export const OperationGetAdviserConnectsForUserLoaded = (
  adviserUserConnects: IAdviserConnect[],
) =>
  ({
    type: Types.SELECTED_USER_ADVISER_CONNECTS_LOADED,
    data: adviserUserConnects,
  } as IOperationGetAdviserConnectsForUserLoaded);

export const OperationGetAdviserConnectsForUser = (userId: string) => async (
  dispatch: any,
) => {
  dispatch(Loading());

  const adviserUserConnects = (await getAllAdviserUserConnectsForUser(userId))
    .data;

  dispatch(
    OperationGetAdviserConnectsForUserLoaded(
      adviserUserConnects as IAdviserConnect[],
    ),
  );
  dispatch(Loaded());
};

export const OperationUpdateSelectedAdviserConnect = (
  adviserConnect: IAdviserConnect,
) => async (dispatch: any) => {
  try {
    dispatch(Loading());
    const result = await updateAdviserConnect(adviserConnect);
    dispatch(OperationGetSelectedAdviserConnectLoaded(result.data));
    dispatch(Loaded());
  } catch (err) {
    alert('Operation - Failed to update adviser connect ');
  }
};

export interface IOperationUpdateAdviserConnectStatus extends Action {
  type: Types.UPDATE_ADVISER_CONNECT_STATUS_LOCAL;
  data: MeetingStatus;
}

export const OperationUpdateAdviserConnectStatus = (status: MeetingStatus) =>
  ({
    type: Types.UPDATE_ADVISER_CONNECT_STATUS_LOCAL,
    data: status,
  } as IOperationUpdateAdviserConnectStatus);

export interface IOperationUpdateAdviserConnectPolicyScans extends Action {
  type: Types.UPDATE_ADVISER_CONNECT_POLICY_SCANS_LOCAL;
  data: {
    type: string;
    count: number;
  };
}

export const OperationUpdateAdviserConnectPolicyScans = (data: {
  type: string;
  count: number;
}) =>
  ({
    type: Types.UPDATE_ADVISER_CONNECT_POLICY_SCANS_LOCAL,
    data,
  } as IOperationUpdateAdviserConnectPolicyScans);

export const OperationCreatePolicyRenewal = (
  newPolicy: IPolicy,
  oldPolicy: IPolicy,
) => async (dispatch) => {
  try {
    dispatch(Loading());

    const policyRenewal = {
      newPolicyData: { ...newPolicy },
      policyId: oldPolicy.id,
      renewalDate: oldPolicy.renewalDate,
      premium: oldPolicy.premium,
    };

    policyRenewal.renewalDate = convertToDateOnlyString(
      policyRenewal.renewalDate,
    );

    await createPolicyRenewal(policyRenewal);

    dispatch(GetRenewalForPolicy(policyRenewal.policyId));
    dispatch(Loaded());

    const policyRenewalDate = moment(oldPolicy.renewalDate);
    const isFuture = policyRenewalDate.isAfter(moment());

    if (isFuture) {
      dispatch(
        AdminUpdatePolicy({
          ...oldPolicy,
          policyRenewalAdded: true,
        }),
      );
    }
  } catch (err) {
    console.error(err);
    alert('Operation - Failed to create policy renewal');
  }
};

export interface IOperationGetMarketScanFeedbacks extends Action {
  type: Types.MARKET_SCAN_FEEDBACKS_LOADED;
  data: IMarketScanFeedback[];
}

export const OperationGetMarketScanFeedbacksLoaded = (
  feedbacks: IMarketScanFeedback[],
) => ({
  type: Types.MARKET_SCAN_FEEDBACKS_LOADED,
  data: feedbacks,
});

export const OperationGetMarketScanFeedbacks = (dataType: string) => async (
  dispatch: any,
) => {
  dispatch(Loading());
  try {
    const result = await getFormSubmissionDataByDataType(dataType);
    dispatch(OperationGetMarketScanFeedbacksLoaded(result.data));
  } catch (err) {
    console.error(err);
    alert('Operation - Get Market Scan feedbacks failed');
  }
};

export interface IPolicyScanPayloadLoaded extends Action {
  type: Types.POLICY_SCAN_PAYLOAD_LOADED;
  data?: any;
}

export const PolicyScanPayloadLoaded = (data?: any) =>
  ({
    type: Types.POLICY_SCAN_PAYLOAD_LOADED,
    data,
  } as IPolicyScanPayloadLoaded);
