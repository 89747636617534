import React, { useEffect, useState } from 'react';
import { Calendar, Check, Phone } from 'react-feather';
import styles from '../styles/ContactForm.module.scss';
import CalendlyButton from 'components/calendly-button';
import { Back, Button } from 'components/quashed';
import Title from 'components/title';
import { logPageView, PAGE_VIEW_EVENTS, validatePhone } from 'helpers';

const MAX_NOTES_LENGTH = 1000;

const getCalendlyProps = ({ user, submitCalendly }) => {
  const name = user.name || `${user.firstName} ${user.lastName}`;
  return {
    buttonText: (
      <>
        <Calendar size={20} />
        <h3>Schedule a call</h3>
        <div className={styles.circle}>
          <Check size={10} strokeWidth={4} />
        </div>
      </>
    ),
    prefill: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      name,
    },
    onEventScheduled: submitCalendly,
  };
};

const CallButton = ({
  error,
  selected,
  phone,
  updateContactMethod,
  updatePhone,
}) => {
  return (
    <div
      className={classnames(
        styles.call,
        selected && styles.selected,
        error && styles.error,
      )}
      onClick={() => updateContactMethod('phone')}
    >
      <Phone fill="#34363e" strokeWidth={0} />
      <div className={styles.phone}>
        {selected ? (
          <>
            <div className={styles.label}>Enter your phone number</div>
            <input
              onChange={(e) => updatePhone(e.target.value)}
              value={phone}
            />
          </>
        ) : (
          <h3>Call me ASAP</h3>
        )}
      </div>
      <div className={styles.circle}>
        <Check size={10} strokeWidth={4} />
      </div>
    </div>
  );
};

const Notes = ({ notes, updateNotes }) => {
  const charRemaining = notes
    ? MAX_NOTES_LENGTH - notes.length
    : MAX_NOTES_LENGTH;
  return (
    <div className={styles.notes}>
      <b>Is there any information you would like the adviser to know?</b>
      <textarea
        placeholder="Enter information"
        onChange={(e) => updateNotes(e.target.value)}
        className={styles.input}
        rows={3}
        maxLength={1000}
      />
      <span className={charRemaining <= 0 ? styles.coral : ''}>
        {charRemaining} Characters Remaining
      </span>
    </div>
  );
};

const ContactForm = (props) => {
  const {
    contactMethod,
    loading,
    notes,
    phone,
    submitAdviserConnect,
    prev,
    updateContactMethod,
    updateNotes,
    updatePhone,
    user,
  } = props;
  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 3, premium: !!user.premium });
  }, []);

  const [error, setError] = useState(false);

  const isPhone = contactMethod === 'phone';

  const handleSubmit = () => {
    if (validatePhone(phone)) {
      setError(false);
      submitAdviserConnect();
    } else {
      setError(true);
    }
  };

  const handleCalendlyClick = () => {
    updateContactMethod('');
  };

  return (
    <div className={styles.container}>
      <Title
        title="When would be a good time to reach you?"
        subtitle="Our adviser will reach out to discuss your insurance options."
      />
      <div className={styles.contactBtns}>
        <CallButton
          error={error}
          selected={isPhone}
          phone={phone}
          updateContactMethod={updateContactMethod}
          updatePhone={updatePhone}
        />
        <div className={styles.or}>OR</div>
        <CalendlyButton
          className={styles.calendly}
          {...getCalendlyProps(props)}
          onClick={handleCalendlyClick}
        />
      </div>
      {isPhone && <Notes notes={notes} updateNotes={updateNotes} />}
      <div className={styles.buttons}>
        <Back className={styles.back} onClick={prev} disabled={loading} />
        {isPhone && (
          <Button text="Next" onClick={handleSubmit} disabled={loading} />
        )}
      </div>
    </div>
  );
};

export default ContactForm;
