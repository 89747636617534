// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1TFAoF39SbPKU6wrCKwTJG{font-family:\"Open Sans\";font-weight:400;font-size:14px;padding:16px 24px;border-radius:5px;background-color:#ebedf3;text-align:left;max-width:700px;margin-bottom:24px}._1TFAoF39SbPKU6wrCKwTJG svg{margin-right:40px;min-width:65px}._1TFAoF39SbPKU6wrCKwTJG a{color:#34363e;text-decoration:underline}._2DD3XnYq-d1WiMMQnBj_av{color:#878787;font-size:12px}._2DD3XnYq-d1WiMMQnBj_av a{color:#878787;text-decoration:underline}._2DD3XnYq-d1WiMMQnBj_av p{margin-bottom:0}._3LstYkpqoo0eZ2SeGupjZb{margin-top:16px;text-align:left}.SKZAei_k1k-IQjjjX22Vc>div{text-align:left}.SKZAei_k1k-IQjjjX22Vc>div:first-child{margin-bottom:0}.SKZAei_k1k-IQjjjX22Vc>div:last-child{margin-top:0;margin-bottom:24px}._25i_Ac_A9X71C-lkqffr_z{display:none !important}@media(max-width: 820px){.SKZAei_k1k-IQjjjX22Vc>div:first-child{margin-left:0}}@media(max-width: 576px){._2DD3XnYq-d1WiMMQnBj_av p:first-child{margin-bottom:8px}}", ""]);
// Exports
exports.locals = {
	"note": "_1TFAoF39SbPKU6wrCKwTJG",
	"note2": "_2DD3XnYq-d1WiMMQnBj_av",
	"noteEnd": "_3LstYkpqoo0eZ2SeGupjZb _1TFAoF39SbPKU6wrCKwTJG",
	"titleContainer": "SKZAei_k1k-IQjjjX22Vc",
	"hide": "_25i_Ac_A9X71C-lkqffr_z"
};
module.exports = exports;
