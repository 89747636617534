// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".dZCcR0bOAX3da7NGgqUeH{background-color:#f5f5f5;padding:8px 24px 8px 16px;color:#34363e;display:flex;border-radius:5px;border:1px solid #ffa53a}.dZCcR0bOAX3da7NGgqUeH i.icon{color:#ffa53a;margin-right:8px}", ""]);
// Exports
exports.locals = {
	"container": "dZCcR0bOAX3da7NGgqUeH"
};
module.exports = exports;
