import Button from 'components/button';
import { IPopupProps } from 'hooks/useUploader';
import styles from './uploaderPopup.scss';

interface IUploaderPopupProps {
  popupProps: IPopupProps;
  onPopupAction(confirm: boolean): void;
}

const UploaderPopup = ({ popupProps, onPopupAction }: IUploaderPopupProps) => {
  const fileNames = R.propOr([], 'files', popupProps).map((file) => file.name || file.fileName);

  const getDuplicateFileText = fileNames ? (
    fileNames.length > 1 ? (
      <p>
        File's with the name's <b>{fileNames.join(' & ')}</b> already exist,
        would you like to replace them?
      </p>
    ) : (
      <p>
        The file name <b>{fileNames.join('')}</b> already exists, would you like
        to replace it?
      </p>
    )
  ) : undefined;

  return (
    <div
      className={classnames(
        styles.modal,
        popupProps?.open ? styles.modalShow : styles.modalHide,
      )}
    >
      <section className={styles.modalMain}>
        {getDuplicateFileText}
        <div className={styles.buttons}>
          <Button
            text="Confirm"
            onClick={() => onPopupAction(true)}
            className={styles.blueButton}
          />
          <Button
            text="Cancel"
            onClick={() => onPopupAction(false)}
            className={styles.redButton}
          />
        </div>
      </section>
    </div>
  );
};

export default UploaderPopup;
