import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import history from 'helpers/history';
import styles from './index.scss';

export interface IBackProps {
  handleBack?: any;
  className?: any;
  black?: boolean;
  link?: string;
  browserBack?: boolean;
}

const content = (
  <>
    <Icon className={styles.back} name="chevron left" size="small" />
    <span className={styles.label}>Previous</span>
  </>
);

const wrapLink = (props, link) => (
  <Link to={link} {...props}>
    {content}
  </Link>
);

const browserBackCb = () => history.goBack();

const Back = ({
  handleBack,
  className,
  black,
  link,
  browserBack,
}: IBackProps) => {
  const props = useMemo(
    () => ({
      className: classnames(black ? styles.black : styles.back, className),
      onClick: browserBack ? browserBackCb : handleBack,
    }),
    [black, className, browserBack, handleBack],
  );

  return link ? wrapLink(props, link) : <div {...props}>{content}</div>;
};

export default Back;
