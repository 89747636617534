import { AppBannerContentType, TASK_TYPES } from 'constants/notification';
import { Action } from 'redux';
import {
  getNotifications,
  bulkUpdateNotification,
  updateNotification,
  updateGlobalNotification,
} from 'services';
import { INotification } from 'types';

export enum Types {
  DELETE_POLICY_NOTIFICATIONS_LOADED = 'DELETE_POLICY_NOTIFICATIONS_LOADED',
  GET_NOTIFICATIONS_LOADED = 'GET_NOTIFICATIONS_LOADED',
  NOTIFICATIONS_LOADING = 'NOTIFICATIONS_LOADING',
  SEEN_NOTIFICATIONS = 'SEEN_NOTIFICATIONS',
  TOGGLE_APP_BANNER = 'TOGGLE_APP_BANNER',
  UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
}

export interface INotificationsLoading extends Action {
  type: Types.NOTIFICATIONS_LOADING;
}

const NotificationsLoading = () =>
  ({
    type: Types.NOTIFICATIONS_LOADING,
  } as INotificationsLoading);

// Get notifications
export interface IGetNotificationsLoaded extends Action {
  type: Types.GET_NOTIFICATIONS_LOADED;
  data: INotification[];
}

const GetNotificationsLoaded = (data: INotification[]) =>
  ({
    type: Types.GET_NOTIFICATIONS_LOADED,
    data,
  } as IGetNotificationsLoaded);

export const GetNotifications = () => async (dispatch) => {
  dispatch(NotificationsLoading());
  const notifications = await getNotifications();
  dispatch(GetNotificationsLoaded(notifications.data));
};

export interface IUpdateNotificationLoaded extends Action {
  type: Types.UPDATE_NOTIFICATION;
  data: INotification;
}

const UpdateNotificationLoaded = (data: INotification) =>
  ({
    type: Types.UPDATE_NOTIFICATION,
    data,
  } as IUpdateNotificationLoaded);

export const UpdateNotification = (notification: INotification) => async (
  dispatch,
) => {
  if (notification.notificationType !== TASK_TYPES.GLOBAL_NOTIFICATION) {
    dispatch(UpdateNotificationLoaded(notification));
    updateNotification(notification);
  }
};

// Batch update seen
export interface ISeenNotificationsLoaded extends Action {
  type: Types.SEEN_NOTIFICATIONS;
  data: INotification[];
}

const SeenNotificationsLoaded = (data: INotification[]) =>
  ({
    type: Types.SEEN_NOTIFICATIONS,
    data,
  } as ISeenNotificationsLoaded);

export const SeenNotifications = (notifications: INotification[]) => async (
  dispatch,
) => {
  const seen: INotification[] = notifications.map(R.assoc('seen', true));
  dispatch(SeenNotificationsLoaded(seen));
  bulkUpdateNotification(seen);
};

// Delete local notifications
export interface IDeletePolicyNotificationsLoaded extends Action {
  type: Types.DELETE_POLICY_NOTIFICATIONS_LOADED;
  data: string;
}

export const DeletePolicyNotificationsLoaded = (data: string) =>
  ({
    type: Types.DELETE_POLICY_NOTIFICATIONS_LOADED,
    data,
  } as IDeletePolicyNotificationsLoaded);

// Toggle app banner
export interface IToggleAppBanner extends Action {
  type: Types.TOGGLE_APP_BANNER;
  data: AppBannerContentType;
}

export const ToggleAppBanner = (data: AppBannerContentType) =>
  ({
    type: Types.TOGGLE_APP_BANNER,
    data,
  } as IToggleAppBanner);
