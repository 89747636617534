import { getMakeChangeHandler, getModelChangeHandler } from '../helpers/fields';
import { getYearOptions } from '../helpers/date';
import {
  validatePhone,
  validateNumberplate,
  validateFullAddress,
} from '../helpers/validation';
import {
  atFaultIncidents5YearsOptions,
  atFaultIncidents1YearOptions,
  ridingExpOptions,
  motorcycleLicenseOptions,
  regoStatusOptions,
  yesNoOptions,
  atFaultIncidents5YearsMotorcycleOptions,
  atFaultIncidents1YearMotorcycleOptions,
  coverTypeOptions,
  parkingOptions,
  annualDistanceOptions,
  useOptions,
  motorcycleUseOptions,
  licenceClassOptions,
  licenceYears,
  MotorcycleRegoStatus,
  motorcycleCoverTypeOptions,
  CO_PAYMENT_OPTIONS,
  PAYMENT_METHODS_OPTIONS,
} from './options';

export const EXCESS_QUESTION = {
  line: 1,
  order: 1,
  key: 'excess',
  fieldKey: 'excess',
  placeholder: 'Excess',
  before: 'Excess amount',
  label: 'Excess',
  prefix: '$',
  type: 'dropdown',
  inputType: 'number',
  creatable: true,
  noResultsMessage: 'Enter excess amount',
  validator: (value) => !!value,
};

export const COPAYMENT_QUESTION = {
  fieldKey: 'coPayment',
  placeholder: 'Co-payment',
  label: 'Co-payment',
  type: 'dropdown',
  valueKey: 'coPayment',
  options: CO_PAYMENT_OPTIONS,
};

export const PAYMENT_METHOD_QUESTION = {
  key: 'paymentMethod',
  fieldKey: 'paymentMethod',
  label: 'Payment method',
  before: 'Payment method',
  placeholder: 'Payment method',
  type: 'dropdown',
  options: PAYMENT_METHODS_OPTIONS,
};

export const ADDRESS_QUESTION = {
  line: 1,
  order: 1,
  before: 'This vehicle is parked at',
  key: 'address',
  fieldKey: 'address',
  label: 'Address',
  type: 'address',
  inputType: 'address',
  placeholder: '',
  required: true,
  fullWidth: true,
};

export const MS_ADDRESS_PROPS = {
  placeholder: '',
  showButton: true,
  optional: true,
  autoFocus: true,
  validator: (_, formData) => validateFullAddress(formData),
};

export const MS_ADDRESS_QUESTION = {
  ...ADDRESS_QUESTION,
  ...MS_ADDRESS_PROPS,
};

export const FINANCE_QUESTION = {
  line: 3,
  before: 'Is your vehicle on finance?',
  key: 'isFinance',
  label: 'Finance',
  order: 2,
  type: 'dropdown',
  options: yesNoOptions,
  getValue: (v) => (v.isFinance ? 'Yes' : 'No'),
};

export const MOTORCYCLE_FINANCE_QUESTION = {
  ...FINANCE_QUESTION,
  line: 2,
  order: 2,
  before: 'Is your motorcycle on finance?',
};

export const REGO_QUESTION = {
  line: 1,
  before: 'What is your number plate?',
  label: 'Number plate',
  placeholder: 'Number plate',
  key: 'numberPlate',
  fieldKey: 'numberPlate',
  order: 1,
  required: true,
  maxLength: 6,
  validator: validateNumberplate,
};

export const MOTORCYCLE_REGO_QUESTION = {
  ...REGO_QUESTION,
  optional: true,
  validator: (numberPlate, { registrationStatus }) => {
    return numberPlate
      ? validateNumberplate(numberPlate)
      : registrationStatus !== MotorcycleRegoStatus.ROAD_REGISTERED;
  },
};

export const DRIVING_EXP_QUESTION = {
  line: 2,
  before: 'with ',
  after: ' years of driving experience',
  key: 'drivingExp',
  label: 'Driving experience',
  order: 2,
  type: 'number',
  source: 'user',
  decimalScale: 0,
  inputType: 'number',
};

export const MAKE_QUESTION = {
  line: 2,
  fieldKey: 'make',
  label: 'Make',
  key: 'make',
  before: 'Make',
  type: 'search',
  minCharacters: 0,
  getChangeHandler: getMakeChangeHandler,
  showKeyValue: ({ vehicleFound, make }) => !R.isNil(vehicleFound) || !!make,
};

export const MODEL_QUESTION = {
  line: 3,
  key: 'model',
  fieldKey: 'model',
  label: 'Model',
  before: 'Model',
  type: 'search',
  minCharacters: 0,
  getChangeHandler: getModelChangeHandler,
  showKeyValue: ({ vehicleFound, make, model }) =>
    !R.isNil(vehicleFound) || !!make || !!model,
};

export const SUBMODEL_QUESTION = {
  line: 4,
  key: 'subModel',
  fieldKey: 'subModel',
  label: 'Sub model',
  before: 'Sub model',
  type: 'search',
  minCharacters: 0,
  showKeyValue: ({ vehicleFound, make, subModel }) =>
    !R.isNil(vehicleFound) || !!make || !!subModel,
};

export const CC_RATING_QUESTION = {
  line: 4,
  key: 'ccRating',
  fieldKey: 'ccRating',
  label: 'CC Rating',
  before: 'CC Rating',
  type: 'number',
  inputType: 'number',
  decimalScale: 0,
  errorText: 'CC Rating must be between 25 and 2999cc',
  validator: (value: string) => {
    const ccRating = parseInt(value, 10);
    return ccRating >= 25 && ccRating <= 2999;
  },
};

export const MOTORCYCLE_OWNERSHIP_QUESTION = {
  line: 5,
  order: 1,
  before: 'Years of motorcycle ownership in the last 3 years',
  key: 'ridingExp',
  fieldKey: 'ridingExp',
  label: 'Years of ownership',
  type: 'dropdown',
  options: ridingExpOptions,
  source: 'user',
};

export const MOTORCYCLE_LICENSE_TYPE_QUESTION = {
  line: 2,
  order: 1,
  before: 'I have a ',
  after: 'license.',
  key: 'motorcycleLicenseType',
  fieldKey: 'motorcycleLicenseType',
  label: 'License Type',
  type: 'dropdown',
  source: 'user',
  options: motorcycleLicenseOptions,
};

export const THEFT_INCIDENTS_5YEARS_QUESTION = {
  line: 6,
  before: 'Theft/at-fault incidents you have had in the last 5 years',
  key: 'atFaultIncidents5Years',
  fieldKey: 'atFaultIncidents5Years',
  label: 'Theft/at-fault incidents (5 years)',
  type: 'dropdown',
  options: atFaultIncidents5YearsOptions,
};

export const THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION = {
  ...THEFT_INCIDENTS_5YEARS_QUESTION,
  options: atFaultIncidents5YearsMotorcycleOptions,
};

export const THEFT_INCIDENTS_12MONTHS_QUESTION = {
  line: 7,
  before: 'Theft/at-fault incidents within the last 12 months',
  key: 'atFaultIncidents1Year',
  fieldKey: 'atFaultIncidents1Year',
  label: 'Theft/at-fault incidents (12 months)',
  type: 'dropdown',
  options: atFaultIncidents1YearOptions,
  showKeyValue: (v) =>
    !!v.atFaultIncidents5Years && v.atFaultIncidents5Years !== '0',
  validator: (incidents, values) => {
    const fiveYearsParsed = parseInt(values.atFaultIncidents5Years, 10);
    const oneYearParsed = parseInt(incidents, 10);
    const fiveYearsVal = Number.isNaN(fiveYearsParsed)
      ? atFaultIncidents5YearsOptions.length - 1
      : fiveYearsParsed;
    const oneYearVal = Number.isNaN(oneYearParsed)
      ? atFaultIncidents1YearOptions.length - 1
      : oneYearParsed;
    return fiveYearsVal >= oneYearVal;
  },
};

export const THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION = {
  ...THEFT_INCIDENTS_12MONTHS_QUESTION,
  options: atFaultIncidents1YearMotorcycleOptions,
};

export const MOTORCYCLE_PARKING = {
  line: 3,
  order: 1,
  before: 'Where is your motorcycle parked overnight?',
  key: 'overnightParking',
  fieldKey: 'overnightParking',
  label: 'Overnight parking',
  type: 'dropdown',
  options: parkingOptions,
};

export const VEHICLE_PARKING_QUESTION = {
  ...MOTORCYCLE_PARKING,
  line: 2,
  before: 'Where is your vehicle parked over night?',
};

export const VEHICLE_YEAR_QUESTION = {
  line: 5,
  before: 'Year of manufacture',
  key: 'vehicleYear',
  fieldKey: 'vehicleYear',
  label: 'Year of manufacture',
  type: 'dropdown',
  options: getYearOptions(),
};

export const REGO_STATUS_QUESTION = {
  line: 9,
  before: 'Registration status',
  key: 'registrationStatus',
  fieldKey: 'registrationStatus',
  label: 'Registration status',
  type: 'dropdown',
  options: regoStatusOptions,
  getValue: (v) => {
    const option = regoStatusOptions.find(
      R.propEq('value', v.registrationStatus),
    );
    return option?.text;
  },
};

// Market Scan Purchase questions
export const PURCHASE_COVER_TYPE_QUESTION = {
  line: 1,
  before: 'Type of cover',
  key: 'coverType',
  order: 1,
  type: 'dropdown',
  options: coverTypeOptions,
};

export const MOTORCYCLE_COVER_TYPE_QUESTION = {
  ...PURCHASE_COVER_TYPE_QUESTION,
  options: motorcycleCoverTypeOptions,
};

export const AREA_OF_GARAGING_QUESTION = {
  line: 3,
  before: 'Area of garaging',
  key: 'areaOfGaraging',
  label: 'Area of garaging',
  fieldKey: 'areaOfGaraging',
  order: 1,
  type: 'dropdown',
};

export const ANNUAL_DISTANCE_QUESTION = {
  line: 4,
  before: 'Annual distance covered',
  key: 'annualDistanceDriven',
  type: 'dropdown',
  order: 1,
  options: annualDistanceOptions,
};

export const VEHICLE_USE_QUESTION = {
  line: 5,
  before: 'Intended use',
  label: 'Intended use',
  key: 'vehicleUse',
  order: 1,
  type: 'dropdown',
  options: useOptions,
};

export const MOTORCYCLE_USE_QUESTION = {
  ...VEHICLE_USE_QUESTION,
  line: 2,
  options: motorcycleUseOptions,
};

export const OTHER_PARTY_FINANCE_QUESTION = {
  line: 6,
  before: 'Is your vehicle on finance?',
  key: 'otherPartyFinInt',
  order: 1,
  type: 'dropdown',
  options: yesNoOptions,
};

export const FINANCE_COMPANY_QUESTION = {
  line: 7,
  before: 'Name of finance company',
  key: 'financeCompany',
  order: 1,
  showKeyValue: ({ otherPartyFinInt }) => !!otherPartyFinInt,
};

export const MOD_VALUE_QUESTION = {
  line: 14,
  before: 'Modifications value',
  key: 'modificationsValue',
  order: 1,
  type: 'number',
  prefix: '$',
};

export const MOD_DESCRIPTION_QUESTION = {
  line: 15,
  before: 'Modifications',
  key: 'modificationsDescription',
  order: 1,
  showKeyValue: ({ modificationsValue }) => !!modificationsValue,
};

export const LICENSE_VERSION_QUESTION = {
  line: 8,
  order: 1,
  before: 'Rider license version',
  label: 'License version',
  key: 'licenseVersion',
  fieldKey: 'licenseVersion',
  type: 'number',
  source: 'user',
  placeholder: 'e.g. 123',
};

export const LICENSE_NUMBER_QUESTION = {
  line: 7,
  order: 1,
  before: 'Rider license number',
  key: 'licenseNumber',
  fieldKey: 'licenseNumber',
  label: 'License number',
  source: 'user',
  placeholder: 'e.g. AB123456',
};

export const PERCENTAGE_USE_QUESTION = {
  line: 2,
  before: 'Percentage used',
  key: 'percentageUse',
  order: 1,
  type: 'number',
  validator: (_, __, drivers) => {
    let total = 100;
    if (Array.isArray(drivers)) {
      total = drivers.reduce(
        (sum, d) => sum + parseInt(d.percentageUse || 0, 10),
        0,
      );
    }
    return total === 100;
  },
};

export const PHONE_QUESTION = {
  line: 3,
  before: 'Phone number',
  key: 'phoneNumber',
  order: 1,
  validator: validatePhone,
  placeholder: 'e.g. 0210123456',
};

export const LICENSE_CLASS_QUESTION = {
  line: 4,
  before: 'Drivers license class',
  key: 'licenseClass',
  order: 1,
  type: 'dropdown',
  options: licenceClassOptions,
};

export const MOTORCYCLE_LICENSE_CLASS_QUESTION = {
  ...LICENSE_CLASS_QUESTION,
  before: 'Rider license class',
  options: motorcycleLicenseOptions,
};

export const LICENSE_YEARS_QUESTION = {
  line: 5,
  before: 'Drivers license years',
  key: 'licenseYears',
  order: 1,
  type: 'dropdown',
  options: licenceYears,
};

export const LICENSE_COUNTRY_QUESTION = {
  line: 6,
  before: 'Drivers license country',
  key: 'licenseCountry',
  order: 1,
  type: 'dropdown',
};

export const CURRENT_INSURER_QUESTION = {
  line: 9,
  before: 'Current insurer',
  key: 'currentInsurer',
  order: 1,
  type: 'dropdown',
  optional: true,
};

export const VIN_QUESTION = {
  line: 3,
  before: 'VIN number',
  label: 'VIN number',
  key: 'vin',
  order: 1,
  placeholder: `e.g. EG4EG07E4HB001120`,
  showKeyValue: ({ registrationStatus }) =>
    registrationStatus === MotorcycleRegoStatus.NOT_REQUIRED ||
    registrationStatus === MotorcycleRegoStatus.REGISTRATION_PENDING,
};

export const INSURED_AMOUNT_QUESTION = {
  key: 'amount',
  fieldKey: 'amount',
  placeholder: 'Insured amount',
  label: 'Insured amount',
  before: 'Insured amount',
  prefix: '$',
  inputType: 'number',
  type: 'number',
  decimalScale: 0,
};

export const BUILD_YEAR_QUESTION = {
  fieldKey: 'yearBuilt',
  key: 'yearBuilt',
  label: 'Year of build',
  before: 'Year of the build',
  type: 'dropdown',
  options: getYearOptions(undefined, 'number'),
  line: 2,
  order: 1,
};

export const FLOOR_AREA_QUESTION = {
  key: 'livingArea',
  fieldKey: 'livingArea',
  label: 'Floor area (m²)',
  inputType: 'number',
  type: 'number',
  line: 4,
  order: 1,
  before: 'Floor Area of the house (m²)',
  decimalScale: 0,
};

export const EXTERNAL_AREA_QUESTION = {
  key: 'externalArea',
  fieldKey: 'externalArea',
  label: 'External area (m²)',
  inputType: 'number',
  type: 'number',
  line: 5,
  order: 1,
  before: 'Floor area of any outhouses/external garages (m²)',
  optional: true,
  decimalScale: 0,
};

export const FULL_ADDRESS_OTHER_QUESTIONS = [
  {
    key: 'flatNo',
    fieldKey: 'flatNo',
    placeholder: '(optional)',
    label: 'Flat number',
    before: 'Flat number',
    showKeyValue: R.prop('showFullAddress'),
    optional: true,
    maxLength: 10,
    display: [{ when: 'showFullAddress', is: true }],
  },
  {
    key: 'streetNo',
    fieldKey: 'streetNo',
    required: true,
    label: 'Street number',
    before: 'Street number',
    maxLength: 10,
    showKeyValue: R.prop('showFullAddress'),
    display: [{ when: 'showFullAddress', is: true }],
  },
  {
    key: 'streetName',
    fieldKey: 'streetName',
    fullWidth: true,
    required: true,
    label: 'Street name',
    before: 'Street name',
    showKeyValue: R.prop('showFullAddress'),
    display: [{ when: 'showFullAddress', is: true }],
  },
  {
    key: 'suburb',
    fieldKey: 'suburb',
    required: true,
    label: 'Suburb',
    before: 'Suburb',
    showKeyValue: R.prop('showFullAddress'),
    display: [{ when: 'showFullAddress', is: true }],
  },
  {
    key: 'city',
    fieldKey: 'city',
    required: true,
    label: 'City',
    before: 'City',
    showKeyValue: R.prop('showFullAddress'),
    display: [{ when: 'showFullAddress', is: true }],
  },
  {
    key: 'postCode',
    fieldKey: 'postCode',
    required: true,
    label: 'Postcode',
    before: 'Postcode',
    showKeyValue: R.prop('showFullAddress'),
    type: 'number',
    allowLeadingZeros: true,
    castAs: 'string',
    thousandSeparator: '',
    maxLength: 4,
    display: [{ when: 'showFullAddress', is: true }],
  },
];
