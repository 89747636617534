import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { Modal, ModalDimmerProps } from 'semantic-ui-react';
import styles from './styles/ConfettiModal.module.scss';
import TriangleSvg from '-!svg-react-loader!./images/triangle.svg';
import RectSvg from '-!svg-react-loader!./images/rect.svg';
import CircleSvg from '-!svg-react-loader!./images/circle.svg';
import LongSvg from '-!svg-react-loader!./images/long.svg';

interface IProps {
  content: React.ReactNode;
  className?: string;
  heading?: React.ReactNode;
  modalClassName?: string;
  open?: boolean;
}

const dimmer: ModalDimmerProps = {
  className: styles.dimmer,
};

const ConfettiItems = ({ className }) => (
  <div className={classnames(styles.container, className)}>
    <TriangleSvg className={styles.triangle} />
    <TriangleSvg className={styles.triangle} />
    <TriangleSvg className={styles.triangle} />
    <TriangleSvg className={styles.triangle} />
    <TriangleSvg className={styles.triangle} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <RectSvg className={styles.rect} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <CircleSvg className={styles.circle} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
    <LongSvg className={styles.long} />
  </div>
);

const ConfettiModal = ({
  className,
  content,
  heading,
  open: showModal,
  modalClassName,
}: IProps) => {
  const [open, setOpen] = useState(showModal);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    open && (
      <>
        <ConfettiItems className={className} />
        <Modal
          open={open}
          className={classnames(styles.modal, modalClassName)}
          onClose={handleClose}
          size="mini"
          closeOnDimmerClick={true}
          closeIcon={false}
          dimmer={dimmer}
        >
          <Modal.Content className={styles.content}>
            <X className={styles.close} onClick={handleClose} />
            {heading && <div className={styles.heading}>{heading}</div>}
            <div className={styles.content}>{content}</div>
          </Modal.Content>
        </Modal>
      </>
    )
  );
};

export default React.memo(ConfettiModal);
