// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3-PiRxCCwGrGVmfcI63bNl.ui.modal .content{padding:24px;text-align:center}.Ctxxb6-5uHBPOazT-e9DG{margin-top:24px}", ""]);
// Exports
exports.locals = {
	"modal": "_3-PiRxCCwGrGVmfcI63bNl",
	"button": "Ctxxb6-5uHBPOazT-e9DG"
};
module.exports = exports;
