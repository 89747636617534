import React, { useCallback, useState } from 'react';
import { X } from 'react-feather';
import { Checkbox, Modal , TextArea } from 'semantic-ui-react';
import Button from 'components/button';
import styles from './styles/adviserClientInvite.scss';
import GirlSvg from '-!svg-react-loader!images/people/girl-client-invite.svg';

interface IProps {
  client;
  open;
  onClose;
  onSubmit;
}

const AdviserClientInviteModal = ({ client, open, onClose, onSubmit }: IProps) => {
  const [isPolicyOwner, setPolicyOwner] = useState(false);
  const [message, setMessage] = useState('');

  const handleTextChange = useCallback(
    (_, data) => setMessage(data.value),
    [],
  );

  const handleCheckboxChange = useCallback(
    (_, data) => setPolicyOwner(data.checked),
    [],
  );

  const handleSubmit = useCallback(
    () => {
      onSubmit({ message, isPolicyOwner });
      // Reset state
      setPolicyOwner(false);
      setMessage('');
    },
    [isPolicyOwner, message],
  );

  return (
    <Modal
      className={styles.modal}
      size="small"
      open={open}
      closeIcon={false}
      onClose={onClose}
    >
      <Modal.Content>
        <X onClick={onClose} className={styles.close} size={24} />
        <GirlSvg className={styles.girl} />
        <div className={styles.title}>
          {`Invite ${client.firstName} to Quashed`}
        </div>
        <div className={styles.content}>
          <p>Bring your client to Quashed, we’ll send them an email inviting them to finish setting up their account.</p>
          <b>Sending to {client.email}</b>
        </div>
        <TextArea
          className={styles.textarea}
          onChange={handleTextChange}
          placeholder="Write them a personal message!"
          value={message}
        />
        <div className={styles.buttons}>
          <div className={styles.checkboxContainer}>
            <span>Confirm the email is the policy owner{' '}</span>
            <Checkbox
              className={styles.checkbox}
              label=""
              checked={isPolicyOwner}
              onChange={handleCheckboxChange}
            />
          </div>
          <Button
            disabled={!isPolicyOwner}
            className={styles.button}
            text="Send invite"
            onClick={handleSubmit}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdviserClientInviteModal;
