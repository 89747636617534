export const updateListByItem = (items: any[], item: any) => {
  const idxToUpdate = items.findIndex(R.propEq('id', item.id));
  if (idxToUpdate >= 0) {
    const updated = { ...item }; // server's value should be the latest, so over write the existing one.
    return R.update(idxToUpdate, updated, items);
  }
  return items;
};

export const updateListByItemMerged = (items: any[], item: any) => {
  const idxToUpdate = items.findIndex(R.propEq('id', item.id));
  if (idxToUpdate >= 0) {
    const updated = R.merge(items[idxToUpdate], item);
    return R.update(idxToUpdate, updated, items);
  }
  return items;
};

// Insert only if it doesnt exist
export const insertItem = (items: any[], item: any) => {
  const itemIdx = items.findIndex(R.propEq('id', item.id));
  if (itemIdx >= 0) {
    return items;
  }
  return [...items, item];
};

export const removeItemsByPropList = (
  items: any[],
  prop: string,
  propValues: any[],
) => {
  return R.reject((i) => R.includes(i[prop], propValues), items);
};

export const removeItemsByProp = (
  items: any[],
  prop: string,
  propValue: any,
) => {
  return R.reject(R.propEq(prop, propValue), items);
};

export const removeItemsByProps = (
  items: any[],
  props: string[],
  propValue: any,
) => {
  return R.reject((item) => {
    return !!props.find((p) => item[p] === propValue);
  }, items);
};

export const findBySk = (items: any[], sk: string) =>
  items.find(R.propEq('sk', sk));

export const batchUpdateListMerge = (
  origItems: any[],
  itemsToMerge: any[],
  prop: string = 'id',
) => {
  return origItems.map((item) => {
    const toMerge = itemsToMerge.find(R.propEq(prop, item[prop]));
    return toMerge ? R.merge(item, toMerge) : item;
  });
};

export const converArrayTo2ItemsArray = (items: Array<any>) => {
  const cards = [...items];
  const total = Math.ceil(items.length / 2);
  const newArray = [];

  for (let i = 0; i < total; i++) {
    const newRowArray = [];
    const one = cards.pop();
    newRowArray.push(one);
    const two = cards.pop();
    if (two) {
      newRowArray.push(two);
    }
    newArray.push(newRowArray);
  }
  return newArray;
};
