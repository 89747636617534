import React, { ReactNode, useEffect, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { Check } from 'react-feather';
import DOMPurify from 'dompurify';
import { HelpTooltip } from 'components/tooltip';
import styles from './styles/InputWrap.module.scss';
import { DefaultScrollConfig } from 'hooks/useScrollOnMount';

interface IProps {
  className?: string;
  errorClassName?: string;
  errorLabelClassName?: string;
  children: any;
  description?: string;
  error?: boolean | ReactNode;
  info?: string;
  label?: string;
  labelAfter?: string;
  labelClassName?: string;
  name?: string;
  tooltip?: string;
  rightIcon?: ReactNode;
  scrollIntoView?: boolean;
  tooltipProps?: any;
}

export const InputTooltip = ({ tooltip, on = ['click', 'hover'] }) => (
  <HelpTooltip
    className={styles.tooltip}
    toggleClassName={styles.toggle}
    position="top center"
    on={on}
    content={
      <div
        className={styles.toggleContent}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(tooltip, {
            ADD_ATTR: ['target', 'rel'],
          }),
        }}
      />
    }
  />
);

const InputWrap = ({
  className,
  description,
  errorClassName,
  errorLabelClassName,
  children,
  error,
  info,
  label,
  labelAfter,
  labelClassName,
  name,
  rightIcon,
  scrollIntoView,
  tooltip,
  tooltipProps,
}: IProps) => {
  const ref = useRef(null);
  const tooltipComponent = tooltip && (
    <InputTooltip tooltip={tooltip} {...tooltipProps} />
  );

  useEffect(() => {
    if (scrollIntoView) {
      ref.current?.scrollIntoView(DefaultScrollConfig);
    }
  }, [scrollIntoView]);

  return (
    <div
      ref={ref}
      className={classnames(
        styles.container,
        !!error && styles.error,
        !!error && errorClassName,
        !!error && typeof error === 'string' && 'withErrorText',
        className,
      )}
    >
      {label && (
        <label
          htmlFor={name}
          className={classnames(styles.label, labelClassName)}
        >
          <span className={styles.textWrap}>
            {typeof label === 'string' ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(label),
                }}
              />
            ) : (
              label
            )}
            {description && (
              <div className={styles.description}>
                {description}
                {tooltipComponent}
              </div>
            )}
            {!description && tooltipComponent}
          </span>
        </label>
      )}
      <div className={styles.inputContainer}>
        <div className={styles.inputWrap}>
          {!!error && (
            <Icon
              className={classnames('errorIcon', styles.errorIcon)}
              name="exclamation circle"
            />
          )}
          {children}
          {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
        </div>
        {/* If error is a boolean just show red border else we render it if its a valid element */}
        {!!error &&
          (typeof error === 'string' || React.isValidElement(error)) && (
            <div
              className={classnames(
                'errorText',
                styles.errorText,
                errorLabelClassName,
              )}
            >
              {error}
            </div>
          )}
        {info && (
          <div className={styles.info}>
            <Check size={18} />
            {info}
          </div>
        )}
      </div>
      {labelAfter && (
        <div className={classnames(styles.labelAfter, labelClassName)}>
          {labelAfter}
        </div>
      )}
    </div>
  );
};

export default InputWrap;
