import notificationList from 'components/notification-list';
import { TASK_TYPES } from 'constants/notification';
import { createSelector } from 'reselect';
import { IAppState } from 'types';

export const selectNotificationState = (state: IAppState) => state.notification;

export const selectNotifications = createSelector(
  selectNotificationState,
  (notification) => notification.notifications,
);

export const getNotificationCount = createSelector(
  selectNotificationState,
  (notification) => notification.notifications.filter((n) => !n.seen).length,
);

export const getMarketScanReadyNotificationCount = createSelector(
  selectNotificationState,
  (notification) =>
    notification.notifications.filter(
      (n) => !n.seen && n.notificationType === TASK_TYPES.POLICY_SCAN_READY,
    ).length,
);
