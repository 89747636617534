import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Form } from 'semantic-ui-react';
import FormField from 'components/form-field';
import { CLIENT_FIELDS } from 'constants/user-form';
import useForm from 'hooks/useForm';
import { IUser } from 'types';
import styles from './styles/clientDetailsForm.scss';

interface IProps {
  client: IUser;
  updateClient(client: IUser): (dispatch: any) => void;
}

const leftFields = [CLIENT_FIELDS[0], CLIENT_FIELDS[2], CLIENT_FIELDS[6]];

const rightFields = [CLIENT_FIELDS[4], CLIENT_FIELDS[1], CLIENT_FIELDS[3]];

const ClientDetailsForm = ({ client, updateClient }: IProps) => {
  const [isEdit, setEdit] = useState(false);

  const onSubmit = useCallback(
    ({ values }) => {
      const payload = {
        ...client,
        ...values,
      };
      updateClient(payload);
      setEdit(false);
    },
    [client, updateClient, setEdit],
  );

  const form = useForm({
    defaultValues: client,
    onSubmit,
  });

  const handleCancel = useCallback(() => {
    setEdit(false);
    form.handleReset();
  }, [form]);

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    setEdit(true);
  }, []);

  return (
    <Form className={styles.container}>
      <div className={styles.left}>
        {leftFields.map((field) => (
          <FormField
            key={field.key}
            name={field.key}
            className={classnames(styles.field)}
            labelClassName={styles.label}
            inputClassName={styles.input}
            onChange={form.handleInputChange}
            value={form.values[field.key]}
            disabled={!isEdit}
            {...field}
            required={false}
          />
        ))}
      </div>
      <div className={styles.right}>
        {rightFields.map((field) => (
          <FormField
            key={field.key}
            name={field.key}
            className={classnames(styles.field)}
            labelClassName={styles.label}
            inputClassName={styles.input}
            onChange={form.handleInputChange}
            value={form.values[field.key]}
            disabled={!isEdit}
            {...field}
            required={false}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        {isEdit ? (
          <>
            <div className={styles.textBtn} onClick={form.handleSubmit}>
              Save
            </div>
            <div className={styles.textBtn} onClick={handleCancel}>
              Cancel
            </div>
          </>
        ) : (
          <div className={styles.textBtn} onClick={handleEdit}>
            Edit
          </div>
        )}
      </div>
    </Form>
  );
};

export default ClientDetailsForm;
