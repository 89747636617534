import { getHeaderOptions, getHttpService } from './http-service';
import { IPolicy, IPolicyScan, IUser, POLICY_SCAN_STATUS } from 'types';
import { claimsHistoryCleanUp, removeEmptyValues } from 'helpers';
import { VehicleCoverTypes } from './reference-service';

export enum AddressLookupAction {
  INFORMATION = 'information',
  VALIDATION = 'validation',
}

export enum IPolicyScanQuoteTypes {
  QUOTE = 'quote',
  CONFIRM_QUOTE = 'confirm-quote',
}

export interface IPolicyScanQuote {
  type: VehicleCoverTypes;
  policyProviderName: string;
  quoteType: IPolicyScanQuoteTypes;
  policyScanId: string;
  policyId: string;
  quoteInfo: any;
}

export interface IBrokerAcceptOfferPayload {
  policyScanId: string;
  policyId: string;
  brokerPolicyProvider: string;
  brokerPolicyProviderName: string;
  phone: string;
  frequency: 'Yearly' | 'Monthly';
}

interface IBrokerMessagePayload {
  policyScanId: string;
  policyId: string;
  message: string;
}

export interface ICreatePolicyScanPayload
  extends Partial<IPolicy>,
    Partial<IUser> {}

const httpClient = getHttpService();

export const getPolicyScanByPolicyId = (id: string) => {
  return httpClient.get(`/policyscan/policy/${id}`, {
    headers: getHeaderOptions(),
  });
};

export const getPolicyScanById = (id: string, sk?: string) => {
  return httpClient.post(
    `/policyscan/getByIdSk`,
    { id, sk },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const rerunPolicyScan = (
  id: string,
  sk: string,
  policyProviderName: string,
) => {
  return httpClient.post(
    `/policyscan/rerun`,
    { id, sk, policyProviderName },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getPolicyScanPayload = (
  id: string,
  sk: string,
  policyProviderName: string,
) => {
  return httpClient.post(
    `/policyscan/payload`,
    { id, sk, policyProviderName },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const deletePolicyScan = (id: string, sk: string) => {
  return httpClient.post(
    `/policyscan/delete`,
    { id, sk },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getPendingPolicyScanByUserId = (userId: string) => {
  const id = encodeURIComponent(userId);
  return httpClient.get(`/policyscan/user/${id}?completed=false`, {
    headers: getHeaderOptions(),
  });
};

export const getPolicyScanByUserId = (userId: string) => {
  const id = encodeURIComponent(userId);
  return httpClient.get(`/policyscan/user/${id}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicyScans = (caStart?: string, caEnd?: string) => {
  return httpClient.get(`/policyscan/`, {
    headers: getHeaderOptions(),
    params: {
      caStart,
      caEnd,
    },
  });
};

export const getAllPolicyScansByEmail = (email: string) => {
  return httpClient.get(`/policyScan/user/email/${email}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllReferralList = (caStart?: string, caEnd?: string) => {
  return httpClient.get(`/referral/getAll`, {
    headers: getHeaderOptions(),
    params: {
      caStart,
      caEnd,
    },
  });
};

export const createPolicyScan = (payload: ICreatePolicyScanPayload) => {
  const newPayload = claimsHistoryCleanUp(payload);
  return httpClient.post(`/policyscan`, removeEmptyValues(newPayload), {
    headers: getHeaderOptions(),
  });
};

export const createPolicyScanNewPolicy = (
  payload: ICreatePolicyScanPayload,
) => {
  const newPayload = claimsHistoryCleanUp(payload);

  return httpClient.post(
    `/policyscan/newpolicy`,
    removeEmptyValues(newPayload),
    {
      headers: getHeaderOptions(),
    },
  );
};

export const updatePolicyScan = (payload: IPolicyScan) => {
  return httpClient.put(
    `/policyscan/${payload.id}`,
    removeEmptyValues(payload),
    {
      headers: getHeaderOptions(),
    },
  );
};

export const updatePolicyScanAdmin = (payload: IPolicyScan) => {
  return httpClient.put(
    `/policyscan/admin/update`,
    removeEmptyValues(payload),
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getPolicyScanResult = (id: string, sk: string) => {
  return httpClient.post(
    `/policyscan/userGetByIdSk`,
    { id, sk },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const checkPolicyScanAddress = (
  address: string = '',
  fullAddress: any,
  action: AddressLookupAction = AddressLookupAction.INFORMATION,
) => {
  return httpClient.post(
    `/policyscan/address`,
    {
      address,
      fullAddress,
      action,
    },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getPolicyScanQuote = (payload: IPolicyScanQuote) => {
  return httpClient.post('/policyscan/quote', payload, {
    headers: getHeaderOptions(),
  });
};

export const brokerAcceptOffer = (payload: IBrokerAcceptOfferPayload) => {
  return httpClient.put('/policyscan/broker/acceptOffer', payload, {
    headers: getHeaderOptions(),
  });
};

export const createBrokerMessage = (payload: IBrokerMessagePayload) => {
  return httpClient.post('/policyscan/broker/message', payload, {
    headers: getHeaderOptions(),
  });
};

export const confirmPolicyScanPurchase = (
  policyScanId: string,
  policyId: string,
) => {
  return httpClient.post(
    '/policyscan/confirmPurchase',
    {
      policyId,
      policyScanId,
    },
    {
      headers: getHeaderOptions(),
    },
  );
};
