import { getHeaderOptions, getHttpService } from './http-service';

const httpClient = getHttpService();

export const getEmailNotifications = (caStart?: string, caEnd?: string) => {
  return httpClient.get(`/emailNotification/`, {
    headers: getHeaderOptions(),
    params: {
      caStart,
      caEnd,
    },
  });
};
