import React from 'react';
import { Progress } from 'semantic-ui-react';
import styles from './progress.scss';

interface IProps {
  className?: string;
  min?: number;
  percent: number;
}

const ProgressBar = ({ className, percent, min = 0.5 }: IProps) => {
  return (
    <div className={classnames(styles.progress, className)}>
      <Progress
        className={styles.progressBar}
        percent={percent || min}
        size="tiny"
      />
      <span>{percent}%</span>
    </div>
  );
};

export default ProgressBar;
