// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1MtNWdP8xDkrUb4KHtSX8n.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#4187f5;margin-right:0}@media(max-width: 576px){._1MtNWdP8xDkrUb4KHtSX8n.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._44WGyyzQYUi72RZdi3miV{display:flex;flex-wrap:wrap}._1zS0sSdB6T-fuGCCC3QKgM{border:1px solid #fff;flex:1;border-radius:10px;display:flex;justify-content:space-between;padding:8px 16px}._1zS0sSdB6T-fuGCCC3QKgM:first-child{margin-right:24px}._3DvRRQwn-UbC72Jnb9-m5f{font-family:\"Open Sans\";font-weight:700}@media(max-width: 576px){._44WGyyzQYUi72RZdi3miV{flex-direction:column}._1zS0sSdB6T-fuGCCC3QKgM:first-child{margin-right:0;margin-bottom:24px}}", ""]);
// Exports
exports.locals = {
	"blueBtn": "_1MtNWdP8xDkrUb4KHtSX8n",
	"counters": "_44WGyyzQYUi72RZdi3miV",
	"counter": "_1zS0sSdB6T-fuGCCC3QKgM",
	"bold": "_3DvRRQwn-UbC72Jnb9-m5f"
};
module.exports = exports;
