import { getReferenceData, setReferenceData } from 'helpers';
import { getHeaderOptions, getHttpService } from './http-service';

const httpClient = getHttpService();

const FORM_SERVICE_BASE_ROUTE = '/form';

export const getAllForms = () => {
  return httpClient.get(`${FORM_SERVICE_BASE_ROUTE}/`, {
    headers: getHeaderOptions(),
  });
};

export const getFormById = async (id: string) => {
  const { items = [] } = getReferenceData('formDefinition');
  const formDefinition = items.find(R.propEq('id', id));

  if (formDefinition) {
    return { data: formDefinition };
  }

  const { data } = await httpClient.get(`${FORM_SERVICE_BASE_ROUTE}/${id}`, {
    headers: getHeaderOptions(),
  });

  setReferenceData(R.append(data, items), 'formDefinition');
  return { data };
};

export const submitForm = (formData: any) => {
  return httpClient.post(`${FORM_SERVICE_BASE_ROUTE}/submit`, formData, {
    headers: getHeaderOptions(),
  });
};

export const getFormSubmissionDataByDataType = (dataType: string) => {
  return httpClient.get(`${FORM_SERVICE_BASE_ROUTE}/data/${dataType}`, {
    headers: getHeaderOptions(),
  });
};
