import {
  adviserBtnPolicies,
  exploreCoverageRules,
  ICoverageRule,
} from 'constants/explore-coverage';
import { IAdviserState, IUser } from 'types';
import { UserRoles } from 'constants/user';
import { PROFILE_COMPLETED_FIELDS } from 'constants/user-form';
import DefaultPicture from 'assets/images/user.png';
import { hasRole } from '../react-auth0-spa';

export const isFamilyOwner = ({ families, currentFamilyId }: IUser) => {
  const currentFamily =
    families && families.find(R.propEq('id', currentFamilyId));
  return currentFamily && currentFamily.familyOwner;
};

export const ruleContainsPolicyType = (rule, policyType) =>
  rule.policies.includes(policyType);

export const getCoverageResults = (values, userPolicyTypes) => {
  const removeExistingType = R.without(userPolicyTypes);
  return exploreCoverageRules.reduce((acc, rule) => {
    const {
      key,
      label,
      results,
      includes,
      includesResults,
      excludes,
      excludesResults,
      exceptions = [],
      resultExceptions = [],
    }: ICoverageRule = rule;

    if (!key && removeExistingType(results).length) {
      return [
        ...acc,
        {
          label,
          policies: removeExistingType(results),
        },
      ];
    }

    const val = values[key];
    const noExceptions =
      R.intersection(exceptions, userPolicyTypes).length === 0;
    const noResultExceptions =
      R.innerJoin(ruleContainsPolicyType, acc, resultExceptions).length === 0;

    if (val && noExceptions && noResultExceptions) {
      if (results && removeExistingType(results).length) {
        return [
          ...acc,
          {
            label,
            policies: removeExistingType(results),
          },
        ];
      } else if (
        includes &&
        includes.includes(val) &&
        removeExistingType(includesResults).length
      ) {
        return [
          ...acc,
          {
            label,
            policies: removeExistingType(includesResults),
          },
        ];
      } else if (
        excludes &&
        !excludes.includes(val) &&
        removeExistingType(excludesResults).length
      ) {
        return [
          ...acc,
          {
            label,
            policies: removeExistingType(excludesResults),
          },
        ];
      }
    }

    return acc;
  }, []);
};

export const shouldShowAdviserBtn = (results) => {
  let show = false;

  for (const result of results) {
    const { policies } = result;
    const match = R.intersection(policies, adviserBtnPolicies);
    if (match.length > 0) {
      show = true;
      break;
    }
  }

  return show;
};

export const getCoverageResultsPolicies = (user, policies) => {
  const results = getCoverageResults(user, policies).reduce(
    (acc, { policies: p }) => [...acc, ...p],
    [],
  );
  return R.uniq(results).sort();
};

export const isProfileUpdated = (user) =>
  !!PROFILE_COMPLETED_FIELDS.find((f) => !!user[f]);

export const getSharedFamily = (families = []) =>
  families.find((f) => !f.familyOwner);

export const getFamily = (families = []) => families.find((f) => f.familyOwner);

export const isUserFirstTimeUploadAttachment = (user: IUser) =>
  !user.hasAttachment;

export const getAdviser = (advisers = [], id) =>
  advisers.find(R.propEq('id', id));

export const hasEmployer = ({ employerId, otherEmployerName }: IUser) =>
  employerId || otherEmployerName;

export const mapEmployerOptions = R.map(({ id, name }) => ({
  key: id,
  text: name,
  value: id,
}));

export const findEmployerById = (employers, id) =>
  employers.find(R.propEq('id', id));

export const getAdviserOrgId = R.path(['adviserOrg', 'id']);

// Returns true when user logged in via email/password instead of SSO
export const isNormalLogin = R.includes('auth0');

export const getProfilePictureSrc = (picture) => picture || DefaultPicture;

export const isPremiumOnboardingIncomplete = (user: IUser) =>
  user.premium && !user.premiumOnboardingCompleted;

export const isOnboardingUser = (user: IUser, adviser: IAdviserState) => {
  if (adviser.premiumAdviserConnect || hasRole(UserRoles.ADMIN)) {
    return false;
  }

  return (
    !user.premium && !user.walkthroughCompleted && !user.walkthroughSkipped
  );
};
