import React from 'react';
import styles from '../styles/InsuranceTypes.module.scss';
import HouseSvg from '-!svg-react-loader!images/premium-insurance/houseIcon.svg';
import ContentsSvg from '-!svg-react-loader!images/premium-insurance/contentIcon.svg';
import CarSvg from '-!svg-react-loader!images/premium-insurance/carIcon.svg';
import LandlordSvg from '-!svg-react-loader!images/premium-insurance/landLordIcon.svg';
import LifeSvg from '-!svg-react-loader!images/premium-insurance/lifeIcon.svg';
import CommercialSvg from '-!svg-react-loader!images/premium-insurance/businessIcon.svg';

const typesOfInsurance = [
  {
    Icon: HouseSvg,
    text: 'House & holiday homes',
  },
  {
    Icon: ContentsSvg,
    text: 'Valuable contents',
  },
  {
    Icon: CarSvg,
    text: 'Performance vehicles',
  },
  {
    Icon: LandlordSvg,
    text: 'Landlord insurance',
  },
  {
    Icon: LifeSvg,
    text: 'Life & health insurance',
  },
  {
    Icon: CommercialSvg,
    text: 'Commercial insurance',
  },
];

const InsuranceTypes = () => {
  return (
    <div className={styles.insuranceTypeContainer}>
      {typesOfInsurance.map((insurance) => (
        <div className={styles.pairContainer} key={insurance.text}>
          <insurance.Icon className={styles.icon} />
          <div className={styles.label}>{insurance.text}</div>
        </div>
      ))}
    </div>
  );
};

export default InsuranceTypes;
