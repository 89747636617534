// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._9yaGBZoQAYn3cpnCk00qE ._3KKsvkcsFclgUVO8hb1AuV{border-color:#e47c6e;padding-left:16px;width:calc(100% - 8px)}._2_tgVO06wwoEsosR96yl8R{display:flex;align-items:center}._3KKsvkcsFclgUVO8hb1AuV.ui.checkbox{min-height:25px;min-width:25px}._3KKsvkcsFclgUVO8hb1AuV.ui.checkbox label{line-height:22px}._3KKsvkcsFclgUVO8hb1AuV.ui.checkbox label:before{width:25px;height:25px}._3KKsvkcsFclgUVO8hb1AuV.ui.checkbox :checked~label:after{content:\"\";font-family:Icons;color:#34363e;font-size:14px;margin-left:4px;margin-top:2px}", ""]);
// Exports
exports.locals = {
	"error": "_9yaGBZoQAYn3cpnCk00qE",
	"input": "_3KKsvkcsFclgUVO8hb1AuV",
	"inputWrap": "_2_tgVO06wwoEsosR96yl8R"
};
module.exports = exports;
