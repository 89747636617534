// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3qAgqFWUoJOpxtvGsa8HWA{position:relative;display:flex;border-radius:5px;background-color:#f1f3fb;align-items:center;justify-content:flex-end;margin-left:auto;max-height:60px}.jGupyqD99nHQ5aa0uPQHP{display:flex;flex-direction:row}.JolU1KgzaiKo1DpShpnWt{background-color:#fff;border:2px solid #4187f5;margin:0 8px 0 0}.JolU1KgzaiKo1DpShpnWt svg{fill:#4187f5;stroke:#4187f5;stroke-width:.5px}._2-m1eoHq4psm8PC4Zd6Mov{background-color:#fff;border:2px solid #4187f5;margin:0 8px 0 0}._2-m1eoHq4psm8PC4Zd6Mov svg{fill:#fff;stroke:#4187f5}._3ljNUzNh-vOnfxFZrnGBEN{margin:0 8px 0 8px;position:absolute;top:-25%;width:auto;height:90px}._12l0KPGHGYXWWbl2lh6B9H{display:none}@media(max-width: 1200px){._1T4mQXPngJUQp1zVrUM8jL{display:none}._12l0KPGHGYXWWbl2lh6B9H{display:flex;flex-direction:row;z-index:1;position:fixed;right:40px;bottom:80px;align-items:center}._2bs_65jEdruf5uioNifRfo{width:auto;height:90px;cursor:pointer}._1JEj4-DyTtrAKVtYlwTWOV{background-color:#f1f3fb;display:flex;flex-direction:row;padding:8px 16px 8px 8px;margin-right:-8px;border-radius:5px;transition:all .5s}.S2_8RU5hhaMtuJZlzSpyW{opacity:1;margin-right:-8px}._28sPjJn5vdqu_zj3Ca_1t1{opacity:0;margin-right:-100px}}@media(max-width: 1090px){._3ljNUzNh-vOnfxFZrnGBEN{height:42px;top:0}}@media(max-width: 992px){._3qAgqFWUoJOpxtvGsa8HWA{width:100%;justify-content:space-between;margin-top:24px;margin-bottom:24px;padding-left:40px;padding-right:48px}._3ljNUzNh-vOnfxFZrnGBEN{height:90px;top:0}.ZxDAJJBqIunI7IQ5Rlo3e{margin:0 24px 0 0 !important}}@media(max-width: 820px){._12l0KPGHGYXWWbl2lh6B9H{bottom:200px}}@media(max-width: 576px){._3qAgqFWUoJOpxtvGsa8HWA{padding-left:24px;padding-right:40px}}", ""]);
// Exports
exports.locals = {
	"adviserContactContainer": "_3qAgqFWUoJOpxtvGsa8HWA",
	"iconContainer": "jGupyqD99nHQ5aa0uPQHP",
	"iconToolTipPhone": "JolU1KgzaiKo1DpShpnWt",
	"iconToolTipMail": "_2-m1eoHq4psm8PC4Zd6Mov",
	"adviserImage": "_3ljNUzNh-vOnfxFZrnGBEN",
	"adviserCard": "_12l0KPGHGYXWWbl2lh6B9H",
	"dash": "_1T4mQXPngJUQp1zVrUM8jL",
	"adviserProfileButton": "_2bs_65jEdruf5uioNifRfo",
	"dashIconContainer": "_1JEj4-DyTtrAKVtYlwTWOV",
	"show": "S2_8RU5hhaMtuJZlzSpyW",
	"hide": "_28sPjJn5vdqu_zj3Ca_1t1",
	"icon": "ZxDAJJBqIunI7IQ5Rlo3e"
};
module.exports = exports;
