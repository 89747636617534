import { useCallback } from 'react';
import styles from './adviser-card.scss';
import Button from 'components/button';
import { PolicyTypesInline } from 'components/policy-types';
import { IUser } from 'types';

const sampleSpecialisations = ['Life', 'House', 'Health'];

interface IAdviserCardProps {
  adviser: IUser;
  selected?: boolean;
  handleAdviserClick: (a: IUser) => void;
  handleConnectClick: (a: IUser) => void;
}

const AdviserCard = ({
  adviser,
  selected,
  handleAdviserClick,
  handleConnectClick,
}:IAdviserCardProps) => {
  const {
    firstName,
    lastName,
    location,
    company,
    fspNo,
    picture,
  } = adviser;

  const onAdviserClick = useCallback(() => handleAdviserClick(adviser), [adviser, handleAdviserClick]);
  const onConnectClick = useCallback((e) => {
    e.stopPropagation();
    handleConnectClick(adviser);
  }, [adviser, handleConnectClick]);

  return (
    <div className={classnames(styles.adviser, selected && styles.selected)} onClick={onAdviserClick}>
      <img className={styles.adviserImg} src={picture} alt={firstName} />
      <div className={styles.adviserName}>{`${firstName} ${lastName}`}</div>
      <div className={styles.adviserDetails}>
        <span>{location}</span>
        <span>{` • ${company}`}</span>
      </div>
      <div className={styles.adviserDetails}>
        {`FSP No: ${fspNo}`}
      </div>
      <div className={styles.adviserSpec}>
        <div className={styles.adviserSpecLabel}>Specialising in</div>
        <div className={styles.adviserSpecPolicies}>
          <PolicyTypesInline policies={sampleSpecialisations} />
        </div>
      </div>
      <div className={styles.submitContainer}>
        <Button
          className={styles.connectBtn}
          text="Connect me"
          onClick={onConnectClick}
        />
      </div>
    </div>
  );
};

export default AdviserCard;
