/* Helper functions for form fields */

import { IDropdownOption } from 'types';

export const getMakeChangeHandler = (setValues) => (_, { name, value }) => {
  setValues((prevValues) => {
    const newValues = {
      ...prevValues,
      [name]: value,
      model: null,
      subModel: null,
    };
    return newValues;
  });
};

export const getModelChangeHandler = (setValues) => (_, { name, value }) => {
  setValues((prevValues) => {
    const newValues = { ...prevValues, [name]: value };
    delete newValues.subModel;
    return newValues;
  });
};

export const getQuestionWithOption = (question, options) => {
  const updated = { ...question };
  // Change the type of field with options to a dropdown
  if (options && options.length > 0) {
    updated.options = options;
  }
  return updated;
};

export const isValueInOptions = (
  options: IDropdownOption[] = [],
  value = '',
) => {
  return options.find((m) => m.value.toLowerCase() === value.toLowerCase());
};

export const getAddressFromFullAddress = ({
  flatNo,
  streetNo,
  streetName,
  suburb,
  city,
  postCode,
}) => {
  return `${
    flatNo ? `${flatNo}/` : ''
  }${streetNo} ${streetName}, ${suburb}, ${city} ${postCode}`;
};

export const isMissingAddressFields = ({
  streetNo,
  streetName,
  suburb,
  city,
  postCode,
}) => {
  return !streetNo || !streetName || !suburb || !city || !postCode;
};
