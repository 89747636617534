import { getHeaderOptions, getHttpService } from "./http-service";
import { IInvitation } from "../types";

const httpClient = getHttpService();

export const getInvitationsByFamilyId = familyId => {
  return httpClient.get(`/invitation/familyId/${familyId}`, {
    headers: getHeaderOptions()
  });
};

export const getInvitationsByUserId = userId => {
  const encoded = encodeURIComponent(userId);
  return httpClient.get(`/invitation/user/${encoded}`, {
    headers: getHeaderOptions()
  });
};

export const deleteInvitationById = id => {
  return httpClient.delete(`/invitation/${id}`, {
    headers: getHeaderOptions()
  });
};

export const createInvitation = (invitation: IInvitation) => {
  return httpClient.post(`/invitation`, invitation, {
    headers: getHeaderOptions()
  });
};

export const processInvitation = (invitationId: string) => {
  return httpClient.put(
    `/invitation`,
    { invitationId },
    {
      headers: getHeaderOptions()
    }
  );
};

export const updateInvitation = (invitation: IInvitation) => {
  return httpClient.put(`/invitation/${invitation.id}`, invitation, {
    headers: getHeaderOptions()
  });
};
