export const UPLOAD_FAILED_MESSAGE =
  'Sorry, there was a problem uploading your files. Please try again.';
export const UPLOAD_MAX_SIZE_EXCEEDED =
  "The file you selected was too big or wasn't in the correct file format.";
export const CREATE_POLICY_ERROR =
  'Sorry, there was a problem adding your policy. Please try again.';
export const UPDATE_POLICY_ERROR =
  'Sorry, there was a problem updating your policy. Please try again.';
export const CREATE_POLICY_RENEWAL_ERROR =
  'Sorry, there was a problem renewing your policy. Please try again.';
export const ACTIVATE_POLICY_SCAN_ERROR =
  'Sorry, there was a problem saving your policy. Please try again.';
export const GET_POLICY_SCAN_ERROR =
  'Sorry, there was a problem getting your Market Scan. Please try again.';
export const INQUIRY_ERROR =
  'You have reached the question limit for the day. Please try again after 24 hours.';
export const REFRESH_QUOTE_ERROR =
  'Oops, something went wrong. Please check your details and try again.';
export const DECLINED_QUOTE_ERROR =
  'Your quote got declined for reason: #REASON#.';
export const LIFE_FETCH_QUOTE_ERROR =
  "We're currently having trouble fetching your quote. Please try again later.";
export const TRY_AGAIN_ERROR =
  'If you’re seeing this, we’ve hit a small roadblock. Don’t worry. Please try submitting it again!';
export const PINNACLE_OCCUPATION_ERROR =
  'We’re unable to cover your occupation';
