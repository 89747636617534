// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._32ED7kOnTS6Be5OCWKAPhG.ui.modal{top:8px;right:24px;width:350px}._32ED7kOnTS6Be5OCWKAPhG.ui.modal .content{background-color:#f7f8fc;padding:24px}._2HwQ4AIoDPxWoITv2pJ9cE.ui.dimmer{background-color:rgba(52,54,62,.6)}._2CBrIDVBvwA7hBBnNHaAi0{display:flex;align-items:center}._2CBrIDVBvwA7hBBnNHaAi0 h3{font-family:\"DM Sans\";font-weight:700;margin:0 0 0 8px}._1g8afoS0t5GeAaHAQt_l98{cursor:pointer;margin-left:auto}.iGg6HbrUggrWZ4TU82tzP{border:1px solid #4187f5;padding:8px;width:100%}.iGg6HbrUggrWZ4TU82tzP svg{margin-right:8px}._3Q-U24NKi_MV2xGjMgthIu{background-color:#fff;color:#4187f5;margin-bottom:16px}._3Q-U24NKi_MV2xGjMgthIu:hover{color:#4187f5}._3juVYR01YnpxNHbFSeoGTQ{background-color:#4187f5;color:#fff}._3juVYR01YnpxNHbFSeoGTQ svg path{fill:#fff}@media(max-width: 576px){._32ED7kOnTS6Be5OCWKAPhG.ui.modal{top:unset;right:unset;width:100%;max-width:350px}._32ED7kOnTS6Be5OCWKAPhG.ui.modal .content{padding:24px !important}}", ""]);
// Exports
exports.locals = {
	"modal": "_32ED7kOnTS6Be5OCWKAPhG",
	"dimmer": "_2HwQ4AIoDPxWoITv2pJ9cE",
	"header": "_2CBrIDVBvwA7hBBnNHaAi0",
	"closeIcon": "_1g8afoS0t5GeAaHAQt_l98",
	"button": "iGg6HbrUggrWZ4TU82tzP",
	"btnWhite": "_3Q-U24NKi_MV2xGjMgthIu iGg6HbrUggrWZ4TU82tzP",
	"btnBlue": "_3juVYR01YnpxNHbFSeoGTQ iGg6HbrUggrWZ4TU82tzP"
};
module.exports = exports;
