// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._21rcb6-ZuqktOnW3qSKlqH{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%}._3h2UyQ3U0x5aPZ_FiuqHmF{display:flex;flex-direction:column;background-color:#fff;align-items:center;padding:50px 20px 50px 20px;width:100%;align-self:stretch}._3-QWruU5ofkVDVdpZzZIwK{text-align:center;max-width:674px;margin-top:20px}._2GVc7Nu-TQCBzQ5OJHtTOZ{max-width:674px;text-align:center}._2r_ae_zDYCabnCS-Ivk3hj{width:100vh}._3KOCVpZgG_iIHayJolZ1hv{margin-top:30px}.dKcAa8Jk7SNULL1fB9aU4{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#4187f5;display:inline-block;text-align:center;line-height:1;margin-right:16px;margin-bottom:16px}.dKcAa8Jk7SNULL1fB9aU4:hover{opacity:.9;color:#fff}@media(max-width: 576px){.dKcAa8Jk7SNULL1fB9aU4{font-size:14px;padding-top:13px;padding-bottom:13px}}._3Q7jmGiAYEZ8NnndYi7kJJ{margin-top:10px;margin-bottom:20px;font-size:18px;font-weight:700}", ""]);
// Exports
exports.locals = {
	"bigContainer": "_21rcb6-ZuqktOnW3qSKlqH",
	"descriptionBox": "_3h2UyQ3U0x5aPZ_FiuqHmF",
	"longDescriptionBox": "_3-QWruU5ofkVDVdpZzZIwK",
	"upperDescrption": "_2GVc7Nu-TQCBzQ5OJHtTOZ",
	"innerDescription": "_2r_ae_zDYCabnCS-Ivk3hj",
	"buttonDiv": "_3KOCVpZgG_iIHayJolZ1hv",
	"button": "dKcAa8Jk7SNULL1fB9aU4",
	"subTitle": "_3Q7jmGiAYEZ8NnndYi7kJJ"
};
module.exports = exports;
