import { IOperationsState } from '../../types';
import { AppActionTypes } from '../actions';
import { Types } from '../actions/operation-actions';

const policyScanReducer = (
  state: IOperationsState,
  action: AppActionTypes
): IOperationsState => {
  switch (action.type) {
    case Types.POLICY_SCANS_LOADED:
      return {
        ...state,
        policyScans: action.data
      };
    case Types.POLICY_SCAN_LOADED:
    case Types.POLICY_SCAN_LOCAL_UPDATED:
      return {
        ...state,
        currentPolicyScan: action.data
      };
    default:
      return state;
  }
};

export default policyScanReducer;
