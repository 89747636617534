import React, { useEffect, useState } from 'react';
import { getAttachmentLinks } from 'services';
import { IAttachment } from 'types';
import styles from '../styles/PolicyTabs.module.scss';
import PolicyManual from './PolicyManual';
import PolicyUpload from './PolicyUpload';

enum Tabs {
  UPLOAD = 'UPLOAD',
  MANUAL = 'MANUAL',
}

const pageMap = {
  [Tabs.UPLOAD]: PolicyUpload,
  [Tabs.MANUAL]: PolicyManual,
};

const fetchAttachmentWithLinks = async (
  attachments: IAttachment[],
  setState,
) => {
  const att = await getAttachmentLinks(attachments);
  setState(att);
};

const PolicyTabs = ({
  adviserConnect,
  loading,
  referenceData,
  addPolicy,
  editPolicy,
  uploadAttachments,
}) => {
  const [activeTab, setTab] = useState<Tabs>(Tabs.UPLOAD);
  const [attachments, setAttachments] = useState([]); // Attachments with links
  const PageComponent = pageMap[activeTab];

  useEffect(() => {
    if (adviserConnect?.adviserConnectAttachments) {
      fetchAttachmentWithLinks(
        adviserConnect?.adviserConnectAttachments,
        setAttachments,
      );
    }
  }, [adviserConnect?.adviserConnectAttachments]);

  return (
    <div>
      <div className={styles.tabs}>
        <div
          onClick={() => setTab(Tabs.UPLOAD)}
          className={classnames(
            styles.tab,
            activeTab === Tabs.UPLOAD && styles.active,
          )}
        >
          Drop off all policies (1 min)
        </div>
        <div
          onClick={() => setTab(Tabs.MANUAL)}
          className={classnames(
            styles.tab,
            activeTab === Tabs.MANUAL && styles.active,
          )}
        >
          Enter policy information (3 min)
        </div>
      </div>
      <div className={styles.content}>
        <PageComponent
          adviserConnect={adviserConnect}
          attachments={attachments}
          loading={loading}
          referenceData={referenceData}
          addPolicy={addPolicy}
          editPolicy={editPolicy}
          uploadAttachments={uploadAttachments}
        />
      </div>
    </div>
  );
};

export default PolicyTabs;
