import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { renderBool } from 'containers/operations/utils';
import { createSelector } from 'reselect';
import { getInstalmentAmount, showMarketScanResultsPage } from 'helpers';
import { IAdviserConnect, IAppState } from 'types';
import { MeetingStatus } from 'constants/adviser';

export interface IAdviserConnectDetailsData {
  value: string | boolean;
  title: string;
  type: string;
}

export const selectAdviserConnects = (state: IAppState) =>
  state.adviser.adviserConnects;

export const selectCart = (state: IAppState) => state.adviser.cart;

export const selectPremiumAdviserConnect = (state: IAppState) =>
  state.adviser.premiumAdviserConnect;

export const selectAdviserUserConnectDetails = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (val: IAdviserConnect) => {
    if (!val) {
      return [];
    }
    let data: IAdviserConnectDetailsData[] = [
      { value: val.id, title: 'ID', type: 'string' },
      { value: val.createdBy, title: 'Created by ID (sk)', type: 'string' },
      { value: val.createdByName, title: 'Customer Name', type: 'string' },
      { value: val.createdAt, title: 'Created At', type: 'date' },
      { value: val.message, title: 'Message', type: 'string' },
      { value: val.phoneNumber, title: 'Phone Number', type: 'string' },
      {
        value: renderBool(val.asapCallback),
        title: 'Callback ASAP',
        type: 'string',
      },
      { value: val.updatedAt, title: 'Updated At', type: 'date' },
    ];

    if (val.calendlyEvent) {
      data = [
        ...data,
        {
          value: val.calendlyEvent.resource.name,
          title: 'Name',
          type: 'string',
        },
        {
          value: val.calendlyEvent.resource.start_time,
          title: ' Calendly Meeting Start Time',
          type: 'date',
        },
        {
          value: val.calendlyEvent.resource.end_time,
          title: 'Calendly Meeting End Time',
          type: 'date',
        },
      ];
    }
    return data;
  },
);

export const selectAdviserUserConnectCompletedPolicyScans = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (!adviserConnect || !adviserConnect.policyScans) {
      return [];
    }

    const policyScans = Object.keys(adviserConnect.policyScans).reduce(
      (sum, key) => {
        const typeByKey = adviserConnect.policyScans[key];
        if (typeByKey.items) {
          sum = [...sum, ...typeByKey.items];
        }
        return sum;
      },
      [],
    );

    return policyScans;
  },
);

export const selectAdviserUserConnectPolicyScans = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (!adviserConnect || !adviserConnect.policyScans) {
      return {};
    }
    return adviserConnect.policyScans;
  },
);

export const selectAdviserUserConnectAttachments = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (!adviserConnect || !adviserConnect.adviserConnectAttachments) {
      return [];
    }
    return adviserConnect.adviserConnectAttachments;
  },
);
export const selectAdviserUserConnectPolicies = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (!adviserConnect || !adviserConnect.adviserConnectPolicies) {
      return [];
    }
    return adviserConnect.adviserConnectPolicies;
  },
);

export const selectAdviserUserConnectDesiredPolicies = createSelector(
  (state: IAppState) => state.operations?.selectedAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (!adviserConnect || !adviserConnect.policies) {
      return {};
    }
    return adviserConnect.policies;
  },
);

export const selectAllPremiumAdviserScans = createSelector(
  selectPremiumAdviserConnect,
  R.propOr({}, 'policyScans'),
);

export const selectAddedToCart = createSelector(
  selectPremiumAdviserConnect,
  (adviserConnect: IAdviserConnect) => adviserConnect?.addedToCart,
);

export const selectShoppingCartItems = createSelector(
  selectPremiumAdviserConnect,
  (adviserConnect: IAdviserConnect) => {
    if (adviserConnect) {
      const { addedToCart = {}, policyScans } = adviserConnect;
      return Object.keys(addedToCart).reduce((acc, policyScanId) => {
        const item = addedToCart[policyScanId];
        const policyScansByType = R.pathOr(
          [],
          [item.policyType, 'items'],
          policyScans,
        );
        const policyScan = policyScansByType.find(R.propEq('id', policyScanId));
        if (policyScan) {
          const data = policyScan.brokerFormResults;

          acc.push({
            ...item,
            monthly: getInstalmentAmount(item.premium),
            yearly: item.premium,
            insuredItem:
              item.policyType === INSURANCE_TYPES_NAMES.VEHICLE
                ? `${data.numberPlate} ${data.make} ${data.model}`
                : data.address,
          });
        }
        return acc;
      }, []);
    }
    return [];
  },
);

export const selectUnsoldAdviserScans = createSelector(
  selectAllPremiumAdviserScans,
  (policyScans) => {
    return Object.keys(policyScans).reduce((acc, pt) => {
      const psByType = policyScans[pt]?.items || [];
      const filtered = psByType.filter((ps) => !ps.brokerPolicyAcceptedAt);
      return [...filtered, ...acc];
    }, []);
  },
);

export const selectIsAllAdviserScansInCheckout = createSelector(
  selectUnsoldAdviserScans,
  selectShoppingCartItems,
  (unsoldScans, cartItems) => cartItems.length >= unsoldScans.length,
);

export const selectPopupCartItem = createSelector(
  selectCart,
  selectShoppingCartItems,
  (cart, addedToCart) => {
    if (cart) {
      return addedToCart.find(R.propEq('policyScanId', cart.policyScanId));
    }
    return null;
  },
);

export const selectShowShoppingCart = createSelector(
  selectShoppingCartItems,
  selectPremiumAdviserConnect,
  (addedToCart, adviserConnect) =>
    addedToCart.length > 0 ||
    showMarketScanResultsPage(adviserConnect?.meetingStatus),
);

export const selectIsDeclined = createSelector(
  selectAdviserConnects,
  (adviserConnects: IAdviserConnect[]) => {
    const ad = adviserConnects[0]; // Already sorted by desc in reducer
    return ad?.meetingStatus === MeetingStatus.ADVISER_DECLINED;
  },
);
