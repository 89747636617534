import { IPolicyScan } from 'types';
import { getClaimsToken } from './storage';

declare const analytics: any;

const propsToOmit = ['dateOfBirth', 'firstName', 'lastName', 'summary'];

export enum FB_EVENTS {
  Subscribe = 'Subscribe',
  CompleteRegistration = 'CompleteRegistration',
  Contact = 'Contact',
  AddToCart = 'AddToCart',
  Purchase = 'Purchase',
  Schedule = 'Schedule',
  Search = 'Search',
}

export enum PAGE_VIEW_EVENTS {
  Dashboard = 'Dashboard-Page',
  PolicyDetails = 'Policy-Details-Page',
  ExplorePolicy = 'Discover-Insurance-Page',
  FindAdviser = 'Find-Adviser-Page',
  InsuranceCheckup = 'Insurance-Checkup-Page',
  MarketScan = 'Market-Scan-Page',
  MarketScanBroker = 'Market-Scan-Page-Broker-Selection-Page',
  MarketScanDirect = 'Market-Scan-Page-Direct-Selection-Page',
  MarketScanAddPolicy = 'Market-Scan-Add-Policy-Page',
  MarketScanNoPolicy = 'Market-Scan-No-Policy-Page',
  MarketScanPurchase = 'Market-Scan-Purchase-Page',
  MarketScanBrokerConfirmation = 'Market-Scan-Broker-Confirmation-Page',
  CreditCard = 'Explore-Credit-Card-Page',
  ProcessInvitation = 'Process-Invitation-Page',
  Share = 'Share-Policy-Page',
  User = 'User-Profile-Page',

  // Market Scan
  MarketScanLifeResults = 'Market-Scan-Life-Results-Page',
  MarketScanLifeResultsAddons = 'Market-Scan-Life-Results-Addons-Page',

  // Premium
  Premium = 'Premium-Page',

  // Onboarding
  OnboardingAccountSetupPage = 'Web-App-Onboarding-Account-Setup-Page',
  OnboardingIntroPage = 'Web-App-Onboarding-Intro-Page',
  OnboardingOptionsPage = 'Web-App-Onboarding-Options-Page',
  OnboardingUploadPage = 'Web-App-Onboarding-Upload-Page',
}

export enum TRACKING_EVENTS {
  // App
  NotificationClick = 'Web-App-Notification-Click',
  NotificationViewAll = 'Web-App-Notification-View-All-Click',

  FindAdviserSubmitted = 'Web-App-Find-Adviser-Submitted',
  InsuranceCheckupCompleted = 'Web-App-Insurance-Checkup-Completed',
  FeedbackUpvote = 'Web-App-Feedback-Vote-Upvote',
  FeedbackDownvote = 'Web-App-Feedback-Vote-Downvote',
  FeedbackNotes = 'Web-App-Feedback-Notes',

  // Market Scan
  MarketScanViewClick = 'Web-App-Market-Scan-View-Click',
  MarketScanBuy = 'Web-App-Market-Scan-Buy',
  MarketScanAddToCart = 'Web-App-Market-Scan-Add-To-Cart',
  MarketScanPageChange = 'Web-App-Market-Scan-Page-Change',
  MarketScanPurchasePageChange = 'Web-App-Market-Scan-Purchase-Page-Change',
  MarketScanPurchaseSaveDraft = 'Web-App-Market-Scan-Purchase-Save-Draft',
  MarketScanPurchasePay = 'Web-App-Market-Scan-Purchase-Pay',
  MarketScanPurchaseSuccess = 'Web-App-Market-Scan-Purchase-Success',
  MarketScanPurchaseTermsDownload = 'Web-App-Market-Scan-Purchase-Terms-Download',
  MarketScanPolicyTypeSelect = 'Web-App-Market-Scan-Policy-Type-Select',
  MarketScanDisclosureClick = 'Web-App-Market-Scan-Disclosure-Click',
  MarketScanRiskProperty = 'Web-App-Market-Scan-Risk-Property',
  MarketScanEndScreenCta = 'Web-App-Market-Scan-End-Screen-Cta',
  MarketScanCompareExistingClick = 'Web-App-Market-Scan-Compare-Existing-Click',
  MarketScanToggleExpand = 'Web-App-Market-Scan-Toggle-Expand',

  // MS Broker
  BrokerFormOpenedBanner = 'Web-App-Open-Broker-Form-Banner',
  BrokerFormOpenedFull = 'Web-App-Open-Broker-Form-Full',
  BrokerFormComplected = 'Web-App-Complete-Broker-Form',
  BrokerPolicyMakeClaim = 'Web-App-Broker-Make-Claim-Click',
  BrokerPolicyMakeChange = 'Web-App-Broker-Make-Change-Click',
  MarketScanBrokerConfirmationSubmit = 'Web-App-Broker-Confirmation-Submit',

  // MS Results
  MarketScanResultHelpClick = 'Web-App-Market-Scan-Result-Help-Click',
  MarketScanResultHelpOptionClick = 'Web-App-Market-Scan-Result-Help-Option-Click',
  MarketScanResultSeeMore = 'Web-App-Market-Scan-Result-See-More',

  // MS Life
  MarketScanLifeStart = 'Web-App-Market-Scan-Life-Start',
  MarketScanLifeSubmit = 'Web-App-Market-Scan-Life-Submit',
  MarketScanLifeProviderSelect = 'Web-App-Market-Scan-Life-Provider-Select',
  MarketScanLifeAddonChange = 'Web-App-Market-Scan-Life-Addon-Change',
  MarketScanLifeAmountChange = 'Web-App-Market-Scan-Life-Amount-Change',
  MarketScanLifeToPremium = 'Web-App-Market-Scan-Life-To-Premium',
  MarketScanLifeContinue = 'Web-App-Market-Scan-Life-Get-Cover',

  // Onboarding
  OnboardingOptionClick = 'Web-App-Onboarding-Option-Click',
  OnboardingOptionSelect = 'Web-App-Onboarding-Option-Select',
  OnboardingMsUpload = 'Web-App-Onboarding-Market-Scan-Upload',
  OnboardingMsSkip = 'Web-App-Onboarding-Market-Scan-Skip',
  OnboardingMsPolicyTypeSelect = 'Web-App-Onboarding-Market-Scan-Policy-Type-Select',
  OnboardingPolicyUpload = 'Web-App-Onboarding-Policy-Upload',

  // Shared dashboard
  SharedDashboardInvitationCreated = 'Web-App-Shared-Dashboard-Invitation-Created',
  SharedDashboardInvitationProcessed = 'Web-App-Shared-Dashboard-Invitation-Processed',

  // Policy details
  PolicyDetailsEdit = 'Web-App-Policy-Details-Edit',
  PolicyDetailsSave = 'Web-App-Policy-Details-Save',
  PolicyDetailsDelete = 'Web-App-Policy-Details-Delete',
  PolicyDetailsDocClick = 'Web-App-Policy-Details-Doc-Click',
  PolicyDetailsDocDelete = 'Web-App-Policy-Details-Doc-Delete',
  PolicyDetailsRenewalClick = 'Web-App-Policy-Details-Renewal-Click',
  PolicyDetailsTabChange = 'Web-App-Policy-Details-Tab-Change',

  // Policy renewal
  PolicyRenewalManual = 'Web-App-Policy-Renewal-Manual',
  PolicyRenewalUpload = 'Web-App-Policy-Renewal-Upload',
  PolicyRenewalUpdated = 'Web-App-Policy-Renewal-Updated',
  PolicyRenewalCreated = 'Web-App-Policy-Renewal-Created',
  PolicyRenewalPendingCompleted = 'Web-App-Policy-Renewal-Pending-Completed',

  // Premium
  PremiumEngagementCreated = 'Web-App-Premium-Engagement-Created',
  PremiumEngagementUpdated = 'Web-App-Premium-Engagement-Updated',
  PremiumUploads = 'Web-App-Premium-Uploads',
  PremiumAddPolicy = 'Web-App-Premium-Add-Policy',
  PremiumMarketScanSubmit = 'Web-App-Premium-Market-Scan-Submit',
}

export const getUserUniqId = () => {
  let uniqId = '';
  // Make sure the app is not broker
  // When Ad blocker install, user might not exist
  if (analytics.user) {
    const analyticsUser = analytics.user();
    if (analyticsUser && analyticsUser.anonymousId) {
      uniqId = analyticsUser.anonymousId();
    }
  }
  return uniqId;
};

export const logPageView = (pageName: string, data?: any) => {
  trackEvent(`Web-App-${pageName}`, {
    category: 'PageView',
    ...data,
  });
};

export const logEvent = (category: string, action: string, label: string) => {
  setTimeout(() => {
    analytics.timeout(500);
    const uniqId = getUserUniqId();
    analytics.track(category, {
      category,
      action,
      label,
      externalId: uniqId,
      external_id: uniqId,
    });
  }, 500);
};

export const trackEvent = (eventName: string, properties?: any) => {
  setTimeout(() => {
    analytics.timeout(500);
    const uniqId = getUserUniqId();
    const props = properties || {};

    props.externalId = uniqId;
    props.external_id = uniqId;

    const newProps = R.omit(propsToOmit, props);
    analytics.track(eventName, newProps);
  }, 500);
};

export const identify = (userId: string, properties: any) => {
  const firstName = properties['https://quashed.co.nz/firstName'];
  const lastName = properties['https://quashed.co.nz/lastName'];
  const userRole = properties['https://quashed.co.nz/role'];
  const email = properties['email'];
  const name = properties['name'];
  const phone = properties['phoneNumber'];
  setTimeout(() => {
    analytics.timeout(500);
    const uniqId = getUserUniqId();
    analytics.identify(userId, {
      firstName,
      lastName,
      userRole,
      email,
      name,
      phone,
      id: userId,
      externalId: uniqId,
      external_id: uniqId,
    });
  }, 500);
};

const getTrackingData = () => {
  const userClaims = getClaimsToken();
  return {
    userId: userClaims.sub,
  };
};

export const trackEventWithUserId = (eventName: string) => {
  trackEvent(eventName, getTrackingData());
};

export const trackEventWithData = (eventName: string, data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(eventName, trackingData);
};

export const trackEventCompleteRegistration = () => {
  const trackingData = getTrackingData();
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.CompleteRegistration, trackingData);
};

export const trackEventPolicyScan = () => {
  const trackingData = getTrackingData();
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.AddToCart, trackingData);
};

export const trackEventPolicyScanView = (policyScan: IPolicyScan) => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.MarketScanBuy, policyScan);
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.Purchase, R.merge(policyScan, trackingData));
};

export const trackEventFindAdviser = () => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.FindAdviserSubmitted, trackingData);
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.Schedule, trackingData);
};

export const trackEventCoverageCompleted = () => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.InsuranceCheckupCompleted, trackingData);
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.Search, trackingData);
};

export const trackVoteFeedback = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  if (data.vote) {
    trackEvent(TRACKING_EVENTS.FeedbackUpvote, trackingData);
  } else {
    trackEvent(TRACKING_EVENTS.FeedbackDownvote, trackingData);
  }
};

export const trackNotesFeedback = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.FeedbackNotes, trackingData);
};

export const trackMarketScanPageChange = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPageChange, trackingData);
};

export const trackMSPurchasePageChange = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPurchasePageChange, trackingData);
};

export const trackPurchaseSaveDraft = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPurchaseSaveDraft, trackingData);
};

export const trackPurchasePay = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPurchasePay, trackingData);
};

export const trackPurchaseTermsDownload = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPurchaseTermsDownload, trackingData);
};

export const trackSharedInvitationCreated = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.SharedDashboardInvitationCreated, trackingData);
};

export const trackSharedInvitationProcessed = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.SharedDashboardInvitationProcessed, trackingData);
};

export const trackPolicyDetailsEdit = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsEdit, trackingData);
};

export const trackPolicyDetailsSave = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsSave, trackingData);
};

export const trackPolicyDetailsDelete = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsDelete, trackingData);
};

export const trackPolicyDetailsTabChange = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsTabChange, trackingData);
};

export const trackPolicyDetailsDocClick = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsDocClick, trackingData);
};

export const trackPolicyDetailsDocDelete = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyDetailsDocDelete, trackingData);
};

export const trackPolicyRenewalManual = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyRenewalManual, trackingData);
};

export const trackPolicyRenewalUpload = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyRenewalUpload, trackingData);
};

export const trackPolicyRenewalUpdated = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyRenewalUpdated, trackingData);
};

export const trackPolicyRenewalCreated = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyRenewalCreated, trackingData);
};

export const trackPolicyRenewalPendingCompleted = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PolicyRenewalPendingCompleted, trackingData);
};

export const trackNotificationClick = () => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.NotificationClick, trackingData);
};

export const trackNotificationViewAllClick = () => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.NotificationViewAll, trackingData);
};

export const trackMSViewClick = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanViewClick, trackingData);
};

export const trackMSPolicyTypeSelect = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPolicyTypeSelect, trackingData);
};

export const trackMSDisclosureClick = () => {
  trackEvent(TRACKING_EVENTS.MarketScanDisclosureClick, getTrackingData());
};

export const trackMSResultHelpClick = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanResultHelpClick, trackingData);
};

export const trackMSResultHelpOptionClick = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanResultHelpOptionClick, trackingData);
};

export const trackMSResultSeeMore = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanResultSeeMore, trackingData);
};

export const trackMSBrokerConfirmationSubmit = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanBrokerConfirmationSubmit, trackingData);
};

export const trackMSPurchaseSuccess = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanPurchaseSuccess, trackingData);
};

export const trackMarketScanEndScreenCta = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.MarketScanEndScreenCta, trackingData);
};

export const trackPremiumEngagementCreated = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PremiumEngagementCreated, trackingData);
};

export const trackPremiumEngagementUpdated = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PremiumEngagementUpdated, trackingData);
};

export const trackPremiumUploads = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PremiumEngagementUpdated, trackingData);
};

export const trackPremiumAddPolicy = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PremiumAddPolicy, trackingData);
};

export const trackPremiumMarketScanSubmit = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.PremiumMarketScanSubmit, trackingData);
};

export const trackMarketScanCompareExistingClick = () => {
  trackEvent(TRACKING_EVENTS.MarketScanCompareExistingClick, getTrackingData());
};

export const trackPremiumAddToCart = (policyScan: IPolicyScan) => {
  const trackingData = getTrackingData();
  trackEvent(TRACKING_EVENTS.MarketScanAddToCart, policyScan);
  trackEvent(FB_EVENTS.Contact, trackingData);
  trackEvent(FB_EVENTS.Purchase, R.merge(policyScan, trackingData));
};

export const trackOnboardingOptionClick = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.OnboardingOptionClick, trackingData);
};

export const trackOnboardingOptionSelect = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.OnboardingOptionSelect, trackingData);
};

export const trackOnboardingMsPolicyTypeSelect = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.OnboardingMsPolicyTypeSelect, trackingData);
};

export const trackOnboardingMsUpload = (data: any) => {
  const trackingData = R.merge(data, getTrackingData());
  trackEvent(TRACKING_EVENTS.OnboardingMsUpload, trackingData);
};

export const trackOnboardingMsSkip = () => {
  trackEvent(TRACKING_EVENTS.OnboardingMsSkip, getTrackingData());
};

export const trackOnboardingPolicyUpload = () => {
  trackEvent(TRACKING_EVENTS.OnboardingPolicyUpload, getTrackingData());
};
