import React, { useCallback } from 'react';
import { Search as ViewSvg } from 'react-feather';
import { Dropdown } from 'semantic-ui-react';
import { convertToNZDate } from 'helpers';
import { IAttachment } from 'types';
import styles from './uploader.scss';

interface IUploaderAttachmentsProps {
  attachments: IAttachment[];
  onViewClick(href: string): void;
  onDeleteClick?(document: IAttachment): void;
}

interface IUploaderAttachmentProps {
  attachment: IAttachment;
  index?: number;
  onViewClick(href: string): void;
  onDeleteClick?(attachment: IAttachment): void;
}

const ActionMenu = ({ attachment, id, onDeleteClick }) => {
  const openUrl = () => {
    window.open(attachment.href, 'blank');
  };

  const handleDeleteClick = () => {
    onDeleteClick(attachment);
  };

  return (
    <Dropdown
      id={`${id}_Menu`}
      icon="chevron down"
      floating={true}
      labeled={true}
      className={styles.action}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          id={`${id}_Menu_Download`}
          className={styles.actionItem}
          onClick={openUrl}
        >
          Download
        </Dropdown.Item>
        {onDeleteClick && (
          <Dropdown.Item
            id={`${id}_Menu_Delete`}
            className={styles.actionItem}
            onClick={handleDeleteClick}
          >
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const UploaderAttachment = ({
  attachment,
  index,
  onDeleteClick,
  onViewClick,
}: IUploaderAttachmentProps) => {
  const { fileName, name, href } = attachment;

  const handleViewClick = useCallback(() => onViewClick(href), [
    href,
    onViewClick,
  ]);

  const openUrl = () => {
    window.open(attachment.href, 'blank');
  };

  const isExternalPdf = href && href.includes('protecta.co.nz');
  const id = `UploaderAttachment_${index}`;
  const date = convertToNZDate(attachment.createdAt);

  return (
    <div
      id={id}
      className={styles.document}
      onClick={isExternalPdf ? openUrl : handleViewClick}
    >
      <div className={styles.borderWrap}>
        <div className={styles.nameContainer}>
          <div className={styles.viewBtn}>
            <ViewSvg />
          </div>
          <span className={styles.name}>
            {fileName || name}
            <span className={styles.nameDate}>{date}</span>
          </span>
        </div>
        <div className={styles.buttons}>
          <div className={styles.date}>
            <span>{'Uploaded '}</span>
            {date}
          </div>
          <ActionMenu
            id={id}
            attachment={attachment}
            onDeleteClick={onDeleteClick}
          />
        </div>
      </div>
    </div>
  );
};

const UploaderAttachments = ({
  attachments,
  ...otherProps
}: IUploaderAttachmentsProps) => (
  <>
    {attachments.map((attachment, index) => (
      <UploaderAttachment
        key={index}
        attachment={attachment}
        index={index}
        {...otherProps}
      />
    ))}
  </>
);

export default UploaderAttachments;
