import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { setClaimsToken, getClaimsToken, identify } from './helpers';
import { UserRoles } from 'constants/user';

const DEFAULT_REDIRECT_CALLBACK = (appState) =>
  window.history.replaceState(
    appState,
    document.title,
    window.location.pathname,
  );

export const Auth0Context = React.createContext({} as any);
export const useAuth0 = () => useContext(Auth0Context);

export const hasRole = (role) => {
  const claims = getClaimsToken();
  return R.pathOr('', ['https://quashed.co.nz/roles', 0], claims) === role;
};

const getRole = () => {
  const claims = getClaimsToken();
  return R.pathOr(UserRoles.USER, ['https://quashed.co.nz/roles', 0], claims);
};

const isAdviserRole = () => {
  return getRole() === UserRoles.ADVISER;
};

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState({});
  const [auth0Client, setAuth0] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(
        initOptions as Auth0ClientOptions,
      );
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const authenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(authenticated);

      if (authenticated) {
        const currentUser = await auth0FromHook.getUser();
        const token = await auth0FromHook.getIdTokenClaims();
        setClaimsToken(token);
        setUser(currentUser);
        identify(currentUser.sub, currentUser);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        hasRole,
        getRole,
        isAdviserRole,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => {
          if (auth0Client) {
            auth0Client.loginWithRedirect(...p);
          }
        },
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client && auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
