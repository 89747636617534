// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1zOJ91J1gieovDqt5gEyg0{width:100%;margin-right:-30px}._36hrLEeYh3rPRVUvJ8yizy{font-weight:bold;font-size:16px;margin-bottom:30px;text-align:left}._35IlW5PGiEsZqihU8kPvJm{display:flex;flex-wrap:wrap}._3Gjsj55zI1uDA09XQ-AerY{flex:1;margin-right:30px;margin-left:0}._3Gjsj55zI1uDA09XQ-AerY .ui.dropdown .text{height:31px;line-height:36px}._3Gjsj55zI1uDA09XQ-AerY .ui.dropdown+._39nAQmJa8-zugVyZsLY1Bj{padding-right:24px}._3Gjsj55zI1uDA09XQ-AerY input{height:70px}._3Gjsj55zI1uDA09XQ-AerY input:focus+._39nAQmJa8-zugVyZsLY1Bj{top:0px !important}._3Gjsj55zI1uDA09XQ-AerY .ui.dropdown+._39nAQmJa8-zugVyZsLY1Bj,._3Gjsj55zI1uDA09XQ-AerY input+._39nAQmJa8-zugVyZsLY1Bj{top:50%;transform:translateY(-50%)}._3Gjsj55zI1uDA09XQ-AerY .ui.dropdown+._39nAQmJa8-zugVyZsLY1Bj.hasValue,._3Gjsj55zI1uDA09XQ-AerY input+._39nAQmJa8-zugVyZsLY1Bj.hasValue{top:0 !important;padding-right:8px}._3Gjsj55zI1uDA09XQ-AerY .ui.dropdown.active+._39nAQmJa8-zugVyZsLY1Bj,._3Gjsj55zI1uDA09XQ-AerY input.active+._39nAQmJa8-zugVyZsLY1Bj{top:0 !important}.xUgzhT-Dqqo-MfEm_l32t{width:calc(33% - 12px);justify-content:center;align-items:center;display:flex}.xUgzhT-Dqqo-MfEm_l32t>div>input{height:100%}.xUgzhT-Dqqo-MfEm_l32t>div:first-child{width:100%;height:100%}._23Ty8qfrn-VNOr4brhQ6h4>div{display:flex;align-items:center}@media(max-width: 820px){.xUgzhT-Dqqo-MfEm_l32t{width:100%}}@media(max-width: 636px){._3Gjsj55zI1uDA09XQ-AerY{max-width:none}}", ""]);
// Exports
exports.locals = {
	"lifeOptionalRow": "_1zOJ91J1gieovDqt5gEyg0",
	"lifeOptionalTitle": "_36hrLEeYh3rPRVUvJ8yizy",
	"lifeOptionalInputs": "_35IlW5PGiEsZqihU8kPvJm",
	"lifeOptionalFields": "_3Gjsj55zI1uDA09XQ-AerY",
	"lifeOptionalPlaceholder": "_39nAQmJa8-zugVyZsLY1Bj",
	"lifeCoverInputs": "xUgzhT-Dqqo-MfEm_l32t",
	"lifeCoverCheckbox": "_23Ty8qfrn-VNOr4brhQ6h4"
};
module.exports = exports;
