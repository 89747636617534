import Spinner from 'components/spinner';
import React from 'react';
import { InlineWidget } from 'react-calendly';
import { AdviseDisclaimer } from 'components/disclaimer/Disclaimer';
import { IUser } from 'types';
import styles from './styles/adviser-connect-calendar.scss';

declare const CALENDLY: string;

interface IProps {
  user: IUser;
  loadingCalender: boolean;
}

const widgetStyles = {
  height: 'auto',
};

const ConnectForm = ({ user, loadingCalender }: IProps) => {
  return (
    <div className={styles.container}>
      {loadingCalender && <Spinner loading={loadingCalender} />}
      <InlineWidget
        url={CALENDLY}
        prefill={{
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          name: `${user.firstName} ${user.lastName}`
        }}
        styles={widgetStyles}
      />
      <AdviseDisclaimer />
    </div>
  );
};

ConnectForm.defaultProps = {
  disable: {
    message: false
  }
};

export default ConnectForm;
