// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3DtKW1G0EIiU6ci2qLRr1q.ui.modal > .content{text-align:center;color:#34363e;padding:24px;border-radius:5px}._2jzSYmaMEoe7E5kg3MjdP-,._3_Xc3Y6H7Dl9Lhd7RZrMyS{margin-bottom:16px}._31fkTww9J_Y6lqCv7pSciK{font-family:\"DM Sans\";font-weight:700;font-size:24px;margin-bottom:8px}._3_Xc3Y6H7Dl9Lhd7RZrMyS{font-family:\"Open Sans\";font-weight:400}._3ivcBsaqiQKdx6wo51_dEI{width:100%;max-width:420px;height:auto;margin:24px auto;display:block}._140eJx40E-eeMRvAj22CPG{text-align:center}._1tPPGU1Ae7PMZDuSJzu-72.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#7ba770}@media(max-width: 576px){._1tPPGU1Ae7PMZDuSJzu-72.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}", ""]);
// Exports
exports.locals = {
	"modal": "_3DtKW1G0EIiU6ci2qLRr1q",
	"svg": "_2jzSYmaMEoe7E5kg3MjdP-",
	"subtitle": "_3_Xc3Y6H7Dl9Lhd7RZrMyS",
	"title": "_31fkTww9J_Y6lqCv7pSciK",
	"video": "_3ivcBsaqiQKdx6wo51_dEI",
	"buttonContainer": "_140eJx40E-eeMRvAj22CPG",
	"submit": "_1tPPGU1Ae7PMZDuSJzu-72"
};
module.exports = exports;
