import { prettifyNumber } from 'helpers';
import { createSelector } from 'reselect';
import { IAppState, IReferenceData } from 'types';

interface IFieldOptions {
  [key: string]: Array<{
    key: string;
    text: string;
    value: string | number;
  }>;
}

export const getExcessOptions = (
  policyType: string,
  referenceData: IReferenceData,
) => {
  const excessMap = R.pathOr(
    null,
    ['insuranceCheckup', policyType],
    referenceData,
  );

  if (excessMap) {
    return Object.keys(excessMap).map((k) => {
      const value = parseInt(k, 10);
      return {
        key: k,
        text: `$${prettifyNumber(value)}`,
        value,
      };
    });
  }
  return [];
};

export const selectFieldOptions = (policyType: string) =>
  createSelector(
    (state: IAppState) => state.referenceData,
    (referenceData) => {
      const options = {} as IFieldOptions;

      options.excess = getExcessOptions(policyType, referenceData);

      return options;
    },
  );
