import { dateValidator, validatePhone } from 'helpers';
import { OCCUPATION_OPTIONS } from './occupations';
import {
  EMPLOYMENT_OPTIONS,
  INCOME_FREQUENCY_OPTIONS,
  motorcycleLicenseOptions,
} from './options';

export enum ProfileTabs {
  PERSONAL = 'Personal',
  FINANCES = 'Finances',
  FAMILY = 'Family',
  ASSETS = 'Assets',
  ADVISERS = 'My Adviser',
}

const GENDER_OPTIONS = [
  {
    key: 'Male',
    text: 'Male',
    value: 'male',
  },
  {
    key: 'Female',
    text: 'Female',
    value: 'female',
  },
];

const LICENSE_OPTIONS = [
  {
    key: 'NZ Learners',
    text: 'NZ Learners',
    value: 'NZ Learners',
  },
  {
    key: 'NZ Restricted',
    text: 'NZ Restricted',
    value: 'NZ Restricted',
  },
  {
    key: 'NZ Full',
    text: 'NZ Full',
    value: 'NZ Full',
  },
  {
    key: 'Australia',
    text: 'Australia',
    value: 'Australia',
  },
  {
    key: 'United Kingdom',
    text: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    key: 'Ireland',
    text: 'Ireland',
    value: 'Ireland',
  },
  {
    key: 'South Africa',
    text: 'South Africa',
    value: 'South Africa',
  },
  {
    key: 'International / Other overseas licence',
    text: 'International / Other overseas licence',
    value: 'International / Other overseas licence',
  },
];

const ACCIDENT_OPTIONS = [
  {
    key: 'Yes',
    text: 'Yes',
    value: true,
  },
  {
    key: 'No',
    text: 'No',
    value: false,
  },
];

// We tick "Profile Completed" on SetupStatus when any of the onboarding=true fields has a value
export const USER_FIELDS = [
  { key: 'firstName', label: 'First name', type: 'input' },
  { key: 'lastName', label: 'Last name', type: 'input' },
  { key: 'email', label: 'Email', disabled: true },
];

export const ADVISER_FIELDS = [
  { key: 'firstName', label: 'First name' },
  { key: 'lastName', label: 'Last name' },
  { key: 'email', label: 'Email', disabled: true },
  { key: 'phone', label: 'Contact number' },
  { key: 'company', label: 'Company' },
  { key: 'fspNo', label: 'FSP number' },
  { key: 'website', label: 'Website' },
];

export const CONSENT_FIELDS_BULK = [
  { key: 'firstName', label: 'First name', required: true },
  { key: 'lastName', label: 'Last name', required: true },
  { key: 'email', label: 'Email', required: true },
  {
    key: 'dateOfBirth',
    label: 'Date of birth',
    type: 'birthday',
    arrow: true,
    validator: dateValidator,
  },
];

export const CONSENT_FIELDS = [
  ...CONSENT_FIELDS_BULK,
  {
    key: 'isPolicyOwner',
    label: 'Confirm this is the policy owner',
    inputType: 'checkbox',
    required: true,
  },
];

export const CLIENT_FIELDS = [
  { key: 'firstName', label: 'First name', required: true },
  { key: 'phone', label: 'Phone', placeholder: 'Optional' },
  { key: 'lastName', label: 'Last name', required: true },
  {
    key: 'address',
    label: 'Address',
    inputType: 'address',
    placeholder: 'Optional',
  },
  {
    key: 'dateOfBirth',
    label: 'DOB',
    type: 'birthday',
    required: true,
    validator: dateValidator,
  },
  {
    key: 'isPolicyOwner',
    label: 'I confirm this is the policy owner being added',
    inputType: 'checkbox',
    required: true,
  },
  { key: 'email', label: 'Email', required: true },
  {
    key: 'hasConsent',
    label: 'The client has given consent to be signed up',
    inputType: 'checkbox',
    required: true,
  },
];

export const PERSONAL_USER_FIELDS = [
  {
    key: 'address',
    label: 'Address',
    inputType: 'address',
    type: 'input',
    listDefaultKey: 'addresses',
    fullWidth: true,
  },
  {
    key: 'gender',
    label: 'Gender',
    type: 'dropdown',
    options: [
      { key: 'male', text: 'Male', value: 'male' },
      { key: 'female', text: 'Female', value: 'female' },
    ],
  },
  {
    label: 'Driving experience',
    key: 'drivingExp',
    type: 'number',
  },
  {
    key: 'dateOfBirth',
    label: 'D.O.B',
    type: 'birthday',
    arrow: true,
    validator: dateValidator,
  },
  {
    key: 'phone',
    label: 'Phone',
    type: 'input',
    validator: validatePhone,
    placeholder: 'e.g. 0210123456',
  },
  {
    key: 'holidays',
    label: 'Travel',
    type: 'dropdown',
    options: [
      {
        key: 'holidays1',
        text: 'Once in a blue moon',
        value: 'once',
      },
      {
        key: 'holidays2',
        text: 'Once a year',
        value: 'yearly',
      },
      {
        key: 'holidays3',
        text: 'More than once a year',
        value: 'frequently',
      },
    ],
  },
  {
    key: 'occupation',
    label: 'Occupation',
    type: 'search',
    options: OCCUPATION_OPTIONS,
  },
  {
    key: 'employment',
    label: 'Employment Status',
    type: 'dropdown',
    options: EMPLOYMENT_OPTIONS,
  },
  {
    key: 'mortgage',
    label: 'Mortgage',
    type: 'dropdown',
    options: [
      {
        key: 'mortgage1',
        text: 'Yes',
        value: true,
      },
      {
        key: 'mortgage2',
        text: 'No',
        value: false,
      },
    ],
  },
  {
    label: 'License Type',
    key: 'licenseType',
    type: 'dropdown',
    options: LICENSE_OPTIONS,
  },
  {
    label: 'Motorcyle License Type',
    key: 'motorcycleLicenseType',
    type: 'dropdown',
    options: motorcycleLicenseOptions,
  },
  {
    key: 'smoker',
    label: 'Smoker',
    type: 'dropdown',
    options: [
      {
        key: 'Yes',
        text: 'Yes',
        value: true,
      },
      {
        key: 'No',
        text: 'No',
        value: false,
      },
    ],
  },
  {
    key: 'pet',
    label: 'Pets',
    type: 'dropdown',
    options: [
      {
        key: 'pet1',
        text: 'None',
        value: 'none',
      },
      {
        key: 'pet2',
        text: 'Dog',
        value: 'dog',
      },
      {
        key: 'pet3',
        text: 'Cat',
        value: 'cat',
      },
      {
        key: 'pet4',
        text: 'Both',
        value: 'both',
      },
      {
        key: 'pet5',
        text: 'Others',
        value: 'others',
      },
    ],
  },
  {
    key: 'employerId',
    altKey: 'otherEmployerName',
    label: 'Workplace',
  },
];

export const FAMILY_DEPENDENCY_FIELD = {
  key: 'isFamilyDependent',
  calcLabel: 'Is your family dependant on your income?',
  label: 'Dependent family',
  type: 'dropdown',
  options: [
    {
      key: 'yes',
      text: 'Yes',
      value: true,
    },
    {
      key: 'no',
      text: 'No',
      value: false,
    },
  ],
};

export const INCOME_FIELD = {
  key: 'income',
  calcLabel: 'What is your yearly income?',
  label: 'Current income',
  inputType: 'number',
  type: 'number',
  prefix: '$',
};

export const INCOME_FREQUENCY_FIELD = {
  key: 'incomeFrequency',
  label: 'Income frequency',
  type: 'dropdown',
  options: INCOME_FREQUENCY_OPTIONS,
};

export const FAMILY_DEP_YEARS_FIELD = {
  key: 'familyDependentYears',
  calcLabel:
    'No. of years to provide your income for your family after you pass away',
  label: 'Years of providing',
  inputType: 'number',
  type: 'number',
  decimalScale: 0,
};

export const OUTSTANDING_MORTGAGE_FIELD = {
  key: 'outstandingMortgage',
  calcLabel: 'Do you have any outstanding debts that need paying off?',
  calcLabel2: 'Eg. Outstanding mortgage, other debts, credit card etc...',
  label: 'Outstanding mortgage',
  inputType: 'number',
  type: 'number',
  prefix: '$',
};

export const OTHER_DEBTS_FIELD = {
  key: 'otherDebts',
  calcLabel: 'Any other expenses you would like to include?',
  calcLabel2: 'Eg. Childrens education, everyday life etc...',
  label: 'Other debts',
  inputType: 'number',
  type: 'number',
  prefix: '$',
};

export const OTHER_EXPENSES_FIELD = {
  key: 'otherExpenses',
  label: 'Other expenses',
  inputType: 'number',
  type: 'number',
  prefix: '$',
};

export const LIFE_USER_FIELDS = [
  FAMILY_DEPENDENCY_FIELD,
  OUTSTANDING_MORTGAGE_FIELD,
  INCOME_FIELD,
  INCOME_FREQUENCY_FIELD,
  OTHER_DEBTS_FIELD,
  FAMILY_DEP_YEARS_FIELD,
  // OTHER_EXPENSES_FIELD
];

const ASSETS_USER_FIELDS = [
  {
    key: 'cars',
    label: 'Cars',
    type: 'dropdown',
    options: [
      {
        key: 'cars1',
        text: 'Own',
        value: true,
      },
      {
        key: 'cars2',
        text: 'None',
        value: false,
      },
    ],
  },
  {
    key: 'homeOwnership',
    label: 'Home',
    type: 'dropdown',
    options: [
      {
        key: 'rental',
        text: 'Rental',
        value: 'rental',
      },
      {
        key: 'own',
        text: 'My own',
        value: 'own',
      },
    ],
  },
  {
    key: 'landlord',
    label: 'Investment properties',
    type: 'dropdown',
    options: [
      { key: '0', text: '0', value: '0' },
      { key: '1', text: '1', value: '1' },
      { key: '2', text: '2', value: '2' },
      { key: '3+', text: '3+', value: '3+' },
    ],
  },
  {
    key: 'boat',
    label: 'Boats',
    type: 'dropdown',
    options: [
      {
        key: 'boat1',
        text: 'Own',
        value: true,
      },
      {
        key: 'boat2',
        text: 'None',
        value: false,
      },
    ],
  },
  {
    key: 'motorcycles',
    label: 'Motorcycles',
    type: 'dropdown',
    options: [
      {
        key: 'boat1',
        text: 'Own',
        value: true,
      },
      {
        key: 'boat2',
        text: 'None',
        value: false,
      },
    ],
  },
];

export const USER_FIELDS_BY_TABS = {
  [ProfileTabs.PERSONAL]: PERSONAL_USER_FIELDS,
  [ProfileTabs.FINANCES]: LIFE_USER_FIELDS,
  [ProfileTabs.FAMILY]: [],
  [ProfileTabs.ASSETS]: ASSETS_USER_FIELDS,
};

export const PROFILE_COMPLETED_FIELDS = R.pluck('key', [
  ...PERSONAL_USER_FIELDS,
  ...ASSETS_USER_FIELDS,
]);

export const USER_FAMILY_FIELDS = [
  {
    key: 'name',
    label: 'Name',
    mobile: true,
  },
  {
    key: 'relationship',
    label: 'Relationship',
    mobile: true,
  },
  // TODO: Add these after implementing new life insurance purchase flow
  // {
  //   key: 'gender',
  //   label: 'Gender',
  // },
  // {
  //   key: 'dateOfBirth',
  //   label: 'DOB',
  // },
  // {
  //   key: 'smoker',
  //   label: 'Smoking History',
  // },
  {
    key: 'invitedEmail',
    label: 'Email',
  },
];

export const ADVISER_USER_FIELDS = [
  {
    key: 'name',
    label: 'Name',
    getValue: (u) => `${u.firstName} ${u.lastName}`,
    mobile: true,
  },
  {
    key: 'phone',
    label: 'Contact',
    link: true,
    getLink: (u) => `tel:${u.phone}`,
  },
  {
    key: 'company',
    label: 'Company',
  },
  {
    key: 'email',
    label: 'Email',
    mobile: true,
    link: true,
    getLink: (u) => `mailto:${u.email}`,
  },
];

export const CONTENT_FIELDS = [
  {
    key: 'contentElectronics',
    img: require('images/wiki/content-electronics.svg'),
    calcLabel: 'Total value of all your electronics',
    calcLabel2:
      'TV, phone, laptop, tablet, headphones, computer, smart watch, smart home devices, speakers etc...',
    label: 'Electronics',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentPersonal',
    img: require('images/wiki/content-personal.svg'),
    calcLabel: 'Total value of your personal items',
    calcLabel2:
      'Clothes, shoes, hats, bags, make up, accessories, hair tools etc...',
    label: 'Personal items',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentValuables',
    img: require('images/wiki/content-valuable.svg'),
    calcLabel: 'Total value of your valuables',
    calcLabel2:
      'Bracelets, watches, rings, necklaces, broaches, cuff links, earrings etc...',
    label: 'Valuables',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentKitchen',
    img: require('images/wiki/content-kitchen.svg'),
    calcLabel: 'Total value of your kitchen items',
    calcLabel2: 'Kitchen appliances, utensils, china, glassware etc...',
    label: 'Kitchen',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentFurniture',
    img: require('images/wiki/content-furniture.svg'),
    calcLabel: 'Total value of your furniture',
    calcLabel2:
      'In your bedrooms, lounge, dining, bathroom, gaming room etc...',
    label: 'Furniture',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentWhiteware',
    img: require('images/wiki/content-whiteware.svg'),
    calcLabel: 'Total value of your whiteware',
    calcLabel2: 'Washing machine, dryer, dehumidifier, stove, fridge etc...',
    label: 'Whiteware',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentSports',
    img: require('images/wiki/content-sports.svg'),
    calcLabel: 'Total value of your sports equipment',
    calcLabel2:
      'Rackets, footwear, kayaks, paddles, canoes, boards, gym gear etc...',
    label: 'Sports/Leisure equipment',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
  {
    key: 'contentCustom',
    img: require('images/wiki/content-custom.svg'),
    calcLabel: 'Have any custom or super valuable items?',
    calcLabel2: 'Extra jewellery, DJ equipment, collectibles etc...',
    label: 'Custom items',
    inputType: 'number',
    type: 'number',
    prefix: '$',
  },
];
