import { useEffect, useState } from 'react';

const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const oneMinute = 60e3;

// Based no https://github.com/streamich/react-use/blob/master/src/useIdle.ts
const useIdle = (ms: number = oneMinute, callback: () => void): boolean => {
  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    let timeout: any;
    let localState: boolean = state;
    const set = (newState: boolean) => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    const onTimeout = () => {
      set(true);
      if (callback) {
        callback();
      }
    };

    const onEvent = () => {
      if (localState) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(onTimeout, ms);
    };

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    events.forEach((event) => {
      window.addEventListener(event, onEvent);
    });

    document.addEventListener('visibilitychange', onVisibility);

    timeout = setTimeout(onTimeout, ms);

    return () => {
      mounted = false;

      events.forEach((event) => {
        window.removeEventListener(event, onEvent);
      });

      document.removeEventListener('visibilitychange', onVisibility);
    };
  }, [ms]);

  return state;
};

export default useIdle;
