// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2BKpd1Ywk6PNWC3G3xtH2h.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#4187f5;color:#fff}@media(max-width: 576px){._2BKpd1Ywk6PNWC3G3xtH2h.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}", ""]);
// Exports
exports.locals = {
	"button": "_2BKpd1Ywk6PNWC3G3xtH2h"
};
module.exports = exports;
