// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._6-5yvc3s2_m31Pt1nEMSK{margin-top:-60px}._6-5yvc3s2_m31Pt1nEMSK iframe{height:660px}@media(max-width: 1355px){._6-5yvc3s2_m31Pt1nEMSK{margin-top:-60px}._6-5yvc3s2_m31Pt1nEMSK iframe{height:885px}}@media(max-width: 1005px){._6-5yvc3s2_m31Pt1nEMSK{margin-top:0}._6-5yvc3s2_m31Pt1nEMSK iframe{height:780px}}@media(max-width: 992px){._6-5yvc3s2_m31Pt1nEMSK{margin-top:-60px}._6-5yvc3s2_m31Pt1nEMSK iframe{height:885px}}@media(max-width: 820px){._6-5yvc3s2_m31Pt1nEMSK{margin-top:0}._6-5yvc3s2_m31Pt1nEMSK iframe{height:780px}}", ""]);
// Exports
exports.locals = {
	"container": "_6-5yvc3s2_m31Pt1nEMSK"
};
module.exports = exports;
