import { getAge, prettifyNumber } from 'helpers';
import { IUser } from 'types';

// TODO: Move to API and store in contentful
const common = {
  policyProviderId: 'policy-provider:cda5f8f0-20b4-11ea-8ea4-af6325a80c01',
  policyProviderName: 'AIA',
  logoUrl:
    'https://images.ctfassets.net/3rn1xghl5q2h/4JtEhbBGhIgtfzPRtVFbEQ/9ebef364cc8a73e92fa38e62f4da00e6/aia.png',
  bonus: '1st month FREE',
  benefits: {
    photoUrl:
      'https://www.aia.co.nz/content/dam/nz/en/starterplans/images/logo-AIA-Vitality-Starter.svg',
    benefitUrl:
      'https://www.aia.co.nz/en/starterplans/aia-vitality-starter.html',
    benefitPdfUrl:
      'https://quashed.co.nz/documents/aia-vitality-starter-points-and-rewards.pdf',
    items: [
      '25% off Les Mills membership',
      'Free health checks',
      'Gain more benefits upon membership!',
    ],
  },
  selectedCoverTitleImgUrl: 'https://quashed.co.nz/images/q-aia.png',
  selectedCoverTitle: 'Great job on selecting a cover!',
  selectedCoverDescription:
    'Quashed and AIA have partnered to make insurance easily accessible for Kiwis. We will share your first name & email with AIA if you continue with this application.',
  selectedCoverEligibilityTitle: 'To be eligible for an AIA starter cover:',
  selectedCoverEligibilityItems: [
    'Must be a new customer to AIA',
    'Between the ages of 20-55',
    'A resident of New Zealand',
  ],
};

const defaultMapping = {
  healthyStarterResult: {
    order: 1,
    ...common,
    title: 'Rent Protection',
    description:
      'Receive payments to help cover rent if you’re unable to work due to a sickness or injury.',
  },
  starterLifeResult: {
    order: 2,
    ...common,
    title: 'Life',
    description:
      'Your loved ones will receive a sum of money if you pass away or become terminally ill.',
  },
  starterLifePlusRentResult: {
    order: 3,
    ...common,
    title: 'Life + Rent Protection',
    description:
      'Your loved ones will receive a sum of money if you pass away or become terminally ill + Receive payments to help cover rent if you’re unable to work due to a sickness or injury.',
  },
  starterLifePlusMortgageResult: {
    order: 4,
    ...common,
    title: 'Life + Mortgage Repayment',
    description:
      'Your loved ones will receive a sum of money if you pass away or become terminally ill + Receive payments to help cover mortgage repayments if you’re unable to work due to a sickness or injury.',
  },
};

const mapOptions = (
  options: number[],
  prefix?: string,
  suffix: string = '',
) => {
  return options.map((val: number) => ({
    key: val,
    text: `${prefix}${prettifyNumber(val)}${suffix}`,
    value: val,
  }));
};

const mapQuestions = ({ life, rentMortgage, specTest }) => {
  const questions = [];
  if (life) {
    questions.push({
      key: 'life.insuredAmount',
      label: 'LIFE COVER AMOUNT',
      tooltip:
        'This is the amount your loved ones will be paid out if you were to pass away.',
      type: 'select',
      prefix: '$',
      options: mapOptions(life.options, '$'),
    });
  }

  if (rentMortgage) {
    const {
      insuredAmountMonthly,
      insuredAmountMonthlyOptions,
      paymentPeriod,
      paymentPeriodOptions,
      waitingPeriod,
      waitingPeriodOptions,
      mortgageType,
    } = rentMortgage;
    const type = mortgageType.toUpperCase();
    if (insuredAmountMonthly) {
      questions.push({
        key: 'rentMortgage.insuredAmountMonthly',
        label: `MONTHLY ${type} REPAYMENT COVER`,
        tooltip: `This is the amount you would need to cover your ${type.toLowerCase()} for the month.`,
        type: 'select',
        prefix: '$',
        suffix: ' per month',
        options: mapOptions(insuredAmountMonthlyOptions, '$', ' per month'),
      });
    }
    if (paymentPeriod) {
      questions.push({
        key: 'rentMortgage.paymentPeriod',
        label: `${type} COVER LENGTH`,
        tooltip:
          'This is the maximum amount of time you will receive payments for if you’re unable to work due to sickness or injury.',
        type: 'select',
        suffix: ' months',
        options: mapOptions(paymentPeriodOptions, '', ' months'),
      });
    }
    if (waitingPeriod) {
      questions.push({
        key: 'rentMortgage.waitingPeriod',
        label: 'WAIT PERIOD',
        tooltip:
          'The wait period is the length of time between the date on which your illness or injury commences and the date on which your insurance payments start. The longer the wait period the lower your plan cost will be.',
        type: 'select',
        suffix: ' weeks',
        options: mapOptions(waitingPeriodOptions, '', ' weeks'),
      });
    }
  }

  if (specTest) {
    questions.push({
      key: 'specTest.isSelected',
      label: 'Add Specialist & Diagnostic Testing',
      description: 'Maximum yearly cover $5,000',
      tooltip:
        'An optional benefit that covers the cost of seeing a private medical specialist and receiving diagnostic tests.',
      type: 'checkbox',
    });
  }

  return questions;
};

export const mapAiaResults = (results) => {
  return R.mapObjIndexed((data, key) => {
    const defaultData = defaultMapping[key];

    return {
      ...defaultData,
      ...data,
      valueKey: key,
      questions: mapQuestions(data),
      fortnightly: prettifyNumber(data.fortnightly, 2),
      monthly: prettifyNumber(data.monthly, 2),
    };
  }, results);
};

export const showPinnacleAddons = (dateOfBirth: string) =>
  getAge(dateOfBirth) < 60;
