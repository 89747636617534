import { getAdviser } from 'helpers';
import React from 'react';
import { IReferral, IReferralAdviser, IUser } from 'types';
import ReferBase from './ReferBase';
import styles from './styles/referAdviser.scss';

const text = {
  leftHeader: 'Is your adviser great?',
  leftTitle: 'Refer your adviser',
  leftDesc: "Think your adviser is doing a great job and want to recommend them to someone you know? Pass their details to your friend or family so they can get in touch easily.",
  submitBtnText: "Send details",
  submittedBold: "Great, we’ve sent your advisers details to them!",
  submittedDesc: "Want to refer them to another friend/family?",
};

interface IProps {
  open: boolean;
  referrals: IReferral[];
  user: IUser;
  createAdviserReferral(referral: IReferralAdviser): void;
  toggleAdviserReferral(open: boolean): void;
}

const ReferAdviser = ({
  open,
  referrals,
  user,
  createAdviserReferral,
  toggleAdviserReferral,
}: IProps) => {
  const adviser = getAdviser(user.advisers, user.createdBy);

  const handleClose = () => {
    toggleAdviserReferral(false);
  };

  const handleSubmit = (values) => {
    createAdviserReferral({
      email: values.recipientEmail,
      name: values.recipientFirstName
    });
  };

  return (
    <ReferBase
      onClose={handleClose}
      onOpen={R.F}
      open={open}
      items={referrals}
      onSubmit={handleSubmit}
      rightDesc={(
        <>
          <b>Securely send your friends and family your advisers details.</b><br />{' '}
          <div className={styles.descName}>{`${adviser?.firstName} ${adviser?.lastName}`}</div>
          <div className={styles.desc}>{adviser?.email}</div>
          <div>{adviser?.phone}</div>
        </>
      )}
      {...text}
    />
  );
};

export default ReferAdviser;
