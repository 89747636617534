import React from 'react';
import styles from './headings.scss';

const Heading = ({ children, className }) => {
  return <span className={classnames(styles.h1, className)}>{children}</span>;
};

export const H1 = (props) => (
  <Heading {...props} className={classnames(styles.h1, props.className)} />
);
export const H2 = (props) => (
  <Heading {...props} className={classnames(styles.h2, props.className)} />
);
export const H3 = (props) => (
  <Heading {...props} className={classnames(styles.h3, props.className)} />
);
export const H4 = (props) => (
  <Heading {...props} className={classnames(styles.h4, props.className)} />
);
