import React, { useEffect, useState } from 'react';
import { Progress } from 'semantic-ui-react';
import { Check, Play, Plus } from 'react-feather';
import Title from 'components/title';
import styles from '../styles/MarketScanForms.module.scss';
import MarketScanList from 'components/market-scan-list';
import { MenuDropdown } from 'components/quashed';
import AdviserContact from '../components/AdviserContact';
import { getPolicyTypeByName } from 'containers/market-scan/utils';
import MarketScanBrokerForm from 'containers/market-scan/components/MarketScanBrokerForm';
import {
  isBreakpoint,
  logPageView,
  PAGE_VIEW_EVENTS,
  trackPremiumMarketScanSubmit,
} from 'helpers';
import {
  allMarketScanFormsCompleted,
  isMSCompleted,
  PREMIUM_GENERAL_POLICY_TYPES,
} from '../utils';
import { INSURANCE_TYPES_ICONS_SVG_V2 } from 'constants/insurance';
import { ConfettiModal } from 'components/modals';

const Status = ({ policyScan }) => {
  if (isMSCompleted(policyScan)) {
    return (
      <div className={styles.completed}>
        <span>COMPLETED</span>
        <div className={styles.greenCircle}>
          <Check size={10} />
        </div>
      </div>
    );
  }

  const percent = policyScan.brokerFormResults
    ? policyScan.brokerFormResults.percentCompleted
    : 0;

  return (
    <div className={styles.status}>
      <Progress percent={percent || 5} />
      <span>{`${percent || 0}%`}</span>
    </div>
  );
};

const getAllMarketScans = (policyScans) => {
  return Object.keys(policyScans).reduce((acc, pt) => {
    const { items = [] } = policyScans[pt];
    const results = items.map((i) => R.propOr(i, 'brokerFormResults', i));
    return R.concat(acc, results);
  }, []);
};

const getMarketScanList = (policyScans) => {
  return Object.keys(policyScans).reduce((acc, pt) => {
    const scansByPolicyType = [];
    const { count, items = [] } = policyScans[pt];

    for (let i = 0; i < count; i++) {
      scansByPolicyType.push(
        items[i] || {
          policyType: pt,
        },
      );
    }

    // Sort items by percentCompleted
    const sorted = scansByPolicyType.sort(
      R.descend(R.pathOr(0, ['brokerFormResults', 'percentCompleted'])),
    );

    return [...acc, ...sorted];
  }, []);
};

const MarketScanForms = ({
  adviserConnect,
  policies,
  referenceData,
  user,
  beforeMarketScanSubmit,
  onMarketScanComplete,
}) => {
  const [selectedScan, setSelectedScan] = useState(null);
  const [chooseQuote, setChooseQuote] = useState(false);
  const msItems = getMarketScanList(adviserConnect.policyScans);
  const allMarketScans = getAllMarketScans(adviserConnect.policyScans);

  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 5 });
  }, []);

  const selectItem = (ps) => !isMSCompleted(ps) && setSelectedScan(ps);

  const handleBack = (page: number) => {
    if (page === 0) {
      setSelectedScan(null);
    }
  };

  const handleAfterSubmit = (data) => {
    trackPremiumMarketScanSubmit(data);
    setSelectedScan(null);
    onMarketScanComplete();
  };

  if (selectedScan) {
    const policyType = getPolicyTypeByName(
      selectedScan.policyType,
      referenceData,
    );
    return (
      <MarketScanBrokerForm
        policyType={policyType}
        // Get prefill data from brokerFormResults or policy scan itself
        policiesList={allMarketScans}
        user={user}
        onBack={handleBack}
        onAfterSubmit={handleAfterSubmit}
        onAfterNext={onMarketScanComplete}
        onBeforeSubmit={beforeMarketScanSubmit}
        policyScan={selectedScan}
        submitOnNext={true}
        metadata={{
          adviserUserConnectId: adviserConnect.id,
        }}
      />
    );
  }

  const actionItems = [
    {
      actionId: 'Start',
      icon: Play,
      getText: ({ policyScan }) =>
        policyScan.brokerFormResults ? 'Continue form' : 'Start form',
      onClick: ({ policyScan }) => {
        selectItem(policyScan);
      },
    },
    // {
    //   actionId: 'Delete',
    //   icon: Trash2,
    //   text: 'Delete',
    //   onClick: ({ policyScan }) => {
    //     console.log('Delete', policyScan);
    //   },
    // },
  ];

  const showSuccess = allMarketScanFormsCompleted(adviserConnect.policyScans);
  const showChooseQuote = () => setChooseQuote(true);
  const hideChooseQuote = () => setChooseQuote(false);
  const handleGetQuote = (pt) => {
    setSelectedScan({ policyType: pt });
  };
  const handleMouseEnter = () => !isBreakpoint('md') && showChooseQuote();

  return (
    <div className={styles.container}>
      <Title
        title="Policy details required"
        subtitle="Our adviser needs you to provide us with more information"
      />
      <ConfettiModal
        modalClassName={styles.success}
        open={showSuccess}
        heading="Yay, your forms are completed!"
        content={
          <>
            Sit back and relax,
            <br />
            our adviser will be in touch soon.
          </>
        }
      />
      <div className={styles.topContainer}>
        <div className={styles.overview}>
          {Object.keys(adviserConnect.policyScans).map((pt) => {
            const { count, items = [] } = adviserConnect.policyScans[pt];

            if (count === 0) {
              return null;
            }

            const completedCount = items.filter(isMSCompleted).length;
            const isComplete = completedCount === count;

            return (
              <div key={pt} className={styles.overviewItem}>
                <div className={styles.overviewName}>
                  {pt}
                  {isComplete && (
                    <div className={styles.greenCircle}>
                      <Check size={10} />
                    </div>
                  )}
                </div>
                <div className={styles.overviewCount}>
                  <h3>{completedCount}</h3>/{count} COMPLETED
                </div>
              </div>
            );
          })}
        </div>
        <AdviserContact adviser={adviserConnect.adviser} />
      </div>
      <MarketScanList
        items={msItems}
        className={styles.list}
        headerClassName={styles.headers}
        titleClassName={styles.title}
        contentClassName={styles.content}
        policyProviders={referenceData.policyProviders}
        onClick={selectItem}
        statusComponent={Status}
        actionMenuComponent={({ policyScan }) =>
          !isMSCompleted(policyScan) && (
            <MenuDropdown items={actionItems} policyScan={policyScan} />
          )
        }
      >
        {!showSuccess && (
          <div
            className={styles.getQuote}
            onClick={showChooseQuote}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={hideChooseQuote}
          >
            <div className={styles.bar} />
            <div className={styles.label}>
              <Plus />
              <b>
                {chooseQuote ? (
                  <span className={styles.labelText}>Choose a quote</span>
                ) : (
                  'Get another quote'
                )}
              </b>
            </div>
            {chooseQuote && (
              <div className={styles.icons}>
                {PREMIUM_GENERAL_POLICY_TYPES.map((pt) => {
                  const IconComponent = INSURANCE_TYPES_ICONS_SVG_V2[pt];

                  return (
                    <div
                      key={`quote_${pt}`}
                      className={styles.icon}
                      onClick={() => handleGetQuote(pt)}
                    >
                      <IconComponent />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </MarketScanList>
    </div>
  );
};

export default MarketScanForms;
