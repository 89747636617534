import React, { useCallback, useMemo } from 'react';
import styles from './styles/contents.scss';
import Button from '../../button';
import GuyCalculator from '-!svg-react-loader!images/people/guy-calculator.svg';
import { prettifyNumber } from 'helpers';
import { TypeFormField } from 'components/type-form';
import { CONTENT_FIELDS } from 'constants/user-form';
import useForm from 'hooks/useForm';
import usePager from 'hooks/usePager';
import { IUser } from 'types';
import { Form } from 'semantic-ui-react';

interface IProps {
  user: IUser;
  updateUser(user: IUser): void;
  backToForm?(total: number): void;
}

const Buttons = ({ prev, next, submitText }) => (
  <div className={styles.buttons}>
    <Button className={styles.back} onClick={prev} text="Back" type="button" />
    <Button
      type="submit"
      className={styles.next}
      onClick={next}
      text={submitText}
    />
  </div>
);

const FirstPage = ({ next }) => {
  return (
    <div className={styles.page}>
      <div className={styles.headerStretch}>
        <GuyCalculator />
        <div className={styles.bold}>
          Calculate how much your valuables are worth
        </div>
      </div>
      <div className={styles.buttons}>
        <Button className={styles.back} onClick={next} text="Start" />
      </div>
    </div>
  );
};

const Question = ({ field, form, isLast, prev, next }) => {
  return (
    <Form className={styles.page}>
      <div className={styles.header}>
        <img alt={field.label} src={field.img} />
        <div className={styles.bold}>{field.label}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.label}>{field.calcLabel}</div>
        <div className={styles.labelBlue}>{field.calcLabel2}</div>
        <TypeFormField className={styles.field} form={form} question={field} />
      </div>
      <Buttons
        prev={prev}
        next={next}
        submitText={
          form.values[field.key] ? (isLast ? 'Finish' : 'Next') : 'Skip'
        }
      />
    </Form>
  );
};

const LastPage = ({ form, reset, backToForm }) => {
  const total = useMemo(() => {
    return Object.keys(form.values).reduce(
      (acc, k) => acc + (form.values[k] || 0),
      0,
    );
  }, [form.values]);

  const handleClick = () => {
    reset();
    if (backToForm) {
      backToForm(total);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <GuyCalculator />
      </div>
      <div className={styles.body}>
        <div className={styles.bold}>
          Here’s an amount you can consider insuring for...
        </div>
        <div className={styles.bigNumber}>{`$${prettifyNumber(total)}`}</div>
      </div>
      <div className={styles.buttons}>
        <Button
          className={styles.back}
          onClick={handleClick}
          text={backToForm ? 'Finish' : 'Redo'}
        />
      </div>
    </div>
  );
};

const getDefaultValues = (user) => {
  return CONTENT_FIELDS.reduce((acc, field) => {
    acc[field.key] = user[field.key] || '';
    return acc;
  }, {});
};

const ContentsCalculator = ({ user, updateUser, backToForm }: IProps) => {
  const { active, next, prev, setActive } = usePager(0, true);
  const defaultValues = useMemo(() => getDefaultValues(user), [user]);
  const form = useForm({ defaultValues });
  const isLast = active === CONTENT_FIELDS.length;

  const handleNext = useCallback(() => {
    next();
    if (isLast) {
      const updated = R.merge(user, form.values);
      updateUser(updated);
    }
  }, [isLast, form.values, user, next, updateUser]);

  const handleReset = useCallback(() => setActive(1), []);

  if (active === 0) {
    return <FirstPage next={next} />;
  }

  if (active === CONTENT_FIELDS.length + 1) {
    return <LastPage reset={handleReset} form={form} backToForm={backToForm} />;
  }

  return (
    <Question
      field={CONTENT_FIELDS[active - 1]}
      form={form}
      isLast={isLast}
      prev={prev}
      next={handleNext}
    />
  );
};

export default ContentsCalculator;
