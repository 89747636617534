// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._35GSElYA-IsTfe7b88qFml{font-size:8px;line-height:1.2}._2O7kAyUqATPjynm25OSU0D{display:flex;flex-wrap:row;align-items:baseline;justify-content:space-between;margin-top:8px}._1V99d_d4dvvgdonuc0Et5g{display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;width:20%;max-height:40px;margin-top:24px}.SbXMBv3Lvht8BVuUFd3Hg{align-self:center;height:auto;max-width:70%;justify-self:baseline}", ""]);
// Exports
exports.locals = {
	"label": "_35GSElYA-IsTfe7b88qFml",
	"insuranceTypeContainer": "_2O7kAyUqATPjynm25OSU0D",
	"pairContainer": "_1V99d_d4dvvgdonuc0Et5g",
	"icon": "SbXMBv3Lvht8BVuUFd3Hg"
};
module.exports = exports;
