import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from 'types';
import { isFamilyOwner } from 'helpers';
import { UserRoles } from 'constants/user';
import { useAuth0 } from '../react-auth0-spa';
import {
  GetPolicyScanByUserId,
  GetUserPolicies,
  GetInvitationsForFamilyId,
  GetConsents,
  GetReferrals,
  GetClients,
  GetPolicyBundlesByUserId,
  GetPendingRenewalsByUserId,
  GetAdviserConnectsForCurrentUser,
  GetCreditCardPolicies,
  GetCreditCardPoliciesLoaded,
  GetNotifications,
} from 'actions';
import { INVITATION_ROUTE } from 'constants/routes';

// This hook dispatches functions once user is loaded
const useUserLoaded = () => {
  const dispatch = useDispatch();
  const { hasRole } = useAuth0();
  const {
    user,
    invitation: { invitations },
  } = useSelector(R.identity) as IAppState;

  const isAdviser = hasRole(UserRoles.ADVISER);

  useEffect(() => {
    if (user.id) {
      if (isAdviser) {
        dispatch(GetConsents());
        dispatch(GetClients());
      }

      if (!isAdviser) {
        dispatch(GetPolicyBundlesByUserId(user.id));
        dispatch(GetPolicyScanByUserId(user.id));
        dispatch(GetPendingRenewalsByUserId());
        dispatch(GetReferrals());
        dispatch(GetAdviserConnectsForCurrentUser());
        dispatch(GetNotifications());
      }
    }
  }, [user.id]);

  useEffect(() => {
    if (
      user.currentFamilyId &&
      !isAdviser &&
      // This will prevent race condition when loading invitation policies
      // as the Invitation component will handle it on their own
      window.location.pathname !== INVITATION_ROUTE
    ) {
      dispatch(GetUserPolicies(user.currentFamilyId));

      if (isFamilyOwner(user)) {
        // Load owned credit cards
        dispatch(GetCreditCardPolicies(user.id));

        // Load invitations
        if (invitations.length === 0) {
          dispatch(GetInvitationsForFamilyId(user.currentFamilyId));
        }
      } else {
        // Hide credit cards for shared policy
        dispatch(GetCreditCardPoliciesLoaded([]));
      }
    }
  }, [user.currentFamilyId]);
};

export default useUserLoaded;
