// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".kGQwKj33iwnIoCMeLByBI{position:relative;width:38px;height:38px;background-color:#4187f5;display:flex;align-items:center;justify-content:center;border-radius:50%;margin-left:8px;cursor:pointer}.kGQwKj33iwnIoCMeLByBI svg{width:21px;height:21px}.kGQwKj33iwnIoCMeLByBI svg path{fill:#fff}.ySxrr0mjVWr3GxksCl61c{position:absolute;border-radius:50%;background-color:#e47c6e;width:8px;height:8px;text-align:center;bottom:15px;right:4px;color:#fff;font-size:10px}.ySxrr0mjVWr3GxksCl61c._1au6INLo0vVpyh1glK79Qg{background-color:#7ba770}@media(max-width: 820px){.kGQwKj33iwnIoCMeLByBI{margin-left:0;margin-right:0;background-color:rgba(0,0,0,0)}.kGQwKj33iwnIoCMeLByBI svg{width:24px;height:24px}}", ""]);
// Exports
exports.locals = {
	"trigger": "kGQwKj33iwnIoCMeLByBI",
	"dot": "ySxrr0mjVWr3GxksCl61c",
	"green": "_1au6INLo0vVpyh1glK79Qg"
};
module.exports = exports;
