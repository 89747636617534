// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".pFYBSzBGOf8Q30QbugR9u{display:inline-flex;flex-direction:row;align-items:center}._3WSCNfBX6fDWI7gzlyiQY8{color:#fff;fill:#c4c4c4}._3WSCNfBX6fDWI7gzlyiQY8 circle{color:rgba(0,0,0,0)}", ""]);
// Exports
exports.locals = {
	"toggleContainer": "pFYBSzBGOf8Q30QbugR9u",
	"toggle": "_3WSCNfBX6fDWI7gzlyiQY8"
};
module.exports = exports;
