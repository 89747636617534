import { useEffect, useState } from 'react';
import { getAllPolicyTypesByProviderId } from 'services';
import { getSubTypeOptions } from 'helpers';

export const getSubTypeOptionsAsync = (policyProviderId, policyTypeName) => {
  return getAllPolicyTypesByProviderId(policyProviderId).then((results) =>
    getSubTypeOptions(results.data.Items, policyTypeName),
  );
};

export const usePolicySubTypes = (policyProviderId, policyTypeName) => {
  const [subTypes, setSubTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (policyProviderId && policyTypeName) {
      setLoading(true);
      getSubTypeOptionsAsync(policyProviderId, policyTypeName).then(
        (results) => {
          setSubTypes(results);
          setLoading(false);
        },
      );
    }
  }, [policyProviderId, policyTypeName]);

  return { subTypes, loading };
};
