// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal{text-align:center;padding:40px 24px;max-width:550px;border-radius:5px}._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .header:not(.ui){font-family:\"Open Sans\";font-weight:700;font-size:18px;color:#34363e;border-bottom:none;padding:0;margin-bottom:16px}._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .content{color:#34363e;padding:0;margin-bottom:16px}._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .actions{text-align:center;border-top:none;display:flex;justify-content:center;padding:0;background-color:#fff}._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .actions > .ui.button.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;background-color:#4187f5;order:2;max-width:135px}@media(max-width: 576px){._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .actions > .ui.button.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .actions > .ui.primary.button.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;background-color:#ff5353;order:1;max-width:135px}@media(max-width: 576px){._2ptEX1uYDS1Ho7QsK7zCvj.ui.modal > .actions > .ui.primary.button.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}", ""]);
// Exports
exports.locals = {
	"confirmAction": "_2ptEX1uYDS1Ho7QsK7zCvj"
};
module.exports = exports;
