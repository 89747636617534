import { useEffect, useState } from 'react';
import { getMakes, getModels, getSubmodels, VehicleCoverTypes } from 'services';
import {
  getVehicleTypeByPolicyType,
  toDropdownOptionsWithTransformer,
} from 'helpers';

const makeModelTransformer = {
  [VehicleCoverTypes.CAR]: {
    // We uppercase car values to match lookup values
    value: R.toUpper,
  },
};

const setMakes = async (type, setState) => {
  const { data } = await getMakes(type);
  if (Array.isArray(data)) {
    setState(
      R.assoc(
        'make',
        toDropdownOptionsWithTransformer(data, makeModelTransformer[type]),
      ),
    );
  }
};

const setModels = async (type, make, setState) => {
  const { data } = await getModels(type, make);
  if (Array.isArray(data)) {
    setState(
      R.assoc(
        'model',
        toDropdownOptionsWithTransformer(data, makeModelTransformer[type]),
      ),
    );
  }
};

const setSubmodels = async (type, make, model, setState) => {
  if (type === VehicleCoverTypes.CAR) {
    const { data } = await getSubmodels(type, make, model);
    if (Array.isArray(data)) {
      setState(
        R.assoc(
          'subModel',
          toDropdownOptionsWithTransformer(data, makeModelTransformer[type]),
        ),
      );
    }
  }
};

// Returns object containing make, model and submodel based on input
const useVehicleInfoOptions = (
  policyType: string,
  make: string,
  model: string,
) => {
  const type = getVehicleTypeByPolicyType(policyType);
  const [options, setOptions] = useState({});

  // Set makes
  useEffect(() => {
    if (type) {
      setMakes(type, setOptions);
    }
  }, [type]);

  // Set models
  useEffect(() => {
    if (type && make) {
      setModels(type, make, setOptions);
    }
  }, [type, make]);

  // Set submodels
  useEffect(() => {
    if (type && make) {
      if (model) {
        setSubmodels(type, make, model, setOptions);
      } else {
        // Set empty subModels list when there is no model
        setOptions(R.assoc('subModel', []));
      }
    }
  }, [type, make, model]);

  return options;
};

export default useVehicleInfoOptions;
