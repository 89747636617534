import { useCallback } from 'react';
import styles from './styles/adviser-connect.scss';
import { Modal } from 'semantic-ui-react';
import { IUser } from 'types';
import { IUseForm } from 'hooks/useForm';
import JumpImg from 'images/explore/explore2.svg';
import ConnectForm from './AdviserConnectForm';

interface IAdviserConnectModal {
  adviser: IUser;
  form: IUseForm;
  open: boolean;
  submitted?: boolean;
  isConnected?: boolean;
  onClose: () => void;
  handleConnect: (a: IUser) => void;
}

const connectSubmitted = (
  <>
    <div className={styles.submitTitle}>An adviser will be in touch!</div>
    <div className={styles.submitText}>Thanks for choosing to engage with an insurance expert. We're connecting you with an adviser we know. The adviser should be in touch within the next 48 hours.</div>
    <img className={styles.submitImg} src={JumpImg} alt="Submitted" />
  </>
);

const alreadyConnected = (
  <>
    <div className={styles.submitTitle}>Hooray! You're connected</div>
    <div className={styles.submitText}>We've connected you with an adviser within the last 30 days. If you have any issues, please drop us an email - team@quashed.co.nz</div>
    <img className={styles.submitImg} src={JumpImg} alt="Submitted" />
  </>
);

const getSuccessContent = (submitted) => (
  submitted
    ? connectSubmitted
    : alreadyConnected
);

const AdviserConnectModal = ({
  open,
  onClose,
  adviser,
  form,
  handleConnect,
  submitted,
  isConnected,
}: IAdviserConnectModal) => {
  const handleConnectClick = useCallback(() => handleConnect(adviser), [handleConnect]);
  return (
    <Modal
      className={styles.modal}
      size="tiny"
      open={open}
      onClose={onClose}
      closeIcon="times circle"
    >
      <Modal.Content>
        {
          (submitted || isConnected)
            ? getSuccessContent(submitted)
            : <ConnectForm form={form} handleConnectClick={handleConnectClick} />
        }
      </Modal.Content>
    </Modal>
  );
};

export default AdviserConnectModal;
