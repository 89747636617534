import {
  getContentsErrorText,
  getHouseErrorText,
  validateContentsAmount,
  validateHouseAmount,
} from 'helpers';
import { minAgeDateValidator } from '../helpers/date';
import { INSURANCE_TYPES_NAMES } from './insurance';
import { areaOfGaragingOptions, GENDER_OPTIONS, yesNoOptions } from './options';
import {
  MS_ADDRESS_QUESTION,
  CC_RATING_QUESTION,
  DRIVING_EXP_QUESTION,
  FINANCE_QUESTION,
  MAKE_QUESTION,
  MODEL_QUESTION,
  MOTORCYCLE_FINANCE_QUESTION,
  MOTORCYCLE_LICENSE_TYPE_QUESTION,
  MOTORCYCLE_OWNERSHIP_QUESTION,
  MOTORCYCLE_PARKING,
  MOTORCYCLE_REGO_QUESTION,
  REGO_QUESTION,
  REGO_STATUS_QUESTION,
  SUBMODEL_QUESTION,
  VEHICLE_YEAR_QUESTION,
  AREA_OF_GARAGING_QUESTION,
  MOTORCYCLE_USE_QUESTION,
  THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION,
  THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION,
  EXCESS_QUESTION,
  BUILD_YEAR_QUESTION,
  FLOOR_AREA_QUESTION,
  EXTERNAL_AREA_QUESTION,
} from './questions';

export const GENDER_QUESTION = {
  line: 1,
  before: 'I am a',
  key: 'gender',
  label: 'Gender',
  after: ' ',
  order: 1,
  type: 'dropdown',
  options: GENDER_OPTIONS,
  source: 'user',
};

export const DOB_QUESTION = {
  line: 1,
  before: ' and my date of birth is ',
  key: 'dateOfBirth',
  label: 'DOB',
  order: 2,
  type: 'birthday',
  source: 'user',
  validator: minAgeDateValidator,
};

const LICENSE_TYPE_QUESTION = {
  line: 2,
  before: 'I have a ',
  after: ' license',
  key: 'licenseType',
  label: 'License Type',
  order: 1,
  type: 'dropdown',
  source: 'user',
  options: [
    'NZ Learners',
    'NZ Restricted',
    'NZ Full',
    'Australia',
    'United Kingdom',
    'Ireland',
    'South Africa',
    'International / Other overseas licence',
  ],
};

const LICENSE_CONFIRM_QUESTION = {
  line: 9,
  before: 'Please confirm your license is equivalent to a NZ Full license ',
  key: 'licenseConfirm',
  label: 'NZ Equivalent Full License',
  order: 1,
  type: 'checkbox',
  inputType: 'checkbox',
  source: 'user',
  defaultValue: false,
  showKeyValue: ({ licenseType }) => {
    return (
      licenseType &&
      !['NZ Learners', 'NZ Restricted', 'NZ Full'].includes(licenseType)
    );
  },
};

const ACCIDENT_QUESTION = {
  line: 5,
  before: 'Have you had any accidents or claims in the last five years?',
  key: 'accidentHistory',
  label: 'Accident history',
  order: 1,
  type: 'dropdown',
  getValue: (v) =>
    v ? 'One or more in the past 5 years' : 'None in the past 5 years',
  options: [
    {
      key: 'No',
      text: 'No',
      value: false,
    },
    {
      key: 'Yes',
      text: 'Yes',
      value: true,
    },
  ],
};

const DATE_OF_CLAIM_QUESTION = {
  line: 7,
  before: 'Date of claim',
  key: 'accidentClaimDate1',
  label: 'Claim date',
  order: 1,
  type: 'date',
  max: new Date(),
  showKeyValue: { accidentClaimCount: 1 },
};

const CLAIM_TYPE_QUESTION = {
  line: 6,
  before: 'Type of claim',
  key: 'claimType1',
  label: 'Claim type',
  optionKey: 'claimType',
  order: 1,
  type: 'dropdown',
  showKeyValue: { accidentHistory: true },
};

const CLAIMS_QUESTIONS = [
  {
    line: 6,
    before: 'Were you at fault?',
    key: 'accidentFault',
    label: 'Accident at fault',
    order: 1,
    type: 'dropdown',
    showKeyValue: { accidentHistory: true },
    options: [
      {
        key: 'Yes',
        text: 'Yes',
        value: true,
      },
      {
        key: 'No',
        text: 'No',
        value: false,
      },
    ],
  },
  {
    line: 6,
    before: 'How many claims?',
    key: 'accidentClaimCount',
    label: 'Claim count',
    order: 2,
    type: 'dropdown',
    showKeyValue: { accidentHistory: true },
    options: [
      {
        key: '1',
        text: '1',
        value: 1,
      },
      {
        key: '2',
        text: '2',
        value: 2,
      },
    ],
  },
  DATE_OF_CLAIM_QUESTION,
  {
    line: 7,
    before: 'Date of first claim',
    key: 'accidentClaimDate1',
    label: 'Claim date #1',
    order: 1,
    type: 'date',
    max: new Date(),
    showKeyValue: { accidentClaimCount: 2 },
  },
  {
    line: 8,
    before: 'Date of second claim',
    key: 'accidentClaimDate2',
    label: 'Claim date #2',
    order: 1,
    type: 'date',
    max: new Date(),
    showKeyValue: { accidentClaimCount: 2 },
  },
];

const HOME_CONTENTS_COMMON = [
  {
    line: 3,
    before: 'Do you have a security system?',
    key: 'securitySystem',
    label: 'Security system',
    order: 2,
    type: 'dropdown',
    options: [
      {
        key: 'Yes, monitored',
        text: 'Yes, monitored',
        value: 'monitored',
      },
      {
        key: 'Yes, not monitored',
        text: 'Yes, not monitored',
        value: 'not monitored',
      },
      {
        key: 'none',
        text: 'none',
        value: 'none',
      },
    ],
  },
];

const ACCIDENT_QUESTION_CONTENTS = [
  ...HOME_CONTENTS_COMMON,
  ACCIDENT_QUESTION,
  CLAIM_TYPE_QUESTION,
  {
    ...DATE_OF_CLAIM_QUESTION,
    showKeyValue: { accidentHistory: true },
  },
];

const IMMOBILISER_QUESTION = {
  line: 4,
  before: 'Does your vehicle have an immobiliser?',
  key: 'isImmobiliser',
  label: 'Immobiliser',
  order: 1,
  type: 'dropdown',
  options: [
    {
      key: 'Yes',
      text: 'Yes',
      value: true,
    },
    {
      key: 'No',
      text: 'No',
      value: false,
    },
  ],
};

const HAZARD_QUESTION = {
  line: 6,
  before: 'Identified by council as a risk from natural hazards?',
  key: 'isRiskHazard',
  label: 'Risk from natural hazards',
  order: 1,
  type: 'dropdown',
  options: [
    {
      key: 'Yes',
      text: 'Yes',
      value: true,
    },
    {
      key: 'No',
      text: 'No',
      value: false,
    },
  ],
};

const HOME_PROPERTY_OCCUPANTS = [
  {
    key: 'Owner',
    text: 'Owner Occupied',
    value: 'owner',
  },
  {
    key: 'owner_boarder',
    text: 'Owner + Boarder',
    value: 'owner_boarder',
  },
];

const PROPERTY_TYPE_QUESTION = {
  line: 3,
  before: 'This is my ',
  after: ' home.',
  key: 'propertyType',
  label: 'Property type',
  order: 1,
  type: 'dropdown',
  options: [
    {
      key: 'permanent',
      text: 'permanent',
      value: 'permanent',
    },
    {
      key: 'holiday',
      text: 'holiday (without tenancy)',
      value: 'holiday',
    },
  ],
};

const PROPERTY_OCCUPANT_QUESTION = {
  line: 2,
  before: 'Who lives in the property?',
  key: 'propertyOccupants',
  label: 'Occupants',
  order: 2,
  type: 'dropdown',
  showKeyValue: { isPropertyOwner: true },
  options: [
    {
      key: 'Owner',
      text: 'Owner Occupied',
      value: 'owner',
    },
    {
      key: 'Tenants',
      text: 'Tenants',
      value: 'tenants',
    },
    {
      key: 'Both',
      text: 'Both',
      value: 'both',
    },
    {
      key: 'None',
      text: 'None',
      value: 'none',
    },
  ],
};

const PROPERTY_OCCUPANT_NOT_OWNER_QUESTION = {
  line: 3,
  before: 'I am a',
  key: 'propertyOccupants',
  label: 'Occupants',
  order: 1,
  type: 'dropdown',
  showKeyValue: { isPropertyOwner: false },
  options: [
    {
      key: 'Boarder',
      text: 'Boarder',
      value: 'boarder',
    },
    {
      key: 'Tenant',
      text: 'Tenant',
      value: 'tenants',
    },
  ],
};

export const ADDRESS_CONTENT_QUESTION = {
  ...MS_ADDRESS_QUESTION,
  before: 'My address is',
  line: 2,
  order: 1,
};

const MARKET_SCAN_CONTENT_BASIC = [
  GENDER_QUESTION,
  DOB_QUESTION,
  ADDRESS_CONTENT_QUESTION,
  {
    line: 3,
    before: 'I ',
    after: ' this property.',
    key: 'isPropertyOwner',
    label: 'Property owner',
    order: 1,
    type: 'dropdown',
    options: [
      {
        key: 'own',
        text: 'own',
        value: true,
      },
      {
        key: 'do not own',
        text: 'do not own',
        value: false,
      },
    ],
  },

  PROPERTY_OCCUPANT_NOT_OWNER_QUESTION,
  { ...PROPERTY_OCCUPANT_QUESTION, line: 3, order: 2 },
  {
    ...PROPERTY_TYPE_QUESTION,
    showKeyValue: { isPropertyOwner: true },
    line: 4,
    order: 1,
  },
];

const MARKET_SCAN_CONTENT = [
  ...R.remove(2, 1, MARKET_SCAN_CONTENT_BASIC),
  ...ACCIDENT_QUESTION_CONTENTS,
];

const MARKET_SCAN_HOME = [
  GENDER_QUESTION,
  DOB_QUESTION,
  {
    ...PROPERTY_OCCUPANT_QUESTION,
    options: HOME_PROPERTY_OCCUPANTS,
    showKeyValue: R.T,
  },
  PROPERTY_TYPE_QUESTION,
  ...ACCIDENT_QUESTION_CONTENTS,
];

const MARKET_SCAN_VEHICLE = [
  GENDER_QUESTION,
  DOB_QUESTION,
  LICENSE_TYPE_QUESTION,
  DRIVING_EXP_QUESTION,
  FINANCE_QUESTION,
  IMMOBILISER_QUESTION,
  ACCIDENT_QUESTION,
  ...CLAIMS_QUESTIONS,
  LICENSE_CONFIRM_QUESTION,
];

const MARKET_SCAN_MOTORCYCLE = [
  GENDER_QUESTION,
  DOB_QUESTION,
  MOTORCYCLE_LICENSE_TYPE_QUESTION,
  MOTORCYCLE_FINANCE_QUESTION,
  MOTORCYCLE_PARKING,
  R.merge(MOTORCYCLE_USE_QUESTION, { line: 4 }),
  MOTORCYCLE_OWNERSHIP_QUESTION,
  THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION,
  THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION,
];

export const MARKET_SCAN_QUESTIONS_BY_TYPE = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: MARKET_SCAN_VEHICLE,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: MARKET_SCAN_MOTORCYCLE,
  [INSURANCE_TYPES_NAMES.LANDLORD]: MARKET_SCAN_HOME,
  [INSURANCE_TYPES_NAMES.HOME]: MARKET_SCAN_HOME,
  [INSURANCE_TYPES_NAMES.CONTENT]: MARKET_SCAN_CONTENT,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: MARKET_SCAN_HOME,
};

const MS_AREA_OF_GARAGING_QUESTION = {
  ...AREA_OF_GARAGING_QUESTION,
  before: 'In the area',
  options: areaOfGaragingOptions,
  line: 2,
};

const INSURED_AMOUNT_QUESTION = {
  line: 3,
  order: 3,
  before: 'Insured amount',
  key: 'amount',
  label: 'Insured amount',
  type: 'number',
  prefix: '$',
  validator: (value) => !!value,
};

const EXCESS_AMOUNT_QUESTION = {
  ...EXCESS_QUESTION,
  placeholder: '',
  line: 2,
};

export const HOME_SCAN_AMOUNTS_QUESTIONS = [
  {
    ...INSURED_AMOUNT_QUESTION,
    getErrorText: (value, form) => getHouseErrorText(value, form.livingArea),
    validator: (value, form) => validateHouseAmount(value, form.livingArea),
    line: 1,
  },
  EXCESS_AMOUNT_QUESTION,
];

export const VEHICLE_SCAN_AMOUNTS_QUESTIONS = [
  {
    ...INSURED_AMOUNT_QUESTION,
    line: 1,
  },
  EXCESS_AMOUNT_QUESTION,
];

export const MARKET_SCAN_VEHICLE_INFO = [
  MAKE_QUESTION,
  MODEL_QUESTION,
  SUBMODEL_QUESTION,
  VEHICLE_YEAR_QUESTION,
];

// Always show these fields for motorcycle
export const MARKET_SCAN_MOTORCYCLE_INFO = [
  R.omit(['showKeyValue'], MAKE_QUESTION),
  R.omit(['showKeyValue', 'getChangeHandler'], MODEL_QUESTION),
  R.omit(['showKeyValue'], CC_RATING_QUESTION),
  R.omit(['showKeyValue'], VEHICLE_YEAR_QUESTION),
  R.omit(['showKeyValue'], REGO_STATUS_QUESTION),
];

export const BROKER_QUESTIONS = [
  {
    line: 1,
    before: 'Would you like our in-house adviser to find you the right cover?',
    key: 'needRegularChecks',
    after: '',
    order: 1,
    type: 'dropdown',
    options: yesNoOptions,
  },
  {
    line: 2,
    before:
      'Would you like our adviser available to answer questions and review your insurances?',
    key: 'needAdviceForCovers',
    after: '',
    order: 1,
    type: 'dropdown',
    options: yesNoOptions,
  },
  {
    line: 3,
    before: 'Would you like our adviser to help you with any future claims?',
    key: 'needHelpOnClaims',
    after: '',
    order: 1,
    type: 'dropdown',
    options: yesNoOptions,
  },
];

export const MARKET_SCAN_NEW_VEHICLE = {
  1: [
    GENDER_QUESTION,
    DOB_QUESTION,
    LICENSE_TYPE_QUESTION,
    DRIVING_EXP_QUESTION,
    ACCIDENT_QUESTION,
    ...CLAIMS_QUESTIONS,
    LICENSE_CONFIRM_QUESTION,
  ],
  2: [R.dissoc('placeholder', REGO_QUESTION), ...MARKET_SCAN_VEHICLE_INFO],
  3: [
    MS_ADDRESS_QUESTION,
    MS_AREA_OF_GARAGING_QUESTION,
    IMMOBILISER_QUESTION,
    FINANCE_QUESTION,
  ],
};

export const MARKET_SCAN_NEW_CONTENT = {
  1: MARKET_SCAN_CONTENT_BASIC,
  2: [...ACCIDENT_QUESTION_CONTENTS],
  3: [
    {
      ...INSURED_AMOUNT_QUESTION,
      before: 'What are your contents worth?',
      link: { text: 'Use the calculator', to: '#' },
      getErrorText: (value) => getContentsErrorText(value),
      validator: (value) => validateContentsAmount(value),
    },
  ],
};

const MARKET_SCAN_HOME_DETAILS = [
  R.merge(ADDRESS_CONTENT_QUESTION, { line: 1, showWarning: true }),
  {
    ...BUILD_YEAR_QUESTION,
    tooltip:
      'Date when the house was built - if you can’t remember the exact year, put in the decade.',
  },
  {
    line: 3,
    before: 'How many storeys?',
    key: 'numberOfStories',
    label: 'Storeys',
    type: 'dropdown',
    order: 1,
    tooltip:
      'How many levels are there in your house (it counts as a level if it contains a living space).',
  },
  {
    ...FLOOR_AREA_QUESTION,
    tooltip:
      'The floor area of your house including attached garages & basements',
  },
  {
    ...EXTERNAL_AREA_QUESTION,
    tooltip:
      'The floor area of the outhouses/external garages that aren’t attached to the house. Not including sleepouts or granny flats.',
  },
  {
    line: 6,
    before: 'Wall build material',
    key: 'constructionType',
    label: 'Wall material',
    type: 'dropdown',
    order: 1,
    tooltip:
      'The material that your walls are made up of. If there are multiple materials, pick the main material.',
  },
  {
    line: 7,
    before: 'Roof Build material',
    key: 'roofType',
    label: 'Roof material',
    type: 'dropdown',
    order: 1,
    tooltip:
      'The material that your roof is made up of. If there are multiple materials, pick the main material.',
  },
  {
    line: 8,
    before: 'Slope of section',
    key: 'slope',
    label: 'Slope of section',
    type: 'dropdown',
    order: 1,
    tooltip:
      'This helps determine whether or not the slope of your section is vulnerable to natural disaster damage.',
  },
  {
    line: 9,
    before: 'Construction quality',
    key: 'quality',
    label: 'Construction quality',
    type: 'dropdown',
    order: 1,
    tooltip: [
      'Standard - Could include basic appliances, tapware, laminated bench tops and flushed face doors.',
      'High - Could include European applicances, tap ware, laminated timber bench tops, and feature doors.',
      'Pretige -  Could include European applicances, tap ware, granite bench tops, and custom doors.',
    ],
  },
];

const MARKET_SCAN_NEW_HOME = {
  1: MARKET_SCAN_HOME_DETAILS,
  2: [
    GENDER_QUESTION,
    DOB_QUESTION,
    {
      ...PROPERTY_OCCUPANT_QUESTION,
      options: HOME_PROPERTY_OCCUPANTS,
      showKeyValue: R.T,
    },
    PROPERTY_TYPE_QUESTION,
  ],
  3: ACCIDENT_QUESTION_CONTENTS,
};

export const ADDRESS_MOTORCYCLE_QUESTION = {
  ...MS_ADDRESS_QUESTION,
  line: 3,
  order: 1,
  before: 'This motorcycle is parked at',
};

export const MARKET_SCAN_NEW_MOTORCYCLE = {
  1: [
    GENDER_QUESTION,
    DOB_QUESTION,
    R.merge(MOTORCYCLE_LICENSE_TYPE_QUESTION, {
      line: 2,
      order: 1,
      source: 'user',
    }),
    MOTORCYCLE_FINANCE_QUESTION,
    ADDRESS_MOTORCYCLE_QUESTION,
    R.merge(MS_AREA_OF_GARAGING_QUESTION, {
      line: 4,
      order: 1,
      before: 'In the',
      after: 'area, ',
    }),
    R.merge(MOTORCYCLE_PARKING, {
      line: 4,
      order: 2,
      before: 'and will be parked',
    }),
    R.merge(MOTORCYCLE_USE_QUESTION, {
      line: 5,
    }),
    R.merge(MOTORCYCLE_OWNERSHIP_QUESTION, {
      line: 6,
      order: 1,
    }),
    R.merge(THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION, {
      line: 7,
      order: 1,
    }),
    R.merge(THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION, {
      line: 8,
      order: 1,
    }),
  ],
  2: [MOTORCYCLE_REGO_QUESTION, ...MARKET_SCAN_MOTORCYCLE_INFO],
};

export const MARKET_SCAN_NEW_QUESTIONS_BY_TYPE = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: MARKET_SCAN_NEW_VEHICLE,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: MARKET_SCAN_NEW_MOTORCYCLE,
  [INSURANCE_TYPES_NAMES.CONTENT]: MARKET_SCAN_NEW_CONTENT,
  [INSURANCE_TYPES_NAMES.HOME]: MARKET_SCAN_NEW_HOME,
};
