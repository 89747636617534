// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3BGbH21eVrJM-hdRlZTs8H.ui.modal > .times{position:absolute;top:25px;right:40px;color:#000;font-size:20px}._3BGbH21eVrJM-hdRlZTs8H.ui.modal > .header{font-family:\"DM Sans\";font-weight:700;font-size:28px;padding-left:40px;padding-right:40px}._3rnIruS2BXr5i5Uqhv7n2n.ui.grid > .column:not(.row){padding:20px}._1jjqQj8Ti8gBiCXCBPmTe6{cursor:pointer;text-align:center;font-family:\"Open Sans\";font-weight:400;font-size:20px;margin-bottom:40px;color:#000}._1jjqQj8Ti8gBiCXCBPmTe6:hover{color:#000}._1jjqQj8Ti8gBiCXCBPmTe6._2qiOiD1NOLa5vD7YihGtWD{font-family:\"Open Sans\";font-weight:700}._1jjqQj8Ti8gBiCXCBPmTe6._2qiOiD1NOLa5vD7YihGtWD ._1G7E7YWpFZT_tWYIBuHUoA:before{background:#f1ffee}._1G7E7YWpFZT_tWYIBuHUoA{position:relative;z-index:0}._1G7E7YWpFZT_tWYIBuHUoA:before{content:\"\";position:absolute;left:0;bottom:-10px;height:22px;width:100%;border-radius:3px;background:#eafeff;z-index:-1}@media(max-width: 820px){._3BGbH21eVrJM-hdRlZTs8H.ui.modal > .header{padding:25px !important}}@media(max-width: 576px){._3BGbH21eVrJM-hdRlZTs8H.ui.modal > .header{font-size:18px}._3BGbH21eVrJM-hdRlZTs8H.ui.modal > .times{top:25px;right:20px}._1jjqQj8Ti8gBiCXCBPmTe6{font-size:14px;margin-bottom:20px}._1G7E7YWpFZT_tWYIBuHUoA:before{bottom:-5px;height:13px}}", ""]);
// Exports
exports.locals = {
	"modal": "_3BGbH21eVrJM-hdRlZTs8H",
	"grid": "_3rnIruS2BXr5i5Uqhv7n2n",
	"item": "_1jjqQj8Ti8gBiCXCBPmTe6",
	"selected": "_2qiOiD1NOLa5vD7YihGtWD",
	"itemName": "_1G7E7YWpFZT_tWYIBuHUoA"
};
module.exports = exports;
