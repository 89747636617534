import { IAdmin } from 'types';

export enum updateUserPoliciesFieldKey {
  attachments = 'attachments',
  renewals = 'renewals',
}
export const updateUserPolicies = (
  state: IAdmin,
  action,
  fieldKey: updateUserPoliciesFieldKey,
) => {
  const policiesOfUser = R.pathOr([], ['selectedUser', 'policies'], state);
  policiesOfUser.forEach((p) => {
    if (p.id === action.data.policyId) {
      p[fieldKey] = action.data[fieldKey];
    }
  });
  const newSelectedUser = {
    ...state.selectedUser,
    policies: policiesOfUser,
  };
  return newSelectedUser;
};
