import React from 'react';
import styles from './ImageCarousel.module.scss';

interface IProps {
  className?: string;
  images: any[];
}

const ImageCarousel = ({ className, images }: IProps) => {
  const imagesElement = (
    <div className={styles.move}>
      {images.map((src, idx) => (
        <img key={idx} src={src} />
      ))}
    </div>
  );

  return (
    <div className={classnames(styles.slideshow, className)}>
      {imagesElement}
      {imagesElement}
    </div>
  );
};

export default ImageCarousel;
