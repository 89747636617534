import React, { useCallback } from 'react';
import FormEngine from 'components/form-engine';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import history from 'helpers/history';
import { MARKET_SCAN_BROKER_DEFAULT_ROUTE } from 'constants/routes';
import styles from '../styles/MarketScanBroker.module.scss';
import {
  IFormDefinition,
  IPolicy,
  IPolicyScan,
  IPolicyType,
  IUser,
} from 'types';
import { getDefaultBrokerFormValues } from '../utils/broker';
import { getBrokerFirstFormId, trackMarketScanPageChange } from 'helpers';
import { MarketScanPath } from '../reducer';
import useMarketScanForm from 'hooks/useMarketScanForm';
import { LoadingSpinner } from 'components/loading';
import getMsCalcHandlers from 'components/calculator/MarketScanCalculator';

interface IProps {
  metadata?: any;
  policyScan?: IPolicyScan;
  policyType: IPolicyType;
  policiesList: IPolicy[];
  user: IUser;
  questionProps?: any;
  submitOnNext?: boolean;
  onAfterNext?(): void;
  onBack?(page: number): void;
  onBeforeSubmit(data: any): void;
  onAfterSubmit(data: any): void;
}

const getFieldClassName = (formValues) => {
  return !R.isNil(formValues?.addressValid) ||
    !R.isNil(formValues?.vehicleFound)
    ? 'hide-button'
    : '';
};

const MarketScanBrokerForm = ({
  policyType,
  policiesList,
  policyScan,
  user,
  metadata,
  questionProps,
  submitOnNext,
  onAfterNext,
  onAfterSubmit,
  onBeforeSubmit,
  onBack,
}: IProps) => {
  const handleNext = async (page, data, form, pageNum, next) => {
    trackMarketScanPageChange({
      path: MarketScanPath.PERSONALISED,
      page: pageNum + 1,
      data,
    });
  };

  const {
    formRef,
    formOptions,
    searching,
    onInputSearch,
    onFormChange,
    onNext,
    onAddressSelect,
    setVehicleInfo,
  } = useMarketScanForm({
    policyType,
    policy: policyScan?.brokerFormResults,
    onNextPage: handleNext,
  });

  const { isOpen, onRenderLabel, onRenderPage } = getMsCalcHandlers(
    formRef,
    policyType?.name,
    user,
  );

  const formId = getBrokerFirstFormId(policyType?.name);

  const getDefaultValues = useCallback(
    (formDefinition: IFormDefinition) => {
      const defaultValues = getDefaultBrokerFormValues({
        formDefinition,
        policyType,
        policiesList,
        user,
        policyScan,
      });
      setVehicleInfo({ make: defaultValues.make, model: defaultValues.model });
      return defaultValues;
    },
    [policyScan, policyType, policiesList, user],
  );

  const formMetadata = {
    policyType: policyType.name,
    policyTypeId: policyType.id,
    ...metadata,
  };

  const handleBack = (page) => {
    if (onBack) {
      onBack(page);
    } else if (page === 0) {
      history.replace(MARKET_SCAN_BROKER_DEFAULT_ROUTE);
    }
  };

  const handleBeforeSubmit = async (data, formPage, isLastPage) => {
    let res = null;

    if (onBeforeSubmit) {
      res = await onBeforeSubmit(data);
    }

    return {
      policySubTypeId: data.policySubTypeName || 'High',
      policySubTypeName: data.policySubTypeName || 'High',
      policyScanId: data.id,
      policyId: data.sk,
      saveDraft: !isLastPage,
      pageCompleted: formPage.index,
      percentCompleted: isLastPage
        ? 100
        : R.max(
            data?.brokerFormResults?.percentCompleted || 0,
            formPage.progress,
          ),
      ...res,
    };
  };

  const handleAfterSubmit = (data: any, isLastPage: boolean) => {
    if (isLastPage) {
      return onAfterSubmit(data);
    }
    onAfterNext?.();

    // Bug on react-hook-form where it doesn't set the value for the first setValue
    setTimeout(() => {
      formRef.current.setValue('id', data.policyScan?.id);
      formRef.current.setValue('sk', data.policyScan?.sk);
    });
  };

  const defaultPage = R.isNil(policyScan?.brokerFormResults?.pageCompleted)
    ? 0
    : policyScan.brokerFormResults.pageCompleted + 1;

  return (
    <FormEngine
      formId={formId}
      formRef={formRef}
      metadata={formMetadata}
      options={formOptions}
      questionProps={questionProps}
      submitOnNext={submitOnNext}
      defaultPage={defaultPage}
      contentClassName={classnames(isOpen && styles.hide)}
      fieldClassName={getFieldClassName(formRef.current?.getValues())}
      getDefaultValues={getDefaultValues}
      onBack={handleBack}
      onAddressSelect={onAddressSelect}
      onBeforeSubmit={handleBeforeSubmit}
      onButtonClick={onInputSearch}
      onNext={onNext}
      onChange={onFormChange}
      onAfterSubmit={handleAfterSubmit}
      renderLabel={onRenderLabel}
      renderPage={onRenderPage}
      loader={
        searching && (
          <LoadingSpinner
            text={`Sit tight, we’re looking for your ${
              policyType?.name === INSURANCE_TYPES_NAMES.VEHICLE
                ? 'car'
                : 'address'
            }!`}
          />
        )
      }
    />
  );
};

export default MarketScanBrokerForm;
