import React from 'react';
import styles from '../styles/DisclosureCard.module.scss';
import { Image } from 'semantic-ui-react';
import { DISCLOSURE_TWO_LINK } from 'constants/links';

interface DisclosureCardProps {
  adviser: {
    firstName: string;
    lastName: string;
    picture: string;
  };
}

const DisclosureCard = ({ adviser }: DisclosureCardProps) => {
  const { firstName, lastName, picture } = adviser;

  return (
    <div className={styles.disclosureCard}>
      <Image className={styles.adviserImage} src={picture} />
      <div>
        <div className={styles.heading}>
          {firstName} {lastName} | General Insurance Adviser
        </div>
        <div className={styles.contentText}>
          PSF No. 1001782 <br />
          See disclosure statement{' '}
          <a
            href={DISCLOSURE_TWO_LINK}
            target="_blank"
            className={styles.disclosureLink}
          >
            here
          </a>
          .
        </div>
      </div>
    </div>
  );
};
export default DisclosureCard;
