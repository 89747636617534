import { FormIds } from 'constants/form-engine';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { FULL_ADDRESS_OTHER_QUESTIONS } from 'constants/questions';
import { IFormDefinition, IFormQuestion, IFormSubmitPayload } from 'types';
import { isEmptyValue, removeEmptyValues } from './object';

const comparators = {
  is: R.equals,
  not: R.complement(R.equals),
  valid: R.complement(R.isNil),
  invalid: R.isNil,
  lt: (value, schemaVal) => !isEmptyValue(value) && R.lt(value, schemaVal),
  gt: (value, schemaVal) => !isEmptyValue(value) && R.gt(value, schemaVal),
};

export const getAllQuestions = (formDef) => {
  if (formDef?.pages) {
    return formDef.pages.reduce((acc, page) => {
      return R.concat(page.questions || [], acc);
    }, []);
  }

  return formDef?.questions || [];
};

const getAllQuestionsToDisplay = (formDef, data) => {
  const questions = getAllQuestions(formDef);
  return getQuestionsToDisplay(questions, data);
};

const castValue = (value: any, toCast: string) => {
  switch (toCast) {
    case 'array':
      return [value];
    case 'boolean':
      return value === 'true';
    case 'number':
      return Number(value);
    default:
      return value;
  }
};

const mapArrayValues = (items, fields) => {
  return items.reduce((iAcc, v) => {
    const fieldsToDisplay = getQuestionsToDisplay(fields, v);
    const reduced = fieldsToDisplay.reduce((acc, f) => {
      const fieldVal = castValue(v[f.key], f.castAs);
      return R.assoc(f.key, fieldVal, acc);
    }, {});

    // Get rid of empty objects
    if (!isEmptyValue(removeEmptyValues(reduced))) {
      iAcc.push(reduced);
    }

    return iAcc;
  }, []);
};

export const shouldDisplay = (display = [], formValues) => {
  // Should not have any schema error
  return !R.any(({ when, ...schema }) => {
    const value = formValues[when];

    // Find error in schema
    return Object.keys(schema).find((key) => {
      const fn = comparators[key];
      return !fn(value, schema[key]);
    });
  }, display);
};

export const getQuestionsToDisplay = (
  questions: IFormQuestion[],
  formValues: any,
  keysToIgnore: string[] = [],
  override?: any,
) => {
  return questions.reduce((acc, q) => {
    if (shouldDisplay(q.display, formValues)) {
      const question = { ...q, ...override };
      if (question.key === 'address' && formValues.showFullAddress) {
        const addressQuestions = question.column
          ? FULL_ADDRESS_OTHER_QUESTIONS.map((q) => ({
              ...q,
              column: question.column,
              basic: question.basic,
              className: 'halfWidth nested',
            }))
          : FULL_ADDRESS_OTHER_QUESTIONS;
        if (R.includes(question.key, keysToIgnore)) {
          return R.concat(acc, addressQuestions);
        }
        return R.concat(acc, [question, ...addressQuestions]);
      } else {
        acc.push(question);
      }
    }
    return acc;
  }, []);
};

export const getSubmitPayload = (
  formDefinition: IFormDefinition,
  data: any,
  metadata?: any,
): IFormSubmitPayload => {
  const questionsToDisplay = getAllQuestionsToDisplay(formDefinition, data);
  const questions = questionsToDisplay.reduce((acc, question) => {
    const { key, castAs, fields } = question;
    const rawValue = data[key];

    // Ignore undefined and null values
    if (R.isNil(rawValue)) {
      return acc;
    }

    const value = fields
      ? mapArrayValues(rawValue, fields)
      : castValue(rawValue, castAs);

    if (!isEmptyValue(value)) {
      acc.push({
        key,
        value,
      });
    }

    return acc;
  }, []);

  return {
    id: formDefinition.id,
    sk: formDefinition.sk,
    questions,
    metadata,
  };
};

export const getBrokerFormId = (policyType: string) => {
  switch (policyType.toLowerCase()) {
    case INSURANCE_TYPES_NAMES.VEHICLE.toLowerCase():
      return FormIds.BROKER_FORM_VEHICLE;
    case INSURANCE_TYPES_NAMES.HOME.toLowerCase():
      return FormIds.BROKER_FORM_HOUSE;
    case INSURANCE_TYPES_NAMES.CONTENT.toLowerCase():
      return FormIds.BROKER_FORM_CONTENTS;
    default:
      return null;
  }
};

export const getBrokerFirstFormId = (policyType: string) => {
  switch (policyType.toLowerCase()) {
    case INSURANCE_TYPES_NAMES.VEHICLE.toLowerCase():
      return FormIds[`BROKER_FIRST_FORM_VEHICLE_V2`];
    case INSURANCE_TYPES_NAMES.HOME.toLowerCase():
      return FormIds[`BROKER_FIRST_FORM_HOUSE_V2`];
    case INSURANCE_TYPES_NAMES.CONTENT.toLowerCase():
      return FormIds[`BROKER_FIRST_FORM_CONTENTS_V2`];
    case INSURANCE_TYPES_NAMES.LANDLORD.toLowerCase():
      return FormIds[`BROKER_FIRST_FORM_LANDLORD_V2`];
    default:
      return null;
  }
};
