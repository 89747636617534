import { useEffect, useState } from 'react';
import { getAllInsurersByPolicyType } from 'services';

const toInsuranceProviderOptions = ({ id, policyProviderName }) => ({
  key: id,
  text: policyProviderName,
  value: id,
});

const defaultState = {
  insuranceProviders: [],
  loading: false,
};

export const useInsuranceProviders = (policyTypeId) => {
  const [state, setState] = useState(defaultState);
  
  useEffect(() => {
    if (policyTypeId) {
      setState(R.assoc('loading', true));
      getAllInsurersByPolicyType(policyTypeId)
        .then(results => {
          const sorted = R.sortBy(R.prop('policyProviderName'), results.data.Items)
            .map(toInsuranceProviderOptions);
          setState({
            insuranceProviders: sorted,
            loading: false,
          });
        });
    }
  }, [policyTypeId]);

  return state;
};
