import React, { useMemo } from 'react';
import classnames from 'classnames';
import { take } from 'ramda';
import { prepareTypeFormQuestions } from 'helpers/typeForm';
import useForm from 'hooks/useForm';
import Button from 'components/button';
import styles from './typeForm.scss';
import { isFormCompleted } from 'helpers';
import TypeFormQuestion, { ITypeFormQuestion } from './TypeFormQuestion';
import TypeFormAddress from './TypeFormAddress';

interface ITypeFormProps extends Partial<ITypeFormQuestion> {
  className?: string;
  defaultValues?: object;
  header?: any;
  hideSubmit?: boolean;
  lines?: number;
  addressClassName?: string;
  headerClassName?: string;
  rowClassName?: string;
  onSubmit?: ({ values }) => void;
}

const getLinesToShow = (lines, questions, values) => {
  if (!lines) {
    return questions.length;
  }

  let linesAnswered = questions.length;

  for (let i = 0; i < questions.length; i++) {
    const line = questions[i];
    const hasUnanswered = !!line.find((l) => R.isNil(values[l.key]));
    if (hasUnanswered) {
      linesAnswered = i;
      break;
    }
  }

  return linesAnswered % lines === 0
    ? linesAnswered + lines
    : Math.ceil(linesAnswered / lines) * lines;
};

const TypeForm = ({
  className,
  form,
  questions,
  defaultValues,
  lines,
  header,
  hideSubmit,
  rowClassName,
  headerClassName,
  onSubmit,
  ...fieldProps
}: ITypeFormProps) => {
  const preparedQuestions = useMemo(() => prepareTypeFormQuestions(questions), [
    questions,
  ]);

  if (!form) {
    form = useForm({
      defaultValues,
      onSubmit,
    });
  }

  const linesToShow = useMemo(
    () => getLinesToShow(lines, preparedQuestions, form.values),
    [form.values, preparedQuestions, lines],
  );

  const showSubmit = useMemo(() => {
    return !hideSubmit && isFormCompleted(questions, form.values);
  }, [hideSubmit, questions, form.values]);

  return (
    <div className={classnames(styles.container, className)}>
      {header && (
        <div className={classnames(styles.header, headerClassName)}>
          {header}
        </div>
      )}
      {take(linesToShow, preparedQuestions).map((line, idx) => (
        <div
          key={`line${idx}`}
          className={classnames(styles.row, rowClassName)}
        >
          {line.map((q, qIdx) => {
            const fieldCn = classnames(
              fieldProps.fieldClassName,
              fieldProps[`${q.key}ClassName`],
            );
            const QuestionComponent =
              q.key === 'address' && q.showButton
                ? TypeFormAddress
                : TypeFormQuestion;
            return (
              <QuestionComponent
                key={`${q.key}_${idx}_${qIdx}`}
                field={q}
                questions={questions}
                form={form}
                {...fieldProps}
                fieldClassName={fieldCn}
              />
            );
          })}
        </div>
      ))}
      {showSubmit && (
        <Button
          className={styles.submit}
          text="Finish"
          onClick={form.handleSubmit}
        />
      )}
    </div>
  );
};

export default TypeForm;
