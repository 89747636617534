import React from 'react';
import DropdownField from './DropdownField';
import DatePickerField from './DatePickerField';
import InputField from './InputField';
import BirthdayPicker from '../bday-picker';
import InputMultiple from './InputMultipleField';

const Field = (props) => {
  switch (props.type) {
    case 'dropdown':
      return <DropdownField {...props} />;
    case 'search':
      return (
        <DropdownField
          {...props}
          search={true}
          minCharacters={props.minCharacters}
        />
      );
    case 'birthday':
      return <BirthdayPicker {...props} />;
    case 'date':
      return <DatePickerField {...props} />;
    default:
      return props.multiple ? (
        <InputMultiple {...props} />
      ) : (
        <InputField {...props} />
      );
  }
};

Field.defaultProps = {
  value: '',
  minCharacters: 3,
};

export default Field;
