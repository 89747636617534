import { IFeedback, IOperationsState } from '../../types';
import { AppActionTypes } from '../actions';
import { Types } from '../actions/operation-actions';
import policyScanReducer from './policy-scan-reducer';
import { Types as OpsPolicyTypes } from '../actions/operation-policy-actions';
import { Types as OpsPolicyRenewalTypes } from '../actions/operation-policy-renewal-actions';
import { groupBy, prop, values } from 'ramda';

const operationsReducer = (
  state: IOperationsState,
  action: AppActionTypes,
): IOperationsState => {
  switch (action.type) {
    case Types.LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.LOADED:
      return {
        ...state,
        loading: false,
      };
    case Types.FAILED:
      return {
        ...state,
        error: action.data,
        loading: false,
      };
    case Types.POLICY_SCANS_LOADED:
    case Types.POLICY_SCAN_LOADED:
    case Types.POLICY_SCAN_LOCAL_UPDATED:
      return policyScanReducer(state, action);

    case Types.ADD_POLICY_MODEL_OPEN:
      return {
        ...state,
        addPolicyModelOpen: action.data.isOpen,
        addPolicyModelMode: action.data.mode,
      };

    case OpsPolicyTypes.UPDATE_SELECTED_POLICY_LOCAL:
      return {
        ...state,
        selectedPolicy: action.data,
      };
    case OpsPolicyTypes.UPDATE_SELECTED_POLICY_RENEW_LOCAL:
      return {
        ...state,
        selectedPolicyRenew: action.data,
      };
    case OpsPolicyTypes.NEW_SELECTED_POLICY_LOCAL:
      return {
        ...state,
        selectedPolicy: action.data,
      };
    case Types.FEEDBACK_LOADED:
      return {
        ...state,
        feedbacks: action.data,
      };
    case Types.FILTERED_POLICYS_LOADED:
      return {
        ...state,
        filteredPolicies: action.data,
      };
    case Types.REFERRAL_LOADED:
      return {
        ...state,
        referrals: action.data,
      };

    case Types.ADVISER_CONNECT_LOADED:
      return {
        ...state,
        adviserConnects: action.data,
      };

    case Types.SELECTED_ADVISER_CONNECT_LOADED:
      return {
        ...state,
        selectedAdviserConnect: action.data,
      };

    case Types.UPDATE_ADVISER_CONNECT_STATUS_LOCAL:
      const newAdviserConnectWithStatus = {
        ...state.selectedAdviserConnect,
        meetingStatus: action.data,
      };

      return {
        ...state,
        selectedAdviserConnect: newAdviserConnectWithStatus,
      };

    case Types.UPDATE_ADVISER_CONNECT_POLICY_SCANS_LOCAL:
      const policyScansToUpdate = state.selectedAdviserConnect.policyScans
        ? state.selectedAdviserConnect.policyScans
        : {};

      const newAdviserConnectWithPolicyScans = {
        ...state.selectedAdviserConnect,
        policyScans: {
          ...policyScansToUpdate,
          [action.data.type as string]: {
            count: Math.max(action.data.count, 0),
          },
        },
      };

      return {
        ...state,
        selectedAdviserConnect: newAdviserConnectWithPolicyScans,
      };

    case Types.EMAIL_NOTIFICATION:
      return {
        ...state,
        emailNotification: action.data,
      };
    case OpsPolicyRenewalTypes.OPERATION_POLICY_RNEWAL_DETAILS_LOADED:
      return {
        ...state,
        policyRenewalDetails: action.data,
      };
    case OpsPolicyRenewalTypes.OPERATION_UPDATE_POLICY_RENEWAL:
      return {
        ...state,
        policyRenewalDetails: updatePolicyRenewal(
          state.policyRenewalDetails,
          action.data,
        ),
      };
    case Types.MARKET_SCAN_FEEDBACKS_LOADED:
      return {
        ...state,
        marketScanFeedbacks: action.data,
        loading: false,
      };
    case Types.POLICY_SCAN_PAYLOAD_LOADED:
      return {
        ...state,
        policyScanPayload: action.data,
      };
    default:
      return state;
  }
};

const updatePolicyRenewal = (current, newData) => {
  const { policy, policyRenewal } = current;
  return {
    policy,
    policyRenewal: {
      ...policyRenewal,
      newPolicyData: {
        ...policyRenewal.newPolicyData,
        ...newData,
      },
    },
  };
};

const groupBySk = groupBy(prop('sk'));

/**
 * Helper function to selectJoinedFeedbacks which takes a list of feedback objects
 * with equal sk's and combines the attributes to return one feedback object
 * @param feedbacks - A list of feedbacks with the same SK
 * @returns A concatinated feedback item
 */
const mergeFeedbacks = (feedbacks: IFeedback[]) => {
  return feedbacks.reduce((final, item) => {
    return {
      ...final,
      ...item,
      feedbackType: '-',
    };
  }, {});
};

/**
 * Takes a list of feedbacks and joins ones with the same sk into one object
 * @param feedbacks - List of feedbacks to be joined by sk
 * @returns A joined list of feedbacks by sk
 */
export const selectJoinedFeedbacks = (feedbacks: IFeedback[]) => {
  const groupedFeedbacks = values(groupBySk(feedbacks));
  const joinedFeedbacks = groupedFeedbacks.map((item: IFeedback[]) =>
    mergeFeedbacks(item),
  );
  return joinedFeedbacks;
};

export default operationsReducer;
