import React, { useMemo } from 'react';
import { useAuth0 } from '../../../react-auth0-spa';
import {
  INSURANCE_TYPES_NAMES,
  INSURANCE_QUESTIONS_BY_TYPE,
  INSURANCE_QUESTIONS_COMMON,
  INSURANCE_QUESTIONS_DATES,
  TRAVEL_INSURANCE_QUESTIONS_DATES,
  INSURANCE_QUESTIONS_PAYMENTS,
  INSURANCE_SUB_TYPE_QUESTION,
  INSURANCE_QUESTIONS_OPTIONAL_BY_NAME,
} from 'constants/insurance';
import { FREQUENCY_OPTIONS, TRAVEL_FREQUENCY_OPTIONS } from 'constants/options';
import { useInsuranceProviders } from 'hooks/insuranceProviders';
import { usePolicySubTypes } from 'hooks/usePolicySubTypes';
import styles from '../styles/manual.scss';
import { Common as Page } from './Common';
import { LifeCovers, OptionalLifePage } from './Life';
import { IPolicy, IPolicyType } from 'types';
import { IUseForm } from 'hooks/useForm';
import { IUsePager } from 'hooks/usePager';
import { useSelector } from 'react-redux';
import { selectFieldOptions } from 'selectors';

interface IManualWizardProps {
  textMap?: object;
  policy: IPolicy;
  policyType: any;
  pager: IUsePager;
  fieldLabels?: object;
  bottomLabels?: object;
  questionProps?: object;
  policyTypes: IPolicyType[];
  disableSubmit: boolean;
  form: IUseForm;
  onBack?(): void;
}

const InsuranceDetailsPage = ({
  next,
  policyType: { id, name },
  values: form,
  onInputChange,
  onDropdownIdNameChange,
  onMultipleInputChange,
  page,
  title,
  onBack,
}) => {
  const { insuranceProviders } = useInsuranceProviders(id);
  const { subTypes } = usePolicySubTypes(form.policyProviderId, name);

  const questions = useMemo(() => {
    const q = [
      ...INSURANCE_QUESTIONS_BY_TYPE[name],
      ...INSURANCE_QUESTIONS_COMMON,
    ];
    const isSubTypeRequired = R.includes(
      name,
      INSURANCE_SUB_TYPE_QUESTION.requiredFor,
    );
    return subTypes.length > 0 || isSubTypeRequired
      ? R.insert(
          2,
          R.assoc('required', isSubTypeRequired, INSURANCE_SUB_TYPE_QUESTION),
          q,
        )
      : q;
  }, [name, subTypes.length]);

  const pageTitle = title || `Let’s find out more about your ${name} insurance`;

  return (
    <Page
      page={page}
      questions={questions}
      title={pageTitle}
      subtitle="Please provide the following details"
      form={form}
      onInputChange={onInputChange}
      onMultipleInputChange={onMultipleInputChange}
      onSubmit={next}
      options={{ policyProvider: insuranceProviders, policySubType: subTypes }}
      loading={!insuranceProviders.length}
      onDropdownChange={onDropdownIdNameChange}
      onBack={onBack}
    />
  );
};

const PolicyDatesPage = ({
  next,
  values: form,
  onInputChange,
  policyType,
  fieldLabels,
  bottomLabels,
  questionProps,
  page,
  onBack,
}) => {
  const questions = useMemo(() => {
    if (policyType.name === INSURANCE_TYPES_NAMES.TRAVEL) {
      return TRAVEL_INSURANCE_QUESTIONS_DATES.map((f) => {
        if (f.fieldKey === 'renewalDate' && form.startDate) {
          return R.assoc('min', new Date(form.startDate), f);
        } else if (f.fieldKey === 'startDate' && form.renewalDate) {
          return R.assoc('max', new Date(form.renewalDate), f);
        }
        return f;
      });
    }
    return INSURANCE_QUESTIONS_DATES;
  }, [form.startDate, form.renewalDate, policyType]);

  return (
    <Page
      page={page}
      questions={questions}
      title="Please provide the policy dates"
      subtitle="Please provide the following details"
      form={form}
      onInputChange={onInputChange}
      onSubmit={next}
      fieldLabels={fieldLabels}
      bottomLabels={bottomLabels}
      questionProps={questionProps}
      onBack={onBack}
    />
  );
};

const InsurancePaymentsPage = ({
  next,
  values: form,
  onInputChange,
  policyType,
  fieldLabels,
  bottomLabels,
  page,
  title = 'Tell us about your insurance payments',
  onDropdownChange,
  onBack,
}) => {
  const options = {
    frequency:
      policyType.name === 'Travel'
        ? TRAVEL_FREQUENCY_OPTIONS
        : FREQUENCY_OPTIONS,
  };

  return (
    <Page
      page={page}
      questions={INSURANCE_QUESTIONS_PAYMENTS}
      title={title}
      subtitle="Please provide the following details"
      form={form}
      onInputChange={onInputChange}
      onSubmit={next}
      options={options}
      onDropdownChange={onDropdownChange}
      fieldLabels={fieldLabels}
      bottomLabels={bottomLabels}
      onBack={onBack}
    />
  );
};

const AdditionalInfoPage = ({
  values: form,
  onInputChange,
  onDropdownChange,
  onSubmit,
  policyType,
  disableSubmit,
  page,
  onBack,
}) => {
  const { hasRole } = useAuth0() as any;

  const questions =
    INSURANCE_QUESTIONS_OPTIONAL_BY_NAME[policyType.name] ||
    INSURANCE_QUESTIONS_OPTIONAL_BY_NAME.default;

  const options = useSelector(selectFieldOptions(policyType.name));

  return (
    <Page
      page={page}
      questions={
        hasRole('admin')
          ? [
              ...questions,
              {
                fieldKey: 'userId',
                placeholder: 'UserId',
                fullWidth: true,
              },
            ]
          : questions
      }
      title={`Let’s find out more about your ${policyType.name} insurance`}
      subtitle="Optional"
      form={form}
      onInputChange={onInputChange}
      onDropdownChange={onDropdownChange}
      onSubmit={onSubmit}
      buttonText="Finish"
      inputClassName={styles.additionalInfoInputs}
      subtitleClassName={styles.additionalInfoSubtitle}
      disableSubmit={disableSubmit}
      options={options}
      onBack={onBack}
    />
  );
};

const lifePageMap = {
  0: InsuranceDetailsPage,
  1: LifeCovers,
  2: PolicyDatesPage,
  3: InsurancePaymentsPage,
  4: OptionalLifePage,
};

const defaultPageMap = {
  0: InsuranceDetailsPage,
  1: PolicyDatesPage,
  2: InsurancePaymentsPage,
  3: AdditionalInfoPage,
};

export default (props: IManualWizardProps) => {
  const { textMap, policyType, pager, form } = props;

  const pageMap =
    policyType.name === INSURANCE_TYPES_NAMES.LIFE
      ? lifePageMap
      : defaultPageMap;

  const title = R.prop(`step${pager.active + 1}Title`, textMap);

  const Component = pageMap[pager.active];

  return (
    <Component
      {...props}
      {...pager}
      onSubmit={form.handleSubmit}
      values={form.values}
      onInputChange={form.handleInputChange}
      onDropdownChange={form.handleDropdownChange}
      onDropdownIdNameChange={form.handleDropdownIdNameChange}
      onMultipleInputChange={form.handleMultipleInputChange}
      setFormValue={form.setValues}
      title={title}
    />
  );
};
