// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".Fld5tZKBUTP-9sg64RumB{display:flex;flex-wrap:wrap;justify-content:center;margin-right:-8px;margin-bottom:-8px;background-color:#fff;max-width:774px}._2NVkVffP0PmrsXGpZPj3lU{flex:1;display:flex;justify-content:center;align-items:center;color:#34363e;margin-right:8px;margin-bottom:8px;min-width:250px;padding:16px;background-color:#f7f8fc;border-radius:5px;cursor:pointer}._2NVkVffP0PmrsXGpZPj3lU svg{height:20px;width:auto;max-width:24px;min-width:24px;margin-right:16px;flex:1}._2NVkVffP0PmrsXGpZPj3lU:hover{background-color:#edf1ff}@media(max-width: 820px){._2NVkVffP0PmrsXGpZPj3lU{min-width:calc(50% - 8px)}._2NVkVffP0PmrsXGpZPj3lU span{flex-basis:0}}@media(max-width: 375px){._2NVkVffP0PmrsXGpZPj3lU{min-width:100%}}", ""]);
// Exports
exports.locals = {
	"container": "Fld5tZKBUTP-9sg64RumB",
	"item": "_2NVkVffP0PmrsXGpZPj3lU"
};
module.exports = exports;
