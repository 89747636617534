import axios from 'axios';
import { getHeaderOptions, getHttpService } from './http-service';
import { getClaimsToken, removeEmptyValues } from '../helpers';
import { IUser } from 'types';

declare const AUTH0_DOMAIN: string;
declare const AUTH0_CLIENT_ID: string;

const USER_PROPS_TO_IGNORE = [
  'quotes',
  'currentFamilyId',
  'advisers',
  'referrals',
  'subscription',
  'subscriptionStatus',
];

const httpClient = getHttpService();

// if the userId not found in db, a new user will be created.
export const getUserById = (userId?: string, email?: string) => {
  const claims = getClaimsToken();
  const id = userId || `user:${claims.sub}`;
  return httpClient.post(
    `/user/get`,
    { id, email },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const searchUserByEmail = (email: string) => {
  return httpClient.post(
    `/user/email`,
    { email },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const deleteUserFromFamily = (userId: string, familyId: string) => {
  return httpClient.delete(
    `/user/${encodeURIComponent(userId)}/family/${encodeURIComponent(
      familyId,
    )}`,
    {
      headers: getHeaderOptions(),
    },
  );
};

export const updateUser = (data?: IUser) => {
  const updated = R.omit(USER_PROPS_TO_IGNORE, data);
  const cleaned = removeEmptyValues(updated);
  return httpClient.put(`/user/${encodeURIComponent(data.id)}`, cleaned, {
    headers: getHeaderOptions(),
  });
};

export const updateUserProfilePhoto = (key: string) => {
  return httpClient.put(
    `/user/profilePhoto`,
    { key },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getUsers = (days: string) => {
  return httpClient.get(`/user`, {
    headers: getHeaderOptions(),
    params: {
      days: days,
    },
  });
};

export const notifyUser = (
  userId: string,
  templateName: string,
  adviserClientId?: string,
  extraParams?: { [key: string]: string },
) => {
  let data = { templateName, adviserClientId };
  if (extraParams) {
    data = {
      ...data,
      ...extraParams,
    };
  }
  return httpClient.post(`/user/${encodeURIComponent(userId)}/notify`, data, {
    headers: getHeaderOptions(),
  });
};

export const getUserNotifications = (userId: string) => {
  return httpClient.get(`/user/${encodeURIComponent(userId)}/notify`, {
    headers: getHeaderOptions(),
  });
};

export const deleteUserById = (id: string) => {
  return httpClient.delete(`/user/${encodeURIComponent(id)}`, {
    headers: getHeaderOptions(),
  });
};

export const triggerPasswordReset = (email: string) => {
  return axios.post(
    `https://${AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      client_id: AUTH0_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: getHeaderOptions(),
    },
  );
};
