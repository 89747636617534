import React, { useEffect } from 'react';
import Button from 'components/button';
import Uploader from 'components/uploader';
import styles from './styles/upload.scss';
import { useUploader } from 'hooks/useUploader';
import { UploaderPreviews } from 'components/uploader';
import { UPLOAD_MAX_SIZE_EXCEEDED } from 'constants/errors';
import { Back } from 'components/quashed';
import { Upload } from 'react-feather';
import { IAttachment } from 'types';

interface IUploadProps {
  policyError?: string;
  backText?: string;
  className?: string;
  errorClassName?: string;
  placeholderClassName?: string;
  uploaderClassName?: string;
  uploadContainerClassName?: string;
  defaultAttachments?: any;
  isUploading?: boolean;
  showSubmit?: boolean;
  submitText?: string;
  textMap?: object;
  onBack(): void;
  onPreviewClick(att: IAttachment): void;
  onSubmit(attachments): void;
  onSkip?(): void;
}

const UploadPage = ({
  onBack,
  onSubmit,
  onPreviewClick,
  onSkip,
  backText = 'Previous',
  className,
  errorClassName,
  placeholderClassName,
  uploaderClassName,
  uploadContainerClassName,
  isUploading,
  policyError,
  textMap,
  defaultAttachments,
  showSubmit,
  submitText = 'Upload',
}: IUploadProps) => {
  const {
    attachments,
    rejected,
    uploaderRef,
    onDrop,
    removeAttachment,
    onSubmit: handleSubmit,
    openUploader,
    popupProps,
    onPopupAction,
    setAttachments,
  } = useUploader(onSubmit, defaultAttachments);

  useEffect(() => {
    if (defaultAttachments) {
      setAttachments(defaultAttachments);
    }
  }, [defaultAttachments]);

  const error =
    policyError || (rejected.length > 0 && UPLOAD_MAX_SIZE_EXCEEDED);

  const hasAttachments = attachments.length > 0;

  const showSubmitBtn = hasAttachments || showSubmit;

  return (
    <div className={classnames(styles.container, className)}>
      {error && (
        <div className={classnames(styles.errorMessage, errorClassName)}>
          {error}
        </div>
      )}
      <div
        className={classnames(
          styles.uploadContainer,
          uploadContainerClassName,
          hasAttachments && styles.withAttachments,
        )}
      >
        <Uploader
          ref={uploaderRef}
          onDrop={onDrop}
          className={classnames(styles.uploader, uploaderClassName)}
          placeholderClassName={placeholderClassName}
          isUploading={isUploading}
          placeholder={R.prop('upload', textMap)}
          popupProps={popupProps}
          onPopupAction={onPopupAction}
        >
          {hasAttachments && (
            <>
              <UploaderPreviews
                className={styles.preview}
                attachments={attachments}
                onClick={onPreviewClick}
                removeAttachment={removeAttachment}
              />
              <div className={styles.attachmentBtns}>
                <Button
                  className={styles.uploadBtn}
                  text={
                    <>
                      <Upload size={20} />
                      Choose file
                    </>
                  }
                  onClick={openUploader}
                  disabled={isUploading}
                />
                <div className={styles.uploadText}>
                  or <b>drag and drop</b>
                </div>
              </div>
            </>
          )}
        </Uploader>
      </div>
      <div className={styles.btnContainer}>
        {onBack && (
          <Back
            className={classnames(styles.back, showSubmitBtn && styles.margin)}
            text={backText}
            onClick={onBack}
          />
        )}
        {showSubmitBtn && (
          <Button
            className={styles.blueBtn}
            text={hasAttachments ? submitText : 'Skip'}
            onClick={hasAttachments ? handleSubmit : onSkip || handleSubmit}
            disabled={isUploading}
          />
        )}
      </div>
    </div>
  );
};

export default UploadPage;
