import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Plus, X } from 'react-feather';
import { Button } from 'components/quashed';
import CartCheckSvg from '-!svg-react-loader!icons/shared/cart-check.svg';
import CheckSvg from '-!svg-react-loader!icons/shared/check-circle.svg';
import styles from './styles/ShoppingCartPopup.module.scss';
import ShoppingCartList from './ShoppingCartList';
import { CHECKOUT_ROUTE, PREMIUM_ROUTE } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsAllAdviserScansInCheckout,
  selectPopupCartItem,
} from 'selectors/adviserConnectSelectors';
import { AddToCartLoaded } from 'actions';

const ShoppingCartPopup = () => {
  const dispatch = useDispatch();
  const item = useSelector(selectPopupCartItem);
  const isAllInCheckout = useSelector(selectIsAllAdviserScansInCheckout);

  const handleClose = () => dispatch(AddToCartLoaded(null));

  return (
    <Modal
      className={styles.modal}
      dimmer={{ className: styles.dimmer }}
      onClose={handleClose}
      open={!!item}
    >
      <Modal.Content>
        <div className={styles.header}>
          <CheckSvg className={styles.checkIcon} />
          <h3>Added to cart</h3>
          <X className={styles.closeIcon} onClick={handleClose} />
        </div>
        <ShoppingCartList items={item ? [item] : []} />
        {!isAllInCheckout && (
          <Button
            className={styles.btnWhite}
            onClick={handleClose}
            link={PREMIUM_ROUTE}
          >
            <Plus />
            Add more to cart
          </Button>
        )}
        <Button
          className={styles.btnBlue}
          onClick={handleClose}
          link={CHECKOUT_ROUTE}
        >
          <CartCheckSvg className={styles.btnCheckCart} />
          Continue to checkout
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default ShoppingCartPopup;
