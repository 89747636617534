import React from 'react';
import styles from './styles/NewFeatureModal.module.scss';
import Button from 'components/button';
import { Modal } from 'semantic-ui-react';
import { X } from 'react-feather';
import { IGlobalNotificationModalInfo } from 'types';

interface NewFeatureProps {
  globalModalInfo: IGlobalNotificationModalInfo;
  setGlobalModalInfo: React.Dispatch<
    React.SetStateAction<IGlobalNotificationModalInfo>
  >;
}

const NewFeatureModal = ({
  globalModalInfo,
  setGlobalModalInfo,
}: NewFeatureProps) => {
  const handleClose = () => {
    setGlobalModalInfo({ ...globalModalInfo, show: false });
  };
  return (
    <Modal
      className={styles.modal}
      dimmer={{ className: styles.dimmer }}
      closeIcon={<X className={styles.close} size={20} />}
      onClose={handleClose}
      open={globalModalInfo?.show || false}
      closeOnDimmerClick={true}
    >
      <Modal.Content>
        <div className={styles.container}>
          <div className={styles.newTag}>New</div>
          <div className={styles.title}>{globalModalInfo?.modalInfo.title}</div>
          <img src={globalModalInfo?.modalInfo.imgUrl} className={styles.img} />
          <div className={styles.description}>
            {globalModalInfo?.modalInfo.description}
          </div>

          <Button
            className={styles.button}
            text={globalModalInfo?.modalInfo.actionText || 'Ok'}
            link={globalModalInfo?.modalInfo.actionUrl}
            onClick={handleClose}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default NewFeatureModal;
