// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1amhKmitaCuv-5x5Gz9wBQ{display:flex;align-items:center;justify-content:center;font-size:12px;color:#34363e}._1amhKmitaCuv-5x5Gz9wBQ span{font-family:\"Open Sans\";font-weight:700}._1amhKmitaCuv-5x5Gz9wBQ span:first-child{margin-right:16px}._1amhKmitaCuv-5x5Gz9wBQ span:last-child{margin-left:16px}.LlIDlBNOkjJAqvfKxb2qA{flex:1}.LlIDlBNOkjJAqvfKxb2qA.ui.progress{margin:0;max-width:350px}.LlIDlBNOkjJAqvfKxb2qA.ui.progress .bar{background-color:#4187f5;min-width:10px;height:10px}@media(max-width: 820px){.LlIDlBNOkjJAqvfKxb2qA.ui.progress{max-width:none}}", ""]);
// Exports
exports.locals = {
	"progress": "_1amhKmitaCuv-5x5Gz9wBQ",
	"progressBar": "LlIDlBNOkjJAqvfKxb2qA"
};
module.exports = exports;
