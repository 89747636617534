// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2XAmJEYNZpzE4JSoGsd2Vr{color:#34363e}.n6679TJLwkdJ50U2JsDEo{background-color:#fff;padding:24px}.n6679TJLwkdJ50U2JsDEo h2{font-family:\"Open Sans\";font-weight:700;margin-bottom:0;margin-right:8px}.n6679TJLwkdJ50U2JsDEo h2+div{padding-top:8px}._32FRJdPyyUiCEyl9YPCpXY{display:none}._31fTSXapgsqwjkhVds_swF{background-color:rgba(0,0,0,0) !important;padding:0 !important;border:none !important}._2lzkJPqm73cumzqOSiGfKw{text-align:left}._2nGFcIC_9yHZHDW6O7pnih{font-family:\"Open Sans\";font-weight:400;font-size:14px;font-weight:600;max-width:420px;display:flex;align-items:center}._12idydhPGRx-wPiijAtDAO{min-height:0 !important}._3DgcxjCwZpqjpVz1sdV_nS{display:flex}._3DgcxjCwZpqjpVz1sdV_nS>div{flex:1}._39s0X6fl46CoMAJ3Kb01yi{justify-content:flex-start;margin:8px 0 16px}._39s0X6fl46CoMAJ3Kb01yi .ui.progress .bar{background-color:#3d67ab}._2HFo1BRPwsnzD7oR4aOHDx{font-size:12px;color:#34363e;font-weight:300;line-height:1.4;margin-bottom:16px}._2iBACohYT-UKmtaw-FSVL9.checkbox{justify-content:flex-start;align-items:flex-start}._2iBACohYT-UKmtaw-FSVL9.checkbox .errorIcon+.ui.checkbox{padding-left:0;margin-left:16px}._2iBACohYT-UKmtaw-FSVL9.checkbox label{max-width:100% !important;line-height:1.2;order:1;margin:0}._2iBACohYT-UKmtaw-FSVL9.checkbox label>span>span+div{font-size:12px;color:#34363e;font-weight:300;line-height:1.4}._2iBACohYT-UKmtaw-FSVL9.checkbox label+div{flex:0;margin-right:24px}._2iBACohYT-UKmtaw-FSVL9.checkbox .ui.checkbox label:before{background-color:#f7f7f7 !important;border:1px solid #787777}._2iBACohYT-UKmtaw-FSVL9.checkbox .ui.checkbox label:after{color:#3d67ab !important}@media(max-width: 820px){._2iBACohYT-UKmtaw-FSVL9.checkbox{flex-direction:row}._2iBACohYT-UKmtaw-FSVL9.checkbox label{padding-left:16px}}", ""]);
// Exports
exports.locals = {
	"container": "_2XAmJEYNZpzE4JSoGsd2Vr",
	"page": "n6679TJLwkdJ50U2JsDEo",
	"header": "_32FRJdPyyUiCEyl9YPCpXY",
	"question": "_31fTSXapgsqwjkhVds_swF",
	"titleContainer": "_2lzkJPqm73cumzqOSiGfKw",
	"title": "_2nGFcIC_9yHZHDW6O7pnih",
	"content": "_12idydhPGRx-wPiijAtDAO",
	"contentWrap": "_3DgcxjCwZpqjpVz1sdV_nS",
	"progress": "_39s0X6fl46CoMAJ3Kb01yi",
	"subtitle": "_2HFo1BRPwsnzD7oR4aOHDx",
	"field": "_2iBACohYT-UKmtaw-FSVL9"
};
module.exports = exports;
