// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3NybZFH-OOgr0AllHuafiM{position:absolute;border-radius:5px;background-color:#fff;width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;z-index:5;left:0}._12ildXefOKRUj-RAp6wWQH.ui.inverted.dimmer{background-color:rgba(0,0,0,0);position:relative;height:auto;margin-top:24px}._12ildXefOKRUj-RAp6wWQH .ui.loader:before,._12ildXefOKRUj-RAp6wWQH .ui.loader:after{border-width:4px}._12ildXefOKRUj-RAp6wWQH .ui.loader:after{border-color:#4187f5 rgba(0,0,0,0) rgba(0,0,0,0) !important}", ""]);
// Exports
exports.locals = {
	"loader": "_3NybZFH-OOgr0AllHuafiM",
	"spinner": "_12ildXefOKRUj-RAp6wWQH"
};
module.exports = exports;
