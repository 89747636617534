import React from 'react';
import DOMPurify from 'dompurify';
import { getQuestionsToDisplay } from 'helpers';
import { IFormQuestion } from 'types';
import FormEngineController from '../components/FormEngineController';
import styles from '../FormEngine.module.scss';
import {
  getChangeHandler,
  getFieldComponent,
  getSelectHandler,
  getInfoWarningText,
} from '../utils';
import { getValidator } from '../validation';
import Back from 'components/back';
import Button from 'components/button';
import { useSelector } from 'react-redux';

const renderQuestionsByType = (questionElements, questions, type) => {
  if (type === 'typeform') {
    const split = questionElements.reduce((acc, el, idx) => {
      const q = questions?.[idx];
      const isOwnRow = R.includes('fullWidth', q?.className || '');

      if (isOwnRow) {
        acc.push([el, null]);
        return acc;
      }

      const lastGrp = R.last(acc);
      if (lastGrp?.length < 2) {
        acc[acc.length - 1] = [...lastGrp, el];
      } else {
        acc.push([el]);
      }

      return acc;
    }, []);

    return split.map((grp, idx) => (
      <div
        key={idx}
        className={classnames(
          styles.row,
          R.reject(R.isNil, grp).length === 1 && styles.fullWidth,
        )}
      >
        {grp}
      </div>
    ));
  }

  return questionElements;
};

const PageTitle = ({ pageTitle, titleClassName, titleImage }) => {
  if (Array.isArray(pageTitle)) {
    return (
      <>
        {pageTitle.map((pt, idx) => (
          <div
            key={`title_${idx}`}
            className={classnames(styles.titleList, titleClassName)}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pt) }}
          />
        ))}
      </>
    );
  }

  return (
    <div className={classnames(styles.title, titleClassName)}>
      {titleImage}
      {pageTitle}
    </div>
  );
};

const FormEnginePage = ({
  active,
  containerClassName,
  contentClassName,
  questionsClassName,
  fieldClassName,
  fieldContainerClassName,
  inputClassName,
  labelClassName,
  titleClassName,
  titleImage,
  options,
  form,
  page,
  formDefinition,
  formValues,
  loader,
  loading,
  onBack,
  onButtonClick,
  renderLabel,
  getOptions,
  questionProps,
  ...props
}) => {
  const config = useSelector(R.prop('config'));
  const { control, formState } = form;
  const { imageUrl, questions, text, title } = formDefinition;
  const pageText = page?.text ?? text;
  const pageSubmitText = page?.submitText ?? 'Next';
  const pageQuestionsClassName = page?.questionsClassName;
  const pageImage = page?.imageUrl ?? imageUrl;
  const pageTitle = page?.title ?? title;
  const pageLayout = page?.layout;
  const pageQuestions = getQuestionsToDisplay(
    page?.questions ?? questions,
    formValues,
    undefined,
    questionProps,
  );
  const hideBack = page?.hideBack;
  const buttonDisclaimer = page?.buttonDisclaimer;

  const toggleFullAddress = () => {
    form.setValue('showFullAddress', !formValues.showFullAddress);
  };

  const errorKeys = Object.keys(formState.errors);

  const questionElements = pageQuestions?.reduce(
    (acc, question: IFormQuestion) => {
      const { key, column, type, required, className: qClassName } = question;
      const FieldComponent = getFieldComponent(type);
      const validate = getValidator(type, question, formValues);
      const error = formState.errors[key]?.message || formState.errors[key];
      const handleButtonClick = () => {
        if (onButtonClick) {
          onButtonClick(question, formValues, form);
        }
      };

      const component = (
        <FormEngineController
          key={key}
          control={control}
          name={key}
          rules={{ required, validate }}
          render={({ field }) => (
            <FieldComponent
              {...question}
              {...field}
              label={
                renderLabel ? renderLabel(question, formValues) : question.label
              }
              scrollIntoView={errorKeys[0] === key} // Scroll only to the first error
              formValues={formValues}
              onChange={getChangeHandler(field, form, props, question)}
              onSelect={getSelectHandler(field, form, config, props)}
              onButtonClick={handleButtonClick}
              toggleAddress={toggleFullAddress}
              options={
                (getOptions && getOptions(question, formValues)) ||
                options?.[key] ||
                question.options
              }
              error={error}
              warning={getInfoWarningText(question.warning, formValues)}
              info={getInfoWarningText(question.info, formValues)}
              containerClassName={classnames(
                styles.fieldContainer,
                fieldContainerClassName,
              )}
              className={classnames(
                styles.field,
                styles[type],
                qClassName,
                fieldClassName,
              )}
              inputClassName={classnames(styles.input, inputClassName)}
              labelClassName={classnames(styles.fieldLabel, labelClassName)}
            />
          )}
        />
      );

      if (pageLayout === 'column') {
        const colIdx = column - 1;
        if (!acc[colIdx]) {
          acc[colIdx] = [];
        }
        acc[colIdx].push(component);
      } else {
        acc.push(component);
      }

      return acc;
    },
    [],
  );

  if (pageLayout === 'column') {
    return (
      <div className={classnames(styles.columnContainer, containerClassName)}>
        {loader}
        {questionElements.map((questionComponents, idx) => {
          return (
            <div
              key={`col_${idx}`}
              className={classnames(
                styles.content,
                pageTitle && Array.isArray(pageTitle) && styles.contentLarge,
                contentClassName,
              )}
            >
              {pageTitle && idx === 0 && (
                <PageTitle
                  pageTitle={pageTitle}
                  titleClassName={titleClassName}
                  titleImage={titleImage}
                />
              )}
              <div
                className={classnames(
                  styles.questions,
                  questionsClassName,
                  pageQuestionsClassName,
                )}
              >
                {pageImage && (
                  <div className={styles.img}>
                    <img src={pageImage} />
                  </div>
                )}
                {pageText && (
                  <div className={styles.pageText}>
                    {pageText.map((t) => (
                      <div key={t}>{t}</div>
                    ))}
                  </div>
                )}
                {renderQuestionsByType(
                  questionComponents,
                  pageQuestions,
                  page?.type,
                )}
              </div>
              {idx === questionElements.length - 1 && (
                <div className={styles.columnBtns}>
                  {!hideBack && (
                    <Back className={styles.columnBack} handleBack={onBack} />
                  )}
                  <Button
                    className={styles.columnSubmit}
                    type="submit"
                    disabled={loading || errorKeys.length > 0}
                    loading={loading}
                    text={pageSubmitText}
                  />
                  {buttonDisclaimer && (
                    <div
                      className={styles.buttonDisclaimer}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(buttonDisclaimer, {
                          ADD_ATTR: ['target', 'rel'],
                        }),
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={classnames(
        styles.content,
        pageTitle && Array.isArray(pageTitle) && styles.contentLarge,
        contentClassName,
      )}
    >
      {pageTitle && (
        <PageTitle
          pageTitle={pageTitle}
          titleClassName={titleClassName}
          titleImage={titleImage}
        />
      )}
      <div
        className={classnames(
          styles.questions,
          questionsClassName,
          pageQuestionsClassName,
        )}
      >
        {loader}
        {pageImage && (
          <div className={styles.img}>
            <img src={pageImage} />
          </div>
        )}
        {pageText && (
          <div className={styles.pageText}>
            {pageText.map((t) => (
              <div key={t}>{t}</div>
            ))}
          </div>
        )}
        {renderQuestionsByType(questionElements, pageQuestions, page?.type)}
      </div>
    </div>
  );
};

export default FormEnginePage;
