import { IAdmin, IPolicyType, IPolicySubType, IPolicy } from '../../types';
import { AppActionTypes, Types } from '../actions';
import { Types as OpsActionTypes } from '../actions/operation-actions';
import * as R from 'ramda';
import {
  arrayOrderByDateKey,
  updateUserPolicies,
  updateUserPoliciesFieldKey,
} from 'helpers';

const Reducer = (state: IAdmin, action: AppActionTypes): IAdmin => {
  switch (action.type) {
    case Types.ADMIN_LOADING:
    case Types.UPDATE_POLICY_PROVIDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_POLICY_PROVIDER_LOADED:
      const updatedProvider = {
        ...action.data,
        policyTypes: state.selectedPolicyProvider.policyTypes,
      };
      return {
        ...state,
        loading: false,
        selectedPolicyProvider: updatedProvider,
      };
    case Types.GET_USERS_LOADED:
      return {
        ...state,
        loading: false,
        users: arrayOrderByDateKey('data', action.data, true),
      };
    case Types.SELECT_POLICY_PROVIDER:
      const provider = action.data.policyProviders.find(
        (p) => p.id === action.data.id,
      );
      return {
        ...state,
        selectedPolicyProvider: { ...provider, policyTypes: [] },
      };
    case Types.SELECT_USER:
      const user = action.data.users.find((p) => p.id === action.data.id);
      if (user) {
        return {
          ...state,
          selectedUser: { ...user, policies: [] },
        };
      } else {
        return {
          ...state,
          selectedUser: undefined,
        };
      }
    case Types.ADMIN_GET_USER_BY_ID_LOADED:
      const ownedFamily = action.data.families.find((f) => f.familyOwner);
      return {
        ...state,
        selectedUser: {
          ...action.data,
          currentFamilyId: R.propOr('', 'id', ownedFamily),
        },
      };
    case OpsActionTypes.SELECTED_USER_ADVISER_CONNECTS_LOADED:
      const selectedUser = {
        ...state.selectedUser,
        adviserUserConnects: action.data,
      };
      return {
        ...state,
        selectedUser,
      };
    case Types.SELECT_POLICY_TYPE:
      const policyType = action.data.policyTypes.find(
        (p) => p.id === action.data.id,
      );
      return {
        ...state,
        selectedPolicyType: { ...policyType, policySubTypes: [] },
      };
    case Types.SELECT_POLICY_SUBTYPE:
      const policySubType = action.data.policySubtypes.find(
        (p) => p.id === action.data.id,
      );
      return {
        ...state,
        selectedPolicySubtype: { ...policySubType },
      };
    case Types.UPDATE_SELECTED_POLICY_SUBTYPE:
      return {
        ...state,
        selectedPolicySubtype: { ...action.data },
      };

    case Types.CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED:
      const { selectedPolicyProvider } = state;
      return {
        ...state,
        selectedPolicyProvider: {
          ...selectedPolicyProvider,
          policyTypes: [
            ...selectedPolicyProvider.policyTypes,
            action.data as IPolicyType,
          ],
        },
      };
    case Types.DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADED:
      const selectedProvider = state.selectedPolicyProvider;
      const newTypes = R.reject((t: IPolicyType) => {
        return t.sk === action.data;
      }, selectedProvider.policyTypes);

      return {
        ...state,
        selectedPolicyProvider: {
          ...selectedProvider,
          policyTypes: newTypes,
        },
      };
    case Types.DELETE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED:
      const selectedType = state.selectedPolicyType;
      const newSubTypes = R.reject((t: IPolicySubType) => {
        return t.sk === action.data;
      }, selectedType.policySubTypes);

      return {
        ...state,
        selectedPolicyType: {
          ...selectedType,
          policySubTypes: newSubTypes,
        },
      };
    case Types.ADMIN_GET_USER_POLICIES:
      const newUser = {
        ...state.selectedUser,
        policies: action.data as IPolicy[],
      };
      return { ...state, selectedUser: newUser };
    case OpsActionTypes.USER_BUNDLES_LOADED:
      const userWithLoadedBundles = {
        ...state.selectedUser,
        bundles: action.data,
      };
      return { ...state, selectedUser: userWithLoadedBundles };
    case Types.GET_ATTACHMENT_FOR_POLICY_LOADED:
      return {
        ...state,
        selectedUser: updateUserPolicies(
          state,
          action,
          updateUserPoliciesFieldKey.attachments,
        ),
      };
    case Types.GET_RENEWAL_FOR_POLICY_LOADED:
      return {
        ...state,
        selectedUser: updateUserPolicies(
          state,
          action,
          updateUserPoliciesFieldKey.renewals,
        ),
      };
    case OpsActionTypes.ADMIN_UPDATE_POLICY_LOADED:
      const updatedUser = {
        ...state.selectedUser,
        policies: selectPoliciesFromUser(state, action.data),
      };
      return { ...state, selectedUser: updatedUser };
    case Types.GET_POLICY_TYPES_BY_PROVIDER_LOADED:
      const newProvider = {
        ...state.selectedPolicyProvider,
        policyTypes: action.data as IPolicyType[],
      };
      return { ...state, selectedPolicyProvider: newProvider };

    case Types.GET_POLICY_SUB_TYPES_BY_POLICY_TYPE_LOADED:
      const newType = {
        ...state.selectedPolicyType,
        policySubTypes: action.data as IPolicySubType[],
      };
      return { ...state, selectedPolicyType: newType };

    case Types.CREATE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED:
      const { selectedPolicyType } = state;
      return {
        ...state,
        selectedPolicyType: {
          ...selectedPolicyType,
          policySubTypes: [
            ...selectedPolicyType.policySubTypes,
            action.data as IPolicySubType,
          ],
        },
      };
    case Types.ADMIN_DELETE_USER_BY_ID_LOADED:
      return {
        ...state,
        users: R.reject(R.propEq('id', action.data), state.users),
      };
    case Types.ADMIN_UPDATE_USER_LOADED:
      return {
        ...state,
        selectedUser: action.data,
      };

    case Types.ADMIN_GET_USER_INVITATIONS_LOADED:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          invitations: action.data,
        },
      };

    case Types.ADMIN_GET_USER_SENT_INVITATIONS_LOADED:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          sentInvitations: action.data,
        },
      };

    case Types.ADMIN_GET_USER_POLICY_SCANS_LOADED:
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          policyScans: action.data,
        },
      };
    default:
      return state;
  }
};

const selectPoliciesFromUser = (state, policy) => {
  const userPolicyToUpdate = state.selectedUser;
  const policies = userPolicyToUpdate.policies || [];
  return policies.map((p) => {
    if (p.id === policy.id) {
      p = {
        ...policy,
      };
    }
    return p;
  });
};

export default Reducer;
