import {
  HOME_BUILD_FIELDS,
  INSURANCE_QUESTIONS_BY_TYPE,
  INSURANCE_TYPES_NAMES,
  MOTORCYCLE_INFO_FIELDS,
  VEHICLE_INFO_FIELDS,
} from './insurance';
import { POLICY_SCAN_STATUS } from '../types';
import { titleOptions } from './options';
import { GENDER_QUESTION } from './typeform-questions';
import {
  ADDRESS_QUESTION,
  ANNUAL_DISTANCE_QUESTION,
  AREA_OF_GARAGING_QUESTION,
  CC_RATING_QUESTION,
  CURRENT_INSURER_QUESTION,
  FINANCE_COMPANY_QUESTION,
  LICENSE_CLASS_QUESTION,
  LICENSE_COUNTRY_QUESTION,
  LICENSE_NUMBER_QUESTION,
  LICENSE_VERSION_QUESTION,
  LICENSE_YEARS_QUESTION,
  MAKE_QUESTION,
  MODEL_QUESTION,
  MOD_DESCRIPTION_QUESTION,
  MOD_VALUE_QUESTION,
  MOTORCYCLE_COVER_TYPE_QUESTION,
  MOTORCYCLE_LICENSE_CLASS_QUESTION,
  MOTORCYCLE_OWNERSHIP_QUESTION,
  MOTORCYCLE_REGO_QUESTION,
  MOTORCYCLE_USE_QUESTION,
  OTHER_PARTY_FINANCE_QUESTION,
  PERCENTAGE_USE_QUESTION,
  PHONE_QUESTION,
  PURCHASE_COVER_TYPE_QUESTION,
  REGO_STATUS_QUESTION,
  SUBMODEL_QUESTION,
  THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION,
  THEFT_INCIDENTS_12MONTHS_QUESTION,
  THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION,
  THEFT_INCIDENTS_5YEARS_QUESTION,
  VEHICLE_PARKING_QUESTION,
  VEHICLE_USE_QUESTION,
  VEHICLE_YEAR_QUESTION,
  VIN_QUESTION,
} from './questions';

export const MARKET_SCAN_FIELDS_TO_IGNORE = ['paymentMethod', 'policyNumber'];

export const MARKET_SCAN_STATUS_OPTIONS = [
  {
    key: POLICY_SCAN_STATUS.READY,
    value: POLICY_SCAN_STATUS.READY,
    text: POLICY_SCAN_STATUS.READY,
  },
  {
    key: POLICY_SCAN_STATUS.PENDING,
    value: POLICY_SCAN_STATUS.PENDING,
    text: POLICY_SCAN_STATUS.PENDING,
  },
  {
    key: POLICY_SCAN_STATUS.VIEWED,
    value: POLICY_SCAN_STATUS.VIEWED,
    text: POLICY_SCAN_STATUS.VIEWED,
  },
  {
    key: POLICY_SCAN_STATUS.ABANDONED,
    value: POLICY_SCAN_STATUS.ABANDONED,
    text: POLICY_SCAN_STATUS.ABANDONED,
  },
  {
    key: POLICY_SCAN_STATUS.IN_REVIEW,
    value: POLICY_SCAN_STATUS.IN_REVIEW,
    text: POLICY_SCAN_STATUS.IN_REVIEW,
  },
  {
    key: POLICY_SCAN_STATUS.REFERRED,
    value: POLICY_SCAN_STATUS.REFERRED,
    text: POLICY_SCAN_STATUS.REFERRED,
  },
];

export const MARKET_SCAN_QUESTIONS_BY_NAME = {
  ...INSURANCE_QUESTIONS_BY_TYPE,
  [INSURANCE_TYPES_NAMES.VEHICLE]: [
    ...INSURANCE_QUESTIONS_BY_TYPE[INSURANCE_TYPES_NAMES.VEHICLE],
    ...VEHICLE_INFO_FIELDS,
    ADDRESS_QUESTION,
  ],
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: [
    ...INSURANCE_QUESTIONS_BY_TYPE[INSURANCE_TYPES_NAMES.MOTORCYCLE],
    ...MOTORCYCLE_INFO_FIELDS,
    ADDRESS_QUESTION,
  ],
  [INSURANCE_TYPES_NAMES.HOME]: [
    ...INSURANCE_QUESTIONS_BY_TYPE[INSURANCE_TYPES_NAMES.HOME],
    ...HOME_BUILD_FIELDS,
  ],
};

export const MARKET_SCAN_POLICIES = [
  INSURANCE_TYPES_NAMES.VEHICLE,
  INSURANCE_TYPES_NAMES.CONTENT,
  INSURANCE_TYPES_NAMES.HOME,
  INSURANCE_TYPES_NAMES.MOTORCYCLE,
  INSURANCE_TYPES_NAMES.LIFE,
  INSURANCE_TYPES_NAMES.PET,
];

export const MARKET_SCAN_POLICIES_BROKER_FIRST = [
  INSURANCE_TYPES_NAMES.HOME,
  INSURANCE_TYPES_NAMES.CONTENT,
  INSURANCE_TYPES_NAMES.VEHICLE,
  INSURANCE_TYPES_NAMES.LANDLORD,
];

export const MARKET_SCAN_POLICIES_SOON = [INSURANCE_TYPES_NAMES.PET];

export const MARKET_SCAN_ADVISER_POLICIES = [INSURANCE_TYPES_NAMES.HEALTH];

const TITLE_QUESTION = {
  line: 1,
  before: 'Title',
  key: 'title',
  order: 1,
  type: 'dropdown',
  options: titleOptions,
};

const DRIVER_QUESTIONS = [
  PERCENTAGE_USE_QUESTION,
  PHONE_QUESTION,
  LICENSE_CLASS_QUESTION,
  LICENSE_YEARS_QUESTION,
  LICENSE_COUNTRY_QUESTION,
  R.merge(THEFT_INCIDENTS_5YEARS_QUESTION, {
    line: 7,
    before: 'Number of theft/at fault incidents (last 5 years)',
  }),
  R.merge(THEFT_INCIDENTS_12MONTHS_QUESTION, {
    line: 8,
    before: 'Number of theft/at fault incidents (last 12 months)',
  }),
  CURRENT_INSURER_QUESTION,
];

const RIDER_QUESTIONS = [
  PERCENTAGE_USE_QUESTION,
  PHONE_QUESTION,
  MOTORCYCLE_LICENSE_CLASS_QUESTION,
  MOTORCYCLE_OWNERSHIP_QUESTION,
  R.merge(LICENSE_COUNTRY_QUESTION, {
    before: 'Rider license country',
  }),
  LICENSE_NUMBER_QUESTION,
  LICENSE_VERSION_QUESTION,
  R.merge(THEFT_INCIDENTS_5YEARS_MOTORCYCLE_QUESTION, {
    line: 9,
    before: 'Number of theft/at fault incidents (last 5 years)',
  }),
  R.merge(THEFT_INCIDENTS_12MONTHS_MOTORCYCLE_QUESTION, {
    line: 10,
    before: 'Number of theft/at fault incidents (last 12 months)',
  }),
  R.merge(CURRENT_INSURER_QUESTION, { line: 11 }),
];

export const MARKET_SCAN_PURCHASE_QUESTIONS = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: {
    // Vehicle questions
    0: [
      PURCHASE_COVER_TYPE_QUESTION,
      VEHICLE_PARKING_QUESTION,
      AREA_OF_GARAGING_QUESTION,
      ANNUAL_DISTANCE_QUESTION,
      {
        ...VEHICLE_USE_QUESTION,
        warning: {
          value: 'business',
          text:
            'Business use means the vehicle will be used for Business or Trade purposes. This policy will not cover (under any use) vehicles carrying fare paying passengers E.G. Taxi or Uber, vehicles used for courier or delivery purposes, vehicles designed or altered solely to carry goods or merchandise, or vehicles with a Gross Vehicle Weight of 3500kg or more. If in doubt about which use to select, please call Protecta at 0800 776 832 to discuss your needs.',
        },
      },
      OTHER_PARTY_FINANCE_QUESTION,
      FINANCE_COMPANY_QUESTION,
      R.merge(MAKE_QUESTION, {
        line: 8,
        before: 'Make of vehicle',
      }),
      R.merge(MODEL_QUESTION, {
        line: 9,
        before: 'Model of vehicle',
      }),
      R.merge(SUBMODEL_QUESTION, {
        line: 10,
        before: 'Sub model of vehicle',
      }),
      MOD_VALUE_QUESTION,
      MOD_DESCRIPTION_QUESTION,
    ],
    // Personal questions
    1: [TITLE_QUESTION, ...DRIVER_QUESTIONS],
    // Driver questions
    2: [
      TITLE_QUESTION,
      {
        line: 2,
        before: 'First name',
        key: 'firstName',
        order: 1,
      },
      {
        line: 2,
        before: 'Last name',
        key: 'lastName',
        order: 2,
      },
      {
        line: 3,
        before: 'Date of Birth',
        key: 'dateOfBirth',
        order: 1,
        type: 'birthday',
      },
      {
        ...GENDER_QUESTION,
        before: 'Gender',
        line: 3,
        order: 2,
        after: '',
      },
      ...DRIVER_QUESTIONS.map((q) => R.assoc('line', q.line + 3, q)),
    ],
  },
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: {
    // Vehicle questions
    0: [
      MOTORCYCLE_COVER_TYPE_QUESTION,
      {
        ...MOTORCYCLE_USE_QUESTION,
        warning: {
          value: 'Business use',
          text:
            'Business use means the vehicle will be used for Business or Trade purposes. This policy will not cover (under any use) motorcycles let out on hire, motorcycles used for carrying fare paying passengers, or motorcycles used for delivery or courier purposes. If you unsure what use to select, please call Protecta at 0800 776 832 to discuss your needs.',
        },
      },
      R.merge(AREA_OF_GARAGING_QUESTION, { line: 3 }),
      R.merge(VEHICLE_PARKING_QUESTION, { line: 4 }),
      R.merge(MAKE_QUESTION, {
        line: 5,
      }),
      R.merge(MODEL_QUESTION, {
        line: 6,
      }),
      R.merge(CC_RATING_QUESTION, {
        line: 7,
        before: 'CC rating',
      }),
      R.merge(VEHICLE_YEAR_QUESTION, { line: 8 }),
      REGO_STATUS_QUESTION,
      R.merge(MOTORCYCLE_REGO_QUESTION, {
        placeholder: 'e.g. A1ABC',
        line: 10,
      }),
      R.merge(VIN_QUESTION, { line: 11 }),
      R.merge(OTHER_PARTY_FINANCE_QUESTION, { line: 12 }),
      R.merge(FINANCE_COMPANY_QUESTION, { line: 13 }),
      MOD_VALUE_QUESTION,
      MOD_DESCRIPTION_QUESTION,
    ],
    // Personal questions
    1: [TITLE_QUESTION, ...RIDER_QUESTIONS],
    // Driver questions
    2: [
      TITLE_QUESTION,
      {
        line: 2,
        before: 'First name',
        key: 'firstName',
        order: 1,
      },
      {
        line: 2,
        before: 'Last name',
        key: 'lastName',
        order: 2,
      },
      {
        line: 3,
        before: 'Date of Birth',
        key: 'dateOfBirth',
        order: 1,
        type: 'birthday',
      },
      {
        ...GENDER_QUESTION,
        before: 'Gender',
        line: 3,
        order: 2,
        after: '',
      },
      ...RIDER_QUESTIONS.map((q) => R.assoc('line', q.line + 3, q)),
    ],
  },
};

export const PROTECTA_BENEFITS = {
  excludeUnder25s: {
    label: 'Exclude drivers younger than 25 years old',
    tooltip:
      "This policy includes a discount for excluding all under 25 year olds that haven't been disclosed to us on this policy. This means any undisclosed driver under 25 years old will not be covered under this policy. Tick this box if you would like to add this discount and extend the policy to cover undisclosed drivers under the age of 25.",
  },
  namedDriver: {
    label: 'Exclude other (undisclosed) drivers',
    tooltip:
      'This policy includes a discount as a result of all undisclosed drivers being subject to an additional $250 excess. Tick this box if you would like to add this discount and extend the policy to allow undisclosed drivers at the standard policy excess.',
  },
  rentalCars: {
    label: 'Rental cars',
    type: 'dropdown',
  },
  roadsideAssistance: {
    label: 'Roadside assistance',
    tooltip:
      '24/7 assistance for Flat Tyre, Damaged Wheel, Flat Battery, Locked Out, Lost Keys, Out of Fuel, Breakdown Towing.',
  },
  voluntaryExcess: {
    label: 'Voluntary excess',
    type: 'dropdown',
    tooltip:
      'An additional excess to pay on top of  your standard excess on all claims in exchange for a cheaper premium.',
  },
  windscreens: {
    label: 'Windscreen cover',
    tooltip:
      'Cover for damage to any window glass (including sunroofs), with no excess to pay.',
  },
};

export enum LifeProviders {
  AIA = 'AIA',
  PINNACLE_LIFE = 'Pinnacle Life',
}

export enum AIAProducts {
  STARTER_LIFE = 'VSL21',
  STARTER_LIFE_PLUS = 'VSLP21',
  HEALTHY_STARTER = 'VSR21',
}

export enum AIABenefitCodes {
  RENT = 'Rent',
  MORTGAGE = 'Mortgage',
}

export enum PinnacleProducts {
  LIFE = 'Life',
  CI_RIDER = 'CiRider',
  TPD_RIDER = 'TpdRider',
}

export const EXCESS_TOOLTIPS = {
  [INSURANCE_TYPES_NAMES.VEHICLE]:
    '$500 excess is the most popular option for vehicle from our existing customers',
  [INSURANCE_TYPES_NAMES.HOME]:
    '$1000 excess is the most popular option for house from our existing customers',
  [INSURANCE_TYPES_NAMES.CONTENT]:
    '$250 excess is the most popular option for contents from our existing customers',
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]:
    '$500 excess is the most popular option for motorcycle from our existing customers',
};
