import { useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './styles/base.scss';

interface IBaseToastProps {
  text: any;
  title: string;
  img: JSX.Element;
  info?: boolean;
  success?: boolean;
  error?: boolean;
  onClose(): void;
  onClick?(args): void;
}

const BaseToast = ({
  img,
  text,
  title,
  info,
  success,
  error,
  onClose,
  onClick,
}: IBaseToastProps) => {
  const className = classnames(
    styles.container,
    info && styles.info,
    success && styles.success,
    error && styles.error,
  );

  const handleClose = useCallback((e) => {
    // Clicking close should not also trigger container onClick
    e.stopPropagation();
    onClose();
  }, [onClose]);

  return (
    <div className={className} onClick={onClick}>
      <Icon name="close" onClick={handleClose} />
      <div className={styles.header}>
        <div className={styles.img}>
          {img}
        </div>
        <div className={styles.title}>
          {title}
        </div>
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default BaseToast;