const breakpointMap = {
  xxl: 1300,
  xl: 1200,
  lg: 992,
  md: 820,
  sm: 576,
  xs: 0,
};

export const isBreakpoint = (breakpoint) => {
  return window.innerWidth <= breakpointMap[breakpoint];
};