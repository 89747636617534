import React, { useMemo, useState } from 'react';
import Upload from 'components/policy-wizard/Upload';
import styles from '../styles/PolicyTabs.module.scss';
import { sortByCreatedAt } from 'helpers';
import DocViewer from 'components/doc-viewer';
import { IAttachment } from 'types';
import { HelpTooltip } from 'components/tooltip';

const ToolTipContent =
  "If you've finished uploading your documents just make sure you've hit \"Upload\" before closing the page. Once you're done, you're all set. Our adviser will be in touch soon.";

const PolicyUpload = ({ attachments = [], ...props }) => {
  const [pdfSrc, setPdfSrc] = useState(null);

  const handleUpload = (files) => {
    // Ignore files with IDs as they have already been uploaded
    const newFiles = files.filter((f) => !f.id);
    props.uploadAttachments(newFiles);
  };

  const defaultAttachments = useMemo(() => sortByCreatedAt(attachments), [
    attachments,
  ]);

  const handleViewClick = async (attachment: IAttachment) => {
    if (attachment?.href) {
      setPdfSrc(attachment?.href);
    }
  };

  const handlePdfViewerClose = () => setPdfSrc(null);

  return (
    <>
      <DocViewer src={pdfSrc} onClose={handlePdfViewerClose} />
      <Upload
        {...props}
        isUploading={props.loading}
        className={styles.uploadContainer}
        uploaderClassName={styles.uploader}
        defaultAttachments={defaultAttachments}
        onSubmit={handleUpload}
        onPreviewClick={handleViewClick}
      />
      <div className={styles.helpContainer}>
        <HelpTooltip content={ToolTipContent}>
          <div className={styles.toolTipLabel}>What to do next?</div>
        </HelpTooltip>
      </div>
    </>
  );
};

export default PolicyUpload;
