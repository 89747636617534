// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._19vHfKkesuUj6jEfoqv65v ._1fGFE2o9dPoWixBupbAUeT{border-color:#e47c6e;padding-left:16px;width:calc(100% - 8px)}._1Tid-Ipn_vjuQbTGMlpgIc{display:flex;align-items:center}._1fGFE2o9dPoWixBupbAUeT.ui.checkbox{min-height:25px;min-width:25px;box-shadow:inset 0px 1px 2px rgba(0,0,0,.2);width:auto}._1fGFE2o9dPoWixBupbAUeT.ui.checkbox label{line-height:22px}._1fGFE2o9dPoWixBupbAUeT.ui.checkbox label:before{width:25px;height:25px;box-shadow:inset 0px 1px 2px rgba(0,0,0,.2);background:#fbfbfb !important}._1fGFE2o9dPoWixBupbAUeT.ui.checkbox :checked~label:after{content:\"\";font-family:Icons;color:#34363e;font-size:14px;margin-left:4px;margin-top:2px}._2dKcc7GPdPWEZzl4l2fmgW{display:flex;flex-direction:column;width:100%;margin-top:16px}._1beqhVBE9qIpRciyzaZkMh{display:flex;justify-content:space-between}._1beqhVBE9qIpRciyzaZkMh:not(:last-child){margin-bottom:16px}._3xsn1AvbN9XQrajVH259m7{display:flex}._3xsn1AvbN9XQrajVH259m7 span{width:200px}", ""]);
// Exports
exports.locals = {
	"error": "_19vHfKkesuUj6jEfoqv65v",
	"input": "_1fGFE2o9dPoWixBupbAUeT",
	"inputWrap": "_1Tid-Ipn_vjuQbTGMlpgIc",
	"checkboxGroup": "_2dKcc7GPdPWEZzl4l2fmgW",
	"checkbox": "_1beqhVBE9qIpRciyzaZkMh",
	"label": "_3xsn1AvbN9XQrajVH259m7"
};
module.exports = exports;
