import {
  INSTALMENT_RATE,
  MeetingStatus,
  PREMIUM_MEETING_STATUS,
} from 'constants/adviser';
import { IAdviserConnect } from 'types';

export const getPremiumAdviserConnect = (
  adviserConnects: IAdviserConnect[] = [],
) => {
  return (
    adviserConnects.find((ad) =>
      R.includes(ad.meetingStatus, PREMIUM_MEETING_STATUS),
    ) || null
  );
};

export const getInstalmentAmount = (amount: number) => {
  return (amount * INSTALMENT_RATE) / 11;
};

export const showMarketScanResultsPage = (meetingStatus: string) => {
  return R.includes(meetingStatus, [
    MeetingStatus.CLIENT_ACTION_AWAITING_ACCEPTANCE,
    MeetingStatus.ADVISER_ACTION_CONFIRM_ACCEPTANCE,
  ]);
};
