// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3FRabDSL7aLA8FHakjWodq{color:#34363e;position:absolute;top:16px;right:16px;cursor:pointer}.Zxy2gHx4bahnTbEsmHkne{font-family:\"DM Sans\";font-weight:700;font-size:24px;margin-top:16px;margin-bottom:16px}._1yutecy_FZOHkJ5vsNQYR_.ui.modal > .content{font-family:\"Open Sans\";font-weight:400;color:#34363e;text-align:center;padding:0;max-width:420px;margin:40px auto}._1yutecy_FZOHkJ5vsNQYR_.ui.modal > .content b{font-family:\"Open Sans\";font-weight:700}._3jfKDQZm-HkrtB3B-dtyI2{margin-bottom:16px}._1pCIVTaOEjjyTqDZNfRQfo{font-family:\"Open Sans\";font-weight:400;width:100%;border-radius:10px;padding:8px 16px;max-width:100%}._3NyUW38tQX8s5WB_z8gvv5.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#34363e}@media(max-width: 576px){._3NyUW38tQX8s5WB_z8gvv5.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._2nxYCst5K3GUUaTdrnGKHc{display:flex;justify-content:space-between;align-items:center;margin-top:16px}._1DhnZn5gK5-690waFppYb{display:flex;align-items:center}._1DhnZn5gK5-690waFppYb span{margin-right:8px}@media(max-width: 820px){._1yutecy_FZOHkJ5vsNQYR_.ui.modal > .content{padding:0 !important}}@media(max-width: 576px){._1yutecy_FZOHkJ5vsNQYR_.ui.modal > .content{padding-right:16px !important;padding-left:16px !important}._2nxYCst5K3GUUaTdrnGKHc{flex-direction:column}._1DhnZn5gK5-690waFppYb{margin-bottom:16px}}", ""]);
// Exports
exports.locals = {
	"close": "_3FRabDSL7aLA8FHakjWodq",
	"title": "Zxy2gHx4bahnTbEsmHkne",
	"modal": "_1yutecy_FZOHkJ5vsNQYR_",
	"content": "_3jfKDQZm-HkrtB3B-dtyI2",
	"textarea": "_1pCIVTaOEjjyTqDZNfRQfo",
	"button": "_3NyUW38tQX8s5WB_z8gvv5",
	"buttons": "_2nxYCst5K3GUUaTdrnGKHc",
	"checkboxContainer": "_1DhnZn5gK5-690waFppYb"
};
module.exports = exports;
