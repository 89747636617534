import React from 'react';
import DOMPurify from 'dompurify';
import styles from './styles/Q4EOffers.module.scss';

const Q4EOffers = ({ policyProviders, offers }) => {
  return (
    <div className={styles.offers}>
      {offers.map(({ title, policyProviderId, description }, idx) => {
        const provider = policyProviders.find(R.propEq('id', policyProviderId));
        return (
          <div key={`${policyProviderId}_${idx}`} className={styles.offer}>
            <img alt={provider.name} src={provider.logoUrl} />
            <div className={styles.right}>
              <div className={styles.title}>{title}</div>
              <div
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description, {
                    ADD_ATTR: ['target', 'rel'],
                  }),
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Q4EOffers;
