// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2cfq3XQFC8cy4Bq4LmduNW{width:fit-content}._2cfq3XQFC8cy4Bq4LmduNW img{width:11%}._2oDb5_jZ3MdAAYHneBGaDA{position:relative;padding:40px 24px 40px;display:flex;flex-direction:column;flex:1;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"images": "_2cfq3XQFC8cy4Bq4LmduNW",
	"container": "_2oDb5_jZ3MdAAYHneBGaDA"
};
module.exports = exports;
