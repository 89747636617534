import { GrowthBook } from '@growthbook/growthbook-react';
import axios from 'axios';
import { IUser } from 'types';

declare const GROWTHBOOK_URL: string;

const instance = axios.create({
  baseURL: GROWTHBOOK_URL,
});

export const growthBook = new GrowthBook({
  // Callback when a user is put into an A/B experiment
  trackingCallback: (experiment, result) => {
    // console.log('Experiment Viewed', {
    //   experimentId: experiment.key,
    //   variationId: result.variationId,
    //   value: result.value,
    //   userId: result.hashValue,
    // });
  },
});

export const growthBookInit = async (user: IUser) => {
  // Load feature definitions from GrowthBook API
  const { data } = await instance.get('/');
  growthBook.setFeatures(data.features);

  // Set user attributes for targeting (from cookie, auth system, etc.)
  growthBook.setAttributes({
    id: user.id,
  });
};
