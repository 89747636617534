import React from 'react';

const getListItem = (value, listKey) => {
  return listKey.reduce((acc, key) => {
    const val = value[key];
    return acc ? `${acc} ${val}` : val;
  }, '');
};

const FormEngineList = ({
  className,
  emptyLabel,
  listKey,
  name,
  value = [],
  onClick,
}) => {
  return (
    <ul className={className}>
      {value.length === 0 ? (
        <li onClick={onClick}>{emptyLabel}</li>
      ) : (
        value.map((val, idx) => {
          return (
            <li key={`${name}_${idx}`} onClick={onClick}>
              {getListItem(val, listKey)}
            </li>
          );
        })
      )}
    </ul>
  );
};

export default FormEngineList;
