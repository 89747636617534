import React, { useEffect } from 'react';
import classnames from "classnames";
import { Dropdown, DropdownProps, DropdownItemProps } from "semantic-ui-react";
import { HelpTooltip } from 'components/tooltip';
import styles from './formField.scss';

export interface IInputMultipleFieldProps {
  options?: DropdownItemProps[];
  name: string;
  value: any[];
  as: string;
  onChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => void;
  required?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  dropdownClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  search?: boolean;
  minCharacters?: number;
  icon?: string;
  tooltip?: string;
}

const InputMultipleField = ({
  required,
  value = [],
  onChange,
  className,
  label,
  dropdownClassName,
  labelClassName,
  disabled,
  error,
  placeholder = '',
  name,
  minCharacters = 0,
  as,
  tooltip
}: IInputMultipleFieldProps) => {
  const handleChange = (e, data) => {
    const values = data.value.map((v, idx) => {
      const existing = value.find(R.propEq(name, v));
      return {
        ...existing,
        order: existing?.order || idx,
        [name]: v
      };
    });
    onChange(e, { name: as, value: values });
  };

  const parsedOptions = value.map((v, idx) => ({
    key: `${v[name]}_${idx}`,
    text: v[name],
    value: v[name],
  }));

  const parsedValues = value.map(R.prop(name)) as string[];

  return (
    <div className={classnames(styles.container, error && styles.error, className)}>
      <span className={classnames(styles.label, labelClassName, tooltip && styles.withTooltip)}>
        <span>
          {required && <span className={styles.required}>{"* "}</span>}
          {label}
        </span>
        {tooltip && (
          <HelpTooltip
            toggleClassName={styles.toggle}
            content={tooltip}
            position="top center"
          />
        )}
      </span>
      <div className={classnames(styles.inputWrap, disabled && styles.disabled)}>
        <Dropdown
          className={classnames(styles.dropdown, styles.multiple, dropdownClassName)}
          placeholder={placeholder}
          required={required}
          value={parsedValues}
          onChange={handleChange}
          options={parsedOptions}
          selection={true}
          search={true}
          disabled={disabled}
          name={name}
          minCharacters={minCharacters}
          icon={false}
          allowAdditions={true}
          multiple={true}
        />
      </div>
    </div>
  );
};

export default InputMultipleField;