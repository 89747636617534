import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ADDITIONAL_LIFE_COVERS_QUESTIONS,
  INSURANCE_TYPES_NAMES,
  LIFE_INSURANCE_OPTIONAL_FIELDS,
} from 'constants/insurance';
import FormField from 'components/form-field-bordered';
import styles from '../styles/life.scss';
import { Common } from './Common';
import { useAuth0 } from '../../../react-auth0-spa';
import { setDefaultLifeCovers } from '../utils';

const handleInputChange = (fieldKey, order, setValue) => ({ target }) => {
  const { name = fieldKey, value } = target;

  setValue((prevState) => {
    const { insured } = prevState;
    const updated = R.assoc(name, value, insured[order]);
    return {
      ...prevState,
      insured: R.update(order, updated, insured),
    };
  });
};

const handleDropdownChange = (fieldKey, order, setValue) => (
  _,
  { value, options },
) => {
  setValue((prevState) => {
    const { insured } = prevState;
    const insuredItem = insured[order];
    const newVal = value.map((id) => {
      const option = options.find(R.propEq('value', id)) || {};
      const { value: policyTypeId, text: policyType } = option;
      const { additionalCovers = [] } = insuredItem;
      const coverData = additionalCovers.find(R.propEq('policyTypeId', id));
      return coverData || { policyTypeId, policyType };
    });
    const updated = R.assoc(fieldKey, newVal, insuredItem);
    return {
      ...prevState,
      insured: R.update(order, updated, insured),
    };
  });
};

export const LifeCovers = ({
  next,
  values: form,
  fieldLabels,
  bottomLabels,
  policyTypes,
  setFormValue,
  page,
  title = 'Select the covers in your Life insurance policy',
  onBack,
}) => {
  const [errors, setErrors] = useState(null);

  const options = useMemo(() => {
    return policyTypes
      .filter(R.prop('life'))
      .map(({ id, name }) => ({ key: name, value: id, text: name }));
  }, [policyTypes]);

  useEffect(() => {
    const opt = policyTypes.find(R.propEq('name', INSURANCE_TYPES_NAMES.LIFE));
    setFormValue((prevState) => setDefaultLifeCovers(prevState, opt));
  }, [policyTypes, setFormValue]);

  const handleSubmit = useCallback(() => {
    /**
     * Error object looks like { [order]: { name: true, ... } }
     * {
     *  0: {},
     *  1: {}
     * }
     */
    const newErrors = form.insured.reduce((acc, row, idx) => {
      ADDITIONAL_LIFE_COVERS_QUESTIONS.forEach(
        ({ fieldKey: k, multiple, required }) => {
          if (required) {
            const value = row[k];
            // Check array if we expect multiple selections
            const isError = multiple ? (value || []).length === 0 : !value;
            if (isError) {
              acc = R.assocPath([idx, k], true, acc);
            }
          }
        },
      );
      return acc;
    }, null);

    if (newErrors) {
      setErrors(newErrors);
    } else {
      next();
    }
  }, [form, setFormValue, policyTypes]);

  const formFields = useMemo(() => {
    /**
     * insured: [{
     *  order: 1,
     *  name: "",
     *  additionalCovers: [{...}],
     *  premiumCover: false,
     * }]
     */
    return form.insured.reduce((acc, row, i) => {
      ADDITIONAL_LIFE_COVERS_QUESTIONS.forEach((field) => {
        const { fieldKey, type } = field;
        const handleChange =
          type === 'dropdown' ? handleDropdownChange : handleInputChange;
        const value =
          type === 'dropdown' && row[fieldKey]
            ? R.pluck('policyTypeId', row[fieldKey])
            : row[fieldKey];
        acc.push(
          <FormField
            key={`${fieldKey}${i}`}
            name={fieldKey}
            {...field}
            className={classnames(
              styles.lifeCoverInputs,
              field.inputType === 'checkbox' && styles.lifeCoverCheckbox,
            )}
            value={value}
            onChange={handleChange(fieldKey, i, setFormValue)}
            error={R.path([i, fieldKey], errors)}
            options={options}
          />,
        );
      });
      return acc;
    }, []);
  }, [form, errors]);

  return (
    <Common
      questions={[]}
      title={title}
      subtitle="Please provide the following details"
      form={form}
      onSubmit={handleSubmit}
      options={options}
      fieldLabels={fieldLabels}
      bottomLabels={bottomLabels}
      inputClassName={styles.lifeCoverInputs}
      page={page}
      onBack={onBack}
    >
      {formFields}
    </Common>
  );
};

export const OptionalLifePage = ({
  values: form,
  onSubmit,
  policyType: selectedPolicyType,
  disableSubmit,
  setFormValue,
  page,
  onInputChange,
  title,
  onBack,
}) => {
  const { hasRole } = useAuth0();

  const getValue = (policyType, order, fieldKey) => {
    const { additionalCovers } = form.insured[order];
    const cover = additionalCovers.find(R.propEq('policyType', policyType));
    return cover ? cover[fieldKey] : '';
  };

  const handleChange = (policyType, order, field) => (event, ddValue) => {
    const value =
      field.type === 'dropdown'
        ? ddValue.value
        : R.path(['target', 'value'], event);

    setFormValue((prevState) => {
      const { additionalCovers } = prevState.insured[order];
      const prevCoverIdx = additionalCovers.findIndex(
        R.propEq('policyType', policyType),
      );
      const updatedItem = R.assoc(
        field.fieldKey,
        value,
        additionalCovers[prevCoverIdx],
      );
      const updatedCovers = R.update(
        prevCoverIdx,
        updatedItem,
        additionalCovers,
      );
      const updatedInsured = R.update(
        order,
        R.assoc('additionalCovers', updatedCovers, prevState.insured[order]),
        prevState.insured,
      );
      return R.assoc('insured', updatedInsured, prevState);
    });
  };

  const fields = useMemo(() => {
    const f = form.insured.reduce((acc, row, i) => {
      const { additionalCovers = [] } = row;
      acc.push(
        <div key={`${row.name}${i}`} className={styles.lifeOptionalRow}>
          {form.insured.length > 1 && (
            <div className={styles.lifeOptionalTitle}>{row.name}</div>
          )}
          <div className={styles.lifeOptionalInputs}>
            {additionalCovers.map(({ policyType }, j) => {
              const coverFields =
                LIFE_INSURANCE_OPTIONAL_FIELDS[policyType] ||
                LIFE_INSURANCE_OPTIONAL_FIELDS.default;
              return coverFields.map((cf) => (
                <FormField
                  {...cf}
                  key={`${cf.fieldKey}${i}${j}`}
                  className={styles.lifeOptionalFields}
                  value={getValue(policyType, i, cf.fieldKey)}
                  onChange={handleChange(policyType, i, cf)}
                  placeholder={`${policyType} ${cf.placeholder}`}
                  placeholderClassName={styles.lifeOptionalPlaceholder}
                />
              ));
            })}
          </div>
        </div>,
      );
      return acc;
    }, []);

    if (hasRole('admin')) {
      f.push(
        <FormField
          key="userId"
          name="userId"
          className={styles.lifeOptionalFields}
          value={form.userId}
          onChange={onInputChange}
          placeholder="User ID"
          placeholderClassName={styles.lifeOptionalPlaceholder}
        />,
      );
    }

    return f;
  }, [form]);

  const pageTitle =
    title ||
    `Let’s find out more about your ${selectedPolicyType.name} insurance`;

  return (
    <Common
      questions={[]}
      title={pageTitle}
      subtitle="Optional"
      form={form}
      onSubmit={onSubmit}
      buttonText="Finish"
      inputClassName={styles.additionalInfoInputs}
      subtitleClassName={styles.additionalInfoSubtitle}
      disableSubmit={disableSubmit}
      page={page}
      onBack={onBack}
    >
      {fields}
    </Common>
  );
};
