import React, { ReactElement } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import Button from 'components/button';
import styles from './styles/error.scss';
import LogoSmall from '-!svg-react-loader!images/logo-small.svg';
import { X } from 'react-feather';

interface IErrorToast {
  closeText?: string;
  message?: ReactElement;
  messageClassName?: string;
  closeToast(): void;
  onClose?(): void;
}

const toastId = 'errorToast';

const options: ToastOptions = {
  className: styles.toastContainer,
  position: 'top-center',
  toastId,
  autoClose: false,
};

const errorMessage = (
  <>
    <div className={styles.message}>
      If you're seeing this, we've hit a small roadblock. We'e looking into
      this, don't worry.
    </div>
    <div className={styles.contact}>
      If you have any questions, contact{' '}
      <a href="mailto:support@quashed.co.nz">support@quashed.co.nz</a>
    </div>
  </>
);

const renderToast = (component: ToastContent) => {
  if (toast.isActive(toastId)) {
    // Update error content if toast is already open
    toast.update(toastId, {
      render: component,
    });
  } else {
    toast(component, options);
  }
};

const ErrorGenericToast = ({
  closeText = 'Go back',
  message = errorMessage,
  messageClassName,
  onClose,
  closeToast,
}: IErrorToast) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    closeToast();
  };
  // removed messageClassName for now, so old styling does not clash with new
  return (
    <div className={styles.container}>
      <X className={styles.xBtn} onClick={handleClose} />
      <LogoSmall className={styles.logo} />
      <div className={styles.header}>Oops! Something went wrong.</div>
      <div className={classnames(styles.messageContainer)}>{message}</div>
      <div className={styles.btnContainer}>
        <Button
          className={styles.button}
          onClick={handleClose}
          text={closeText}
        />
      </div>
    </div>
  );
};

const ErrorToast = (props) => {
  const message = (
    <>
      <div className={styles.message}>{props.message}</div>
      <div className={styles.contact}>
        If you have any questions, contact{' '}
        <a href="mailto:support@quashed.co.nz">support@quashed.co.nz</a>
      </div>
    </>
  );
  return (
    <ErrorGenericToast
      {...props}
      message={message}
      messageClassName={styles.wider}
    />
  );
};

const controller = {
  errorGeneric: (props?) => renderToast(<ErrorGenericToast {...props} />),
  error: (props?) => renderToast(<ErrorToast {...props} />),
};

export default controller;
