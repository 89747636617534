import React from 'react';
import moment from 'moment';
import { AlertCircle, Star } from 'react-feather';
import { Link } from 'react-router-dom';
import { Features } from 'constants/features';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { AppBannerContentType } from 'constants/notification';
import { getMarketScanNewRouteByName, hasFeature } from 'helpers';
import styles from './AppBanner.module.scss';
import { IAdviserConnect } from 'types';
import { ResetPolicyForm } from 'actions';

// We only show popup when there is no booking or booking is after 3 months
const showExploreLifeBanner = (adviserConnects: IAdviserConnect[]) => {
  if (adviserConnects.length === 0) {
    return true;
  }

  const threeMonthsFromNow = moment().add(3, 'month');
  const endBooking = R.path(
    ['calendlyEvent', 'resource', 'end_time'],
    adviserConnects[0],
  );
  return moment(endBooking).isAfter(threeMonthsFromNow);
};

const contentMap = {
  [AppBannerContentType.HIGH_VOL_MS]: () => (
    <span>
      <AlertCircle size={18} className={styles.leftIcon} />
      We are experiencing high volume of <b>Market Scans</b>. Just a heads up we
      will have your results ready as soon as possible.
    </span>
  ),
  [AppBannerContentType.LIFE]: ({ dispatch, onClose }) => {
    const handleClose = () => {
      dispatch(ResetPolicyForm());
      onClose();
    };

    return (
      <span>
        <Star size={18} className={classnames(styles.leftIcon, styles.star)} />
        We now have <b>Life Insurance Market Scan.</b>{' '}
        <Link
          to={getMarketScanNewRouteByName(INSURANCE_TYPES_NAMES.LIFE)}
          onClick={handleClose}
        >
          Check it out!
        </Link>
      </span>
    );
  },
};

export const getAppBannerContent = (props) => (state) => {
  const bannerType = R.path(['notification', 'bannerType'], state);
  if (bannerType && hasFeature(state.config, Features.APP_BANNER_ENABLED)) {
    const Component = contentMap[AppBannerContentType.HIGH_VOL_MS];
    return <Component />;
  } else if (
    bannerType === AppBannerContentType.LIFE &&
    showExploreLifeBanner(state.adviser.adviserConnects)
  ) {
    const Component = contentMap[AppBannerContentType.LIFE];
    return <Component {...props} />;
  }
  return null;
};
