import React, { useMemo } from 'react';
import styles from '../styles/q4e.scss';
import QuashedForm from 'components/quashed-form';

const getComponentProps = (question, values) => {
  if (question.key === 'q4eOption') {
    const desc = question.labelAfter[values.q4eOption || question.defaultValue];
    return {
      labelAfter: desc ? (
        <div
          className={styles.desc}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      ) : null,
    };
  }
};

const Page1 = ({
  employer,
  title,
  values,
  onSubmit,
  questions,
  loading,
  onBack,
}) => {
  const questionDefaultValues = useMemo(() => {
    return questions.reduce((acc, q) => {
      if (!R.isNil(q.defaultValue)) {
        acc[q.key] = q.defaultValue;
      }
      return acc;
    }, {});
  }, [questions]);

  const defaultValues = useMemo(() => {
    // undefined values overwrite defaultValues when merged
    const cleaned = R.reject(R.isNil)(values);
    return { ...questionDefaultValues, ...cleaned };
  }, [questionDefaultValues, values]);

  return (
    <div className={styles.page}>
      <QuashedForm
        className={styles.form}
        buttonClassName={styles.formButtons}
        questions={questions}
        defaultValues={defaultValues}
        title={title}
        onBack={onBack}
        onSubmit={onSubmit}
        disableSubmit={loading}
        getComponentProps={getComponentProps}
        submitText="Next"
      />
      <div />
    </div>
  );
};

export default Page1;
