import React, { useEffect, useState } from 'react';
import ConfirmAction from 'components/confirm-action';
import styles from './MarketScanConfirmPurchase.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { policiesToLinkSelector } from 'selectors';
import { getFieldValueByPolicyType } from 'helpers';
import { ConfirmPolicyScanPurchase, UpdatePolicy } from 'actions';

const MarketScanConfirmPurchase = () => {
  const dispatch = useDispatch();
  const policies = useSelector(policiesToLinkSelector);
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    if (!policy && policies.length > 0) {
      setPolicy(policies[0]);
    } else if (policy && policies.length === 0) {
      setPolicy(null);
    }
  }, [policies]);

  const handleCancel = () => {
    setPolicy(null);
    if (policy) {
      const toUpdate = R.assoc('needConfirmPurchase', null, policy);
      dispatch(UpdatePolicy(toUpdate));
    }
  };

  const handleConfirm = () => {
    setPolicy(null);
    if (policy) {
      dispatch(
        ConfirmPolicyScanPurchase(policy.needConfirmPurchase, policy.id),
      );
    }
  };

  if (!policy) {
    return null;
  }

  const insuredItem = getFieldValueByPolicyType(policy);

  return (
    <ConfirmAction
      header={
        <>
          {`We noticed you’ve just uploaded a ${policy?.policyType} insurance for ${insuredItem}.`}
          <br />
          {`Did you find this policy through your Quashed Market Scan?`}
        </>
      }
      content=""
      className={styles.confirm}
      open={true}
      cancelText="No"
      confirmText="Yes"
      closeOnDimmerClick={false}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};

export default MarketScanConfirmPurchase;
