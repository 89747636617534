import React, { useEffect } from 'react';
import { Plus } from 'react-feather';
import { INSURANCE_TYPES_ICONS_SVG_V2 } from 'constants/insurance';
import Manual from 'components/policy-wizard/single/Manual';
import styles from '../styles/PolicyTabs.module.scss';
import {
  getItemName,
  getPoliciesForAdviserConnect,
  PREMIUM_GENERAL_POLICY_TYPES,
} from '../utils';
import usePager from 'hooks/usePager';
import useForm from 'hooks/useForm';

const PolicyManual = ({
  adviserConnect,
  referenceData,
  addPolicy,
  editPolicy,
}) => {
  const pager = usePager(0);

  const form = useForm({
    defaultValues: null,
    resetOnSubmit: true,
    onSubmit: ({ values }) => {
      const submitFn = values.id ? editPolicy : addPolicy;
      submitFn(values);
      pager.resetActive(); // Go back to start
    },
  });

  const handleNewPolicy = (pt: string) => {
    const ptData = referenceData.policyTypes.find(R.propEq('name', pt));
    if (ptData) {
      form.setValues({
        policyType: ptData.name,
        policyTypeId: ptData.id,
      });
    }
  };

  const handleBack = () => {
    if (pager.active === 0) {
      form.setValues(null);
    } else {
      pager.prev();
    }
  };

  const groupedPolicies = getPoliciesForAdviserConnect(adviserConnect);

  if (!form.values?.policyType) {
    return (
      <div className={styles.manualContainer}>
        {PREMIUM_GENERAL_POLICY_TYPES.map((pt: string) => {
          const Icon = INSURANCE_TYPES_ICONS_SVG_V2[pt];

          return (
            <div key={pt} className={styles.column}>
              <div
                className={styles.button}
                onClick={() => handleNewPolicy(pt)}
              >
                <Icon className={styles.icon} />
                <h3>{pt}</h3>
                <Plus size={30} />
              </div>
              {groupedPolicies[pt] && (
                <div className={styles.items}>
                  {groupedPolicies[pt].map((p) => (
                    <div
                      key={p.id}
                      className={styles.item}
                      onClick={() => form.setValues(p)}
                    >
                      {getItemName(p)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.form}>
      <Manual
        policy={form.values}
        policyType={{
          id: form.values.policyTypeId,
          name: form.values.policyType,
        }}
        pager={pager}
        policyTypes={referenceData.policyTypes}
        disableSubmit={false}
        form={form}
        onBack={handleBack}
      />
    </div>
  );
};

export default PolicyManual;
