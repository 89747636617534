import React, { useState } from 'react';
import styles from '../styles/AdviserContact.module.scss';
import { Image } from 'semantic-ui-react';
import { IconTooltip } from 'components/tooltip';
import { Mail, Phone } from 'react-feather';

interface AdviserContactProps {
  className?: string;
  isDash?: boolean;
  adviser: {
    phoneNumber: string;
    email: string;
    picture: string;
  };
}

const AdviserContact = ({
  className,
  isDash,
  adviser,
}: AdviserContactProps) => {
  const { phoneNumber, email, picture } = adviser;
  const [showIcons, setShowIcons] = useState(false);

  const handleShowIcons = () => {
    setShowIcons((current) => !current);
  };
  return (
    <>
      <div
        className={classnames(
          className,
          styles.adviserContactContainer,
          isDash && styles.dash,
        )}
      >
        <Image className={styles.adviserImage} src={picture} />
        <div className={styles.iconContainer}>
          <IconTooltip
            className={styles.iconToolTipPhone}
            href={'tel:' + phoneNumber}
            icon={Phone}
            content={phoneNumber}
            position="bottom right"
          />
          <IconTooltip
            className={styles.iconToolTipMail}
            href={'mailto:' + email}
            icon={Mail}
            content={email}
            position="bottom right"
            target="_blank"
          />
        </div>
      </div>
      {isDash && (
        <div className={styles.adviserCard}>
          <div
            className={classnames(
              styles.dashIconContainer,
              showIcons ? styles.show : styles.hide,
            )}
          >
            <IconTooltip
              className={styles.iconToolTipPhone}
              href={'tel:' + phoneNumber}
              icon={Phone}
              content={phoneNumber}
              position="bottom right"
            />
            <IconTooltip
              className={styles.iconToolTipMail}
              href={'mailto:' + email}
              icon={Mail}
              content={email}
              position="bottom right"
              target="_blank"
            />
          </div>
          <Image
            className={styles.adviserProfileButton}
            onClick={handleShowIcons}
            src={picture}
          />
        </div>
      )}
    </>
  );
};
export default AdviserContact;
