import {
  addPremiums,
  calculateTotalInsured,
  getClosestRenewalDateDays,
  prettifyNumber,
} from 'helpers';
import { INSURANCE_TYPES_NAMES } from './insurance';
import { FREQUENCY } from './options';

export const SERVICE_FEE = 5.75;

export const INSTALMENT_RATE = 1.088;

export const POLICY_TYPES = [
  {
    key: INSURANCE_TYPES_NAMES.LIFE,
    text: 'Life insurance',
    value: INSURANCE_TYPES_NAMES.LIFE,
    details:
      'Including Life, Trauma, TPD, Income protection, Mortgage repayment & Medical insurance',
  },
  {
    key: INSURANCE_TYPES_NAMES.OTHER,
    text: 'General insurance',
    value: INSURANCE_TYPES_NAMES.OTHER,
    details:
      'Including Vehicle, Home, Pet, Contents, Boat, Landlord, Travel, Renters & Other insurance',
  },
  {
    key: 'all',
    text: 'Life & General insurance',
    value: 'all',
    details:
      'Including Trauma, TPD, Income protection, Mortgage repayment, Medical, Vehicle, Home, Pet, Contents, Boat, Landlord, Travel, Renters & Other insurance',
  },
];

export const ADVICE_HELP_OPTIONS = [
  {
    id: 'purchasing',
    name: 'purchasing',
    label: 'Look for a new cover',
  },
  {
    id: 'reviewing',
    name: 'reviewing',
    label: 'Review my existing cover',
  },
];

export const PREFERRED_CONTACT_QUESTION = {
  key: 'preferredContact',
  type: 'dropdown',
  options: [
    {
      key: 'video',
      value: 'video',
      text: 'Video call',
    },
    {
      key: 'phone',
      value: 'phone',
      text: 'Phone call',
    },
  ],
};

export const PREFFERED_CONTACT_DETAILS_QUESTION = {
  key: 'preferredContactDetails',
  type: 'input',
};

export const DEFAULT_FORM_STATE = {
  interestedIn: [],
  helpWith: '',
  // video or phone
  preferredContact: 'video',
};

export enum ClientSortValues {
  RENEWALS = 'renewals',
  BIRTHDAYS = 'dateOfBirth',
  CREATED_AT_ASC = 'oldest',
  CREATED_AT_DESC = 'newest',
  NAME_ASC = 'A-Z',
  NAME_DESC = 'Z-A',
}

export enum ClientFilterValues {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ALL = 'all',
}

export enum ClientStatus {
  ACTIVE = 'Active',
  EXPIRED = 'Need Attention',
  NO_POLICIES = 'Click to add policies',
}

export enum ClientInviteStatus {
  NOT_INVITED = 'NOT_INVITED',
  INVITATION_PENDING = 'INVITATION_PENDING',
  ACTIVE = 'ACTIVE',
}

export enum ClientConsentStatus {
  PENDING = 'PENDING',
  FINISH_SETUP = 'FINISH_SETUP',
  IN_REVIEW = 'IN_REVIEW',
  READY_INVITE_CLIENT = 'READY_INVITE_CLIENT',
  INVITATION_PENDING = 'INVITATION_PENDING',
}

export enum MeetingStatus {
  ADVISER_ACTION_CONTACT_REQUESTED = 'ADVISER_ACTION_CONTACT_REQUESTED',
  CLIENT_ACTION_AWAITING_FORMS_COMPLETION = 'CLIENT_ACTION_AWAITING_FORMS_COMPLETION',
  ADVISER_ACTION_OBTAIN_QUOTES = 'ADVISER_ACTION_OBTAIN_QUOTES',
  CLIENT_ACTION_AWAITING_ACCEPTANCE = 'CLIENT_ACTION_AWAITING_ACCEPTANCE',
  ADVISER_ACTION_CONFIRM_ACCEPTANCE = 'ADVISER_ACTION_CONFIRM_ACCEPTANCE',
  CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
  CLIENT_DECLINED = 'CLIENT_DECLINED',
  ADVISER_DECLINED = 'ADVISER_DECLINED',
}

export const MEETING_STATUS_OPTIONS = [
  {
    key: MeetingStatus.ADVISER_ACTION_CONTACT_REQUESTED,
    value: MeetingStatus.ADVISER_ACTION_CONTACT_REQUESTED,
    text: MeetingStatus.ADVISER_ACTION_CONTACT_REQUESTED,
  },
  {
    key: MeetingStatus.ADVISER_DECLINED,
    value: MeetingStatus.ADVISER_DECLINED,
    text: MeetingStatus.ADVISER_DECLINED,
  },
  {
    key: MeetingStatus.CLIENT_ACTION_AWAITING_FORMS_COMPLETION,
    value: MeetingStatus.CLIENT_ACTION_AWAITING_FORMS_COMPLETION,
    text: MeetingStatus.CLIENT_ACTION_AWAITING_FORMS_COMPLETION,
  },
  {
    key: MeetingStatus.ADVISER_ACTION_OBTAIN_QUOTES,
    value: MeetingStatus.ADVISER_ACTION_OBTAIN_QUOTES,
    text: MeetingStatus.ADVISER_ACTION_OBTAIN_QUOTES,
  },
  {
    key: MeetingStatus.CLIENT_ACTION_AWAITING_ACCEPTANCE,
    value: MeetingStatus.CLIENT_ACTION_AWAITING_ACCEPTANCE,
    text: MeetingStatus.CLIENT_ACTION_AWAITING_ACCEPTANCE,
  },
  {
    key: MeetingStatus.ADVISER_ACTION_CONFIRM_ACCEPTANCE,
    value: MeetingStatus.ADVISER_ACTION_CONFIRM_ACCEPTANCE,
    text: MeetingStatus.ADVISER_ACTION_CONFIRM_ACCEPTANCE,
  },
  {
    key: MeetingStatus.CLIENT_ACCEPTED,
    value: MeetingStatus.CLIENT_ACCEPTED,
    text: MeetingStatus.CLIENT_ACCEPTED,
  },
  {
    key: MeetingStatus.CLIENT_DECLINED,
    value: MeetingStatus.CLIENT_DECLINED,
    text: MeetingStatus.CLIENT_DECLINED,
  },
];

export const ADVISER_SUMMARY_ITEMS = [
  {
    key: 'clients',
    title: 'Total clients',
  },
  {
    key: 'policies',
    title: 'Total policies',
  },
  {
    key: 'premiums',
    title: 'Total premiums',
    getValue: (v) => `$${prettifyNumber(v)}`,
  },
  {
    key: 'amount',
    title: 'Total sum insured',
    getValue: (v) => `$${prettifyNumber(v)}`,
  },
];

export const CLIENT_SUMMARY_ITEMS = [
  {
    key: 'policies',
    title: 'Total policies',
    getValue: (p) => p.length,
  },
  {
    key: 'amount',
    title: 'Total sum insured',
    valueKey: 'policies',
    getValue: (p) =>
      `$${prettifyNumber(R.prop('totalInsured', calculateTotalInsured(p)))}`,
  },
  {
    key: 'premiums',
    title: 'Total premiums',
    valueKey: 'policies',
    getValue: (p) => `$${prettifyNumber(addPremiums(p, FREQUENCY.YEARLY))}`,
  },
  {
    key: 'renewalDate',
    title: 'Next renewal',
    valueKey: 'policies',
    getValue: (p) => {
      let days = getClosestRenewalDateDays(p);
      if (Number.isNaN(days) || days < 0) {
        days = 0;
      }
      return `${days} days`;
    },
  },
];

export const PREMIUM_MEETING_STATUS = [
  MeetingStatus.ADVISER_ACTION_CONTACT_REQUESTED,
  MeetingStatus.CLIENT_ACTION_AWAITING_FORMS_COMPLETION,
  MeetingStatus.ADVISER_ACTION_OBTAIN_QUOTES,
  MeetingStatus.CLIENT_ACTION_AWAITING_ACCEPTANCE,
  MeetingStatus.ADVISER_ACTION_CONFIRM_ACCEPTANCE,
];
