import React, { useCallback, useMemo, useState } from 'react';
import styles from './styles/referAFriend.scss';
import Button from '../button';
import { IReferral, IReferralNew, IUser } from 'types';
import { ReferralStatus } from 'constants/referral';
import ReferBase from './ReferBase';

interface IProps {
  user: IUser;
  referrals: IReferral[];
  className?: string;
  triggerText?: any;
  createReferral(referral: IReferralNew): void;
}

const text = {
  leftHeader: 'Having fun & loving Quashed?',
  leftTitle: 'Refer a friend & be in to win!',
  leftDesc:
    "Every month our most active fans get sent a fan pack! It could be our latest branded gear, some of the team's favourite treats or a voucher that will help you celebrate your newfound status with your insurance. Will it be you this month?",
  rightDesc: (
    <>
      Exclusively invite your friends and family to Quashed today!
      <br /> <b>Gift them a new & refreshing insurance experience.</b>
    </>
  ),
  submitBtnText: 'Send invite',
  submittedBold: 'Great, you’re in to win a Quashed fan pack!',
  submittedDesc: 'You’ll get an email from us if you’ve won.',
};

const ReferAFriend = ({
  className,
  referrals,
  triggerText = 'Refer a friend',
  createReferral,
  user,
}: IProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const invited = referrals.length;
  const onboarded = useMemo(() => {
    return referrals.filter(R.propEq('status', ReferralStatus.ACCEPTED)).length;
  }, [referrals]);

  const counter = (
    <div className={styles.counters}>
      <div className={classnames(styles.counter, styles.bold)}>
        <div>Invited</div>
        <div>{invited}</div>
      </div>
      <div className={classnames(styles.counter, styles.bold)}>
        <div>Onboarded</div>
        <div>{onboarded}</div>
      </div>
    </div>
  );

  return (
    <ReferBase
      user={user}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      items={referrals}
      rightHeader={counter}
      trigger={
        <Button
          className={classnames(styles.blueBtn, className)}
          text={triggerText}
        />
      }
      onSubmit={createReferral}
      {...text}
    />
  );
};

export default ReferAFriend;
