import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { MarketScanPath } from './reducer';

export const SUBTITLES = {
  [MarketScanPath.EXISTING_POLICY]: {
    [INSURANCE_TYPES_NAMES.HOME]: {
      0: 'Great! Here’s what we’ve picked up, please check it’s correct',
      1: 'We just need a few more details to provide an accurate quote',
      2: 'Here’s an overview, please check that the information is correct and click scan to finish',
    },
    default: {
      0: 'We just need a few more details to provide an accurate quote',
      1: 'Here’s an overview, please check that the information is correct and click scan to finish',
    },
  },
  [MarketScanPath.ADD_POLICY]: {
    // Manual is +1 to page
    manual: {
      3: 'Great! Here’s what we’ve picked up, please check it’s correct',
      4: 'Almost there, we just need a few more details',
      5: 'Here’s an overview, please check that the information is correct and click scan to finish',
    },
    default: {
      2: 'Great! Here’s what we’ve picked up, please check it’s correct',
      3: 'Almost there, we just need a few more details',
      4: 'Here’s an overview, please check that the information is correct and click scan to finish',
    },
  },
};

export const DEFAULT_POLICY_DATA_MAP = {
  [INSURANCE_TYPES_NAMES.HOME]: {
    isPropertyOwner: true,
  },
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: {
    accidentHistory: false,
  },
};
