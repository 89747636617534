// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3G4x010D2dzlYHXIeGR9ud{font-family:\"Open Sans\";font-weight:400;font-size:14px;display:flex;color:#34363e;position:relative}._3G4x010D2dzlYHXIeGR9ud ::-webkit-input-placeholder{font-family:\"Open Sans\";font-weight:400;font-size:14px}.Emrs4iYdDBIwRRvPDUvwO{font-weight:700;margin-right:8px;display:flex;align-items:center;flex-wrap:wrap}.Emrs4iYdDBIwRRvPDUvwO svg{margin-left:8px}._3HiU-BcASoZNp6v1SALfiv>*{vertical-align:middle}._2rO93k4pvIwewzzStMjtmC{margin-left:8px;line-height:30px}._38ukNXXKow1BqAS03sYutb{font-weight:400;display:flex;align-items:center;color:#c7c7c7}.Lf3b5ll32pfC9Xs48I24X{display:flex;margin-left:5px}.L5BNgxOtaRb4SsvggzRL-{flex:1}.I_wA87BxEmysS7QtA_rqD{position:relative;display:flex;align-items:center;width:100%}._3I_2ifs4GfD47_FKJ7PcF8{position:absolute;color:#e47c6e;z-index:1}._3I_2ifs4GfD47_FKJ7PcF8.icon{width:auto;height:auto}._3SmlfiR0bojdUYRKR2h9Rs{font-size:12px;color:#e47c6e}._2kCLoenYzrxtMM_VRabxSW .Emrs4iYdDBIwRRvPDUvwO{color:#e47c6e}._1nHm_RnKjYmPfurXfGfRd0{margin-left:auto;cursor:pointer}._1wYdKu-GGQVbHSP0_2UYv a{color:#34363e}._1wYdKu-GGQVbHSP0_2UYv a:hover{text-decoration:underline}.L7qV27d7FBxIluSL5kZrB{font-family:\"Open Sans\";font-weight:600;font-size:12px;display:flex;align-items:center;color:#4187f5;margin-top:8px;line-height:1.2}.L7qV27d7FBxIluSL5kZrB svg{margin-right:4px;min-width:18px}", ""]);
// Exports
exports.locals = {
	"container": "_3G4x010D2dzlYHXIeGR9ud",
	"label": "Emrs4iYdDBIwRRvPDUvwO",
	"textWrap": "_3HiU-BcASoZNp6v1SALfiv",
	"labelAfter": "_2rO93k4pvIwewzzStMjtmC",
	"description": "_38ukNXXKow1BqAS03sYutb",
	"rightIcon": "Lf3b5ll32pfC9Xs48I24X",
	"inputContainer": "L5BNgxOtaRb4SsvggzRL-",
	"inputWrap": "I_wA87BxEmysS7QtA_rqD",
	"errorIcon": "_3I_2ifs4GfD47_FKJ7PcF8",
	"errorText": "_3SmlfiR0bojdUYRKR2h9Rs",
	"error": "_2kCLoenYzrxtMM_VRabxSW",
	"toggle": "_1nHm_RnKjYmPfurXfGfRd0",
	"toggleContent": "_1wYdKu-GGQVbHSP0_2UYv",
	"info": "L7qV27d7FBxIluSL5kZrB"
};
module.exports = exports;
