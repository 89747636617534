import React from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';

const Tooltip = (props: PopupProps) => {
  return (
    <Popup
      basic={true}
      offset={[0, 0]}
      hideOnScroll={true}
      position="top center"
      {...props}
    />
  );
};

export default Tooltip;
