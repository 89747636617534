import React from 'react';
import { Dimmer, Loader } from "semantic-ui-react";

export interface ISpinnerProps {
  className?: string;
  content?: string;
  loading: boolean;
}

const Spinner = ({ className, loading, content }: ISpinnerProps) => (
  <Dimmer className={className} active={loading} inverted={true}>
    <Loader inverted={true} content={content} />
  </Dimmer>
)

export default Spinner;