// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3R9nxkkcGYOck906hLiGF{min-height:70vh;display:flex;flex-direction:column;align-items:center;justify-content:center;opacity:50%;animation:_1cqbVN4dRiVSfJDo-FHoO_ 1s ease infinite alternate}._3R9nxkkcGYOck906hLiGF._1wI87LS4e6KOKv9Zioc0gr{min-height:100vh}@keyframes _1cqbVN4dRiVSfJDo-FHoO_{0%{opacity:50%}100%{opacity:80%}}", ""]);
// Exports
exports.locals = {
	"container": "_3R9nxkkcGYOck906hLiGF",
	"throbbing": "_1cqbVN4dRiVSfJDo-FHoO_",
	"full": "_1wI87LS4e6KOKv9Zioc0gr"
};
module.exports = exports;
