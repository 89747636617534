import { getFamily } from 'helpers/user';
import { IUser } from '../../types';
import { AppActionTypes } from '../actions';
import { Types } from '../actions/user';
import { Types as AdviserTypes } from '../actions/adviser-actions';

const userReducer = (state: IUser, action: AppActionTypes): IUser => {
  switch (action.type) {
    case Types.GET_USER_BY_ID_LOADED:
      const ownedFamily = getFamily(action.data.families);
      return {
        ...state,
        ...action.data,
        currentFamilyId: ownedFamily ? ownedFamily.id : '',
        loaded: true,
        loading: false,
        error: null,
      };
    case Types.UPDATE_USER_LOADED:
      return { ...state, ...action.data, loading: false };
    case Types.UPDATE_USER_LOADING:
      return { ...state, loading: true };
    case Types.SET_CURRENT_FAMILY:
      return { ...state, currentFamilyId: action.data };
    case Types.GET_USER_BY_ID_FAILED:
      return {
        ...state,
        error: action.data,
        loaded: true,
        loading: false,
      };
    case Types.GET_USER_BY_ID_LOADING:
      return { ...state, loading: true, error: null };
    case AdviserTypes.CREATE_ADVISER_CONNECT:
    case AdviserTypes.UPDATE_ADVISER_CONNECT:
      return {
        ...state,
        phone: action.data.phoneNumber || state.phone,
      };
    default:
      return state;
  }
};

export default userReducer;
