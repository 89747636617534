import {
  USER_FIELDS_BY_TABS,
  USER_FIELDS,
  ProfileTabs,
} from 'constants/user-form';
import { dateValidator, getDefaultValues } from 'helpers';

const tabs = [
  { name: ProfileTabs.PERSONAL },
  { name: ProfileTabs.FINANCES },
  { name: ProfileTabs.FAMILY },
  { name: ProfileTabs.ASSETS },
];

const advisersTab = { name: ProfileTabs.ADVISERS };

export const getTabs = (user, invitations) => {
  const allTabs = tabs.reduce((acc, t) => {
    // Hide tab if there are no questions
    if (
      hasFieldsInTab(t.name, user) ||
      (t.name === ProfileTabs.FAMILY && invitations.length > 0)
    ) {
      acc.push(t);
    }
    return acc;
  }, []);

  if (user.advisers) {
    allTabs.push(advisersTab);
  }

  return allTabs;
};

export const getDefaultFormValues = (user) => {
  const questions = Object.keys(USER_FIELDS_BY_TABS).reduce(
    (acc, k) => {
      const userFields = USER_FIELDS_BY_TABS[k];
      return [...acc, ...userFields];
    },
    [...USER_FIELDS],
  );

  return getDefaultValues(questions, user);
};

export const hasFieldsInTab = (tab, user) => {
  return R.any(
    (f) => !R.isNil(user[f.key] || user[f.altKey]),
    USER_FIELDS_BY_TABS[tab],
  );
};

export const validateProfileForm = (values) => {
  let valid = USER_FIELDS.reduce((acc, { key }) => {
    if (!values[key]) {
      return R.assoc(key, true, acc);
    }
    return acc;
  }, null);
  if (!dateValidator(values.dateOfBirth)) {
    valid = R.assoc('dateOfBirth', true, valid);
  }
  return valid;
};
