import React from 'react';
import styles from './styles/BirthdayInput.module.scss';
import BirthdayPicker from 'components/bday-picker';
import InputWrap from './InputWrap';
import { IFormFieldProps } from './common';

interface IProps {
  disabledClassName?: string;
  inputContainerClassName?: string;
}

const BirthdayInput = ({
  className,
  disabledClassName,
  inputClassName,
  inputContainerClassName,
  labelClassName,
  label,
  error,
  rightIcon,
  scrollIntoView,
  ...inputProps
}: IFormFieldProps & IProps) => {
  return (
    <InputWrap
      className={classnames(className, 'birthday')}
      errorClassName={styles.error}
      error={error}
      label={label}
      labelClassName={labelClassName}
      name={inputProps.name}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
    >
      <BirthdayPicker
        className={styles.bday}
        inputContainerClassName={classnames(
          styles.bdayInputContainer,
          inputContainerClassName,
        )}
        inputClassName={classnames(styles.input, inputClassName)}
        disabledClassName={disabledClassName}
        labelClassName={labelClassName}
        {...inputProps}
      />
    </InputWrap>
  );
};

export default BirthdayInput;
