import React from 'react';
import classnames from 'classnames';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';
import Placeholder from '../form-field/FormFieldPlaceholder';
import styles from './formFieldBordered.scss';
import DropdownInput from 'components/dropdown-input';

export interface IDropdownBorderedProps {
  options?: DropdownItemProps[];
  value: string;
  onChange?: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => void;
  required?: boolean;
  className?: string;
  placeholder?: string;
  dropdownClassName?: string;
  creatable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  multiple?: boolean;
  placeholderClassName?: string;
  inputType?: string;
  noResultsMessage?: string;
  prefix?: string;
  name: string;
  search: boolean;
}

const DropdownBordered = ({
  required,
  value,
  onChange,
  className,
  options,
  placeholder,
  dropdownClassName,
  disabled,
  loading,
  error,
  multiple,
  placeholderClassName,
  name,
  inputType,
  prefix,
  search,
  creatable,
  noResultsMessage,
}: IDropdownBorderedProps) => {
  const Component = inputType ? DropdownInput : Dropdown;
  return (
    <div
      className={classnames(styles.container, error && styles.error, className)}
    >
      <Component
        className={classnames(
          styles.dropdown,
          multiple && value.length && styles.hasMultipleValue,
          dropdownClassName,
        )}
        required={required}
        value={value || (multiple ? [] : '')}
        onChange={onChange}
        options={options}
        selection={true}
        loading={loading}
        disabled={disabled}
        multiple={multiple}
        placeholder=""
        name={name}
        prefix={prefix}
        inputType={inputType}
        search={search}
        noResultsMessage={noResultsMessage}
        creatable={creatable}
      />
      <Placeholder
        required={required}
        placeholder={placeholder}
        className={classnames(
          styles.placeholder,
          placeholderClassName,
          value && 'hasValue',
          value && styles.hasValue,
        )}
      />
    </div>
  );
};

export default DropdownBordered;
