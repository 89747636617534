import React, { useCallback } from 'react';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import Placeholder from '../form-field/FormFieldPlaceholder';
import Autocomplete from 'react-google-autocomplete';
import styles from './formFieldBordered.scss';

export interface IInputBordered {
  required?: boolean;
  value: string | number;
  onChange?: (e: React.SyntheticEvent) => void;
  className?: string;
  placeholder?: string;
  inputClassName?: string;
  inputType?: string;
  prefix?: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  bottomLabel?: any;
  label?: string;
  placeholderClassName?: string;
}

const renderLabelWithInfo = (label, infoMsg) => infoMsg ? (
  <label className={styles.infoLabel}>
    {label}
    <Popup
      content={infoMsg}
      trigger={
        <Icon
          disabled={true}
          name="question circle"
          className={styles.info}
        />
      }
      position="bottom right"
      basic={true}
    />
  </label>
) : label;

const InputBordered = ({
  required,
  value,
  onChange,
  className,
  placeholder,
  inputClassName,
  disabled,
  inputType,
  prefix,
  error,
  bottomLabel,
  name,
  label,
  placeholderClassName,
  infoMsg,
  maxLength = 256,
}) => {
  const numberOnChange = useCallback(({ floatValue }) => {
    const target = { name, value: floatValue };
    onChange({ target });
  }, [onChange]);

  const checkboxOnChange = useCallback(
    (_, { checked }) => {
      onChange(R.assocPath(['target', 'value'], checked, {}));
    },
    [onChange]
  );

  const handlePlaceSelected = useCallback(
    ({ formatted_address }) => {
      const target = { name, value: formatted_address.replace(/, New Zealand$/i, '') };
      onChange({ target });
    },
    [name, onChange]
  );

  return (
    <div
      className={classnames(styles.container, error && styles.error, className)}
    >
      <div
        className={classnames(
          styles.inputWrap,
          inputClassName,
          disabled && 'disabled'
        )}
      >
        {!!value && prefix && (
          <span
            className={classnames(styles.prefix, disabled && styles.disabled)}
          >
            {prefix}
          </span>
        )}
        {inputType === 'number' ? (
          <NumberFormat
            className={styles.input}
            onValueChange={numberOnChange}
            disabled={disabled}
            thousandSeparator=","
            value={value}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
          />
        ) : inputType === 'address' ? (
          <Autocomplete
            name={name}
            className={styles.input}
            onChange={onChange}
            onPlaceSelected={handlePlaceSelected}
            types={['address']}
            componentRestrictions={{ country: 'nz' }}
            disabled={disabled}
            value={value}
            placeholder=""
          />
        ) : inputType === 'checkbox' ? (
          <Checkbox
            className={styles.checkbox}
            label={renderLabelWithInfo(label, infoMsg)}
            onChange={checkboxOnChange}
            checked={value || false}
            disabled={disabled}
            name={name}
          />
        ) : (
          <input
            className={styles.input}
            value={value}
            onChange={onChange}
            disabled={disabled}
            type={inputType}
            name={name}
            maxLength={maxLength}
          />
        )}
        <Placeholder
          required={required}
          placeholder={placeholder}
          className={classnames(
            styles.placeholder,
            placeholderClassName,
            (value || value === 0) && styles.hasValue,
            (value || value === 0) && 'hasValue'
          )}
        />
      </div>
      {bottomLabel && <div className={styles.bottomLabel}>{bottomLabel}</div>}
    </div>
  );
};

export default InputBordered;
