import React from 'react';
import QLaptop from '-!svg-react-loader!images/q/q-laptop.svg';
import styles from './loading.scss';

const Loading = ({ full }) => {
  return (
    <div className={classnames(styles.container, full && styles.full)}>
      <QLaptop />
    </div>
  );
};

export default Loading;
export { default as LoadingSpinner } from './LoadingSpinner';
