import React from 'react';
import { Modal } from 'semantic-ui-react';
import styles from './styles/welcome.scss';
import Button from '../button';
import LogoSmall from '-!svg-react-loader!images/logo-small.svg';
import { X } from 'react-feather';

interface IProps {
  open: boolean;
  name: string;
  onClose(): void;
}

const WelcomeModal = ({ open, name, onClose }: IProps) => {
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      className={styles.modal}
      size="small"
    >
      <Modal.Content>
        <div className={styles.content}>
          <X className={styles.xBtn} onClick={onClose} size={24} />
          <LogoSmall className={styles.svg} />
          <b
            className={styles.title}
          >{`Nice to meet you, ${name}! We’re Quashed.`}</b>
          <div className={styles.subText}>
            Easily see all your insurance benefits from your workplace alongside
            all your other ones.
          </div>
          <Button className={styles.btn} text="Get started" onClick={onClose} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default WelcomeModal;
