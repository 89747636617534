import { IWindcavePaymentResult } from 'types';
import { getHeaderOptions, getHttpService } from './http-service';
import { IPolicyScanQuote } from './policy-scan-service';

const httpClient = getHttpService();

export interface ICreateSubscriptionBody {
  paymentMethodId: string;
  priceId?: string;
}

export const createMonthlySubscription = (body: ICreateSubscriptionBody) => {
  return httpClient.post(`/pay`, body, {
    headers: getHeaderOptions()
  });
};

export const payProtecta = (
  body: IPolicyScanQuote
) => {
  return httpClient.post(`/pay/protecta`, body, {
    headers: getHeaderOptions()
  });
};

export interface IVerifyTransactionBySessionId {
  sessionId: string;
}

export const verifyTransactionBySessionId = (sessionId: string) => {
  return httpClient.post(
    `/pay/protecta/verify`,
    { sessionId },
    {
      headers: getHeaderOptions()
    }
  );
};

export const getNewPurchaseUrlBySessionId = (sessionId: string) => {
  return httpClient.post<IWindcavePaymentResult>(
    `/pay/protecta/retry`,
    { sessionId },
    {
      headers: getHeaderOptions()
    }
  );
};
