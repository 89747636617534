// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3RzmTn-T6txHdjevATfAor{border-radius:5px;cursor:auto;max-width:600px;line-height:1.4}._3RzmTn-T6txHdjevATfAor>div{width:100%}._1NcplwWXkGEXhd25o-F0Vo{font-family:\"Open Sans\";font-weight:400;color:#34363e;padding:40px}._2gW8KawLmNCM68XSuenDa5{width:24px;height:24px;position:absolute;top:24px;right:24px;cursor:pointer}.a-3pT7i17IcE1UU_rBYi{width:36px;height:36px;margin-bottom:16px}.sgo2B3qWVgv3N48X8oAUt{font-size:20px;font-weight:700;margin-bottom:8px}.inae7tSeFyXP1YnGI76YE{margin-right:24px}._3L0ofeTm9kaEMe6PdbGWf5{display:flex;flex-direction:column;font-weight:600}._28ZmA24rJ3HJrRIm5Bu_nG{font-size:14px;margin-bottom:8px}._3L78VtavnAhBJQCGTJROra{font-size:14px;color:#828282;margin-bottom:40px}._3L78VtavnAhBJQCGTJROra a{color:#4187f5;text-decoration:underline}._3L78VtavnAhBJQCGTJROra a:hover{color:#4187f5;opacity:.8}.STIMBtg9r8YdJk4eJman5{display:flex;justify-content:center}._2Oy9OewHzuvl_vCiJwj0u8.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;width:100%;max-width:300px}@media(max-width: 576px){._2Oy9OewHzuvl_vCiJwj0u8.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 576px){._3RzmTn-T6txHdjevATfAor{max-width:100%}._1NcplwWXkGEXhd25o-F0Vo{padding:24px}.sgo2B3qWVgv3N48X8oAUt{font-size:16px}._3L78VtavnAhBJQCGTJROra,._28ZmA24rJ3HJrRIm5Bu_nG{font-size:12px}.a-3pT7i17IcE1UU_rBYi{margin-bottom:8px}._2Oy9OewHzuvl_vCiJwj0u8{max-width:none !important}}", ""]);
// Exports
exports.locals = {
	"toastContainer": "_3RzmTn-T6txHdjevATfAor",
	"container": "_1NcplwWXkGEXhd25o-F0Vo",
	"xBtn": "_2gW8KawLmNCM68XSuenDa5",
	"logo": "a-3pT7i17IcE1UU_rBYi",
	"header": "sgo2B3qWVgv3N48X8oAUt",
	"svg": "inae7tSeFyXP1YnGI76YE",
	"messageContainer": "_3L0ofeTm9kaEMe6PdbGWf5",
	"message": "_28ZmA24rJ3HJrRIm5Bu_nG",
	"contact": "_3L78VtavnAhBJQCGTJROra",
	"btnContainer": "STIMBtg9r8YdJk4eJman5",
	"button": "_2Oy9OewHzuvl_vCiJwj0u8"
};
module.exports = exports;
