// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3PYPErVGINTd2iZ97FG_9h{text-align:center;background-color:#4187f5;color:#fff;max-height:0;overflow:hidden;transition:all 100ms ease}._3PYPErVGINTd2iZ97FG_9h._2L766bOkG-OGZ6Zqnloqme{max-height:1000px;padding:16px 40px}._2sxwmWoq2wkP1S3XHnyMNP{position:relative;display:flex;align-items:center;justify-content:center}._2sxwmWoq2wkP1S3XHnyMNP a,._2sxwmWoq2wkP1S3XHnyMNP a:hover{color:#fff;text-decoration:underline}.C-_V4NeCeQsn0YZoQwce0{margin-right:24px;vertical-align:top}.-UNMrZQyqmsozhLPMKHBk{color:#ffd25d;fill:#ffd25d}._1yEUW-fNkexo-JCYICdBvi{margin-left:80px;min-width:20px;cursor:pointer}@media(max-width: 820px){._2sxwmWoq2wkP1S3XHnyMNP{align-items:flex-end}.C-_V4NeCeQsn0YZoQwce0{margin-right:8px}._1yEUW-fNkexo-JCYICdBvi{margin-left:8px}}@media(max-width: 576px){._3PYPErVGINTd2iZ97FG_9h._2L766bOkG-OGZ6Zqnloqme{padding:16px 40px}}", ""]);
// Exports
exports.locals = {
	"container": "_3PYPErVGINTd2iZ97FG_9h",
	"open": "_2L766bOkG-OGZ6Zqnloqme",
	"content": "_2sxwmWoq2wkP1S3XHnyMNP",
	"leftIcon": "C-_V4NeCeQsn0YZoQwce0",
	"star": "-UNMrZQyqmsozhLPMKHBk",
	"closeIcon": "_1yEUW-fNkexo-JCYICdBvi"
};
module.exports = exports;
