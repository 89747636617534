import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

// Same as Controller from react-hook-forms but unregister props on unmount while keeping the value
// Un-registering the components will remove the validation from that component
// so form will not throw errors while question is not there
const FormEngineController = (props) => {
  useEffect(() => {
    return () => {
      props.control.unregister(props.name, { keepValue: true });
    };
  }, []);

  return <Controller {...props} />;
};

export default FormEngineController;
