import React from 'react';
import styles from './styles/Radio.module.scss';
import InputWrap from './InputWrap';
import { IFormFieldProps } from './common';

export interface IProps extends IFormFieldProps {
  isButton?: boolean;
  options: any[];
}

const RadioGroup = ({
  isButton,
  label,
  className,
  labelClassName,
  inputClassName,
  rightIcon,
  error,
  disabled,
  options,
  value,
  onChange,
  name,
  scrollIntoView,
  tooltip,
}: IProps) => {
  return (
    <InputWrap
      className={className}
      errorClassName={styles.error}
      error={error}
      label={label}
      labelClassName={labelClassName}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
      tooltip={tooltip}
    >
      <div
        className={classnames(
          'radioGroup',
          styles.radioGroup,
          isButton && styles.buttons,
        )}
      >
        {options.map(({ text, value: optValue }, idx) => (
          <label
            className={classnames(
              styles.radio,
              value === optValue && styles.checked,
              inputClassName,
            )}
            key={`${idx}_${text}`}
          >
            <input
              type="radio"
              name={name}
              onChange={onChange}
              value={optValue}
              checked={value === optValue}
              disabled={disabled}
            />
            {text && <span className={styles.label}>{text}</span>}
          </label>
        ))}
      </div>
    </InputWrap>
  );
};

export default RadioGroup;
