// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1KuD9Bq-j_kqoVdHioEBfK{position:absolute;top:0;left:0;transform:translate3d(0, 0, 0);display:flex;width:100%;overflow:hidden}._12gtnsr8Ba8BlLK4mRgR24{height:100%;min-width:150%;transform:translate3d(0, 0, 0);display:flex;align-items:center;justify-content:space-around;animation:_1Iv0Te_S4R1oSYS1O8hJgZ 5s linear infinite}._12gtnsr8Ba8BlLK4mRgR24>img{height:auto;width:auto}@keyframes _1Iv0Te_S4R1oSYS1O8hJgZ{0%{transform:translateX(0%)}100%{transform:translateX(-100%)}}", ""]);
// Exports
exports.locals = {
	"slideshow": "_1KuD9Bq-j_kqoVdHioEBfK",
	"move": "_12gtnsr8Ba8BlLK4mRgR24",
	"moveSlideshow": "_1Iv0Te_S4R1oSYS1O8hJgZ"
};
module.exports = exports;
