import {
  ADMIN_ROUTE,
  EXPLORE_CC_ROUTE,
  EXPLORE_DISCOVER_POLICY_SELECT_ROUTE,
  EXPLORE_ADVISER,
  HOME_ROUTE,
  MARKET_SCAN_BROKER_DEFAULT_ROUTE,
  MARKET_SCAN_NEW_DEFAULT_ROUTE,
  MARKET_SCAN_RESULTS_ROUTE,
  MARKET_SCAN_ROUTE,
  OPERATIONS_ROUTE,
  EXPLORE_COVERAGE_ROUTE,
  SHARE_ROUTE,
  ADD_POLICY_ROUTE,
  EDIT_POLICY_ROUTE,
  WORKPLACE_ROUTE,
  PROFILE_ROUTE,
  WINDCAVE_CALLBACK_BASE_ROUTE,
  NOTIFICATIONS_ROUTE,
  PREMIUM_ROUTE,
  CHECKOUT_ROUTE,
} from 'constants/routes';

const exactMap = {
  [HOME_ROUTE]: 'My Dashboard',
  [MARKET_SCAN_NEW_DEFAULT_ROUTE]: 'Market Scan',
  [MARKET_SCAN_RESULTS_ROUTE]: 'Market Scan results',
  [EXPLORE_CC_ROUTE]: 'Credit card benefits',
  [EXPLORE_ADVISER]: 'Get advice',
  [EXPLORE_COVERAGE_ROUTE]: 'Insurance checkup',
  [SHARE_ROUTE]: 'Shared dashboard',
  [ADD_POLICY_ROUTE]: 'Add policy',
  [EDIT_POLICY_ROUTE]: 'Edit policy',
  [WORKPLACE_ROUTE]: 'Add workplace',
  [PROFILE_ROUTE]: 'Profile',
  [NOTIFICATIONS_ROUTE]: 'My Notifications',
  [CHECKOUT_ROUTE]: 'Quashed Premium',
};

const includesMap = [
  {
    route: EXPLORE_DISCOVER_POLICY_SELECT_ROUTE,
    name: 'Explore insurance',
  },
  {
    route: WINDCAVE_CALLBACK_BASE_ROUTE,
    name: 'Market Scan',
  },
  {
    route: MARKET_SCAN_ROUTE,
    name: 'Market Scan',
  },
  {
    route: ADMIN_ROUTE,
    name: 'Admin',
  },
  {
    route: OPERATIONS_ROUTE,
    name: 'Operations',
  },
  {
    route: PREMIUM_ROUTE,
    name: 'Quashed Premium',
  },
  {
    route: '/renewal',
    name: 'Renew policy',
  },
  {
    route: '/policy/',
    name: 'Policy details',
  },
];

export const getPageName = (pathname) => {
  const exact = exactMap[pathname];
  if (exact) {
    return exact;
  }

  const included = includesMap.find((m) => pathname.includes(m.route));
  return included?.name || 'None';
};
