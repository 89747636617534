import moment from "moment";

const monthValueMap = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

const pad = (val) => String(val).padStart(2, '0');

const getPlaceholder = (text) => (
  <option key={text} value="" disabled={true}>
    {text}
  </option>
);

export const getDayOptions = () => {
  const options = [...Array(31)].map((_, idx) => {
    const val = pad(idx + 1);
    return (
      <option key={idx} value={val}>
        {val}
      </option>
    );
  });
  return R.prepend(getPlaceholder('DD'), options);
};

export const getMonthOptions = () => {
  const options = [...Array(12)].map((_, idx) => {
    const val = pad(idx + 1);
    return (
      <option key={idx} value={val}>
        {val}
      </option>
    );
  });
  return R.prepend(getPlaceholder('MM'), options);
};

export const getYearOptions = (max) => {
  const options = [...Array(100)].map((_, idx) => {
    const val = max - idx;
    return (
      <option key={idx} value={val}>
        {val}
      </option>
    );
  });
  return R.prepend(getPlaceholder('YYYY'), options);
};

export const getValue = (value) => {
  if (!value) {
    return {
      month: '',
      day: '',
      year: '',
    };
  }

  const date = moment(value);
  return {
    month: date.format('MM'),
    day: date.format('DD'),
    year: date.format('YYYY'),
  };
};

export const convertStateToDate = ({ day, month, year }) => {
  return moment(`${day}/${month}/${year}`, 'DD/MM/YYYY', true)
    .toISOString();
};

export const getDisabledValue = ({ day, month, year }) => {
  if (!day || !month || !year) {
    return '';
  }
  return `${pad(day)}/${pad(month)}/${year}`;
};