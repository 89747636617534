import { ILayoutState } from '../../types';
import { ActionTypes, Types } from '../actions/layout-actions';

const layoutReducer = (
  state: ILayoutState,
  action: ActionTypes,
): ILayoutState => {
  switch (action.type) {
    case Types.TOGGLE_MAXIMISED:
      return { ...state, maximised: action.data };
    case Types.TOGGLE_SIDEBAR:
      return { ...state, expanded: action.data };
    default:
      return state;
  }
};

export default layoutReducer;
