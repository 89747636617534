import { getHeaderOptions, getHttpServiceExternal } from './http-service';

const externalHttpClient = getHttpServiceExternal();

interface IReqBody {
  age: number;
  insuredAmount: number;
}

export const postQuickQuote = (body: IReqBody) => {
  return externalHttpClient.post('/quote', body, {
    headers: getHeaderOptions(),
  });
};
