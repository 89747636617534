import { WarningMessage } from 'components/form-message';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/TextInput.module.scss';

const NumberInput = (
  {
    label,
    labelAfter,
    className,
    decimalScale = 0,
    disabled,
    error,
    fixedDecimalScale = true,
    inputClassName,
    labelClassName,
    name,
    placeholder,
    prefix,
    rightIcon,
    suffix,
    thousandSeparator = ',',
    value,
    basic,
    max,
    tooltip,
    tooltipProps,
    castAs,
    allowLeadingZeros,
    scrollIntoView,
    maxLength,
    onChange,
    full,
    warning,
  }: NumberFormatProps & Partial<IFormFieldProps>,
  ref: React.Ref<HTMLInputElement>,
) => {
  const numberOnChange = ({ floatValue, value: stringValue }, { source }) => {
    // To ensure onChange will not be called on prop change
    if (source === 'event' && onChange) {
      const val = castAs === 'string' ? stringValue : floatValue;
      onChange({
        target: {
          name,
          value: R.isNil(val) ? '' : val,
        },
      });
    }
  };

  const limitAllowed = ({ floatValue }) => R.isNil(max) || floatValue <= max;

  return (
    // styles.full intended only for address, only need flex-diretion col
    <InputWrap
      className={classnames(className, full && styles.full)}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      error={error}
      label={label}
      labelAfter={labelAfter}
      labelClassName={labelClassName}
      name={name}
      rightIcon={rightIcon}
      tooltip={tooltip}
      tooltipProps={tooltipProps}
      scrollIntoView={scrollIntoView}
    >
      <div className={styles.inputContainer}>
        {/* @ts-ignore ts(2607) */}
        <NumberFormat
          id={name}
          getInputRef={ref}
          className={classnames(
            styles.input,
            basic && styles.basic,
            inputClassName,
          )}
          onValueChange={numberOnChange}
          allowNegative={false}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          prefix={prefix}
          suffix={suffix}
          isAllowed={limitAllowed}
          allowLeadingZeros={allowLeadingZeros}
          maxLength={maxLength}
        />
      </div>

      {warning && (
        <WarningMessage
          text={warning}
          className={classnames('warningText', styles.warning)}
        />
      )}
    </InputWrap>
  );
};

export default React.forwardRef(NumberInput);
