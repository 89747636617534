// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3bklhvuoy34ogdurt3KsM3{font-family:\"Open Sans\";font-weight:400;color:#34363e;height:calc(100vh - 120px)}._3bklhvuoy34ogdurt3KsM3.ui.modal{border-radius:5px}._3bklhvuoy34ogdurt3KsM3.ui.modal > .content{display:flex;flex-direction:column;height:100%;padding:40px}.dL9wna96VD7Y1cfEBYrSd{border:none;width:100%;height:100%;margin-top:24px;margin-bottom:24px;padding:0 24px}._3G71k97c40eDcx4aBFKILh{border:1px solid #c7c7c7;border-radius:5px;padding:16px 24px}._3G71k97c40eDcx4aBFKILh .ui.checkbox{display:block}._3G71k97c40eDcx4aBFKILh .ui.checkbox a{font-family:\"DM Sans\";font-weight:700;font-size:14px;text-decoration:underline;color:#34363e}._3G71k97c40eDcx4aBFKILh .ui.checkbox:not(:last-child){margin-bottom:16px}._1f1o9ttHc27FkGhBkquRjF{text-align:right}._3RD0JdAqXF4csSTnyu5EsA.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#34363e;margin-top:24px}@media(max-width: 576px){._3RD0JdAqXF4csSTnyu5EsA.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._344nVu60oP0rg59jkUeCrL{font-family:\"DM Sans\";font-weight:700;font-size:14px;text-align:center;border-bottom:solid 1px #c7c7c7;padding-bottom:24px}._2vBem_uJleO5BJETnRrPYS{font-size:18px;margin-bottom:16px}._2rx-HC8HZQGtbTOGXKotrg{font-size:24px;color:#4187f5}@media(max-width: 820px){._3bklhvuoy34ogdurt3KsM3.ui.modal > .content{padding:24px 40px !important}._344nVu60oP0rg59jkUeCrL{padding-bottom:16px}._2vBem_uJleO5BJETnRrPYS{font-size:14px;margin-bottom:8px}._2rx-HC8HZQGtbTOGXKotrg{font-size:18px;color:#4187f5}}@media(max-width: 576px){._3bklhvuoy34ogdurt3KsM3{height:100vh}._3bklhvuoy34ogdurt3KsM3.ui.modal > .content{padding:24px !important}.dL9wna96VD7Y1cfEBYrSd{padding:0}}", ""]);
// Exports
exports.locals = {
	"modal": "_3bklhvuoy34ogdurt3KsM3",
	"iframe": "dL9wna96VD7Y1cfEBYrSd",
	"checkboxes": "_3G71k97c40eDcx4aBFKILh",
	"buttonContainer": "_1f1o9ttHc27FkGhBkquRjF",
	"submit": "_3RD0JdAqXF4csSTnyu5EsA",
	"header": "_344nVu60oP0rg59jkUeCrL",
	"brand": "_2vBem_uJleO5BJETnRrPYS",
	"title": "_2rx-HC8HZQGtbTOGXKotrg"
};
module.exports = exports;
