import React from 'react';
import { Popup } from 'semantic-ui-react';
import { Icon } from 'react-feather';
import styles from './styles/iconTooltip.scss';

interface IProps {
  className?: string;
  href?: string;
  content: string;
  icon: Icon;
  onClick?(e): void;
  target?: string;
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center';
}

const IconTooltip = ({
  className,
  content,
  href,
  icon: IconComponent,
  onClick,
  target,
  position,
}: IProps) => {
  const handleClick = (e) => {
    // Stop parent click event
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Popup
      trigger={
        <a
          className={classnames(styles.button, className)}
          href={href}
          onClick={handleClick}
          target={target}
        >
          <IconComponent size={20} />
        </a>
      }
      position={position ? position : 'top center'}
      content={content}
    />
  );
};

export default IconTooltip;
