import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import AccordionList from 'components/accordion-list';
import Button from 'components/button';
import ButtonToolbar from 'components/button-toolbar';
import FormField from 'components/form-field';
import PasswordReset from 'components/password-reset';
import {
  ADVISER_USER_FIELDS,
  ProfileTabs,
  USER_FAMILY_FIELDS,
  USER_FIELDS_BY_TABS,
} from 'constants/user-form';
import {
  getAdviser,
  hasEmployer,
  hasFeature,
  isBreakpoint,
  isDropdownField,
  mapEmployerOptions,
} from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';
import styles from './styles/userTabs.scss';
import { getTabs } from './utils';
import { IUseForm } from 'hooks/useForm';
import { IInvitation, IEmployer, IUser, IConfig } from 'types';
import {
  PREMIUM_ROUTE,
  EXPLORE_COVERAGE_ROUTE,
  WORKPLACE_ROUTE,
} from 'constants/routes';
import QCoolGuy from '-!svg-react-loader!images/q/q-cool.svg';
import { Features } from 'constants/features';
import { useLocation } from 'react-router-dom';
import { IconTooltip } from 'components/tooltip';
import { ThumbsUp } from 'react-feather';

interface IUserTabsProps {
  config: IConfig;
  employers: IEmployer[];
  form: IUseForm;
  invitations: IInvitation[];
  user: IUser;
  isEdit: boolean;
  isAuth0User: boolean;
  errors: { [key: string]: boolean };
  onCancel(): void;
  onEdit(e): void;
  toggleAdviserReferral(open: boolean): void;
}

const exploreCoverageRoute = {
  pathname: EXPLORE_COVERAGE_ROUTE,
  state: {
    fromProfilePage: true,
  },
};

const UserCoverage = ({ user }) => {
  return (
    <div className={styles.coverageContainer}>
      <QCoolGuy className={styles.qCoolGuy} />
      <div className={styles.bold}>
        {`We dont know anything about you yet ${user.firstName}!`}
      </div>
      <div className={styles.description}>
        Help us get to you know you better by filling out our coverage checker
        form real quick. Your information helps us create a more personal
        experience for you on the platform and to make buying and managing your
        insurance much easier.
      </div>
      <Button
        className={styles.getStartedBtn}
        link={exploreCoverageRoute}
        text="Get started"
      />
    </div>
  );
};

const EmployerField = ({
  employers,
  form,
  field,
  disabled,
  value,
  showAdviserButton,
}) => {
  const [openModal, setOpen] = useState(false);
  const isDropdown = !!form.values[field.key];
  const options = mapEmployerOptions(employers);

  const handleRemove = useCallback(() => {
    form.setValues((prevValues) =>
      R.merge(prevValues, { employerId: undefined, employerName: undefined }),
    );
    setOpen(false);
  }, [form.setValues]);

  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  return (
    <div className={classnames(styles.field, styles.employer)}>
      <FormField
        key={field.key}
        name={isDropdown ? 'employer' : field.altKey}
        className={classnames(styles.employerField)}
        labelClassName={styles.label}
        inputClassName={styles.input}
        onChange={
          isDropdown ? form.handleDropdownIdNameChange : form.handleInputChange
        }
        value={value}
        disabled={disabled || isDropdown}
        options={options}
        type={isDropdown ? 'dropdown' : 'text'}
        {...field}
      />
      {!disabled && isDropdown && (
        <Modal
          className={styles.modal}
          open={openModal}
          onClose={handleClose}
          onOpen={handleOpen}
          trigger={<div className={styles.toggle}>Remove</div>}
          size="small"
        >
          <Modal.Content>
            <div className={styles.bold}>Removing your workplace</div>
            <div>
              This means you will no longer see your workplace insurance
              benefits, please check and update your policies or talk to an
              adviser if you want to explore your insurance options after
              leaving your workplace.
            </div>
            <div className={styles.employerModalButtons}>
              <Button
                className={styles.updateWorkBtn}
                text="Remove"
                onClick={handleRemove}
              />
              {showAdviserButton && (
                <Button
                  className={styles.findAdviserBtn}
                  text="Find an adviser"
                  link={PREMIUM_ROUTE}
                />
              )}
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

const AccordionTitle = ({ item, fields }) => {
  return (
    <div>
      {fields.filter(R.prop('mobile')).map(({ key, getValue }) => (
        <div key={key}>{getValue ? getValue(item) : item[key]}</div>
      ))}
    </div>
  );
};

const AccordionContent = (props) => {
  const { item, fields, buttonComponent: ButtonComponent } = props;
  return fields.map((f, idx) => {
    const value = f.getValue ? f.getValue(item) : item[f.key];
    return (
      idx > 1 && (
        <>
          <div key={f.key}>
            <div className={styles.label}>{f.label}</div>
            {f.link ? (
              <a href={f.getLink(item)}>{value}</a>
            ) : (
              <div>{value}</div>
            )}
          </div>
          {fields.length - 1 === idx && ButtonComponent && (
            <ButtonComponent className={styles.mobile} {...props} />
          )}
        </>
      )
    );
  });
};

const AdviserButton = ({ className, toggleAdviserReferral }) => {
  const handleReferClick = (e) => {
    e.preventDefault();
    toggleAdviserReferral(true);
  };

  return (
    <IconTooltip
      className={classnames(styles.adviserButton, className)}
      icon={ThumbsUp}
      onClick={handleReferClick}
      content="Refer your adviser"
    />
  );
};

const UserTable = (props) => {
  const {
    data,
    fields,
    toggleAdviserReferral,
    buttonComponent: ButtonComponent,
  } = props;
  useBreakpoint();
  const isSmall = isBreakpoint('md');

  const headerFields = useMemo(() => {
    const headers = isSmall ? fields.filter(R.prop('mobile')) : fields;
    return headers.map((f) => <div key={f.key}>{f.label}</div>);
  }, [fields, isSmall]);

  return (
    <div className={styles.family}>
      <div className={styles.familyHeader}>{headerFields}</div>
      {isSmall ? (
        <AccordionList
          items={data}
          componentProps={{
            fields,
            buttonComponent: ButtonComponent,
            toggleAdviserReferral,
          }}
          titleComponent={AccordionTitle}
          contentComponent={AccordionContent}
          titleIcon="chevron down"
          titleClassName={styles.familyTitle}
          contentClassName={styles.familyContent}
        />
      ) : (
        data.map((item) => (
          <div key={item.id} className={styles.familyRow}>
            {fields.map((f, idx) => {
              const value = f.getValue ? f.getValue(item) : item[f.key];
              return f.link ? (
                <a key={f.key} href={f.getLink(item)}>
                  {value}
                  {fields.length - 1 === idx && ButtonComponent && (
                    <ButtonComponent {...props} />
                  )}
                </a>
              ) : (
                <div key={f.key}>{value}</div>
              );
            })}
          </div>
        ))
      )}
    </div>
  );
};

const getDefaultTab = (currentTabs) => {
  if (currentTabs.length === 1) {
    return currentTabs[0].name;
  }
  return ProfileTabs.PERSONAL;
};

const UserTabs = ({
  config,
  employers,
  form,
  invitations,
  user,
  isEdit,
  isAuth0User,
  errors,
  onCancel,
  onEdit,
  toggleAdviserReferral,
}: IUserTabsProps) => {
  const { state = {} } = useLocation();
  const tabs = useMemo(() => getTabs(user, invitations), [invitations, user]);
  const [tab, setTab] = useState(state.tab || getDefaultTab(tabs));

  const adviser = useMemo(() => getAdviser(user.advisers, user.createdBy), [
    user.advisers,
    user.createdBy,
  ]);
  const fields = USER_FIELDS_BY_TABS[tab];
  const isEmployed = hasEmployer(user);
  const showCoverage = !user.coverageCompleted && !adviser;
  const showTabs = !!(user.coverageCompleted || isEmployed || adviser);
  const showWorkplaceBtn = hasFeature(config, Features.Q4E);

  const handleTabClick = useCallback(({ name }) => setTab(name), []);

  return (
    <>
      {showTabs && (
        <div className={styles.extraContainer}>
          <ButtonToolbar
            items={tabs}
            active={tab}
            onClick={handleTabClick}
            className={styles.tabs}
            itemClassName={styles.tab}
            responsive={true}
          />
          <div
            className={classnames(styles.content, showCoverage && styles.small)}
          >
            {tab === ProfileTabs.FAMILY ? (
              <UserTable data={invitations} fields={USER_FAMILY_FIELDS} />
            ) : tab === ProfileTabs.ADVISERS ? (
              <UserTable
                data={user.advisers}
                fields={ADVISER_USER_FIELDS}
                toggleAdviserReferral={toggleAdviserReferral}
                buttonComponent={AdviserButton}
              />
            ) : (
              <>
                {fields.map((field) => {
                  const value =
                    form.values[field.key] || form.values[field.altKey];

                  // Check if user has the field so the input wont be hidden when we empty it
                  if (R.isNil(user[field.key] || user[field.altKey])) {
                    return null;
                  }

                  if (field.key === 'employerId') {
                    return (
                      <EmployerField
                        key={field.key}
                        field={field}
                        form={form}
                        disabled={!isEdit}
                        value={value}
                        employers={employers}
                        showAdviserButton={!user.adviserOrgId}
                      />
                    );
                  }

                  const fieldClassName = classnames(
                    styles.field,
                    field.fullWidth && styles.fullField,
                    field.type === 'birthday' && styles.bday,
                  );

                  return (
                    <FormField
                      key={field.key}
                      name={field.key}
                      className={fieldClassName}
                      labelClassName={styles.label}
                      inputClassName={styles.input}
                      onChange={
                        isDropdownField(field.type)
                          ? form.handleDropdownChange
                          : form.handleInputChange
                      }
                      value={form.values[field.key]}
                      disabled={!isEdit}
                      error={R.propOr(false, field.key, errors)}
                      {...field}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
      {showCoverage && <UserCoverage user={user} />}
      <div className={styles.buttons}>
        {isAuth0User && (
          <PasswordReset className={styles.reset} email={user.email} />
        )}
        <div className={styles.innerButtons}>
          {showWorkplaceBtn && !isEdit && (
            <Button
              className={styles.updateWorkBtn}
              type="button"
              text={`${isEmployed ? 'Update' : 'Add'} workplace`}
              link={WORKPLACE_ROUTE}
            />
          )}
          {!R.includes(tab, [ProfileTabs.FAMILY, ProfileTabs.ADVISERS]) &&
            (isEdit ? (
              <div className={styles.submitWrap}>
                <Button className={styles.submit} type="submit" text="Save" />
                <Button
                  className={styles.cancel}
                  type="button"
                  text="Cancel"
                  onClick={onCancel}
                />
              </div>
            ) : (
              <div className={styles.submitWrap}>
                {showTabs && (
                  <Button
                    className={styles.submit}
                    type="button"
                    text="Edit"
                    onClick={onEdit}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default UserTabs;
