import { IReferenceData } from '../../types';
import { AppActionTypes } from '../actions';
import { sortByObjectKeyAscending } from '../../helpers';
import { Types } from 'actions/referencedata-actions';

const referenceDataReducer = (
  state: IReferenceData,
  action: AppActionTypes,
): IReferenceData => {
  switch (action.type) {
    case Types.GET_CREDIT_CARD_BENEFITS_LOADED:
      return { ...state, creditCards: action.data };
    case Types.GET_ALL_POLICY_PROVIDERS_LOADED:
      return {
        ...state,
        policyProviders: sortByObjectKeyAscending('name')(action.data),
      };
    case Types.GET_ALL_POLICY_TYPES_LOADED:
      return {
        ...state,
        policyTypes: sortByObjectKeyAscending('name')(action.data),
      };
    case Types.GET_ALL_POLICY_SUB_TYPES_LOADED:
      return {
        ...state,
        policySubTypes: sortByObjectKeyAscending('name')(action.data),
      };
    case Types.CREATE_POLICY_TYPE_LOADED:
      const newTypes = [...state.policyTypes, action.data];
      return {
        ...state,
        policyTypes: sortByObjectKeyAscending('name')(newTypes),
      };
    case Types.CREATE_POLICY_SUB_TYPE_LOADED:
      const newSubTypes = [...state.policySubTypes, action.data];
      return {
        ...state,
        policySubTypes: sortByObjectKeyAscending('name')(newSubTypes),
      };
    case Types.GET_EMPLOYERS_LOADED:
      return {
        ...state,
        employers: sortByObjectKeyAscending('name')(action.data),
      };
    case Types.GET_WIKI_LOADED:
      return {
        ...state,
        wiki: action.data,
      };
    case Types.GET_POLICY_SCAN_REFERENCE_LOADED:
      return {
        ...state,
        policyScan: action.data,
      };
    case Types.GET_INURANCE_CHECKUP_REFERENCE_LOADED:
      return {
        ...state,
        insuranceCheckup: action.data,
      };
    default:
      return state;
  }
};

export default referenceDataReducer;
