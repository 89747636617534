import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AskQuestion from 'components/ask-question';
import usePager from 'hooks/usePager';
import PolicyCounter from './pages/PolicyCounter';
import UnderwritingForm from './pages/UnderwritingForm';
import ContactForm from './pages/ContactForm';
import PremiumIntro from './pages/PremiumIntro';
import styles from './styles/PremiumContainer.module.scss';
import {
  getCalendlyEventUrl,
  isCalendlyScheduledEvent,
  showMarketScanResultsPage,
  trackPremiumAddPolicy,
  trackPremiumEngagementCreated,
  trackPremiumEngagementUpdated,
  trackPremiumUploads,
} from 'helpers';
import AddPolicyPage from './pages/AddPolicyPage';
import {
  CreateAdviserConnect,
  CreateAdviserConnectPolicy,
  GetAdviserConnectByIdSk,
  UpdateAdviserConnect,
  UpdateAdviserConnectPolicy,
  UploadAdviserUserConnectAttachments,
} from 'actions';
import Loading from 'components/loading';
import { IAdviserConnect } from 'types';
import MarketScanForms from './pages/MarketScanForms';
import Stepper from 'components/stepper';
import MarketScanResults from './pages/MarketScanResults';
import { showAddPolicyPage, showMarketScanFormsPage } from './utils';
import { updateAdviserConnectUser } from 'services';

const StartFlow = {
  0: PremiumIntro,
  1: PolicyCounter,
  2: UnderwritingForm,
  3: ContactForm,
};

const initialState = {
  policyCount: {},
  underwritingQuestions: {},
  phone: '',
  contactMethod: null,
  notes: '',
};

const PremiumContainer = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const {
    adviser,
    policies: { policies },
    referenceData,
    user,
  } = useSelector(R.pick(['adviser', 'policies', 'user', 'referenceData']));
  const [openModal, setOpenModal] = useState(!user.premium);
  const { active, next, prev } = usePager(user.premium ? 1 : 0);
  const { premiumAdviserConnect: adviserConnect } = adviser;

  useEffect(() => {
    if (adviserConnect?.phoneNumber) {
      setState(R.assoc('phone', adviserConnect.phoneNumber));
    }
  }, [adviserConnect?.phoneNumber]);

  const PageComponent = StartFlow[active];

  const updatePolicyCount = (policyType, count) => {
    const value = R.max(count, 0);
    setState(R.assocPath(['policyCount', policyType], value));
  };

  const updateUnderwritingQuestions = (data) => {
    setState(R.assoc('underwritingQuestions', data));
  };

  const increasePolicyCount = (policyType) => {
    const count = R.path(['policyCount', policyType], state) || 0;
    updatePolicyCount(policyType, count + 1);
  };

  const decreasePolicyCount = (policyType) => {
    const count = R.path(['policyCount', policyType], state) || 0;
    updatePolicyCount(policyType, count - 1);
  };

  const updateContactMethod = (method: string) => {
    setState(R.assoc('contactMethod', method));
  };

  const updatePhone = (phone: string) => {
    setState(R.assoc('phone', phone));
  };

  const updateNotes = (notes: string) => {
    setState(R.assoc('notes', notes));
  };

  const submitAdviserConnect = () => {
    const payload = {
      name: `${user.firstName} ${user.lastName}`,
      message: state.notes,
      phoneNumber: state.phone,
      policies: state.policyCount,
      underwritingQuestions: state.underwritingQuestions,
    };

    trackPremiumEngagementCreated(payload);
    dispatch(CreateAdviserConnect(payload));
  };

  const updateAdviserConnect = (updated: Partial<IAdviserConnect>) => {
    trackPremiumEngagementUpdated(updated);
    dispatch(
      UpdateAdviserConnect({
        ...adviserConnect,
        ...updated,
      }),
    );
  };

  const submitCalendly = (e) => {
    if (isCalendlyScheduledEvent(e)) {
      const payload = {
        name: `${user.firstName} ${user.lastName}`,
        message: state.notes,
        eventUrl: getCalendlyEventUrl(e),
        policies: state.policyCount,
        underwritingQuestions: state.underwritingQuestions,
      };
      trackPremiumEngagementCreated(payload);
      dispatch(CreateAdviserConnect(payload));
    }
  };

  const handleUpload = (files) => {
    if (files.length) {
      trackPremiumUploads({
        id: adviserConnect.id,
        sk: adviserConnect.sk,
        files: files.map(R.prop('name')),
      });
      dispatch(
        UploadAdviserUserConnectAttachments(
          adviserConnect.id,
          adviserConnect.sk,
          files,
        ),
      );
    }
  };

  const handleManualAdd = (policy) => {
    const payload = {
      ...policy,
      adviserUserConnectId: adviserConnect.id,
      processed: true,
      userId: user.id,
      familyId: user.currentFamilyId,
      hide: true, // We hide policy from dashboard until engagement is complete
    };
    trackPremiumAddPolicy(payload);
    dispatch(
      CreateAdviserConnectPolicy(adviserConnect.id, adviserConnect.sk, payload),
    );
  };

  const handleManualEdit = (policy) => {
    trackPremiumAddPolicy(policy);
    dispatch(
      UpdateAdviserConnectPolicy(adviserConnect.id, adviserConnect.sk, policy),
    );
  };

  const handleMarketScanComplete = () => {
    dispatch(GetAdviserConnectByIdSk(adviserConnect.id, adviserConnect.sk));
  };

  /**
   * Increment adviser connect policy scan count if there will
   * be more policy scans than the count after adding a new quote
   */
  const beforeMarketScanSubmit = async (data) => {
    const count = R.pathOr(
      0,
      ['policyScans', data.policyType, 'count'],
      adviserConnect,
    );
    const scans = R.pathOr(
      [],
      ['policyScans', data.policyType, 'items'],
      adviserConnect,
    );

    // Don't update scan count when we're saving draft
    if (!data.id && count < scans.length + 1) {
      await updateAdviserConnectUser({
        id: adviserConnect.id,
        sk: adviserConnect.sk,
        policyScans: {
          ...adviserConnect.policyScans,
          [data.policyType]: {
            count: count + 1,
          },
        },
      });
    }

    return null;
  };

  if (adviser.adviserConnects === null) {
    return <Loading />;
  }

  if (showMarketScanResultsPage(adviserConnect?.meetingStatus)) {
    return (
      <>
        <MarketScanResults
          adviserConnect={adviserConnect}
          referenceData={referenceData}
        />
        <AskQuestion />
      </>
    );
  }

  if (showMarketScanFormsPage(adviserConnect?.meetingStatus)) {
    return (
      <MarketScanForms
        adviserConnect={adviserConnect}
        policies={policies}
        referenceData={referenceData}
        user={user}
        beforeMarketScanSubmit={beforeMarketScanSubmit}
        onMarketScanComplete={handleMarketScanComplete}
      />
    );
  }

  if (showAddPolicyPage(adviserConnect?.meetingStatus)) {
    return (
      <AddPolicyPage
        {...state}
        loading={adviser.loading}
        meeting={R.pathOr(null, ['calendlyEvent', 'resource'], adviserConnect)}
        adviserConnect={adviserConnect}
        referenceData={referenceData}
        updateAdviserConnect={updateAdviserConnect}
        uploadAttachments={handleUpload}
        addPolicy={handleManualAdd}
        editPolicy={handleManualEdit}
        updatePhone={updatePhone}
      />
    );
  }

  return (
    <div
      className={classnames(
        active === 0 ? styles.introContainer : styles.container,
      )}
    >
      <Stepper
        className={styles.stepper}
        steps={['Select policies', 'Your details', 'Book a call']}
        currentStep={active - 1}
        leftLabel={
          <>
            <b>3 easy steps</b> to connect to our adviser
          </>
        }
      />
      <PageComponent
        {...state}
        loading={adviser.loading}
        openModal={openModal}
        user={user}
        decreasePolicyCount={decreasePolicyCount}
        increasePolicyCount={increasePolicyCount}
        next={next}
        prev={prev}
        setOpenModal={setOpenModal}
        submitAdviserConnect={submitAdviserConnect}
        submitCalendly={submitCalendly}
        updateAdviserConnect={updateAdviserConnect}
        updateContactMethod={updateContactMethod}
        updateNotes={updateNotes}
        updatePhone={updatePhone}
        updateUnderwritingQuestions={updateUnderwritingQuestions}
      />
    </div>
  );
};

export default PremiumContainer;
