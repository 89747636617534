import React, { useCallback } from 'react';
import { trackEvent } from 'helpers';
import styles from './styles/Button.module.scss';
import { Link } from 'react-router-dom';

export interface IProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  inverted?: boolean;
  children?: React.ReactNode;
  link?: any;
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  eventName?: string;
  eventData?: any;
  onClick?: (e?: any) => void;
}

const Button = ({
  className,
  disabled,
  children,
  eventName,
  eventData = {},
  id,
  inverted,
  link,
  text,
  type,
  onClick,
}: IProps) => {
  const handleClick = useCallback(
    (e) => {
      if (eventName) {
        trackEvent(eventName, eventData);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [eventName, eventData, onClick],
  );

  if (link) {
    return (
      <Link
        id={id}
        className={classnames(styles.link, className)}
        to={link}
        onClick={onClick}
      >
        {text || children}
      </Link>
    );
  }

  return (
    <button
      id={id}
      disabled={disabled}
      className={classnames(
        styles.button,
        inverted && styles.inverted,
        className,
      )}
      onClick={handleClick}
      type={type}
    >
      {text || children}
    </button>
  );
};

export default Button;
