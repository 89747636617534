// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2daS0OihYV5j8d-2p0kxKK.ui.modal{max-width:600px;font-family:\"Open Sans\";font-weight:700;font-size:18px;font-size:14px;line-height:1.4;color:#34363e}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions{margin-top:16px}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.content{display:none}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions .ui.button.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;background-color:#4187f5 !important;margin-bottom:0}@media(max-width: 576px){._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions .ui.button.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions .ui.button.ui.button:first-child{margin-left:16px}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions .ui.button.ui.button:last-child{margin-left:0}@media(max-width: 576px){._2daS0OihYV5j8d-2p0kxKK.ui.modal{padding:24px}._2daS0OihYV5j8d-2p0kxKK.ui.modal>.actions{padding:0 !important}}", ""]);
// Exports
exports.locals = {
	"confirm": "_2daS0OihYV5j8d-2p0kxKK"
};
module.exports = exports;
