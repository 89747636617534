import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ICreatePolicyProps,
  IPolicy,
  IPolicyProvider,
  IEmployer,
  IUser,
  FieldType,
} from 'types';
import WelcomeModal from './WelcomeModal';
import styles from './styles/q4e.scss';
import { HOME_ROUTE, PROFILE_ROUTE } from 'constants/routes';
import usePager from 'hooks/usePager';
import history from 'helpers/history';
import {
  getOtherSubmitData,
  getDefaultValues,
  getEmployerOptions,
  createEmployerPolicies,
  updateUserEmployer,
  getEmployerBenefits,
  shouldSkipPage,
  getEmployerQuestions,
  getTitle,
  getFormTitle,
  getPage,
} from './utils';
import { OTHER_EMPLOYER_ID } from 'constants/user';
import { findEmployerById, hasEmployerPolicy } from 'helpers';
import Title from 'components/title';

interface IProps {
  employers: IEmployer[];
  policies: IPolicy[];
  policyProviders: IPolicyProvider[];
  user: IUser;
  createPolicies(policies: ICreatePolicyProps[]): void;
  deletePolicies(): void;
  updatePolicy(policy: IPolicy): void;
  updateUser(user: IUser): void;
}

const employerSearch = (options, searchVal = '') => {
  const val = searchVal.trim().toLowerCase();
  return options.filter(
    (o) =>
      R.includes(val, o.text.toLowerCase()) || o.text === OTHER_EMPLOYER_ID,
  );
};

const Q4E = ({
  employers,
  policies,
  policyProviders,
  user,
  createPolicies,
  deletePolicies,
  updatePolicy,
  updateUser,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(getDefaultValues(user));

  const location = useLocation();
  const fromDashboard = R.pathOr(false, ['state', 'fromDashboard'], location);
  const skipPage = fromDashboard || shouldSkipPage();
  const showBack = fromDashboard || !skipPage;

  const openWelcome = user.id && user.employerId && !user.q4eCompleted;

  const { active, next, prev } = usePager(skipPage ? 1 : 0);

  const employer = findEmployerById(employers, values.employerId);

  const isUpdate = useMemo(() => hasEmployerPolicy(policies, employer), [
    employer,
    policies,
  ]);

  const selectedEmployerQuestions = useMemo(
    () => getEmployerQuestions(employers, values),
    [employers, values],
  );

  const onSubmit = async (data) => {
    const updatedUser = R.merge(values, data);
    setValues(updatedUser);
    setLoading(true);

    if (active === 0) {
      // If "Other" employer, next (we ask for employer name on next page)
      // If employer has questions, next (we ask them next page)
      // If any other employer, we finish and go to profile page

      const employerPolicies = getEmployerBenefits(
        employers,
        updatedUser.employerId,
      );

      if (
        !employerPolicies.length &&
        updatedUser.employerId !== OTHER_EMPLOYER_ID
      ) {
        updateUserEmployer(updateUser, employers, user, updatedUser);
        history.push(PROFILE_ROUTE);
      } else {
        next();
      }
    } else if (active === 1 && selectedEmployerQuestions?.length > 0) {
      // If employer has questions, next (go to last page)
      // If other employer, update user
      if (updatedUser.employerId === OTHER_EMPLOYER_ID) {
        const submitData = getOtherSubmitData(user, updatedUser);
        updateUser(submitData);
        await deletePolicies();
        history.push(PROFILE_ROUTE);
      } else {
        next();
      }
    } else {
      const submitData = updateUserEmployer(
        updateUser,
        employers,
        user,
        updatedUser,
      );
      await createEmployerPolicies(
        createPolicies,
        updatePolicy,
        employers,
        policies,
        submitData,
      );
      if (fromDashboard) {
        history.goBack();
      } else {
        history.push(isUpdate ? PROFILE_ROUTE : HOME_ROUTE);
      }
    }
    setLoading(false);
  };

  const options = useMemo(() => getEmployerOptions(employers), [employers]);

  const handleCloseWelcome = useCallback(() => {
    // Complete walkthrough so the normal user
    // onboarding popup doesnt show up for Q4E users
    updateUser({
      ...user,
      q4eCompleted: true,
      walkthroughCompleted: true,
    });
  }, [user, updateUser]);

  const handleBack = () => {
    if (active === 0 || fromDashboard) {
      history.goBack();
    } else {
      prev();
    }
  };

  const PageComponent = getPage(selectedEmployerQuestions, active);
  const title = getTitle(selectedEmployerQuestions, active);
  const formTitle = getFormTitle(employer, values, active);
  const questions = useMemo(() => {
    if (active === 0) {
      return [
        {
          key: 'employerId',
          placeholder: 'Search',
          labelAfter:
            'If your employer is not on the list simply tell us who you work for and we’ll reach out to them to check.',
          required: true,
          type: FieldType.Select,
          search: employerSearch,
          options,
          minCharacters: 2,
        },
      ];
    }
    return selectedEmployerQuestions;
  }, [active, selectedEmployerQuestions]);

  return (
    <div className={styles.container}>
      <WelcomeModal
        name={user.firstName}
        open={openWelcome}
        onClose={handleCloseWelcome}
      />
      <Title
        subtitle="See the free or discounted insurance benefits provided by your workplace."
        title={title}
        tooltip={
          <>
            <b>Make the most of your workplace provided insurance benefits.</b>
            <br />
            Check to see if your employer provides you with free or discounted
            insurance.
          </>
        }
      />
      <PageComponent
        employers={employers}
        employer={employer}
        policyProviders={policyProviders}
        values={values}
        isUpdate={isUpdate}
        loading={loading}
        questions={questions}
        title={formTitle}
        onBack={handleBack}
        onSubmit={onSubmit}
        setValues={setValues}
      />
    </div>
  );
};

export default Q4E;
