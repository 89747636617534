// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1hSbStgRa_t8sGKTk8QS6_{position:relative;background-color:#fff;border:solid 1px #ebedf3;padding:16px 24px;max-width:380px;border-radius:5px;filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))}._1hSbStgRa_t8sGKTk8QS6_ i.icon{cursor:pointer;position:absolute;font-size:18px;top:16px;right:10px}._1hSbStgRa_t8sGKTk8QS6_._34vrtu1guH44HiZu4tMvJq{border-left-width:5px;border-left-color:#4187f5}._1hSbStgRa_t8sGKTk8QS6_._31cWwlDqnWo1ZF_VwtKFsp{border-left-width:5px;border-left-color:#7ba770}._1hSbStgRa_t8sGKTk8QS6_._2bSYQnMUeFYy8qweqsPav1{border-left-width:5px;border-left-color:#e47c6e}._2Zz0tXebJMADswk2uMk-DM{font-family:\"Open Sans\";font-weight:700;font-size:14px;display:flex;align-items:center;margin-bottom:8px}._3MetfIy2ZV9L4lY5hEIFRz{margin-right:24px}._3jdDVIiYakD034B1CsjZNg{padding-right:24px}._2cXMp8_BeOy5DesziGQX_{font-family:\"Open Sans\";font-weight:400;font-size:14px}._2cXMp8_BeOy5DesziGQX_ b{cursor:pointer}@media only screen and (max-width: 480px){._1hSbStgRa_t8sGKTk8QS6_{max-width:none}}", ""]);
// Exports
exports.locals = {
	"container": "_1hSbStgRa_t8sGKTk8QS6_",
	"info": "_34vrtu1guH44HiZu4tMvJq",
	"success": "_31cWwlDqnWo1ZF_VwtKFsp",
	"error": "_2bSYQnMUeFYy8qweqsPav1",
	"header": "_2Zz0tXebJMADswk2uMk-DM",
	"img": "_3MetfIy2ZV9L4lY5hEIFRz",
	"title": "_3jdDVIiYakD034B1CsjZNg",
	"text": "_2cXMp8_BeOy5DesziGQX_"
};
module.exports = exports;
