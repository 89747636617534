// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1ksvsYW5LNoETNrfnIBxbe.ui.modal{border-radius:5px}._1ksvsYW5LNoETNrfnIBxbe.ui.modal>.content{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:40px;height:fit-content;line-height:2em;text-align:center}._2967CJw_-l5eXmeaW3ziu1{margin-bottom:24px}._3-LOaFG-H6klxOoSyaaBNs{width:180px;height:fit-content}.KRi6Tq_UeUpDRry_ZCawN{width:140px;height:fit-content}._25GFldTGz1S_uH0uTTzyFF{font-family:\"DM Sans\";font-weight:700;font-size:18px;color:#4187f5;margin-bottom:8px}._1mVST9tuxFGqyuWC_qNnQq{font-family:\"DM Sans\";font-weight:400}.HYDJICEHQaa6eD2ybKl5t{margin-top:24px}._1J23qAiZyLf5XnZ9hKYNht.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;width:175px;background-color:#4187f5}@media(max-width: 576px){._1J23qAiZyLf5XnZ9hKYNht.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 576px){._1ksvsYW5LNoETNrfnIBxbe.ui.modal>.content{margin:1.5em 0 1.5em 0}}", ""]);
// Exports
exports.locals = {
	"modal": "_1ksvsYW5LNoETNrfnIBxbe",
	"image": "_2967CJw_-l5eXmeaW3ziu1",
	"santa": "_3-LOaFG-H6klxOoSyaaBNs",
	"swagSanta": "KRi6Tq_UeUpDRry_ZCawN",
	"heading": "_25GFldTGz1S_uH0uTTzyFF",
	"body": "_1mVST9tuxFGqyuWC_qNnQq",
	"close": "HYDJICEHQaa6eD2ybKl5t",
	"button": "_1J23qAiZyLf5XnZ9hKYNht"
};
module.exports = exports;
