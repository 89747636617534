import React from 'react';
import styles from './progress.scss';

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);

const getOffset = (val = 0) => Math.round((100 - Math.min(val, 100)) / 100 * diameter);

interface IProps {
  className?: string;
  progress: number;
}

const ProgressSpinner = ({ className, progress }: IProps) => {
  return (
    <div className={className}>
      <svg width="100%" height="100%" viewBox="-25 -25 400 400">
        <circle opacity="0.1" stroke="#C7C7C7" cx="175" cy="175" r="175" strokeWidth="40" fill="none"/>
        <circle stroke="#4187F5" transform="rotate(-90 175 175)" cx="175" cy="175" r="175" strokeDasharray="1100" strokeWidth="40" strokeDashoffset="1100" strokeLinecap="round" fill="none" style={{ strokeDashoffset: getOffset(progress) }} />
      </svg>
    </div>
  );
};

export default ProgressSpinner;
