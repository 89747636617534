import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styles from './formFieldBordered.scss';
import InputBordered, { IInputBordered } from './InputBordered';

interface IInputMultiple extends Omit<IInputBordered, 'onChange'> {
  fieldKey: string;
  values: object;
  errors: object;
  as: string;
  onChange(item: any): void;
}

const InputMultiple = (props: IInputMultiple) => {
  const { className, errors, fieldKey, onChange, values = {}, as } = props;
  const [count, setCount] = useState(Object.keys(values).length || 1);

  useEffect(() => {
    if (R.isEmpty(values)) {
      onChange({ fieldKey, order: 0, as, value: '' });
    }
  }, [values]);

  const handleAdd = useCallback(() => {
    const order = count + 1;
    setCount(order);
    onChange({ fieldKey, order: count, as, value: '' });
  }, [count, fieldKey, values]);

  const handleInputChange = (order) => ({ target }) => {
    const { value } = target;
    onChange({ fieldKey, order, as, value });
  };

  const handleRemoveInput = (order) => () => {
    onChange({ fieldKey, order, as, isRemove: true });
    setCount(count - 1);
  };

  const inputs = useMemo(() => {
    const x = [];
    for (let i = 0; i < count; i++) {
      x.push(
        <div className={styles.inputMultiple} key={`${fieldKey}${i}`}>
          <InputBordered
            {...props}
            onChange={handleInputChange(i)}
            value={R.pathOr('', [i, fieldKey], values)}
            className={classnames(styles.multiple, className)}
            name={fieldKey}
            error={R.path([as, i], errors)}
          />
          {
            i > 0 &&
            <Icon
              className={styles.close}
              name="times"
              size="small"
              onClick={handleRemoveInput(i)}
            />
          }
        </div>
      );
    }
    return x;
  }, [count, errors, fieldKey, values]);

  return (
    <div className={styles.inputMultipleContainer}>
      {inputs}
      <div className={styles.addBtn} onClick={handleAdd}>
        Add {fieldKey} +
      </div>
    </div>
  );
};

export default InputMultiple;