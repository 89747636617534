import React from 'react';
import { Confirm } from 'semantic-ui-react';
import styles from './confirmAction.scss';

interface IConfirmActionProps {
  open: boolean;
  className?: string;
  content?: string;
  header?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  closeOnDimmerClick?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmAction = ({
  className,
  open,
  header,
  content,
  cancelText = 'No',
  confirmText = 'Yes',
  closeOnDimmerClick,
  onCancel,
  onConfirm,
}: IConfirmActionProps) => {
  return (
    <Confirm
      className={classnames(styles.confirmAction, className)}
      open={open}
      header={header}
      content={content}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelButton={cancelText}
      confirmButton={confirmText}
      closeOnDimmerClick={closeOnDimmerClick}
    />
  );
};

export default ConfirmAction;
