import {
  CHECKOUT_ROUTE,
  CLIENTS_DETAILS_POLICY_ROUTE,
  MARKET_SCAN_BROKER_ROUTE,
  MARKET_SCAN_NEW_ROUTE,
  MARKET_SCAN_RESULTS_ROUTE,
  MARKET_SCAN_ROUTE,
  POLICY_DETAILS_ROUTE,
  PREMIUM_QUOTE_ROUTE,
  PREMIUM_ROUTE,
  RENEWAL_ROUTE,
} from 'constants/routes';
import { IPolicy } from 'types';

export const getExistingMarketScanRoute = (policy: IPolicy) => {
  return {
    pathname: MARKET_SCAN_ROUTE,
    state: { policy, isExisting: true },
  };
};

export const getPolicyDetailsRoute = (policyId: string) => {
  return POLICY_DETAILS_ROUTE.replace(':id', policyId);
};

export const getPolicyRenewalRoute = (policyId: string) => {
  return RENEWAL_ROUTE.replace(':id', policyId);
};

export const getAdviserClientRoute = (userId: string, policyId: string) => {
  return CLIENTS_DETAILS_POLICY_ROUTE.replace(':id', userId).replace(
    ':policyId?',
    policyId,
  );
};

export const getRenewalEndRoute = (policy: IPolicy) => {
  return policy.adviserOrgId
    ? getAdviserClientRoute(policy.userId, policy.id)
    : getPolicyDetailsRoute(policy.id);
};

export const getMarketScanNewRouteByName = (policyTypeName: string) => {
  return MARKET_SCAN_NEW_ROUTE.replace(':policyTypeName?', policyTypeName);
};

export const getMarketScanBrokerRouteByName = (policyTypeName: string) => {
  return MARKET_SCAN_BROKER_ROUTE.replace(':policyTypeName?', policyTypeName);
};

export const getMarketScanResultsRoute = (id: string, sk: string) => {
  return `${MARKET_SCAN_RESULTS_ROUTE}?id=${id}&sk=${sk}`;
};

export const getPremiumMarketScanResultsRoute = (id: string, sk: string) => {
  return `${PREMIUM_QUOTE_ROUTE}?id=${id}&sk=${sk}`;
};

export const isPremiumRoute = (location) => {
  return (
    R.includes(PREMIUM_ROUTE, location.pathname) ||
    location.pathname === CHECKOUT_ROUTE
  );
};

export const isPremiumMarketScan = (ms) => {
  return ms.isPremium || ms.isBrokerFirst || ms.brokerFormCreatedAt;
};
