import React, { useCallback, useState } from 'react';
import styles from '../styles/common.scss';
import Button from 'components/button';
import FormField from 'components/form-field-bordered';
import Title from 'components/title';
import { getQuestionWithOption } from 'helpers';
import Back from 'components/back';
import { WarningMessage } from 'components/form-message';

interface ICommonProps {
  questions: any[];
  children?: any;
  title: string;
  subtitle?: string;
  form: object;
  onInputChange?: (
    fieldKey: string,
  ) => (event: React.FormEvent<HTMLInputElement>, data: object) => void;
  onMultipleInputChange?: (
    fieldKey: string,
  ) => (event: React.FormEvent<HTMLInputElement>, data: object) => void;
  onBack: () => void;
  onSubmit: () => void;
  buttonText?: string;
  options?: object;
  onDropdownChange?: (
    fieldKey: string,
  ) => (event: React.FormEvent<HTMLInputElement>, data: object) => void;
  loading?: boolean;
  inputClassName?: string;
  subtitleClassName?: string;
  disableSubmit?: boolean;
  fieldLabels?: object;
  bottomLabels?: object;
  questionProps?: object;
  page?: boolean;
}

export const Common = ({
  questions,
  title,
  subtitle,
  form,
  onInputChange,
  onSubmit,
  buttonText,
  options,
  onDropdownChange,
  loading,
  inputClassName,
  subtitleClassName,
  disableSubmit,
  fieldLabels,
  bottomLabels,
  children,
  onMultipleInputChange,
  onBack,
  questionProps,
  page,
}: ICommonProps) => {
  const [errors, setErrors] = useState(null);

  const handleSubmit = useCallback(() => {
    const required = questions.filter(R.prop('required'));

    const newErrors = required.reduce(
      (acc, { validationKey, fieldKey, multiple, as, validator = R.T }) => {
        if (multiple) {
          const items = form[as] || [];
          const values = items.length
            ? items.map((f) => !(f && f[fieldKey]))
            : [true];
          if (R.any(R.identity, values)) {
            return R.assoc(as, values, acc);
          }
        } else {
          const value = form[validationKey || fieldKey];

          if ((!value && value !== 0) || !validator(value, form)) {
            return R.assoc(fieldKey, true, acc);
          }
        }
        return acc;
      },
      null,
    );

    if (newErrors) {
      setErrors(newErrors);
    } else {
      onSubmit();
    }
  }, [form, questions]);

  const preventDefault = useCallback((e) => e.preventDefault(), []);

  return (
    <>
      <div className={styles.container}>
        <Title
          title={title}
          subtitle={subtitle}
          subtitleClassName={classnames(styles.subtitle, subtitleClassName)}
        />
        <form className={styles.form} onSubmit={preventDefault}>
          <div className={styles.formFieldsContainer}>
            {children ||
              questions.map((q) => {
                const fieldOptions = R.propOr(q.options, q.fieldKey, options);
                const question = getQuestionWithOption(q, fieldOptions);
                const {
                  fieldKey,
                  fullWidth,
                  valueKey,
                  multiple,
                  as,
                  warning,
                } = question;

                return (
                  <div className={styles.fieldContainer}>
                    <FormField
                      key={fieldKey}
                      name={fieldKey}
                      className={classnames(
                        inputClassName,
                        questions.length === 2 && styles.half,
                        fullWidth && styles.fullWidth,
                      )}
                      value={form[valueKey || fieldKey]}
                      values={form[as]}
                      onChange={
                        multiple
                          ? onMultipleInputChange
                          : question.type === 'dropdown'
                          ? onDropdownChange
                          : onInputChange
                      }
                      options={fieldOptions}
                      loading={loading}
                      error={R.prop(fieldKey, errors)}
                      errors={errors}
                      bottomLabel={R.prop(fieldKey, bottomLabels)}
                      {...question}
                      {...R.propOr({}, fieldKey, questionProps)}
                      placeholder={R.propOr(
                        question.placeholder,
                        fieldKey,
                        fieldLabels,
                      )}
                    />
                    {warning &&
                      warning(
                        form[valueKey || fieldKey],
                        form['frequency'] ? form['frequency'] : 'Yearly',
                      ) && (
                        <WarningMessage
                          text={
                            'Your premium looks high, are you sure this is correct?'
                          }
                          className={classnames(styles.warning)}
                        />
                      )}
                  </div>
                );
              })}
          </div>
        </form>
      </div>
      <div className={styles.footer}>
        {onBack && <Back className={styles.back} handleBack={onBack} />}
        <Button
          className={classnames(
            styles.submit,
            buttonText === 'Finish' && styles.finishBtn,
          )}
          text={buttonText || 'Next'}
          disabled={buttonText === 'Finish' ? disableSubmit : false}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};
