import { INSURANCE_TYPES_ICONS_SVG_V2 } from 'constants/insurance';
import { IPolicyType } from 'types';
import styles from './policy-types.scss';
import { reorderPolicy } from 'helpers/policyTypes';

interface IPolicyTypesProps {
  className?: string;
  buttonClassName?: string;
  policyTypes: IPolicyType[];
  onClick?: (e?: any) => void;
  link?: string;
  showDescription?: boolean;
}

const PolicyTypes = (props: IPolicyTypesProps) => {
  const { className, buttonClassName, policyTypes, onClick } = props;

  const newPolicyTypes = reorderPolicy(policyTypes);

  return (
    <div className={classnames(styles.container, className)}>
      {newPolicyTypes.map((policy) => {
        const { name, id } = policy;
        const Icon =
          INSURANCE_TYPES_ICONS_SVG_V2[name] ||
          INSURANCE_TYPES_ICONS_SVG_V2.default;
        return (
          <div
            key={id}
            onClick={() => onClick(policy)}
            className={classnames(styles.item, buttonClassName)}
          >
            <Icon />
            <span>{name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PolicyTypes;
