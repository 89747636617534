import {
  IAttachment,
  IPolicy,
  IPolicyRenewal,
  IPolicySubType,
  IPolicyType,
} from 'types';
import { MARKET_SCAN_ROUTE } from 'constants/routes';
import history from 'helpers/history';

export enum MarketScanTypes {
  SET_POLICY = 'SET_POLICY',
  SET_POLICY_TYPE = 'SET_POLICY_TYPE',
  GOTO_START = 'GOTO_START',
  GOTO_EXISTING_POLICY = 'GOTO_EXISTING_POLICY',
  GOTO_ADD_POLICY = 'GOTO_ADD_POLICY',
  GOTO_NEW_POLICY = 'GOTO_NEW_POLICY',
  CONTINUE_MS = 'CONTINUE_MS',
  NEXT_PAGE = 'NEXT_PAGE',
  PREV_PAGE = 'PREV_PAGE',
  CHANGE_PAGE = 'CHANGE_PAGE',
  RESET_PAGE = 'RESET_PAGE',
  SET_LOADING = 'SET_LOADING',
  SET_COMPARE_SELECTED = 'SET_COMPARE_SELECTED',
  SET_RENEWAL = 'SET_RENEWAL',
}

export enum MarketScanPath {
  START = 'START',
  PERSONALISED = 'PERSONALISED',
  EXISTING_POLICY = 'EXISTING_POLICY',
  ADD_POLICY = 'ADD_POLICY',
  NEW_POLICY = 'NEW_POLICY',
}

// Interfaces
export interface IState {
  attachments: IAttachment[];
  compareSelected: boolean;
  loading: boolean;
  path: MarketScanPath;
  policy: IPolicy;
  policyType: IPolicyType;
  subTypes: IPolicySubType[];
  page: number;
  renewal: IPolicyRenewal;
}

interface ISetPolicyData {
  attachments: IAttachment[];
  policy: IPolicy;
  subTypes: IPolicySubType[];
  renewal: IPolicyRenewal;
}

// Initial state
export const initialState: IState = {
  attachments: [],
  compareSelected: false,
  loading: false,
  path: MarketScanPath.START,
  policy: null,
  policyType: null,
  subTypes: [],
  page: 0,
  renewal: null,
};

const getPrevPageState = (state: IState, routeState: any) => {
  if (
    state.page === 0 &&
    (state.path === MarketScanPath.START ||
      // Go back to previous route (policy details)
      (routeState?.isExisting && state.path === MarketScanPath.EXISTING_POLICY))
  ) {
    history.goBack();
    return state;
  }

  if (
    state.path === MarketScanPath.ADD_POLICY &&
    (state.policy?.isUpload ? state.page === 2 : state.page === 3)
  ) {
    // Reset route state coz we put the uploaded policy
    // when it finishes uploading
    history.replace(MARKET_SCAN_ROUTE);

    return {
      ...state,
      policy: null,
      page: 0,
      path: MarketScanPath.START,
      compareSelected: false,
    };
  } else if (
    // When they are on the policy type question page on new policy path
    state.path === MarketScanPath.NEW_POLICY &&
    state.page === 0
  ) {
    return {
      ...state,
      page: 0,
      path: MarketScanPath.START,
    };
  }

  const page = R.max(0, state.page - 1);
  const path =
    state.page === 0 && state.path !== MarketScanPath.START
      ? MarketScanPath.START
      : state.path;

  return {
    ...state,
    page,
    path,
  };
};

// Initialiser
export const initialise = (routeState) => (state) => {
  if (routeState?.isExisting) {
    return {
      ...state,
      path: MarketScanPath.EXISTING_POLICY,
      page: 0,
    };
  }

  const isContinueDraft = !!routeState?.policy;
  if (isContinueDraft) {
    return {
      ...state,
      policy: routeState.policy,
      path: MarketScanPath.ADD_POLICY,
      page: 2,
    };
  }
  return state;
};

// Actions
export const prevPageAction = (routeState: any) => ({
  type: MarketScanTypes.PREV_PAGE,
  data: routeState,
});
export const nextPageAction = () => ({ type: MarketScanTypes.NEXT_PAGE });
export const changePageAction = (data: number) => ({
  type: MarketScanTypes.CHANGE_PAGE,
  data,
});
export const goToStartAction = () => ({ type: MarketScanTypes.GOTO_START });
export const goToExistingPolicyAction = () => ({
  type: MarketScanTypes.GOTO_EXISTING_POLICY,
});
export const goToAddPolicyAction = () => ({
  type: MarketScanTypes.GOTO_ADD_POLICY,
});
export const goToNewPolicyAction = () => ({
  type: MarketScanTypes.GOTO_NEW_POLICY,
});
export const setLoadingAction = (data: boolean) => ({
  type: MarketScanTypes.SET_LOADING,
  data,
});
export const setPolicyAction = (data: ISetPolicyData) => ({
  type: MarketScanTypes.SET_POLICY,
  data,
});
export const setPolicyTypeAction = (data: IPolicyType) => ({
  type: MarketScanTypes.SET_POLICY_TYPE,
  data,
});
export const setCompareSelectedAction = (data: boolean) => ({
  type: MarketScanTypes.SET_COMPARE_SELECTED,
  data,
});
export const setRenewalAction = (data: IPolicyRenewal) => ({
  type: MarketScanTypes.SET_RENEWAL,
  data,
});
export const continueMarketScanAction = (data: IPolicy) => ({
  type: MarketScanTypes.CONTINUE_MS,
  data,
});

// Reducer
export const reducer = (state: IState, action) => {
  switch (action.type) {
    case MarketScanTypes.SET_POLICY:
      return {
        ...state,
        attachments: action.data.attachments,
        policy: action.data.policy,
        subTypes: action.data.subTypes,
        renewal: action.data.renewal,
        loading: false,
      };
    case MarketScanTypes.SET_POLICY_TYPE:
      return {
        ...state,
        page: state.page + 1,
        policyType: action.data,
      };
    case MarketScanTypes.GOTO_START: {
      history.replace(MARKET_SCAN_ROUTE);
      return initialState;
    }
    case MarketScanTypes.GOTO_EXISTING_POLICY:
      return { ...state, path: MarketScanPath.EXISTING_POLICY };
    case MarketScanTypes.GOTO_ADD_POLICY:
      return {
        ...state,
        path: MarketScanPath.ADD_POLICY,
        compareSelected: false,
      };
    case MarketScanTypes.GOTO_NEW_POLICY:
      return {
        ...state,
        path: MarketScanPath.NEW_POLICY,
        compareSelected: false,
      };
    case MarketScanTypes.CHANGE_PAGE:
      return { ...state, page: action.data };
    case MarketScanTypes.NEXT_PAGE:
      return { ...state, page: state.page + 1 };
    case MarketScanTypes.PREV_PAGE:
      return getPrevPageState(state, action.data);
    case MarketScanTypes.SET_LOADING:
      return { ...state, loading: action.data };
    case MarketScanTypes.SET_COMPARE_SELECTED:
      return { ...state, compareSelected: action.data };
    case MarketScanTypes.SET_RENEWAL:
      return { ...state, renewal: action.data };
    case MarketScanTypes.CONTINUE_MS:
      return {
        ...state,
        policy: action.data,
        path: MarketScanPath.ADD_POLICY,
        page: 2,
      };
    default:
      return state;
  }
};
