import React, { useCallback, useMemo } from 'react';
import usePager from 'hooks/usePager';
import styles from './styles/life.scss';
import {
  FAMILY_DEPENDENCY_FIELD,
  FAMILY_DEP_YEARS_FIELD,
  INCOME_FIELD,
  LIFE_USER_FIELDS,
  OTHER_DEBTS_FIELD,
  OUTSTANDING_MORTGAGE_FIELD,
} from 'constants/user-form';
import { convertFrequency, getDefaultValues, prettifyNumber } from 'helpers';
import useForm from 'hooks/useForm';
import HeaderImg from '-!svg-react-loader!images/people/couple-umbrella.svg';
import Button from '../../button';
import { TypeFormField } from 'components/type-form';
import ReactApexChart from 'react-apexcharts';
import { CALCULATOR_FIELDS, getChartOptions } from './utils';
import { IUser } from 'types';
import { FREQUENCY } from 'constants/options';

interface IProps {
  user: IUser;
  updateUser(user: IUser): void;
}

const isEmptyValue = (value) => R.isNil(value) || R.isEmpty(value);

const header = (
  <div className={styles.header}>
    <HeaderImg />
    <div className={styles.bold}>Calculate how much you could leave behind</div>
  </div>
);

const Buttons = ({
  disabled,
  hideBack = false,
  prev,
  next,
  submitText = 'Next',
}) => (
  <div className={styles.buttons}>
    {!hideBack && <Button className={styles.back} onClick={prev} text="Back" />}
    <Button
      className={styles.next}
      onClick={next}
      text={submitText}
      disabled={disabled}
    />
  </div>
);

const Page1 = ({ form, prev, next }) => {
  return (
    <div className={styles.page}>
      {header}
      <div className={styles.body}>
        <div className={styles.label}>{FAMILY_DEPENDENCY_FIELD.calcLabel}</div>
        <TypeFormField
          className={styles.field}
          form={form}
          question={FAMILY_DEPENDENCY_FIELD}
        />
      </div>
      <Buttons
        disabled={R.isNil(form.values[FAMILY_DEPENDENCY_FIELD.key])}
        hideBack={true}
        prev={prev}
        next={next}
      />
    </div>
  );
};

const Page2 = ({ form, prev, next }) => {
  return (
    <div className={styles.page}>
      {header}
      <div className={styles.body}>
        <div className={styles.label}>{INCOME_FIELD.calcLabel}</div>
        <TypeFormField
          className={styles.field}
          form={form}
          question={INCOME_FIELD}
        />
        {form.values[FAMILY_DEPENDENCY_FIELD.key] && (
          <>
            <div className={styles.label}>
              {FAMILY_DEP_YEARS_FIELD.calcLabel}
            </div>
            <TypeFormField
              className={styles.field}
              form={form}
              question={FAMILY_DEP_YEARS_FIELD}
            />
          </>
        )}
      </div>
      <Buttons
        disabled={!form.values[INCOME_FIELD.key]}
        prev={prev}
        next={next}
      />
    </div>
  );
};

const Page3 = ({ form, prev, next }) => {
  return (
    <div className={styles.page}>
      {header}
      <div className={styles.body}>
        <div className={styles.label}>
          {OUTSTANDING_MORTGAGE_FIELD.calcLabel}
        </div>
        <div className={styles.labelGrey}>
          {OUTSTANDING_MORTGAGE_FIELD.calcLabel2}
        </div>
        <TypeFormField
          className={styles.field}
          form={form}
          question={OUTSTANDING_MORTGAGE_FIELD}
        />
      </div>
      <Buttons
        disabled={isEmptyValue(form.values[OUTSTANDING_MORTGAGE_FIELD.key])}
        prev={prev}
        next={next}
      />
    </div>
  );
};

const Page4 = ({ form, prev, onSubmit }) => {
  return (
    <div className={styles.page}>
      {header}
      <div className={styles.body}>
        <div className={styles.label}>{OTHER_DEBTS_FIELD.calcLabel}</div>
        <div className={styles.labelGrey}>{OTHER_DEBTS_FIELD.calcLabel2}</div>
        <TypeFormField
          className={styles.field}
          form={form}
          question={OTHER_DEBTS_FIELD}
        />
      </div>
      <Buttons
        disabled={isEmptyValue(form.values[OTHER_DEBTS_FIELD.key])}
        prev={prev}
        next={onSubmit}
        submitText="Finish"
      />
    </div>
  );
};

const Page5 = ({ form, reset }) => {
  const options = useMemo(() => getChartOptions(form.values), [form.values]);

  return (
    <div className={styles.results}>
      <div className={styles.header}>
        <ReactApexChart {...options} type="donut" />
      </div>
      <div className={styles.body}>
        <div className={styles.bold}>
          Here is an amount you can consider for your life insurance
        </div>
        {CALCULATOR_FIELDS.map(({ key, color, legend, calculateValue }) => {
          const value = calculateValue
            ? calculateValue(form.values[key], form.values)
            : form.values[key];
          return (
            <div key={key} className={styles.legend}>
              <div
                className={styles.circle}
                style={{ backgroundColor: color }}
              />
              <div>{`$${prettifyNumber(value)} ${legend}`}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.buttons}>
        <Button className={styles.back} onClick={reset} text="Redo" />
      </div>
    </div>
  );
};

const pageMap = {
  0: Page1,
  1: Page2,
  2: Page3,
  3: Page4,
  4: Page5,
};

const getDefaultLifeCalcValues = (user) => {
  const values = getDefaultValues(LIFE_USER_FIELDS, user);
  values.income = convertFrequency(
    user.income,
    user.incomeFrequency || FREQUENCY.YEARLY,
    FREQUENCY.YEARLY,
  );
  // Default should be Yearly as the calculators asks yearly income
  values.incomeFrequency = FREQUENCY.YEARLY;
  return values;
};

const LifeCalculator = ({ user, updateUser }: IProps) => {
  const { active, next, prev, resetActive } = usePager(0, true);
  const defaultValues = useMemo(() => getDefaultLifeCalcValues(user), [user]);
  const form = useForm({ defaultValues });
  const Page = pageMap[active];

  const handleSubmit = useCallback(() => {
    const updated = R.merge(user, form.values);
    updateUser(updated);
    next();
  }, [form.values, user, next, updateUser]);

  return (
    Page && (
      <Page
        next={next}
        form={form}
        prev={prev}
        reset={resetActive}
        onSubmit={handleSubmit}
      />
    )
  );
};

export default LifeCalculator;
