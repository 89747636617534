import 'react-widgets/dist/css/react-widgets.css';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import styles from './date-picker.scss';
import { convertToDateOnlyString, parseStandardFormat } from 'helpers';
import useBreakpoint from 'hooks/useBreakpoint';
import Moment from 'moment';

const DatePicker = (props) => {
  const {
    className,
    onChange,
    onOpen,
    name,
    containerClassName,
    disabled,
    max,
    min,
    wrapClassName,
  } = props;
  const ref = useRef(null);
  const [dropUp, setDropUp] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean | string>(false);
  const breakPoint = useBreakpoint();
  const isMobile = breakPoint === 'sm' || breakPoint === 'xs';

  const updateDropUp = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const shouldDropUp = rect.top + 260 > window.innerHeight;
      setDropUp(shouldDropUp);
    }
  };

  useEffect(() => {
    // Open datepicker upwards when datepicker goes over viewport
    updateDropUp();
    window.addEventListener('resize', updateDropUp);
    window.addEventListener('scroll', updateDropUp);
    return () => {
      window.removeEventListener('resize', updateDropUp);
      window.removeEventListener('scroll', updateDropUp);
    };
  }, []);

  const handleClick = useCallback(
    (event) => {
      if (event.target.tagName === 'INPUT' && !disabled) {
        setOpen('date');
        if (onOpen) {
          onOpen(true);
        }
      }
    },
    [disabled],
  );

  const handleToggle = useCallback(() => {
    const toggledState = open ? false : 'date';
    setOpen(toggledState);
    if (onOpen) {
      onOpen(!!toggledState);
    }
  }, [open]);

  const handleChange = useCallback(
    (val) => {
      const value = val ? val.toISOString() : '';
      const target = { name, value };
      onChange({ target });
    },
    [onChange, name],
  );

  const date = props.value ? new Date(props.value) : undefined;

  const defaultCurrentDate =
    !props.value && max && Moment().isAfter(Moment(max)) ? max : undefined;

  const handleInputChange = useCallback(
    (e) => {
      const val = e.target.value ? Moment(e.target.value).toISOString() : '';
      const target = { name, value: val };
      onChange({ target });
    },
    [onChange, name],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        const momentVal = parseStandardFormat(e.target.value);
        if (momentVal.isValid()) {
          const val = momentVal.toISOString();
          const target = { name, value: val };
          onChange({ target });
        }
      }
    },
    [onChange],
  );

  if (isMobile) {
    return (
      <div className={classnames(styles.wrap, wrapClassName)}>
        <input
          className={styles.hiddenInput}
          name={name}
          type="date"
          value={props.value ? convertToDateOnlyString(props.value) : ''}
          onChange={handleInputChange}
          max={max ? convertToDateOnlyString(max) : ''}
          min={min ? convertToDateOnlyString(min) : ''}
          disabled={disabled}
        />
        <DateTimePicker
          {...props}
          value={date}
          className={classnames(styles.date, className)}
          containerClassName={classnames(
            styles.dateContainer,
            containerClassName,
          )}
          open={open}
          onClick={handleClick}
          onToggle={handleToggle}
          onChange={handleChange}
          time={false}
          defaultCurrentDate={defaultCurrentDate}
        />
      </div>
    );
  }

  return (
    <div className={classnames(styles.wrap, wrapClassName)} ref={ref}>
      <DateTimePicker
        {...props}
        value={date}
        className={classnames(styles.date, className)}
        containerClassName={classnames(
          styles.dateContainer,
          containerClassName,
        )}
        open={open}
        onClick={handleClick}
        onToggle={handleToggle}
        onChange={handleChange}
        time={false}
        defaultCurrentDate={defaultCurrentDate}
        onKeyDown={handleKeyDown}
        dropUp={dropUp}
      />
    </div>
  );
};

export default DatePicker;
