import React from 'react';
import { FieldTypeMap } from 'components/form-fields-v2';
import { X, Plus } from 'react-feather';
import styles from './MultiForm.module.scss';
import { getQuestionsToDisplay } from 'helpers/formEngine';
import { FieldType } from 'types';
import { validateQuestion } from 'components/form-engine/validation';

const defaultValues = [{}];

interface IProps {
  fields: any[];
  addLabel?: string;
  basic?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean | string;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  itemName?: string;
  startIndex?: number;
  placeholder?: string;
  name: string;
  value: any;
  onBlur?(event: { target: any; type?: any }): void;
  onChange(event: { target: any; type?: any }): void;
}

const FieldComponentMap = {
  ...FieldTypeMap,
  [FieldType.MultiForm]: MultiForm,
};

function MultiForm({
  addLabel = 'Add another',
  basic,
  className,
  containerClassName,
  labelClassName,
  error,
  fields,
  itemName,
  startIndex = 1,
  name,
  value = defaultValues,
  onChange,
}: IProps) {
  const handleAdd = () => {
    onChange({
      target: {
        name,
        value: [...value, {}],
      },
    });
  };

  return (
    <div
      className={classnames(
        'multi-form',
        styles.container,
        basic && styles.basic,
        containerClassName,
      )}
    >
      {value.map((item, idx) => {
        const itemKey = `item_${idx}`;
        const questions = getQuestionsToDisplay(fields, item);

        const handleChange = (e) => {
          const toUpdate = {
            ...value[idx],
            [e.target.name]: e.target.value,
          };

          const updatedValues = R.update(idx, toUpdate, value);

          onChange({
            target: {
              name,
              value: updatedValues,
            },
          });
        };

        const handleRemove = () => {
          if (idx > 0) {
            onChange({
              target: {
                name,
                value: R.remove(idx, 1, value),
              },
            });
          }
        };

        return (
          <div className={styles.formContainer} key={itemKey}>
            <div
              className={classnames(styles.header, !itemName && styles.hide)}
            >
              <b>{`${itemName} ${idx + startIndex}`}</b>
              {idx > 0 && (
                <div className={styles.headerBtn} onClick={handleRemove}>
                  Remove <X size={16} />
                </div>
              )}
            </div>
            <div className={styles.form}>
              {questions.map((field) => {
                const FieldComponent = FieldComponentMap[field.type];
                const fieldKey = `${itemKey}_field_${field.key}`;
                const fieldValue = item[field.key];
                const fieldError =
                  error && !validateQuestion(field, item, fieldValue);

                return (
                  <FieldComponent
                    key={fieldKey}
                    errorClassName={styles.error}
                    error={field.fields ? error : fieldError}
                    {...field}
                    className={classnames(className, field.className)}
                    labelClassName={classnames(
                      styles.label,
                      labelClassName,
                      field.labelClassName,
                    )}
                    name={field.key}
                    value={fieldValue}
                    onChange={handleChange}
                  />
                );
              })}
            </div>
            {basic && idx > 0 && (
              <div className={styles.basicRemove} onClick={handleRemove}>
                Remove <X size={16} />
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.add} onClick={handleAdd}>
        <b>{addLabel}</b>
        <Plus />
      </div>
    </div>
  );
}

export default MultiForm;
