import React, { ReactElement } from 'react';
import { Image } from 'semantic-ui-react';
import styles from './index.scss';
import { H1 } from 'components/typography';
import { HelpTooltip } from 'components/tooltip';

export interface ITitleProps {
  title: any;
  id?: any;
  subtitle?: any;
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  tooltip?: ReactElement;
  tooltipProps?: any;
  img?: string;
  svgIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

const Title = ({
  id,
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  tooltip,
  tooltipProps,
  className,
  img,
  svgIcon: SvgIcon,
}: ITitleProps) => (
  <div id={id} className={className}>
    <H1 className={classnames(styles.title, titleClassName)}>
      {img && <Image className={styles.image} src={img} />}
      {SvgIcon && <SvgIcon className={styles.svg} />}
      {title}
      {tooltip && (
        <HelpTooltip
          basic={true}
          {...tooltipProps}
          position={tooltipProps?.position || 'bottom center'}
          content={tooltip}
          className={classnames(styles.tooltip, tooltipProps?.className)}
          toggleClassName={classnames(
            styles.toggle,
            tooltipProps?.toggleClassName,
          )}
        />
      )}
    </H1>
    {subtitle && (
      <div className={classnames(styles.subtitle, subtitleClassName)}>
        <span>{subtitle}</span>
      </div>
    )}
  </div>
);

export default Title;
