import {
  convertFrequency,
  getContentsErrorText,
  getHouseErrorText,
  validateContentsAmount,
  validateHouseAmount,
} from 'helpers';
import {
  areaOfGaragingOptions,
  FREQUENCY,
  FREQUENCY_OPTIONS,
  PAYOUT_BENEFIT_OPTIONS,
  PAYOUT_FREQUENCY_OPTIONS,
  PAYOUT_WAIT_OPTIONS,
} from './options';
import {
  ADDRESS_QUESTION,
  AREA_OF_GARAGING_QUESTION,
  BUILD_YEAR_QUESTION,
  CC_RATING_QUESTION,
  COPAYMENT_QUESTION,
  EXCESS_QUESTION,
  EXTERNAL_AREA_QUESTION,
  FLOOR_AREA_QUESTION,
  INSURED_AMOUNT_QUESTION,
  MAKE_QUESTION,
  MODEL_QUESTION,
  MOTORCYCLE_REGO_QUESTION,
  PAYMENT_METHOD_QUESTION,
  REGO_QUESTION,
  REGO_STATUS_QUESTION,
  SUBMODEL_QUESTION,
  VEHICLE_YEAR_QUESTION,
} from './questions';

/* eslint-disable import/prefer-default-export */
interface IInsuranceType {
  value: string;
  name: string;
  src: string;
}

export const INSURANCE_TYPES_NAMES = {
  VEHICLE: 'Vehicle',
  MOTORCYCLE: 'Motorcycle',
  HOME: 'House',
  CONTENT: 'Contents',
  HOME_CONTENTS: 'House & Contents',
  RENTERS: 'Renters',
  LANDLORD: 'Landlord',
  HEALTH: 'Health',
  LIFE: 'Life',
  DISABILITY: 'TPD',
  PET: 'Pet',
  MORTGAGE: 'Mortgage Repayment',
  REDUNDANCY: 'Redundancy',
  TRAUMA: 'Trauma',
  INCOME: 'Income Protection',
  CREDIT_CARD: 'Credit Card Benefits',
  TRAVEL: 'Travel',
  BOAT: 'Boat',
  MOBILE: 'Mobile',
  CANCER: 'Cancer',
  ACCIDENTAL_INJURY: 'Accidental Injury',
  RENT_PROTECTION: 'Rent Protection',
  OTHER: 'Other',
  COMMERCIAL: 'Commercial',
};

export { default as POLICY_BUNDLE_ICON } from 'images/bundle.svg';

export const INSURANCE_TYPES_ICONS = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: require('images/car.svg'),
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: require('images/motorbike.svg'),
  [INSURANCE_TYPES_NAMES.CONTENT]: require('images/content.svg'),
  [INSURANCE_TYPES_NAMES.CREDIT_CARD]: require('images/credit-card.svg'),
  [INSURANCE_TYPES_NAMES.DISABILITY]: require('images/disability.svg'),
  [INSURANCE_TYPES_NAMES.HEALTH]: require('images/health.svg'),
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: require('images/home-and-contents.svg'),
  [INSURANCE_TYPES_NAMES.HOME]: require('images/home.svg'),
  [INSURANCE_TYPES_NAMES.INCOME]: require('images/income.svg'),
  [INSURANCE_TYPES_NAMES.LIFE]: require('images/life.svg'),
  [INSURANCE_TYPES_NAMES.LANDLORD]: require('images/home.svg'),
  [INSURANCE_TYPES_NAMES.MORTGAGE]: require('images/mortgage.svg'),
  [INSURANCE_TYPES_NAMES.PET]: require('images/pet.svg'),
  [INSURANCE_TYPES_NAMES.RENTERS]: require('images/renters.svg'),
  [INSURANCE_TYPES_NAMES.TRAUMA]: require('images/trauma.svg'),
  [INSURANCE_TYPES_NAMES.BOAT]: require('images/Boat.svg'),
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: require('images/Redundancy.svg'),
  [INSURANCE_TYPES_NAMES.TRAVEL]: require('images/travel.svg'),
  [INSURANCE_TYPES_NAMES.CANCER]: require('images/cancer.svg'),
  [INSURANCE_TYPES_NAMES.RENT_PROTECTION]: require('images/renters.svg'),
  [INSURANCE_TYPES_NAMES.ACCIDENTAL_INJURY]: require('images/trauma.svg'),
  [INSURANCE_TYPES_NAMES.OTHER]: require('images/other.svg'),
  default: require('images/home.svg'),
};

export const INSURANCE_TYPES_ICONS_V2 = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: require('assets/icons/car.svg'),
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: require('assets/icons/motorbike.svg'),
  [INSURANCE_TYPES_NAMES.CONTENT]: require('assets/icons/content.svg'),
  [INSURANCE_TYPES_NAMES.CREDIT_CARD]: require('assets/icons/credit-card.svg'),
  [INSURANCE_TYPES_NAMES.DISABILITY]: require('assets/icons/disability.svg'),
  [INSURANCE_TYPES_NAMES.HEALTH]: require('assets/icons/health.svg'),
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: require('assets/icons/home-and-contents.svg'),
  [INSURANCE_TYPES_NAMES.HOME]: require('assets/icons/home.svg'),
  [INSURANCE_TYPES_NAMES.INCOME]: require('assets/icons/income.svg'),
  [INSURANCE_TYPES_NAMES.LIFE]: require('assets/icons/life.svg'),
  [INSURANCE_TYPES_NAMES.LANDLORD]: require('assets/icons/landlord.svg'),
  [INSURANCE_TYPES_NAMES.MORTGAGE]: require('assets/icons/mortgage.svg'),
  [INSURANCE_TYPES_NAMES.PET]: require('assets/icons/pet.svg'),
  [INSURANCE_TYPES_NAMES.COMMERCIAL]: require('assets/icons/commercial.svg'),
  [INSURANCE_TYPES_NAMES.RENTERS]: require('assets/icons/renters.svg'),
  [INSURANCE_TYPES_NAMES.TRAUMA]: require('assets/icons/trauma.svg'),
  [INSURANCE_TYPES_NAMES.BOAT]: require('assets/icons/boat.svg'),
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: require('assets/icons/redundancy.svg'),
  [INSURANCE_TYPES_NAMES.TRAVEL]: require('assets/icons/travel.svg'),
  [INSURANCE_TYPES_NAMES.CANCER]: require('assets/icons/cancer.svg'),
  [INSURANCE_TYPES_NAMES.OTHER]: require('assets/icons/other.svg'),
  default: require('assets/icons/other.svg'),
};

export const INSURANCE_TYPES_ICONS_SVG_V2 = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: require('-!svg-react-loader!assets/icons/car.svg'),
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: require('-!svg-react-loader!assets/icons/motorbike.svg'),
  [INSURANCE_TYPES_NAMES.CONTENT]: require('-!svg-react-loader!assets/icons/content.svg'),
  [INSURANCE_TYPES_NAMES.CREDIT_CARD]: require('-!svg-react-loader!assets/icons/credit-card.svg'),
  [INSURANCE_TYPES_NAMES.DISABILITY]: require('-!svg-react-loader!assets/icons/disability.svg'),
  [INSURANCE_TYPES_NAMES.HEALTH]: require('-!svg-react-loader!assets/icons/health.svg'),
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: require('-!svg-react-loader!assets/icons/home-and-contents.svg'),
  [INSURANCE_TYPES_NAMES.HOME]: require('-!svg-react-loader!assets/icons/home.svg'),
  [INSURANCE_TYPES_NAMES.INCOME]: require('-!svg-react-loader!assets/icons/income.svg'),
  [INSURANCE_TYPES_NAMES.LIFE]: require('-!svg-react-loader!assets/icons/life.svg'),
  [INSURANCE_TYPES_NAMES.LANDLORD]: require('-!svg-react-loader!assets/icons/landlord.svg'),
  [INSURANCE_TYPES_NAMES.MORTGAGE]: require('-!svg-react-loader!assets/icons/mortgage.svg'),
  [INSURANCE_TYPES_NAMES.PET]: require('-!svg-react-loader!assets/icons/pet.svg'),
  [INSURANCE_TYPES_NAMES.RENTERS]: require('-!svg-react-loader!assets/icons/renters.svg'),
  [INSURANCE_TYPES_NAMES.TRAUMA]: require('-!svg-react-loader!assets/icons/trauma.svg'),
  [INSURANCE_TYPES_NAMES.BOAT]: require('-!svg-react-loader!assets/icons/boat.svg'),
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: require('-!svg-react-loader!assets/icons/redundancy.svg'),
  [INSURANCE_TYPES_NAMES.TRAVEL]: require('-!svg-react-loader!assets/icons/travel.svg'),
  [INSURANCE_TYPES_NAMES.CANCER]: require('-!svg-react-loader!assets/icons/cancer.svg'),
  [INSURANCE_TYPES_NAMES.OTHER]: require('-!svg-react-loader!assets/icons/other.svg'),
  [INSURANCE_TYPES_NAMES.COMMERCIAL]: require('-!svg-react-loader!assets/icons/business.svg'),
  default: require('-!svg-react-loader!assets/icons/other.svg'),
};

export const INSURANCE_TYPES_COLOR_MAP = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: '#FF6565',
  [INSURANCE_TYPES_NAMES.CONTENT]: '#BF3487',
  [INSURANCE_TYPES_NAMES.CREDIT_CARD]: '',
  [INSURANCE_TYPES_NAMES.DISABILITY]: '#D3E1B5',
  [INSURANCE_TYPES_NAMES.HEALTH]: '#95B828',
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]:
    'linear-gradient(to bottom, #FFC01F 50%, #BF3487 50%)',
  [INSURANCE_TYPES_NAMES.HOME]: '#FFC01F',
  [INSURANCE_TYPES_NAMES.INCOME]: '#3D67AB',
  [INSURANCE_TYPES_NAMES.LIFE]: '#4D4E4B',
  [INSURANCE_TYPES_NAMES.LANDLORD]: '#FFE68F',
  [INSURANCE_TYPES_NAMES.MORTGAGE]: '#4187F5',
  [INSURANCE_TYPES_NAMES.PET]: '#FE94D4',
  [INSURANCE_TYPES_NAMES.RENTERS]: '#FDD7EE',
  [INSURANCE_TYPES_NAMES.TRAUMA]: '#83A48C',
  [INSURANCE_TYPES_NAMES.BOAT]: '#FFCDA9',
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: '#CADEFE',
  [INSURANCE_TYPES_NAMES.TRAVEL]: '#B0A0D2',
  [INSURANCE_TYPES_NAMES.CANCER]: '#C598C8',
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: '#F0761D',
  q4e: 'rgba(65, 135, 245, 0.5)',
  creditCard: '#EBEDF3',
  default: '#34363E',
};

export const ADVISER_INSURANCE_TYPES_ICONS = {
  [INSURANCE_TYPES_NAMES.OTHER]: require('images/icons/new_other_insurance_icon.png'),
  [INSURANCE_TYPES_NAMES.LIFE]: require('images/icons/new_life_insurance_icon.png'),
};

export const INSURANCE_TYPES: IInsuranceType[] = [
  {
    value: 'car',
    name: INSURANCE_TYPES_NAMES.VEHICLE,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.VEHICLE],
  },
  {
    value: 'redundancy',
    name: INSURANCE_TYPES_NAMES.REDUNDANCY,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.REDUNDANCY],
  },
  {
    value: 'boat',
    name: INSURANCE_TYPES_NAMES.BOAT,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.BOAT],
  },
  {
    value: 'contents',
    name: INSURANCE_TYPES_NAMES.CONTENT,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.CONTENT],
  },
  {
    value: 'creditcard',
    name: INSURANCE_TYPES_NAMES.CREDIT_CARD,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.CREDIT_CARD],
  },
  {
    value: 'disability',
    name: INSURANCE_TYPES_NAMES.DISABILITY,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.DISABILITY],
  },
  {
    value: 'health',
    name: INSURANCE_TYPES_NAMES.HEALTH,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.HEALTH],
  },
  {
    value: 'home-and-contents',
    name: INSURANCE_TYPES_NAMES.HOME_CONTENTS,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.HOME_CONTENTS],
  },
  {
    value: 'home',
    name: INSURANCE_TYPES_NAMES.HOME,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.HOME],
  },
  {
    value: 'income',
    name: INSURANCE_TYPES_NAMES.INCOME,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.INCOME],
  },
  {
    value: 'life',
    name: INSURANCE_TYPES_NAMES.LIFE,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.LIFE],
  },
  {
    value: 'mortgage',
    name: INSURANCE_TYPES_NAMES.MORTGAGE,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.MORTGAGE],
  },
  {
    value: 'pet',
    name: INSURANCE_TYPES_NAMES.PET,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.PET],
  },
  {
    value: 'renters',
    name: INSURANCE_TYPES_NAMES.RENTERS,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.RENTERS],
  },
  {
    value: 'trauma',
    name: INSURANCE_TYPES_NAMES.TRAUMA,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.TRAUMA],
  },
  {
    value: 'travel',
    name: INSURANCE_TYPES_NAMES.TRAVEL,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.TRAVEL],
  },
  {
    value: 'cancer',
    name: INSURANCE_TYPES_NAMES.CANCER,
    src: INSURANCE_TYPES_ICONS[INSURANCE_TYPES_NAMES.CANCER],
  },
];

const VESSEL_FIELD = {
  fieldKey: 'vessel',
  placeholder: 'Vessel',
  label: 'Vessel',
  required: true,
};

export const NAME_FIELD = {
  fieldKey: 'name',
  placeholder: 'Name',
  label: 'Name',
  required: true,
};

const ADDRESS_FIELD = {
  ...ADDRESS_QUESTION,
  placeholder: 'Insured address',
};

const PROPERTY_INSURED_FIELD = {
  ...ADDRESS_FIELD,
  placeholder: 'Property insured',
  label: 'Property insured',
};

const MULTIPLE_INSURED_FIELD = {
  fieldKey: 'name',
  placeholder: 'Name',
  label: 'Name',
  required: true,
  multiple: true,
  as: 'insured',
};

const PET_NAME_FIELD = {
  fieldKey: 'petName',
  placeholder: 'Pet Name',
  label: 'Pet Name',
  required: true,
};

export const INSURANCE_QUESTIONS_BY_TYPE = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: [REGO_QUESTION],
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: [MOTORCYCLE_REGO_QUESTION],
  [INSURANCE_TYPES_NAMES.BOAT]: [VESSEL_FIELD],
  [INSURANCE_TYPES_NAMES.CONTENT]: [ADDRESS_FIELD],
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: [ADDRESS_FIELD],
  [INSURANCE_TYPES_NAMES.HOME]: [R.assoc('showWarning', true, ADDRESS_FIELD)],
  [INSURANCE_TYPES_NAMES.RENTERS]: [ADDRESS_FIELD],
  [INSURANCE_TYPES_NAMES.HEALTH]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.CREDIT_CARD]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.DISABILITY]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.INCOME]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.MORTGAGE]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.REDUNDANCY]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.TRAUMA]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.TRAVEL]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.LANDLORD]: [PROPERTY_INSURED_FIELD],
  [INSURANCE_TYPES_NAMES.LIFE]: [MULTIPLE_INSURED_FIELD],
  [INSURANCE_TYPES_NAMES.PET]: [PET_NAME_FIELD],
  [INSURANCE_TYPES_NAMES.CANCER]: [NAME_FIELD],
  [INSURANCE_TYPES_NAMES.OTHER]: [NAME_FIELD],
};

export const POLICY_PROVIDER_QUESTION = {
  fieldKey: 'policyProvider',
  placeholder: 'Insurance provider',
  required: true,
  type: 'dropdown',
  validationKey: 'policyProviderId',
  valueKey: 'policyProviderId',
  search: true,
};

export const RENEWAL_DATE_QUESTION = {
  fieldKey: 'renewalDate',
  placeholder: 'Policy renewal date',
  required: true,
  type: 'date',
};

const RENEWAL_DATE_DETAILS_QUESTION = {
  key: 'renewalDate',
  fieldKey: 'renewalDate',
  label: 'Renewal date',
  before: 'Renewal date',
  type: 'date',
  showKeyValue: R.prop('premium'),
};

export const INSURANCE_QUESTIONS_COMMON = [
  POLICY_PROVIDER_QUESTION,
  { fieldKey: 'policyNumber', placeholder: 'Policy number' },
];

export const INSURANCE_QUESTIONS_DATES = [RENEWAL_DATE_QUESTION];

export const TRAVEL_INSURANCE_QUESTIONS_DATES = [
  {
    fieldKey: 'startDate',
    placeholder: 'Policy start date',
    type: 'date',
  },
  {
    fieldKey: 'renewalDate',
    placeholder: 'Policy end date',
    required: true,
    type: 'date',
  },
];

export const PREMIUM_QUESTION = {
  key: 'premium',
  fieldKey: 'premium',
  placeholder: 'Premium amount',
  label: 'Premium',
  before: 'Premium',
  required: true,
  inputType: 'number',
  type: 'number',
  prefix: '$',
  warning: (value, freq) =>
    convertFrequency(value, freq, FREQUENCY.YEARLY) > 10000,
};

export const FREQUENCY_QUESTION = {
  fieldKey: 'frequency',
  placeholder: 'Payment frequency',
  required: true,
  type: 'dropdown',
};

export const INSURANCE_QUESTIONS_PAYMENTS = [
  PREMIUM_QUESTION,
  FREQUENCY_QUESTION,
  PAYMENT_METHOD_QUESTION,
];

export const INSURANCE_QUESTIONS_OPTIONAL = [
  EXCESS_QUESTION,
  INSURED_AMOUNT_QUESTION,
];

export const PET_INSURANCE_QUESTIONS_OPTIONAL = [
  COPAYMENT_QUESTION,
  EXCESS_QUESTION,
  INSURED_AMOUNT_QUESTION,
];

export const OTHER_INSURANCE_QUESTIONS_OPTIONAL = [
  EXCESS_QUESTION,
  INSURED_AMOUNT_QUESTION,
  {
    fieldKey: 'notes',
    placeholder: 'Notes',
    label: 'Notes',
    maxLength: 512,
  },
];

const EXCESS_HOME_QUESTION = {
  ...EXCESS_QUESTION,
  fieldKey: 'excessHome',
  placeholder: 'Main excess - Home',
  label: 'Main excess - Home',
};

const EXCESS_CONTENTS_QUESTION = {
  ...EXCESS_QUESTION,
  fieldKey: 'excessContents',
  placeholder: 'Main excess - Contents',
  label: 'Main excess - Contents',
};

export const HOME_CONTENT_INSURANCE_QUESTIONS_OPTIONAL = [
  EXCESS_HOME_QUESTION,
  EXCESS_CONTENTS_QUESTION,
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountHome',
    placeholder: 'Insured amount - Home',
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountContents',
    placeholder: 'Insured amount - Contents',
  },
];

export const LANDLORD_INSURANCE_QUESTIONS_OPTIONAL = [
  {
    ...EXCESS_HOME_QUESTION,
    placeholder: 'Main excess - Rental home',
  },
  {
    ...EXCESS_CONTENTS_QUESTION,
    placeholder: 'Main excess - Rental contents',
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountHome',
    placeholder: 'Insured amount - Rental home',
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountContents',
    placeholder: 'Insured amount- Rental contents',
  },
];

export const VEHICLE_INSURANCE_QUESTIONS_OPTIONAL = [
  ...INSURANCE_QUESTIONS_OPTIONAL,
  R.assoc('required', false, ADDRESS_FIELD),
];

const PAYOUT_FREQUENCY_FIELD = {
  fieldKey: 'payoutFrequency',
  type: 'dropdown',
  options: PAYOUT_FREQUENCY_OPTIONS,
};

const PAYOUT_WAIT_FIELD = {
  fieldKey: 'payoutWaitPeriod',
  type: 'dropdown',
  options: PAYOUT_WAIT_OPTIONS,
};

const PAYOUT_BENEFIT_FIELD = {
  fieldKey: 'payoutBenefitPeriod',
  type: 'dropdown',
  options: PAYOUT_BENEFIT_OPTIONS,
};

const PAYOUT_QUESTIONS = [
  {
    ...PAYOUT_FREQUENCY_FIELD,
    placeholder: 'Payout frequency',
  },
  {
    ...PAYOUT_WAIT_FIELD,
    placeholder: 'Payout wait period',
  },
  {
    ...PAYOUT_BENEFIT_FIELD,
    placeholder: 'Benefit period',
  },
];

export const PAYOUT_QUESTIONS_OPTIONAL = [
  ...INSURANCE_QUESTIONS_OPTIONAL,
  ...PAYOUT_QUESTIONS,
];

export const INSURANCE_QUESTIONS_OPTIONAL_BY_NAME = {
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: HOME_CONTENT_INSURANCE_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.LANDLORD]: LANDLORD_INSURANCE_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.PET]: PET_INSURANCE_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.VEHICLE]: VEHICLE_INSURANCE_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: VEHICLE_INSURANCE_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.INCOME]: PAYOUT_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.MORTGAGE]: PAYOUT_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.RENT_PROTECTION]: PAYOUT_QUESTIONS_OPTIONAL,
  [INSURANCE_TYPES_NAMES.OTHER]: OTHER_INSURANCE_QUESTIONS_OPTIONAL,
  default: INSURANCE_QUESTIONS_OPTIONAL,
};

export const INSURANCE_SUB_TYPE_QUESTION = {
  key: 'policySubType',
  fieldKey: 'policySubType',
  placeholder: 'Policy Type',
  label: 'Policy Type',
  before: 'Policy Type',
  type: 'dropdown',
  valueKey: 'policySubTypeId',
  validationKey: 'policySubTypeId',
  requiredFor: [INSURANCE_TYPES_NAMES.VEHICLE],
};

export const INSURANCE_PROVIDER_QUESTION = {
  fieldKey: 'policyProvider',
  label: 'Insurer',
  type: 'dropdown',
  valueKey: 'policyProviderId',
  handlerName: 'handleDropdownIdNameChange',
};

export const INSURANCE_DETAILS_FIELDS = [
  { fieldKey: 'policyType', label: 'Insured', disabled: true },
  {
    ...INSURANCE_SUB_TYPE_QUESTION,
    placeholder: '',
    handlerName: 'handleDropdownIdNameChange',
  },
  {
    ...PREMIUM_QUESTION,
    placeholder: '',
    required: false,
    showKeyValue: R.prop('id'),
  },
  {
    key: 'frequency',
    fieldKey: 'frequency',
    label: 'Payment frequency',
    before: 'Payment frequency',
    type: 'dropdown',
    handlerName: 'onFrequencyChange',
    options: FREQUENCY_OPTIONS,
    showKeyValue: R.prop('premium'),
  },
  PAYMENT_METHOD_QUESTION,
  {
    fieldKey: 'policyNumber',
    key: 'policyNumber',
    label: 'Policy number',
    before: 'Policy number',
  },
  {
    key: 'renewalDate',
    fieldKey: 'renewalDate',
    label: 'Renewal date',
    before: 'Renewal date',
    type: 'date',
    showKeyValue: R.prop('premium'),
  },
  {
    ...EXCESS_QUESTION,
    exclude: INSURANCE_TYPES_NAMES.LIFE,
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    placeholder: '',
    exclude: INSURANCE_TYPES_NAMES.LIFE,
  },
];

export const HOME_BUILD_FIELDS = [
  {
    ...BUILD_YEAR_QUESTION,
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    fieldKey: 'numberOfStories',
    label: 'Storeys',
    type: 'dropdown',
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    ...FLOOR_AREA_QUESTION,
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    ...EXTERNAL_AREA_QUESTION,
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
    optional: true,
  },
  {
    fieldKey: 'constructionType',
    label: 'Wall material',
    type: 'dropdown',
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    fieldKey: 'roofType',
    label: 'Roof material',
    type: 'dropdown',
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    fieldKey: 'slope',
    label: 'Slope',
    type: 'dropdown',
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
  {
    fieldKey: 'quality',
    label: 'Construction quality',
    type: 'dropdown',
    showKeyValue: ({ addressValid }) => addressValid === false,
    exclude: INSURANCE_TYPES_NAMES.HOME,
  },
];

export const CONTENTS_INSURANCE_DETAILS_FIELDS = [
  ...R.init(INSURANCE_DETAILS_FIELDS),
  {
    ...INSURED_AMOUNT_QUESTION,
    getErrorText: (value) => getContentsErrorText(value),
    validator: (value) => validateContentsAmount(value),
  },
];

export const HOME_INSURANCE_DETAILS_FIELDS = [
  ...R.init(INSURANCE_DETAILS_FIELDS),
  {
    ...INSURED_AMOUNT_QUESTION,
    getErrorText: (value, form) => getHouseErrorText(value, form.livingArea),
    validator: (value, form) =>
      form.livingArea ? validateHouseAmount(value, form.livingArea) : !!value,
  },
  ...HOME_BUILD_FIELDS,
];

export const HOME_CONTENTS_INSURANCE_DETAILS_FIELDS = [
  ...INSURANCE_DETAILS_FIELDS.filter(
    (f) => f.fieldKey !== 'amount' && f.fieldKey !== 'excess',
  ),
  EXCESS_HOME_QUESTION,
  EXCESS_CONTENTS_QUESTION,
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountHome',
    label: 'Insured amount - Home',
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountContents',
    label: 'Insured amount - Contents',
  },
];

export const LANDLORD_INSURANCE_DETAILS_FIELDS = [
  ...INSURANCE_DETAILS_FIELDS.filter(
    (f) => f.fieldKey !== 'amount' && f.fieldKey !== 'excess',
  ),
  {
    ...EXCESS_HOME_QUESTION,
    label: 'Main excess - Rental home',
  },
  {
    ...EXCESS_CONTENTS_QUESTION,
    label: 'Main excess - Rental contents',
    optional: true,
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountHome',
    placeholder: 'Insured amount - Rental home',
    label: 'Insured amount - Rental home',
  },
  {
    ...INSURED_AMOUNT_QUESTION,
    fieldKey: 'amountContents',
    placeholder: 'Insured amount - Rental contents',
    label: 'Insured amount - Rental contents',
    optional: true,
  },
];

export const PET_INSURANCE_DETAILS_FIELDS = [
  ...INSURANCE_DETAILS_FIELDS,
  COPAYMENT_QUESTION,
];

export const OTHER_INSURANCE_DETAILS_FIELDS = [
  ...INSURANCE_DETAILS_FIELDS.filter(
    (f) => f.fieldKey !== 'amount' && f.fieldKey !== 'excess',
  ),
  ...OTHER_INSURANCE_QUESTIONS_OPTIONAL,
];

export const VEHICLE_INFO_FIELDS = [
  R.merge(MAKE_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.VEHICLE,
  }),
  R.merge(MODEL_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.VEHICLE,
  }),
  R.merge(SUBMODEL_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.VEHICLE,
  }),
  R.merge(VEHICLE_YEAR_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.VEHICLE,
  }),
  R.merge(AREA_OF_GARAGING_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.VEHICLE,
    options: areaOfGaragingOptions,
  }),
];

export const MOTORCYCLE_INFO_FIELDS = [
  R.merge(MAKE_QUESTION, {
    showKeyValue: undefined,
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
  }),
  R.merge(MODEL_QUESTION, {
    showKeyValue: undefined,
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
  }),
  R.merge(CC_RATING_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
  }),
  R.merge(VEHICLE_YEAR_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
  }),
  R.merge(REGO_STATUS_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
  }),
  R.merge(AREA_OF_GARAGING_QUESTION, {
    exclude: INSURANCE_TYPES_NAMES.MOTORCYCLE,
    options: areaOfGaragingOptions,
  }),
];

export const VEHICLE_INSURANCE_DETAILS_FIELDS = [
  ...VEHICLE_INFO_FIELDS,
  ...INSURANCE_DETAILS_FIELDS,
  {
    ...ADDRESS_QUESTION,
    label: 'Insured Address',
    required: false,
    fullWidth: true,
  },
];

export const MOTORCYCLE_INSURANCE_DETAILS_FIELDS = [
  ...MOTORCYCLE_INFO_FIELDS,
  ...INSURANCE_DETAILS_FIELDS,
  {
    ...ADDRESS_QUESTION,
    label: 'Insured Address',
    required: false,
    fullWidth: true,
  },
];

const TRAVEL_INSURANCE_DETAILS_FIELDS = [
  ...R.reject(R.propEq('fieldKey', 'renewalDate'), INSURANCE_DETAILS_FIELDS),
  {
    key: 'startDate',
    fieldKey: 'startDate',
    label: 'Start date',
    before: 'Start date',
    type: 'date',
    showKeyValue: R.prop('premium'),
  },
  { ...RENEWAL_DATE_DETAILS_QUESTION, label: 'End date' },
];

export const ADDITIONAL_LIFE_COVERS_QUESTIONS = [
  {
    fieldKey: 'name',
    placeholder: 'Name',
    required: true,
  },
  {
    fieldKey: 'additionalCovers',
    placeholder: 'Select your covers',
    type: 'dropdown',
    multiple: true,
    required: true,
  },
  {
    fieldKey: 'premiumCover',
    label: 'Select if you have "Premium cover"',
    infoMsg:
      'An optional benefit you may have selected which covers you for payment of your premiums to keep your insurance in place while you are on claim and may not have the ability to earn an income.',
    inputType: 'checkbox',
  },
];

export const LIFE_INSURANCE_AMOUNT_FIELD = {
  fieldKey: 'insuredAmount',
  placeholder: 'Amount',
  prefix: '$',
  inputType: 'number',
};

export const LIFE_INSURANCE_OPTIONAL_FIELDS = {
  [INSURANCE_TYPES_NAMES.HEALTH]: [
    EXCESS_QUESTION,
    LIFE_INSURANCE_AMOUNT_FIELD,
  ],
  [INSURANCE_TYPES_NAMES.INCOME]: [
    ...PAYOUT_QUESTIONS,
    LIFE_INSURANCE_AMOUNT_FIELD,
  ],
  [INSURANCE_TYPES_NAMES.MORTGAGE]: [
    ...PAYOUT_QUESTIONS,
    LIFE_INSURANCE_AMOUNT_FIELD,
  ],
  [INSURANCE_TYPES_NAMES.RENT_PROTECTION]: [
    ...PAYOUT_QUESTIONS,
    LIFE_INSURANCE_AMOUNT_FIELD,
  ],
  default: [LIFE_INSURANCE_AMOUNT_FIELD],
};

export const LIFE_INSURANCE_FIELD_KEYS = [
  'name',
  'additionalCovers',
  'premiumCover',
];

export const FIELD_KEYS_FOR_MISSING_INFO_COMMON = [
  'renewalDate',
  'premium',
  'frequency',
  'policyProviderId',
];

export const FIELD_KEYS_FOR_COMPLETION_BY_NAME = {
  [INSURANCE_TYPES_NAMES.VEHICLE]: [
    ...FIELD_KEYS_FOR_MISSING_INFO_COMMON,
    'policySubTypeId',
  ],
  default: FIELD_KEYS_FOR_MISSING_INFO_COMMON,
};

export const FIELD_KEYS_EXCESS_BY_NAME = {
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: ['excessHome', 'excessContents'],
  [INSURANCE_TYPES_NAMES.LANDLORD]: ['excessHome', 'excessContents'],
  default: ['excess'],
};

export const FIELD_KEYS_AMOUNT_BY_NAME = {
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: ['amountHome', 'amountContents'],
  [INSURANCE_TYPES_NAMES.LANDLORD]: ['amountHome', 'amountContents'],
  default: ['amount'],
};

export const PAYOUT_INSURANCE_DETAILS_FIELDS = [
  ...INSURANCE_DETAILS_FIELDS,
  {
    ...PAYOUT_FREQUENCY_FIELD,
    label: 'Payout frequency',
    handlerName: 'onFrequencyChange',
    tooltip:
      'This is how often the insurer will make a payout to you e.g. monthly, lump sum, etc.',
  },
  {
    ...PAYOUT_WAIT_FIELD,
    label: 'Payout wait period',
    handlerName: 'onFrequencyChange',
    tooltip:
      'This is how many days you must wait, from the date of your illness or injury, before your insurer will start to make a payout to you.',
  },
  {
    ...PAYOUT_BENEFIT_FIELD,
    label: 'Benefit period',
    handlerName: 'onFrequencyChange',
    tooltip:
      'This is the maximum amount of time you can receive the payout as part of your policy.',
  },
];

export const INSURANCE_DETAILS_FIELDS_BY_NAME = {
  [INSURANCE_TYPES_NAMES.HOME]: HOME_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: HOME_CONTENTS_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.INCOME]: PAYOUT_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.LANDLORD]: LANDLORD_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.MORTGAGE]: PAYOUT_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.OTHER]: OTHER_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.PET]: PET_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.VEHICLE]: VEHICLE_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.MOTORCYCLE]: MOTORCYCLE_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.CONTENT]: CONTENTS_INSURANCE_DETAILS_FIELDS,
  [INSURANCE_TYPES_NAMES.TRAVEL]: TRAVEL_INSURANCE_DETAILS_FIELDS,
  default: INSURANCE_DETAILS_FIELDS,
};
