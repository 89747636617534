import { ICreditCard, ICreditCardState } from 'types';
import { AppActionTypes } from 'actions';
import { Types } from '../actions/credit-card-actions';
import { insertItem, removeItemsByProp } from 'helpers';

const policiesReducer = (
  state: ICreditCardState,
  action: AppActionTypes
): ICreditCardState => {
  switch (action.type) {
    case Types.CREDIT_CARD_RESET_FORM:
      return {
        ...state,
        submitted: false,
        submitting: false,
      };
    case Types.CREDIT_CARD_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case Types.GET_CREDIT_CARD_POLICIES:
      return {
        ...state,
        loaded: true,
        creditCards: action.data
      };
    case Types.CREATE_CREDIT_CARD_POLICY_LOADED:
      return {
        ...state,
        submitted: true,
        submitting: false,
        creditCards: insertItem(state.creditCards, action.data),
      };
    case Types.DELETE_CREDIT_CARD_POLICY_LOADED:
      return {
        ...state,
        creditCards: removeItemsByProp(state.creditCards, 'id', action.data.id),
      };
    default:
      return state;
  }
};

export default policiesReducer;
