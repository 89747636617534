import { createSelector } from 'reselect';
import { IAppState, ICreditCard, IPolicy } from 'types';

const defaultPolicy = {};

export const selectPolicyById = (policyId: string = '') =>
  createSelector(
    (state: IAppState) => ({
      creditCards: state.creditCard.creditCards,
      policies: state.policies.policies,
      selectedPolicy: state.policies.selectedPolicy,
    }),
    ({ creditCards, policies, selectedPolicy }): IPolicy | ICreditCard => {
      const policy = policyId.includes('credit-card')
        ? creditCards.find(R.propEq('id', policyId))
        : policies.find(R.propEq('id', policyId));
      return policy || selectedPolicy || defaultPolicy;
    },
  );

export const policiesToLinkSelector = createSelector(
  R.path(['policies', 'policies']),
  (policies: IPolicy[]): IPolicy[] => {
    return policies.filter(R.prop('needConfirmPurchase'));
  },
);
