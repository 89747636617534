import { Action } from 'redux';
import { assoc } from 'ramda';
import {
  IPolicy,
  IAppState,
  IPolicyScan,
  ICreatePolicyProps,
  IUser,
  IBundle,
} from 'types';
import {
  createPolicy,
  getAllPoliciesByFamilyId,
  deletePolicy,
  updatePolicy,
  batchUploadAttachments,
  getPolicyScanByPolicyId,
  createPolicyScan,
  updatePolicyScan,
  getPendingPolicyScanByUserId,
  getPolicyScanByUserId,
  createPolicyWithAttachments,
  getUserById,
  createBundleWithAttachments,
  getBundlesForUser,
  createPolicyScanNewPolicy,
  getPolicyById,
  confirmPolicyScanPurchase,
} from 'services';
import {
  getPolicyDetailsRoute,
  hasPolicyWithPremium,
  isUserFirstTimeUploadAttachment,
  trackEventCompleteRegistration,
} from 'helpers';
import {
  ACTIVATE_POLICY_SCAN_ERROR,
  CREATE_POLICY_ERROR,
  GET_POLICY_SCAN_ERROR,
  UPDATE_POLICY_ERROR,
  UPLOAD_FAILED_MESSAGE,
} from 'constants/errors';
import { PolicyStatus } from 'constants/policy';
import { GetUserByIdLoaded, UpdateUserLoaded } from './user';
import { GenericToast as toast, ErrorToast } from 'components/toasts';
import history from 'helpers/history';
import {
  HOME_ROUTE,
  MARKET_SCAN_NEW_DEFAULT_ROUTE,
  MARKET_SCAN_ROUTE,
} from 'constants/routes';
import {
  SelectPolicyRenewalLoaded,
  SetRenewalsByPolicy,
} from './policy-renewal-actions';

import { PaymentLoading } from './payment-actions';

export const trackUploads = (user: IUser, dispatch) => {
  if (isUserFirstTimeUploadAttachment(user)) {
    trackEventCompleteRegistration();
    dispatch(
      UpdateUserLoaded({
        ...user,
        hasAttachment: true,
        walkthroughCompleted: true,
      }),
    );
  }
};

export enum Types {
  CREATE_POLICY_LOADING = 'CREATE_POLICY_LOADING',
  CREATE_POLICY_LOADED = 'CREATE_POLICY_LOADED',
  CREATE_POLICIES = 'CREATE_POLICIES',
  CREATE_POLICY_FAILED = 'CREATE_POLICY_FAILED',
  GET_USER_POLICIES_LOADING = 'GET_USER_POLICIES_LOADING',
  GET_USER_POLICIES_LOADED = 'GET_USER_POLICIES_LOADED',
  GET_USER_POLICIES_FAILED = 'GET_USER_POLICIES_FAILED',
  DELETE_POLICY_LOADING = 'DELETE_POLICY_LOADING',
  DELETE_POLICY_LOADED = 'DELETE_POLICY_LOADED',
  DELETE_POLICY_FAILED = 'DELETE_POLICY_FAILED',
  DELETE_POLICIES = 'DELETE_POLICIES',
  UPDATE_POLICY_LOADING = 'UPDATE_POLICY_LOADING',
  UPDATE_POLICY_LOADED = 'UPDATE_POLICY_LOADED',
  UPDATE_POLICY_FAILED = 'UPDATE_POLICY_FAILED',
  RESET_POLICY_FORM = 'RESET_POLICY_FORM',
  SELECT_POLICY = 'SELECT_POLICY',
  GET_POLICY_SCAN_BY_POLICY_ID_LOADED = 'GET_POLICY_SCAN_BY_POLICY_ID_LOADED',
  CREATE_POLICY_SCAN_LOADED = 'CREATE_POLICY_SCAN_LOADED',
  CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED = 'CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED',
  CREATE_POLICY_SCAN_LOADING = 'CREATE_POLICY_SCAN_LOADING',
  CREATE_POLICY_SCAN_FAILED = 'CREATE_POLICY_SCAN_FAILED',
  UPDATE_POLICY_SCAN_LOADED = 'UPDATE_POLICY_SCAN_LOADED',
  GET_PENDING_POLICY_SCAN_LOADED = 'GET_PENDING_POLICY_SCAN_LOADED',
  GET_POLICY_REVIEW_BY_USER = 'GET_POLICY_REVIEW_BY_USER',
  CREATE_POLICY_REVIEW_LOADED = 'CREATE_POLICY_REVIEW_LOADED',
  GET_POLICY_SCAN_LOADED = 'GET_POLICY_SCAN_LOADED',
  GET_POLICY_BUNDLES_LOADED = 'GET_POLICY_BUNDLES_LOADED',
  CREATE_POLICY_BUNDLE_LOADED = 'CREATE_POLICY_BUNDLE_LOADED',
  DELETE_POLICY_BUNDLE_LOADED = 'DELETE_POLICY_BUNDLE_LOADED',
}

export interface ICreatePolicyLoading extends Action {
  type: Types.CREATE_POLICY_LOADING;
}

export interface ICreatePolicyFailed extends Action {
  type: Types.CREATE_POLICY_FAILED;
  data?: string;
}

export interface ICreatePolicyLoaded extends Action {
  type: Types.CREATE_POLICY_LOADED;
  data: IPolicy;
}

export interface ICreatePoliciesLoaded extends Action {
  type: Types.CREATE_POLICIES;
  data: IPolicy[];
}

export interface IDeletePoliciesLoaded extends Action {
  type: Types.DELETE_POLICIES;
  data: string[];
}
export interface ICreatePoliciesLoaded extends Action {
  type: Types.CREATE_POLICIES;
  data: IPolicy[];
}

export interface IDeletePolicyLoading extends Action {
  type: Types.DELETE_POLICY_LOADING;
}

export interface IDeletePolicyFailed extends Action {
  type: Types.DELETE_POLICY_FAILED;
  data: IPolicy;
}

export interface IDeletePolicyLoaded extends Action {
  type: Types.DELETE_POLICY_LOADED;
  data: string;
}

export interface IUpdatePolicyLoading extends Action {
  type: Types.UPDATE_POLICY_LOADING;
}

export interface IUpdatePolicyFailed extends Action {
  type: Types.UPDATE_POLICY_FAILED;
}

export interface IUpdatePolicyLoaded extends Action {
  type: Types.UPDATE_POLICY_LOADED;
  data: IPolicy;
}

export interface IGetUserPoliciesLoading extends Action {
  type: Types.GET_USER_POLICIES_LOADING;
}

export interface IGetUserPoliciesFailed extends Action {
  type: Types.GET_USER_POLICIES_FAILED;
}

export interface IGetUserPoliciesLoaded extends Action {
  type: Types.GET_USER_POLICIES_LOADED;
  data: IPolicy[];
}

export interface IResetPolicyForm extends Action {
  type: Types.RESET_POLICY_FORM;
}

export interface ISelectPolicy {
  type: Types.SELECT_POLICY;
  data: IPolicy;
}

export interface IGetPolicyScanByPolicyIdLoaded {
  type: Types.GET_POLICY_SCAN_BY_POLICY_ID_LOADED;
  data: IPolicyScan;
}

export interface ICreatePolicyScanLoaded {
  type: Types.CREATE_POLICY_SCAN_LOADED;
  data: IPolicyScan;
}

export interface ICreatePolicyScanWithoutPolicyLoaded {
  type: Types.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED;
  data: IPolicyScan;
}

export interface IUpdatePolicyScanLoaded {
  type: Types.UPDATE_POLICY_SCAN_LOADED;
  data: IPolicyScan;
}

export interface IGetPendingPolicyScanLoaded {
  type: Types.GET_PENDING_POLICY_SCAN_LOADED;
  data: IPolicyScan[];
}

export interface IGetPolicyScanLoaded {
  type: Types.GET_POLICY_SCAN_LOADED;
  data: IPolicyScan[];
}
export interface ICreatePolicyScanLoading {
  type: Types.CREATE_POLICY_SCAN_LOADING;
}

export interface ICreatePolicyScanLoaded {
  type: Types.CREATE_POLICY_SCAN_LOADED;
  data: IPolicyScan;
}

export interface ICreatePolicyScanFailed {
  type: Types.CREATE_POLICY_SCAN_FAILED;
}

export interface IGetPolicyBundlesLoaded {
  type: Types.GET_POLICY_BUNDLES_LOADED;
  data: IBundle[];
}

export interface ICreatePolicyBundleLoaded {
  type: Types.CREATE_POLICY_BUNDLE_LOADED;
  data: IBundle[];
}

export interface IDeletePolicyBundleLoaded {
  type: Types.DELETE_POLICY_BUNDLE_LOADED;
  data: string;
}

export const CreatePolicyLoading = () =>
  ({
    type: Types.CREATE_POLICY_LOADING,
  } as ICreatePolicyLoading);

export const CreatePolicyFailed = (data?: string) =>
  ({
    type: Types.CREATE_POLICY_FAILED,
    data,
  } as ICreatePolicyFailed);

export const CreatePolicyLoaded = (data: IPolicy) =>
  ({
    type: Types.CREATE_POLICY_LOADED,
    data,
  } as ICreatePolicyLoaded);

export const CreatePoliciesLoaded = (data: IPolicy[]) =>
  ({
    type: Types.CREATE_POLICIES,
    data,
  } as ICreatePoliciesLoaded);

export const DeletePoliciesLoaded = (data: string[]) =>
  ({
    type: Types.DELETE_POLICIES,
    data,
  } as IDeletePoliciesLoaded);

export const DeletePolicyLoading = () =>
  ({
    type: Types.DELETE_POLICY_LOADING,
  } as IDeletePolicyLoading);

export const DeletePolicyFailed = (data: IPolicy) =>
  ({
    type: Types.DELETE_POLICY_FAILED,
    data,
  } as IDeletePolicyFailed);

export const DeletePolicyLoaded = (data: string) =>
  ({
    type: Types.DELETE_POLICY_LOADED,
    data,
  } as IDeletePolicyLoaded);

export const UpdatePolicyLoading = () =>
  ({
    type: Types.UPDATE_POLICY_LOADING,
  } as IUpdatePolicyLoading);

export const UpdatePolicyFailed = (data: IPolicy) =>
  ({
    type: Types.UPDATE_POLICY_FAILED,
    data,
  } as IUpdatePolicyFailed);

export const UpdatePolicyLoaded = (data: IPolicy) =>
  ({
    type: Types.UPDATE_POLICY_LOADED,
    data,
  } as IUpdatePolicyLoaded);

export const GetUserPoliciesLoading = () =>
  ({
    type: Types.GET_USER_POLICIES_LOADING,
  } as IGetUserPoliciesLoading);

export const GetUserPoliciesFailed = () =>
  ({
    type: Types.GET_USER_POLICIES_FAILED,
  } as IGetUserPoliciesFailed);

export const GetUserPoliciesLoaded = (data: IPolicy[]) =>
  ({
    type: Types.GET_USER_POLICIES_LOADED,
    data,
  } as IGetUserPoliciesLoaded);

export const ResetPolicyFormAction = () =>
  ({
    type: Types.RESET_POLICY_FORM,
  } as IResetPolicyForm);

export const SelectPolicyLoaded = (data: IPolicy) =>
  ({
    type: Types.SELECT_POLICY,
    data,
  } as ISelectPolicy);

export const GetPolicyScanByPolicyIdLoaded = (data: IPolicyScan) =>
  ({
    type: Types.GET_POLICY_SCAN_BY_POLICY_ID_LOADED,
    data,
  } as IGetPolicyScanByPolicyIdLoaded);

export const CreatePolicyScanLoading = () =>
  ({
    type: Types.CREATE_POLICY_SCAN_LOADING,
  } as ICreatePolicyScanLoading);

export const CreatePolicyScanLoaded = (data: IPolicyScan) =>
  ({
    type: Types.CREATE_POLICY_SCAN_LOADED,
    data,
  } as ICreatePolicyScanLoaded);

export const CreatePolicyScanWithoutPolicyLoaded = (data: IPolicyScan) =>
  ({
    type: Types.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED,
    data,
  } as ICreatePolicyScanWithoutPolicyLoaded);

export const CreatePolicyScanFailed = () =>
  ({
    type: Types.CREATE_POLICY_SCAN_FAILED,
  } as ICreatePolicyScanFailed);

export const UpdatePolicyScanLoaded = (data: IPolicyScan) =>
  ({
    type: Types.UPDATE_POLICY_SCAN_LOADED,
    data,
  } as IUpdatePolicyScanLoaded);

export const GetPendingPolicyScanLoaded = (data: IPolicyScan[]) =>
  ({
    type: Types.GET_PENDING_POLICY_SCAN_LOADED,
    data,
  } as IGetPendingPolicyScanLoaded);

export const GetPolicyScanLoaded = (data: IPolicyScan[]) =>
  ({
    type: Types.GET_POLICY_SCAN_LOADED,
    data,
  } as IGetPolicyScanLoaded);

export const GetPolicyBundlesLoaded = (data: IBundle[]) =>
  ({
    type: Types.GET_POLICY_BUNDLES_LOADED,
    data,
  } as IGetPolicyBundlesLoaded);

export const CreatePolicyBundleLoaded = (data: IBundle[]) =>
  ({
    type: Types.CREATE_POLICY_BUNDLE_LOADED,
    data,
  } as ICreatePolicyBundleLoaded);

export const DeletePolicyBundleLoaded = (data: string) =>
  ({
    type: Types.DELETE_POLICY_BUNDLE_LOADED,
    data,
  } as IDeletePolicyBundleLoaded);

export const CreatePolicy = (policy: ICreatePolicyProps) => async (
  dispatch,
  getState,
) => {
  const {
    policies: { policies },
  } = getState();
  dispatch(CreatePolicyLoading());
  try {
    const newPolicy = { ...policy, effective: true };
    const result = await createPolicy(newPolicy);

    // Track first time adding policy with premium
    if (!hasPolicyWithPremium(policies) && newPolicy.premium) {
      trackEventCompleteRegistration();
    }

    dispatch(CreatePolicyLoaded(result.data));
  } catch {
    ErrorToast.error({ message: CREATE_POLICY_ERROR });
    dispatch(CreatePolicyFailed());
  }
};

export const CreatePolicyWithAttachments = (
  policy: ICreatePolicyProps,
  attachments,
) => async (dispatch, getState) => {
  dispatch(CreatePolicyLoading());
  try {
    const newPolicy = await createPolicyWithAttachments(policy, attachments);
    const { user }: IAppState = getState();
    trackUploads(user, dispatch);
    dispatch(CreatePolicyLoaded(newPolicy));
  } catch {
    ErrorToast.error({ message: UPLOAD_FAILED_MESSAGE });
    dispatch(CreatePolicyFailed());
  }
};

export const UpdatePolicyWithAttachments = (
  policy: IPolicy,
  attachments,
) => async (dispatch, getState) => {
  dispatch(CreatePolicyLoading());
  try {
    await batchUploadAttachments(attachments, policy.id);
    const { user }: IAppState = getState();
    trackUploads(user, dispatch);
    dispatch(
      UpdatePolicy({
        ...policy,
        isUpload:
          policy.status === PolicyStatus.FINISH_SETUP || policy.isUpload,
      }),
    );
  } catch {
    ErrorToast.error({ message: UPLOAD_FAILED_MESSAGE });
    dispatch(CreatePolicyFailed());
  }
};

export const GetUserPolicies = (familyId?: string) => async (
  dispatch,
  getState,
) => {
  const { user }: IAppState = getState();
  const id = familyId || user.currentFamilyId;
  dispatch(GetUserPoliciesLoading());
  const result = await getAllPoliciesByFamilyId(id, true);
  const data: IPolicy[] = result.data.Items;
  dispatch(GetUserPoliciesLoaded(data));
};

export const DeletePolicy = (id: string, userId?: string) => (dispatch) => {
  deletePolicy(id, userId);
  dispatch(DeletePolicyLoaded(id));
};

export const UpdatePolicy = (policy: IPolicy, submitted?: boolean) => async (
  dispatch,
  getState,
) => {
  const { user } = getState();
  dispatch(UpdatePolicyLoading());

  try {
    const { data } = await updatePolicy(policy);
    dispatch(UpdatePolicyLoaded(assoc('submitted', submitted, data)));

    // Get user addresses
    if (!R.includes(data.address, user.addresses || [])) {
      const { data: userData } = await getUserById();
      const newUser: IUser = userData.Items[0];
      dispatch(GetUserByIdLoaded(newUser));
    }
  } catch {
    ErrorToast.error({ message: UPDATE_POLICY_ERROR });
    dispatch(CreatePolicyFailed());
  }
};

export const ResetPolicyForm = () => (dispatch) => {
  dispatch(ResetPolicyFormAction());
};

export const SelectPolicy = (policy: IPolicy) => async (dispatch) => {
  dispatch(SelectPolicyLoaded(policy));
  SetRenewalsByPolicy(policy)(dispatch);
};

export const ResetSelectedPolicy = () => async (dispatch, getState) => {
  const {
    policies: { selectedPolicy },
  }: IAppState = getState();
  if (selectedPolicy) {
    dispatch(ResetPolicyFormAction());
    dispatch(SelectPolicyRenewalLoaded([]));
  }
};

export const GetPolicyScanByPolicyId = (policyId: string) => async (
  dispatch,
) => {
  const result = await getPolicyScanByPolicyId(policyId);
  dispatch(GetPolicyScanByPolicyIdLoaded(result.data.Items));
};

export const CreatePolicyScan = (policy: IPolicy, user: IUser) => async (
  dispatch,
) => {
  try {
    dispatch(CreatePolicyScanLoading());
    const result = await createPolicyScan({
      // When we have fullAddress, the address prop will be empty
      // In this case, we omit user address so it doesnt get included into the policy scan
      ...R.omit(['address'], user),
      ...policy,
      policyId: policy.id,
    });
    dispatch(CreatePolicyScanLoaded(result.data));
    dispatch(UpdateUserLoaded(user));
    dispatch(GetUserPolicies());
  } catch {
    dispatch(CreatePolicyScanFailed());
    ErrorToast.errorGeneric();
  }
};

export const CreatePolicyScanWithoutPolicy = (
  policy: IPolicy,
  user: IUser,
) => async (dispatch) => {
  try {
    dispatch(CreatePolicyScanLoading());
    const result = await createPolicyScanNewPolicy({
      ...user,
      ...policy,
    });
    dispatch(CreatePolicyScanWithoutPolicyLoaded(result.data));
    dispatch(UpdateUserLoaded(user));
    dispatch(GetUserPolicies());
  } catch {
    dispatch(CreatePolicyScanFailed());
    ErrorToast.errorGeneric();
  }
};

export const UpdatePolicyScan = (payload: IPolicyScan) => async (dispatch) => {
  dispatch(UpdatePolicyScanLoaded(payload));
  updatePolicyScan(payload);
};

export const GetPendingPolicyScan = (userId: string) => async (dispatch) => {
  const result = await getPendingPolicyScanByUserId(userId);
  dispatch(GetPendingPolicyScanLoaded(result.data.Items));
};

export const GetPolicyScanByUserId = (userId?: string) => async (
  dispatch,
  getState,
) => {
  const { user }: IAppState = getState();
  const id = userId || user.id;
  const result = await getPolicyScanByUserId(id);
  dispatch(GetPolicyScanLoaded(result.data.Items));
};

export const SetPolicies = (policies: IPolicy[]) => async (dispatch) => {
  dispatch(GetUserPoliciesLoaded(policies));
};

export const CreatePolicies = (policies: ICreatePolicyProps[]) => async (
  dispatch,
) => {
  dispatch(CreatePolicyLoading());
  const results = await Promise.all(
    policies.map((policy) => createPolicy(R.assoc('effective', true, policy))),
  );
  const data: IPolicy[] = results.map(R.prop('data'));
  dispatch(CreatePoliciesLoaded(data));
};

export const DeletePolicies = (ids: string[], userId?: string) => async (
  dispatch,
) => {
  dispatch(DeletePolicyLoading());
  const results = await Promise.all(ids.map((id) => deletePolicy(id, userId)));
  dispatch(DeletePoliciesLoaded(ids));
};

export const GetPolicyBundlesByUserId = (id: string) => async (dispatch) => {
  const result = await getBundlesForUser(id);
  dispatch(GetPolicyBundlesLoaded(result.data));
};

export const CreatePolicyBundle = (attachments) => async (
  dispatch,
  getState,
) => {
  dispatch(CreatePolicyLoading());
  try {
    const { user } = getState();
    const res = await createBundleWithAttachments(attachments);

    // Show generic error toast on upload error
    res.forEach(({ success, file }) => {
      if (!success) {
        toast.fileUploadErrorToast({
          filename: file.name,
        });
      }
    });

    trackUploads(user, dispatch);
    dispatch(CreatePolicyBundleLoaded([]));
    dispatch(GetPolicyBundlesByUserId(user.id));
  } catch {
    ErrorToast.error({ message: UPLOAD_FAILED_MESSAGE });
    dispatch(CreatePolicyFailed());
  }
};

export const ActivatePolicyScanPolicy = (policyScan: IPolicyScan) => async (
  dispatch,
  getState,
) => {
  dispatch(CreatePolicyLoading());
  try {
    const { user }: IAppState = getState();
    const { data } = await getPolicyById(policyScan.sk, user.currentFamilyId);

    if (data.Items.length > 0) {
      const policy = data.Items[0];
      const incomplete = {
        ...policy,
        status: PolicyStatus.PENDING_SCAN,
        processed: true,
      };
      const { data: updated } = await updatePolicy(incomplete);

      // We add policy to reducer then open the dashboard page
      dispatch(CreatePolicyLoaded(updated));
      history.push(HOME_ROUTE);
    } else {
      dispatch(CreatePolicyScanFailed());
    }
  } catch {
    ErrorToast.error({ message: ACTIVATE_POLICY_SCAN_ERROR });
    dispatch(CreatePolicyScanFailed());
  }
};

export const HandlePolicyScanNotification = (policyScan: IPolicyScan) => async (
  dispatch,
  getState,
) => {
  dispatch(CreatePolicyLoading());
  try {
    const { user }: IAppState = getState();
    const { data } = await getPolicyById(policyScan.sk, user.currentFamilyId);
    const policy = data.Items[0];
    if (policy) {
      dispatch(GetPolicyScanByPolicyIdLoaded(policyScan));
      history.push(MARKET_SCAN_ROUTE, {
        endScreen: true,
        showSave: policy.status === PolicyStatus.MARKET_SCAN_HOLDER,
      });
    } else {
      dispatch(CreatePolicyScanFailed());
    }
  } catch {
    ErrorToast.error({ message: GET_POLICY_SCAN_ERROR });
    dispatch(CreatePolicyScanFailed());
  }
};

export const LoadPolicyByIdAndSelect = (policyId) => async (
  dispatch,
  getState,
) => {
  const { user, policies }: IAppState = getState();
  const id = user.currentFamilyId;
  dispatch(PaymentLoading(true));
  const { data } = await getPolicyById(policyId, id);
  const policy = data.Items[0];
  const newPolicies = policies.policies.filter((p) => p.id !== policyId);
  newPolicies.push(policy);
  dispatch(GetUserPoliciesLoaded(newPolicies));
  dispatch(PaymentLoading(false));
  history.push(getPolicyDetailsRoute(policy.id));
};

export const ConfirmPolicyScanPurchase = (
  policyScanId: string,
  policyId: string,
) => async (dispatch) => {
  const { data } = await confirmPolicyScanPurchase(policyScanId, policyId);
  const { updatedPolicy, updatedPolicyScan, deletedPolicyId } = data;
  dispatch(DeletePolicyLoaded(deletedPolicyId));
  dispatch(UpdatePolicyScanLoaded(updatedPolicyScan));
  dispatch(UpdatePolicyLoaded(updatedPolicy));
};
