import { updateListByItem, insertItem, removeItemsByProp } from 'helpers';
import { IPolicyRenewal, IPolicyRenewalState } from '../../types';
import { AppActionTypes } from '../actions';
import { Types } from '../actions/policy-renewal-actions';

const filterRenewalsByPending = (renewals: IPolicyRenewal[], pending: IPolicyRenewal[]) => {
  return renewals.filter((r) => {
    // Manual renewal does not set processed
    // if (!r.completed && (r.processed === false || (r.processed && !r.finished))) {
    //   acc.pending.push(r);
    // } else {
    //   acc.processed.push(r);
    // }
    // return acc;
    return !pending.find(R.propEq('id', r.id));
  });
};

const policyRenewalReducer = (
  state: IPolicyRenewalState,
  action: AppActionTypes
): IPolicyRenewalState => {
  switch (action.type) {
    case Types.GET_PENDING_RENEWALS_LOADED:
      return {
        ...state,
        pendingRenewals: action.data,
      };
    case Types.RESET_RENEWAL_SUBMIT:
      return {
        ...state,
        submitting: false,
        submitted: false,
      };
    case Types.POLICY_RENEWAL_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case Types.POLICY_RENEWAL_FAILED:
      return {
        ...state,
        submitting: false,
        submitted: true,
      };
    case Types.DELETE_RENEWAL_LOADED:
      return {
        ...state,
        renewals: removeItemsByProp(state.renewals, 'id', action.data),
      };
    case Types.DELETE_PENDING_RENEWAL_LOADED:
      return {
        ...state,
        pendingRenewals: removeItemsByProp(state.pendingRenewals, 'id', action.data),
      };
    case Types.SELECT_RENEWAL_LOADED:
      return {
        ...state,
        renewals: filterRenewalsByPending(action.data, state.pendingRenewals),
      };
    case Types.CREATE_RENEWAL_LOADED:
      return {
        ...state,
        renewals: [action.data, ...state.renewals],
        submitting: false,
        submitted: true,
      };
    case Types.CREATE_PENDING_RENEWAL:
      return {
        ...state,
        pendingRenewals: insertItem(state.pendingRenewals, action.data),
        submitting: false,
        submitted: true,
      };
    case Types.UPDATE_RENEWAL_LOADED:
      return {
        ...state,
        renewals: updateListByItem(
          state.renewals,
          action.data
        ),
        submitting: false,
        submitted: true,
      };
    case Types.UPDATE_PENDING_RENEWAL_LOADED:
      return {
        ...state,
        pendingRenewals: updateListByItem(
          state.pendingRenewals,
          action.data
        ),
        submitting: false,
        submitted: true,
      };
    default:
      return state;
  }
};

export default policyRenewalReducer;