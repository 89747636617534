// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1yhRSd7p4K8mvHaF7qEc9W{display:inline-block;border-bottom:1px solid #000;border-radius:initial;vertical-align:bottom}._3bJl9MzOhSWix6v2UhJIix{border:none;height:22px;box-shadow:none !important}._3bJl9MzOhSWix6v2UhJIix>input{box-shadow:none;font-size:18px;width:135px;text-align:center}._3bJl9MzOhSWix6v2UhJIix>span{display:none}._3Zc-MkEiXqDSfRGj6sl7d5{position:relative;flex:1}._3Eb94bq9DLNUe97OSueUYS{position:absolute;top:0;left:0;height:100%;width:100%;min-width:100%;opacity:0;z-index:1}._3Eb94bq9DLNUe97OSueUYS::-webkit-calendar-picker-indicator{background:rgba(0,0,0,0);color:rgba(0,0,0,0);position:absolute;cursor:pointer;left:-24px;top:0;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"date": "_1yhRSd7p4K8mvHaF7qEc9W",
	"dateContainer": "_3bJl9MzOhSWix6v2UhJIix",
	"wrap": "_3Zc-MkEiXqDSfRGj6sl7d5",
	"hiddenInput": "_3Eb94bq9DLNUe97OSueUYS"
};
module.exports = exports;
