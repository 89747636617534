import { updateListByItem } from "helpers";
import { IInvitationState } from "../../types";
import { AppActionTypes } from "../actions";
import { Types } from "../actions/invitation-actions";

const invitationReducer = (
  state: IInvitationState,
  action: AppActionTypes
): IInvitationState => {
  switch (action.type) {
    case Types.SHOW_CREATE_INVITATION_FORM:
      return { ...state, showCreateInvitationPage: action.data };
    case Types.INVITATION_LOADING:
      return { ...state, loading: true, error: null };
    case Types.CREATE_INVITATION_LOADED:
      return { ...state, invitations: [...state.invitations, action.data], loading: false };
    case Types.DELETE_INVITATION_LOADED:
      const newInvitationsAfterDelete = state.invitations.filter(
        i => i.id !== action.data.id
      );
      return {
        ...state,
        loading: false,
        invitations: [...newInvitationsAfterDelete]
      };
    case Types.GET_INVITATIONS_LOADED:
      return { ...state, loading: false, invitations: action.data };
    case Types.GET_SHARED_INVITATIONS_LOADED:
      return { ...state, loading: false, sharedInvitations: action.data };
    case Types.PROCESS_INVITATION_LOADED:
      return { ...state, loading: false, invitaitonProcessed: action.data };
    case Types.INVITATION_FAILED:
      return { ...state, loading: false, error: action.data };
    case Types.CLEAR_INVITATION_ERRORS:
      return { ...state, error: null };
    case Types.UPDATE_INVITATION_LOADED:
      return { ...state, invitations: updateListByItem(state.invitations, action.data) };
    default:
      return state;
  }
};

export default invitationReducer;
