import { Action } from 'redux';

declare const close: () => void;

import { IAppState, IPolicy, IPolicyRenewal } from 'types';
import {
  getPolicyRenewalById,
  getPolicyById,
  updatePolicyRenewal,
  updatePolicy,
} from 'services';
import { convertToDateOnlyString, sortDateDesc } from 'helpers';

import { Loading, Loaded } from './operation-actions';
import moment from 'moment';
import { AdminUpdatePolicy } from './operation-policy-actions';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';

export enum Types {
  OPERATION_POLICY_RNEWAL_DETAILS_LOADED = 'OPERATION_POLICY_RNEWAL_DETAILS_LOADED',
  OPERATION_UPDATE_POLICY_RENEWAL = 'OPERATION_UPDATE_POLICY_RENEWAL',
}

export interface IOperationUpdatePolicyRenewal extends Action {
  type: Types.OPERATION_UPDATE_POLICY_RENEWAL;
  data: IPolicyRenewal;
}

export const OperationUpdatePolicyRenewal = (data: IPolicyRenewal) =>
  ({
    type: Types.OPERATION_UPDATE_POLICY_RENEWAL,
    data,
  } as IOperationUpdatePolicyRenewal);

export interface IOperationPolicyRenewalDetailsloaded extends Action {
  type: Types.OPERATION_POLICY_RNEWAL_DETAILS_LOADED;
  data: {
    policy: IPolicy;
    policyRenewal: IPolicyRenewal;
  };
}

export const OperationPolicyRenewalDetailsloaded = (data: {
  policy: IPolicy;
  policyRenewal: IPolicyRenewal;
}) =>
  ({
    type: Types.OPERATION_POLICY_RNEWAL_DETAILS_LOADED,
    data,
  } as IOperationPolicyRenewalDetailsloaded);

export const OperationLoadPolicyRenewalDetails =
  (id: string, sk: string, familyId: string) => async (dispatch) => {
    try {
      dispatch(Loading());
      const response = await getPolicyRenewalById(id, sk);
      const policyResponse = await getPolicyById(sk, familyId);
      const data = {
        policyRenewal: response.data,
        policy: policyResponse.data.Items[0],
      };
      if (
        data.policy.policyType === INSURANCE_TYPES_NAMES.LIFE &&
        !data.policyRenewal.newPolicyData.insured
      ) {
        data.policyRenewal.newPolicyData.insured = [];
        data.policyRenewal.newPolicyData.policyTypeId =
          data.policy.policyTypeId;
      }
      dispatch(
        OperationPolicyRenewalDetailsloaded({
          policyRenewal: response.data,
          policy: policyResponse.data.Items[0],
        }),
      );
      dispatch(Loaded());
    } catch (err) {
      console.error(err);
      alert('Operation - Failed OperationLoadPolicyRenewalDetails');
    }
  };

export const OperationSubmitUpdatePolicyRenewal =
  () => async (dispatch, getState) => {
    try {
      dispatch(Loading());

      const {
        operations: { policyRenewalDetails },
      } = getState() as IAppState;
      const policyRenewal = policyRenewalDetails.policyRenewal;
      const policy = policyRenewalDetails.policy;

      policyRenewal.policyId = policy.id;
      policyRenewal.renewalDate = policy.renewalDate;
      policyRenewal.premium = policy.premium;
      policyRenewal.finished = true;
      policyRenewal.renewalDate = convertToDateOnlyString(
        policyRenewal.renewalDate,
      );

      policyRenewal.newPolicyData = {
        ...policy,
        ...policyRenewal.newPolicyData,
      };

      await updatePolicyRenewal(policyRenewal);

      dispatch(
        OperationLoadPolicyRenewalDetails(
          policyRenewal.id,
          policyRenewal.sk,
          policy.sk,
        ),
      );

      dispatch(Loaded());

      const policyRenewalDate = moment(policy.renewalDate);
      const isFuture = policyRenewalDate.isAfter(moment());

      if (isFuture) {
        await updatePolicy({
          ...policy,
          policyRenewalAdded: true,
        });
      }
    } catch (err) {
      console.error(err);
      alert('Operation - Failed to update policy renewal');
    }
  };
