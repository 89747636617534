import { ICalendlyIFrameEvent } from 'types';
import { trackEvent } from '../helpers/segment';
import {
  CalendarLoading,
  CreateCalendlyEvent,
} from '../redux/actions/adviser-actions';
function isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
}

const EVENT_SCHEDULED = 'calendly.event_scheduled';
const CALENDLY_READY = 'calendly.event_type_viewed';

export const isCalendlyScheduledEvent = (e: MessageEvent<any>) => {
  const { event } = e.data as ICalendlyIFrameEvent;
  return event === EVENT_SCHEDULED;
};

export const getCalendlyEventUrl = R.path(['data', 'payload', 'event', 'uri']);

export const listenCalendlyEvents = (store) => {
  window.addEventListener('message', (e: MessageEvent<any>) => {
    if (isCalendlyEvent(e)) {
      const event = e.data as ICalendlyIFrameEvent;
      trackEvent(event.event, {});
      if (isCalendlyScheduledEvent(e)) {
        store.dispatch(CreateCalendlyEvent(event));
      } else if (event.event === CALENDLY_READY) {
        store.dispatch(CalendarLoading(false));
      }
    }
  });
};
