import React, { useEffect, useState } from 'react';
import { Calendar, Check, Edit3, Phone } from 'react-feather';
import Title from 'components/title';
import styles from '../styles/AddPolicyPage.module.scss';
import PolicyTabs from '../components/PolicyTabs';
import moment from 'moment';
import { logPageView, PAGE_VIEW_EVENTS, validatePhone } from 'helpers';
import RightArrow from '-!svg-react-loader!icons/layout/right-arrow.svg';
import DisclosureCard from '../components/DisclosureCard';

const PhoneCard = ({ error, isEdit, phone, onSave, setEdit, updatePhone }) => {
  return (
    <div className={classnames(styles.phoneContainer, error && styles.error)}>
      <div className={styles.left}>
        <Phone size={35} fill="#34363e" strokeWidth={0} />
        <RightArrow className={styles.rightArrow} />
      </div>
      <div className={styles.right}>
        <div className={styles.phone}>
          <div className={styles.label}>
            Call me ASAP through this phone number
          </div>
          <input
            onChange={(e) => updatePhone(e.target.value)}
            value={phone}
            disabled={!isEdit}
          />
        </div>

        {isEdit ? (
          <div className={styles.save} onClick={onSave}>
            <u>Save</u>
            <div className={styles.saveCircle}>
              <Check size={10} strokeWidth={4} />
            </div>
          </div>
        ) : (
          <div className={styles.edit} onClick={() => setEdit(true)}>
            <Edit3 size={15} />
            <u>edit</u>
          </div>
        )}
      </div>
    </div>
  );
};

const MeetingCard = ({ meeting }) => {
  return (
    <div className={styles.phoneContainer}>
      <div className={styles.left}>
        <Calendar size={35} />
        <RightArrow className={styles.rightArrow} />
      </div>

      <div className={styles.right}>
        <div className={styles.phone}>
          <div className={styles.label}>
            Meeting scheduled through Calendly on
          </div>
          {meeting && (
            <>
              <div className={styles.date}>
                {moment(meeting.start_time).format('ddd, DD MMM YYYY')}
              </div>
              <div className={styles.time}>{`${moment(
                meeting.start_time,
              ).format('h:mma')} - ${moment(meeting.end_time).format(
                'h:mma',
              )}`}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AddPolicyPage = ({
  adviserConnect,
  loading,
  meeting,
  phone,
  referenceData,
  addPolicy,
  editPolicy,
  updatePhone,
  updateAdviserConnect,
  uploadAttachments,
}) => {
  const [error, setError] = useState(false);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 4 });
  }, []);

  const handlePhoneSave = () => {
    if (validatePhone(phone)) {
      setEdit(false);
      setError(false);

      if (phone !== adviserConnect.phoneNumber) {
        updateAdviserConnect({
          phoneNumber: phone,
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <Title
          title="One last thing!"
          subtitle="Before we call you, please attach all relevant documents for current insurance policies that you hold. 
        Simply drag your files into the box below, or fill in the details manually. Our advisor will review your current cover and create a personalised dashboard for you."
          subtitleClassName={styles.subTitleDropoff}
        />
        <PolicyTabs
          adviserConnect={adviserConnect}
          loading={loading}
          referenceData={referenceData}
          uploadAttachments={uploadAttachments}
          addPolicy={addPolicy}
          editPolicy={editPolicy}
        />
      </div>
      <div className={styles.confirmedDiv}>
        <Title
          title="Your call is confirmed!"
          subtitle="Sit back and relax. You're in good hands with our adviser. We will be in touch soon."
          subtitleClassName={styles.subTitleConfirm}
        />
        <div className={styles.confirmedContent}>
          {meeting ? (
            <MeetingCard meeting={meeting} />
          ) : (
            <PhoneCard
              error={error}
              isEdit={isEdit}
              phone={phone}
              onSave={handlePhoneSave}
              setEdit={setEdit}
              updatePhone={updatePhone}
            />
          )}
          <DisclosureCard adviser={adviserConnect.adviser} />
        </div>
      </div>
    </div>
  );
};

export default AddPolicyPage;
