import React, { useMemo } from 'react';
import { Plus, X } from 'react-feather';
import { FieldTypeMap } from 'components/form-fields-v2';
import styles from './MultiRow.module.scss';

interface IProps {
  fields: any[];
  className?: string;
  disabled?: boolean;
  error?: boolean | string;
  containerClassName?: string;
  inputClassName?: string;
  hideAddButton?: boolean;
  placeholder?: string;
  rowCount?: number | string;
  formValues: any;
  name: string;
  value: any;
  onBlur?(event: { target: any; type?: any }): void;
  onChange(event: { target: any; type?: any }): void;
}

const getRows = (value, rowCount = 0) => {
  if (rowCount) {
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(value?.[i] || {});
    }
    return rows;
  }
  return value || [{}];
};

const MultiRow = ({
  className,
  containerClassName,
  inputClassName,
  error,
  fields,
  hideAddButton,
  name,
  rowCount,
  value: valueProps,
  formValues,
  onChange,
}: IProps) => {
  const value = useMemo(() => {
    const rows = typeof rowCount === 'string' ? formValues[rowCount] : rowCount;
    return getRows(valueProps, rows);
  }, [formValues, rowCount, valueProps]);

  const handleAdd = () => {
    onChange({
      target: {
        name,
        value: [...value, {}],
      },
    });
  };

  return (
    <div className={classnames(styles.container, containerClassName)}>
      {value.map((v, idx) => {
        const rowKey = `row_${idx}`;

        const handleChange = (e) => {
          const toUpdate = {
            ...value[idx],
            [e.target.name]: e.target.value,
          };

          const updatedValues = R.update(idx, toUpdate, value);

          onChange({
            target: {
              name,
              value: updatedValues,
            },
          });
        };

        const handleRemove = () => {
          if (idx > 0) {
            onChange({
              target: {
                name,
                value: R.remove(idx, 1, value),
              },
            });
          }
        };

        const hasLabel = fields.find(R.propEq('type', 'label'));

        return (
          <div
            className={classnames(styles.row, hasLabel && styles.labelRow)}
            key={rowKey}
          >
            {fields.map((field, fieldIdx) => {
              const fieldKey = `${rowKey}_field_${field.key}`;

              if (field.type === 'label') {
                return (
                  <div key={fieldKey} className={styles.col}>
                    <label>{`${field.prefix} ${idx + 1}`}</label>
                  </div>
                );
              }

              const FieldComponent = FieldTypeMap[field.type];
              const fieldValue = v[field.key];
              const fieldError = error && field.required && R.isNil(fieldValue);

              return (
                <div key={fieldKey} className={styles.col}>
                  <div className={styles.header}>
                    {field.header}
                    {idx > 0 && fieldIdx === 0 && (
                      <div
                        className={styles.removeLabel}
                        onClick={handleRemove}
                      >
                        Remove
                        <X size={16} />
                      </div>
                    )}
                  </div>
                  <FieldComponent
                    className={className}
                    errorClassName={styles.error}
                    error={fieldError}
                    inputClassName={inputClassName}
                    basic={true}
                    {...field}
                    name={field.key}
                    value={fieldValue}
                    onChange={handleChange}
                  />
                </div>
              );
            })}
            {idx > 0 && !hideAddButton && (
              <X
                className={styles.removeBtn}
                size={16}
                onClick={handleRemove}
              />
            )}
          </div>
        );
      })}
      {!hideAddButton && (
        <div className={styles.btnContainer}>
          <div className={styles.addBtn} onClick={handleAdd}>
            Add another <Plus size={16} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiRow;
