// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3oVcvk7Fu9sZHhSyTV1dWh{position:relative;display:flex;flex-direction:row;background-color:#f1f3fb;margin-left:auto;align-items:center;border-radius:5px;padding-right:16px;padding-left:16px;width:515px}._3j89-QIzVHudjFBtgha5ts{margin:-24px 16px 0 8px;width:auto;height:120px}._1yaRmA-su_5cjwObYByXtl{font-family:\"Open Sans\";font-weight:600;font-size:16px}._1Hby-MrT9qr-c3FEdzaHSt{font-family:\"Open Sans\";font-size:16px}._375-h-l1My6Km5PKbk-m1r{color:#34363e;text-decoration:underline}._375-h-l1My6Km5PKbk-m1r:hover{color:#34363e;opacity:.7}@media screen and (max-width: 1320px){._3oVcvk7Fu9sZHhSyTV1dWh{margin-left:0;margin-top:40px}}@media(max-width: 820px){._3oVcvk7Fu9sZHhSyTV1dWh{width:100%;padding:16px}._3j89-QIzVHudjFBtgha5ts{height:100px;margin-bottom:-24px}}@media(max-width: 576px){._1yaRmA-su_5cjwObYByXtl{font-size:12px}._1Hby-MrT9qr-c3FEdzaHSt{font-size:12px}}", ""]);
// Exports
exports.locals = {
	"disclosureCard": "_3oVcvk7Fu9sZHhSyTV1dWh",
	"adviserImage": "_3j89-QIzVHudjFBtgha5ts",
	"heading": "_1yaRmA-su_5cjwObYByXtl",
	"contentText": "_1Hby-MrT9qr-c3FEdzaHSt",
	"disclosureLink": "_375-h-l1My6Km5PKbk-m1r"
};
module.exports = exports;
