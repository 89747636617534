export enum FormIds {
  POLICY_SCAN_SURVEY_FORM = 'policy-scan-survey-form',

  // MS Broker Forms
  BROKER_FORM_CONTENTS = 'broker-form-contents',
  BROKER_FORM_VEHICLE = 'broker-form-vehicle',
  BROKER_FORM_HOUSE = 'broker-form-house',
  BROKER_FIRST_FORM_CONTENTS = 'broker-first-form-contents',
  BROKER_FIRST_FORM_VEHICLE = 'broker-first-form-vehicle',
  BROKER_FIRST_FORM_HOUSE = 'broker-first-form-house',
  BROKER_FIRST_FORM_LANDLORD = 'broker-first-form-landlord',
  BROKER_FIRST_FORM_CONTENTS_V2 = 'broker-first-form-contents-2',
  BROKER_FIRST_FORM_VEHICLE_V2 = 'broker-first-form-vehicle-2',
  BROKER_FIRST_FORM_HOUSE_V2 = 'broker-first-form-house-2',
  BROKER_FIRST_FORM_LANDLORD_V2 = 'broker-first-form-landlord-2',

  // Premium
  ADVISER_SCAN_ORDER_FORM = 'adviser-scan-order-form',
  PREMIUM_UNDERWRITING_FORM = 'premium-underwriting-form',

  // Insurance checkup
  INSURANCE_CHECKUP_FORM = 'insurance-checkup',
}
