import { MeetingStatus } from 'constants/adviser';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { getFieldValueByPolicyType } from 'helpers';
import { IAdviserConnect, IPolicy } from 'types';

export const PREMIUM_GENERAL_POLICY_TYPES = [
  INSURANCE_TYPES_NAMES.HOME,
  INSURANCE_TYPES_NAMES.CONTENT,
  INSURANCE_TYPES_NAMES.VEHICLE,
  INSURANCE_TYPES_NAMES.LANDLORD,
];

export const PREMIUM_POLICY_TYPES = [
  ...PREMIUM_GENERAL_POLICY_TYPES,
  INSURANCE_TYPES_NAMES.LIFE,
  INSURANCE_TYPES_NAMES.COMMERCIAL,
];

export const getPoliciesForAdviserConnect = (
  adviserConnect: IAdviserConnect,
) => {
  if (adviserConnect) {
    const policies = adviserConnect.adviserConnectPolicies || [];
    return R.groupBy(R.prop('policyType'), policies);
  }
  return [];
};

export const getItemName = (policy: IPolicy) => {
  const insured = getFieldValueByPolicyType(policy);
  if (policy.policyType === INSURANCE_TYPES_NAMES.VEHICLE && policy.make) {
    return `${insured}, ${policy.make} ${policy.model}`;
  }

  return insured;
};

export const hasSelectedPolicy = (policyCount: any) => {
  return PREMIUM_POLICY_TYPES.find((pt) => !!(policyCount[pt] || 0));
};

export const showAddPolicyPage = (meetingStatus: string) => {
  return R.includes(meetingStatus, [
    MeetingStatus.ADVISER_ACTION_CONTACT_REQUESTED,
  ]);
};

export const showMarketScanFormsPage = (meetingStatus: string) => {
  return R.includes(meetingStatus, [
    MeetingStatus.CLIENT_ACTION_AWAITING_FORMS_COMPLETION,
    MeetingStatus.ADVISER_ACTION_OBTAIN_QUOTES,
  ]);
};

export const allMarketScanFormsCompleted = (policyScans) => {
  return !Object.keys(policyScans).find((pt) => {
    const { count, items = [] } = policyScans[pt];
    const completed = items.filter(isMSCompleted);
    return completed.length < count;
  });
};

export const isMSCompleted = ({ brokerFormResults }) =>
  brokerFormResults?.percentCompleted === 100;
