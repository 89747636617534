import React from 'react';
import { Star } from 'react-feather';
import DOMPurify from 'dompurify';
import styles from './styles/Ratings.module.scss';

const Ratings = ({ value, name, options, onChange }) => {
  const sorted = R.sortBy(R.prop('order'), options);

  const handleChange = (optionValue: string) => {
    if (onChange) {
      const target = { name, value: optionValue };
      onChange({ target });
    }
  };

  return (
    <div className={styles.container}>
      {sorted.map(({ value: optionValue, text, rating, description }) => {
        const isSelected = value === optionValue;
        return (
          <div
            key={optionValue}
            onClick={() => handleChange(optionValue)}
            className={classnames(styles.item, isSelected && styles.selected)}
          >
            <div className={styles.content}>
              <div className={styles.name}>
                <b>{text}</b>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              />
            </div>
            <div className={styles.rating}>
              <span>
                <b>Coverage level</b>
              </span>
              {[...Array(5)].map((_, i) => (
                <Star
                  key={`${name}-star-${i}`}
                  className={classnames(styles.star, i < rating && styles.fill)}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.forwardRef(Ratings);
