// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1qPJB5uyO-dL_ALHf6hY8v{max-width:650px}.HRlT6OSCW4Vr1MdicMbVA{max-width:100%}.HRlT6OSCW4Vr1MdicMbVA .ALMS4gNobNYwvAHScaNNu{display:none}.ALMS4gNobNYwvAHScaNNu{margin-bottom:24px}", ""]);
// Exports
exports.locals = {
	"container": "_1qPJB5uyO-dL_ALHf6hY8v",
	"introContainer": "HRlT6OSCW4Vr1MdicMbVA",
	"stepper": "ALMS4gNobNYwvAHScaNNu"
};
module.exports = exports;
