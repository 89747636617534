import moment from 'moment';
import { assoc, pathOr, propEq, propOr } from 'ramda';
import {
  INSURANCE_QUESTIONS_BY_TYPE,
  INSURANCE_TYPES_NAMES,
} from 'constants/insurance';
import { FREQUENCY, YEARLY_MULTIPLIERS } from 'constants/options';
import { IPolicy, IPolicyProvider, IPolicyRenewal } from 'types';
import { getUpcomingRenewal } from './policy';
import { shouldShowQuestion } from './typeForm';
import { PolicyStatus } from 'constants/policy';
import { getConfig } from './storage';
import { FULL_ADDRESS_OTHER_QUESTIONS } from 'constants/questions';

export const getFieldByPolicyType = (policyType) =>
  pathOr('', [policyType, 0], INSURANCE_QUESTIONS_BY_TYPE);

export const getFieldsByPolicyType = (policyType) =>
  propOr([], policyType, INSURANCE_QUESTIONS_BY_TYPE);

export const getFieldValueByPolicyType = (policy) => {
  const fieldKey = getFieldByPolicyType(policy.policyType).fieldKey;
  if (policy.policyType === INSURANCE_TYPES_NAMES.LIFE) {
    const insured = policy.insured || [];
    return insured.map(R.prop(fieldKey)).join(', ');
  }
  return policy[fieldKey];
};

export const convertFrequency = (
  value,
  policyFrequency,
  dashboardFrequency,
) => {
  return (
    (value * YEARLY_MULTIPLIERS[policyFrequency]) /
    YEARLY_MULTIPLIERS[dashboardFrequency]
  );
};

export const shouldShowPremiumWarning = (policy) => {
  if (!policy.frequency || policy.frequency === FREQUENCY.ONE_OFF) {
    return false;
  }

  const convertedPremium = convertFrequency(
    policy.premium,
    policy.frequency,
    FREQUENCY.YEARLY,
  );

  return convertedPremium > 10000 && !policy.premiumConfirmed;
};

export const getRenewalUpdateFormValues = (
  selectedPolicy: IPolicy,
  selectedPolicyRenewals: IPolicyRenewal[],
) => {
  const upcomingRenewal = getUpcomingRenewal(selectedPolicyRenewals);
  if (upcomingRenewal) {
    return upcomingRenewal.newPolicyData;
  }
  const nextRenewalDate = moment(selectedPolicy.renewalDate)
    .add(1, 'year')
    .utc()
    .format();
  return assoc('renewalDate', nextRenewalDate, selectedPolicy);
};

export const checkPolicyIsUnfinished = ({ status }: IPolicy) =>
  status === PolicyStatus.FINISH_SETUP;

export const getSubTypeOptions = (typeProviderMap, policyTypeName: string) => {
  const typeProvider =
    typeProviderMap.find(propEq('policyTypeName', policyTypeName)) || {};
  const subTypes = propOr([], 'policySubTypes', typeProvider);
  return subTypes.map(({ id, name }) => ({
    key: id,
    text: name,
    value: id,
  }));
};

export const isMultipleFieldKey = (fieldKey) => (_, key) => {
  const regex = new RegExp(`${fieldKey}\\d+`, 'g');
  return regex.test(key);
};

export const pickMultipleFieldValues = (fieldKey, form) =>
  R.pickBy(isMultipleFieldKey(fieldKey), form);

export const isEqualCaseInsensitive = (str1: string = '', str2: string = '') =>
  str1.toLowerCase() === str2.toLowerCase();

export const isDropdownField = (type) =>
  type === 'dropdown' || type === 'search';

export const validateRequired = (fields, form) => {
  return fields.reduce((acc, { key, required, validator = R.T }) => {
    const value = form[key];
    if (
      (required && !value) ||
      // Only validate fields with a validator when they have a value
      (value && !validator(value))
    ) {
      return R.assoc(key, true, acc);
    }
    return acc;
  }, null);
};

export const validateForm = (questions, values, data = null) => {
  return questions.reduce((acc, q) => {
    const { key, fieldKey, inputType, valueKey, optional, validator = R.T } = q;
    const rawValue = values[valueKey || key || fieldKey];
    const value = typeof rawValue === 'string' ? rawValue.trim() : rawValue;

    // Skip when question is optional
    if (
      (optional && validator(value, values, data)) ||
      !shouldShowQuestion(q, questions, values)
    ) {
      return acc;
    }

    if (
      R.isNil(value) ||
      R.isEmpty(value) ||
      (inputType === 'number' && parseFloat(value) === 0) ||
      !validator(value, values, data)
    ) {
      return R.assoc(key || fieldKey, true, acc);
    }
    return acc;
  }, null);
};

export const validateFormArray = (questions, values) => {
  const errors = values.map((val) => validateForm(questions, val, values));
  return R.all(R.isNil, errors) ? null : errors;
};

export const getNumberChangeHandler = (name, handleChange) => (value) => {
  handleChange({
    target: { name, value },
  });
};

export const addPremiums = (policies: IPolicy[], currentFrequency) => {
  const sum = policies.reduce(
    (total, { premium = 0, frequency, processed, status }) => {
      // Ignore One-off and IN_REVIEW policies
      if (
        !premium ||
        !processed ||
        !frequency ||
        frequency === FREQUENCY.ONE_OFF ||
        status === PolicyStatus.IN_REVIEW
      ) {
        return total;
      }
      const parsed = Number(premium);
      const multiplied = convertFrequency(parsed, frequency, currentFrequency);
      return total + multiplied;
    },
    0,
  );
  return sum.toFixed(2);
};

export const shouldHideField = (field, policy, options = []) => {
  return (
    field.exclude === policy.policyType ||
    // Hide policy subtype field when there are no options
    (field.fieldKey === 'policySubType' &&
      options.length === 0 &&
      !R.includes(policy.policyType, field.requiredFor || []))
  );
};

export const getContactData = (policyProviders, providerId) =>
  policyProviders.find(R.propEq('id', providerId));

export const mapProviderOptions = (insuranceProviders, providerData) => {
  return insuranceProviders.map((p) => {
    const contact = getContactData(providerData, p.key);
    return {
      ...p,
      image: { src: contact?.logoUrl },
    };
  });
};

export const getProviderOptionsWithImg = (
  insuranceProviders: IPolicyProvider[],
  providerReference,
  policy: IPolicy,
) => {
  const providers =
    insuranceProviders.length === 0 && policy.policyProviderId
      ? [
          {
            key: policy.policyProviderId,
            value: policy.policyProviderId,
            text: policy.policyProviderName,
          },
        ]
      : insuranceProviders;
  return mapProviderOptions(providers, providerReference);
};

export const toDropdownOptions = (
  items: string[],
  transformer: (t: string) => any = R.identity,
) => {
  return items.map((val) => ({
    key: transformer(val),
    text: transformer(val),
    value: transformer(val),
  }));
};

export const toDropdownOptionsWithTransformer = (
  items: string[],
  transformer: object,
) => {
  const defaultTransformer = {
    key: R.identity,
    text: R.identity,
    value: R.identity,
    ...transformer,
  };

  return items.map((val) =>
    R.evolve(defaultTransformer, {
      key: val,
      text: val,
      value: val,
    }),
  );
};

export const getChangeHandler = (form, field) => {
  if (field.getChangeHandler) {
    return field.getChangeHandler(form.setValues);
  }

  if (form[field.handlerName]) {
    return form[field.handlerName];
  }

  if (field.type === 'dropdown' || field.type === 'search') {
    return field.valueKey
      ? form.handleDropdownIdNameChange
      : form.handleDropdownChange;
  }

  return form.handleInputChange;
};

export const mapCoverTypeOptions = (coverTypes: string[], options: object) => {
  return coverTypes.map((item: string) => {
    return R.find(R.propEq('value', item))(options);
  });
};

export const clearAccidentHistory = (policy: IPolicy) => {
  const keysToOmit = [];

  if (!policy.accidentHistory) {
    keysToOmit.push(
      'accidentFault',
      'accidentClaimCount',
      'accidentClaimDate1',
      'accidentClaimDate2',
      'claimType1',
    );
  }

  if (policy.accidentClaimCount === 1) {
    keysToOmit.push('accidentClaimDate2');
  }

  // Motorcycle only
  if (policy.atFaultIncidents5Years === '0') {
    keysToOmit.push('atFaultIncidents1Year');
  }

  return R.omit(keysToOmit, policy);
};

const getAddressComponentByType = (addressComponents, type) => {
  const component = addressComponents.find((i) => R.includes(type, i.types));
  return component?.long_name || '';
};

export const getFullAddressFromAddressComponents = (addressComponents) => {
  return {
    flatNo: getAddressComponentByType(addressComponents, 'subpremise'),
    streetNo: getAddressComponentByType(addressComponents, 'street_number'),
    streetName: getAddressComponentByType(addressComponents, 'route'),
    suburb:
      getAddressComponentByType(addressComponents, 'sublocality') ||
      getAddressComponentByType(addressComponents, 'locality'),
    city: getAddressComponentByType(
      addressComponents,
      'administrative_area_level_1',
    ),
    postCode: getAddressComponentByType(addressComponents, 'postal_code'),
  };
};

export const isRiskZoneProperty = ({ postCode }, config) => {
  const postCodes = config?.settings.riskZonePostCodes || [];
  return R.includes(postCode, postCodes);
};

export const largeOutbuildsAreaWarning = (value) => {
  return value >= 200;
};

export const highPremiumWarning = (
  value: number,
  frequency: string,
): boolean => {
  return convertFrequency(value, frequency, FREQUENCY.YEARLY) > 10000;
};

export const getFullAddress = (values) => {
  const fullAddress = FULL_ADDRESS_OTHER_QUESTIONS.reduce((acc, q) => {
    const value = values[q.key];
    if (value) {
      return R.assoc(q.key, value, acc);
    }
    return acc;
  }, {});

  if (!R.isEmpty(fullAddress)) {
    return { ...fullAddress, lat: values.lat, lng: values.lng };
  }

  return values.fullAddress;
};

export const getGenericSubTypes = (referenceData, policy) => {
  const policyTypeData = referenceData?.wiki.find(
    R.propEq('policyType', policy.policyType),
  );
  const subTypes = policyTypeData?.data || [];
  return subTypes.sort(R.descend(R.prop('order')));
};
