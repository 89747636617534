// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2Qx2681H52GxJ5udfo8CPU{position:fixed;bottom:0;right:24px;max-width:270px;display:flex;flex-direction:column;align-items:flex-end;z-index:1}._2y5inLAR0Q6VQkj-mhp4Dj{display:flex;border-top-left-radius:10px;border-top-right-radius:10px;background-color:#444757;color:#fff;padding:12px 16px;width:270px;justify-content:center;margin-left:auto}._1xceYNWqRnXPjyILbmVpVS{background-color:#4187f5}._18cCem8oWcYLWPaldXGosV{width:40px;height:40px;margin-right:24px}._18cCem8oWcYLWPaldXGosV path{fill:#fff}.j8QjpSWs_fl9MGYXg1a0c{display:flex;flex-direction:column;justify-content:center;font-size:10px;cursor:pointer}.j8QjpSWs_fl9MGYXg1a0c b{display:flex;font-size:12px;font-weight:600;line-height:1;justify-content:space-between;align-items:center}._39677o9VlwM6eKgWcXOdTH{width:100%;min-width:500px;background-color:#fff;border-radius:10px 0px 10px 10px;transition:all 1000ms ease;max-height:0}._39677o9VlwM6eKgWcXOdTH._2G-weYvIckSvvQzzHF99YU{max-height:1000px}._1IMMmG6ZkSTCu-vOd6B6cw{height:100%;display:flex;border-top-left-radius:5px;flex-direction:column;background-color:#fff;padding:24px}._1llkiRZkiRCZkNrd0FYz4w{position:relative}._1llkiRZkiRCZkNrd0FYz4w span{display:block;position:absolute;font-size:10px;bottom:8px;right:16px;color:#787777}._3bTX6hbfGsNTB07GqgWMiS{font-family:\"Open Sans\";font-weight:400;border-radius:5px;min-height:65px;resize:none;width:100%;background-color:#fff;border:solid 1px #ebedf3;padding:16px;color:#34363e;min-height:200px}._3bTX6hbfGsNTB07GqgWMiS::-webkit-input-placeholder{color:#c7c7c7}._1xceYNWqRnXPjyILbmVpVS{margin-left:auto;margin-top:16px}._3IrxrHCYnvIoCB0a5s2z7u{padding:24px;text-align:center}._3IrxrHCYnvIoCB0a5s2z7u h3{font-family:\"Open Sans\";font-weight:700;color:#34363e;margin-top:16px}@media(max-width: 576px){._2Qx2681H52GxJ5udfo8CPU{right:8px;max-width:calc(100% - 16px);width:100%}._39677o9VlwM6eKgWcXOdTH{min-width:0}}", ""]);
// Exports
exports.locals = {
	"container": "_2Qx2681H52GxJ5udfo8CPU",
	"header": "_2y5inLAR0Q6VQkj-mhp4Dj",
	"blueBtn": "_1xceYNWqRnXPjyILbmVpVS",
	"logo": "_18cCem8oWcYLWPaldXGosV",
	"headerText": "j8QjpSWs_fl9MGYXg1a0c",
	"content": "_39677o9VlwM6eKgWcXOdTH",
	"show": "_2G-weYvIckSvvQzzHF99YU",
	"form": "_1IMMmG6ZkSTCu-vOd6B6cw",
	"inputContainer": "_1llkiRZkiRCZkNrd0FYz4w",
	"input": "_3bTX6hbfGsNTB07GqgWMiS",
	"submitted": "_3IrxrHCYnvIoCB0a5s2z7u"
};
module.exports = exports;
