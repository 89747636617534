import LogoLarge from '-!svg-react-loader!images/logo.svg';
import LogoSub from '-!svg-react-loader!images/logo-large.svg';
import LogoSmall from '-!svg-react-loader!images/logo-small.svg';
import styles from './logo.scss';

interface IProps {
  small?: boolean;
  sub?: boolean;
}

const Logo = ({ small, sub }: IProps) => {
  if (small) {
    return <LogoSmall className={classnames(styles.logo, styles.small)} />;
  }
  const LogoComponent = sub ? LogoSub : LogoLarge;
  return (
    <LogoComponent
      className={classnames(styles.logo, sub && styles.sub, styles.large)}
    />
  );
};

export default Logo;
