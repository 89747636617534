import React from 'react';
import cx from 'classnames';
import { getPolicyProviderById, prettifyNumber } from 'helpers';
import { isMarketValue } from 'components/market-scan-results/utils';
import { INSURANCE_TYPES_ICONS_SVG_V2 } from 'constants/insurance';
import { IAdviserConnectCartItem } from 'types';
import styles from './styles/ShoppingCartList.module.scss';

interface IProps extends IAdviserConnectCartItem {
  amount: string;
  excess: string;
  insuredItem: string;
  onRemove?(item: IAdviserConnectCartItem): void;
}

const ShoppingCartItem = (props: IProps) => {
  const {
    amount,
    excess,
    frequency,
    insuredItem,
    policyProviderId,
    policyType,
    premium,
    onRemove,
  } = props;
  const provider = getPolicyProviderById(policyProviderId);
  const IconSvg = INSURANCE_TYPES_ICONS_SVG_V2[policyType];
  const amountText = isMarketValue(
    provider.name,
    R.assoc('isBrokerFirst', true, props),
  )
    ? 'Market Value'
    : `${prettifyNumber(amount)} sum insured (incl. GST)`;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img alt={provider.name} src={provider?.logoUrl} />
        <div className={styles.title}>
          <div>{policyType} insurance</div>
          <div>{insuredItem}</div>
        </div>
        <div className={styles.policyType}>
          <IconSvg className={styles.policyTypeIcon} />
        </div>
      </div>
      <div className={styles.row}>
        <b>${prettifyNumber(premium)}</b> /
        {frequency.replace('ly', '').toLowerCase()}
      </div>
      <div className={styles.row}>{amountText}</div>
      <div className={styles.row}>${excess} excess</div>
      {onRemove && (
        <u className={styles.remove} onClick={() => onRemove(props)}>
          Remove
        </u>
      )}
    </div>
  );
};

const ShoppingCartList = ({ className, items, onRemove }) => {
  return (
    <div className={cx(styles.items, className)}>
      {items.map((item, idx) => (
        <ShoppingCartItem key={idx} {...item} onRemove={onRemove} />
      ))}
    </div>
  );
};

export default ShoppingCartList;
