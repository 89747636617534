// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3BTX0z3tq0UDVIC2fH7X9X ._2u-Hd-2bSkGBOMKn0xGLP3{border-color:#e47c6e}._3BTX0z3tq0UDVIC2fH7X9X ._2u-Hd-2bSkGBOMKn0xGLP3 ::-webkit-input-placeholder{color:#e47c6e}._22Qxw5zZhmokTIUngSwiPe{}._22Qxw5zZhmokTIUngSwiPe i{left:8px}._22Qxw5zZhmokTIUngSwiPe ._2u-Hd-2bSkGBOMKn0xGLP3._2nI-295ipeNXlKNTh4M_-g{border-color:#e47c6e;padding-left:24px}._2u-Hd-2bSkGBOMKn0xGLP3{border-bottom:solid 2px #34363e;width:100%}._2u-Hd-2bSkGBOMKn0xGLP3 input{font-size:14px;color:#34363e}._2u-Hd-2bSkGBOMKn0xGLP3._2nI-295ipeNXlKNTh4M_-g{border:solid 1px #ebedf3;border-radius:5px;padding:8px 16px}._2u-Hd-2bSkGBOMKn0xGLP3._2nI-295ipeNXlKNTh4M_-g input{text-align:left;padding:0;width:100%}._2u-Hd-2bSkGBOMKn0xGLP3 ::-webkit-input-placeholder{color:#c7c7c7}", ""]);
// Exports
exports.locals = {
	"error": "_3BTX0z3tq0UDVIC2fH7X9X",
	"date": "_2u-Hd-2bSkGBOMKn0xGLP3",
	"basicError": "_22Qxw5zZhmokTIUngSwiPe _3BTX0z3tq0UDVIC2fH7X9X",
	"basic": "_2nI-295ipeNXlKNTh4M_-g"
};
module.exports = exports;
