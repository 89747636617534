import { OCCUPATION_OPTIONS } from './occupations';

const getEnumOptions = (obj, textBefore = '') =>
  Object.keys(obj).map((k) => ({
    key: obj[k],
    text: `${textBefore}${obj[k]}`,
    value: obj[k],
  }));

enum Relationships {
  SPOUSE = 'Spouse',
  CHILD = 'Child',
  PARTNER = 'Partner',
  PARENT = 'Parent',
  SIBLING = 'Sibling',
  FRIEND = 'Friend',
}

export enum CoPayment {
  TEN = '10%',
  FIFTEEN = '15%',
  TWENTY = '20%',
  TWENTY_FIVE = '25%',
  THIRTY = '30%',
  THIRTY_FIVE = '35%',
  NONE = 'None',
}

export enum FREQUENCY {
  YEARLY = 'Yearly',
  SIX_MONTHLY = 'Six-Monthly',
  QUARTERLY = 'Quarterly',
  MONTHLY = 'Monthly',
  FORTNIGHTLY = 'Fortnightly',
  WEEKLY = 'Weekly',
  ONE_OFF = 'One-Off',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const YEARLY_MULTIPLIERS = {
  [FREQUENCY.YEARLY]: 1,
  [FREQUENCY.SIX_MONTHLY]: 2,
  [FREQUENCY.QUARTERLY]: 4,
  [FREQUENCY.MONTHLY]: 12,
  [FREQUENCY.FORTNIGHTLY]: 26,
  [FREQUENCY.WEEKLY]: 52,
};

export const TRAVEL_FREQUENCY_OPTIONS = [
  {
    key: FREQUENCY.ONE_OFF,
    text: FREQUENCY.ONE_OFF,
    value: FREQUENCY.ONE_OFF,
  },
  {
    key: FREQUENCY.YEARLY,
    text: FREQUENCY.YEARLY,
    value: FREQUENCY.YEARLY,
  },
];

export enum PolicySort {
  DEFAULT = 'Policy type',
  ALPHABETICAL = 'Alphabetical',
  DATE_ADDED = 'Date added',
  RENEWING_NEXT = 'Renewing next',
}

enum PaymentMethods {
  DIRECT_DEBIT_BANK = 'Direct debit - bank account',
  DIRECT_DEBIT_CARD = 'Direct debit - credit/debit card',
  ONLINE_PAYMENT = 'Online payment',
  INTERNET_BANKING = 'Internet banking transfer',
  CHEQUE = 'Cheque',
}

export enum PayoutFrequency {
  LUMP_SUM = 'Lump Sum',
  YEARLY = 'Yearly',
  SIX_MONTHLY = 'Six-Monthly',
  QUARTERLY = 'Quarterly',
  MONTHLY = 'Monthly',
  FORTNIGHTLY = 'Fortnightly',
  WEEKLY = 'Weekly',
}

export enum PayoutWaitPeriod {
  THIRTY_DAYS = '30 days',
  SIXTY_DAYS = '60 days',
  NINETY_DAYS = '90 days',
  FOUR_WEEKS = '4 weeks',
  EIGHT_WEEKS = '8 weeks',
  THIRTEEN_WEEKS = '13 weeks',
  TWENTY_SIX_WEEKS = '26 weeks',
  FIFTY_TWO_WEEKS = '52 weeks',
  ONE_HUNDRED_FOUR_WEEKS = '104 weeks',
}

export enum PayoutBenefitPeriod {
  THREE_MONTHS = '3 months',
  SIX_MONTHS = '6 months',
  ONE_YEAR = '1 year',
  TWO_YEARS = '2 years',
  FIVE_YEARS = '5 years',
  SIXTY_FIVE_YEARS = '65 years old',
  SEVENTY_YEARS = '70 years old',
}

export enum IncomeFrequency {
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum MotorcycleRegoStatus {
  ROAD_REGISTERED = 'roadregistered',
  REGISTRATION_PENDING = 'registrationpending',
  NOT_REQUIRED = 'notrequired',
}

export enum ParkingOptions {
  GARAGE = 'In a garage',
  PROPERTY = 'On own property',
  ROAD = 'On street or road',
  COMMUNAL_PARKING = 'In communal parking area',
}

export const useOptions = [
  { key: 'private', text: 'Private', value: 'private' },
  { key: 'business', text: 'Business', value: 'business' },
];

export const motorcycleUseOptions = [
  {
    key: 'Recreational riding',
    text: 'Recreational riding',
    value: 'Recreational riding',
  },
  { key: 'Commuting', text: 'Commuting', value: 'Commuting' },
  { key: 'Business use', text: 'Business use', value: 'Business use' },
];

export const parkingOptions = getEnumOptions(ParkingOptions);

export const areaOfGaragingOptions = [
  { key: 0, value: 'Auckland City', text: 'Auckland City' },
  {
    key: 1,
    value: 'Auckland West Country / Rodney / Gulf Islands',
    text: 'Auckland West Country / Rodney / Gulf Islands',
  },
  { key: 2, value: 'Bay Of Plenty', text: 'Bay Of Plenty' },
  { key: 3, value: 'Canterbury', text: 'Canterbury' },
  { key: 4, value: 'Hawkes Bay', text: 'Hawkes Bay' },
  {
    key: 5,
    value: 'Howick / Pakuranga / Beachlands',
    text: 'Howick / Pakuranga / Beachlands',
  },
  { key: 6, value: 'Manawatu / Wanganui', text: 'Manawatu / Wanganui' },
  { key: 7, value: 'Manukau City', text: 'Manukau City' },
  { key: 8, value: 'Marlborough', text: 'Marlborough' },
  { key: 9, value: 'Nelson', text: 'Nelson' },
  { key: 10, value: 'North Shore', text: 'North Shore' },
  { key: 11, value: 'Northland', text: 'Northland' },
  { key: 12, value: 'Otago', text: 'Otago' },
  { key: 13, value: 'Poverty Bay', text: 'Poverty Bay' },
  { key: 14, value: 'Pukekohe / Franklin', text: 'Pukekohe / Franklin' },
  { key: 15, value: 'Southland', text: 'Southland' },
  { key: 16, value: 'Taranaki', text: 'Taranaki' },
  { key: 17, value: 'Waikato', text: 'Waikato' },
  { key: 18, value: 'Wairarapa', text: 'Wairarapa' },
  { key: 19, value: 'Waitakere', text: 'Waitakere' },
  { key: 20, value: 'Wellington', text: 'Wellington' },
  { key: 21, value: 'West Coast', text: 'West Coast' },
];

export const yesNoOptions = [
  { key: 'no', value: false, text: 'No' },
  { key: 'yes', value: true, text: 'Yes' },
];

export const licenceYears = [
  { key: 'licenceYears1', value: 'less than 1 year', text: 'Less than 1 year' },
  { key: 'licenceYears2', value: '1-2 years', text: '1-2 years' },
  { key: 'licenceYears3', value: '2-4 years', text: '2-4 years' },
  {
    key: 'licenceYears4',
    value: 'more than 4 years',
    text: 'More than 4 years',
  },
];

export const licenceClassOptions = [
  { key: 'licenceClassOptions0', value: 'none', text: 'None' },
  { key: 'licenceClassOptions1', value: 'learner', text: 'Learner' },
  { key: 'licenceClassOptions2', value: 'restricted', text: 'Restricted' },
  { key: 'licenceClassOptions3', value: 'full', text: 'Full' },
];

export const motorcycleLicenseOptions = [
  {
    key: 'licenceClassOptions0',
    value: 'Full Motorcycle',
    text: 'Full Motorcycle',
  },
  {
    key: 'licenceClassOptions1',
    value: 'Restricted Motorcycle',
    text: 'Restricted Motorcycle',
  },
  {
    key: 'licenceClassOptions2',
    value: 'Learners Motorcycle',
    text: 'Learners Motorcycle',
  },
  {
    key: 'licenceClassOptions3',
    value: 'Car Licence Only',
    text: 'Car Licence Only',
  },
  {
    key: 'licenceClassOptions4',
    value: 'Restricted Car Licence Only',
    text: 'Restricted Car Licence Only',
  },
  {
    key: 'licenceClassOptions5',
    value: 'Learners Car Licence Only',
    text: 'Learners Car Licence Only',
  },
  { key: 'licenceClassOptions6', value: 'Unlicenced', text: 'Unlicenced' },
  { key: 'licenceClassOptions7', value: 'Not Required', text: 'Not Required' },
];

export const coverTypeOptions = [
  { key: 'coverTypeOptions0', value: 'full', text: 'Full' },
  { key: 'coverTypeOptions1', value: '3rd party', text: '3rd Party' },
  {
    key: 'coverTypeOptions2',
    value: '3rd partyfiretheft',
    text: '3rd Party Fire & Theft',
  },
];

export enum MOTORCYCLE_COVER_TYPE_NAMES {
  FULL = 'Full',
  THIRDPARTY_FIRE_THEFT = '3rd Party Fire & Theft',
  FIRE_THEFT_TRANSIT = 'Fire, Theft & Transit',
  STORAGE_COVER = 'Storage cover',
}

export const motorcycleCoverTypeOptions = [
  {
    key: 'coverTypeOptions0',
    value: 'full',
    text: MOTORCYCLE_COVER_TYPE_NAMES.FULL,
  },
  {
    key: 'coverTypeOptions2',
    value: 'tpft',
    text: MOTORCYCLE_COVER_TYPE_NAMES.THIRDPARTY_FIRE_THEFT,
  },
  {
    key: 'coverTypeOptions1',
    value: 'ftt',
    text: MOTORCYCLE_COVER_TYPE_NAMES.FIRE_THEFT_TRANSIT,
  },
  {
    key: 'coverTypeOptions3',
    value: 'fto',
    text: MOTORCYCLE_COVER_TYPE_NAMES.STORAGE_COVER,
  },
];

export const titleOptions = [
  { key: 'Mr', value: 'Mr', text: 'Mr' },
  { key: 'Mrs', value: 'Mrs', text: 'Mrs' },
  { key: 'Miss', value: 'Miss', text: 'Miss' },
  { key: 'Ms', value: 'Ms', text: 'Ms' },
  { key: 'Dr', value: 'Dr', text: 'Dr' },
];

export const incidents5YearsOptions = [
  { key: '0', value: 0, text: '0' },
  { key: '1', value: 1, text: '1' },
  { key: '2', value: 2, text: '2' },
  { key: '3', value: 3, text: '3' },
];

export const atFaultIncidents5YearsOptions = [
  { key: 0, value: '0', text: '0' },
  { key: 1, value: '1', text: '1' },
  { key: 2, value: '2', text: '2' },
  { key: 3, value: '3', text: '3' },
  { key: 4, value: 'more than 3', text: 'More than 3' },
];

export const atFaultIncidents5YearsMotorcycleOptions = [
  { key: 0, value: '0', text: '0' },
  { key: 1, value: '1', text: '1' },
  { key: 2, value: '2', text: '2' },
  { key: 3, value: '3 or more', text: '3 or more' },
];

export const atFaultIncidents1YearOptions = [
  { key: 0, value: '0', text: '0' },
  { key: 1, value: '1', text: '1' },
  { key: 2, value: '2', text: '2' },
  { key: 3, value: 'more than 2', text: 'More than 2' },
];

export const atFaultIncidents1YearMotorcycleOptions = [
  { key: 0, value: '0', text: '0' },
  { key: 1, value: '1', text: '1' },
  { key: 2, value: '2 or more', text: '2 or more' },
];

export const ridingExpOptions = [
  { key: 'Less than 1', value: 'Less than 1', text: 'Less than 1' },
  { key: '1', value: '1', text: '1' },
  { key: '2 or more', value: '2 or more', text: '2 or more' },
];

export const regoStatusOptions = [
  {
    key: MotorcycleRegoStatus.ROAD_REGISTERED,
    value: MotorcycleRegoStatus.ROAD_REGISTERED,
    text: 'Road registered',
  },
  {
    key: MotorcycleRegoStatus.REGISTRATION_PENDING,
    value: MotorcycleRegoStatus.REGISTRATION_PENDING,
    text: 'Registration pending',
  },
  {
    key: MotorcycleRegoStatus.NOT_REQUIRED,
    value: MotorcycleRegoStatus.NOT_REQUIRED,
    text: 'Not required',
  },
];

export const annualDistanceOptions = [
  { key: 'low', value: 15000, text: 'Low (less than 15,000km per year)' },
  { key: 'average', value: 20000, text: 'Average (15,000-30,000km per year)' },
  { key: 'high', value: 30000, text: 'High (30,000+km per year)' },
];

export const GENDER_OPTIONS = getEnumOptions(Gender);
export const RELATIONSHIPS_OPTIONS = getEnumOptions(Relationships);
export const FREQUENCY_OPTIONS = getEnumOptions(R.dissoc('ONE_OFF', FREQUENCY));
export const POLICIES_SORTING_OPTIONS = getEnumOptions(PolicySort);
export const PAYMENT_METHODS_OPTIONS = getEnumOptions(PaymentMethods);
export const CO_PAYMENT_OPTIONS = getEnumOptions(CoPayment);
export const PAYOUT_FREQUENCY_OPTIONS = getEnumOptions(PayoutFrequency);
export const PAYOUT_WAIT_OPTIONS = getEnumOptions(PayoutWaitPeriod);
export const PAYOUT_BENEFIT_OPTIONS = getEnumOptions(
  PayoutBenefitPeriod,
  'Up to ',
);
export const INCOME_FREQUENCY_OPTIONS = getEnumOptions(IncomeFrequency);

export const EMPLOYMENT_OPTIONS = [
  { key: 'employed', text: 'Employed', value: 'employed' },
  { key: 'unemployed', text: 'Unemployed', value: 'unemployed' },
  {
    key: 'not looking for work',
    text: 'Not looking',
    value: 'not looking for work',
  },
  { key: 'retired', text: 'Retired', value: 'retired' },
];

export const FORM_ENGINE_OPTIONS = {
  occupation: OCCUPATION_OPTIONS,
};
