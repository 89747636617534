import styles from './windcaveCallbackFail.scss';
import ProtectaImg from 'images/providers/protecta.png';
import QCurious from '-!svg-react-loader!images/q/q-scratching-head.svg';

interface IWindcaveCallbackFailedProps {
  handleRetry: (sessionId: string) => void;
  sessionId: string;
}

export const WindcaveCallbackFailed = ({
  handleRetry,
  sessionId,
}: IWindcaveCallbackFailedProps) => {
  return (
    <>
      <div className={styles.bigContainer}>
        <div>
          <img src={ProtectaImg} />
        </div>
        <div className={styles.subTitle}>
          <span>Please try again</span>
        </div>
        <div className={styles.descriptionBox}>
          <div>
            <QCurious />
          </div>
          <div className={styles.upperDescrption}>
            <span>
              <strong>
                Something unexpected happened and you were redirected back here!{' '}
              </strong>
            </span>
          </div>
          <div className={styles.longDescriptionBox}>
            <span>
              Not to worry, we’ve saved your information and you can simply try
              making your payment again. If you have any more issues, feel free
              to contact us at team@quashed.co.nz and we can help you sort it
              out.
            </span>
          </div>
          <div className={styles.buttonDiv}>
            <button
              className={styles.button}
              onClick={() => handleRetry(sessionId)}
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
