import { getHeaderOptions, getHttpService } from './http-service';
import {
  getClaimsToken,
  getFileExtensionFromString,
  removeEmptyValues,
} from '../helpers';
import { IAttachmentKeyName, IPolicy, IPolicyRenewal } from 'types';
import { IFile, uploadAttachment } from 'services';

const httpClient = getHttpService();

export const getPolicyRenewalsByPolicyId = (id: string) => {
  return httpClient.get(`/policyrenewal/policy/${id}`, {
    headers: getHeaderOptions(),
  });
};

export const getPolicyRenewalById = (id: string, sk: string) => {
  return httpClient.get(`/policyrenewal/${id}/${sk}`, {
    headers: getHeaderOptions(),
  });
};

export const getPendingRenewalsByUserId = () => {
  return httpClient.get('/policyrenewal/pending', {
    headers: getHeaderOptions(),
  });
};

// min props + any additional props if we need later.
export interface ICreatePolicyRenewalProps {
  id?: string;
  renewalDate: string; // old renewalDate
  premium: number; // old premium
  policyId: string;
  newPolicyData: IPolicy;
  [key: string]: any;
}

export const createPolicyRenewal = (
  policyRenewal: ICreatePolicyRenewalProps,
) => {
  const sanitized = removeEmptyValues(policyRenewal);
  return httpClient.post(`/policyrenewal`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export const updatePolicyRenewal = (policyRenewal: IPolicyRenewal) => {
  const sanitized = removeEmptyValues(policyRenewal);
  return httpClient.put(`/policyrenewal/${policyRenewal.id}`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export interface ICreateRenewalByAttachmentsProps {
  policyId: string;
  policyType: string;
  policyTypeId: string;
  files: IAttachmentKeyName[];
  id?: string;
}

export const createRenewalByAttachments = (
  props: ICreateRenewalByAttachmentsProps,
) => {
  const claims = getClaimsToken();
  const userId = `user:${claims.sub}`;
  const sanitized = removeEmptyValues(props);
  return httpClient.post(
    `/policyrenewal/attachments`,
    R.assoc('userId', userId, sanitized),
    {
      headers: getHeaderOptions(),
    },
  );
};

export interface ICreateAttachmentForPolicyRenewal {
  policyRenewalId: string;
  fileName: string;
  fileSize: number;
  key: string;
  type: string;
}

export const createAttachmentForPolicyRenewal = (
  props: ICreateAttachmentForPolicyRenewal,
) => {
  const sanitized = removeEmptyValues(props);
  return httpClient.post(`/policyrenewal/attachment`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export const uploadPolicyRenewalAttachments = async (
  files: IFile[],
  renewal: ICreateRenewalByAttachmentsProps,
) => {
  const results = [];

  for (const file of files) {
    try {
      const fileWithUploadInfo = renewal.files.find(
        (f) => f.fileName === file.name,
      );
      const { fields, url } = fileWithUploadInfo.postFileInfo;
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append('file', file as any);
      await uploadAttachment(url, formData, file.type);
      const resp = await createAttachmentForPolicyRenewal({
        policyRenewalId: renewal.id,
        key: fileWithUploadInfo.key,
        fileName: file.name,
        fileSize: file.size,
        type: file.type,
      });
      results.push({ success: true, file, data: resp.data });
    } catch (err) {
      results.push({ success: false, file });
    }
  }

  return results;
};

export const createPolicyRenewalWithAttachments = async (
  policy: IPolicy,
  files: IFile[],
) => {
  const attachments = files.map((file, index) => {
    const extensionName = getFileExtensionFromString(file.name);
    // create a new uniq key for file
    const key = `${new Date().getTime()}-${index}.${extensionName}`;
    return {
      key,
      fileName: file.name,
    };
  });

  const { data } = await createRenewalByAttachments({
    policyId: policy.id,
    policyType: policy.policyType,
    policyTypeId: policy.policyTypeId,
    files: attachments,
  });

  await uploadPolicyRenewalAttachments(files, data);

  return {
    ...data,
    attachments: data.files,
    files: data.files.length,
  };
};
