import Button from 'components/button';
import React, { ReactNode } from 'react';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/TextInput.module.scss';

const TextInput = (
  {
    basic,
    label,
    labelAfter,
    className,
    inputClassName,
    labelClassName,
    leftIcon,
    rightIcon,
    error,
    scrollIntoView,
    tooltip,
    showButton,
    buttonText = 'Search',
    onButtonClick,
    ...inputProps
  }: IFormFieldProps & React.HTMLProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>,
) => {
  return (
    <InputWrap
      className={className}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      error={error}
      label={label}
      labelAfter={labelAfter}
      labelClassName={labelClassName}
      name={inputProps.name}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
      tooltip={tooltip}
    >
      <input
        ref={ref}
        id={inputProps.name}
        {...inputProps}
        className={classnames(
          styles.input,
          basic && styles.basic,
          inputClassName,
        )}
      />
      {showButton && (
        <Button
          type="button"
          className={styles.button}
          onClick={onButtonClick}
          text={buttonText}
        />
      )}
    </InputWrap>
  );
};

export default React.forwardRef(TextInput);
