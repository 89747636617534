// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".G896IjL0iyP3ZNUU9ei4a i.icon{display:none}.G896IjL0iyP3ZNUU9ei4a .Z_KKx8iX2pTqdXWUvHhEx{border-color:#e47c6e;padding-left:16px;width:calc(100% - 8px)}.Z_KKx8iX2pTqdXWUvHhEx{font-family:\"Open Sans\";font-weight:400;font-size:14px;color:#34363e;border:solid 1px #ebedf3;border-radius:5px;padding:8px 16px;resize:none}textarea::placeholder{color:#c7c7c7}.obpfPe3TUjeSVUHNXTP74{position:relative}.obpfPe3TUjeSVUHNXTP74 ._29sSvWthh0ZDu1Vl9hrSdI{color:#c7c7c7;position:absolute;right:10px;bottom:8px}", ""]);
// Exports
exports.locals = {
	"error": "G896IjL0iyP3ZNUU9ei4a",
	"input": "Z_KKx8iX2pTqdXWUvHhEx",
	"textarea": "obpfPe3TUjeSVUHNXTP74",
	"length": "_29sSvWthh0ZDu1Vl9hrSdI"
};
module.exports = exports;
