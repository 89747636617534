import { Modal } from 'semantic-ui-react';
import styles from './styles/adviser-details.scss';
import { IUser } from 'types';
import AdviserCard from 'components/adviser-card';

interface IAdviserDetailsModalProps {
  adviser: IUser;
  open: boolean;
  onClose: () => void;
  onConnect: (a: IUser) => void;
}

const AdviserDetailsModal = ({
  adviser,
  open,
  onClose,
  onConnect,
}: IAdviserDetailsModalProps) => {
  return adviser && (
    <Modal
      className={styles.modal}
      size="small"
      open={open}
      onClose={onClose}
      closeIcon="times circle"
    >
      <Modal.Content>
        <div className={styles.left}>
          <AdviserCard
            handleAdviserClick={onConnect}
            handleConnectClick={onConnect}
            adviser={adviser}
            selected={true}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.title}>About</div>
          <div className={styles.details}>{`${adviser.location} • ${adviser.company} • FSP No: ${adviser.fspNo}`}</div>
          <div className={styles.bio}>{adviser.bio}</div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AdviserDetailsModal;
