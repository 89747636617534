import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Button } from 'components/quashed';
import styles from '../styles/ReviewReminderModal.module.scss';

const ReviewReminderModal = ({ onClose, ...props }) => {
  return (
    <Modal className={styles.modal} onClose={onClose} size="tiny" {...props}>
      <Modal.Content>
        <div>
          Oops.. you've selected a policy that is not our recommended option.
          <br />
          <br />
          Multi-policy discount is only available where more than one policy is
          purchased from an insurer.
          <br />
          <br />
          What you can do:
          <br />
          1. Change your option to the recommended insurer.
          <br />
          2. Wait for our adviser to be in touch to update your quote with the
          options you have currently selected.
        </div>
        <Button className={styles.button} text="OK" onClick={onClose} />
      </Modal.Content>
    </Modal>
  );
};

export default ReviewReminderModal;
