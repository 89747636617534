import {
  trackEventPolicyScan,
  trackEvent,
  trackEventFindAdviser
} from '../helpers/segment';
import { AppActionTypes } from '../redux/actions';
import { Types as PolicyTypes } from '../redux/actions/policy';
import { Types as AdviserTypes } from '../redux/actions/adviser-actions';

const gaLogger = (store) => (next) => (action: AppActionTypes) => {
  const result = next(action);

  switch (action.type) {
    case PolicyTypes.CREATE_POLICY_LOADED:
      if (action.data.isUpload) {
        trackEvent('Web-App-Policy-Added-Upload', {
          policyType: action.data.policyType,
          policyProviderName: action.data.policyProviderName
        });
      } else {
        trackEvent('Web-App-Policy-Added-Manual', {
          policyType: action.data.policyType,
          policyProviderName: action.data.policyProviderName
        });
      }
      break;
    case PolicyTypes.CREATE_POLICY_SCAN_LOADED:
      trackEvent('Web-App-Market-Scan-Submitted', action.data);
      trackEventPolicyScan();
      break;
    case PolicyTypes.CREATE_POLICY_SCAN_WITHOUT_POLICY_LOADED:
      trackEvent('Web-App-Market-Scan-No-Policy-Submitted', action.data);
      trackEventPolicyScan();
      break;
    case AdviserTypes.CREATE_ADVISER_CONNECT:
      trackEventFindAdviser();
    default:
      break;
  }

  return result;
};

export default gaLogger;
