import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { HelpTooltip } from 'components/tooltip';
import { shouldShowQuestion } from 'helpers/typeForm';
import { IUseForm } from 'hooks/useForm';
import TypeFormField from './TypeFormField';
import styles from './typeForm.scss';
import { WarningMessage } from 'components/form-message';

export interface ITypeFormQuestion {
  field: any;
  disabled?: boolean;
  fieldClassName?: string;
  inputClassName?: string;
  form?: IUseForm;
  errors?: object;
  tooltipProps?: any;
  scrollOnError?: boolean;
  questions: object[];
  linkClickHandlers?: { [key: string]: () => void };
}

const getTooltip = (tooltip) => {
  // Array of tooltips should be separated by <br />
  if (Array.isArray(tooltip)) {
    return tooltip.map((t, idx) => (
      <React.Fragment key={idx}>
        {t}
        {idx < tooltip.length - 1 && (
          <>
            <br />
            <br />
          </>
        )}
      </React.Fragment>
    ));
  }
  return tooltip;
};

const TypeFormQuestion = ({
  field,
  questions,
  form,
  scrollOnError,
  errors,
  disabled,
  tooltipProps,
  fieldClassName,
  inputClassName,
  linkClickHandlers,
}: ITypeFormQuestion) => {
  const ref = useRef(null);
  const hasError = errors?.[field.key || field.fieldKey];
  const shouldScroll =
    !!scrollOnError && Object.keys(errors || {})[0] === field.key;

  useEffect(() => {
    // Scroll to field on error
    if (shouldScroll) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [shouldScroll]);

  if (!shouldShowQuestion(field, questions, form.values)) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classnames(
        styles.question,
        field.tooltip && styles.hasTooltip,
        field.tooltip && 'hasTooltip',
        fieldClassName,
      )}
    >
      <div>{field.before}</div>
      <TypeFormField
        className={classnames(inputClassName)}
        form={form}
        question={field}
        disabled={disabled}
        error={hasError}
      />
      {field.link && (
        <div className={styles.typeFormFieldLink}>
          <Link to={field.link.to} onClick={linkClickHandlers?.[field.key]}>
            {field.link.text}
          </Link>
        </div>
      )}
      {field.tooltip && (
        <HelpTooltip
          className={styles.tooltip}
          toggleClassName={styles.toggle}
          content={getTooltip(field.tooltip)}
          position="left center"
          {...tooltipProps}
        />
      )}
      {field.after && (
        <div
          className={classnames(
            disabled &&
              field.after &&
              field.after.length === 1 &&
              styles.noLeftMargin,
            styles.after,
          )}
        >
          {field.after}
        </div>
      )}
      {field.warning &&
        form.values[field.warning.key || field.key] === field.warning.value && (
          <WarningMessage
            text={field.warning.text}
            className={styles.warning}
          />
        )}
    </div>
  );
};

export default TypeFormQuestion;
