import { toast } from 'react-toastify';
import BaseToast from './Base';
import MissingInfoSvg from '-!svg-react-loader!images/people/girl-searching-red.svg';
import SuccessSvg from '-!svg-react-loader!images/people/girl-race.svg';
import InfoSvg from '-!svg-react-loader!images/people/guy-info.svg';
import { useCallback } from 'react';
import { IPolicy, IPolicyRenewal } from 'types';

interface IPolicyUploadToastProps {
  policy?: IPolicy;
  closeToast(): void;
  onClick?(args): void;
}

interface IRenewalToastProps {
  renewal: IPolicyRenewal;
  closeToast(): void;
  onClick?(args): void;
}

const PolicyUploadMissingToast = ({ policy, closeToast, onClick }: IPolicyUploadToastProps) => {
  const handleClick = useCallback(
    () => {
      onClick(policy);
      closeToast();
    },
    [onClick, policy],
  );
  return (
    <BaseToast
      img={<MissingInfoSvg />}
      title="Almost there!"
      error={true}
      onClose={closeToast}
      onClick={handleClick}
      text="Just a few bits of optional information missing. Click to complete it and you're all set."
    />
  );
};

const PolicyUploadErrorToast = ({ closeToast }: IPolicyUploadToastProps) => {
  return (
    <BaseToast
      img={<InfoSvg />}
      title="Your document needs a little TLC from us"
      text="We'll send you an email once we've checked the information has picked up correctly."
      info={true}
      onClose={closeToast}
    />
  );
};

const PolicyUploadAdviserErrorToast = ({ closeToast }: IPolicyUploadToastProps) => {
  return (
    <BaseToast
      img={<InfoSvg />}
      title="Thanks for uploading your client's policy documents"
      text="Wow! Wasn’t that easy! We’re sorting it all out for you, we’ll let you know when they’re ready for you to view & share with your client."
      info={true}
      onClose={closeToast}
    />
  );
};

const PolicyUploadSuccessToast = ({ policy, closeToast, onClick }: IPolicyUploadToastProps) => {
  const handleClick = useCallback(
    () => {
      onClick(policy);
      closeToast();
    },
    [onClick, policy],
  );
  return (
    <BaseToast
      img={<SuccessSvg />}
      title="Thanks for uploading your policy!"
      success={true}
      onClose={closeToast}
      onClick={handleClick}
      text="Wow! Wasn't that easy! Your policy information has picked up automatically. Click to see it and make any changes if needed."
    />
  );
};

const PolicyUploadAdviserSuccessToast = ({ closeToast }: IPolicyUploadToastProps) => {
  return (
    <BaseToast
      img={<SuccessSvg />}
      title="Thanks for uploading your client's policy documents"
      success={true}
      onClose={closeToast}
      onClick={closeToast}
      text="Wow! Wasn't that easy! Your policy information has picked up automatically."
    />
  );
};

const MarketScanSuccessToast = ({ policy, closeToast, onClick }: IPolicyUploadToastProps) => {
  const handleClick = useCallback(
    () => {
      onClick(policy);
      closeToast();
    },
    [onClick, policy],
  );
  return (
    <BaseToast
      img={<SuccessSvg />}
      title="Thanks for uploading your policy!"
      success={true}
      onClose={closeToast}
      onClick={handleClick}
      text="Wow! Wasn't that easy! Your policy information has picked up automatically. Click to continue with your Market Scan."
    />
  );
};

const RenewalSuccessToast = ({ renewal, closeToast, onClick }: IRenewalToastProps) => {
  const handleClick = useCallback(
    () => {
      onClick(renewal);
      closeToast();
    },
    [onClick, renewal],
  );
  return (
    <BaseToast
      img={<SuccessSvg />}
      title="Thanks for uploading your renewal!"
      success={true}
      onClose={closeToast}
      onClick={handleClick}
      text="Wow! Wasn't that easy! Your renewal has picked up automatically. Click to finish with your renewal."
    />
  );
};

const controller = {
  success: (props?) => toast(<PolicyUploadSuccessToast {...props} />),
  successAdviser: (props?) => toast(<PolicyUploadAdviserSuccessToast {...props} />),
  error: (props?) => toast(<PolicyUploadErrorToast {...props} />),
  errorAdviser: (props?) => toast(<PolicyUploadAdviserErrorToast {...props} />),
  missingInfo: (props?) => toast(<PolicyUploadMissingToast {...props} />),
  marketScanSuccess: (props?) => toast(<MarketScanSuccessToast {...props} />),
  renewalSuccess: (props?) => toast(<RenewalSuccessToast {...props} />),
};

export default controller;