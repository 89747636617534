import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import DropdownInput from 'components/dropdown-input';
import InputWrap from './InputWrap';
import styles from './styles/Select.module.scss';
import { IFormFieldProps } from './common';
import { FORM_ENGINE_OPTIONS } from 'constants/options';

export interface ISelectProps extends IFormFieldProps {
  creatable?: boolean;
  search?: boolean;
  options: any[];
}

const Select = ({
  basic,
  label,
  labelAfter,
  description,
  className,
  inputClassName,
  labelClassName,
  leftIcon,
  rightIcon,
  error,
  info,
  options,
  placeholder,
  tooltip,
  onChange,
  scrollIntoView,
  ...inputProps
}: ISelectProps) => {
  const handleChange = (_, data) => {
    if (onChange) {
      const target = { name: data.name, value: data.value };
      onChange({ target });
    }
  };

  const DropdownComponent =
    inputProps?.creatable || inputProps?.search ? DropdownInput : Dropdown;
  const dropdownOptions =
    typeof options === 'string' ? FORM_ENGINE_OPTIONS[options] : options;

  return (
    <InputWrap
      className={className}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      error={error}
      label={label}
      labelAfter={labelAfter}
      description={description}
      labelClassName={labelClassName}
      name={inputProps.name}
      rightIcon={rightIcon}
      tooltip={tooltip}
      info={info}
      scrollIntoView={scrollIntoView}
    >
      <DropdownComponent
        className={classnames(
          styles.dropdown,
          basic && styles.basic,
          inputClassName,
        )}
        options={dropdownOptions}
        icon="chevron down"
        placeholder={placeholder || ''}
        {...inputProps}
        onChange={handleChange}
        scrolling={true}
      />
    </InputWrap>
  );
};

export default React.forwardRef(Select);
