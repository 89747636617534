export enum UserRoles {
  ADVISER = 'adviser',
  ADMIN = 'admin',
  USER = 'user',
}

export enum UserEmployment {
  EMPLOYED = 'employed',
  UNEMPLOYED = 'unemployed',
  NOT_LOOKING_FOR_WORK = 'not looking for work',
}

export const OTHER_EMPLOYER_ID = 'Other';

export enum SubscriptionStatus {
  ACTIVE = 'active',
  INCOMPLETE = 'incomplete',
}

export enum OnboardingOption {
  MANAGE = 'MANAGE',
  COMPARE = 'COMPARE',
  ADVISER = 'ADVISER',
}
