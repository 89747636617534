import { Action } from 'redux';
import { createAdviserReferral, createReferral, getReferrals, processReferral } from 'services';
import { IReferral, IReferralAdviser, IReferralNew } from 'types';

export enum Types {
  GET_REFERRALS = 'GET_REFERRALS',
  CREATE_REFERRAL = 'CREATE_REFERRAL',
  UPDATE_REFERRAL = 'UPDATE_REFERRAL',
  TOGGLE_ADVISER_REFERRAL = 'TOGGLE_ADVISER_REFERRAL',
}

export interface IGetReferrals extends Action {
  type: Types.GET_REFERRALS;
  data: IReferral[];
}

export const GetReferralsLoaded = (data: IReferral[]) =>
  ({
    type: Types.GET_REFERRALS,
    data
  } as IGetReferrals);

export const GetReferrals = () => async (dispatch) => {
  const result = await getReferrals();
  dispatch(GetReferralsLoaded(result.data.Items));
};

export interface ICreateReferral extends Action {
  type: Types.CREATE_REFERRAL;
  data: IReferral;
}

export const CreateReferralLoaded = (data: IReferral) =>
  ({
    type: Types.CREATE_REFERRAL,
    data
  } as ICreateReferral);

export const CreateReferral = (referral: IReferralNew) => async (dispatch) => {
  const result = await createReferral(referral);
  dispatch(CreateReferralLoaded(result.data));
};

export interface IUpdateReferral extends Action {
  type: Types.UPDATE_REFERRAL;
  data: IReferral[];
}

export const UpdateReferralLoaded = (data: IReferral[]) =>
  ({
    type: Types.UPDATE_REFERRAL,
    data
  } as IUpdateReferral);

export const ProcessReferral = (id: string) => async (dispatch) => {
  const result = await processReferral({ id });
  dispatch(UpdateReferralLoaded(result.data));
};

export interface IToggleAdviserReferral extends Action {
  type: Types.TOGGLE_ADVISER_REFERRAL;
  data: boolean;
}

export const ToggleAdviserReferralLoaded = (data: boolean) =>
  ({
    type: Types.TOGGLE_ADVISER_REFERRAL,
    data
  } as IToggleAdviserReferral);

export const ToggleAdviserReferral = (open: boolean) => (dispatch) => {
  dispatch(ToggleAdviserReferralLoaded(open));
};

export const CreateAdviserReferral = (referral: IReferralAdviser) => async (dispatch) => {
  const result = await createAdviserReferral(referral);
};