import React from 'react';
import DatePicker from "components/date-picker";
import Placeholder from '../form-field/FormFieldPlaceholder';
import styles from './formFieldBordered.scss';
import { useState } from 'react';

export interface IDatePickerBordered {
  required?: boolean;
  value: string;
  onChange?: (e: React.SyntheticEvent) => void;
  className?: string;
  placeholder?: string;
  dateClassName?: string;
  disabled?: boolean;
  error?: boolean;
  bottomLabel?: any;
  min?: Date;
  max?: Date;
}

const DatePickerBordered = ({
  required,
  value,
  onChange,
  className,
  placeholder,
  min,
  max,
  disabled,
  error,
  bottomLabel,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = (open: boolean) => {
    setIsOpen(open);
  }
  return (
    <div className={classnames(styles.datepickerContainer, error && styles.error, className)}>
        <DatePicker
          className={styles.date}
          containerClassName={styles.datepickerContainer}
          onChange={onChange}
          onOpen={onOpen}
          value={value}
          inputProps={{ required }}
          disabled={disabled}
          min={min}
          max={max}
          name={name}
        />
        <Placeholder
          required={required}
          placeholder={placeholder}
          className={classnames(styles.placeholder, (isOpen || value) && styles.hasValue)}
        />
        {bottomLabel && <div className={styles.bottomLabel}>{bottomLabel}</div>}
      </div>
  );
};

export default DatePickerBordered;