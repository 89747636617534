import './styles/index.scss';
import 'core-js/stable';
import 'react-toastify/dist/ReactToastify.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './helpers/loadImages';

import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './app';
import configureStore from './configure-store';

import { Auth0Provider } from './react-auth0-spa';

import history from './helpers/history';
import { IAdviserTotal, IAppState } from './types';
import queryString from 'query-string';
import { getConfig, getSidebarState } from './helpers';
import {
  ADD_POLICY_ROUTE,
  HOME_ROUTE,
  INVITATION_ROUTE,
  MARKET_SCAN_ONBOARDING_ROUTE,
  MARKET_SCAN_PURCHASE_ROUTE,
  MARKET_SCAN_RESULTS_ROUTE,
  POLICY_DETAILS_ROUTE,
  WORKPLACE_ROUTE,
} from 'constants/routes';
import { matchPath } from 'react-router-dom';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';

declare const AUTH0_DOMAIN: string;
declare const AUTH0_CLIENT_ID: string;

interface IExtraParams {
  prompt: string;
  email: string;
  targetUrl: string;
  fn?: string;
  ln?: string;
  insuranceTypes?: string;
  insuranceReasons?: string;
  premium?: string;
  hideSocial?: boolean;
  adviserSignUp?: boolean;
  phone?: string;
  fspNo?: string;
  company?: string;
}

moment.locale('en-nz', {
  parentLocale: 'en-nz',
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    ss: '%d s',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    w: '1 w',
    ww: '%d w',
    M: '1 m',
    MM: '%d m',
    y: '1 y',
    yy: '%d y',
  },
});
momentLocalizer();

const removeCodeAndStateQuery = (url) =>
  url.replace(/&code(=[^&]*)?|&state(=[^&]*)?/g, '');

const getRedirectUrl = () => {
  return queryString.stringifyUrl({
    url: `${window.location.origin}/callback`,
    query: queryString.parse(window.location.search),
  });
};
const onRedirectCallback = (appState) => {
  const parsed = queryString.parse(location.search);
  let pathname =
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname;

  if (pathname.indexOf('onboarding') !== -1) {
    pathname = HOME_ROUTE;
  }

  if (parsed.invitationId) {
    pathname = `${pathname}?invitationId=${parsed.invitationId}`;
  } else if (parsed.referralId) {
    pathname = `${pathname}?referralId=${parsed.referralId}`;
  } else if (parsed.q4eId) {
    pathname = `${WORKPLACE_ROUTE}?id=${parsed.q4eId}`;
  } else if (parsed.redirectPath) {
    pathname = parsed.redirectPath;
  } else if (
    matchPath(pathname, [
      MARKET_SCAN_RESULTS_ROUTE,
      MARKET_SCAN_ONBOARDING_ROUTE,
      MARKET_SCAN_PURCHASE_ROUTE,
      POLICY_DETAILS_ROUTE,
      ADD_POLICY_ROUTE,
    ])
  ) {
    pathname = `${pathname}${removeCodeAndStateQuery(location.search)}`;
  }

  history.push(pathname);
};

const configData = getConfig();
window.addEventListener('load', () => {
  const initState: IAppState = {
    user: {
      name: '',
      email: '',
      id: '',
      sk: '',
      picture: '',
      firstName: '',
      lastName: '',
      data: '',
      currentFamilyId: '',
      walkthroughCompleted: false,
      exploreCompleted: false,
      onboardingStep: 0,
      quotes: [],
      coverageCompleted: false,
    },
    referenceData: {
      policyTypes: [],
      policyProviders: [],
      policySubTypes: [],
      creditCards: [],
      employers: [],
      wiki: [],
      policyScan: {},
      insuranceCheckup: {},
    },
    admin: {
      loading: false,
    },
    operations: {
      loading: false,
      addPolicyModelOpen: false,
      policyScans: [],
      emailNotification: [],
    },
    policies: {
      loading: true,
      policies: [],
      submitted: false,
      submitting: false,
      selectedPolicy: null,
      selectedPolicyScan: null,
      policyScans: [],
      policyReviews: [],
      bundles: [],
      error: '',
    },
    invitation: {
      loading: false,
      showCreateInvitationPage: true,
      invitations: [],
      sharedInvitations: [],
    },
    config: configData,
    adviser: {
      advisers: null,
      adviserConnects: null,
      premiumAdviserConnect: null,
      isConnected: false,
      submitted: false,
      submitting: false,
      loadingCalendar: true,
      loadingConsents: true,
      loaded: false,
      loading: false,
      isInquired: false,
      clients: [],
      consents: [],
      total: {} as IAdviserTotal,
      calendly: {},
      error: null,
    },
    referral: {
      referrals: [],
      showAdviserReferral: false,
    },
    payment: {
      loading: false,
      error: '',
      paymentResult: null,
    },
    creditCard: {
      creditCards: [],
      submitted: false,
      submitting: false,
      loaded: false,
    },
    policyRenewal: {
      renewals: [],
      pendingRenewals: [],
      submitted: false,
      submitting: false,
      loaded: false,
    },
    notification: {
      notifications: [],
      loading: false,
      bannerType: null,
    },
    layout: {
      expanded: getSidebarState(),
      maximised: false,
    },
  };

  const isInvitationPath =
    window.location.pathname.indexOf(INVITATION_ROUTE) !== -1;

  const isOnboardingPath =
    window.location.pathname.indexOf('onboarding') !== -1;

  const redirectUrl = getRedirectUrl();

  const extraParams: IExtraParams = {
    prompt: 'login',
    email: '',
    targetUrl: window.location.pathname,
  };

  const parsed = queryString.parse(location.search);

  if (isInvitationPath) {
    extraParams.prompt = parsed.prompt as string;
    extraParams.email = parsed.email as string;
    extraParams.hideSocial = true;
  } else if (isOnboardingPath) {
    extraParams.prompt = parsed.prompt as string;
    extraParams.fn = parsed.fn as string;
    extraParams.ln = parsed.ln as string;
    extraParams.insuranceTypes = parsed.insuranceTypes as string;
    extraParams.insuranceReasons = parsed.insuranceReasons as string;
    extraParams.premium = parsed.premium as string;

    if (parsed.adviserSignUp === 'true') {
      extraParams.adviserSignUp = true;
      extraParams.email = parsed.email as string;
      extraParams.phone = parsed.phone as string;
      extraParams.fspNo = parsed.fspNo as string;
      extraParams.company = parsed.company as string;
      extraParams.hideSocial = true;
    }
  }

  if (extraParams.prompt !== 'login') {
    extraParams.prompt = '';
  }

  const isMobile = window.innerWidth < 768;

  const store = configureStore(initState);

  ReactDom.render(
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      client_id={AUTH0_CLIENT_ID}
      redirect_uri={redirectUrl}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      {...extraParams}
    >
      <Provider store={store}>
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={8000}
          draggable={true}
          hideProgressBar={true}
          closeButton={false}
          closeOnClick={false}
          limit={isMobile ? 2 : 3}
        />
      </Provider>
    </Auth0Provider>,
    document.getElementById('app'),
  );
});
