import { updateListByItemMerged } from "helpers";
import { IReferralState } from "../../types";
import { AppActionTypes } from "../actions";
import { Types } from "../actions/referral-actions";

const referralReducer = (state: IReferralState, action: AppActionTypes): IReferralState => {
  switch (action.type) {
    case Types.GET_REFERRALS:
      return {
        ...state,
        referrals: action.data,
      };
    case Types.CREATE_REFERRAL:
      return {
        ...state,
        referrals: [...state.referrals, action.data]
      };
    case Types.UPDATE_REFERRAL:
      return {
        ...state,
        referrals: updateListByItemMerged(state.referrals, action.data),
      };
    case Types.TOGGLE_ADVISER_REFERRAL:
      return {
        ...state,
        showAdviserReferral: action.data,
      };
    default:
      return state;
  }
};

export default referralReducer;
