import React from 'react';
import { Link } from 'react-router-dom';
import { PREMIUM_ROUTE } from 'constants/routes';
import { prettifyNumber } from './number';

export const CONTENTS_MS_MIN_AMOUNT = 10000;
export const CONTENTS_MEDIUM_AMOUNT = 50000;
export const CONTENTS_DIRECT_MS_MAX = 250000;

export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Validate phone numbers via regex
 * Minimum 9 characters.
 * Doesnt accept the following symbols: +, -
 * @example
 * (123)456.7890
 * (021)0123456
 * 123.456.789
 * 092551234
 * 02101234567
 * @returns {Boolean}
 */
export const validatePhone = (phone) => {
  const pattern = /^[(]?[0-9]{2,3}[)]?[\.]?[0-9]{3}[\.]?[0-9]{4,6}$/;
  return pattern.test(String(phone).toLowerCase());
};

export const validateNumberplate = (numberplate) => {
  const letterNumber = /^[0-9a-zA-Z]+$/;
  return letterNumber.test(String(numberplate).trim());
};

export const validateRenewalPremium = (value: number, _, oldValues: any) =>
  value !== oldValues.premium;

export const validateFullAddress = ({
  isAddressValid,
  streetNo,
  streetName,
  suburb,
  city,
  postCode,
}) => {
  return (
    isAddressValid || (streetNo && streetName && suburb && city && postCode)
  );
};

export const getMinMaxHouseAmount = (livingArea) => {
  return {
    min: livingArea * 2600,
    max: livingArea * 10000,
  };
};
// The amount you’ve entered is above our Market
// Scan limit. (Max amount is $2,100,000.) Use the calculator to check your
// estimated insured amount.  Alternatively Quashed Premium could be a better fit for you.
export const getHouseMinErrorText = (min: number) => {
  return `The amount you’ve entered is below our Market Scan limit. (Min amount is $${prettifyNumber(
    min,
  )}. Use the calculator to check your estimated insured amount.`;
};

export const getHouseMaxErrorText = (max: number) => {
  return (
    <>
      The amount you've entered is above our Market Scan limit. (Max amount is $
      {prettifyNumber(max)}.) Use the calculator to check your estimated insured
      amount.
    </>
  );
};

export const getHouseErrorText = (amount: number, livingArea: number) => {
  const { min, max } = getMinMaxHouseAmount(livingArea);
  if (amount < min) {
    return getHouseMinErrorText(min);
  } else if (amount > max) {
    return getHouseMaxErrorText(max);
  }
  return '';
};

export const validateHouseAmount = (amount, livingArea) => {
  const { min, max } = getMinMaxHouseAmount(livingArea);
  return amount >= min && amount <= max;
};

export const validateContentsAmount = (amount) => {
  return amount >= CONTENTS_MS_MIN_AMOUNT && amount <= CONTENTS_DIRECT_MS_MAX;
};

export const getContentsMinErrorText = () => {
  return `Sorry, we are unable to provide quotes for contents worth less than $${prettifyNumber(
    CONTENTS_MS_MIN_AMOUNT,
  )}. Use the calculator to estimate insured amount.`;
};

export const getContentsMaxErrorText = () => {
  return (
    <>
      Sorry, we are unable to provide quotes for contents worth more than
      $250,000.
    </>
  );
};

export const getContentsErrorText = (amount) => {
  if (amount < CONTENTS_MS_MIN_AMOUNT) {
    return getContentsMinErrorText();
  } else if (amount > CONTENTS_DIRECT_MS_MAX) {
    return getContentsMaxErrorText();
  }
  return '';
};
