// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "@media(min-width: 1040px){._2AjV92Wle3VsPm1jYo2iYj:first-child{margin-left:0}}", ""]);
// Exports
exports.locals = {
	"additionalInfoInputs": "_2AjV92Wle3VsPm1jYo2iYj"
};
module.exports = exports;
