import { IReferralNew, IReferral, IReferralAdviser } from 'types';
import { getHeaderOptions, getHttpService } from './http-service';

const httpClient = getHttpService();

export const getReferrals = () => {
  return httpClient.get(`/referral`, {
    headers: getHeaderOptions()
  });
};

export const createReferral = (referral: IReferralNew) => {
  return httpClient.post(`/referral`, referral, {
    headers: getHeaderOptions()
  });
};

export const createAdviserReferral = (referral: IReferralAdviser) => {
  return httpClient.post(`/referral/adviser`, referral, {
    headers: getHeaderOptions()
  });
};

export const processReferral = (referral: IReferral) => {
  return httpClient.put(`/referral`, referral, {
    headers: getHeaderOptions()
  });
};