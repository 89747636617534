// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._112ZDEwFCBtBd5_SmY8obr{font-family:\"Open Sans\";font-weight:400;color:#34363e;text-align:center;overflow-x:auto;display:flex;height:100%}._112ZDEwFCBtBd5_SmY8obr>*{flex:1}._2zX1Ndtn9fYM6AHaR3bmpq{font-size:18px;margin-bottom:24px}.mlhf8klX-rYWYHq3pHi1S{cursor:pointer;background-color:#ebedf3;border:1px solid rgba(0,0,0,0);border-radius:5px;display:flex;flex-direction:column;align-items:center;justify-content:center;flex:1}.mlhf8klX-rYWYHq3pHi1S:not(:last-child){margin-right:16px}.mlhf8klX-rYWYHq3pHi1S:hover,.mlhf8klX-rYWYHq3pHi1S._2XAjWEju4_87MERIyyiss6{border-color:#000}._2VzXhFBmW1TcvJdizQeAE7{padding:16px 24px}._2f6fPjkgake_xczqWuTMvu{display:flex;justify-content:center;align-items:center;padding:16px}._2f6fPjkgake_xczqWuTMvu span{font-size:14px;margin-right:8px}._3CtsDg-cRlDSayWVmkbY9O{color:#cbcbcb;fill:#cbcbcb;width:20px;height:20px}._3CtsDg-cRlDSayWVmkbY9O:not(:last-child){margin-right:8px}._3CtsDg-cRlDSayWVmkbY9O._1JpUX2XP4dG3ymkZ_X1aZ9{color:#ffd25d;fill:#ffd25d}@media(max-width: 992px){._112ZDEwFCBtBd5_SmY8obr{flex-direction:column}.mlhf8klX-rYWYHq3pHi1S{min-width:0}.mlhf8klX-rYWYHq3pHi1S:not(:last-child){margin-right:0;margin-bottom:8px}._2f6fPjkgake_xczqWuTMvu{padding-top:0}}@media(max-width: 820px){._112ZDEwFCBtBd5_SmY8obr{overflow-x:inherit}.mlhf8klX-rYWYHq3pHi1S{min-height:190px}._2VzXhFBmW1TcvJdizQeAE7{height:auto}}", ""]);
// Exports
exports.locals = {
	"container": "_112ZDEwFCBtBd5_SmY8obr",
	"name": "_2zX1Ndtn9fYM6AHaR3bmpq",
	"item": "mlhf8klX-rYWYHq3pHi1S",
	"selected": "_2XAjWEju4_87MERIyyiss6",
	"content": "_2VzXhFBmW1TcvJdizQeAE7",
	"rating": "_2f6fPjkgake_xczqWuTMvu",
	"star": "_3CtsDg-cRlDSayWVmkbY9O",
	"fill": "_1JpUX2XP4dG3ymkZ_X1aZ9"
};
module.exports = exports;
