// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3zeVF3y9OTpSql8K981Mit .pi8BGi3PtlnMFrlnWtyMn{padding-left:16px;width:calc(100% - 8px)}._3zeVF3y9OTpSql8K981Mit .uSu6bXnjlFoYaikOsuuXp{border-color:#e47c6e;width:calc(100% - 8px)}.pi8BGi3PtlnMFrlnWtyMn,._3RuhyJTJjzYWN6r9EEYZHm{width:100%}.uSu6bXnjlFoYaikOsuuXp{border-bottom:solid 2px #34363e;min-height:22px;width:100%}.uSu6bXnjlFoYaikOsuuXp select{color:#34363e;background-color:rgba(0,0,0,0);width:100%}.uSu6bXnjlFoYaikOsuuXp select option[value=\"\"][disabled]{color:#c7c7c7}", ""]);
// Exports
exports.locals = {
	"error": "_3zeVF3y9OTpSql8K981Mit",
	"bday": "pi8BGi3PtlnMFrlnWtyMn",
	"input": "uSu6bXnjlFoYaikOsuuXp",
	"bdayInputContainer": "_3RuhyJTJjzYWN6r9EEYZHm"
};
module.exports = exports;
