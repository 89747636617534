import { getHeaderOptions, getHttpService, IIdSkPayload } from './http-service';
import {
  IUser,
  ICreatePolicyProps,
  IPolicy,
  IConsent,
  IAdviserConnect,
} from 'types';
import { standardFormatWithTime, sortDate, removeEmptyValues } from 'helpers';
import { batchUploadAttachments, deletePolicy, IFile } from 'services';

const omitClientProps = R.omit(['policies']);

const httpClient = getHttpService();

const sortAndFormat = (items) =>
  items.sort(sortDate('createdAt')).map((i) => ({
    ...i,
    createdAt: standardFormatWithTime(i.createdAt),
  }));

export const createAdviser = (createAdviserBody) => {
  return httpClient.post(`/adviser`, createAdviserBody, {
    headers: getHeaderOptions(),
  });
};

export interface IConnectAdviserBody {
  eventUrl?: string;
  phoneNumber?: string;
  name: string;
  message: string;
  policies: any;
  underwritingQuestions: any;
}

export const connectAdviser = (connectAdviserBody: IConnectAdviserBody) => {
  return httpClient.post(`/adviser/connectadviser`, connectAdviserBody, {
    headers: getHeaderOptions(),
  });
};

export const updateAdviser = (updateAdviserBody: IUser) => {
  return httpClient.put(`/adviser`, updateAdviserBody, {
    headers: getHeaderOptions(),
  });
};

export const getAllAdvisers = () => {
  return httpClient
    .get<IUser[]>(`/adviser`, {
      headers: getHeaderOptions(),
    })
    .then((res) => {
      res.data = sortAndFormat(res.data);
      return res;
    });
};

export const getAllAdviserUserConnects = () => {
  return httpClient
    .get(`/adviser/adviseruserconnect`, {
      headers: getHeaderOptions(),
    })
    .then((res) => {
      res.data = sortAndFormat(res.data);
      return res;
    });
};

export const getAllAdviserUserConnectsForUser = (userId: string) => {
  userId = encodeURIComponent(userId);
  return httpClient.get(`/adviser/adviseruserconnect/user/${userId}`, {
    headers: getHeaderOptions(),
  });
};

export const getAdviserConnectByIdSk = (id: string, sk: string) => {
  const encodedId = encodeURIComponent(id);
  const encodedSk = encodeURIComponent(sk);
  return httpClient.get(
    `/adviser/adviseruserconnect/${encodedId}/${encodedSk}`,
    {
      headers: getHeaderOptions(),
    },
  );
};

export const updateAdviserConnect = (adviserConnect: IAdviserConnect) => {
  return httpClient.put('/adviser/adviseruserconnect', adviserConnect, {
    headers: getHeaderOptions(),
  });
};

export const updateAdviserConnectUser = (
  adviserConnect: Partial<IAdviserConnect>,
) => {
  return httpClient.put('/adviser/adviseruserconnect/user', adviserConnect, {
    headers: getHeaderOptions(),
  });
};

export const getClients = () => {
  return httpClient.get(`/adviserclient`, {
    headers: getHeaderOptions(),
  });
};

export const createClient = (client: IUser) => {
  const sanitized = removeEmptyValues(client);
  return httpClient.post(`/adviserclient`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export const updateClient = (client: IUser) => {
  const sanitized = removeEmptyValues(client);
  return httpClient.put(`/adviserclient`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export const inviteClient = (payload: { id: string; message: string }) => {
  return httpClient.post(`/adviserclient/send/setpassword`, payload, {
    headers: getHeaderOptions(),
  });
};

export const createClientPolicy = (policy: ICreatePolicyProps) => {
  return httpClient.post(`/adviserclient/create/policy`, policy, {
    headers: getHeaderOptions(),
  });
};

export const createClientPolicyWithAttachments = async (
  policy: ICreatePolicyProps,
  attachments: IFile[],
) => {
  const { data: newPolicy }: { data: IPolicy } = await createClientPolicy({
    ...policy,
    effective: true,
    isUpload: true,
  });

  try {
    await batchUploadAttachments(attachments, newPolicy.id);
  } catch (ex) {
    await deletePolicy(newPolicy.id);
    // Throw again so caller can handle error
    throw ex;
  }

  return newPolicy;
};

export const updateClientPolicy = (policy: IPolicy) => {
  const sanitized = removeEmptyValues(policy);
  return httpClient.post(
    `/adviserclient/update/policy`,
    omitClientProps(sanitized),
    {
      headers: getHeaderOptions(),
    },
  );
};

export const updateClientPolicyWithAttachments = async (
  policy: IPolicy,
  attachments,
) => {
  await batchUploadAttachments(attachments, policy.id);
  return updateClientPolicy(policy);
};

export const deleteClientPolicy = (data) => {
  return httpClient.post(`/adviserclient/delete/policy`, data, {
    headers: getHeaderOptions(),
  });
};

export const getAdvisersByOrgId = async (orgId) => {
  return httpClient.get(`/organization/${orgId}`, {
    headers: getHeaderOptions(),
  });
};

export const getAdviserOrgs = () => {
  return httpClient.get(`/organization`, {
    headers: getHeaderOptions(),
  });
};

export const deleteAdviserOrg = (id) => {
  return httpClient.delete(`/organization/${id}`, {
    headers: getHeaderOptions(),
  });
};

// Consents
export const getConsents = async () => {
  return httpClient.get(`/consent`, {
    headers: getHeaderOptions(),
  });
};

export const getConsentById = async (payload: IIdSkPayload) => {
  return httpClient.post(`/consent/getEnrichedConsent`, payload, {
    headers: getHeaderOptions(),
  });
};

export const createConsents = async (consents: IConsent[]) => {
  const sanitized = consents.map(removeEmptyValues);
  return httpClient.post(
    `/consent`,
    { consents: sanitized },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const deleteConsent = (data) => {
  return httpClient.delete(`/consent`, {
    headers: getHeaderOptions(),
    data,
  });
};

export const createAdviserInquiry = (data) => {
  return httpClient.post(`/adviser/inquiry`, data, {
    headers: getHeaderOptions(),
  });
};
