import moment from 'moment';
import { FieldType, IFormQuestion } from 'types';
import {
  validatePhone,
  validateEmail,
  validateFullAddress,
  validateNumberplate,
  isEmptyValue,
  shouldDisplay,
  CONTENTS_MS_MIN_AMOUNT,
  getMinMaxHouseAmount,
  getHouseMinErrorText,
  getContentsMinErrorText,
  getHouseMaxErrorText,
  CONTENTS_DIRECT_MS_MAX,
  getContentsMaxErrorText,
} from 'helpers';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { MotorcycleRegoStatus } from 'constants/options';
import { PINNACLE_OCCUPATION_ERROR } from 'constants/errors';

const hasAllRequiredValues = (fields, values = []) => {
  return !values.find((value) => {
    // Find missing required value
    return fields.find((f) => {
      const { key, required, display } = f;
      if (shouldDisplay(display, value) && required) {
        const val = value[key];
        // When field is a nested multi-form component, recursively check required values
        if (f.fields && !isEmptyValue(val)) {
          return !hasAllRequiredValues(f.fields, val);
        }
        const validator = getValidator(f.type, f, value);
        return validator ? !validator(val) : isEmptyValue(val);
      }
      return false;
    });
  });
};

const defaultValidators = {
  occupation: (_, formValues) => () => {
    return formValues.canQuote || PINNACLE_OCCUPATION_ERROR;
  },
  rego: (_, { registrationStatus }) => (numberPlate: string) => {
    return numberPlate
      ? validateNumberplate(numberPlate)
      : registrationStatus !== MotorcycleRegoStatus.ROAD_REGISTERED;
  },
  notBusiness: (question) => (value) =>
    value !== 'business' || question.errorMessage,
  listKey: (question, formValues) => (value) => {
    return value === 'false' || formValues[question.listKey]?.length > 0;
  },
  errorIfTrue: (question) => (value: string) => {
    return value === 'false' || question.errorMessage;
  },
  errorIfFalse: (question) => (value: string) => {
    return value === 'true' || question.errorMessage;
  },
  number: (question) => (value: number) => {
    if (question.min) {
      return value >= question.min;
    }
    return true;
  },
  address: (q, formValues) => () => {
    if (q.disabled || validateFullAddress(formValues)) {
      return true;
    }

    if (!!formValues.streetName && !formValues.streetNo) {
      return 'Enter street number';
    }

    return 'Select a match from the address list';
  },
  preferredContact: (question, formValues) => (value) => {
    if (question.key === 'phone' && formValues.preferredContact === 'phone') {
      return validatePhone(value);
    }
    return true;
  },
  phone: R.always(validatePhone),
  email: R.always(validateEmail),
  password: (question) => (value: string) => {
    return (
      question.password === value || question.errorText || 'Incorrect password'
    );
  },
  amount: (_, { livingArea, policyType, direct }) => (amount) => {
    if (policyType === INSURANCE_TYPES_NAMES.HOME) {
      const { min, max } = getMinMaxHouseAmount(livingArea);
      if (amount < min) {
        return getHouseMinErrorText(min);
      } else if (direct && amount > max) {
        return getHouseMaxErrorText(max);
      }
    } else if (policyType === INSURANCE_TYPES_NAMES.CONTENT) {
      if (amount < CONTENTS_MS_MIN_AMOUNT) {
        return getContentsMinErrorText();
      } else if (direct && amount > CONTENTS_DIRECT_MS_MAX) {
        return getContentsMaxErrorText();
      }
    }
  },
  birthday: (question) => (value) => {
    if (question.maxYear || question.minYear) {
      const yearsDiff = moment().diff(moment(value), 'years', true);
      const validMinYear = !question.minYear || yearsDiff >= question.minYear;
      const validMaxYear = !question.maxYear || yearsDiff <= question.maxYear;
      return validMinYear && validMaxYear;
    }

    return true;
  },
  [FieldType.MultiForm]: (question) => (values) => {
    return hasAllRequiredValues(question.fields, values);
  },
  [FieldType.MultiRow]: (question) => (values) => {
    return hasAllRequiredValues(question.fields, values);
  },
};

export const validateQuestion = (
  question: IFormQuestion,
  formValues: any,
  value: any,
) => {
  const validator = defaultValidators[question.validator || question.type];
  return validator
    ? validator(question, formValues)(value)
    : !question.required || !R.isNil(value);
};

export const getValidator = (
  type: string,
  question: IFormQuestion,
  formValues: any,
) => {
  if (typeof question.validator === 'function') {
    return question.validator(question, formValues);
  }

  const validator = defaultValidators[question.validator || type];
  if (validator) {
    return validator(question, formValues);
  }
  return undefined;
};
