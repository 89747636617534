import React from 'react';
import { Modal } from 'semantic-ui-react';
import Button from 'components/button';
import OnboardingImg from '-!svg-react-loader!images/adviser/adviser-onboarding.svg';
import styles from './styles/adviserOnboardingModal.scss';
import { IUser } from 'types';

interface IProps {
  open: boolean;
  user: IUser;
  updateUser(user: IUser): (dispatch: any) => void;
}

const AdviserOnboardingModal = ({ open, user, updateUser }: IProps) => {
  const handleSubmit = () => updateUser(R.assoc('q4aOnboardingCompleted', true, user));

  return (
    <Modal
      className={styles.modal}
      open={open}
      size="small"
      closeIcon={false}
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
    >
      <Modal.Content>
        <OnboardingImg className={styles.svg} />
        <div className={styles.title}>Welcome to the Quashed adviser portal</div>
        <div className={styles.subtitle}>Let us help you get started, watch the quick tutorial.</div>
        <video className={styles.video} controls={true} autoPlay={false}>
          <source src="https://app.quashed.co.nz/q4a-intro.mp4" type="video/mp4" />
        </video>
        <Button
          className={styles.submit}
          text="Get started"
          onClick={handleSubmit}
        />
      </Modal.Content>
    </Modal>
  );
};

export default AdviserOnboardingModal;
