// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._13xes77JvR0EefZSLJIUPk{display:flex}._1rAkyhQhPsPw8Gub89bKK9 input{display:none}._1rAkyhQhPsPw8Gub89bKK9 ._226JVYmzEPj3NbcoOc45Lh{border-radius:5px;cursor:pointer;line-height:1.2;min-height:40px;width:75px;background-color:#f7f8fc;justify-content:center;color:#3d67ab;border:1px solid rgba(0,0,0,0)}._1rAkyhQhPsPw8Gub89bKK9 ._226JVYmzEPj3NbcoOc45Lh:hover,._1rAkyhQhPsPw8Gub89bKK9 ._226JVYmzEPj3NbcoOc45Lh.AMNNvpOnOATQxJn2Rb1ap{font-weight:700;border-color:#3d67ab}._1rAkyhQhPsPw8Gub89bKK9 ._2roLkc3t_FOmVyCYo842CR{padding-left:0}._226JVYmzEPj3NbcoOc45Lh{display:flex;align-items:center;line-height:24px}._226JVYmzEPj3NbcoOc45Lh:not(:last-child){margin-right:16px}._2roLkc3t_FOmVyCYo842CR{padding-left:8px}._11PHsAtMhogvI90BW-lp4e ._13xes77JvR0EefZSLJIUPk{padding-left:20px}", ""]);
// Exports
exports.locals = {
	"radioGroup": "_13xes77JvR0EefZSLJIUPk",
	"buttons": "_1rAkyhQhPsPw8Gub89bKK9",
	"radio": "_226JVYmzEPj3NbcoOc45Lh",
	"checked": "AMNNvpOnOATQxJn2Rb1ap",
	"label": "_2roLkc3t_FOmVyCYo842CR",
	"error": "_11PHsAtMhogvI90BW-lp4e"
};
module.exports = exports;
