import { IConfig } from "../../types";
import { AppActionTypes } from "../actions";
import { Types } from "../actions";

const configReducer = (state: IConfig, action: AppActionTypes): IConfig => {
  switch (action.type) {
    case Types.GET_CONFIG_LOADED:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

export default configReducer;
