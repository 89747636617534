import React, { useEffect, useState } from 'react';
import {
  INSURANCE_TYPES_ICONS_SVG_V2,
  INSURANCE_TYPES_NAMES,
} from 'constants/insurance';
import styles from '../styles/PolicyCounter.module.scss';
import Title from 'components/title';
import { Minus, Plus } from 'react-feather';
import { HelpTooltip } from 'components/tooltip';
import { Button } from 'components/quashed';
import { hasSelectedPolicy, PREMIUM_POLICY_TYPES } from '../utils';
import { logPageView, PAGE_VIEW_EVENTS } from 'helpers';

const COMPONENT_ID = 'PolicyCounter';

const PolicyCounterItem = ({
  policyCount,
  policyType,
  decreasePolicyCount,
  increasePolicyCount,
}) => {
  const value = policyCount[policyType] || 0;
  const Icon = INSURANCE_TYPES_ICONS_SVG_V2[policyType];

  return (
    <div className={styles.item}>
      <div className={styles.left}>
        <Icon />
        <span>{policyType}</span>
        {policyType === INSURANCE_TYPES_NAMES.LIFE && (
          <HelpTooltip
            toggleClassName={styles.toggle}
            content="Including: Life, Trauma, TPD, Income protection, Mortgage repayment & Medical insurance."
          />
        )}
      </div>
      <div className={styles.right}>
        <button
          id={`${policyType}_dec`}
          className={styles.button}
          onClick={() => decreasePolicyCount(policyType)}
        >
          <Minus size={28} strokeLinecap="square" strokeWidth={4} />
        </button>
        <div className={styles.value}>{value}</div>
        <button
          id={`${policyType}_inc`}
          className={styles.button}
          onClick={() => increasePolicyCount(policyType)}
        >
          <Plus size={26} strokeLinecap="square" strokeWidth={4} />
        </button>
      </div>
    </div>
  );
};

const PolicyCounter = ({
  policyCount,
  decreasePolicyCount,
  increasePolicyCount,
  next,
  user,
}) => {
  const showButton = hasSelectedPolicy(policyCount);
  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 1, premium: !!user.premium });
  }, []);
  return (
    <div id={COMPONENT_ID} className={styles.container}>
      <Title
        title="What insurance can we help you with?"
        subtitle="We'll connect you with an insurance adviser for a chat."
      />
      <div className={styles.items}>
        {PREMIUM_POLICY_TYPES.map((pt) => (
          <PolicyCounterItem
            key={pt}
            policyCount={policyCount}
            policyType={pt}
            decreasePolicyCount={decreasePolicyCount}
            increasePolicyCount={increasePolicyCount}
          />
        ))}
      </div>
      {showButton && (
        <div className={styles.buttons}>
          <Button
            type="submit"
            className={styles.submit}
            onClick={next}
            text="Next"
          />
        </div>
      )}
    </div>
  );
};

export default PolicyCounter;
