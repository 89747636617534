import { HandlePolicyScanNotification } from 'actions';
import { COLORS, TASK_TYPES } from 'constants/notification';
import {
  BROKER_FORM_ROUTE,
  MARKET_SCAN_PURCHASE_ROUTE,
  MARKET_SCAN_ROUTE,
  SHARE_ROUTE,
} from 'constants/routes';
import {
  getFieldByPolicyType,
  getMarketScanResultsRoute,
  getPolicyDetailsRoute,
  getPremiumMarketScanResultsRoute,
  isPremiumMarketScan,
} from 'helpers';
import history from 'helpers/history';
import React from 'react';
import { IAppState, INotification, IPolicyScan } from 'types';

const getMsDescription = (
  policyScans: IPolicyScan[],
  { target, policyType }: INotification,
) => {
  const scan = getById(policyScans, target);
  const insuranceField = getFieldByPolicyType(policyType);
  return scan?.[insuranceField?.fieldKey] || policyType;
};

const getById = (items, id) => {
  return items.find(R.propEq('id', id));
};

export const handleNotificationClick = (
  notification: INotification,
  dispatch,
  appState: IAppState,
  setGlobalModalInfo: React.Dispatch<
    React.SetStateAction<{
      modalInfo: {
        title: string;
        shortTitle: string;
        description: string;
        shortDescription: string;
        imgUrl: string;
        actionText: string;
        actionUrl: string;
      };
      show: boolean;
    }>
  >,
) => {
  switch (notification.notificationType) {
    case TASK_TYPES.ACTIVE_SHARE_POLICIES:
    case TASK_TYPES.PENDING_SHARE_POLICIES: {
      history.push(SHARE_ROUTE);
      break;
    }
    case TASK_TYPES.POLICY_SCAN_PENDING:
    case TASK_TYPES.POLICY_SCAN_IN_REVIEW: {
      const item = getById(appState.policies.policyScans, notification.target);
      if (item) {
        dispatch(HandlePolicyScanNotification(item));
      }
      break;
    }
    case TASK_TYPES.POLICY_SCAN_CONTINUE: {
      const item = getById(appState.policies.policies, notification.target);
      if (item) {
        history.push({
          pathname: MARKET_SCAN_ROUTE,
          state: { policy: item },
        });
      }
      break;
    }
    case TASK_TYPES.POLICY_SCAN_READY: {
      isPremiumMarketScan(notification)
        ? history.push(
            getPremiumMarketScanResultsRoute(
              notification.target,
              notification.policyId,
            ),
          )
        : history.push(
            getMarketScanResultsRoute(
              notification.target,
              notification.policyId,
            ),
          );
      break;
    }
    case TASK_TYPES.POLICY_SCAN_PROTECTA_REFERRAL:
    case TASK_TYPES.POLICY_SCAN_PURCHASE_PROGRESS: {
      const item = getById(appState.policies.policyScans, notification.target);
      if (item) {
        history.push(
          `${MARKET_SCAN_PURCHASE_ROUTE}?id=${item.id}&sk=${item.sk}&providerName=${item.savedQuoteInfo?.providerName}`,
        );
      }
      break;
    }

    case TASK_TYPES.COMPLETE_BROKER_FORM: {
      const item = getById(appState.policies.policyScans, notification.target);
      // Route to MS results page if broker form is already completed
      isPremiumMarketScan(notification)
        ? history.push(getPremiumMarketScanResultsRoute(item.id, item.sk))
        : history.push(
            `${BROKER_FORM_ROUTE.replace(':policyType', item.policyType)}/?id=${
              item.id
            }&sk=${item.sk}`,
          );
      break;
    }

    case TASK_TYPES.POLICY_SCAN_PURCHASE_COMPLETED:
    case TASK_TYPES.POLICY_RENEWAL:
    case TASK_TYPES.POLICY_EXPIRED: {
      const route = getPolicyDetailsRoute(notification.target);
      history.push(route);
      break;
    }
    case TASK_TYPES.GLOBAL_NOTIFICATION:
      setGlobalModalInfo({
        modalInfo: notification.modalInfo,
        show: true,
      });
      break;
    default:
      break;
  }
};

export const getDotColor = (taskType: TASK_TYPES) => {
  switch (taskType) {
    case TASK_TYPES.POLICY_RENEWAL:
    case TASK_TYPES.POLICY_SCAN_PENDING:
    case TASK_TYPES.POLICY_SCAN_IN_REVIEW:
    case TASK_TYPES.POLICY_SCAN_PROTECTA_REFERRAL:
    case TASK_TYPES.POLICY_SCAN_PURCHASE_PROGRESS:
    case TASK_TYPES.PENDING_SHARE_POLICIES:
    case TASK_TYPES.COMPLETE_BROKER_FORM:
      return COLORS.BLUE;
    case TASK_TYPES.POLICY_EXPIRED:
      return COLORS.RED;
    default:
      return COLORS.GREEN;
  }
};

export const getText = (notification: INotification, appState: IAppState) => {
  switch (notification.notificationType) {
    case TASK_TYPES.POLICY_EXPIRED: {
      const p = getById(appState.policies.policies, notification.target);
      const insuranceField = getFieldByPolicyType(notification.policyType);
      const title = 'Policy';
      const subtitle = 'Renew now';
      return {
        title,
        subtitle,
        description: p?.[insuranceField?.fieldKey],
      };
    }
    case TASK_TYPES.POLICY_RENEWAL: {
      const p = getById(appState.policies.policies, notification.target);
      const insuranceField = getFieldByPolicyType(notification.policyType);
      const title = 'Policy';
      const subtitle = 'Expiring soon';
      return {
        title,
        subtitle,
        description: p?.[insuranceField?.fieldKey],
      };
    }
    case TASK_TYPES.POLICY_SCAN_PURCHASE_COMPLETED: {
      const p = getById(appState.policies.policies, notification.target);
      const insuranceField = getFieldByPolicyType(notification.policyType);
      const title = 'Policy';
      const subtitle = 'Purchase completed';
      return {
        title,
        subtitle,
        description: p?.[insuranceField?.fieldKey],
      };
    }
    case TASK_TYPES.POLICY_SCAN_PENDING:
    case TASK_TYPES.POLICY_SCAN_IN_REVIEW: {
      const title = 'Market Scan';
      const subtitle = 'Pending';
      const description = getMsDescription(
        appState.policies.policyScans,
        notification,
      );
      return {
        title,
        subtitle,
        description,
      };
    }
    case TASK_TYPES.POLICY_SCAN_CONTINUE: {
      const title = 'Market Scan';
      const subtitle = 'Continue now';
      const description = getMsDescription(
        appState.policies.policyScans,
        notification,
      );
      return {
        title,
        subtitle,
        description,
      };
    }
    case TASK_TYPES.POLICY_SCAN_READY: {
      const title = 'Market Scan';
      const subtitle = 'Ready';
      const description = getMsDescription(
        appState.policies.policyScans,
        notification,
      );
      return {
        title,
        subtitle,
        description,
      };
    }
    case TASK_TYPES.POLICY_SCAN_PROTECTA_REFERRAL: {
      const scan = getById(appState.policies.policyScans, notification.target);
      const title = 'Protecta Purchase';
      const description = scan?.referral?.policyId
        ? `Referral ID ${scan.referral?.policyId}`
        : '';
      return {
        title,
        description,
      };
    }
    case TASK_TYPES.POLICY_SCAN_PURCHASE_PROGRESS: {
      const title = 'Protecta Purchase';
      const description = 'Finish now';
      return {
        title,
        description,
      };
    }
    case TASK_TYPES.PENDING_SHARE_POLICIES: {
      const title = 'Shared Dashboard';
      const subtitle = `Invited`;
      const description = notification.inviteeName;
      return {
        title,
        subtitle,
        description,
      };
    }
    case TASK_TYPES.ACTIVE_SHARE_POLICIES: {
      const title = `Shared Dashboard`;
      const subtitle = `Active`;
      const description = notification.inviteeName;
      return {
        title,
        subtitle,
        description,
      };
    }
    case TASK_TYPES.COMPLETE_BROKER_FORM: {
      const title = `Complete questions for more quotes`;
      const description = getMsDescription(
        appState.policies.policyScans,
        notification,
      );
      return {
        title,
        description,
      };
    }
    case TASK_TYPES.GLOBAL_NOTIFICATION: {
      const title = notification.modalInfo.shortTitle;
      const subtitle = `now live`;
      const description = notification.modalInfo.shortDescription;
      return {
        title,
        subtitle,
        description,
      };
    }

    default:
      return {
        title: '',
        description: '',
      };
  }
};
