import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Form, Image } from 'semantic-ui-react';
import FormField from 'components/form-field';
import { ProfileImageModal } from 'components/modals';
import PasswordReset from 'components/password-reset';
import { ADVISER_FIELDS } from 'constants/user-form';
import useForm from 'hooks/useForm';
import { IFile } from 'services';
import { IUser } from 'types';
import styles from './styles/adviserProfile.scss';
import { getProfilePictureSrc } from 'helpers';

interface IProps {
  auth0: any;
  children?: React.ReactNode;
  disabled?: boolean;
  user: IUser;
  updateUser?(user: IUser): (dispatch: any) => void;
  updateProfilePhoto?(file: IFile): void;
}

const uploadBtn = (
  <div className={styles.uploadBtn}>
    <b className={styles.textBtn}>Change</b>
  </div>
);

const AdviserProfileForm = ({
  auth0,
  children,
  disabled,
  user,
  updateUser,
  updateProfilePhoto,
}: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const picture = getProfilePictureSrc(user.picture || auth0.picture);

  const onSubmit = useCallback(
    ({ values }) => {
      const payload = {
        ...user,
        ...values,
      };
      updateUser(payload);
      setEdit(false);
    },
    [user, updateUser, setEdit],
  );

  const form = useForm({
    defaultValues: user,
    onSubmit,
  });

  const handleCancel = useCallback(() => {
    setEdit(false);
    form.handleReset();
  }, [form]);

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    setEdit(true);
  }, []);

  const closeModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Profile</div>
      <div className={styles.body}>
        <div className={styles.imageContainer}>
          <Image className={styles.avatar} src={picture} avatar={true} />
          {!disabled && (
            <ProfileImageModal
              open={openModal}
              onClose={closeModal}
              onOpen={showModal}
              trigger={uploadBtn}
              onUpload={updateProfilePhoto}
            />
          )}
        </div>
        <div className={styles.questions}>
          <Form className={styles.form}>
            {ADVISER_FIELDS.map((field) => (
              <FormField
                key={field.key}
                name={field.key}
                className={classnames(styles.field)}
                labelClassName={styles.label}
                inputClassName={styles.input}
                onChange={form.handleInputChange}
                value={form.values[field.key]}
                disabled={!isEdit}
                {...field}
              />
            ))}
            {!disabled && (
              <div className={styles.buttons}>
                {isEdit ? (
                  <>
                    <div className={styles.textBtn} onClick={form.handleSubmit}>
                      Save
                    </div>
                    <div className={styles.textBtn} onClick={handleCancel}>
                      Cancel
                    </div>
                  </>
                ) : (
                  <div className={styles.textBtn} onClick={handleEdit}>
                    Edit
                  </div>
                )}
              </div>
            )}
          </Form>
          {!disabled && (
            <PasswordReset className={styles.reset} email={user.email}>
              Reset password?
            </PasswordReset>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdviserProfileForm;
