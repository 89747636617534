import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import Car from './CarCalculator';
import Contents from './ContentsCalculator';
import Income from './IncomeCalculator';
import Life from './LifeCalculator';

export default {
  [INSURANCE_TYPES_NAMES.CONTENT]: Contents,
  [INSURANCE_TYPES_NAMES.INCOME]: Income,
  [INSURANCE_TYPES_NAMES.LIFE]: Life,
  [INSURANCE_TYPES_NAMES.VEHICLE]: Car,
};