import { FieldType } from 'types';
import AddressInput from './AddressInput';
import BirthdayInput from './BirthdayInput';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import RadioGroup from './RadioGroup';
import SearchInput from './SearchInput';
import Select from './Select';
import TextArea from './TextArea';
import TextInput from './TextInput';

const FieldTypeMap = {
  [FieldType.Address]: AddressInput,
  [FieldType.Birthday]: BirthdayInput,
  [FieldType.Checkbox]: Checkbox,
  [FieldType.CheckboxGroup]: CheckboxGroup,
  [FieldType.Date]: DateInput,
  [FieldType.Number]: NumberInput,
  [FieldType.Radio]: RadioGroup,
  [FieldType.Search]: SearchInput,
  [FieldType.Select]: Select,
  [FieldType.TextArea]: TextArea,
  [FieldType.Text]: TextInput,
};

export {
  FieldTypeMap,
  AddressInput,
  DateInput,
  BirthdayInput,
  NumberInput,
  RadioGroup,
  SearchInput,
  Select,
  TextArea,
  TextInput,
};
