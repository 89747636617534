// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".FgMbcnU9M-pgt5IKG5LM3{max-width:1150px;padding-bottom:64px}._3A3URDz7mTmDiVTXd0Vk4z>a>div:nth-child(3)>div>span{display:none}.iy44-eplfuvP2ak1H4oJp{text-align:right}._1-d9BWqkrH619EOZhulWSW{background-color:#4187f5;color:#fff;padding:8px;margin-left:auto}._1-d9BWqkrH619EOZhulWSW svg{margin-right:8px;margin-left:-8px}._1-d9BWqkrH619EOZhulWSW svg path{fill:#fff}@media(min-width: 821px)and (max-width: 1300px){._3A3URDz7mTmDiVTXd0Vk4z>a>div:nth-child(4),._3A3URDz7mTmDiVTXd0Vk4z>a>div:nth-child(5){display:block !important}}", ""]);
// Exports
exports.locals = {
	"container": "FgMbcnU9M-pgt5IKG5LM3",
	"title": "_3A3URDz7mTmDiVTXd0Vk4z",
	"btnContainer": "iy44-eplfuvP2ak1H4oJp",
	"checkout": "_1-d9BWqkrH619EOZhulWSW"
};
module.exports = exports;
