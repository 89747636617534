// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3OVnq-72fJb4GYwvZyDijs.ui.popup{padding:0;min-width:420px;border-radius:5px}._2XImCPzZhTJbNVfp2Sp2v7{cursor:pointer;width:38px;height:38px;display:flex;align-items:center;justify-content:center;position:relative;border-radius:50%;padding:8px;background-color:#ebedf3}._2XImCPzZhTJbNVfp2Sp2v7.EbRRpuN714LNsl7qUrU_e{background-color:#339bfa;border-color:#339bfa}._2XImCPzZhTJbNVfp2Sp2v7 i.icon{font-size:22px;color:#fff;margin-right:0}.o-GSD7bt9lmCWQjXqCE4C{background-color:#e47c6e;font-size:10px;font-weight:bold;color:#fff;display:flex;align-items:center;justify-content:center;position:absolute;bottom:15px;right:8px;border-radius:50%;width:8px;height:8px}", ""]);
// Exports
exports.locals = {
	"popup": "_3OVnq-72fJb4GYwvZyDijs",
	"trigger": "_2XImCPzZhTJbNVfp2Sp2v7",
	"open": "EbRRpuN714LNsl7qUrU_e",
	"count": "o-GSD7bt9lmCWQjXqCE4C"
};
module.exports = exports;
