// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1u6MNRoRP9RgUsegyc5VHy.ui.modal{width:auto}._190C1O5DZdednBRqngwmGi{position:relative;height:calc(100vh - 150px);max-height:1100px;width:700px}._2xzPUXr6ux3zcqVs8lNQqa{width:fit-content}._2xzPUXr6ux3zcqVs8lNQqa.thoVzUxkzTx9BjXyX2XGg{display:none}._2hhbyuFNwxJB9LmARNTNPa:hover ._2uiq2sNcI_Vojcct4j_sIW{opacity:1}._2uiq2sNcI_Vojcct4j_sIW{position:absolute;bottom:5%;left:50%;background:#fff;opacity:0;transform:translateX(-50%);transition:opacity ease-in-out .2s;box-shadow:1px 1px 4px 0 rgba(138,138,138,.5);border-radius:5px}._2uiq2sNcI_Vojcct4j_sIW._2jHl0yORNLnFztzxK-8K96,._2uiq2sNcI_Vojcct4j_sIW.DhIzh58j5rY_PnJylYqEA{opacity:1}._2uiq2sNcI_Vojcct4j_sIW.DhIzh58j5rY_PnJylYqEA{bottom:-50px}._2uiq2sNcI_Vojcct4j_sIW.DhIzh58j5rY_PnJylYqEA button{color:#34363e}._2uiq2sNcI_Vojcct4j_sIW.DhIzh58j5rY_PnJylYqEA button:disabled{opacity:.5}._2uiq2sNcI_Vojcct4j_sIW span{font-family:\"Open Sans\";font-weight:400;font-size:14px;margin:10px}._2uiq2sNcI_Vojcct4j_sIW button{width:44px;height:44px;background:#fff;border:0;font-size:.8em;border-radius:4px}._2uiq2sNcI_Vojcct4j_sIW button i.icon{margin:0}._2uiq2sNcI_Vojcct4j_sIW button:enabled:hover,._2uiq2sNcI_Vojcct4j_sIW button:enabled:focus{background-color:#e6e6e6}._2uiq2sNcI_Vojcct4j_sIW button:enabled:hover{cursor:pointer}._2uiq2sNcI_Vojcct4j_sIW button:first-child{border-top-right-radius:0;border-bottom-right-radius:0}._2uiq2sNcI_Vojcct4j_sIW button:last-child{border-top-left-radius:0;border-bottom-left-radius:0}@media(max-width: 820px){._190C1O5DZdednBRqngwmGi{width:calc(100vw - 75px)}}", ""]);
// Exports
exports.locals = {
	"modal": "_1u6MNRoRP9RgUsegyc5VHy",
	"wrapper": "_190C1O5DZdednBRqngwmGi",
	"imgWrap": "_2xzPUXr6ux3zcqVs8lNQqa",
	"hide": "thoVzUxkzTx9BjXyX2XGg",
	"document": "_2hhbyuFNwxJB9LmARNTNPa",
	"pageControls": "_2uiq2sNcI_Vojcct4j_sIW",
	"show": "_2jHl0yORNLnFztzxK-8K96",
	"img": "DhIzh58j5rY_PnJylYqEA"
};
module.exports = exports;
