// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1xpoA8Q2MuC9d4psiBwbzi{color:#34363e;padding:24px;padding-right:0}._2Smzqj3w2438V3Lq37RW2S{display:flex;align-items:center;margin-bottom:8px}._2Smzqj3w2438V3Lq37RW2S img{height:30px}.TmPvk-DQjanRK6_2Hht6-{overflow:hidden;font-size:12px;margin-right:24px}.TmPvk-DQjanRK6_2Hht6->*{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.JX1bIKX-68u9PE1I9uXJh{width:34px;height:34px;min-width:34px;min-height:34px;padding:8px;background-color:#787777;margin-left:auto;border-radius:50%;display:flex;align-items:center;justify-content:center}.JX1bIKX-68u9PE1I9uXJh svg{width:100%;height:auto}.JX1bIKX-68u9PE1I9uXJh svg path{fill:#fff}._39CM-iRdjkmZWai6KhUgm3{margin-bottom:4px}._1Oh04boV16fu4yftCa-aNr{font-size:12px;cursor:pointer;display:block;width:45px;margin-left:auto}", ""]);
// Exports
exports.locals = {
	"container": "_1xpoA8Q2MuC9d4psiBwbzi",
	"header": "_2Smzqj3w2438V3Lq37RW2S",
	"title": "TmPvk-DQjanRK6_2Hht6-",
	"policyType": "JX1bIKX-68u9PE1I9uXJh",
	"row": "_39CM-iRdjkmZWai6KhUgm3",
	"remove": "_1Oh04boV16fu4yftCa-aNr"
};
module.exports = exports;
