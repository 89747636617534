import { getHeaderOptions, getHttpService } from './http-service';
import {
  IAiaQuoteRequest,
  IPinnacleQuoteRequest,
  IQuoteCoverRequest,
  IQuoteRequest,
  IQuoteResult,
  IUserQuote,
} from 'types';

const httpClient = getHttpService();

export const createQuote = (quoteRequest: IQuoteRequest) => {
  return httpClient.post<IQuoteResult>(`/quote`, quoteRequest, {
    headers: getHeaderOptions(),
  });
};

export const requestCover = (quoteRequest: IQuoteCoverRequest) => {
  return httpClient.post(`/quote/cover`, quoteRequest, {
    headers: getHeaderOptions(),
  });
};

export const updateQuote = (quote: IUserQuote) => {
  return httpClient.put<IUserQuote>(`/quote`, quote, {
    headers: getHeaderOptions(),
  });
};

export const requestAiaQuote = (quoteRequest: IAiaQuoteRequest) => {
  return httpClient.post(`/quote/aia`, quoteRequest, {
    headers: getHeaderOptions(),
  });
};

export const getAiaCover = (payload) => {
  return httpClient.post(`/quote/aia/getCover`, payload, {
    headers: getHeaderOptions(),
  });
};

export const requestPinnacleQuote = (quoteRequest: IPinnacleQuoteRequest) => {
  return httpClient.post(`/quote/pinnacle`, quoteRequest, {
    headers: getHeaderOptions(),
  });
};
