import React, { useState, useCallback, useEffect } from 'react';

interface IUseFormProps {
  defaultValues: object;
  resetOnSubmit?: boolean;
  onDefaultValueUpdate?: (prevValues: any) => any;
  onSubmit?: (form) => void;
}

interface IMultipleInputItem {
  as: string;
  value: string;
  order: number;
  isRemove: string;
  fieldKey: string;
}

export interface IUseForm {
  values: any;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>,
  ) => void;
  handleMultipleInputChange: (item: IMultipleInputItem) => void;
  handleDropdownChange: (_, data) => void;
  handleDropdownIdNameChange: (_, data) => void;
  handleCheckboxChange: (_, data) => void;
  handleSubmit: (e?: any) => void;
  handleReset: () => void;
  setValues: (e: object) => void;
  setValue: (key: string, value: any) => void;
}

const useForm = ({
  defaultValues,
  resetOnSubmit,
  onDefaultValueUpdate,
  onSubmit,
}: IUseFormProps): IUseForm => {
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    setValues((prevValues) =>
      onDefaultValueUpdate
        ? onDefaultValueUpdate(prevValues)
        : R.merge(defaultValues, prevValues),
    );
  }, [defaultValues]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) {
        event.persist();
      }
      const { name, value } = event.target;
      setValues({ ...values, [name]: value });
    },
    [values],
  );

  const handleDropdownChange = useCallback(
    (_, { name, value }) => {
      setValues({ ...values, [name]: value });
    },
    [values],
  );

  const handleDropdownIdNameChange = useCallback(
    (_, { name, value, options }) => {
      const { text } = options.find(R.propEq('value', value));
      setValues({
        ...values,
        [`${name}Id`]: value,
        [`${name}Name`]: text,
      });
    },
    [values],
  );

  const handleCheckboxChange = useCallback(
    (_, { name, checked }) => {
      setValues({ ...values, [name]: checked });
    },
    [values],
  );

  const handleReset = useCallback(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  const handleSubmit = useCallback(
    (event: any) => {
      if (event) {
        event.preventDefault();
      }
      onSubmit({ values });

      if (resetOnSubmit) {
        handleReset();
      }
    },
    [handleReset, onSubmit, resetOnSubmit, values],
  );

  const handleMultipleInputChange = useCallback(
    ({ as, value, order, isRemove, fieldKey }) => {
      setValues((prevState) => {
        const prevItems = prevState[as] || [];

        if (isRemove) {
          const removed = prevItems.reduce((acc, p, i) => {
            if (p.order > order) {
              p.order = i;
            }

            if (p.order !== order) {
              acc.push(p);
            }

            return acc;
          }, []);
          return R.assoc(as, removed, prevState);
        }

        const prevItem = prevItems[order];
        const updatedItem = {
          order,
          ...prevItem,
          [fieldKey]: value,
        };
        const updatedItems = prevItem
          ? R.update(order, updatedItem, prevItems)
          : R.insert(order, updatedItem, prevItems);
        return R.assoc(as, updatedItems, prevState);
      });
    },
    [],
  );

  const setValue = (key: string, value: any) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  return {
    values,
    handleInputChange,
    handleMultipleInputChange,
    handleDropdownChange,
    handleDropdownIdNameChange,
    handleCheckboxChange,
    handleSubmit,
    handleReset,
    setValues,
    setValue,
  };
};

export default useForm;
