import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Button as SemanticButton,
  Icon,
  Popup,
  SemanticICONS,
  Image,
  Ref,
} from 'semantic-ui-react';
import styles from './button.scss';
import { trackEvent } from 'helpers';

interface IButtonProps {
  className?: string;
  contentClassName?: string;
  icon?: SemanticICONS;
  link?: any;
  text: string;
  onClick?: (e?: any) => void;
  [key: string]: any;
}

interface ICardButtonProps extends IButtonProps {
  hoverText?: string;
  src?: string;
}

export const CardButton = (props: ICardButtonProps) => {
  const {
    className,
    contentClassName,
    text,
    hoverText,
    src,
    label,
    subtext,
  } = props;

  const handleClick = useCallback(
    (e) => props.onClick && props.onClick(props, e),
    [props],
  );

  return (
    <div className={`${styles.cardButton} ${className}`} onClick={handleClick}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={classnames(styles.content, contentClassName)}>
        {src && <Image className={styles.image} src={src} />}
        <div className={styles.cardText}>{text}</div>
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
      {hoverText && (
        <Popup
          className={styles.cardPopup}
          content={hoverText}
          trigger={
            <Icon
              disabled={true}
              name="question circle"
              className={styles.cardToggle}
            />
          }
          position="bottom right"
          basic={true}
        />
      )}
    </div>
  );
};

const Button = React.forwardRef(
  (
    {
      id,
      text,
      icon,
      link,
      className,
      onClick,
      type,
      labelPosition,
      disabled,
      style,
      eventName,
      eventData = {},
      target,
      loading,
      name,
    }: IButtonProps,
    ref: any,
  ) => {
    const handleClick = useCallback(
      (e) => {
        if (eventName) {
          trackEvent(eventName, eventData);
        }
        if (onClick) {
          onClick(e);
        }
      },
      [eventName, eventData, onClick],
    );

    const component = (
      <SemanticButton
        id={id}
        disabled={disabled}
        icon={!!icon}
        labelPosition={!!icon ? labelPosition || 'right' : null}
        className={`${styles.button} ${className}`}
        as={link && Link}
        to={link}
        onClick={handleClick}
        type={type}
        style={style}
        ref={ref}
        target={target}
        loading={loading}
        name={name}
      >
        {text}
        {icon && <Icon name={icon} />}
      </SemanticButton>
    );

    return ref ? <Ref innerRef={ref}>{component}</Ref> : component;
  },
);

export default Button;
