import React, { Ref } from 'react';
import Dropzone, { DropEvent, DropzoneRef } from 'react-dropzone';
import Button from 'components/button';
import UploadGif from 'images/policy/upload.gif';
import Spinner from 'components/spinner';
import styles from './uploader.scss';
import UploaderPopup from './UploaderPopup';
import { IPopupProps } from 'hooks/useUploader';
import UploadSvg from '-!svg-react-loader!images/policy/upload2.svg';

export interface IUploadedProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  accept?: string;
  placeholderTitle?: string;
  placeholder?: string;
  placeholderClassName?: string;
  isEmpty?: boolean;
  isUploading?: boolean;
  popupProps?: IPopupProps;
  onDrop?<T extends File>(
    acceptedFiles: T[],
    rejectedFiles: T[],
    event: DropEvent,
  ): void;
  onPopupAction?(confirm: boolean): void;
}

const MAX_SIZE_BYTES = 20000000;

const UploaderPlaceholder = ({
  open,
  isEmpty,
  placeholder,
  className,
  placeholderTitle = 'You currently have no documents stored here',
}) => (
  <div className={classnames(styles.placeholder, className)}>
    <img className={styles.img} src={UploadGif} />
    <UploadSvg className={styles.imgMobile} />
    <div className={styles.right}>
      {isEmpty && <div className={styles.title}>{placeholderTitle}</div>}
      <div className={styles.subtitle}>
        {placeholder ? (
          <>
            {placeholder}
            <div className={styles.buttons}>
              <Button
                id="Uploader_BrowseBtn"
                className={styles.browse}
                onClick={open}
                text="Browse"
              />
              <span className={styles.dragText}>Or drag and drop</span>
            </div>
          </>
        ) : (
          <>
            <span className={styles.linkBtn}>
              <u id="Uploader_BrowseBtn" onClick={open}>
                Choose files
              </u>{' '}
              or <b>drag and drop</b> your files here.
            </span>
            <Button
              id="Uploader_BrowseBtn_Mobile"
              className={styles.linkBtnMobile}
              text="Choose files or photos"
              onClick={open}
            />
            <div className={styles.linkBtnSubtitle}>
              e.g Policy schedules, renewal documents...
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

const hasChildren = (children) =>
  children && React.Children.toArray(children).length > 0;

const Uploader = React.forwardRef(
  (
    {
      onDrop,
      children,
      className,
      isEmpty,
      isUploading,
      placeholder,
      accept = '.pdf, image/jpeg, image/png',
      popupProps,
      placeholderClassName,
      placeholderTitle,
      onPopupAction,
    }: IUploadedProps,
    ref?: Ref<DropzoneRef>,
  ) => {
    return (
      <Dropzone
        noClick={true}
        noKeyboard={true}
        accept={accept}
        onDrop={onDrop}
        ref={ref}
        maxSize={MAX_SIZE_BYTES}
        multiple={true}
        maxFiles={2}
      >
        {({ getRootProps, getInputProps, open, isDragActive }) => (
          <div
            {...getRootProps({
              className: classnames(
                styles.dropzone,
                isDragActive && styles.dropzoneActive,
                className,
              ),
            })}
          >
            <Spinner
              className={styles.loading}
              loading={isUploading}
              content="Uploading"
            />
            <input {...getInputProps()} />
            {hasChildren(children) ? (
              children
            ) : (
              <UploaderPlaceholder
                className={placeholderClassName}
                open={open}
                isEmpty={isEmpty}
                placeholder={placeholder}
                placeholderTitle={placeholderTitle}
              />
            )}
            {popupProps && (
              <UploaderPopup
                popupProps={popupProps}
                onPopupAction={onPopupAction}
              />
            )}
          </div>
        )}
      </Dropzone>
    );
  },
);
export default Uploader;
