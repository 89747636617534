// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._NnqQKfqxf0fjQHLeYphg.ui.dropdown{position:initial}._NnqQKfqxf0fjQHLeYphg.ui.dropdown .menu{left:auto;right:0;top:0;margin-top:0 !important;width:200px;padding:16px 0;min-height:110px}._NnqQKfqxf0fjQHLeYphg.ui.dropdown .menu>.header{font-family:\"Open Sans\";font-weight:700;color:#34363e;opacity:.75;font-size:14px;margin:0;padding:8px 24px;text-transform:none}._NnqQKfqxf0fjQHLeYphg.ui.dropdown .menu>.item{font-family:\"Open Sans\";font-weight:400;font-size:14px;display:flex;align-items:center;padding:8px 24px !important}._NnqQKfqxf0fjQHLeYphg.ui.dropdown .menu>.item svg{color:#34363e;margin-right:16px}._NnqQKfqxf0fjQHLeYphg.ui.dropdown i.icon{position:relative !important;transform:none !important;color:#34363e}", ""]);
// Exports
exports.locals = {
	"action": "_NnqQKfqxf0fjQHLeYphg"
};
module.exports = exports;
