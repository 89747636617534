import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ChevronLeft, X } from 'react-feather';
import { Popup } from 'semantic-ui-react';
import {
  ADMIN_ROUTE,
  OPERATIONS_ROUTE,
  CLIENTS_ROUTE,
  CONSENTS_ROUTE,
  EXPLORE_CC_ROUTE,
  EXPLORE_COVERAGE_ROUTE,
  EXPLORE_DISCOVER_POLICY_SELECT_ROUTE,
  MARKET_SCAN_ROUTE,
  SHARE_ROUTE,
  WORKPLACE_ROUTE,
  PROFILE_ROUTE,
  HOME_ROUTE,
  PREMIUM_ROUTE,
  NOTIFICATIONS_ROUTE,
} from 'constants/routes';
import { IAdviserState, IUser } from 'types';
import styles from './sidebar.scss';
import { useAuth0 } from '../../react-auth0-spa';
import AdviserIcon from '-!svg-react-loader!icons/nav/adviser.svg';
import CheckupIcon from '-!svg-react-loader!icons/nav/checkup.svg';
import CreditCardIcon from '-!svg-react-loader!icons/nav/creditcard.svg';
import ExploreIcon from '-!svg-react-loader!icons/nav/explore.svg';
import AdviserInvitesIcon from '-!svg-react-loader!icons/nav/invites.svg';
import MarketScanIcon from '-!svg-react-loader!icons/nav/marketscan.svg';
import SettingsIcon from '-!svg-react-loader!icons/nav/settings.svg';
import SharedIcon from '-!svg-react-loader!icons/nav/shared.svg';
import WorkplaceIcon from '-!svg-react-loader!icons/nav/workplace.svg';
import FeedbackIcon from '-!svg-react-loader!icons/nav/feedback.svg';
import LogoutIcon from '-!svg-react-loader!icons/nav/logout.svg';
import Logo from 'components/logo';
import { UserRoles } from 'constants/user';
import { ClientConsentStatus } from 'constants/adviser';
import useBreakpoint from 'hooks/useBreakpoint';
import { useClickOutside } from 'hooks/useClickOutside';
import { isPremiumOnboardingIncomplete, isPremiumRoute } from 'helpers';
import { Bell } from 'react-feather';

interface ISideBarProps {
  className?: string;
  showSetup?: boolean;
  adviser: IAdviserState;
  maximised: boolean;
  mobile: boolean;
  small: boolean;
  user: IUser;
  openSidebar(): void;
  closeSidebar(): void;
  closeMobileSidebar(): void;
}

const menuItems = [
  // User items
  {
    route: MARKET_SCAN_ROUTE,
    icon: MarketScanIcon,
    label: 'Market Scan',
    className: styles.stroke,
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: EXPLORE_COVERAGE_ROUTE,
    exact: true,
    icon: CheckupIcon,
    label: 'Insurance check up',
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: EXPLORE_DISCOVER_POLICY_SELECT_ROUTE,
    icon: ExploreIcon,
    label: 'Explore insurance',
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: EXPLORE_CC_ROUTE,
    icon: CreditCardIcon,
    label: 'Credit card benefits',
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: WORKPLACE_ROUTE,
    icon: WorkplaceIcon,
    label: 'Add workplace',
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: SHARE_ROUTE,
    icon: SharedIcon,
    label: 'Shared dashboards',
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },
  {
    route: NOTIFICATIONS_ROUTE,
    icon: Bell,
    label: 'Notifications',
    className: styles.noFill,
    roles: [UserRoles.ADMIN, UserRoles.USER],
    skip: isPremiumOnboardingIncomplete,
  },

  // Admin items
  {
    route: ADMIN_ROUTE,
    icon: SettingsIcon,
    label: 'Admin',
    className: styles.noFill,
    roles: [UserRoles.ADMIN],
  },
  {
    route: OPERATIONS_ROUTE,
    icon: SettingsIcon,
    label: 'Operations',
    className: styles.noFill,
    roles: [UserRoles.ADMIN],
  },

  // Adviser items
  {
    route: CONSENTS_ROUTE,
    icon: AdviserInvitesIcon,
    label: 'Invites',
    className: styles.noFill,
    getLabel: ({ adviser }) => {
      if (adviser.consents.length === 0) {
        return 'Invites';
      }

      const todo = adviser.consents.filter(
        (c) => c.inviteStatus !== ClientConsentStatus.PENDING,
      );
      return `Invites ${todo.length > 0 ? `(${todo.length})` : ''}`;
    },
    roles: [UserRoles.ADVISER],
  },
  {
    route: CLIENTS_ROUTE,
    icon: AdviserIcon,
    label: 'Clients',
    roles: [UserRoles.ADVISER],
  },
];

const bottomMenuItems = [
  {
    route: PROFILE_ROUTE,
    exact: true,
    getIcon: ({ user }) => <img className={styles.avatar} src={user.picture} />,
    getLabel: ({ user }) => `${user.firstName} ${user.lastName}`,
    roles: [UserRoles.ADMIN, UserRoles.USER],
  },
  {
    icon: FeedbackIcon,
    label: 'Submit feedback',
    onClick: (e) => {
      e.preventDefault();
      window.location.href = 'mailto:team@quashed.co.nz';
    },
    roles: [UserRoles.ADMIN, UserRoles.USER],
  },
  {
    className: styles.noFill,
    icon: LogoutIcon,
    label: 'Log out',
    onClick: (_, { logout }) => logout(),
    roles: [UserRoles.ADMIN, UserRoles.USER],
  },
];

const MenuItem = (props) => {
  const {
    className,
    exact,
    label,
    icon: MenuIcon,
    getIcon,
    roles,
    route,
    getLabel,
    skip = R.F,
    onClick,
  } = props.item;

  const isRoute = exact
    ? props.pathname === route
    : props.pathname.includes(route);

  const iconClassName = classnames(
    styles.icon,
    className,
    isRoute && styles.activeIcon,
  );

  if ((roles && !R.includes(props.role, roles)) || skip(props.user)) {
    return null;
  }

  const l = getLabel ? getLabel(props) : label;
  const icon = getIcon ? (
    getIcon(props)
  ) : (
    <MenuIcon className={iconClassName} />
  );

  const handleClick = (e) => {
    if (onClick) {
      onClick(e, props);
    } else {
      props.closeMobileSidebar();
    }
  };

  const trigger = (
    <Link
      className={classnames(styles.menuItem, isRoute && styles.active)}
      to={route || '#'}
      onClick={handleClick}
    >
      <div className={styles.iconContainer}>{icon}</div>
      <span>{l}</span>
    </Link>
  );

  return (
    <Popup
      key={l}
      disabled={!props.small || props.mobile}
      position="right center"
      trigger={trigger}
      content={l}
      hideOnScroll={true}
    />
  );
};

const Menu = (props) => {
  const breakPoint = useBreakpoint();
  const { pathname } = useLocation();

  const isMobile = breakPoint === 'xs' || breakPoint === 'sm';

  return (
    <div className={styles.menu}>
      <X
        className={styles.close}
        size={26}
        onClick={props.closeMobileSidebar}
      />
      <div className={styles.brand}>
        <Link to={HOME_ROUTE} onClick={props.closeMobileSidebar}>
          <Logo
            sub={!isMobile}
            small={!props.mobile && !isMobile && props.small}
          />
        </Link>
      </div>
      <div className={styles.menuItems}>
        <div className={styles.top}>
          {menuItems.map((item, idx) => {
            return (
              <MenuItem
                key={`menuItem_${idx}`}
                {...props}
                item={item}
                pathname={pathname}
              />
            );
          })}
        </div>
        <div className={styles.bottom}>
          {bottomMenuItems.map((item, idx) => {
            if (item.render) {
              return item.render();
            }

            return (
              <MenuItem
                key={`bottomMenu_${idx}`}
                {...props}
                item={item}
                pathname={pathname}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SideBar = ({
  className,
  adviser,
  maximised,
  mobile,
  small,
  user,
  openSidebar,
  closeSidebar,
  closeMobileSidebar,
}: ISideBarProps) => {
  const location = useLocation();
  const ref = useClickOutside(closeMobileSidebar);
  const { getRole, logout } = useAuth0();
  const role = getRole();
  const containerClassName = classnames(
    styles.container,
    small && styles.small,
    mobile && styles.mobile,
    maximised && styles.hide,
    isPremiumRoute(location) && styles.premium,
    className,
  );

  return (
    <div className={containerClassName} ref={mobile ? ref : null}>
      <div className={styles.tab} onClick={small ? openSidebar : closeSidebar}>
        <ChevronLeft size={20} />
      </div>
      <Menu
        adviser={adviser}
        role={role}
        user={user}
        small={small}
        mobile={mobile}
        closeMobileSidebar={closeMobileSidebar}
        logout={logout}
      />
    </div>
  );
};

export default SideBar;
