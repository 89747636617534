// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1FCL1t1DQRviCybIBGuUi7{position:absolute;right:24px;top:24px;text-align:right}._2MP-85T1qE6-uGrM6OMIFy{font-family:\"DM Sans\";font-weight:700;cursor:pointer}._2MP-85T1qE6-uGrM6OMIFy:first-child{text-decoration:underline}._2MP-85T1qE6-uGrM6OMIFy:nth-child(2){margin-top:8px}._2WG_mgOtYRpWUgIWI1P6x9{display:flex;flex-wrap:wrap;padding:16px 24px;padding-right:60px;position:relative;background-color:rgba(219,223,231,.4);border-radius:5px;margin-bottom:24px}._11TV3y5P01iObbdPIhKHei{flex:1;margin-bottom:0;padding-bottom:8px;padding-right:24px}._11TV3y5P01iObbdPIhKHei .rw-datetime-picker.rw-widget{min-width:100px}.L2kKNwLN056m56Zz6nCAH{min-width:80px;width:inherit}.TotgApDC0PcwIzS6EJTjj{min-width:100px}._3iFzCKZtwxSvDFsUJdiLYJ{border-right:1px solid #34363e;margin-right:24px}._3iFzCKZtwxSvDFsUJdiLYJ,._18jUAnV9Z-s4WKbQXw5RPZ{flex:1;display:flex;flex-wrap:wrap}._3iFzCKZtwxSvDFsUJdiLYJ>*:first-child,._3iFzCKZtwxSvDFsUJdiLYJ>*:nth-child(2),._18jUAnV9Z-s4WKbQXw5RPZ>*:first-child,._18jUAnV9Z-s4WKbQXw5RPZ>*:nth-child(2){flex-basis:50%}@media(max-width: 820px){._2WG_mgOtYRpWUgIWI1P6x9{flex-direction:column}._3iFzCKZtwxSvDFsUJdiLYJ{border:none;margin-right:0}}@media(max-width: 576px){._2WG_mgOtYRpWUgIWI1P6x9{padding-right:24px}._11TV3y5P01iObbdPIhKHei{padding-right:0}._3iFzCKZtwxSvDFsUJdiLYJ ._11TV3y5P01iObbdPIhKHei:nth-child(1),._3iFzCKZtwxSvDFsUJdiLYJ ._11TV3y5P01iObbdPIhKHei:nth-child(2){padding-right:60px}._3iFzCKZtwxSvDFsUJdiLYJ,._18jUAnV9Z-s4WKbQXw5RPZ{flex-direction:column}}", ""]);
// Exports
exports.locals = {
	"buttons": "_1FCL1t1DQRviCybIBGuUi7",
	"textBtn": "_2MP-85T1qE6-uGrM6OMIFy",
	"container": "_2WG_mgOtYRpWUgIWI1P6x9",
	"field": "_11TV3y5P01iObbdPIhKHei",
	"label": "L2kKNwLN056m56Zz6nCAH",
	"input": "TotgApDC0PcwIzS6EJTjj",
	"left": "_3iFzCKZtwxSvDFsUJdiLYJ",
	"right": "_18jUAnV9Z-s4WKbQXw5RPZ"
};
module.exports = exports;
