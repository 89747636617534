import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { arrayOrderByDateKey, getAllQuestions, getUtcDateNow } from 'helpers';
import { IPolicy } from 'types';

const FIELD_KEYS_TO_IGNORE = [
  'amount',
  'excess',
  'propertyOccupants',
  'optionalCovers',
  'make',
  'model',
  'subModel',
];

const RELATED_POLICY_TYPES = [
  INSURANCE_TYPES_NAMES.CONTENT,
  INSURANCE_TYPES_NAMES.HOME,
  INSURANCE_TYPES_NAMES.LANDLORD,
];

const policyHasValue = (p: IPolicy, fieldKey: string) =>
  !R.includes(fieldKey, FIELD_KEYS_TO_IGNORE) && !R.isNil(p[fieldKey]);

export const findValueFromPolicies = (policies, policyTypeName, fieldKey) => {
  // Find by same policy type name
  let found = policies.find(
    (p) => p.policyType === policyTypeName && policyHasValue(p, fieldKey),
  );

  // Find by name with related policy type but ignore common fields (ie. amount/excess)
  if (!found && R.includes(policyTypeName, RELATED_POLICY_TYPES)) {
    found = policies.find(
      (p) =>
        R.includes(p.policyType, RELATED_POLICY_TYPES) &&
        policyHasValue(p, fieldKey),
    );
  }

  return found;
};

export const getDefaultBrokerFormValues = ({
  formDefinition,
  policyScan,
  policyType,
  policiesList,
  user,
}) => {
  const defaultSubType =
    policyType?.name === INSURANCE_TYPES_NAMES.VEHICLE
      ? 'Comprehensive'
      : 'High';

  const questions = getAllQuestions(formDefinition);

  const sorted = arrayOrderByDateKey('createdAt', policiesList, true);

  const defaultFormValues = questions.reduce(
    (acc, q) => {
      // Skip if there is default value already
      if (acc[q.key]) {
        return acc;
      }

      let value = user[q.key] || q.defaultValue;

      // Try to get default values from other similar policies
      // when selected one doesnt have a value
      if (sorted.length > 0) {
        const found = findValueFromPolicies(sorted, policyType?.name, q.key); // sorted.find((p) => !R.isNil(p[q.key]));
        if (!R.isNil(found)) {
          value = found[q.key];
        }
      }

      if (!R.isNil(value)) {
        // Driving exp question is a dropdown in current version
        if (q.key === 'drivingExp') {
          acc[q.key] = R.min(5, value);
        } else {
          acc[q.key] = typeof value === 'boolean' ? String(value) : value;
        }
      }

      return acc;
    },
    {
      policyType: policyType?.name,
      vehicleFound: !!policyScan?.numberPlate || undefined,
      addressValid: !!policyScan?.address || undefined,
      ...policyScan,
    },
  );

  return R.merge(defaultFormValues, {
    policySubTypeId: defaultSubType,
    policySubTypeName: defaultSubType,
    policyStartDate: getUtcDateNow(),
  });
};
