export enum Features {
  COVERAGE_CHECK = 'coverageCheckEndabled',
  EXPLORE_POLICY = 'explorePolicyEnabled',
  BROKER_PORTAL = 'brokerPortalEnabled',
  Q4E = 'q4eEnabled',
  FETCH_MY_POLICIES = 'fetchMyPoliciesEnabled',
  ADD_POLICY_POPUP = 'addPolicyPopupEnabled',
  LIFE_MS_ENABLED = 'lifeMSEnabled',
  MS_RESULTS_SEE_MORE = 'msResultsSeeMoreEnabled',
  HIDDEN_BROKER_MS = 'hiddenBrokerMS',
  APP_BANNER_ENABLED = 'appBannerEnabled',

  // Growthbook features
  BROKER_FIRST_MS = 'show-broker-ms-button',
  BROKER_FIRST_VERSION = 'broker-form-version',
  DIRECT_MS_LIFE_TEXT = 'direct-ms-life-text',
  SHOW_APP_BANNER = 'show-app-banner',
  BROKER_FIRST_POPUP = 'broker-ms-closed-popup',
  LIFE_MS_VERSION = 'life-market-scan-version',
}
