import {
  getHeaderOptions,
  getHttpServiceAdmin,
  getHttpService,
} from './http-service';
import { IAdminStats, IPolicy } from 'types';
import { IFormatedForm } from 'types';
const httpAdminClient = getHttpServiceAdmin();
const httpClient = getHttpService();

export const getAdminDashboard = () => {
  return httpAdminClient.get<IAdminStats>(`/dashboard`, {
    headers: getHeaderOptions(),
  });
};

export const getRunAdminSchedulerResult = () => {
  return httpAdminClient.get(`/dashboard/schedulerResponse`, {
    headers: getHeaderOptions(),
  });
};

export interface IGetAdminPoliciesFilter {
  processing: boolean;
}

export const getPolicies = (filter: IGetAdminPoliciesFilter) => {
  let url = `/dashboard/policy`;
  if (filter.processing) {
    url += '?processing=true';
  } else {
    url += '?processing=false';
  }

  return httpAdminClient
    .get<IPolicy[]>(url, {
      headers: getHeaderOptions(),
    })
    .then((res) => {
      return res.data;
    });
};

export const postFormData = async (
  formData: IFormatedForm,
  quoteType: string,
  urlExtension: string,
) => {
  try {
    const results = await httpClient.post(
      urlExtension,
      {
        quoteType: quoteType,
        type: formData.productGroup.cover,
        policyProviderName: 'Protecta',
        policyScanId: formData.policyScanId,
        policyId: formData.policyId,
        quoteInfo: formData,
      },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {
    return err;
  }
};

export const getProtectaMakeList = async (insuranceType: string) => {
  try {
    const results = await httpClient.post(
      `/reference/getMakes`,
      { type: insuranceType },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};

export const getProtectaModelList = async (
  insuranceType: string,
  make: string,
) => {
  try {
    const results = await httpClient.post(
      `/reference/getModels`,
      { type: insuranceType, make },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};

export const getProtectaSubmodelList = async (
  insuranceType: string,
  make: string,
  model: string,
) => {
  try {
    const results = await httpClient.post(
      `/reference/getSubmodels`,
      { type: insuranceType, make, model },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};

export const getProtectaGaragingList = async (insuranceType: string) => {
  try {
    const results = await httpClient.post(
      '/reference/getAreaOfGaraging',
      { type: insuranceType },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};

export const getProtectaSundryLookupList = async (
  insuranceType: string,
  category: string,
) => {
  try {
    const results = await httpClient.post(
      '/reference/getSundryLookup',
      { type: insuranceType, category: category },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};

export const getProtectaFinanceCompanyList = async (insuranceType: string) => {
  try {
    const results = await httpClient.post(
      'reference/getFinanceCompanies',
      { type: insuranceType },
      { headers: getHeaderOptions() },
    );
    return results;
  } catch (err) {}
};
