import React from 'react';
import { ChevronLeft } from 'react-feather';
import history from 'helpers/history';
import Button, { IProps as IButtonProps } from './Button';
import styles from './styles/Back.module.scss';

interface IProps extends IButtonProps {
  browserBack?: boolean;
}

const handleBrowserBack = () => history.goBack();

const Back = ({
  browserBack,
  className,
  disabled,
  text = 'Previous',
  onClick,
}: IProps) => {
  return (
    <Button
      className={classnames(styles.button, className)}
      onClick={browserBack ? handleBrowserBack : onClick}
      inverted={true}
      disabled={disabled}
      type="button"
    >
      <ChevronLeft size={16} />
      {text}
    </Button>
  );
};

export default Back;
