import { IGlobalNotificationCreate, INotification } from 'types';
import { getHeaderOptions, getHttpService } from './http-service';

const httpClient = getHttpService();

export const getNotifications = () => {
  return httpClient.get(`/notification/user?all=true`, {
    headers: getHeaderOptions(),
  });
};

export const updateNotification = (payload: INotification) => {
  return httpClient.put('/notification', payload, {
    headers: getHeaderOptions(),
  });
};

export const bulkUpdateNotification = (notifications: INotification[]) => {
  return httpClient.put(
    '/notification/bulk',
    { notifications },
    {
      headers: getHeaderOptions(),
    },
  );
};

export const getGlobalNotification = () => {
  return httpClient.get('/notification/global', {
    headers: getHeaderOptions(),
  });
};

export const createGlobalNotification = (
  payload: IGlobalNotificationCreate,
) => {
  return httpClient.post('/notification/global', payload, {
    headers: getHeaderOptions(),
  });
};

export const updateGlobalNotification = (payload: INotification) => {
  return httpClient.put('/notification/global', payload, {
    headers: getHeaderOptions(),
  });
};
