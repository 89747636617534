import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown, Star } from 'react-feather';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import DOMPurify from 'dompurify';
import { IUser } from 'types';
import styles from './styles/car.scss';

interface IProps {
  data: any;
  user: IUser;
}

const Cover = ({ subTypes, selected }) => {
  if (!subTypes) {
    return null;
  }

  // Need to adjust logic here when changing
  // order of subtypes from contentful
  const selectedSubType = useMemo(() => {
    let optionNum = 0;
    selected.forEach((s) => {
      const subType = subTypes
        .find((st) => st.options.find(R.equals(s)));
      if (subType.order > optionNum) {
        optionNum = subType.order;
      }
    });
    return subTypes[optionNum];
  }, [subTypes, selected]);

  const { name, rating, description } = selectedSubType;

  return (
    <div
      key={name}
      className={styles.cover}
    >
      <div className={styles.bold}>{name}</div>
      <div className={styles.coverRating}>
        <span>Coverage level</span>
        {
          [...Array(5)].map((_, i) => (
            <Star
              key={`${name}-star-${i}`}
              className={classnames(styles.star, (i < rating) && styles.white)}
              size="20"
            />
          ))
        }
      </div>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
    </div>
  );
};

const getOptionsFromData = (subTypes) => {
  const options = subTypes.reduce((acc, st) => {
    const mapped = st.options.map((o) => ({
      key: o,
      value: o,
      text: o,
    }));
    return [...acc, ...mapped];
  }, []);
  return R.sortBy(R.prop('value'), options);
};

const CarCalculator = ({ data, user }: IProps) => {
  const [selected, setSelected] = useState([]);
  const options = useMemo(() => getOptionsFromData(data), [data]);

  const handleCheckboxChange = useCallback(
    (_, d) => {
      if (d.checked) {
        setSelected(R.append(d.name));
      } else {
        setSelected(R.reject(R.equals(d.name)));
      }
    },
    [],
  );

  const handleSelectChange = useCallback((_, d) => setSelected(d.value), []);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.bold}>
          {`The choice is yours ${user.firstName}, explore the right policy type for you`}
        </div>
        <div className={styles.options}>
          {options.map(({ key, value }) => (
            <Checkbox
              key={key}
              className={styles.checkbox}
              name={value}
              label={value}
              checked={R.includes(value, selected)}
              onChange={handleCheckboxChange}
            />
          ))}
        </div>
        <div className={styles.select}>
          <Dropdown
            placeholder="Select options"
            multiple={true}
            selection={true}
            options={options}
            onChange={handleSelectChange}
            icon={<ChevronDown className={styles.arrow} />}
          />
        </div>
      </div>
      <div className={styles.right}>
        {
          R.isEmpty(selected) ? (
            <div className={styles.bold}>
              Select what you want to protect and let’s find out what policy type is the best for you!
            </div>
          ) : (
            <Cover
              subTypes={data}
              selected={selected}
            />
          )
        }
      </div>
    </div>
  );
};

export default CarCalculator;
