// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3qzDlh49hyKlQ9lD_DyUQk{position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);border-radius:5px}.f99SbPKaw-rdryYasvbAd{position:absolute;background:#fff;width:60%;height:auto;top:50%;left:50%;transform:translate(-50%, -50%);padding:24px;border-radius:5px}.f99SbPKaw-rdryYasvbAd p{font-size:14px;text-align:center}._2VhJOOEnGLP0m0gzAoMS_6{display:flex;justify-content:center}.YE_sVV-0XAifP0--03mTk{display:block}._3aVt-FMg4maKgwOdn5uLPG{display:none}._3IhAMH084IC_-QxlN3gCDd.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;width:100px;background-color:#4187f5;font-size:14px;margin-right:8px}@media(max-width: 576px){._3IhAMH084IC_-QxlN3gCDd.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._1mRCWOd6P7bq0uW2LI0gb-.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;width:100px;background-color:#e47c6e;font-size:14px;margin-left:8px}@media(max-width: 576px){._1mRCWOd6P7bq0uW2LI0gb-.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 820px){.f99SbPKaw-rdryYasvbAd{width:80%}.f99SbPKaw-rdryYasvbAd p{font-size:12px}}", ""]);
// Exports
exports.locals = {
	"modal": "_3qzDlh49hyKlQ9lD_DyUQk",
	"modalMain": "f99SbPKaw-rdryYasvbAd",
	"buttons": "_2VhJOOEnGLP0m0gzAoMS_6",
	"modalShow": "YE_sVV-0XAifP0--03mTk",
	"modalHide": "_3aVt-FMg4maKgwOdn5uLPG",
	"blueButton": "_3IhAMH084IC_-QxlN3gCDd",
	"redButton": "_1mRCWOd6P7bq0uW2LI0gb-"
};
module.exports = exports;
