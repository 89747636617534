export type FormStatus = 'DRAFT' | 'ACTIVE';

export enum FieldType {
  Address = 'address',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkbox-group',
  Date = 'date',
  Number = 'number',
  Radio = 'radio',
  Rating = 'rating',
  Rego = 'rego',
  Search = 'search',
  Select = 'select',
  TextArea = 'textarea',
  Text = 'text',
  Birthday = 'birthday',
  MultiRow = 'multi-row',
  MultiForm = 'multi-form',
  List = 'list',
}

export interface IFormDisplaySchema {
  when: string;
  is?: any;
  not?: any;
  valid?: boolean;
}

export interface IFormQuestion {
  key: string;
  type: FieldType;
  display?: IFormDisplaySchema[];
  label?: string;
  required?: boolean;
  options?: Array<{
    key: string | number;
    text: string | number;
    value: string | number;
  }>;
  // Input props
  [key: string]: any;
}

export interface IFormDefinitionPageSection {
  collapsible?: boolean;
  title?: string;
  questions: IFormQuestion[];
}

export interface IFormDefinitionPage {
  questions: IFormQuestion[];
  sections: IFormDefinitionPageSection[];
  disclaimer?: string;
  headerTitle?: string;
  headerSubtitle?: string;
  hideSubmit?: boolean;
  imageUrl?: string;
  layout?: string;
  orientation?: string;
  progress?: number;
  questionsClassName?: string;
  rightSection?: {
    questions: IFormQuestion[];
  };
  submitText?: string;
  text?: string[];
  title?: string;
  type?: string;
}

export interface IFormDefinition extends IFormDefinitionPage {
  id: string;
  sk: string;
  formStatus: FormStatus;
  theme: string;
  updatedAt: string;
  hideBack?: boolean;
  pages?: IFormDefinitionPage[];
}

export interface IFormSubmitPayload {
  id: string;
  sk: string;
  questions: Array<{ key: string; value: any }>;
  metadata?: any;
}
