import React from 'react';
import { Check } from 'react-feather';
import { ActiveStatus, ErrorStatus, WarningStatus } from 'components/status';
import { IPolicyScan, POLICY_SCAN_STATUS } from 'types';
import styles from './marketScanList.scss';
import moment from 'moment';
import { ATTENTION_REQUIRED_DAYS } from 'helpers';

interface IProps {
  policyScan: IPolicyScan;
  inCart?: boolean;
  toolTip?: string;
}

const InReviewTooltip = (
  <>
    Oops.. you've selected a policy that is not our recommended option.
    <br />
    <br />
    Multi-policy discount is only available where more than one policy is
    purchased from an insurer.
    <br />
    <br />
    What you can do:
    <br />
    1. Change your option to the recommended insurer.
    <br />
    2. Wait for our adviser to be in touch to update your quote with the options
    you have currently selected.
  </>
);

const MarketScanStatus = ({ policyScan, inCart, toolTip }: IProps) => {
  const {
    createdAt,
    brokerPolicyProviderName,
    status,
    isBrokerFirst,
    selectedProvider,
  } = policyScan;

  if (inCart) {
    return (
      <div className={styles.cart}>
        <Check size={16} />
        Added to cart
      </div>
    );
  }

  if (status === POLICY_SCAN_STATUS.READY) {
    return (
      <ActiveStatus
        textClassName={styles.status}
        toolTipClassName={styles.toolTip}
        text="Results ready"
        tooltip={toolTip}
      />
    );
  }

  if (status === POLICY_SCAN_STATUS.VIEWED) {
    if (isBrokerFirst && selectedProvider && !selectedProvider?.recommended) {
      return (
        <WarningStatus
          textClassName={styles.status}
          toolTipClassName={styles.toolTip}
          text="In review with Adviser"
          tooltip={InReviewTooltip}
        />
      );
    }

    const now = moment();
    const createdAtMom = moment(createdAt);
    const warningDays = ATTENTION_REQUIRED_DAYS - 7; // 1 week before expiring

    if (now.diff(createdAtMom, 'days') >= warningDays) {
      return <ErrorStatus textClassName={styles.status} text="Expiring soon" />;
    }

    const text = brokerPolicyProviderName ? 'Selected' : 'Select cover';
    return <ActiveStatus textClassName={styles.status} text={text} />;
  }

  return <WarningStatus textClassName={styles.status} text="Results pending" />;
};

export default MarketScanStatus;
