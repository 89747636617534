// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3Eba-lrUAX6bHQ0jQ-SzJ3{font-family:\"Open Sans\";font-weight:700;font-size:24px;line-height:1.2}.VqURO1WwBF02cSGAgCBhn{font-family:\"Open Sans\";font-weight:700;font-size:28px;line-height:32px}._25lJ40FF3_nbp2UPZYdcGl{font-family:\"Open Sans\";font-weight:400;font-size:24px;line-height:26px}.kimn3jMRb7nY5StCgzntk{font-family:\"Open Sans\";font-weight:400;font-size:14px;line-height:1.4}@media(max-width: 576px){._3Eba-lrUAX6bHQ0jQ-SzJ3{font-size:20px}.VqURO1WwBF02cSGAgCBhn{font-size:26px;line-height:28px}._25lJ40FF3_nbp2UPZYdcGl{font-size:22px;line-height:24px}.kimn3jMRb7nY5StCgzntk{font-size:14px}}", ""]);
// Exports
exports.locals = {
	"h1": "_3Eba-lrUAX6bHQ0jQ-SzJ3",
	"h2": "VqURO1WwBF02cSGAgCBhn",
	"h3": "_25lJ40FF3_nbp2UPZYdcGl",
	"h4": "kimn3jMRb7nY5StCgzntk"
};
module.exports = exports;
