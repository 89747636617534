import React from 'react';
import classnames from 'classnames';
import {
  Dropdown,
  DropdownProps,
  DropdownItemProps,
  Icon,
} from 'semantic-ui-react';
import { HelpTooltip } from 'components/tooltip';
import styles from './formField.scss';
import DropdownInput from 'components/dropdown-input';

export interface IDropdownFieldProps {
  options?: DropdownItemProps[];
  name: string;
  value: string;
  onChange?: (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => void;
  required?: boolean;
  className?: string;
  placeholder?: string;
  label?: string;
  dropdownClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  errorIcon?: boolean;
  search?: boolean;
  minCharacters?: number;
  icon?: string;
  tooltip?: string;
  multiple?: boolean;
  inputType?: string;
  prefix?: string;
  noResultsMessage?: string;
  creatable?: boolean;
}

const DropdownField = ({
  required,
  value,
  onChange,
  className,
  options,
  label,
  dropdownClassName,
  labelClassName,
  disabled,
  loading,
  error,
  errorIcon,
  placeholder = '',
  name,
  search,
  minCharacters = 0,
  icon,
  tooltip,
  multiple,
  inputType,
  prefix,
  noResultsMessage,
  creatable,
}: IDropdownFieldProps) => {
  const Component = inputType ? DropdownInput : Dropdown;
  return (
    <div
      className={classnames(styles.container, error && styles.error, className)}
    >
      <span
        className={classnames(
          styles.label,
          labelClassName,
          tooltip && styles.withTooltip,
        )}
      >
        <span>
          {required && <span className={styles.required}>{'* '}</span>}
          {label}
        </span>
        {tooltip && (
          <HelpTooltip
            toggleClassName={styles.toggle}
            content={tooltip}
            position="top center"
          />
        )}
      </span>
      <div
        className={classnames(styles.inputWrap, disabled && styles.disabled)}
      >
        {error && errorIcon && (
          <Icon className={styles.errorIcon} name="exclamation circle" />
        )}
        <Component
          className={classnames(
            styles.dropdown,
            error && errorIcon && styles.errorIcon,
            dropdownClassName,
          )}
          placeholder={placeholder}
          required={required}
          value={value}
          onChange={onChange}
          options={options}
          selection={true}
          loading={loading}
          disabled={disabled}
          name={name}
          search={search}
          minCharacters={minCharacters}
          icon={icon}
          multiple={multiple}
          prefix={prefix}
          inputType={inputType}
          noResultsMessage={noResultsMessage}
          creatable={creatable}
        />
      </div>
    </div>
  );
};

export default DropdownField;
