import { trackNotesFeedback, trackVoteFeedback } from 'helpers';
import { getHeaderOptions, getHttpService } from './http-service';
import { IFeedback } from 'types';

export enum FeedbackTypes {
  VOTE = 'vote',
  NOTES = 'notes'
}

const httpClient = getHttpService();

export const createVoteFeedback = (target: string, vote: boolean) => {
  const data = {
    feedbackType: FeedbackTypes.VOTE,
    target,
    vote
  };
  trackVoteFeedback(data);
  return httpClient.post(`/feedback`, data, {
    headers: getHeaderOptions()
  });
};

export const createNoteFeedback = (target: string, notes: string) => {
  const data = {
    feedbackType: FeedbackTypes.NOTES,
    target,
    notes
  };
  trackNotesFeedback(data);
  return httpClient.post(`/feedback`, data, {
    headers: getHeaderOptions()
  });
};

/**
 * Service API call to the getAllFeedbacks endpoint
 * @param type    - feedbackType ['vote' | 'notes'] or empty for all types
 * @param loadAll - 'true' if the API should return all feedback.
 *                  Anything else and only the past 30days will be returned
 */
export const getFeedbacks = (type: string, loadAll: boolean) => {
  return httpClient.get<IFeedback[]>(`/feedback`, {
    headers: getHeaderOptions(),
    params: { type: type, loadAll: loadAll }
  });
};
