// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".aVjVB24k_runzm6tn67GZ{height:auto}.aVjVB24k_runzm6tn67GZ.FDhABE_rqdsC3H14Fngeo{width:100px}.aVjVB24k_runzm6tn67GZ.-JOX9J8HqV8odAqbWnVLh{width:100%}.aVjVB24k_runzm6tn67GZ._2u0z8u8if_dKo_sfcXZL3q{width:35px}", ""]);
// Exports
exports.locals = {
	"logo": "aVjVB24k_runzm6tn67GZ",
	"large": "FDhABE_rqdsC3H14Fngeo",
	"sub": "-JOX9J8HqV8odAqbWnVLh",
	"small": "_2u0z8u8if_dKo_sfcXZL3q"
};
module.exports = exports;
