import React, {
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import DOMPurify from 'dompurify';
import styles from './FormEngine.module.scss';
import Back from 'components/back';
import Button from 'components/button';
import { ProgressBar } from 'components/progress';
import Title from 'components/title';
import { ErrorToast } from 'components/toasts';
import { TRY_AGAIN_ERROR } from 'constants/errors';
import { getSubmitPayload } from 'helpers/formEngine';
import usePager from 'hooks/usePager';
import { submitForm } from 'services';
import {
  IFormDefinition,
  IFormDefinitionPage,
  IFormQuestion,
  IFormSubmitPayload,
} from 'types';
import { getPageComponent, parseDefaultValues } from './utils';
import QuashedFormTheme from './themes/QuashedForm.scss';
import history from '../../helpers/history';
import { HOME_ROUTE } from 'constants/routes';
import { windowScrollTop } from 'helpers';

const themes = {
  'quashed-form': QuashedFormTheme,
};

export interface IFormEngineProps {
  formDefinition: IFormDefinition;
  defaultPage?: number;
  defaultValues?: any;
  defaultValuesParser?: any;
  className?: string;
  formRef?: Ref<any>;
  contentClassName?: string;
  headerClassName?: string;
  questionsClassName?: string;
  fieldClassName?: string;
  fieldContainerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  buttonClassName?: string;
  titleClassName?: string;
  submitErrorMsg?: string;
  loader?: React.ReactNode;
  metadata?: any;
  options?: any;
  questionProps?: any;
  skipSubmit?: boolean;
  submitOnNext?: boolean;
  titleImage?: React.ReactNode;
  validateOnMount?: boolean;
  getDefaultValues?(formDefinition: IFormDefinition): void;
  getOptions?(question: IFormQuestion, formValues: any): any;
  onButtonClick?(question: any, formValues: any, form: UseFormReturn): void;
  onBeforeSubmit?(
    data: any,
    currentPage: IFormDefinitionPage,
    isLastPage?: boolean,
  ): Promise<any>;
  onAfterSubmit?(data: any, isLastPage?: boolean): void;
  onAddressSelect?(form: UseFormReturn, formValues: any): void;
  onBack?(currentPage: number): void;
  onChange?(e: any): void;
  onSubmit?(data: any, payload: IFormSubmitPayload): void;
  onNext?(
    page: IFormDefinitionPage,
    data: any,
    form: UseFormReturn,
    active: number,
    next: () => void,
  ): void;
  renderLabel?(question: IFormQuestion, values: any): React.ReactNode;
  renderPage?(
    component: React.ReactNode,
    formDef: IFormDefinition,
    pageNum: number,
  ): React.ReactNode;
}

const FormEngine = ({
  formRef,
  formDefinition,
  defaultValues: propsDefaultValues,
  defaultValuesParser = R.identity,
  className,
  buttonClassName,
  defaultPage = 0,
  headerClassName,
  submitErrorMsg = TRY_AGAIN_ERROR,
  metadata,
  skipSubmit,
  submitOnNext,
  validateOnMount,
  getDefaultValues,
  onBeforeSubmit = R.T,
  onAfterSubmit = R.T,
  onBack,
  onSubmit = R.T,
  onNext,
  renderPage = R.identity,
  ...props
}: IFormEngineProps) => {
  const [loading, setLoading] = useState(false);
  const { active, next, prev, setActive } = usePager(defaultPage);

  useEffect(() => {
    // Scroll to top every page change
    windowScrollTop();
  }, [active]);

  const defaultValues = useMemo(() => {
    const dv = parseDefaultValues(
      getDefaultValues ? getDefaultValues(formDefinition) : propsDefaultValues,
    );
    return defaultValuesParser(dv);
  }, [propsDefaultValues]);

  const form = useForm({ defaultValues, mode: 'all' });
  const { formState, handleSubmit, watch, reset, trigger } = form;
  const formValues = watch();

  // Allows parent component to have access to form data
  useImperativeHandle(formRef, () => form);

  useEffect(() => {
    if (validateOnMount) {
      trigger();
    }
  }, []);

  useEffect(() => {
    // Update form state when defaultValues change
    if (defaultValues) {
      reset({ ...defaultValues, ...R.reject(R.isNil, formValues) });
    }
  }, [defaultValues]);

  useEffect(() => {
    // Reset isSubmitted state so typing does not
    // throw error for empty fields on the next page
    if (formState.isSubmitSuccessful) {
      reset(null, { keepValues: true });
    }
  }, [formState.isSubmitSuccessful]);

  const {
    disclaimer,
    headerTitle,
    headerSubtitle,
    hideBack,
    orientation,
    pages = [],
    submitText,
    theme,
    progress,
  } = formDefinition;

  const formTheme = themes[theme];

  const currentPage = pages[active];
  const pageDisclaimer = currentPage?.disclaimer ?? disclaimer;
  const pageHeaderTitle = currentPage?.headerTitle ?? headerTitle;
  const pageHeaderSubtitle = currentPage?.headerSubtitle ?? headerSubtitle;
  const pageOrientation = currentPage?.orientation ?? orientation;
  const pageSubmitText = currentPage?.submitText ?? submitText;
  const pageProgress = currentPage?.progress ?? progress;
  const pageShowSubmit =
    !currentPage?.hideSubmit && currentPage?.layout !== 'column';

  const PageComponent = getPageComponent(currentPage?.type);

  const goNext = (data: any) => {
    const nextFn = onNext || next;
    return nextFn(currentPage, data, form, active, next);
  };

  const submit = async (data: any) => {
    setLoading(true);
    try {
      if (!submitOnNext && pages && active < pages.length - 1) {
        await goNext(data);
      } else {
        const shouldNext = submitOnNext && active < pages.length - 1;
        const beforeSubmitResult = await onBeforeSubmit(
          data,
          R.assoc('index', active, currentPage),
          !shouldNext,
        );
        const payload = getSubmitPayload(formDefinition, data, {
          ...metadata,
          ...beforeSubmitResult,
        });
        onSubmit(data, payload);

        if (!skipSubmit) {
          const res = await submitForm(payload);
          onAfterSubmit(res.data, !shouldNext);
        }

        if (shouldNext) {
          await goNext(data);
        }
      }
    } catch (ex) {
      console.error(ex);
      ErrorToast.error({
        message: submitErrorMsg,
        closeText: 'Okay',
        onClose: () => history.push(HOME_ROUTE),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (onBack) {
      onBack(active);
    }

    if (active > 0) {
      prev();
    }
  };
  return (
    <form
      className={classnames(
        styles.container,
        formDefinition.pages && styles.paged,
        pageOrientation === 'vertical' && styles.vertical,
        formTheme?.container,
        className,
      )}
      onSubmit={handleSubmit(submit)}
    >
      <div className={classnames(styles.header, headerClassName)}>
        {pageHeaderTitle && (
          <Title
            titleClassName={classnames(
              styles.headerTitle,
              formTheme?.headerTitle,
            )}
            subtitleClassName={formTheme?.headerSubtitle}
            title={
              pageProgress ? (
                <>
                  <span>{pageHeaderTitle}</span>
                  <ProgressBar
                    className={styles.progressBar}
                    percent={pageProgress}
                  />
                  <div />
                </>
              ) : (
                pageHeaderTitle
              )
            }
            subtitle={pageHeaderSubtitle}
          />
        )}
      </div>
      {renderPage(
        <PageComponent
          {...props}
          active={active}
          setActive={setActive}
          form={form}
          onBack={handleBack}
          loading={loading}
          page={currentPage}
          formDefinition={formDefinition}
          formValues={formValues}
          contentClassName={classnames(
            props.contentClassName,
            formTheme?.content,
          )}
          questionsClassName={classnames(
            props.questionsClassName,
            formTheme?.questions,
          )}
          titleClassName={classnames(props.titleClassName, formTheme?.title)}
          fieldContainerClassName={classnames(
            props.fieldContainerClassName,
            formTheme?.fieldContainer,
          )}
          fieldClassName={classnames(props.fieldClassName, formTheme?.field)}
          inputClassName={classnames(props.inputClassName, formTheme?.input)}
          labelClassName={classnames(props.labelClassName, formTheme?.label)}
        />,
        formDefinition,
        active,
      )}
      <div className={styles.buttonContainer}>
        {pageDisclaimer && (
          <div className={styles.disclaimer}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(pageDisclaimer, {
                  ADD_ATTR: ['target', 'rel'],
                }),
              }}
            />
          </div>
        )}
        {!hideBack && pageShowSubmit && (onBack || active > 0) && (
          <Back className={styles.back} handleBack={handleBack} />
        )}
        {pageShowSubmit && (
          <Button
            className={classnames(styles.submit, buttonClassName)}
            type="submit"
            disabled={loading}
            loading={loading}
            text={
              pageSubmitText || (active < pages.length - 1 ? 'Next' : 'Submit')
            }
          />
        )}
      </div>
    </form>
  );
};

export default FormEngine;
