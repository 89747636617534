import moment from 'moment';
import { Checkbox, Dropdown, Icon, Input } from 'semantic-ui-react';
import DatePicker from 'components/date-picker';
import AddressSearch from 'components/address-search';
import NumberFormat from 'react-number-format';
import styles from './typeForm.scss';
import { IUseForm } from 'hooks/useForm';
import React from 'react';
import BirthdayPicker from 'components/bday-picker';
import DropdownInput from 'components/dropdown-input';
import {
  getChangeHandler,
  getFullAddressFromAddressComponents,
  isRiskZoneProperty,
  TRACKING_EVENTS,
} from 'helpers';
import { useSelector } from 'react-redux';
import { WarningMessage } from 'components/form-message';

interface ITypeFormFieldProps {
  form: IUseForm;
  question: any;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

const getDisabledValue = (value, { type, options }) => {
  if (type === 'date' || type === 'birthday') {
    return value && moment(value).format('DD/MM/YYYY');
  }

  if (type === 'dropdown') {
    const option = options.find(R.propEq('value', value));
    return R.propOr(value, 'text', option);
  }

  return value;
};

const ErrorIconWrap = ({ className = '', children, error, errorText }) => {
  return (
    <div className={classnames(styles.inputContainer, className)}>
      {error && <Icon className={styles.errorIcon} name="exclamation circle" />}
      {children}
      {error && errorText && (
        <div className={styles.fieldErrorText}>{errorText}</div>
      )}
    </div>
  );
};

const AddressField = ({ className, error, form, question, value }) => {
  const config = useSelector(R.prop('config'));
  const {
    key: qKey,
    fieldKey,
    placeholder = '',
    errorText,
    showButton,
    autoFocus,
  } = question;

  const handleAddressChange = (e) => {
    form.setValues(
      R.mergeLeft({ isAddressValid: false, [e.target.name]: e.target.value }),
    );
  };

  const handleAddressSelect = (e) => {
    const fullAddress = getFullAddressFromAddressComponents(
      e.target.addressComponents,
    );

    form.setValues(
      R.mergeLeft({
        isAddressValid: !!fullAddress?.streetNo,
        riskZoneProperty: isRiskZoneProperty(fullAddress, config),
        [e.target.name]: e.target.value,
        lng: e.target.lng,
        lat: e.target.lat,
        ...fullAddress,
      }),
    );
  };

  const toggleShowFullAddress = () => {
    form.setValues(R.assoc('showFullAddress', !form.values.showFullAddress));
  };

  const key = qKey || fieldKey;

  return (
    <ErrorIconWrap
      className={styles.addressContainer}
      error={false}
      errorText={errorText}
    >
      <div>
        <AddressSearch
          name={key}
          className={classnames(styles.address, className)}
          onChange={handleAddressChange}
          onSelect={handleAddressSelect}
          value={value || ''}
          placeholder={placeholder}
          autocomplete="off"
          autoFocus={!!value && autoFocus}
        />
        {showButton && (
          <div className={styles.addressBtns}>
            {question.showWarning && form.values.riskZoneProperty ? (
              <WarningMessage
                eventName={TRACKING_EVENTS.MarketScanRiskProperty}
                eventData={form.values}
                text="It appears your property may be in a higher risk area. Some insurance providers may not be able to generate a quick quote for you, so your Market Scan results may have fewer options than usual."
                className={classnames(
                  styles.warningIcon,
                  form.values.riskZoneProperty && styles.show,
                )}
              />
            ) : (
              <span
                className={classnames(
                  styles.errorIcon,
                  error && !form.values.isAddressValid && styles.show,
                )}
              >
                <Icon name="exclamation circle" />
                {
                  // Show different message when streetNo is missing
                  !!form.values.streetName && !form.values.streetNo
                    ? 'Enter street number or'
                    : 'Select a match from the address list or'
                }
              </span>
            )}
            <span
              className={styles.addressToggle}
              onClick={toggleShowFullAddress}
            >
              {form.values.showFullAddress
                ? 'Cancel'
                : 'Enter address manually'}
            </span>
          </div>
        )}
      </div>
    </ErrorIconWrap>
  );
};

const TypeFormField = ({
  form,
  question,
  disabled,
  error,
  className: propClassName,
}: ITypeFormFieldProps) => {
  const {
    key: qKey,
    fieldKey,
    valueKey,
    type,
    max,
    options,
    placeholder = '',
    prefix,
    maxLength = 256,
    minCharacters = 3,
    inputType,
    noResultsMessage,
    getErrorText,
    errorText: errorTextProp,
    allowLeadingZeros,
    castAs,
    thousandSeparator = ',',
  } = question;
  // fieldKey prop is used by old question fields
  const key = qKey || fieldKey;
  const value = form.values[valueKey || key];
  const className = classnames(propClassName, error && styles.error);
  const handleChange = getChangeHandler(form, question);
  const errorText =
    error && getErrorText ? getErrorText(value, form.values) : errorTextProp;

  if (disabled) {
    return (
      <div className={styles.disabled}>{getDisabledValue(value, question)}</div>
    );
  }

  switch (type) {
    case 'address':
      return (
        <AddressField
          className={className}
          error={error}
          form={form}
          question={question}
          value={value}
        />
      );
    case 'date':
      return (
        <ErrorIconWrap
          className={styles.dateContainer}
          error={error}
          errorText={errorText}
        >
          <DatePicker
            className={classnames(styles.date, className)}
            wrapClassName={styles.dateWrap}
            onChange={handleChange}
            name={key}
            value={value}
            max={max}
          />
        </ErrorIconWrap>
      );
    case 'dropdown':
      const DropdownComponent = inputType ? DropdownInput : Dropdown;
      return (
        <ErrorIconWrap error={error} errorText={errorText}>
          <DropdownComponent
            className={classnames(styles.dropdown, className)}
            inline={true}
            options={options}
            value={value}
            onChange={handleChange}
            icon="chevron down"
            name={key}
            placeholder=""
            prefix={prefix}
            inputType={inputType}
            noResultsMessage={noResultsMessage}
          />
        </ErrorIconWrap>
      );
    case 'search':
      return (
        <ErrorIconWrap error={error} errorText={errorText}>
          <Dropdown
            name={key}
            className={classnames(styles.dropdown, className)}
            search={true}
            inline={true}
            options={options}
            value={value}
            onChange={handleChange}
            icon="chevron down"
            placeholder={placeholder}
            minCharacters={minCharacters}
          />
        </ErrorIconWrap>
      );
    case 'number':
      const numberOnChange = ({ floatValue, value: stringValue }) => {
        const val = castAs === 'string' ? stringValue : floatValue;
        handleChange({
          target: {
            name: key,
            value: R.isNil(val) ? '' : val,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      };

      return (
        <ErrorIconWrap
          error={error}
          errorText={errorText}
          className={classnames(styles.number, className)}
        >
          {/* @ts-ignore */}
          <NumberFormat
            onValueChange={numberOnChange}
            disabled={disabled}
            thousandSeparator={thousandSeparator}
            value={value}
            decimalScale={0}
            fixedDecimalScale={true}
            allowNegative={false}
            allowLeadingZeros={allowLeadingZeros}
            maxLength={maxLength}
            placeholder={placeholder}
            prefix={prefix}
          />
        </ErrorIconWrap>
      );
    case 'birthday':
      return (
        <ErrorIconWrap
          error={error}
          errorText={errorText}
          className={classnames(error && styles.bdayError)}
        >
          <BirthdayPicker
            className={classnames(styles.bday, className)}
            inputClassName={styles.bdayInput}
            onChange={handleChange}
            name={key}
            value={value}
            max={max}
          />
        </ErrorIconWrap>
      );
    case 'checkbox':
      return (
        <Checkbox
          name={key}
          className={classnames(styles.checkbox, className)}
          onChange={form.handleCheckboxChange}
          checked={value || false}
        />
      );
    default:
      const errorProps = error
        ? {
            icon: 'exclamation circle',
            iconPosition: 'left',
          }
        : {};
      return (
        <Input
          className={classnames(styles.input, className)}
          name={key}
          onChange={handleChange}
          value={value || ''}
          placeholder={placeholder}
          maxLength={maxLength}
          {...errorProps}
        />
      );
  }
};

export default React.memo(TypeFormField);
