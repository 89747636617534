import { getHeaderOptions, getHttpService } from "./http-service";

const httpClient = getHttpService();

export const getCreditCardBenefits = () => {
  return httpClient.get("/policyprovider/creditcard/creditCardBenefitsData", {
    headers: getHeaderOptions(),
  });
};

export const getAllInsurersByPolicyType = (policyTypeId) => {
  return httpClient.get(`/policyprovider/policytypeid/${policyTypeId}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicyProviders = () => {
  return httpClient.get(`/policyprovider`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicyProviderById = (id: string) => {
  return httpClient.get(`/policyprovider/${id}`, {
    headers: getHeaderOptions(),
  });
};
