import { Link } from "react-router-dom";
import { Grid, Header, Modal } from 'semantic-ui-react';
import styles from './policy-types-modal.scss';
import Back from 'components/back';
import { INSURANCE_TYPES_ICONS } from 'constants/insurance';

const PolicyTypesModal = ({
  open,
  onClose,
  policyTypes = [],
  selected,
  backLink,
  onClick,
}) => {
  return (
    <Modal
      open={open}
      className={styles.modal}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon="times"
      size="large"
    >
      <Header content='Insurance types' />
      <Modal.Content className={styles.modalContent}>
        <Grid doubling={true} className={styles.grid} columns={4} padded={true}>
          {policyTypes.map((policy) => {
            const { name, id } = policy;
            return (
              <Grid.Column
                key={id}
                as={Link}
                className={classnames(styles.item, selected === name && styles.selected)}
                onClick={onClick}
                to={`/explore/policy/${name}`}
              >
                <img className={styles.itemImg} src={INSURANCE_TYPES_ICONS[name] || INSURANCE_TYPES_ICONS.default} />
                <div className={styles.itemName}>{name}</div>
              </Grid.Column>
            );
          })}
        </Grid>
        <Back black={true} link={backLink} />
      </Modal.Content>
    </Modal>
  );
};

export default PolicyTypesModal;
