import FormEnginePage from './FormEnginePage';
import MarketScanSummary from './MarketScanSummary';

export enum FormEnginePages {
  MarketScanSummary = 'market-scan-summary',
}

export const FormEnginePageMap = {
  default: FormEnginePage,
  [FormEnginePages.MarketScanSummary]: MarketScanSummary,
};
