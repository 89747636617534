import React, { useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Modal } from 'semantic-ui-react';
import Button from 'components/button';
import { TextArea } from 'components/form-fields-v2';
import { createBrokerMessage } from 'services';
import QDancing from '-!svg-react-loader!images/q/q-dancing.svg';
import styles from './styles/AskAdviserModal.module.scss';

const AskAdviserModal = ({ open, policyScanId, policyId, onClose }) => {
  const [{ submitted, loading }, setState] = useState({
    submitted: false,
    loading: false,
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: { message: '' },
  });

  const value = watch('message');

  const handleClose = () => {
    onClose();
    reset({ message: '' });
    setState({
      submitted: false,
      loading: false,
    });
  };

  const submit = async ({ message }) => {
    setState(R.assoc('loading', true));
    await createBrokerMessage({ policyScanId, policyId, message });
    setState(R.assoc('submitted', true));
    setTimeout(handleClose, 5000);
  };

  return (
    <Modal
      className={styles.modal}
      size="small"
      open={open}
      closeIcon={false}
      onClose={handleClose}
    >
      <Modal.Content>
        {submitted ? (
          <div className={styles.submitted}>
            <QDancing />
            <h3>Great, all sent! Expect a response in your emails soon.</h3>
          </div>
        ) : (
          <>
            <X onClick={handleClose} className={styles.close} size={24} />
            <div className={styles.title}>
              <b>Ask Broker a quick question!</b> We’ll email you back with a
              response
            </div>
            <form className={styles.form} onSubmit={handleSubmit(submit)}>
              <TextArea
                {...register('message', {
                  required: true,
                  maxLength: 360,
                  minLength: 1,
                })}
                placeholder="What would you like to ask? Max 360 characters"
                error={!!errors.message}
                maxLength={360}
                hideCount={true}
              />
              <div className={styles.askSubmitBtns}>
                <div
                  className={styles.count}
                >{`${value.length}/360 Characters left`}</div>
                <Button
                  className={styles.askSubmit}
                  disabled={loading}
                  text="Send"
                  type="submit"
                />
              </div>
            </form>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AskAdviserModal;
