import { Form } from 'semantic-ui-react';
import Button from 'components/button';
import FormField from 'components/form-field';
import { CONSENT_FIELDS } from 'constants/user-form';
import styles from './styles/newClients.scss';
import { IUseForm } from 'hooks/useForm';
import history from 'helpers/history';
import { CONSENTS_BULK_ROUTE } from 'constants/routes';

interface IProps {
  form: IUseForm;
  errors: object;
  errorLabel: string;
  submitting: boolean;
}

const NewClientsForm = ({ form, errors, submitting, errorLabel }: IProps) => {
  const { values, handleInputChange, handleSubmit } = form;

  const gotoBulk = () => history.push(CONSENTS_BULK_ROUTE);

  const handleAddClient = () => handleSubmit();

  return (
    <Form className={styles.form}>
      <div className={styles.fields}>
        {CONSENT_FIELDS.map((field) => {
          return (
            <FormField
              key={field.key}
              name={field.key}
              className={classnames(
                styles.field,
                field.inputType === 'checkbox' && styles.checkboxField,
                field.type === 'birthday' && styles.bday,
                field.required && styles.required,
              )}
              labelClassName={styles.label}
              inputClassName={styles.input}
              disabledClassName={styles.disabled}
              onChange={handleInputChange}
              value={values[field.key]}
              error={R.prop(field.key, errors)}
              disabled={submitting}
              {...field}
            />
          );
        })}
      </div>
      <div className={styles.add}>
        <Button
          className={styles.bulkBtn}
          text="Bulk invite"
          onClick={gotoBulk}
          disabled={submitting}
          type="button"
        />
        <div className={styles.sendContainer}>
          {errorLabel && <div className={styles.error}>{errorLabel}</div>}
          <Button
            type="submit"
            className={styles.blueBtn}
            text="Send"
            onClick={handleAddClient}
            loading={submitting}
            disabled={submitting}
          />
        </div>
      </div>
    </Form>
  );
};

export default NewClientsForm;
