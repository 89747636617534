import Button from 'components/button';
import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styles from './on-holiday.scss';
import QSwaggerSanta from '-!svg-react-loader!images/q/q-swagger-santa.svg';
import QSanta from '-!svg-react-loader!images/q/q-santa.svg';

interface IOnHolidayProps {
  show: boolean;
  onClose(): void;
}

const OnHoliday = ({ show, onClose }: IOnHolidayProps) => {
  const [rand, setRand] = useState(0);

  useEffect(() => {
    setRand(Math.floor(Math.random() * 2));
  }, []);

  return (
    <Modal
      open={show}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      className={styles.modal}
    >
      <Modal.Content>
        <div className={styles.image}>
          {rand === 0 ? (
            <QSanta className={styles.santa} />
          ) : (
            <QSwaggerSanta className={styles.swagSanta} />
          )}
        </div>
        <div className={styles.heading}>
          It's the holiday season! That means most of the Quashed team are also
          away.
        </div>
        <div className={styles.body}>
          Just a note that some activities on the platform might take a little
          longer than usual to complete.
        </div>
        <div className={styles.body}>
          But don't let that stop you! We'll be back in action on the 5th of
          January 2022.
        </div>
        <div className={styles.close}>
          <Button className={styles.button} text="Got It" onClick={onClose} />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default OnHoliday;
