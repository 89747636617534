import { INSURANCE_TYPES_NAMES } from "constants/insurance";
import { PolicyStatus } from "constants/policy";

export const setDefaultLifeCovers = (policy, lifePolicy) => {
  const insured = policy.insured.map((ins) => {
    const { additionalCovers = [] } = ins;
    if (additionalCovers.length === 0 && lifePolicy) {
      return {
        ...ins,
        additionalCovers: [{ policyTypeId: lifePolicy.id, policyType: lifePolicy.name }]
      };
    }
    return ins;
  });
  return { ...policy, insured };
};

export const shouldOpenPopup = (employer, policy) => {
  if (policy && employer) {
    return employer
      && policy.status === PolicyStatus.FINISH_SETUP
      && !!employer.policies.find((p) => p.policyTypeId === policy.policyTypeId);
  }
  return false;
};

export const getDefaultPolicyType = (policy) => {
  return policy
  ? { id: policy.policyTypeId, name: policy.policyType }
  : null;
};

export const toPolicyPayloadManual = (
  form,
  user,
  selectedPolicyType,
  selectedPolicy
) => {
  let familyIdToSet = '';

  if (user.data === 'adviser') {
    familyIdToSet = form.familyId;
  } else if (form.userId) {
    // admin create policy
    familyIdToSet = null;
  } else {
    familyIdToSet = user.currentFamilyId;
  }

  const payload = {
    ...selectedPolicy,
    ...form,
    processed: true,
    userId: form.userId || user.id, // admin create policy
    familyId: familyIdToSet,
    policyTypeId: selectedPolicyType.id,
    policyType: selectedPolicyType.name
  };

  if (selectedPolicyType.name === INSURANCE_TYPES_NAMES.LIFE) {
    payload.name = R.pathOr('', ['insured', 0, 'name'], payload);
  }

  return payload;
};

export const getManualDefaultValues = (policy, policyType) => {
  if (policyType === INSURANCE_TYPES_NAMES.LIFE) {
    return { insured: [], ...policy };
  } else if (policy) {
    return policy;
  }
  return {};
};