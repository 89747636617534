// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".po4Je74zq2RiVBjJLY3iD{display:flex;align-items:center;justify-content:space-between}._2Y_FxqOdUUhEEacOeSmxK6{width:12px;height:12px;border-radius:calc(12px / 2);margin-right:8px}._2QxS5Ea4Od0tFAugig3kgA{background-color:#7cb26e}._3DN1qlRxTcsfJygIq0zw70{background-color:#1aa8e9}._3bwcvOOFfM0Vmtu45Cshq8{background-color:#ff5353}.sAtjISxLfqRI8KOYWiyBZ{background-color:#c7c7c7}._2ysj_TFXYbBKD4APhqtOmr{background-color:#ffa53a}._3Q_7n3dPoddeB2OGHx6v7b{font-family:\"Open Sans\";font-weight:400;font-size:12px;white-space:normal;width:calc(100% - 14px);flex:1;color:#34363e;line-height:1.2}.q24R_Ll3nzIklVPpViAMz{margin-left:8px}._2dji_dS_NZ8Co0DRvPI-Y_{display:flex;align-items:center}.KSrgrl9xMtHA7RScgDfWa{font-size:12px;margin-left:8px}._1PIRnhOQ0sk0RhEpxvnLD_{width:18px;height:18px}._1PIRnhOQ0sk0RhEpxvnLD_ svg{min-width:18px;height:18px}._1PIRnhOQ0sk0RhEpxvnLD_ svg circle:last-child{stroke:#d7d7d7 !important}", ""]);
// Exports
exports.locals = {
	"container": "po4Je74zq2RiVBjJLY3iD",
	"circle": "_2Y_FxqOdUUhEEacOeSmxK6",
	"active": "_2QxS5Ea4Od0tFAugig3kgA _2Y_FxqOdUUhEEacOeSmxK6",
	"processing": "_3DN1qlRxTcsfJygIq0zw70 _2Y_FxqOdUUhEEacOeSmxK6",
	"error": "_3bwcvOOFfM0Vmtu45Cshq8 _2Y_FxqOdUUhEEacOeSmxK6",
	"disabled": "sAtjISxLfqRI8KOYWiyBZ _2Y_FxqOdUUhEEacOeSmxK6",
	"warning": "_2ysj_TFXYbBKD4APhqtOmr _2Y_FxqOdUUhEEacOeSmxK6",
	"text": "_3Q_7n3dPoddeB2OGHx6v7b",
	"toggle": "q24R_Ll3nzIklVPpViAMz",
	"progress": "_2dji_dS_NZ8Co0DRvPI-Y_",
	"progressText": "KSrgrl9xMtHA7RScgDfWa",
	"spinner": "_1PIRnhOQ0sk0RhEpxvnLD_"
};
module.exports = exports;
