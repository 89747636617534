import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import styles from './address-search.scss';

const SEARCH_TYPES = ['address'];
const COMPONENT_RESTRICTIONS = { country: 'nz' };

interface IAddressSearchProps {
  autoFocus?: boolean;
  autocomplete?: string;
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const AddressSearch = ({
  autocomplete,
  className,
  onChange,
  onSelect,
  disabled,
  value,
  placeholder,
  name,
  autoFocus,
}: IAddressSearchProps) => {
  const ref = useRef(null);

  useEffect(() => {
    const inputRef = ref.current?.refs?.input;
    if (autoFocus && inputRef) {
      setTimeout(() => inputRef.focus(), 500);
    }
  }, [autoFocus]);

  const handlePlaceSelected = useCallback(
    ({ address_components, formatted_address, geometry }) => {
      const target = {
        name,
        value: formatted_address.replace(/, New Zealand$/i, ''),
        addressComponents: address_components,
        lng: geometry?.location?.lng(),
        lat: geometry?.location?.lat(),
      };

      const evt = { target } as ChangeEvent<HTMLInputElement>;

      if (onSelect) {
        onSelect(evt);
      } else {
        onChange(evt);
      }
    },
    [name, onChange],
  );

  return (
    <Autocomplete
      ref={ref}
      id={name}
      className={classnames(styles.input, className)}
      name={name}
      onChange={onChange}
      onPlaceSelected={handlePlaceSelected}
      types={SEARCH_TYPES}
      componentRestrictions={COMPONENT_RESTRICTIONS}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      inputAutocompleteValue={autocomplete}
    />
  );
};

export default AddressSearch;
