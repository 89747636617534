// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".IzUEmStWLf5pVgH9K-lNG.ui.modal{border-radius:5px;background-color:#1e2646}.IzUEmStWLf5pVgH9K-lNG.ui.modal > .content{display:flex;padding:80px 48px;background-color:rgba(0,0,0,0);color:#fff}._1aoUBzw-KQMl4IcM5oIP9X.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#4187f5;margin-right:0}@media(max-width: 576px){._1aoUBzw-KQMl4IcM5oIP9X.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}._3M2I1mbx8UTPSLZqErIno0{cursor:pointer;position:absolute;right:24px;top:24px}.XgqHmwFupJJeZEmX8lgta,._1mdIyKk47ASdfcxOHFOqd{flex:1}.XgqHmwFupJJeZEmX8lgta>*:not(:last-child),._1mdIyKk47ASdfcxOHFOqd>*:not(:last-child){margin-bottom:24px}.XgqHmwFupJJeZEmX8lgta{border-right:2px solid #fff;padding-right:60px;margin-right:60px}._1mdIyKk47ASdfcxOHFOqd{text-align:center}.blk6_7IM2lcCsMDFmrlVv{font-family:\"Open Sans\";font-weight:700;color:#4187f5}.EHRa4VoiX2mqyhx8Rs1T6{font-family:\"DM Sans\";font-weight:700;font-size:30px;line-height:1}._1-Hsq1ljtPovj4OxwlfiS9{font-family:\"Open Sans\";font-weight:700}.wDIpK1nzI0KKnZbZ0CIUx input{border-radius:10px !important}._1mFK8LqPjsCS70EjYhxDP9{display:none}._2CfnwHDNmF981q_1j0vOzo ._1-Hsq1ljtPovj4OxwlfiS9{padding-bottom:8px}@media(max-width: 820px){.IzUEmStWLf5pVgH9K-lNG.ui.modal > .content{flex-direction:column;padding:40px !important}.XgqHmwFupJJeZEmX8lgta{border-right:none;padding-right:0;margin-right:0;margin-bottom:24px;text-align:center}}@media(max-width: 576px){._1mdIyKk47ASdfcxOHFOqd ._2CfnwHDNmF981q_1j0vOzo>br{display:none}.wDIpK1nzI0KKnZbZ0CIUx input{max-width:none}}", ""]);
// Exports
exports.locals = {
	"modal": "IzUEmStWLf5pVgH9K-lNG",
	"blueBtn": "_1aoUBzw-KQMl4IcM5oIP9X",
	"close": "_3M2I1mbx8UTPSLZqErIno0",
	"left": "XgqHmwFupJJeZEmX8lgta",
	"right": "_1mdIyKk47ASdfcxOHFOqd",
	"blue": "blk6_7IM2lcCsMDFmrlVv",
	"title": "EHRa4VoiX2mqyhx8Rs1T6",
	"bold": "_1-Hsq1ljtPovj4OxwlfiS9",
	"input": "wDIpK1nzI0KKnZbZ0CIUx",
	"label": "_1mFK8LqPjsCS70EjYhxDP9",
	"content": "_2CfnwHDNmF981q_1j0vOzo"
};
module.exports = exports;
