import moment from 'moment';
import { IAdviserState, IAdviserConnect } from '../../types';
import { AppActionTypes } from '../actions';
import { Types } from '../actions/adviser-actions';
import { Types as PolicyRenewalTypes } from '../actions/policy-renewal-actions';
import {
  addPremiums,
  calculateTotalInsured,
  insertItem,
  sortByCreatedAtDesc,
  updateListByItem,
  updateListByItemMerged,
} from 'helpers';
import { FREQUENCY } from 'constants/options';

const checkIsConnected = (adviserConnects: IAdviserConnect[]) => {
  if (adviserConnects.length === 0) {
    return false;
  }
  const now = moment();
  const endBooking = R.path(
    ['calendlyEvent', 'resource', 'end_time'],
    adviserConnects[0],
  );
  return moment(endBooking).isAfter(now);
};

const addClientPolicy = (clients, policy) => {
  const client = clients.find(R.propEq('id', policy.userId));
  if (!client) {
    return clients;
  }
  client.policies = insertItem(client.policies, policy);
  return updateListByItem(clients, client);
};

const updateClientPolicy = (clients, policy) => {
  const client = clients.find(R.propEq('id', policy.userId));
  if (!client) {
    return clients;
  }
  client.policies = updateListByItem(client.policies, policy);
  return updateListByItem(clients, client);
};

// Same as updateClientPolicy but merge policies
const updateClientPolicyMerged = (clients, policy) => {
  const client = clients.find(R.propEq('id', policy.userId));
  if (!client) {
    return clients;
  }
  client.policies = updateListByItemMerged(client.policies, policy);
  return updateListByItem(clients, client);
};

const deleteClientPolicy = (clients, { id, sk }) => {
  const client = clients.find(R.propEq('defaultFamilyId', sk));
  if (!client) {
    return clients;
  }
  client.policies = R.reject(R.propEq('id', id), client.policies);
  return updateListByItem(clients, client);
};

const getTotal = (clients) => {
  const totalClients = clients.length;
  let totalPolicies = 0;
  let totalPremiums = 0;
  let totalAmount = 0;

  clients.forEach(({ policies }) => {
    const { totalInsured } = calculateTotalInsured(policies);
    totalPolicies += policies.length;
    totalAmount += totalInsured;
    totalPremiums += parseFloat(addPremiums(policies, FREQUENCY.YEARLY));
  });

  return {
    policies: totalPolicies,
    premiums: totalPremiums,
    amount: totalAmount,
    clients: totalClients,
  };
};

const advisersReducer = (
  state: IAdviserState,
  action: AppActionTypes,
): IAdviserState => {
  switch (action.type) {
    case Types.ADVISER_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case Types.GET_ALL_ADVISERS_LOADED:
      return {
        ...state,
        advisers: action.data,
      };
    case Types.GET_ADVISER_CONNECTS: {
      const adviserConnects = sortByCreatedAtDesc(action.data);
      return {
        ...state,
        adviserConnects,
        isConnected: checkIsConnected(adviserConnects),
        loaded: true,
      };
    }
    case Types.GET_ADVISER_CONNECT_LOADED: {
      return {
        ...state,
        premiumAdviserConnect: action.data,
        loading: false,
      };
    }
    case Types.CREATE_ADVISER_CONNECT: {
      const adviserConnects = sortByCreatedAtDesc([
        action.data,
        ...state.adviserConnects,
      ]);
      return {
        ...state,
        adviserConnects,
        premiumAdviserConnect: action.data,
        submitting: false,
        isConnected: true,
        loading: false,
      };
    }
    case Types.UPDATE_ADVISER_CONNECT: {
      const adviserConnects = updateListByItemMerged(
        state.adviserConnects,
        action.data,
      );
      return {
        ...state,
        adviserConnects,
        premiumAdviserConnect:
          action.data.id === state.premiumAdviserConnect?.id
            ? { ...state.premiumAdviserConnect, ...action.data }
            : state.premiumAdviserConnect,
        loading: false,
      };
    }
    case Types.CALENDY_EVENT_CREATED:
      return {
        ...state,
        calendly: {
          eventUrl: action.data.eventUrl,
        },
      };
    case Types.CALENDY_LOADING:
      return {
        ...state,
        loadingCalendar: action.data,
      };
    case Types.ADVISER_CONNECT_INFO_UPDATED:
      return {
        ...state,
        adviserConnectInfo: action.data,
      };
    case Types.CREATE_ADVISER_CLIENT:
      const clients = [action.data, ...state.clients];
      return {
        ...state,
        clients,
        submitted: true,
        submitting: false,
        total: getTotal(clients),
      };
    case Types.ADVISER_RESET_FORM:
      return {
        ...state,
        submitted: false,
        submitting: false,
        error: null,
      };
    case Types.GET_ADVISER_CLIENTS:
      return {
        ...state,
        loaded: true,
        clients: action.data,
        total: getTotal(action.data),
      };
    case Types.UPDATE_ADVISER_CLIENT: {
      const newClients = updateListByItem(state.clients, action.data);
      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case Types.CREATE_ADVISER_CLIENT_POLICY: {
      const newClients = addClientPolicy(state.clients, action.data);
      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case Types.UPDATE_ADVISER_CLIENT_POLICY: {
      const newClients = updateClientPolicy(state.clients, action.data);
      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case Types.DELETE_ADVISER_CLIENT_POLICY: {
      const newClients = deleteClientPolicy(state.clients, action.data);
      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case Types.DELETE_ADVISER_CLIENT: {
      const newClients = R.reject(R.propEq('id', action.data), state.clients);
      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case PolicyRenewalTypes.CREATE_RENEWAL_LOADED: {
      const updatedPolicy = action.data.isRenewExpiredPolicy
        ? action.data.newPolicyData
        : {
            id: action.data.sk,
            policyRenewalAdded: true,
            userId: R.path(['newPolicyData', 'userId'], action.data),
          };

      const newClients = updateClientPolicyMerged(state.clients, updatedPolicy);

      return {
        ...state,
        loaded: true,
        clients: newClients,
        total: getTotal(newClients),
      };
    }
    case Types.GET_CONSENTS_LOADED:
      return {
        ...state,
        consents: action.data,
        loadingConsents: false,
      };
    case Types.UPDATE_CONSENT_LOADED:
      return {
        ...state,
        consents: updateListByItem(state.consents, action.data),
      };
    case Types.CREATE_CONSENTS_LOADED:
      return {
        ...state,
        consents: R.concat(state.consents, action.data),
        submitted: true,
        submitting: false,
      };
    case Types.CREATE_CONSENTS_FAILED:
      return {
        ...state,
        submitting: false,
        error: action.data,
      };
    case Types.DELETE_CONSENT_LOADED: {
      const newConsents = R.reject(
        R.propEq('id', action.data.id),
        state.consents,
      );
      return {
        ...state,
        consents: newConsents,
      };
    }
    case Types.ADVISER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.ADVISER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case Types.ADVISER_INQUIRY_LOADED:
      return {
        ...state,
        isInquired: true,
        loading: false,
      };
    case Types.ADD_TO_CART_LOADED:
      return {
        ...state,
        cart: action.data,
      };
    default:
      return state;
  }
};

export default advisersReducer;
