import { INSURANCE_TYPES_NAMES } from './insurance';
import { EMPLOYMENT_OPTIONS } from './options';
import { DOB_QUESTION } from './typeform-questions';

export interface ICoverageRule {
  label: string;
  // When there is no key, we always show results even without answer
  key?: string;
  // Always return these results when there is a valid answer
  results?: string[];
  // Returns true when answer includes any of these values
  includes?: Array<string | boolean>;
  // The results returned when includes is true
  includesResults?: string[];
  // Returns true when answer is NOT any of these values
  excludes?: Array<string | boolean>;
  // The results returned when excludes is true
  excludesResults?: string[];
  // Skip rule when user has policies in this array
  exceptions?: string[];
  // Skip rule when current result has policies in this array
  resultExceptions?: string[];
}

export enum RainyDayFundValues {
  Under500 = 'under500',
  Under1000 = 'under1000',
  Over1000 = 'over1000',
}

export const RainyDayTextMap = {
  [RainyDayFundValues.Under500]: {
    short: 'Under $500',
    long: 'under $500',
    excess: 500,
    underExcessMsg:
      'By increasing your rainy day fund, you can also increase your excess amount to save some money.',
    overExcessMsg:
      'But your current excess is over $500, it doesn’t look like you would have enough to cover your excess across your insurance if anything goes wrong.',
  },
  [RainyDayFundValues.Under1000]: {
    short: 'Under $1000',
    long: '$500 - $1000',
    defaultMsg:
      'Check you have more in your rainy day fund than your excess. Increasing your excess is one way you can save some money.',
  },
  [RainyDayFundValues.Over1000]: {
    short: 'Over $1000',
    long: 'over $1000',
    defaultMsg:
      'Check you have more in your rainy day fund than your excess. Increasing your excess is one way you can save some money.',
  },
};

export const exploreCoverageRules: ICoverageRule[] = [
  {
    // no key always show
    label: 'For yourself',
    results: [
      INSURANCE_TYPES_NAMES.HEALTH,
      INSURANCE_TYPES_NAMES.LIFE,
      INSURANCE_TYPES_NAMES.TRAUMA,
      INSURANCE_TYPES_NAMES.DISABILITY,
    ],
  },
  {
    key: 'employment',
    label: 'For your employment',
    includes: ['employed', 'self-employed'],
    includesResults: [INSURANCE_TYPES_NAMES.INCOME],
  },
  {
    key: 'homeOwnership',
    label: 'For your home',
    includes: ['own'],
    includesResults: [INSURANCE_TYPES_NAMES.HOME_CONTENTS],
    exceptions: [INSURANCE_TYPES_NAMES.HOME, INSURANCE_TYPES_NAMES.CONTENT],
  },
  {
    key: 'homeOwnership',
    label: 'For your home',
    includes: ['own'],
    includesResults: [INSURANCE_TYPES_NAMES.HOME],
    exceptions: [INSURANCE_TYPES_NAMES.HOME_CONTENTS],
    resultExceptions: [INSURANCE_TYPES_NAMES.HOME_CONTENTS],
  },
  {
    key: 'homeOwnership',
    label: 'For your rental',
    includes: ['own', 'rental'],
    includesResults: [INSURANCE_TYPES_NAMES.CONTENT],
    exceptions: [INSURANCE_TYPES_NAMES.HOME_CONTENTS],
    resultExceptions: [INSURANCE_TYPES_NAMES.HOME_CONTENTS],
  },
  {
    key: 'landlord',
    label: 'For your investments',
    excludes: ['0'],
    excludesResults: [INSURANCE_TYPES_NAMES.LANDLORD],
  },
  {
    key: 'mortgage',
    label: 'For your mortgage',
    includes: [true],
    includesResults: [INSURANCE_TYPES_NAMES.MORTGAGE],
  },
  {
    key: 'cars',
    label: 'For your vehicles',
    includes: [true],
    includesResults: [INSURANCE_TYPES_NAMES.VEHICLE],
  },
  {
    key: 'motorcycles',
    label: 'For your motorcycles',
    includes: [true],
    includesResults: [INSURANCE_TYPES_NAMES.MOTORCYCLE],
  },
  {
    key: 'boat',
    label: 'For your vehicles',
    includes: [true],
    includesResults: [INSURANCE_TYPES_NAMES.BOAT],
  },
  {
    key: 'pet',
    label: 'For your pets',
    excludes: ['none'],
    excludesResults: [INSURANCE_TYPES_NAMES.PET],
  },
  {
    key: 'holidays',
    label: 'For your holidays',
    results: [INSURANCE_TYPES_NAMES.TRAVEL],
  },
];

// We show the "Get Help From Adviser" button when coverage check results in these policies
export const adviserBtnPolicies = [
  INSURANCE_TYPES_NAMES.HEALTH,
  INSURANCE_TYPES_NAMES.LIFE,
  INSURANCE_TYPES_NAMES.TRAUMA,
  INSURANCE_TYPES_NAMES.DISABILITY,
];
