import { useEffect, useRef } from 'react';
import { POLICY_CHECK_MAX_COUNT, POLICY_CHECK_INTERVAL } from 'constants/policy';
import { getPolicyProgressCount } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getBundleById } from 'services';
import { IAppState, IBundle } from 'types';
import { GetUserPoliciesLoaded, DeletePolicyBundleLoaded } from 'actions';

const hasNewPolicy = (bundlePolicies, policies) => {
  return R.reject(
    (bp) => policies.find(R.propEq('id', bp.id)),
    bundlePolicies
  );
};

const useBundleNotification = () => {
  const dispatch = useDispatch();
  const { policies: { bundles, policies } } = useSelector(R.identity) as IAppState;

  // Keep track of notifications so we do not keep creating multiple intervals for the same policy
  const notifications = useRef({});

  const removeNotification = (id, intervalId, bundleId) => {
    notifications.current = R.dissoc(id, notifications.current);
    clearInterval(intervalId);
    dispatch(DeletePolicyBundleLoaded(bundleId));
  };

  const addNotification = (id, intervalId) => {
    notifications.current = R.assoc(id, intervalId, notifications.current);
  };

  const updatePoliciesList = (userPolicies) => {
    // Update policies list only when there is a new policy added
    if (hasNewPolicy(userPolicies, policies)) {
      dispatch(GetUserPoliciesLoaded(userPolicies));
    }
  }; 

  useEffect(() => {
    if (bundles.length > 0) {
      bundles.forEach((bundle: IBundle) => {
        const { id, processed, createdAt } = bundle;
        const currentCount = getPolicyProgressCount(createdAt);
        const policyInterval = notifications.current[id];

        if (!processed && !policyInterval && currentCount < POLICY_CHECK_MAX_COUNT) {
          const interval = setInterval(async () => {
            const count = getPolicyProgressCount(createdAt);
            if (count >= POLICY_CHECK_MAX_COUNT) {
              // Change processed to true to hide it from policy list
              // when its over the limit
              removeNotification(id, interval, bundle.id);
            } else {
              const { data } = await getBundleById(id, true);
              if (data.processed) {
                removeNotification(id, interval, data.id);
              }
              updatePoliciesList(data.userPolicies);
            }
          }, POLICY_CHECK_INTERVAL);

          if (!policyInterval) {
            addNotification(id, interval);
          }
        }
      });
    }
  }, [bundles]);
};

export default useBundleNotification;
