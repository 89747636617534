import Spinner from 'components/spinner';
import React from 'react';
import styles from './styles/LoadingSpinner.module.scss';

const LoadingSpinner = ({ text }) => {
  return (
    <div className={styles.loader}>
      {text}
      <Spinner className={styles.spinner} loading={true} />
    </div>
  );
};

export default LoadingSpinner;
