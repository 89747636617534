// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1uZDiabwf_UBz9tqivihP{position:relative;display:flex;margin-bottom:16px}._1uZDiabwf_UBz9tqivihP>*{margin-bottom:0}._1uZDiabwf_UBz9tqivihP>*:first-child{flex:.7;margin-right:16px}._1uZDiabwf_UBz9tqivihP>*:not(:first-child){flex:.3}._1uZDiabwf_UBz9tqivihP:first-child ._2mB7eu8CNc_QRdKt89sPFF{display:block}._1_ZHAZllzgVwid9qoLWfyq{cursor:pointer;color:#34363e;position:absolute;right:-24px;top:12px}._3pYlcZc4EWL5SM8e-lAJxR{display:none}._3z0ziitPkTugv-e0w0oLcG{font-family:\"Open Sans\";font-weight:400;font-size:14px;color:#4187f5;display:flex;align-items:center;cursor:pointer}._3z0ziitPkTugv-e0w0oLcG svg{margin-left:4px}._3kDUjuj4-p9FFmRY_EaOIP{display:flex;flex-direction:column}._2mB7eu8CNc_QRdKt89sPFF{margin-bottom:16px;display:none}._3uD39IhUivtWzFoKlIeMol>*:first-child{flex:.3;margin-right:16px}._3uD39IhUivtWzFoKlIeMol>*:not(:first-child){flex:.7}._3uD39IhUivtWzFoKlIeMol:first-child ._2mB7eu8CNc_QRdKt89sPFF{display:none}._3uD39IhUivtWzFoKlIeMol label{line-height:40px;font-weight:700}@media(max-width: 820px){._1uZDiabwf_UBz9tqivihP{flex-direction:column}._1uZDiabwf_UBz9tqivihP>*:first-child{flex:1;margin-right:0;margin-bottom:8px}._1uZDiabwf_UBz9tqivihP>*:not(:first-child){flex:1}._2mB7eu8CNc_QRdKt89sPFF{margin-bottom:8px;display:flex;justify-content:space-between}._1_ZHAZllzgVwid9qoLWfyq{display:none}._3pYlcZc4EWL5SM8e-lAJxR{display:flex;align-items:center;cursor:pointer}._3pYlcZc4EWL5SM8e-lAJxR svg{margin-left:5px}}", ""]);
// Exports
exports.locals = {
	"row": "_1uZDiabwf_UBz9tqivihP",
	"header": "_2mB7eu8CNc_QRdKt89sPFF",
	"removeBtn": "_1_ZHAZllzgVwid9qoLWfyq",
	"removeLabel": "_3pYlcZc4EWL5SM8e-lAJxR",
	"addBtn": "_3z0ziitPkTugv-e0w0oLcG",
	"col": "_3kDUjuj4-p9FFmRY_EaOIP",
	"labelRow": "_3uD39IhUivtWzFoKlIeMol"
};
module.exports = exports;
