// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2BMAVjZ4Ap4qR26Pw-_3VF{display:inline-block;cursor:pointer;border:solid 1px #d4d4d4;border-radius:5px;width:250px;height:450px;text-align:center;padding:30px 20px;margin-right:20px}._2BMAVjZ4Ap4qR26Pw-_3VF:nth-child(3n){margin-right:0}._2BMAVjZ4Ap4qR26Pw-_3VF._29s_awZULD9a31hE7E3uz8 .G0xrOzkXY1duMxeskHFkg.ui.button,._2BMAVjZ4Ap4qR26Pw-_3VF:hover .G0xrOzkXY1duMxeskHFkg.ui.button{background-color:#7cb26e;border-color:#7cb26e;color:#fff}._2BMAVjZ4Ap4qR26Pw-_3VF:hover{box-shadow:0 3px 4px 0 rgba(197,189,189,.5)}._1T6Fnvtk8S6WFzcg6ly9d8{width:116px;height:116px;border-radius:50%;margin-bottom:10px}.LIS2pg3CgqfRP0W8OaHue{font-family:\"Open Sans\";font-weight:700;font-size:18px;margin-bottom:5px}._3l2g4Ow-gsz4hEfO7W77PR{font-family:\"Open Sans\";font-weight:400;font-size:12px;color:#7e8395}._1L3ML9cahg-ihi5TD_pl_S{margin-top:15px;margin-bottom:30px}._36VVLMHRF3xrj4-ryyJAx{font-family:\"Open Sans\";font-weight:400;font-size:14px;color:#696974;font-weight:bold;margin-bottom:10px}.G0xrOzkXY1duMxeskHFkg.ui.button{font-family:\"Open Sans\";font-weight:400;font-size:18px;border-radius:10px;height:40px;padding:0;width:165px;border:none;font-weight:normal;border:1px solid #979797}", ""]);
// Exports
exports.locals = {
	"adviser": "_2BMAVjZ4Ap4qR26Pw-_3VF",
	"selected": "_29s_awZULD9a31hE7E3uz8",
	"connectBtn": "G0xrOzkXY1duMxeskHFkg",
	"adviserImg": "_1T6Fnvtk8S6WFzcg6ly9d8",
	"adviserName": "LIS2pg3CgqfRP0W8OaHue",
	"adviserDetails": "_3l2g4Ow-gsz4hEfO7W77PR",
	"adviserSpec": "_1L3ML9cahg-ihi5TD_pl_S",
	"adviserSpecLabel": "_36VVLMHRF3xrj4-ryyJAx"
};
module.exports = exports;
