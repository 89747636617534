// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1ExSaL7YG6F114FLTwvWZO{margin-top:24px}._3mnKLm2MHAz6fbTHpMcqT3{background-color:#ebedf3;border-radius:5px;display:flex;flex-direction:column;padding:16px 40px;color:#34363e}._3mnKLm2MHAz6fbTHpMcqT3 img{max-width:120px;margin-right:40px}._1_pNXBjqXVmrXB2DtC5iY9{font-family:\"Open Sans\";font-weight:700;font-size:14px}._3hfcrDcMa4LIonhFd0oxZy{line-height:30px}._3hfcrDcMa4LIonhFd0oxZy a{font-family:\"Open Sans\";font-weight:700;font-size:14px;color:#34363e;text-decoration:underline}@media(max-width: 820px){._3mnKLm2MHAz6fbTHpMcqT3{flex-direction:column}._3mnKLm2MHAz6fbTHpMcqT3 img{margin-right:0;margin-top:-8px}._1_pNXBjqXVmrXB2DtC5iY9{margin-bottom:4px}._3hfcrDcMa4LIonhFd0oxZy{line-height:24px}._3hfcrDcMa4LIonhFd0oxZy br{display:none}}@media(max-width: 576px){._1_pNXBjqXVmrXB2DtC5iY9{font-size:14px}}", ""]);
// Exports
exports.locals = {
	"offers": "_1ExSaL7YG6F114FLTwvWZO",
	"offer": "_3mnKLm2MHAz6fbTHpMcqT3",
	"title": "_1_pNXBjqXVmrXB2DtC5iY9",
	"desc": "_3hfcrDcMa4LIonhFd0oxZy"
};
module.exports = exports;
