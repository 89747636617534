import { prettifyNumber } from 'helpers';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

interface IProps {
  className?: string;
  creatable?: boolean;
  disabled?: boolean;
  icon?: string;
  inputType?: string;
  minCharacters?: number;
  noResultsMessage?: string;
  placeholder?: string;
  prefix?: string;
  search?: boolean;
  name: string;
  options: DropdownItemProps[];
  value: boolean | string | number | any[];
  onChange(event: SyntheticEvent, data: object): void;
}

const checkIsNewOption = (options, value) =>
  !options.find(R.propEq('value', value));

const addOption = (options, prefix, value, inputType) => {
  if (value && checkIsNewOption(options, value)) {
    const text = inputType === 'number' ? prettifyNumber(value) : value;
    const newOptions = [
      { key: value, text: `${prefix}${text}`, value },
      ...options,
    ];
    return R.sortBy(R.prop('value'), newOptions);
  }
  return options;
};

const getValue = (value, inputType) => {
  if (inputType === 'number') {
    const parsed = Number(value);
    return Number.isNaN(parsed) ? value : parsed;
  }
  return value;
};

const getTextFromValue = (value, options = []) => {
  const opt = options.find(R.propEq('value', value));
  return opt?.text || '';
};

const DropdownInput = ({
  className,
  disabled,
  placeholder,
  name,
  options = [],
  prefix = '',
  icon,
  value,
  inputType = 'text',
  noResultsMessage,
  minCharacters,
  creatable,
  search = true,
  onChange,
}: IProps) => {
  const [query, setQuery] = useState(getTextFromValue(value, options));
  const [stateOptions, setOptions] = useState(
    addOption(options, prefix, value, inputType),
  );

  useEffect(() => {
    setQuery(getTextFromValue(value, options));
  }, [value]);

  const handleAddition = (_, data) => {
    setOptions((prevState) => {
      const val = getValue(data.value, inputType);
      return addOption(prevState, prefix, val, inputType);
    });
  };

  const handleChange = (e, data) => {
    const val = getValue(data.value, inputType);
    onChange(e, R.assoc('value', val, data));
  };

  const handleSearchChange = (_, data) => {
    const q =
      inputType === 'number'
        ? // Strip all non-numeric values when its a number input
          data.searchQuery.replace(/\D/g, '')
        : data.searchQuery;
    setQuery(q);
  };

  return (
    <Dropdown
      additionLabel={prefix}
      search={search}
      selection={true}
      allowAdditions={creatable}
      className={className}
      disabled={disabled}
      options={creatable ? stateOptions : options}
      name={name}
      value={value}
      placeholder={placeholder}
      icon={icon}
      searchQuery={query}
      onAddItem={handleAddition}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      noResultsMessage={noResultsMessage}
      minCharacters={minCharacters}
    />
  );
};

export default DropdownInput;
