import React from 'react';
import { FieldTypeMap, TextInput } from 'components/form-fields-v2';
import MultiForm from 'components/multi-form';
import MultiRow from 'components/multi-row';
import { FULL_ADDRESS_OTHER_QUESTIONS } from 'constants/questions';
import { getFormById } from 'services';
import { FieldType } from 'types';
import {
  getFullAddressFromAddressComponents,
  isRiskZoneProperty,
} from 'helpers';
import Ratings from './pages/Ratings';
import { FormEnginePageMap } from './pages';
import FormEngineList from './components/FormEngineList';

const fieldTypeMap = {
  ...FieldTypeMap,
  [FieldType.List]: FormEngineList,
  [FieldType.MultiRow]: MultiRow,
  [FieldType.MultiForm]: MultiForm,
  [FieldType.Rating]: Ratings,
};

export const onMountFns = {
  contentsTotalAmount: ({
    hasValuableItems,
    generalAmount = 0,
    valuableItems = [],
  }) => {
    // Booleans are strings until we submit them to the API
    if (hasValuableItems === 'true') {
      return valuableItems.reduce(
        (acc, { value = 0 }) => acc + value,
        generalAmount,
      );
    }

    return generalAmount;
  },
};

export const getFieldComponent = (fieldType) =>
  fieldTypeMap[fieldType] || TextInput;

export const setFormDefinition = async (
  formId: string,
  setFormDef: React.Dispatch<any>,
) => {
  const formDef = await getFormById(formId);
  setFormDef(formDef.data);
};

// React-hook-form follows HTML standards, so it does not expect field values to be null, undefined or boolean
// so we have to parse the booleans into strings
export const parseDefaultValues = (values: any) => {
  if (typeof values !== 'object') {
    return values;
  }

  return R.mapObjIndexed((val: any) => {
    if (Array.isArray(val)) {
      return val.map(parseDefaultValues);
    }
    return typeof val === 'boolean' ? val.toString() : val;
  }, values);
};

export const getPageComponent = (pageType: string) => {
  return FormEnginePageMap[pageType] || FormEnginePageMap.default;
};

export const getSelectHandler = (field, form, config, props) => {
  if (field.name === 'address') {
    return (e) => {
      const fullAddress = getFullAddressFromAddressComponents(
        e.target.addressComponents,
      );

      form.setValue(e.target.name, e.target.value);
      form.setValue('isAddressValid', !!fullAddress?.streetNo);
      form.setValue('lng', e.target.lng);
      form.setValue('lat', e.target.lat);

      FULL_ADDRESS_OTHER_QUESTIONS.forEach((f) => {
        form.setValue(f.key, fullAddress?.[f.key]);
      });

      form.setValue(
        'riskZoneProperty',
        isRiskZoneProperty(fullAddress, config),
      );
      props.onAddressSelect?.(form, {
        address: e.target.value,
        ...fullAddress,
      });

      // If this field has an error, re-validate to try and remove it
      form.trigger(field.name);
    };
  }
};

export const getChangeHandler = (field, form, props, question) => {
  if (field.name === 'address' && question.full) {
    return (e) => {
      form.setValue('isAddressValid', false);
      form.setValue('flatNo', '');
      form.setValue('streetNo', '');
      form.setValue('streetName', '');
      form.setValue('suburb', '');
      form.setValue('city', '');
      form.setValue('postCode', '');
      form.setValue(e.target.name, e.target.value);
    };
  }
  return (...args) => {
    props.onChange?.(...args);
    field.onChange(...args);
  };
};

export const getClickHandler = (question, form, props) => {
  if (question.type === 'list') {
    return () => {
      props.setActive(question.redirectTo);
    };
  }
  return undefined;
};

export const getInfoWarningText = (infoWarning, values) => {
  if (infoWarning && values[infoWarning.when] === infoWarning.is) {
    return infoWarning.text;
  }
  return '';
};
