// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1CR8i9pAFhP8V6Un1Im0Fi{font-family:\"DM Sans\";font-weight:700;font-size:18px;color:#34363e;cursor:pointer;line-height:30px}._1CR8i9pAFhP8V6Un1Im0Fi:hover{color:inherit}._3911nL69dzpxQhkqIj6WFg{font-size:16px;color:#000}._3911nL69dzpxQhkqIj6WFg>span{text-decoration:underline}._3911nL69dzpxQhkqIj6WFg>i.icon{margin-right:0}@media(max-width: 820px){._1CR8i9pAFhP8V6Un1Im0Fi{line-height:20px}._1CR8i9pAFhP8V6Un1Im0Fi .BCDBewSu03oU18XNfnG2V{display:none}}", ""]);
// Exports
exports.locals = {
	"back": "_1CR8i9pAFhP8V6Un1Im0Fi",
	"black": "_3911nL69dzpxQhkqIj6WFg _1CR8i9pAFhP8V6Un1Im0Fi",
	"label": "BCDBewSu03oU18XNfnG2V"
};
module.exports = exports;
