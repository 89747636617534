import { setUserSetting, StorageKeys } from 'helpers';
import { Action } from 'redux';

export enum Types {
  TOGGLE_MAXIMISED = 'TOGGLE_MAXIMISED',
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}

interface IToggleSidebar extends Action {
  type: Types.TOGGLE_SIDEBAR;
  data: boolean;
}

interface IToggleMaximised extends Action {
  type: Types.TOGGLE_MAXIMISED;
  data: boolean;
}

export type ActionTypes = IToggleSidebar | IToggleMaximised;

export const ToggleSidebarAction = (data: boolean) => {
  return {
    type: Types.TOGGLE_SIDEBAR,
    data,
  } as IToggleSidebar;
};

export const ToggleMaximisedAction = (data: boolean) =>
  ({
    type: Types.TOGGLE_MAXIMISED,
    data,
  } as IToggleMaximised);

export const ToggleSidebar = (expanded: boolean) => (dispatch) => {
  setUserSetting(StorageKeys.SIDEBAR, true);
  dispatch(ToggleSidebarAction(expanded));
};

export const ToggleMaximised = (show: boolean) => (dispatch) => {
  dispatch(ToggleMaximisedAction(show));
};
