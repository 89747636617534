import React, { useEffect } from 'react';
import {
  CalendlyEventListener,
  closePopupWidget,
  openPopupWidget,
} from 'react-calendly';
import Button from 'components/button';
import styles from './CalendlyButton.module.scss';
import { AdviserConnectInfoUpdated } from 'actions';
import { useDispatch } from 'react-redux';

declare const CALENDLY: string;

interface IProps {
  className?: string;
  buttonText: string;
  prefill: any;
  values: any;
  onClick?(e: any): void;
  onEventScheduled?(e): void;
}

const CalendlyButton = ({
  buttonText = 'Open',
  className,
  prefill,
  values,
  onClick = R.F,
  onEventScheduled,
}: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (values) {
      dispatch(AdviserConnectInfoUpdated(values));
    }
  }, [values]);

  const handleOpen = (e) => {
    openPopupWidget({
      url: CALENDLY,
      prefill,
    });
    onClick(e);
  };

  const handleEventScheduled = (e) => {
    if (onEventScheduled) {
      onEventScheduled(e);
    }
    // Auto close popup after 3 seconds
    setTimeout(closePopupWidget, 3000);
  };

  return (
    <>
      <CalendlyEventListener onEventScheduled={handleEventScheduled} />
      <Button
        className={classnames(styles.button, className)}
        text={buttonText}
        onClick={handleOpen}
      />
    </>
  );
};

export default CalendlyButton;
