import { Action } from 'redux';
import {
  createPolicyRenewal,
  createPolicyRenewalWithAttachments,
  getPendingRenewalsByUserId,
  getPolicyRenewalsByPolicyId,
  ICreatePolicyRenewalProps,
  IFile,
  updateClientPolicy,
  updatePolicy,
  updatePolicyRenewal,
} from 'services';
import { IPolicy, IPolicyRenewal } from 'types';
import history from 'helpers/history';
import { ErrorToast } from 'components/toasts';
import {
  CREATE_POLICY_RENEWAL_ERROR,
  UPLOAD_FAILED_MESSAGE,
} from 'constants/errors';
import {
  convertToDateOnlyString,
  getRenewalEndRoute,
  getPolicyFromRenewal,
} from 'helpers';

export enum Types {
  CREATE_RENEWAL_LOADING = 'CREATE_RENEWAL_LOADING',
  CREATE_RENEWAL_LOADED = 'CREATE_RENEWAL_LOADED',
  CREATE_PENDING_RENEWAL = 'CREATE_PENDING_RENEWAL',
  DELETE_RENEWAL_LOADED = 'DELETE_RENEWAL_LOADED',
  DELETE_PENDING_RENEWAL_LOADED = 'DELETE_PENDING_RENEWAL_LOADED',
  UPDATE_RENEWAL_LOADED = 'UPDATE_RENEWAL_LOADED',
  UPDATE_PENDING_RENEWAL_LOADED = 'UPDATE_PENDING_RENEWAL_LOADED',
  SELECT_RENEWAL_LOADED = 'SELECT_RENEWAL_LOADED',
  POLICY_RENEWAL_SUBMITTING = 'POLICY_RENEWAL_SUBMITTING',
  POLICY_RENEWAL_FAILED = 'POLICY_RENEWAL_FAILED',
  POLICY_RENEWAL_LOADING = 'POLICY_RENEWAL_LOADING',
  RESET_RENEWAL_SUBMIT = 'RESET_RENEWAL_SUBMIT',
  GET_PENDING_RENEWALS_LOADED = 'GET_PENDING_RENEWALS_LOADED',
}

const setPolicyRenewalAdded = async (
  renewal: IPolicyRenewal,
  policy: IPolicy,
) => {
  if (!renewal.isRenewExpiredPolicy) {
    const policyToUpdate = R.assoc('policyRenewalAdded', true, policy);
    if (policy.adviserOrgId) {
      await updateClientPolicy(policyToUpdate);
    } else {
      await updatePolicy(policyToUpdate);
    }
  }
};

export interface ISelectPolicyRenewalLoaded {
  type: Types.SELECT_RENEWAL_LOADED;
  data: IPolicyRenewal[];
}

export interface ICreateRenewalLoading extends Action {
  type: Types.CREATE_RENEWAL_LOADING;
}

export interface ICreateRenewalLoaded extends Action {
  type: Types.CREATE_RENEWAL_LOADED;
  data: IPolicyRenewal;
}

export interface IUpdateRenewalLoaded extends Action {
  type: Types.UPDATE_RENEWAL_LOADED;
  data: IPolicyRenewal;
}

export interface ICreatePendingRenewal {
  type: Types.CREATE_PENDING_RENEWAL;
  data: IPolicyRenewal;
}

export interface IPolicyRenewalSubmitting {
  type: Types.POLICY_RENEWAL_SUBMITTING;
}

export interface IPolicyRenewalFailed {
  type: Types.POLICY_RENEWAL_FAILED;
}

export interface IDeleteRenewalLoaded {
  type: Types.DELETE_RENEWAL_LOADED;
  data: string;
}

export interface IDeletePendingRenewalLoaded {
  type: Types.DELETE_PENDING_RENEWAL_LOADED;
  data: string;
}

export interface IUpdatePendingRenewalLoaded {
  type: Types.UPDATE_PENDING_RENEWAL_LOADED;
  data: IPolicyRenewal;
}

export interface IResetRenewalSubmit {
  type: Types.RESET_RENEWAL_SUBMIT;
}

export interface IGetPendingRenewalsLoaded {
  type: Types.GET_PENDING_RENEWALS_LOADED;
  data: IPolicyRenewal[];
}

export const GetPendingRenewalsLoaded = (data: IPolicyRenewal[]) =>
  ({
    type: Types.GET_PENDING_RENEWALS_LOADED,
    data,
  } as IGetPendingRenewalsLoaded);

export const SelectPolicyRenewalLoaded = (data: IPolicyRenewal[]) =>
  ({
    type: Types.SELECT_RENEWAL_LOADED,
    data,
  } as ISelectPolicyRenewalLoaded);

export const CreateRenewalLoading = () =>
  ({
    type: Types.CREATE_RENEWAL_LOADING,
  } as ICreateRenewalLoading);

export const CreateRenewalLoaded = (data: IPolicyRenewal) =>
  ({
    type: Types.CREATE_RENEWAL_LOADED,
    data,
  } as ICreateRenewalLoaded);

export const UpdateRenewalLoaded = (data: IPolicyRenewal) =>
  ({
    type: Types.UPDATE_RENEWAL_LOADED,
    data,
  } as IUpdateRenewalLoaded);

export const CreatePendingRenewal = (data: IPolicyRenewal) =>
  ({
    type: Types.CREATE_PENDING_RENEWAL,
    data,
  } as ICreatePendingRenewal);

export const PolicyRenewalSubmitting = () =>
  ({
    type: Types.POLICY_RENEWAL_SUBMITTING,
  } as IPolicyRenewalSubmitting);

export const PolicyRenewalFailed = () =>
  ({
    type: Types.POLICY_RENEWAL_FAILED,
  } as IPolicyRenewalFailed);

export const DeleteRenewalLoaded = (data: string) =>
  ({
    type: Types.DELETE_RENEWAL_LOADED,
    data,
  } as IDeleteRenewalLoaded);

export const DeletePendingRenewalLoaded = (data: string) =>
  ({
    type: Types.DELETE_PENDING_RENEWAL_LOADED,
    data,
  } as IDeletePendingRenewalLoaded);

export const UpdatePendingRenewalLoaded = (data: IPolicyRenewal) =>
  ({
    type: Types.UPDATE_PENDING_RENEWAL_LOADED,
    data,
  } as IUpdatePendingRenewalLoaded);

export const ResetRenewalSubmit = () =>
  ({
    type: Types.RESET_RENEWAL_SUBMIT,
  } as IResetRenewalSubmit);

export const SetRenewalsByPolicy = (policy: IPolicy) => async (dispatch) => {
  if (!policy) {
    dispatch(SelectPolicyRenewalLoaded([]));
  } else {
    const result = await getPolicyRenewalsByPolicyId(policy.id);
    dispatch(SelectPolicyRenewalLoaded(result.data.Items));
  }
};

export const CreatePolicyRenewal =
  (renewal: ICreatePolicyRenewalProps, policy: IPolicy) => async (dispatch) => {
    dispatch(PolicyRenewalSubmitting());
    try {
      renewal.renewalDate = convertToDateOnlyString(renewal.renewalDate);
      const result = await createPolicyRenewal(renewal);
      await setPolicyRenewalAdded(result.data, policy);
      dispatch(CreateRenewalLoaded(result.data));
      history.replace(getRenewalEndRoute(policy));
    } catch {
      ErrorToast.error({ message: CREATE_POLICY_RENEWAL_ERROR });
      dispatch(PolicyRenewalFailed());
    }
  };

export const UpdatePolicyRenewal =
  (renewal: IPolicyRenewal) => async (dispatch) => {
    dispatch(PolicyRenewalSubmitting());
    try {
      const result = await updatePolicyRenewal(renewal);
      dispatch(UpdateRenewalLoaded(result.data));
      history.replace(getRenewalEndRoute(renewal?.newPolicyData));
    } catch {
      ErrorToast.error({ message: CREATE_POLICY_RENEWAL_ERROR });
      dispatch(PolicyRenewalFailed());
    }
  };

export const FinishPendingRenewal =
  (renewal: IPolicyRenewal) => async (dispatch, getState) => {
    dispatch(PolicyRenewalSubmitting());
    try {
      const {
        policies: { policies },
      } = getState();
      const { data: updatedRenewal } = await updatePolicyRenewal(
        R.assoc('finished', true, renewal),
      );
      const policy = getPolicyFromRenewal(policies, updatedRenewal);
      await setPolicyRenewalAdded(updatedRenewal, policy);
      dispatch(CreateRenewalLoaded(updatedRenewal));
      dispatch(DeletePendingRenewalLoaded(updatedRenewal.id));
      history.replace(getRenewalEndRoute(policy));
    } catch {
      ErrorToast.error({ message: CREATE_POLICY_RENEWAL_ERROR });
      dispatch(PolicyRenewalFailed());
    }
  };

export const UpdatePendingRenewal =
  (renewal: IPolicyRenewal) => async (dispatch) => {
    dispatch(PolicyRenewalSubmitting());
    try {
      const result = await updatePolicyRenewal(renewal);
      dispatch(UpdatePendingRenewalLoaded(result.data));
    } catch {
      ErrorToast.error({ message: CREATE_POLICY_RENEWAL_ERROR });
      dispatch(PolicyRenewalFailed());
    }
  };

export const CreatePolicyRenewalWithAttachments =
  (policy: IPolicy, attachments: IFile[]) => async (dispatch) => {
    dispatch(PolicyRenewalSubmitting());
    try {
      const renewal = await createPolicyRenewalWithAttachments(
        policy,
        attachments,
      );
      dispatch(CreatePendingRenewal(renewal));
    } catch {
      ErrorToast.error({ message: UPLOAD_FAILED_MESSAGE });
      dispatch(PolicyRenewalFailed());
    }
  };

export const ResetRenewal = () => (dispatch) => {
  dispatch(ResetRenewalSubmit());
};

export const GetPendingRenewalsByUserId = () => async (dispatch) => {
  const res = await getPendingRenewalsByUserId();
  dispatch(GetPendingRenewalsLoaded(res.data));
};
