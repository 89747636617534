import styles from './policy-types.scss';
import { INSURANCE_TYPES_ICONS } from 'constants/insurance';

const PolicyTypesInline = ({ policies }) => {
  return (
    policies.map((policy) => (
      <div key={policy} className={styles.policy}>
        <img src={INSURANCE_TYPES_ICONS[policy] || INSURANCE_TYPES_ICONS.default} alt={policy} />
        <div className={styles.policyName}>{policy}</div>
      </div>
    ))
  );
};

export default PolicyTypesInline;
