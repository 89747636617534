import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Image } from 'semantic-ui-react';
import { NewLabel } from 'components/labels';
import {
  INSURANCE_TYPES_COLOR_MAP,
  INSURANCE_TYPES_ICONS_V2,
} from 'constants/insurance';
import {
  IAdviserConnectCart,
  IPolicyProvider,
  IPolicyScan,
  POLICY_SCAN_STATUS,
} from 'types';
import styles from './marketScanList.scss';
import AccordionList from 'components/accordion-list';
import {
  checkIsNewItem,
  getFieldValueByPolicyType,
  trackMSViewClick,
  convertToMediumDate,
  getPremiumMarketScanResultsRoute,
  getMarketScanResultsRoute,
  isPremiumMarketScan,
  isPremiumRoute,
} from 'helpers';
import { ChevronDown } from 'react-feather';
import MarketScanStatus from './MarketScanStatus';
import RightArrowBlue from '-!svg-react-loader!icons/layout/right-arrow-blue.svg';
import cx from 'classnames';

interface IMarketScanListProps {
  items: IPolicyScan[];
  policyProviders: IPolicyProvider[];
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  actionMenuComponent?: React.FC<any>;
  statusComponent?: React.FC<any>;
  isPremium?: boolean;
  addedToCart?: IAdviserConnectCart;
  handlePolicyScanNotification?(policyScan: IPolicyScan): void;
  getRoute?(id: string, sk: string): any;
  onClick?(item: any): void;
}

interface IListItemProps {
  item: IPolicyScan;
  policyProviders: IPolicyProvider[];
  actionMenuComponent?: React.FC<any>;
  inCart?: boolean;
  statusComponent?: React.FC<any>;
  getRoute?(id: string, sk: string): string;
  handlePolicyScanNotification?(policyScan: IPolicyScan): void;
  onClick?(item: any): void;
  toggleExpand?(): void;
}

const getProviders = (
  { selectedProvider, brokerPolicyProviderName, quotes = [] },
  policyProviders = [],
  inCart?: boolean,
) => {
  return quotes.reduce((acc, quote, idx) => {
    if (quote.currentProvider) {
      return acc;
    }

    const provider = policyProviders.find(
      R.propEq('name', quote.policyProviderName),
    );

    if (provider) {
      const isSelected =
        inCart ||
        selectedProvider?.policyProviderName === provider.name ||
        brokerPolicyProviderName === provider.name;

      const element = (
        <img
          key={`${idx}_${provider.name}`}
          className={classnames(
            styles.providerImg,
            isSelected && styles.selected,
          )}
          alt={provider.name}
          src={provider.logoUrl}
        />
      );

      // Put selected provider in front of the list
      if (isSelected) {
        return [element, ...acc];
      }

      return [...acc, element];
    }

    return acc;
  }, []);
};

const Header = ({ className, isPremium }) => (
  <div className={classnames(styles.header, className)}>
    <div className={styles.iconColumn} />
    <div className={styles.titleColumn}>Type/name</div>
    <div
      className={classnames(
        styles.providerColumn,
        !isPremium && styles.providerColumnHeader,
      )}
    >
      Providers
    </div>
    <div
      className={classnames(
        styles.dateColumn,
        !isPremium && styles.dateColumnHeader,
      )}
    >
      {isPremium ? 'Date quoted' : 'Date scanned'}
    </div>
    <div className={styles.column}>Status</div>
    <div className={styles.actionColumn} />
  </div>
);

const Body = ({
  inCart,
  item,
  policyProviders,
  actionMenuComponent: ActionMenu,
  statusComponent: StatusComponent,
  toggleExpand,
}: IListItemProps) => {
  const {
    policyType,
    status,
    createdAt,
    selectedProvider,
    brokerPolicyProviderName,
  } = item;
  // Only show new for Ready policy scans
  const isNew =
    checkIsNewItem(item, 'createdAt') && status === POLICY_SCAN_STATUS.READY;
  const borderColor =
    INSURANCE_TYPES_COLOR_MAP[policyType] || INSURANCE_TYPES_COLOR_MAP.default;
  const insured = getFieldValueByPolicyType(item);
  const showProviders =
    status === POLICY_SCAN_STATUS.READY || status === POLICY_SCAN_STATUS.VIEWED;
  const hasSelected = selectedProvider || brokerPolicyProviderName;
  const toolTip =
    'Your result is ready to view. Click to view, select and purchase your policy now';

  return (
    <>
      <div className={styles.border} style={{ background: borderColor }} />
      <div className={styles.iconColumn}>
        <Image
          className={styles.icon}
          src={INSURANCE_TYPES_ICONS_V2[policyType]}
          size="mini"
        />
      </div>
      <div className={styles.titleColumn}>
        <div className={styles.title}>
          {policyType}
          {isNew && <NewLabel className={styles.new} />}
        </div>
        <div className={classnames(styles.subtitle)}>{insured}</div>
      </div>
      <div
        className={classnames(
          styles.providerColumn,
          hasSelected && styles.hasSelected,
        )}
      >
        {showProviders && (
          <>
            <div className={styles.label}>Providers</div>
            {getProviders(item, policyProviders)}
          </>
        )}
      </div>
      <div className={styles.dateColumn}>
        <div className={styles.label}>Date scanned</div>
        {convertToMediumDate(createdAt)}
      </div>
      <div className={styles.statusColumn}>
        <div className={styles.label}>Status</div>
        {StatusComponent ? (
          <StatusComponent policyScan={item} />
        ) : (
          <MarketScanStatus
            inCart={inCart}
            policyScan={item}
            toolTip={toolTip}
          />
        )}
      </div>
      {toggleExpand && (
        <div className={styles.mobileActionColumn}>
          <ChevronDown onClick={toggleExpand} />
        </div>
      )}
      <div className={styles.actionColumn}>
        {ActionMenu ? (
          <ActionMenu policyScan={item} />
        ) : (
          <Icon className={styles.chevronRight} name="chevron right" />
        )}
        <RightArrowBlue className={styles.rightArrow} />
      </div>
    </>
  );
};

const TitleComponent = ({
  addedToCart,
  item,
  policyProviders,
  actionMenuComponent,
  statusComponent,
  getRoute,
  onClick,
  toggleExpand,
}: IListItemProps) => {
  const { id, sk, status } = item;
  const inCart = !!addedToCart?.[id];

  const showResults =
    status &&
    status !== POLICY_SCAN_STATUS.PENDING &&
    status !== POLICY_SCAN_STATUS.IN_REVIEW;

  const body = (
    <Body
      item={item}
      inCart={inCart}
      actionMenuComponent={actionMenuComponent}
      statusComponent={statusComponent}
      policyProviders={policyProviders}
      toggleExpand={toggleExpand}
    />
  );

  const handleClick = () => {
    if (onClick) {
      onClick(item);
    } else {
      trackMSViewClick(item);
    }
  };

  return showResults ? (
    <Link
      to={
        isPremiumMarketScan(item)
          ? getPremiumMarketScanResultsRoute(id, sk)
          : getMarketScanResultsRoute(id, sk)
      }
      className={cx(
        styles.card,
        isPremiumMarketScan(item) && !inCart && styles.premiumCard,
        !item.policyTypeId && styles.zIndexZero,
      )}
      onClick={handleClick}
    >
      {body}
    </Link>
  ) : (
    <div
      onClick={handleClick}
      className={classnames(styles.card, styles.noPointer)}
    >
      {body}
    </div>
  );
};

const ContentComponent = ({
  item,
  policyProviders,
  actionMenuComponent,
  statusComponent,
}) => {
  return (
    <div className={styles.card}>
      <Body
        item={item}
        policyProviders={policyProviders}
        actionMenuComponent={actionMenuComponent}
        statusComponent={statusComponent}
      />
    </div>
  );
};

const MarketScanList = ({
  children,
  items,
  className,
  headerClassName,
  titleClassName,
  contentClassName,
  isPremium,
  ...otherProps
}: IMarketScanListProps) => {
  return (
    <div>
      <Header className={headerClassName} isPremium={isPremium} />
      {children}
      <AccordionList
        className={classnames(styles.accordion, className)}
        componentProps={otherProps}
        items={items}
        titleIcon="chevron down"
        contentClassName={classnames(styles.content, contentClassName)}
        contentComponent={ContentComponent}
        titleClassName={classnames(styles.titleComponent, titleClassName)}
        titleComponent={TitleComponent}
        titleClickToExpand={false}
      />
    </div>
  );
};

export default MarketScanList;
