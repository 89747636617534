import DatePicker from 'components/date-picker';
import React, { ReactNode } from 'react';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/DateInput.module.scss';

interface IProps extends IFormFieldProps {
  max?: Date | 'TODAY';
  min?: Date | 'TODAY';
}

const DateInput = ({
  basic,
  label,
  className,
  inputClassName,
  labelClassName,
  placeholder,
  rightIcon,
  error,
  name,
  max,
  min,
  value,
  scrollIntoView,
  onChange,
}: IProps) => {
  const dateMax = max === 'TODAY' ? new Date() : max;
  const dateMin = min === 'TODAY' ? new Date() : min;

  return (
    <InputWrap
      className={className}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      error={error}
      label={label}
      labelClassName={labelClassName}
      name={name}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
    >
      <DatePicker
        className={classnames(
          styles.date,
          basic && styles.basic,
          inputClassName,
        )}
        wrapClassName={styles.dateWrap}
        containerClassName={styles.dateContainer}
        onChange={onChange}
        name={name}
        value={value}
        max={dateMax}
        min={dateMin}
        placeholder={placeholder || ''}
      />
    </InputWrap>
  );
};

export default React.forwardRef(DateInput);
