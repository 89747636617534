// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3P8c9H51xdgQrB5fPvd5TW.ui.modal > i.icon{position:absolute;cursor:pointer;top:25px;right:20px;font-size:16px;color:#5a5f62}._3P8c9H51xdgQrB5fPvd5TW.ui.modal > .content{display:flex;padding:40px 50px}.gLym2dfkDE4fm195vRzT2{font-family:\"Open Sans\";font-weight:400;color:#353b50;font-size:21px}._1JCvYDxIqK9-cpRTXBvPHH{font-family:\"Open Sans\";font-weight:400;font-size:12px;color:#7e8395;margin-bottom:10px}._1QnSx5BRNxVR7n8wGN_5Z6{font-family:\"Open Sans\";font-weight:400;font-size:16px;color:#757272}", ""]);
// Exports
exports.locals = {
	"modal": "_3P8c9H51xdgQrB5fPvd5TW",
	"title": "gLym2dfkDE4fm195vRzT2",
	"details": "_1JCvYDxIqK9-cpRTXBvPHH",
	"bio": "_1QnSx5BRNxVR7n8wGN_5Z6"
};
module.exports = exports;
