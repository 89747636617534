import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/layoutContainer.scss';
import SideBar from 'components/sidebar';
import NavBar from 'components/navbar';
import { IAppState } from 'types';
import { delay } from 'helpers';
import { NavTitle } from 'components/navbar/NavBar';
import { ToggleSidebar } from 'actions';
import { useLocation } from 'react-router-dom';
import { MARKET_SCAN_RESULTS_ROUTE } from 'constants/routes';

interface IProps {
  children;
}

interface IDispatchProps {
  toggleSidebar(expanded: boolean): void;
}

const LayoutContainer = ({ children }: IAppState & IDispatchProps & IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Show/hide mobile sidebar
  const [openMobile, setMobile] = useState(false);

  const {
    adviser,
    layout: { expanded, maximised },
    user,
  } = useSelector<IAppState, IAppState>(R.pick(['adviser', 'layout', 'user']));

  const openSidebar = () => dispatch(ToggleSidebar(true));
  const closeSidebar = () => dispatch(ToggleSidebar(false));

  const openMobileSidebar = () => setMobile(true);
  const closeMobileSidebar = async () => {
    await delay(100);
    setMobile(false);
  };

  const showNavTitle = !location.pathname.includes(MARKET_SCAN_RESULTS_ROUTE);

  return (
    <div
      id="layout-container"
      className={classnames(
        styles.container,
        expanded && 'quashed-sidebar-open',
      )}
    >
      {openMobile && <div className={styles.overlay} />}
      <SideBar
        adviser={adviser}
        user={user}
        small={!expanded}
        mobile={openMobile}
        maximised={maximised}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        closeMobileSidebar={closeMobileSidebar}
      />
      <div className={classnames(styles.right, maximised && styles.maximised)}>
        <NavBar
          maximised={maximised}
          user={user}
          openMobileSidebar={openMobileSidebar}
        />
        <div id="main" className={styles.children}>
          {showNavTitle && <NavTitle className={styles.navTitle} />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutContainer;
