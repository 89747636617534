import React, { Ref, useEffect, useImperativeHandle, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FieldTypeMap, TextInput } from 'components/form-fields-v2';
import styles from './QuashedForm.module.scss';
import Button from 'components/button';
import { IFormQuestion } from 'types';
import { getValidator } from 'components/form-engine/validation';
import { getQuestionsToDisplay } from 'helpers';
import Back from 'components/back';

interface IProps {
  className?: string;
  buttonClassName?: string;
  submitText?: string;
  defaultValues?: any;
  disableSubmit?: boolean;
  title?: string;
  questions: IFormQuestion[];
  getComponentProps?(question: IFormQuestion, values: any): any;
  onBack?(): void;
  onSubmit(data: any): void;
}

const QuashedForm = (
  {
    className,
    buttonClassName,
    submitText = 'Submit',
    defaultValues,
    disableSubmit,
    questions,
    title,
    getComponentProps = R.F,
    onBack,
    onSubmit,
  }: IProps,
  ref: Ref<any>,
) => {
  const form = useForm({
    defaultValues,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = form;

  useImperativeHandle(ref, () => form);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const formValues = watch();
  const questionsToDisplay = getQuestionsToDisplay(questions, formValues);

  return (
    <form
      className={classnames(styles.container, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.content}>
        <h3>{title}</h3>
        {questionsToDisplay.map((question) => {
          const { asterisk, key, type, required, ...q } = question;
          const Component = FieldTypeMap[type] || TextInput;
          const error = errors[key];
          const validator = getValidator(type, question, formValues);

          return (
            <Controller
              key={key}
              control={control}
              name={key}
              rules={{
                required,
                validate: validator,
              }}
              render={({ field }) => {
                return (
                  <Component
                    {...field}
                    {...q}
                    {...getComponentProps(question, formValues)}
                    error={error?.message || !!error}
                    errorText={error?.message}
                    className={styles.field}
                    labelClassName={classnames(
                      styles.label,
                      asterisk && styles.asterisk,
                    )}
                    inputClassName={styles.input}
                    basic={true}
                  />
                );
              }}
            />
          );
        })}
      </div>
      <div className={classnames(styles.buttons, buttonClassName)}>
        {onBack && <Back className={styles.back} handleBack={onBack} />}
        <Button
          className={styles.submit}
          type="submit"
          text={submitText}
          disabled={disableSubmit}
        />
      </div>
    </form>
  );
};

export default React.forwardRef(QuashedForm);
