import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { Button } from 'components/quashed';
import styles from './AskQuestion.module.scss';
import { CreateAdviserInquiry } from 'actions';
import LogoSmall from '-!svg-react-loader!images/logo-small.svg';
import QDancing from '-!svg-react-loader!images/q/q-dancing.svg';
import { ChevronDown, ChevronUp } from 'react-feather';

interface IProps {
  className?: string;
}

const MAX_LENGTH = 360;

const defaultValues = { message: '' };

const submittedPage = (
  <div className={styles.submitted}>
    <QDancing />
    <h3>
      Great, all sent!
      <br />
      Expect a response in your emails soon.
    </h3>
  </div>
);

const AskQuestion = ({ className }: IProps) => {
  const dispatch = useDispatch();
  const [{ show, submitted }, setState] = useState({
    submitted: false,
    show: false,
  });
  const { handleSubmit, register, reset, watch } = useForm({ defaultValues });
  const message = watch('message');
  const charRemaining = message ? MAX_LENGTH - message.length : MAX_LENGTH;

  const submit = (data) => {
    setState(R.assoc('submitted', true));
    dispatch(CreateAdviserInquiry(data.message));
    setTimeout(() => {
      reset();
      setState(R.assoc('submitted', false));
    }, 3000);
  };

  const toggleShowContent = () => setState(R.evolve({ show: R.not }));

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.header} onClick={toggleShowContent}>
        <LogoSmall className={styles.logo} />
        <div className={styles.headerText}>
          <b>
            Message Broker{' '}
            {show ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
          </b>
          Questions? We’re here to help.
        </div>
      </div>
      <div className={classnames(styles.content, show && styles.show)}>
        {submitted ? (
          submittedPage
        ) : (
          <form className={styles.form} onSubmit={handleSubmit(submit)}>
            <div className={styles.inputContainer}>
              <textarea
                placeholder="Enter message"
                {...register('message', {
                  maxLength: MAX_LENGTH,
                  required: true,
                })}
                className={styles.input}
                rows={2}
                maxLength={MAX_LENGTH}
                required={true}
              />
              <span className={charRemaining <= 0 ? styles.coral : ''}>
                ({message.length}/{MAX_LENGTH} characters)
              </span>
            </div>
            <Button
              text="Send"
              className={styles.blueBtn}
              disabled={submitted}
              type="submit"
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default AskQuestion;
