import React from 'react';
import AddressSearch from 'components/address-search';
import InputWrap from './InputWrap';
import styles from './styles/TextInput.module.scss';
import { IFormFieldProps } from './common';
import Button from 'components/button';
import { WarningMessage } from 'components/form-message';
import { TRACKING_EVENTS } from 'helpers';

interface IProps {
  full?: boolean;
  formValues;
  onSelect?(event: { target: any; type?: any }): void;
  onButtonClick(): void;
  toggleAddress(): void;
}

const AddressInput = ({
  label,
  className,
  inputClassName,
  labelClassName,
  rightIcon,
  error,
  disabled,
  value,
  placeholder,
  name,
  basic,
  full,
  formValues,
  scrollIntoView,
  showButton,
  buttonText = 'Search',
  warning,
  onButtonClick,
  onChange,
  onSelect,
  toggleAddress,
}: IProps & IFormFieldProps) => {
  return (
    <InputWrap
      className={classnames(
        'address',
        formValues?.showFullAddress && 'address-full',
        className,
        full && `full ${styles.full}`,
      )}
      errorClassName={classnames(styles.error, basic && styles.basicError)}
      errorLabelClassName={full && styles.addressErrorLabel}
      error={error}
      label={label}
      labelClassName={labelClassName}
      name={name}
      rightIcon={rightIcon}
      scrollIntoView={scrollIntoView}
    >
      <div className={styles.inputContainer}>
        <AddressSearch
          className={classnames(
            styles.input,
            basic && styles.basic,
            inputClassName,
          )}
          onChange={onChange}
          onSelect={onSelect}
          disabled={disabled}
          value={value}
          placeholder={placeholder || ''}
          name={name}
          autocomplete="off"
          autoFocus={!!value && full}
        />
        {full && (
          <span className={styles.addressToggle} onClick={toggleAddress}>
            {formValues.showFullAddress ? 'Cancel' : 'Enter address manually'}
          </span>
        )}
      </div>
      {warning && (
        <WarningMessage
          eventName={TRACKING_EVENTS.MarketScanRiskProperty}
          eventData={{ name }}
          text={warning}
          className={classnames('warningText', styles.warning)}
        />
      )}
      {showButton && (
        <Button
          className={styles.button}
          onClick={onButtonClick}
          text={buttonText}
          disabled={error}
        />
      )}
    </InputWrap>
  );
};

export default AddressInput;
