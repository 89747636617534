// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".rI0nVJ1rbZ9CmALC4JlXB.ui.modal{max-width:500px;min-height:250px;border-radius:5px;position:relative}.rI0nVJ1rbZ9CmALC4JlXB.ui.modal .content{display:flex;color:#34363e;padding:40px !important;border-radius:5px;background-color:#fff;height:100%}._2Z_FOdigSe0rIMAWS13IaG{position:absolute;cursor:pointer;right:16px;top:16px}._2bbThim4aB_WtERLNzF995{display:flex;flex-direction:column;width:100%;gap:12px}._2JDbOv5ZhfoOGzTuDXlWod{margin-top:16px;color:#efbb43;margin-bottom:-12px}._1Ktux4DIVKQg3QB3mm9xmF{font-family:\"DM Sans\";font-weight:700;font-size:20px}._3wZnRY7xDGxf3DtqPBWJBx{width:100%}.BqUx-Hj_Z5iw7M_h8SEsH{font-family:\"Open Sans\";font-weight:400;font-size:14px}._1v-ct8iNXPY5doF2Cq18Bw.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;max-width:305px;width:100%;align-self:center}@media(max-width: 576px){._1v-ct8iNXPY5doF2Cq18Bw.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 576px){.rI0nVJ1rbZ9CmALC4JlXB.ui.modal{max-width:100%;width:100%}.rI0nVJ1rbZ9CmALC4JlXB.ui.modal .content{padding:24px !important}._1xcmb1MRuOZFQ-bEI0sNn0.ui.dimmer{padding:0 !important}}", ""]);
// Exports
exports.locals = {
	"modal": "rI0nVJ1rbZ9CmALC4JlXB",
	"close": "_2Z_FOdigSe0rIMAWS13IaG",
	"container": "_2bbThim4aB_WtERLNzF995",
	"newTag": "_2JDbOv5ZhfoOGzTuDXlWod",
	"title": "_1Ktux4DIVKQg3QB3mm9xmF",
	"img": "_3wZnRY7xDGxf3DtqPBWJBx",
	"description": "BqUx-Hj_Z5iw7M_h8SEsH",
	"button": "_1v-ct8iNXPY5doF2Cq18Bw",
	"dimmer": "_1xcmb1MRuOZFQ-bEI0sNn0"
};
module.exports = exports;
