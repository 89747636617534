import React from 'react';
import { useSelector } from 'react-redux';
import styles from './typeForm.scss';
import { FULL_ADDRESS_OTHER_QUESTIONS } from 'constants/questions';
import { isRiskZoneProperty } from 'helpers';
import TypeFormQuestion, { ITypeFormQuestion } from './TypeFormQuestion';

const TypeFormAddress = ({
  field,
  questions,
  form,
  ...fieldProps
}: ITypeFormQuestion) => {
  const config = useSelector(R.prop('config'));
  const addressQuestions = [field, ...FULL_ADDRESS_OTHER_QUESTIONS];

  const addressForm = {
    ...form,
    handleInputChange: (event) => {
      if (event.persist) {
        event.persist();
      }
      const { name, value } = event.target;
      form.setValues((val) => {
        const updated = {
          ...val,
          address: '',
          isAddressValid: false,
          lat: undefined,
          lng: undefined,
          [name]: value,
        };
        updated.riskZoneProperty = isRiskZoneProperty(updated, config);
        return updated;
      });
    },
  };

  const items = addressQuestions.map((q) => {
    return (
      <TypeFormQuestion
        key={q.key}
        field={q}
        questions={addressQuestions}
        form={addressForm}
        {...fieldProps}
        fieldClassName={classnames(
          styles.addressQ,
          q.fullWidth && styles.fullWidth,
          fieldProps.fieldClassName,
        )}
      />
    );
  });
  return <>{items}</>;
};

export default TypeFormAddress;
