import { getHeaderOptions, getHttpService } from './http-service';
import { getClaimsToken, removeEmptyValues } from '../helpers';
import {
  ICreatePolicyProps,
  IPolicy,
  IPolicyProvider,
  IPolicySubType,
  IPolicyType,
} from 'types';
import { batchUploadAttachments } from './attachment-service';

const POLICY_PROPS_TO_IGNORE = ['attachments'];

const httpClient = getHttpService();

export const getAllPolicies = (
  status?: string,
  policyType?: string,
  caStart?: string,
  caEnd?: string,
  rdStart?: string,
  rdEnd?: string,
) => {
  return httpClient.get('/policy', {
    headers: getHeaderOptions(),
    params: { status, policyType, caStart, caEnd, rdStart, rdEnd },
  });
};

export const getAllPolicyTypes = () => {
  return httpClient.get('/policytype', {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicySubTypes = () => {
  return httpClient.get('/policysubtype', {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicyTypesByProviderId = (providerId) => {
  return httpClient.get(`/policytype/policyproviderid/${providerId}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPolicySubTypesByPolicyTypeId = (policyTypeId) => {
  return httpClient.get(`/policysubtype/policytype/${policyTypeId}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPoliciesByUserId = (userId?: string) => {
  const claims = getClaimsToken();
  const id = encodeURIComponent(userId || `user:${claims.sub}`);
  return httpClient.get(`/policy/userId/${id}`, {
    headers: getHeaderOptions(),
  });
};

export const getAllPoliciesByFamilyId = (
  familyId: string,
  getAll?: boolean,
) => {
  const id = encodeURIComponent(familyId);
  return httpClient.get(
    `/policy/familyId/${id}?time=${Date.now()}${getAll ? '&getAll=true' : ''}`,
    {
      headers: getHeaderOptions(),
    },
  );
};
export const getPolicyById = (id: string, familyId?: string) => {
  const sk = encodeURIComponent(familyId);

  return httpClient.get(`/policy/${id}/${sk}`, {
    headers: getHeaderOptions(),
  });
};

export const createPolicy = (policy: ICreatePolicyProps) => {
  const claims = getClaimsToken();
  if (!policy.userId) {
    policy.userId = `user:${claims.sub}`;
  }
  const sanitized = removeEmptyValues(policy);
  return httpClient.post(`/policy`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export const updatePolicy = (policy: IPolicy) => {
  const claims = getClaimsToken();
  if (!policy.userId) {
    policy.userId = `user:${claims.sub}`;
  }
  const sanitized = removeEmptyValues(R.omit(POLICY_PROPS_TO_IGNORE, policy));
  return httpClient.put(`/policy/${policy.id}`, sanitized, {
    headers: getHeaderOptions(),
  });
};

export interface ICreatePolicySubTypeProps {
  name: string;
  [key: string]: any;
}

export const createPolicySubType = (data: ICreatePolicySubTypeProps) => {
  return httpClient.post(`/policysubtype`, data, {
    headers: getHeaderOptions(),
  });
};

export const updatePolicySubType = (data: ICreatePolicySubTypeProps) => {
  return httpClient.put(`/policysubtype/${data.id}`, data, {
    headers: getHeaderOptions(),
  });
};

export const deletePolicy = (id: string, userId?: string) => {
  const claims = getClaimsToken();
  const ownerId = encodeURIComponent(userId || `user:${claims.sub}`);
  return httpClient.delete(`/policy/${id}/${ownerId}`, {
    headers: getHeaderOptions(),
  });
};

export interface ICreatePolicyTypeProps {
  name: string;
  [key: string]: any;
}

export const createPolicyType = (data: ICreatePolicyTypeProps) => {
  return httpClient.post(`/policytype`, data, {
    headers: getHeaderOptions(),
  });
};

export const createPolicyWithAttachments = async (policy, attachments) => {
  const { data: newPolicy }: { data: IPolicy } = await createPolicy({
    ...policy,
    effective: true,
    isUpload: true,
  });

  try {
    await batchUploadAttachments(attachments, newPolicy.id);
  } catch (ex) {
    await deletePolicy(newPolicy.id);
    // Throw again so caller can handle error
    throw ex;
  }

  return newPolicy;
};

export const updatePolicyType = (data: IPolicyType) => {
  return httpClient.put(`/policytype/${data.id}`, data, {
    headers: getHeaderOptions(),
  });
};

export interface ICreateProviderPolicyMappingProps {
  id: string;
  policyProviderName: string;
  sk: string;
  policyTypeName: string;
  policySubTypes?: IPolicySubType[];
}

export const createProviderPolicyMapping = (
  data: ICreateProviderPolicyMappingProps,
) => {
  return httpClient.post(`/policytype/providertypemapping`, data, {
    headers: getHeaderOptions(),
  });
};

export const updateProviderPolicyMapping = (
  data: ICreateProviderPolicyMappingProps,
) => {
  return httpClient.put(`/policytype/${data.sk}`, data, {
    headers: getHeaderOptions(),
  });
};

export interface ICreatePolicyTypeSubTypeMappingProps {
  id: string; // policyTypeId
  policyTypeName: string;
  sk: string; // policySubTypeId
  policySubTypeName: string;
}

export const createPolicyTypeSubTypeMapping = (
  data: ICreatePolicyTypeSubTypeMappingProps,
) => {
  return httpClient.post(`/policysubtype/typesubtypemapping`, data, {
    headers: getHeaderOptions(),
  });
};

export const deletePolicyTypeSubType = (policySubTypeId: string) => {
  return httpClient.delete(`/policysubtype/${policySubTypeId}`, {
    headers: getHeaderOptions(),
  });
};

export const deleteProviderPolicyTypeMapping = (
  id: string,
  providerId: string,
) => {
  return httpClient.delete(`/policytype/${id}/${providerId}`, {
    headers: getHeaderOptions(),
  });
};

export const updatePolicyProvider = (data: IPolicyProvider) => {
  return httpClient.put(`/policyprovider/${data.id}`, data, {
    headers: getHeaderOptions(),
  });
};
