import { useEffect, useRef } from 'react';

export const DefaultScrollConfig = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'start',
};

const useScrollOnMount = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(DefaultScrollConfig);
    }
  }, []);

  return ref;
};

export default useScrollOnMount;
