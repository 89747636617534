import { Action } from 'redux';
import {
  ISelectPolicyProvider,
  IGetPolicyTypesByProviderLoaded,
  ICreateProviderPolicyMappingLoaded,
  ICreateProviderPolicyMappingLoading,
  IDeleteProviderPolicyTypeMappingLoaded,
  ISelectPolicyType,
  IGetPolicySubTypeByPolicyTypeLoaded,
  ICreatePolicyTypeSubTypeMappingLoaded,
  IDeletePolicyTypeSubTypeMappingLoaded,
  IUpdatePolicyProviderLoading,
  IUpdatePolicyProviderLoaded,
  IGetUsersLoaded,
  ISelectUser,
  IGetAttachmentForPolicyLoaded,
  IGetRenewalForPolicyLoaded,
  IAdminDeleteUserByIdLoaded,
  IUpdateProviderPolicyMappingLoaded,
  ISelectPolicySubType,
  IUpdateSelectedPolicySubType,
  IAdminGetUserLoaded,
  IAdminUpdateUserLoaded,
  IAdminGetUserPolicies,
  IAdminGetUserInvitationsLoaded,
  IAdminGetUserSentInvitationsLoaded,
  IAdminGetUserPolicyScansLoaded,
  IAdminLoading,
} from './admin-actions';
import {
  ILoading,
  ILoaded,
  IFailed,
  IPolicyScansLoaded,
  IPolicyScanLoaded,
  IPolicyScanLocalUpdated,
  IAddPolicyModelOpen,
  IUserBundlesLoaded,
  IFeedbackLoaded,
  IFilteredPoliciesLoaded,
  IReferralLoaded,
  IOperationGetAdviserConnectLoaded,
  IOperationGetSelectedAdviserConnectLoaded,
  IEmailNotificationLoaded,
  IOperationGetMarketScanFeedbacks,
  IPolicyScanPayloadLoaded,
  IOperationUpdateAdviserConnectPolicyScans,
  IOperationUpdateAdviserConnectStatus,
  IOperationGetAdviserConnectsForUserLoaded,
} from './operation-actions';
import {
  IUpdateSelectedPolicyLocal,
  INewSelectedPolicyLocal,
  IAdminUpdatePolicyLoaded,
  IUpdateSelectedPolicyRenewLocal,
} from './operation-policy-actions';
import {
  IGetUserByIdLoaded,
  IGetUserByIdLoading,
  IUpdateUserLoaded,
  ISetCurrentFamily,
  IGetUserByIdFailed,
  IUpdateUserLoading,
} from './user';
import {
  ICreatePolicyLoaded,
  IGetUserPoliciesLoading,
  IGetUserPoliciesLoaded,
  IDeletePolicyLoaded,
  IUpdatePolicyLoaded,
  IResetPolicyForm,
  ICreatePolicyLoading,
  ISelectPolicy,
  IGetPolicyScanByPolicyIdLoaded,
  ICreatePolicyScanLoaded,
  IGetPendingPolicyScanLoaded,
  ICreatePolicyFailed,
  IGetPolicyScanLoaded,
  IUpdatePolicyScanLoaded,
  ICreatePoliciesLoaded,
  IDeletePoliciesLoaded,
  ICreatePolicyBundleLoaded,
  IGetPolicyBundlesLoaded,
  IDeletePolicyBundleLoaded,
  IUpdatePolicyLoading,
  ICreatePolicyScanLoading,
  ICreatePolicyScanFailed,
  ICreatePolicyScanWithoutPolicyLoaded,
} from './policy';
import {
  IGetAllAdvisersLoaded,
  IGetAdviserConnectsLoaded,
  ICreateAdviserConnectLoaded,
  IAdviserSubmitting,
  ICreateClientLoaded,
  IAdviserResetForm,
  IGetClientsLoaded,
  ISelectClientLoaded,
  IUpdateClientLoaded,
  ICreateClientPolicyFailed,
  ICreateClientPolicyLoaded,
  IUpdateClientPolicyLoaded,
  ICreateClientPolicyLoading,
  IDeleteClientPolicyLoaded,
  IDeleteClientLoaded,
  ICalendlyEventCreated,
  IAdviserConnectInfoUpdated,
  ICalendarLoading,
  IGetConsentsLoaded,
  ICreateConsentsLoaded,
  IDeleteConsentLoaded,
  ICreateConsentsFailed,
  IUpdateConsentLoaded,
  IAdviserInquiryLoaded,
  IAdviserLoading,
  IAdviserFailed,
  IUpdateAdviserConnectLoaded,
  IGetAdviserConnectLoaded,
  IAddToCartLoaded,
} from './adviser-actions';

import {
  IGetInvitationsLoaded,
  IInvitationLoading,
  IProcessInvitationLoaded,
  IDeleteInvitationLoaded,
  ICreateInvitationLoaded,
  IShowCreateInvitationForm,
  IInvitationFailed,
  IClearInvitationErrors,
  IGetSharedInvitationsLoaded,
  IUpdateInvitationLoaded,
} from './invitation-actions';
import { IConfig, IAppState } from 'types';
import { getConfig } from '../../services';
import {
  IGetReferrals,
  ICreateReferral,
  IUpdateReferral,
  IToggleAdviserReferral,
} from './referral-actions';
import {
  ICreatePaymentIntentError,
  ICreatePaymentIntentLoaded,
  IPaymentLoading,
  IVerifyWindcavePaymentLoaded,
  IVerifyWindcavePaymentFailed,
} from './payment-actions';
import {
  ICreateCreditCardPolicyLoaded,
  ICreditCardResetForm,
  ICreditCardSubmitting,
  IDeleteCreditCardPolicyLoaded,
  IGetCreditCardPoliciesLoaded,
} from './credit-card-actions';
import {
  ICreateRenewalLoaded,
  ICreateRenewalLoading,
  ICreatePendingRenewal,
  IDeletePendingRenewalLoaded,
  IDeleteRenewalLoaded,
  IPolicyRenewalFailed,
  IPolicyRenewalSubmitting,
  ISelectPolicyRenewalLoaded,
  IUpdateRenewalLoaded,
  IUpdatePendingRenewalLoaded,
  IResetRenewalSubmit,
  IGetPendingRenewalsLoaded,
} from './policy-renewal-actions';
import {
  IOperationPolicyRenewalDetailsloaded,
  IOperationUpdatePolicyRenewal,
} from './operation-policy-renewal-actions';
import {
  GetAllPolicyTypes,
  GetAllPolicyProvider,
  GetCreditCardBenefitsData,
  GetEmployers,
  GetWiki,
  GetPolicyScanReference,
  GetInsuranceCheckupReference,
  IGetCreditCardBenefitsDataLoaded,
  IGetAllPolicyProvidersLoaded,
  IGetAllPolicyTypesLoaded,
  IGetAllPolicySubTypesLoaded,
  IGetEmployersLoaded,
  IGetWikiLoaded,
  IGetPolicyScanReferenceLoaded,
  IInsuranceCheckupReferenceLoaded,
  ICeatePolicyTypeLoaded,
  ICreatePolicySubTypeLoaded,
  GetFormDefinitionReference,
} from './referencedata-actions';
import {
  IDeletePolicyNotificationsLoaded,
  IGetNotificationsLoaded,
  INotificationsLoading,
  ISeenNotificationsLoaded,
  IToggleAppBanner,
  IUpdateNotificationLoaded,
} from './notification-actions';

export * from './admin-actions';
export * from './user';
export * from './policy';
export * from './invitation-actions';
export * from './adviser-actions';
export * from './operation-actions';
export * from './operation-policy-actions';
export * from './credit-card-actions';
export * from './referral-actions';
export * from './policy-renewal-actions';
export * from './referencedata-actions';
export * from './notification-actions';
export * from './layout-actions';

export enum Types {
  SIGN_OUT = 'SIGN_OUT',

  ADMIN_LOADING = 'ADMIN_LOADING',

  UPDATE_POLICY_PROVIDER_LOADING = 'UPDATE_POLICY_PROVIDER_LOADING',
  UPDATE_POLICY_PROVIDER_LOADED = 'UPDATE_POLICY_PROVIDER_LOADED',

  GET_ATTACHMENT_FOR_POLICY_LOADED = 'GET_ATTACHMENT_FOR_POLICY_LOADED',
  GET_RENEWAL_FOR_POLICY_LOADED = 'GET_RENEWAL_FOR_POLICY_LOADED',

  GET_USERS_LOADED = 'GET_USERS_LOADED',

  SELECT_POLICY_PROVIDER = 'SELECT_POLICY_PROVIDER',
  SELECT_POLICY_TYPE = 'SELECT_POLICY_TYPE',
  SELECT_USER = 'SELECT_USER',
  SELECT_POLICY_SUBTYPE = 'SELECT_POLICY_SUBTYPE',
  UPDATE_SELECTED_POLICY_SUBTYPE = 'UPDATE_SELECTED_POLICY_SUBTYPE',

  GET_POLICY_TYPES_BY_PROVIDER_LOADING = 'GET_POLICY_TYPES_BY_PROVIDER_LOADING',
  GET_POLICY_TYPES_BY_PROVIDER_LOADED = 'GET_POLICY_TYPES_BY_PROVIDER_LOADED',
  GET_POLICY_TYPES_BY_PROVIDER_FAILED = 'GET_POLICY_TYPES_BY_PROVIDER_FAILED',

  GET_POLICY_SUB_TYPES_BY_POLICY_TYPE_LOADED = 'GET_POLICY_SUB_TYPES_BY_POLICY_TYPE_LOADED',

  CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING = 'CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING',
  CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED = 'CREATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED',
  CREATE_PROVIDER_POLICY_TYPE_MAPPING_FAILED = 'CREATE_PROVIDER_POLICY_TYPE_MAPPING_FAILED',

  DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADING = 'DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADING',
  DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADED = 'DELETE_PROVIDER_POLICY_TYPE_MAPPING_LOADED',
  DELETE_PROVIDER_POLICY_TYPE_MAPPING_FAILED = 'DELETE_PROVIDER_POLICY_TYPE_MAPPING_FAILED',

  UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING = 'UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADING',
  UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED = 'UPDATE_PROVIDER_POLICY_TYPE_MAPPING_LOADED',
  UPDATE_PROVIDER_POLICY_TYPE_MAPPING_FAILED = 'UPDATE_PROVIDER_POLICY_TYPE_MAPPING_FAILED',

  CREATE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED = 'CREATE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED',
  DELETE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED = 'DELETE_POLICY_TYPE_SUB_TYPE_MAPPING_LOADED',
  ADMIN_DELETE_USER_BY_ID_LOADED = 'ADMIN_DELETE_USER_BY_ID_LOADED',
  ADMIN_GET_USER_BY_ID_LOADED = 'ADMIN_GET_USER_BY_ID_LOADED',
  ADMIN_UPDATE_USER_LOADED = 'ADMIN_UPDATE_USER_LOADED',
  ADMIN_GET_USER_POLICIES = 'ADMIN_GET_USER_POLICIES',
  ADMIN_GET_USER_INVITATIONS_LOADED = 'ADMIN_GET_USER_INVITATION_LOADED',
  ADMIN_GET_USER_SENT_INVITATIONS_LOADED = 'ADMIN_GET_USER_SET_INVITATIONS_LOADED',
  ADMIN_GET_USER_POLICY_SCANS_LOADED = 'ADMIN_GET_USER_POLICY_SCANS_LOADED',

  GET_CONFIG_LOADED = 'GET_CONFIG_LOADED',
}

export interface IGetConfigLoaded extends Action {
  type: Types.GET_CONFIG_LOADED;
  data: IConfig;
}

export const GetConfigLoaded = (data: IConfig) => {
  return {
    type: Types.GET_CONFIG_LOADED,
    data,
  } as IGetConfigLoaded;
};

export const GetConfigAction = () => (dispatch: any) => {
  return getConfig().then((res) => {
    dispatch(GetConfigLoaded(res.data));
  });
};

export interface ISignOutAction extends Action {
  type: Types.SIGN_OUT;
}

export const SignOutAction = () => {
  return {
    type: Types.SIGN_OUT,
  } as ISignOutAction;
};

const initActions = [
  GetAllPolicyTypes,
  GetAllPolicyProvider,
  GetCreditCardBenefitsData,
  GetEmployers,
  GetWiki,
  GetPolicyScanReference,
  GetInsuranceCheckupReference,
  GetFormDefinitionReference,
];

export const InitApp = () => async (dispatch, getState) => {
  const { config }: IAppState = getState();
  if (!config) {
    await GetConfigAction()(dispatch);
  }

  initActions.forEach((func) => {
    func()(dispatch, getState);
  });
};

export type AppActionTypes =
  | IDeleteProviderPolicyTypeMappingLoaded
  | ISignOutAction
  | IGetCreditCardBenefitsDataLoaded
  | IGetAllPolicyProvidersLoaded
  | IGetAllPolicyTypesLoaded
  | ISelectPolicyProvider
  | IGetPolicyTypesByProviderLoaded
  | ICeatePolicyTypeLoaded
  | ICreateProviderPolicyMappingLoaded
  | ICreateProviderPolicyMappingLoading
  | IGetAllPolicySubTypesLoaded
  | IGetUserByIdLoaded
  | IGetUserByIdLoading
  | ICreatePolicyLoaded
  | ICreatePolicyFailed
  | IGetUserPoliciesLoading
  | IGetUserPoliciesLoaded
  | ICreatePolicySubTypeLoaded
  | ISelectPolicyType
  | IGetPolicySubTypeByPolicyTypeLoaded
  | ICreatePolicyTypeSubTypeMappingLoaded
  | IDeletePolicyTypeSubTypeMappingLoaded
  | IDeletePolicyLoaded
  | IUpdatePolicyProviderLoaded
  | IUpdatePolicyProviderLoading
  | IGetUsersLoaded
  | ISelectUser
  | IUpdatePolicyLoaded
  | IGetAttachmentForPolicyLoaded
  | IGetRenewalForPolicyLoaded
  | IAdminUpdatePolicyLoaded
  | IUpdateUserLoading
  | IUpdateUserLoaded
  | ICreateCreditCardPolicyLoaded
  | ICreateInvitationLoaded
  | IProcessInvitationLoaded
  | IDeleteInvitationLoaded
  | IGetInvitationsLoaded
  | IInvitationLoading
  | IShowCreateInvitationForm
  | ISetCurrentFamily
  | IInvitationFailed
  | IClearInvitationErrors
  | IAdminDeleteUserByIdLoaded
  | IAdminUpdateUserLoaded
  | IResetPolicyForm
  | ICreatePolicyLoading
  | ISelectPolicy
  | ICreateRenewalLoading
  | ICreateRenewalLoaded
  | ICreatePendingRenewal
  | IUpdateRenewalLoaded
  | IUpdatePendingRenewalLoaded
  | IGetPendingRenewalsLoaded
  | IResetRenewalSubmit
  | ISelectPolicyRenewalLoaded
  | IUpdateProviderPolicyMappingLoaded
  | ISelectPolicySubType
  | IUpdateSelectedPolicySubType
  | IGetConfigLoaded
  | IGetAllAdvisersLoaded
  | IGetAdviserConnectsLoaded
  | IGetAdviserConnectLoaded
  | ICreateAdviserConnectLoaded
  | IUpdateAdviserConnectLoaded
  | IOperationUpdateAdviserConnectPolicyScans
  | IOperationUpdateAdviserConnectStatus
  | IGetSharedInvitationsLoaded
  | IGetPolicyScanByPolicyIdLoaded
  | ICreatePolicyScanLoaded
  | IUpdateInvitationLoaded
  | IUpdatePolicyScanLoaded
  | IGetPendingPolicyScanLoaded
  | ILoading
  | ILoaded
  | IFailed
  | IPolicyScanLoaded
  | IPolicyScanLocalUpdated
  | IPolicyScansLoaded
  | IGetPolicyScanLoaded
  | IAdminGetUserLoaded
  | IAdminGetUserPolicies
  | IAdviserSubmitting
  | ICreateClientLoaded
  | IAdviserResetForm
  | IGetClientsLoaded
  | ISelectClientLoaded
  | IUpdateClientLoaded
  | ICreateClientPolicyLoaded
  | ICreateClientPolicyFailed
  | IUpdateClientPolicyLoaded
  | ICreateClientPolicyLoading
  | IDeleteClientPolicyLoaded
  | IDeleteClientLoaded
  | IAdviserConnectInfoUpdated
  | ICalendarLoading
  | ICalendlyEventCreated
  | IGetReferrals
  | ICreateReferral
  | IUpdateReferral
  | ICreatePoliciesLoaded
  | IGetEmployersLoaded
  | IDeletePoliciesLoaded
  | IGetWikiLoaded
  | IPaymentLoading
  | ICreatePaymentIntentError
  | IUpdateSelectedPolicyLocal
  | IUpdateSelectedPolicyRenewLocal
  | INewSelectedPolicyLocal
  | IAddPolicyModelOpen
  | ICreatePaymentIntentLoaded
  | IGetConsentsLoaded
  | ICreateConsentsLoaded
  | ICreateConsentsFailed
  | IDeleteConsentLoaded
  | IUpdateConsentLoaded
  | IGetCreditCardPoliciesLoaded
  | ICreateCreditCardPolicyLoaded
  | IDeleteCreditCardPolicyLoaded
  | ICreditCardSubmitting
  | ICreditCardResetForm
  | IUserBundlesLoaded
  | IGetPolicyBundlesLoaded
  | ICreatePolicyBundleLoaded
  | IDeletePolicyBundleLoaded
  | IUpdatePolicyLoading
  | IGetUserByIdFailed
  | ICreatePolicyScanLoading
  | ICreatePolicyScanFailed
  | ICreatePolicyScanWithoutPolicyLoaded
  | IToggleAdviserReferral
  | IGetPolicyScanReferenceLoaded
  | IFeedbackLoaded
  | IPolicyRenewalSubmitting
  | IPolicyRenewalFailed
  | IDeleteRenewalLoaded
  | IDeletePendingRenewalLoaded
  | IFilteredPoliciesLoaded
  | IFilteredPoliciesLoaded
  | IAdviserInquiryLoaded
  | IAdviserLoading
  | IAdviserFailed
  | IReferralLoaded
  | IInsuranceCheckupReferenceLoaded
  | IVerifyWindcavePaymentLoaded
  | IVerifyWindcavePaymentFailed
  | IAdminGetUserInvitationsLoaded
  | IAdminGetUserPolicyScansLoaded
  | IAdminGetUserSentInvitationsLoaded
  | IOperationGetAdviserConnectLoaded
  | IOperationGetSelectedAdviserConnectLoaded
  | IEmailNotificationLoaded
  | IOperationPolicyRenewalDetailsloaded
  | IOperationUpdatePolicyRenewal
  | IOperationGetMarketScanFeedbacks
  | INotificationsLoading
  | IGetNotificationsLoaded
  | IUpdateNotificationLoaded
  | ISeenNotificationsLoaded
  | IAdminLoading
  | IDeletePolicyNotificationsLoaded
  | IToggleAppBanner
  | IPolicyScanPayloadLoaded
  | IOperationGetAdviserConnectsForUserLoaded
  | IAddToCartLoaded;
