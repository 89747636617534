import React, { useCallback } from 'react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import Autocomplete from 'react-google-autocomplete';
import styles from './formField.scss';
import { Checkbox, Icon } from 'semantic-ui-react';
import { HelpTooltip } from 'components/tooltip';

export interface IInputBordered {
  required?: boolean;
  value: string | number;
  onChange?: (e: React.SyntheticEvent) => void;
  className?: string;
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  inputType?: string;
  prefix?: string;
  disabled?: boolean;
  error?: boolean;
  errorIcon?: boolean;
  tooltip?: string;
  warning?: boolean;
  onConfirmPremium?: () => void;
}

const InputBordered = ({
  value,
  onChange,
  className,
  label,
  inputClassName,
  disabled,
  inputType,
  prefix,
  labelClassName,
  name,
  error,
  errorIcon,
  required,
  decimalScale,
  placeholder = '',
  maxLength = 256,
  tooltip,
  warning,
  onConfirmPremium,
}) => {
  const numberOnChange = useCallback(
    ({ floatValue = '' }) => {
      const target = { name, value: floatValue };
      onChange({ target });
    },
    [onChange],
  );

  const handlePlaceSelected = useCallback(
    ({ formatted_address }) => {
      const target = {
        name,
        value: formatted_address.replace(/, New Zealand$/i, ''),
      };
      onChange({ target });
    },
    [name, onChange],
  );

  const handleCheckboxChange = useCallback(
    (_, { checked }) => {
      const target = { name, value: checked };
      onChange({ target });
    },
    [name, onChange],
  );

  return (
    <label
      className={classnames(styles.container, error && styles.error, className)}
    >
      <span
        className={classnames(
          styles.label,
          labelClassName,
          warning && styles.warning,
        )}
      >
        {required && <span className={styles.required}>{'* '}</span>}
        {label}
      </span>
      <div
        className={classnames(
          styles.inputWrap,
          inputClassName,
          disabled && styles.disabled,
          error && errorIcon && styles.errorIcon,
        )}
      >
        {error && errorIcon && (
          <Icon className={styles.errorIcon} name="exclamation circle" />
        )}
        {!!value && prefix && (
          <span
            className={classnames(
              styles.prefix,
              disabled && styles.disabled,
              warning && styles.warningPrefix,
            )}
          >
            {prefix}
          </span>
        )}
        {inputType === 'number' ? (
          <NumberFormat
            name={name}
            className={classnames(styles.input, warning && styles.warning)}
            onValueChange={numberOnChange}
            disabled={disabled}
            thousandSeparator=","
            value={value}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            allowNegative={false}
            placeholder={placeholder}
          />
        ) : inputType === 'address' ? (
          <Autocomplete
            name={name}
            className={styles.input}
            onChange={onChange}
            onPlaceSelected={handlePlaceSelected}
            types={['address']}
            componentRestrictions={{ country: 'nz' }}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
          />
        ) : inputType === 'checkbox' ? (
          <Checkbox
            className={styles.checkbox}
            onChange={handleCheckboxChange}
            checked={value || false}
            disabled={disabled}
            name={name}
          />
        ) : (
          <input
            className={styles.input}
            value={value}
            onChange={onChange}
            disabled={disabled}
            type={inputType}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        )}
        {warning && (
          <div onClick={onConfirmPremium} className={styles.ignoreWarning}>
            This is correct
          </div>
        )}
        {tooltip && (
          <HelpTooltip
            toggleClassName={styles.toggle}
            content={tooltip}
            position="right center"
          />
        )}
      </div>
    </label>
  );
};

InputBordered.defaultProps = {
  inputType: 'text',
  decimalScale: 2,
};

export default InputBordered;
