export enum COLORS {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
}

export enum TASK_TYPES {
  POLICY_EXPIRED = 'POLICY_EXPIRED',
  POLICY_RENEWAL = 'POLICY_RENEWAL',
  POLICY_SCAN_PENDING = 'POLICY_SCAN_PENDING',
  POLICY_SCAN_CONTINUE = 'POLICY_SCAN_CONTINUE',
  POLICY_SCAN_READY = 'POLICY_SCAN_READY',
  POLICY_SCAN_IN_REVIEW = 'POLICY_SCAN_IN_REVIEW',
  POLICY_SCAN_PROTECTA_REFERRAL = 'POLICY_SCAN_PROTECTA_REFERRAL',
  POLICY_SCAN_PURCHASE_PROGRESS = 'POLICY_SCAN_PURCHASE_PROGRESS',
  PENDING_SHARE_POLICIES = 'PENDING_SHARE_POLICIES',
  ACTIVE_SHARE_POLICIES = 'ACTIVE_SHARE_POLICIES',
  COMPLETE_BROKER_FORM = 'COMPLETE_BROKER_FORM',
  GLOBAL_NOTIFICATION = 'GLOBAL',
  POLICY_SCAN_PURCHASE_COMPLETED = 'POLICY_SCAN_PURCHASE_COMPLETED',
}

export enum CLICK_TYPES {
  ICON = 'ICON',
  CLICK = 'CLICK',
}

export enum AppBannerContentType {
  HIGH_VOL_MS = 'HIGH_VOL_MS',
  LIFE = 'LIFE',
}
