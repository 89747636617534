import React, { useEffect } from 'react';
import DOMPurify from 'dompurify';
import FormEngine from 'components/form-engine';
import { ProgressBar } from 'components/progress';
import Title from 'components/title';
import { FormIds } from 'constants/form-engine';
import { IFormDefinition } from 'types';
import styles from '../styles/UnderwritingPage.module.scss';
import { logPageView, PAGE_VIEW_EVENTS } from 'helpers';

const renderPage = (
  content: React.ReactNode,
  formDef: IFormDefinition,
  pageNum: number,
) => {
  const page = formDef?.pages[pageNum];

  return (
    !!page && (
      <div className={styles.page}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {pageNum > 3 && <h2>{pageNum + 1}.</h2>}
            {page.headerTitle}
          </div>
          <ProgressBar className={styles.progress} percent={page.progress} />
        </div>
        {page.headerSubtitle && (
          <div
            className={styles.subtitle}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(page.headerSubtitle),
            }}
          />
        )}
        <div className={styles.contentWrap}>
          {pageNum < 4 && <h2>{pageNum + 1}.</h2>}
          {content}
        </div>
      </div>
    )
  );
};

const UnderwritingForm = ({
  next,
  prev,
  underwritingQuestions,
  updateUnderwritingQuestions,
  user,
}) => {
  const handleBack = (currentPage) => {
    if (currentPage === 0) {
      prev();
    }
  };

  const handleSubmit = (_, payload) => {
    const formData = payload.questions.reduce(
      (acc, { key, value }) => R.assoc(key, value, acc),
      {},
    );
    updateUnderwritingQuestions(formData);
    next();
  };

  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 2, premium: !!user.premium });
  }, []);

  return (
    <div className={styles.container}>
      <Title title="Your details" subtitle="Let's get to know you better." />
      <FormEngine
        formId={FormIds.PREMIUM_UNDERWRITING_FORM}
        onBack={handleBack}
        onSubmit={handleSubmit}
        renderPage={renderPage}
        headerClassName={styles.header}
        contentClassName={styles.content}
        questionsClassName={styles.question}
        fieldClassName={styles.field}
        skipSubmit={true}
        defaultValues={underwritingQuestions}
      />
    </div>
  );
};

export default UnderwritingForm;
