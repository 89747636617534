// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3id_jeSq5WqBrVCnj26wub.ui.modal{max-width:570px;min-height:250px;border-radius:5px}._3id_jeSq5WqBrVCnj26wub.ui.modal .content{display:flex;color:#34363e;padding:40px;border-radius:5px;background-color:#f7f8fc;height:100%}._3id_jeSq5WqBrVCnj26wub.ui.modal .content h2{font-family:\"Open Sans\";font-weight:700;line-height:1}._2j6JI2-lKzQdAeK1v2KGaL{color:#4187f5}._1zMpsDxST-ougQD9M-mKZL{font-family:\"Open Sans\";font-weight:400;font-size:14px;max-width:350px}._2m3tK7aQ2YilQTf7Kyzyv6{position:absolute;right:10px;top:10px;color:#34363e;cursor:pointer}._2BY6CZa_v8bXcSzyBXL2Et{margin-right:16px;z-index:1}._2BY6CZa_v8bXcSzyBXL2Et>*:not(:last-child){margin-bottom:24px}.-TglxpxmLq4jgZ8laB9bJ{flex:1}.-TglxpxmLq4jgZ8laB9bJ svg{height:100%;width:auto}._3XYjwjTtwT6YIW83iuBIN5.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;background-color:#4187f5}@media(max-width: 576px){._3XYjwjTtwT6YIW83iuBIN5.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 820px){._3id_jeSq5WqBrVCnj26wub.ui.modal > .content{padding:40px !important}}@media(max-width: 576px){._3id_jeSq5WqBrVCnj26wub.ui.modal .content{flex-direction:column;padding:40px 24px 16px !important}.-TglxpxmLq4jgZ8laB9bJ{text-align:right;margin-top:-50px}}", ""]);
// Exports
exports.locals = {
	"modal": "_3id_jeSq5WqBrVCnj26wub",
	"blue": "_2j6JI2-lKzQdAeK1v2KGaL",
	"desc": "_1zMpsDxST-ougQD9M-mKZL",
	"close": "_2m3tK7aQ2YilQTf7Kyzyv6",
	"left": "_2BY6CZa_v8bXcSzyBXL2Et",
	"right": "-TglxpxmLq4jgZ8laB9bJ",
	"button": "_3XYjwjTtwT6YIW83iuBIN5"
};
module.exports = exports;
