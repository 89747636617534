import { useState, useEffect, useCallback } from "react";

const getDeviceConfig = (width: number) => {
  if (width >= 1300) {
    return 'xxl';
  } else if (width >= 1200) {
    return 'xl';
  } else if(width >= 992) {
    return 'lg';
  } else if(width >= 768) {
    return 'md';
  } else if (width >= 576) {
    return 'sm';
  } else {
    return 'xs';
  }
};

const useBreakpoint = (returnWidth = false) => {
  const [brkPnt, setBrkPnt] = useState(() => (
    returnWidth ? window.innerWidth : getDeviceConfig(window.innerWidth)
  ));

  const calcInnerWidth = useCallback(() => (
    setBrkPnt(returnWidth ? window.innerWidth : getDeviceConfig(window.innerWidth))
  ), [returnWidth]); 
  
  useEffect(() => {
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;