import { useCallback, useRef, useState } from 'react';
import { IFile } from 'services';
import { IAttachment } from 'types';

export interface IPopupProps {
  open: boolean;
  files?: IFile[];
  newFiles?: IFile[];
}

export const useUploader = (onSubmit?, defaultAttachments = []) => {
  const [attachments, setAttachments] = useState(defaultAttachments);
  const [popupProps, setPopupProps] = useState<IPopupProps>({ open: false });
  const [rejected, setRejected] = useState([]);
  const uploaderRef = useRef(null);

  const findNewAndSimilarFiles = (files, otherFiles) => {
    const similarFiles = [];
    const newFiles = [];
    for (const file of files) {
      const duplicate = otherFiles.find(
        (a) => (a.name || a.fileName) === (file.name || file.fileName),
      );

      if (duplicate) {
        similarFiles.push(file);
      } else {
        newFiles.push(file);
      }
    }
    return [similarFiles, newFiles];
  };

  const handleDrop = useCallback(
    (files, rejectedFiles) => {
      // find files that are being uploaded that already exist
      const [similar, newF] = findNewAndSimilarFiles(files, attachments);

      // open confirmation popup if there are similar files
      if (similar.length > 0) {
        setPopupProps({
          open: true,
          files: similar,
        });
        setAttachments(R.concat(attachments, newF));
      } else {
        setAttachments(R.concat(attachments, files));
        if (rejectedFiles.length > 0) {
          setRejected(rejectedFiles);
        }
      }
    },
    [attachments],
  );

  const removeAttachment = useCallback(
    (e, idx) => {
      e.stopPropagation();
      setAttachments(R.remove(idx, 1, attachments));
    },
    [attachments],
  );

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(attachments);
      setRejected([]);
    }
  }, [onSubmit, attachments]);

  const handleOpenUploader = useCallback(() => {
    if (uploaderRef.current) {
      uploaderRef.current.open();
    }
  }, []);

  const getUpdatedAttachments = (parent, child) => {
    const [_, notFound] = findNewAndSimilarFiles(parent, child);
    return R.concat(notFound, child);
  };

  const handlePopupAction = useCallback(
    (confirm: boolean = false) => {
      if (confirm && popupProps?.files) {
        const updated = getUpdatedAttachments(attachments, popupProps.files);
        setPopupProps({ open: false });
        setAttachments(updated);
      } else {
        const updated = getUpdatedAttachments(popupProps.files, attachments);
        setPopupProps({ open: false });
        setAttachments(updated);
      }
    },
    [attachments, popupProps],
  );

  return {
    uploaderRef,
    attachments,
    rejected,
    popupProps,
    setAttachments,
    onDrop: handleDrop,
    removeAttachment,
    onSubmit: handleSubmit,
    onPopupAction: handlePopupAction,
    openUploader: handleOpenUploader,
    findNewAndSimilarFiles,
  };
};
