import React, { useCallback, useMemo, useState } from 'react';
import { X } from 'react-feather';
import { Form, Modal } from 'semantic-ui-react';
import styles from './styles/referBase.scss';
import Button from '../button';
import FormField from '../form-field';
import { validateEmail } from 'helpers';
import useForm from 'hooks/useForm';
import { IUser } from 'types';

interface IProps {
  user: IUser;
  className?: string;
  rightHeader?: React.ReactNode;
  trigger?: React.ReactNode;
  items: any[];
  leftHeader: string;
  leftTitle: string;
  leftDesc: string;
  open: boolean;
  rightDesc: React.ReactNode;
  submitBtnText: string;
  submittedBold: string;
  submittedDesc: string;
  onClose(): void;
  onOpen(): void;
  onSubmit(values: any): void;
}

export const REFER_FIELDS = [
  { key: 'recipientFirstName', placeholder: 'Name' },
  { key: 'recipientEmail', placeholder: 'Email', validation: validateEmail },
];

const validate = (form) => {
  return REFER_FIELDS.reduce((acc, { key, validation }) => {
    const value = form[key];
    const isValid = validation ? validation(String(value).trim()) : !!value;
    if (!isValid) {
      return R.assoc(key, true, acc);
    }
    return acc;
  }, null);
};

const noDuplicateEmails = (email, referrals) => {
  return !referrals.find(R.propEq('recipientEmail', email));
};

const defaultValues = {};

const ReferBase = ({
  items,
  trigger,
  leftHeader,
  leftTitle,
  leftDesc,
  open,
  rightHeader,
  rightDesc,
  submitBtnText,
  submittedBold,
  submittedDesc,
  onClose,
  onOpen,
  onSubmit,
  user,
}: IProps) => {
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const submit = useCallback(
    ({ values }) => {
      const findErrors = validate(values);
      if (findErrors) {
        setErrors(findErrors);
      } else if (
        !noDuplicateEmails(values.recipientEmail, items) ||
        user.email === values.recipientEmail
      ) {
        setErrors({ recipientEmail: true });
      } else {
        values.recipientEmail = String(values.recipientEmail).trim();
        onSubmit(values);
        setSubmitted(true);
      }
    },
    [items],
  );

  const { values, handleInputChange, handleReset, handleSubmit } = useForm({
    defaultValues,
    onSubmit: submit,
  });

  const reset = useCallback(() => {
    handleReset();
    setErrors({});
    setSubmitted(false);
  }, [handleReset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      onClose={handleClose}
      onOpen={onOpen}
      open={open}
      size="large"
      trigger={trigger}
    >
      <Modal.Content as={Form} onSubmit={handleSubmit}>
        <X onClick={handleClose} className={styles.close} size={24} />
        <div className={styles.left}>
          <div className={styles.blue}>{leftHeader}</div>
          <div className={styles.title}>{leftTitle}</div>
          <div className={styles.content}>{leftDesc}</div>
          <div className={styles.blue}>#bethebossofyourinsurance</div>
        </div>
        <div className={styles.right}>
          {rightHeader}
          {submitted ? (
            <>
              <div className={styles.title}>All sent.</div>
              <div className={styles.content}>
                <div className={styles.bold}>{submittedBold}</div>
                {submittedDesc}
              </div>
              <Button
                className={styles.blueBtn}
                text="Send another"
                onClick={reset}
              />
            </>
          ) : (
            <>
              <div className={styles.content}>{rightDesc}</div>
              {REFER_FIELDS.map(({ key, placeholder }) => (
                <FormField
                  key={key}
                  name={key}
                  className={styles.field}
                  labelClassName={styles.label}
                  inputClassName={styles.input}
                  value={values[key]}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                  error={errors[key]}
                  errorIcon={true}
                />
              ))}
              <Button
                className={styles.blueBtn}
                text={submitBtnText}
                type="submit"
                eventName="Web-App-Refer-Friend"
              />
            </>
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ReferBase;
