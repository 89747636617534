// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, ".phrQ_58Kwqh87p6Cm5gc4{margin-top:8px;margin-bottom:5px}._2-gywgRZWvHQJy7KyxVmxS{margin-bottom:5px}", ""]);
// Exports
exports.locals = {
	"descName": "phrQ_58Kwqh87p6Cm5gc4",
	"desc": "_2-gywgRZWvHQJy7KyxVmxS"
};
module.exports = exports;
