import moment from 'moment';
import queryString from 'query-string';
import { AUTOMATION_STATUS, IPolicy, IPolicyScan } from 'types';
import {
  FIELD_KEYS_FOR_COMPLETION_BY_NAME,
  FIELD_KEYS_AMOUNT_BY_NAME,
  FIELD_KEYS_EXCESS_BY_NAME,
} from 'constants/insurance';
import {
  MARKET_SCAN_QUESTIONS_BY_NAME,
  MARKET_SCAN_FIELDS_TO_IGNORE,
} from 'constants/marketScan';
import { MARKET_SCAN_PURCHASE_ROUTE } from 'constants/routes';

const MARKET_SCAN_REQUEST_DAYS = 30;

export const getFieldKeysForMarketScan = (policy, subTypes) => {
  const { policyType } = policy;
  const fieldKeysCommon =
    FIELD_KEYS_FOR_COMPLETION_BY_NAME[policyType] ||
    FIELD_KEYS_FOR_COMPLETION_BY_NAME.default;
  const fieldKeysByType = R.propOr(
    [],
    policyType,
    MARKET_SCAN_QUESTIONS_BY_NAME,
  )
    .filter((f) => (f.showKeyValue ? f.showKeyValue(policy) : true))
    .map(R.prop('fieldKey'));
  const amountKeys =
    FIELD_KEYS_AMOUNT_BY_NAME[policy.policyType] ||
    FIELD_KEYS_AMOUNT_BY_NAME.default;
  const excessKeys =
    FIELD_KEYS_EXCESS_BY_NAME[policy.policyType] ||
    FIELD_KEYS_EXCESS_BY_NAME.default;

  const fieldKeys = [
    ...fieldKeysCommon,
    ...fieldKeysByType,
    ...amountKeys,
    ...excessKeys,
  ].filter(
    (f) =>
      !R.includes(f, [
        ...MARKET_SCAN_FIELDS_TO_IGNORE,
        'renewalDate',
        'policyProviderId',
      ]),
  );

  if (subTypes.length > 0) {
    return R.append('policySubTypeId', fieldKeys);
  }

  return fieldKeys;
};

export const canRequestMarketScanByDate = ({ lastPolicyScanDate }: IPolicy) => {
  if (!lastPolicyScanDate) {
    return true;
  }

  const date = moment(lastPolicyScanDate);
  const diffDays = moment().diff(date, 'days');
  return diffDays > MARKET_SCAN_REQUEST_DAYS;
};

export const getPurchaseLink = (providerName: string) => {
  const { id, sk } = queryString.parse(location.search.replace('?', ''));
  return `${MARKET_SCAN_PURCHASE_ROUTE}?id=${id}&sk=${sk}&providerName=${providerName}`;
};

export const claimsHistoryCleanUp = (model) => {
  const newModel = { ...model };
  if (!newModel.accidentHistory) {
    newModel.accidentClaimCount = 0;
    delete newModel.claimType1;
    delete newModel.accidentClaimDate1;
    delete newModel.accidentClaimDate2;
    delete newModel.accidentFault;
  }
  return newModel;
};

export const filterAutomationStatus = (
  automationStatus: AutomationStatusLabel,
  policyScan: IPolicyScan,
) => {
  if (automationStatus === AutomationStatusLabel.CompletedReview) {
    return policyScan.automationHasError !== undefined;
  }
  if (automationStatus === AutomationStatusLabel.ManualProcessNeeded) {
    return (
      policyScan.automationStatus === AUTOMATION_STATUS.MANUAL_COMPLETED_NEEDED
    );
  }
  if (automationStatus === AutomationStatusLabel.PendingReview) {
    return (
      policyScan.automationStatus === AUTOMATION_STATUS.MANUAL_REVIEW_NEEDED &&
      policyScan.automationHasError === undefined
    );
  }
};

export enum AutomationStatusLabel {
  CompletedReview = 'CompletedReview',
  PendingReview = 'PendingReview',
  ManualProcessNeeded = 'ManualProcessNeeded',
}

export const getAutomationStatus = (policyScan: IPolicyScan) => {
  if (policyScan.automationStatus === undefined) {
    return '';
  }
  if (
    policyScan.automationStatus === AUTOMATION_STATUS.MANUAL_COMPLETED_NEEDED
  ) {
    return 'Manual process needed';
  }

  if (policyScan.automationStatus === AUTOMATION_STATUS.MANUAL_REVIEW_NEEDED) {
    if (policyScan.automationHasError !== undefined) {
      return 'Completed review';
    } else {
      return 'Pending review';
    }
  }
};

const AnnualDistanceDrivenOptions = {
  KM_5: 'Less than 5km',
  KM_5_10: '5-10km',
  KM_10_20: '10-20km',
  KM_20: 'More than 20km',
};

export const convertAnnualDistanceDriven = (distance: number) => {
  if (distance < 5000) {
    return AnnualDistanceDrivenOptions.KM_5;
  } else if (distance > 5000 && distance < 10000) {
    return AnnualDistanceDrivenOptions.KM_5_10;
  } else if (distance > 10000 && distance < 20000) {
    return AnnualDistanceDrivenOptions.KM_10_20;
  } else {
    return AnnualDistanceDrivenOptions.KM_20;
  }
};
