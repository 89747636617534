import React from 'react';
import { Check } from 'react-feather';
import styles from './Stepper.module.scss';

interface IProps {
  currentStep: number;
  steps: string[];
  className?: string;
  leftLabel?: React.ReactNode;
}

const Stepper = ({ className, leftLabel, steps, currentStep = 0 }: IProps) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.left}>
        <div className={styles.label}>{leftLabel}</div>
        <div className={styles.bars}>
          {steps.map((_, idx) => (
            <div
              key={`bar_${idx}`}
              className={classnames(
                styles.bar,
                idx <= currentStep && styles.active,
              )}
            />
          ))}
        </div>
      </div>
      <div className={styles.right}>
        <ul className={styles.steps}>
          {steps.map((s: string, idx: number) => (
            <li
              key={`step_${idx}`}
              className={classnames(
                styles.step,
                idx <= currentStep && styles.active,
              )}
            >
              <div
                className={classnames(
                  styles.tail,
                  idx < currentStep && styles.active,
                )}
              />
              <div className={styles.stepWrap}>
                <div key={`label_${idx}`} className={styles.label}>
                  {s}
                </div>
                <div className={styles.circle}>
                  {idx < currentStep && (
                    <Check
                      className={styles.check}
                      size={16}
                      strokeWidth={4}
                      strokeLinecap="square"
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Stepper;
