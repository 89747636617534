// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1mkOScyCXL05FlvVXuZG-{color:#34363e}._3SuNg4zbunrGWVlBNHYfGh,._12vctMeS5QMtcsjJ47YXF1,._3a-mpikHe29HvDqGSRYIu-,._3yxTlPUQjLkShOe3gFU_1G{display:flex;align-items:center}._3SuNg4zbunrGWVlBNHYfGh{justify-content:space-between;padding:24px 40px}._3SuNg4zbunrGWVlBNHYfGh:not(:last-child){border-bottom:1px solid #d9d9d9}._12vctMeS5QMtcsjJ47YXF1{align-items:flex-end}._12vctMeS5QMtcsjJ47YXF1 svg:first-child{max-width:20px;margin-right:40px;height:auto}._12vctMeS5QMtcsjJ47YXF1 span{font-family:\"Open Sans\";font-weight:700;font-size:14px;user-select:none}._3th4bw6UTy8z_6PDQRHaWj{margin-left:16px}._2LeRGPl9sFaBkoXBQO_ibG{font-family:\"Open Sans\";font-weight:700;font-size:24px;width:40px;text-align:center;user-select:none}._3yxTlPUQjLkShOe3gFU_1G{width:40px;height:40px;background-color:#34363e;border-radius:50%;justify-content:center;cursor:pointer;border:2px solid rgba(0,0,0,0)}._3yxTlPUQjLkShOe3gFU_1G svg{color:#fff}._3yxTlPUQjLkShOe3gFU_1G:hover{background-color:#fff;border-color:#34363e}._3yxTlPUQjLkShOe3gFU_1G:hover svg{color:#34363e}._1gdpvJ2OClonYj14ACmvOR{text-align:right;margin-top:16px}@media(max-width: 576px){._3SuNg4zbunrGWVlBNHYfGh{padding:16px 0}._12vctMeS5QMtcsjJ47YXF1 svg:first-child{margin-right:16px}._1gdpvJ2OClonYj14ACmvOR ._3QD5Zu78z1LFFV_o_1Xn6B{width:100%}}", ""]);
// Exports
exports.locals = {
	"container": "_1mkOScyCXL05FlvVXuZG-",
	"item": "_3SuNg4zbunrGWVlBNHYfGh",
	"left": "_12vctMeS5QMtcsjJ47YXF1",
	"right": "_3a-mpikHe29HvDqGSRYIu-",
	"button": "_3yxTlPUQjLkShOe3gFU_1G",
	"toggle": "_3th4bw6UTy8z_6PDQRHaWj",
	"value": "_2LeRGPl9sFaBkoXBQO_ibG",
	"buttons": "_1gdpvJ2OClonYj14ACmvOR",
	"submit": "_3QD5Zu78z1LFFV_o_1Xn6B"
};
module.exports = exports;
