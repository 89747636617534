import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload } from 'react-feather';
import { Form, Image, Popup, Icon } from 'semantic-ui-react';
import FormField from 'components/form-field';
import { USER_FIELDS } from 'constants/user-form';
import { getFamily, getProfilePictureSrc, isNormalLogin } from 'helpers';
import useForm from 'hooks/useForm';
import { IInvitationState, IEmployer, IUser, IConfig } from 'types';
import QScratchingHead from '-!svg-react-loader!images/q/q-scratching-head.svg';
import styles from './styles/userProfile.scss';
import { getDefaultFormValues, validateProfileForm } from './utils';
import UserTabs from './UserTabs';
import { ProfileImageModal } from 'components/modals';
import { IFile } from 'services';

interface IProps {
  auth0: any;
  config: IConfig;
  employers: IEmployer[];
  user: IUser;
  invitation: IInvitationState;
  deletePolicies(): void;
  getInvitationsForFamilyId(familyId: string): void;
  toggleAdviserReferral(open: boolean): void;
  updateProfilePhoto?(file: IFile): void;
  updateUser(user: IUser): void;
}

const PopupContent = ({ onClose }) => (
  <div className={styles.popupContent}>
    <div className={styles.popupContentTitle}>
      <QScratchingHead className={styles.qImage} />
      <div className={styles.title}>Why is all my information here?</div>
    </div>
    <p>
      We store all your information here to make your Insurance experience
      better! We use the information we gather from when you have used our
      platform and we store them here so that you are able to change that
      information if things are wrong and also so that we can automatically pick
      up this information when you need to fill other things throughout the
      platform.
    </p>
    <p>
      If you would like to opt out of storing your information on here, just
      shoot us an email and we can sort it out for you. If you are wanting to
      opt out, just know that you will not be able to use the features on this
      platform that require your information to be stored and entered here. We
      do not sell or give your information to anyone without your permission.
    </p>
  </div>
);

const UserProfileForm = ({
  user,
  auth0,
  config,
  invitation,
  employers,
  deletePolicies,
  getInvitationsForFamilyId,
  toggleAdviserReferral,
  updateProfilePhoto,
  updateUser,
}: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isExtraEdit, setExtraEdit] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [errors, setErrors] = useState(null);

  const picture = getProfilePictureSrc(user.picture || auth0.picture);
  const isAuth0User = user.id && user.id.indexOf('auth0') > 0;
  const defaultValues = useMemo(() => getDefaultFormValues(user), [user]);

  const onSubmit = useCallback(
    ({ values }) => {
      const formErrors = validateProfileForm(values);
      if (formErrors) {
        setErrors(formErrors);
        return;
      }

      const payload = {
        ...user,
        ...values,
        name: `${values.firstName} ${values.lastName}`,
      };

      // Remove employer policies when removing employer
      if (user.employerId && !payload.employerId) {
        deletePolicies();
      }

      updateUser(payload);
      setEdit(false);
      setExtraEdit(false);
      setErrors(null);
    },
    [user, updateUser, setEdit],
  );

  const form = useForm({
    defaultValues,
    onSubmit,
  });

  useEffect(() => {
    const family = getFamily(user.families);
    if (family && family.id) {
      getInvitationsForFamilyId(family.id);
    }
  }, [user]);

  useEffect(() => {
    form.setValues(getDefaultFormValues(user));
  }, [user]);

  const openPopover = useCallback(() => setShowPopover(true), []);
  const closePopover = useCallback(() => setShowPopover(false), []);
  const closeModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const handleCancel = useCallback(() => {
    setEdit(false);
    form.handleReset();
  }, [form]);

  const handleEdit = useCallback((e) => {
    e.preventDefault();
    setEdit(true);
  }, []);

  const handleExtraEdit = useCallback((e) => {
    e.preventDefault();
    setExtraEdit(true);
  }, []);

  const handleExtraCancel = useCallback(() => {
    setExtraEdit(false);
    form.handleReset();
    setErrors(null);
  }, [form]);

  const uploadBtn = (
    <div className={styles.image}>
      <Image className={styles.avatar} src={picture} avatar={true} />
      <div className={styles.uploadBtn}>
        <Upload size={14} />
      </div>
    </div>
  );

  return (
    <Form className={styles.container} onSubmit={form.handleSubmit}>
      <div className={styles.header}>
        <div className={styles.profileTitle}>Profile</div>
        <Popup
          className={styles.popup}
          trigger={
            showPopover ? (
              <Icon
                circular
                name="x"
                className={styles.question}
                size="large"
              />
            ) : (
              <Icon
                circular
                name="question"
                className={styles.question}
                size="large"
              />
            )
          }
          content={<PopupContent onClose={closePopover} />}
          open={showPopover}
          on="click"
          onClose={closePopover}
          onOpen={openPopover}
          position="bottom right"
          wide="very"
        />
      </div>
      <div className={styles.editProfileContainer}>
        <div className={styles.imageContainer}>
          {isNormalLogin(user.id) ? (
            <ProfileImageModal
              open={openModal}
              onClose={closeModal}
              onOpen={showModal}
              trigger={uploadBtn}
              onUpload={updateProfilePhoto}
            />
          ) : (
            <Image className={styles.avatar} src={picture} avatar={true} />
          )}
          <div className={styles.namesButton}>
            {isEdit ? (
              <>
                <div className={styles.textBtn} onClick={form.handleSubmit}>
                  Save
                </div>
                <div className={styles.textBtn} onClick={handleCancel}>
                  Cancel
                </div>
              </>
            ) : (
              <div className={styles.textBtn} onClick={handleEdit}>
                Edit
              </div>
            )}
          </div>
        </div>
        <div className={styles.names}>
          <div className={styles.nameLeft}>
            <FormField
              key={USER_FIELDS[0].key}
              name={USER_FIELDS[0].key}
              className={`${styles.field}`}
              labelClassName={styles.label}
              inputClassName={styles.input}
              onChange={form.handleInputChange}
              value={form.values[USER_FIELDS[0].key]}
              disabled={!isEdit}
              error={errors && errors[USER_FIELDS[0].key]}
              {...USER_FIELDS[0]}
            />
            <FormField
              key={USER_FIELDS[1].key}
              name={USER_FIELDS[1].key}
              className={`${styles.field}`}
              labelClassName={styles.label}
              inputClassName={styles.input}
              onChange={form.handleInputChange}
              value={form.values[USER_FIELDS[1].key]}
              disabled={!isEdit}
              error={errors && errors[USER_FIELDS[1].key]}
              {...USER_FIELDS[1]}
            />
          </div>
          <div className={styles.nameRight}>
            <div className={styles.customLabel}>{USER_FIELDS[2].label}</div>
            <div className={styles.customInput}>
              {form.values[USER_FIELDS[2].key]}
            </div>
          </div>
        </div>
      </div>
      <UserTabs
        config={config}
        form={form}
        user={user}
        isEdit={isExtraEdit}
        invitations={invitation.invitations}
        isAuth0User={isAuth0User}
        employers={employers}
        errors={errors}
        onCancel={handleExtraCancel}
        onEdit={handleExtraEdit}
        toggleAdviserReferral={toggleAdviserReferral}
      />
    </Form>
  );
};

export default UserProfileForm;
