import React, { useEffect, useState } from 'react';
import { dateValidator, getMaxYear, getYear } from 'helpers';
import styles from './styles/bdayPicker.scss';
import {
  getDayOptions,
  getMonthOptions,
  getYearOptions,
  getValue,
  convertStateToDate,
  getDisabledValue,
} from './utils';

interface IProps {
  arrow?: boolean;
  className?: string;
  disabled?: boolean;
  disabledClassName?: string;
  error?: boolean;
  inputClassName?: string;
  max?: number | string;
  inputContainerClassName?: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  icon?: any;
  name: string;
  value: string;
  onChange(e: any): void;
}

const maxYear = getMaxYear();

const dropdownIcon = (
  <i aria-hidden="true" className={classnames(styles.icon, 'dropdown icon')} />
);

const BirthdayPicker = ({
  arrow,
  className,
  disabled,
  disabledClassName,
  error,
  icon = dropdownIcon,
  inputClassName,
  inputContainerClassName,
  label,
  labelClassName,
  required,
  name,
  value,
  max = maxYear,
  onChange,
}: IProps) => {
  const [state, setState] = useState(getValue(value));

  useEffect(() => {
    // Update state when a valid value is changed by the parent component
    if (dateValidator(value)) {
      setState(getValue(value));
    }
  }, [value]);

  const handleChange = (e) => {
    const newState = {
      ...state,
      [e.target.name]: e.target.value,
    };

    setState(newState);

    onChange({
      target: {
        name,
        value: convertStateToDate(newState),
      },
    });
  };

  if (max === 'TODAY') {
    max = getYear();
  }

  return (
    <div
      className={classnames(styles.container, error && styles.error, className)}
    >
      {label && (
        <div className={classnames(styles.label, labelClassName)}>
          {required && <span className={styles.required}>{'* '}</span>}
          {label}
        </div>
      )}
      {disabled ? (
        <div className={classnames(styles.disabled, disabledClassName)}>
          {getDisabledValue(state)}
        </div>
      ) : (
        <div
          className={classnames(styles.inputContainer, inputContainerClassName)}
        >
          <div className={classnames(styles.inputWrap, inputClassName)}>
            {arrow && <div className={styles.iconContainer}>{icon}</div>}
            <select
              id="day"
              name="day"
              className={classnames(
                styles.input,
                !state.day && styles.placeholder,
              )}
              placeholder="DD"
              value={state.day}
              onChange={handleChange}
              disabled={disabled}
            >
              {getDayOptions()}
            </select>
          </div>
          <div className={classnames(styles.inputWrap, inputClassName)}>
            {arrow && <div className={styles.iconContainer}>{icon}</div>}
            <select
              name="month"
              className={classnames(
                styles.input,
                !state.month && styles.placeholder,
              )}
              placeholder="MM"
              value={state.month}
              onChange={handleChange}
              disabled={disabled}
            >
              {getMonthOptions()}
            </select>
          </div>
          <div className={classnames(styles.inputWrap, inputClassName)}>
            {arrow && <div className={styles.iconContainer}>{icon}</div>}
            <select
              name="year"
              className={classnames(
                styles.input,
                !state.year && styles.placeholder,
              )}
              placeholder="YYYY"
              value={state.year}
              onChange={handleChange}
              disabled={disabled}
            >
              {getYearOptions(max)}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default BirthdayPicker;
