import * as R from 'ramda';

const isEmptyString = (val) => {
  if (typeof val === 'string') {
    return val.trim() === '';
  }
  return false;
};

export const isEmptyValue = (value) =>
  R.isNil(value) || isEmptyString(value) || Number.isNaN(value);

// Recursively remove empty string, null or undefined but not false
export const removeEmptyValues = (obj) => {
  const keys = Object.keys(obj);
  return keys.reduce((acc, k) => {
    const val = obj[k];
    if (Array.isArray(val)) {
      acc[k] = val.reduce((accVal, v) => {
        if (!isEmptyValue(v)) {
          accVal.push(typeof v === 'object' ? removeEmptyValues(v) : v);
        }
        return accVal;
      }, []);
    } else if (!isEmptyValue(val)) {
      acc[k] = val;
    }
    return acc;
  }, {});
};

export const nullifyEmptyStrings = R.mapObjIndexed((value) =>
  isEmptyString(value) ? undefined : value,
);

export const shallowEquals = (a, b) => {
  if (a === b) {
    return true;
  }
  if (!(a instanceof Object) || !(b instanceof Object)) {
    return false;
  }

  const keys = Object.keys(a);
  const length = keys.length;

  for (let i = 0; i < length; i++) {
    if (!(keys[i] in b)) {
      return false;
    }
  }

  for (let i = 0; i < length; i++) {
    if (a[keys[i]] !== b[keys[i]]) {
      return false;
    }
  }

  return length === Object.keys(b).length;
};

export const getDifferentKeys = (a, b): string[] => {
  return Object.keys(a).reduce((acc, k) => {
    if (a[k] !== b[k]) {
      acc.push(k);
    }
    return acc;
  }, []);
};
