import { Action } from 'redux';
import {
  IAdviserConnect,
  IUser,
  ICreatePolicyProps,
  IPolicy,
  IConsent,
  ICalendlyIFrameEvent,
  IAppState,
  IAdviserConnectCartItem,
} from '../../types/index';

import {
  getAllAdvisers,
  getAllAdviserUserConnects,
  IConnectAdviserBody,
  connectAdviser,
  createClient,
  getClients,
  updateClient,
  inviteClient,
  IFile,
  createClientPolicyWithAttachments,
  createClientPolicy,
  updateClientPolicy,
  updateClientPolicyWithAttachments,
  deleteClientPolicy,
  deleteUserById,
  getConsents,
  createConsents,
  deleteConsent,
  getConsentById,
  createAdviserInquiry,
  updateAdviserConnectUser,
  getAdviserConnectByIdSk,
  createPolicy,
  updatePolicy,
  uploadAdviserConnectAttachments,
  getAllAdviserUserConnectsForUser,
  updatePolicyScan,
} from 'services';
import { CLIENTS_DETAILS_ROUTE, PREMIUM_ROUTE } from 'constants/routes';
import history from 'helpers/history';
import { INQUIRY_ERROR, UPLOAD_FAILED_MESSAGE } from 'constants/errors';
import { ClientInviteStatus } from 'constants/adviser';
import { ErrorToast } from 'components/toasts';
import { getPremiumAdviserConnect } from 'helpers/adviser';

export enum Types {
  ADVISER_RESET_FORM = 'ADVISER_RESET_FORM',
  ADVISER_SUBMITTING = 'ADVISER_SUBMITTING',
  GET_ALL_ADVISERS_LOADED = 'GET_ALL_ADVISERS_LOADED',
  GET_ADVISER_CONNECTS = 'GET_ADVISER_CONNECTS',
  GET_ADVISER_CONNECT_LOADED = 'GET_ADVISER_CONNECT_LOADED',
  CREATE_ADVISER_CONNECT = 'CREATE_ADVISER_CONNECT',
  UPDATE_ADVISER_CONNECT = 'UPDATE_ADVISER_CONNECT',
  CREATE_ADVISER_CLIENT = 'CREATE_ADVISER_CLIENT',
  GET_ADVISER_CLIENTS = 'GET_ADVISER_CLIENTS',
  SELECT_ADVISER_CLIENT = 'SELECT_ADVISER_CLIENT',
  UPDATE_ADVISER_CLIENT = 'UPDATE_ADVISER_CLIENT',
  INVITE_ADVISER_CLIENT = 'INVITE_ADVISER_CLIENT',
  CREATE_ADVISER_CLIENT_POLICY_LOADING = 'CREATE_ADVISER_CLIENT_POLICY_LOADING',
  CREATE_ADVISER_CLIENT_POLICY = 'CREATE_ADVISER_CLIENT_POLICY',
  CREATE_ADVISER_CLIENT_POLICY_FAILED = 'CREATE_ADVISER_CLIENT_POLICY_FAILED',
  UPDATE_ADVISER_CLIENT_POLICY = 'UPDATE_ADVISER_CLIENT_POLICY',
  DELETE_ADVISER_CLIENT_POLICY = 'DELETE_ADVISER_CLIENT_POLICY',
  DELETE_ADVISER_CLIENT = 'DELETE_ADVISER_CLIENT',
  CALENDY_EVENT_CREATED = 'CALENDY_EVENT_CREATED',
  CALENDY_LOADING = 'CALENDY_LOADING',
  ADVISER_CONNECT_INFO_UPDATED = 'ADVISER_CONNECT_INFO_UPDATED',
  GET_CONSENTS_LOADED = 'GET_CONSENTS_LOADED',
  CREATE_CONSENTS_LOADED = 'CREATE_CONSENTS_LOADED',
  CREATE_CONSENTS_FAILED = 'CREATE_CONSENTS_FAILED',
  DELETE_CONSENT_LOADED = 'DELETE_CONSENT_LOADED',
  UPDATE_CONSENT_LOADED = 'UPDATE_CONSENT_LOADED',
  ADVISER_LOADING = 'ADVISER_LOADING',
  ADVISER_FAILED = 'ADVISER_FAILED',
  ADVISER_INQUIRY_LOADED = 'ADVISER_INQUIRY_LOADED',
  ADD_TO_CART_LOADED = 'ADD_TO_CART_LOADED',
}

export interface IAddToCartLoaded extends Action {
  type: Types.ADD_TO_CART_LOADED;
  data: IAdviserConnectCartItem;
}

export const AddToCartLoaded = (data: IAdviserConnectCartItem) =>
  ({
    type: Types.ADD_TO_CART_LOADED,
    data,
  } as IAddToCartLoaded);

export interface IGetAllAdvisersLoaded extends Action {
  type: Types.GET_ALL_ADVISERS_LOADED;
  data: IUser[];
}

export const GetAllAdvisersLoaded = (data: IUser[]) =>
  ({
    type: Types.GET_ALL_ADVISERS_LOADED,
    data,
  } as IGetAllAdvisersLoaded);

export const GetAllAdvisers = () => async (dispatch) => {
  const result = await getAllAdvisers();
  const data: IUser[] = result.data;
  dispatch(GetAllAdvisersLoaded(data));
};

export interface IGetAdviserConnectsLoaded extends Action {
  type: Types.GET_ADVISER_CONNECTS;
  data: IAdviserConnect[];
}

export interface IGetAdviserConnectLoaded extends Action {
  type: Types.GET_ADVISER_CONNECT_LOADED;
  data: IAdviserConnect;
}

export const GetAdviserConnectsLoaded = (data: IAdviserConnect[]) =>
  ({
    type: Types.GET_ADVISER_CONNECTS,
    data,
  } as IGetAdviserConnectsLoaded);

export const GetAdviserConnectLoaded = (data: IAdviserConnect) =>
  ({
    type: Types.GET_ADVISER_CONNECT_LOADED,
    data,
  } as IGetAdviserConnectLoaded);

export const GetAdviserConnects = () => async (dispatch) => {
  const result = await getAllAdviserUserConnects();
  const data: IAdviserConnect[] = result.data;
  dispatch(GetAdviserConnectsLoaded(data));
};

export const GetAdviserConnectsForCurrentUser = () => async (
  dispatch,
  getState,
) => {
  const { user }: IAppState = getState();
  const result = await getAllAdviserUserConnectsForUser(user.id);
  const data: IAdviserConnect[] = result.data;

  if (data.length > 0) {
    const premiumAdviserConnect = getPremiumAdviserConnect(data);
    if (premiumAdviserConnect) {
      const adRes = await getAdviserConnectByIdSk(
        premiumAdviserConnect.id,
        premiumAdviserConnect.sk,
      );
      dispatch(GetAdviserConnectLoaded(adRes.data));
    }
  }

  dispatch(GetAdviserConnectsLoaded(data));
};

export const CreateAdviserConnectPolicy = (
  id: string,
  sk: string,
  policy: ICreatePolicyProps,
) => async (dispatch) => {
  dispatch(AdviserLoading());
  try {
    await createPolicy(policy);
    // Fetch adviser connect again to get new policies
    const adRes = await getAdviserConnectByIdSk(id, sk);
    dispatch(GetAdviserConnectLoaded(adRes.data));
  } catch {
    dispatch(AdviserFailed());
  }
};

export const UpdateAdviserConnectPolicy = (
  id: string,
  sk: string,
  policy: IPolicy,
) => async (dispatch) => {
  dispatch(AdviserLoading());
  try {
    await updatePolicy(policy);
    // Fetch adviser connect again to get new policies
    const adRes = await getAdviserConnectByIdSk(id, sk);
    dispatch(GetAdviserConnectLoaded(adRes.data));
  } catch {
    dispatch(AdviserFailed());
  }
};

export interface ICreateAdviserConnectLoaded extends Action {
  type: Types.CREATE_ADVISER_CONNECT;
  data: IAdviserConnect;
}

export interface IUpdateAdviserConnectLoaded extends Action {
  type: Types.UPDATE_ADVISER_CONNECT;
  data: IAdviserConnect;
}

export interface IAdviserSubmitting extends Action {
  type: Types.ADVISER_SUBMITTING;
}

export interface IAdviserResetForm extends Action {
  type: Types.ADVISER_RESET_FORM;
}

export interface IGetClientsLoaded extends Action {
  type: Types.GET_ADVISER_CLIENTS;
  data: IUser[];
}

export interface IUpdateClientLoaded extends Action {
  type: Types.UPDATE_ADVISER_CLIENT;
  data: IUser;
}

export interface ICreateClientLoaded extends Action {
  type: Types.CREATE_ADVISER_CLIENT;
  data: IUser;
}

export interface ISelectClientLoaded extends Action {
  type: Types.SELECT_ADVISER_CLIENT;
  data: IUser;
}

export interface ICreateClientPolicyLoaded extends Action {
  type: Types.CREATE_ADVISER_CLIENT_POLICY;
  data: IPolicy;
}

export interface ICreateClientPolicyFailed extends Action {
  type: Types.CREATE_ADVISER_CLIENT_POLICY_FAILED;
  data: string;
}

export interface IUpdateClientPolicyLoaded extends Action {
  type: Types.UPDATE_ADVISER_CLIENT_POLICY;
  data: IPolicy;
}

export interface IDeleteClientPolicyLoaded extends Action {
  type: Types.DELETE_ADVISER_CLIENT_POLICY;
  data: { id: string; sk: string };
}

export interface IDeleteClientLoaded extends Action {
  type: Types.DELETE_ADVISER_CLIENT;
  data: string;
}

export interface ICreateClientPolicyLoading extends Action {
  type: Types.CREATE_ADVISER_CLIENT_POLICY_LOADING;
}

export const CreateAdviserConnectLoaded = (data: IAdviserConnect) =>
  ({
    type: Types.CREATE_ADVISER_CONNECT,
    data,
  } as ICreateAdviserConnectLoaded);

export const UpdateAdviserConnectLoaded = (data: IAdviserConnect) =>
  ({
    type: Types.UPDATE_ADVISER_CONNECT,
    data,
  } as IUpdateAdviserConnectLoaded);

export const CreateAdviserConnect = (payload: IConnectAdviserBody) => async (
  dispatch,
) => {
  dispatch(AdviserLoading());
  try {
    const result = await connectAdviser(payload as IConnectAdviserBody);
    const data: IAdviserConnect = result.data;
    dispatch(CreateAdviserConnectLoaded(data));
  } catch {
    dispatch(AdviserFailed());
  }
};

export const UpdateAdviserConnect = (payload: IAdviserConnect) => async (
  dispatch,
) => {
  dispatch(AdviserLoading());
  try {
    const result = await updateAdviserConnectUser(payload);
    dispatch(
      UpdateAdviserConnectLoaded({
        id: payload.id,
        sk: payload.sk,
        ...result.data.Attributes,
      }),
    );
  } catch {
    dispatch(AdviserFailed());
  }
};

export const AdviserSubmitting = () =>
  ({
    type: Types.ADVISER_SUBMITTING,
  } as IAdviserSubmitting);

export const AdviserSubmittingAction = () => (dispatch) => {
  dispatch(AdviserSubmitting());
};

export const AdviserResetForm = () =>
  ({
    type: Types.ADVISER_RESET_FORM,
  } as IAdviserResetForm);

export const CreateClientLoaded = (data: IUser) =>
  ({
    type: Types.CREATE_ADVISER_CLIENT,
    data,
  } as ICreateClientLoaded);

export const GetClientsLoaded = (data: IUser[]) =>
  ({
    type: Types.GET_ADVISER_CLIENTS,
    data,
  } as IGetClientsLoaded);

export const UpdateClientLoaded = (data: IUser) =>
  ({
    type: Types.UPDATE_ADVISER_CLIENT,
    data,
  } as IUpdateClientLoaded);

export const SelectClientLoaded = (data: IUser) =>
  ({
    type: Types.SELECT_ADVISER_CLIENT,
    data,
  } as ISelectClientLoaded);

export const CreateClientPolicyLoaded = (data: IPolicy) =>
  ({
    type: Types.CREATE_ADVISER_CLIENT_POLICY,
    data,
  } as ICreateClientPolicyLoaded);

export const CreateClientPolicyFailed = (data: string) =>
  ({
    type: Types.CREATE_ADVISER_CLIENT_POLICY_FAILED,
    data,
  } as ICreateClientPolicyFailed);

export const UpdateClientPolicyLoaded = (data: IPolicy) =>
  ({
    type: Types.UPDATE_ADVISER_CLIENT_POLICY,
    data,
  } as IUpdateClientPolicyLoaded);

export const CreateClientPolicyLoading = () =>
  ({
    type: Types.CREATE_ADVISER_CLIENT_POLICY_LOADING,
  } as ICreateClientPolicyLoading);

export const DeleteClientPolicyLoaded = (data) =>
  ({
    type: Types.DELETE_ADVISER_CLIENT_POLICY,
    data,
  } as IDeleteClientPolicyLoaded);

export const DeleteClientLoaded = (data: string) =>
  ({
    type: Types.DELETE_ADVISER_CLIENT,
    data,
  } as IDeleteClientLoaded);

export const CreateClient = (client: IUser) => async (dispatch) => {
  dispatch(AdviserSubmitting());
  const { data } = await createClient(client);
  const c = R.assoc('policies', [], data[0]);
  dispatch(CreateClientLoaded(c));
};

export const GetClients = () => async (dispatch) => {
  const { data } = await getClients();
  dispatch(GetClientsLoaded(data));
};

export const UpdateClient = (client: IUser) => async (dispatch) => {
  dispatch(UpdateClientLoaded(client));
  updateClient(client);
};

export const SelectClient = (client: IUser) => (dispatch) => {
  dispatch(SelectClientLoaded(client));
  history.push(CLIENTS_DETAILS_ROUTE.replace(':id', client.id));
};

export const InviteClient = (client: IUser) => (dispatch) => {
  const { message, ...clientData } = client;
  inviteClient({ id: clientData.id, message });
  const c = R.assoc(
    'status',
    ClientInviteStatus.INVITATION_PENDING,
    clientData,
  );
  dispatch(UpdateClientLoaded(c));
};

export const CreateClientPolicyWithAttachments = (
  policy: ICreatePolicyProps,
  attachments: IFile[],
) => async (dispatch) => {
  try {
    dispatch(CreateClientPolicyLoading());
    const newPolicy = await createClientPolicyWithAttachments(
      policy,
      attachments,
    );
    dispatch(CreateClientPolicyLoaded(newPolicy));
  } catch {
    dispatch(CreateClientPolicyFailed(UPLOAD_FAILED_MESSAGE));
  }
};

// Update consent when a policy has been updated to hide/show the activate button
const reloadConsent = async (familyId, dispatch, getState) => {
  const { adviser } = getState();
  const ownerConsent = adviser.consents.find(
    R.propEq('invitedUserDefaultFamilyId', familyId),
  );
  if (ownerConsent) {
    const { data: consentData } = await getConsentById({
      id: ownerConsent.id,
      sk: ownerConsent.sk,
    });
    dispatch(UpdateConsentLoaded(consentData));
  }
};

export const CreateClientPolicy = (policy: ICreatePolicyProps) => async (
  dispatch,
  getState,
) => {
  dispatch(CreateClientPolicyLoading());
  const result = await createClientPolicy({ ...policy, effective: true });
  const data: IPolicy = result.data;
  dispatch(CreateClientPolicyLoaded(data));
  reloadConsent(policy.familyId, dispatch, getState);
};

export const UpdateClientPolicy = (
  policy: IPolicy,
  submitted: boolean,
) => async (dispatch, getState) => {
  dispatch(CreateClientPolicyLoading());
  const { data } = await updateClientPolicy(policy);
  dispatch(UpdateClientPolicyLoaded(R.assoc('submitted', submitted, data)));
  reloadConsent(policy.familyId, dispatch, getState);
};

export const UpdateClientPolicyWithAttachments = (
  policy: IPolicy,
  attachments,
) => async (dispatch) => {
  try {
    dispatch(CreateClientPolicyLoading());
    updateClientPolicyWithAttachments(policy, attachments);
    dispatch(UpdateClientPolicyLoaded(policy));
  } catch {
    dispatch(CreateClientPolicyFailed(UPLOAD_FAILED_MESSAGE));
  }
};

export const DeleteClientPolicy = (id: string, sk: string) => async (
  dispatch,
  getState,
) => {
  const data = { id, sk };
  dispatch(DeleteClientPolicyLoaded(data));
  await deleteClientPolicy(data);
  reloadConsent(sk, dispatch, getState);
};

export const DeleteClient = (id: string) => async (dispatch) => {
  deleteUserById(id);
  dispatch(DeleteClientLoaded(id));
};

export interface IGetConsentsLoaded extends Action {
  type: Types.GET_CONSENTS_LOADED;
  data: IConsent[];
}

export const GetConsentsLoaded = (data: IConsent[]) =>
  ({
    type: Types.GET_CONSENTS_LOADED,
    data,
  } as IGetConsentsLoaded);

export const GetConsents = () => async (dispatch) => {
  const { data } = await getConsents();
  dispatch(GetConsentsLoaded(data));
};

export interface ICreateConsentsLoaded extends Action {
  type: Types.CREATE_CONSENTS_LOADED;
  data: IConsent[];
}

export const CreateConsentsLoaded = (data: IConsent[]) =>
  ({
    type: Types.CREATE_CONSENTS_LOADED,
    data,
  } as ICreateConsentsLoaded);

export interface ICreateConsentsFailed extends Action {
  type: Types.CREATE_CONSENTS_FAILED;
  data: any;
}

export const CreateConsentsFailed = (data: any) =>
  ({
    type: Types.CREATE_CONSENTS_FAILED,
    data,
  } as ICreateConsentsFailed);

export interface IUpdateConsentLoaded extends Action {
  type: Types.UPDATE_CONSENT_LOADED;
  data: IConsent;
}

export const UpdateConsentLoaded = (data: IConsent) =>
  ({
    type: Types.UPDATE_CONSENT_LOADED,
    data,
  } as IUpdateConsentLoaded);

export const CreateConsents = (consents: IConsent[]) => async (dispatch) => {
  dispatch(AdviserSubmitting());
  try {
    const { data } = await createConsents(consents);
    dispatch(CreateConsentsLoaded(data.consents));
  } catch ({ response }) {
    dispatch(CreateConsentsFailed(response.data));
  }
};

export interface IDeleteConsentLoaded extends Action {
  type: Types.DELETE_CONSENT_LOADED;
  data: { id: string; sk: string };
}

export const DeleteConsentLoaded = (data: { id: string; sk: string }) =>
  ({
    type: Types.DELETE_CONSENT_LOADED,
    data,
  } as IDeleteConsentLoaded);

export const DeleteConsent = (id: string, sk: string) => async (dispatch) => {
  const data = { id, sk };
  deleteConsent(data);
  dispatch(DeleteConsentLoaded(data));
};

export interface ICalendlyEventCreated extends Action {
  type: Types.CALENDY_EVENT_CREATED;
  data: {
    eventUrl: string;
  };
}

export const CalendlyEventCreated = (data: { eventUrl: string }) =>
  ({
    type: Types.CALENDY_EVENT_CREATED,
    data,
  } as ICalendlyEventCreated);

export interface IAdviserConnectInfoUpdated extends Action {
  type: Types.ADVISER_CONNECT_INFO_UPDATED;
  data: {
    helpWith: string;
    interestedIn: string[];
    preferredContact: string;
    preferredContactDetails: string;
  };
}

export const AdviserConnectInfoUpdated = (data: {
  helpWith: string;
  interestedIn: string[];
  preferredContact: string;
  preferredContactDetails: string;
}) =>
  ({
    type: Types.ADVISER_CONNECT_INFO_UPDATED,
    data,
  } as IAdviserConnectInfoUpdated);
export interface ICalendarLoading extends Action {
  type: Types.CALENDY_LOADING;
  data: boolean;
}

export const CalendarLoading = (loading: boolean) =>
  ({
    type: Types.CALENDY_LOADING,
    data: loading,
  } as ICalendarLoading);

export interface IAdviserLoading {
  type: Types.ADVISER_LOADING;
}

export interface IAdviserFailed {
  type: Types.ADVISER_FAILED;
}

export interface IAdviserInquiryLoaded {
  type: Types.ADVISER_INQUIRY_LOADED;
}

export const AdviserLoading = () =>
  ({
    type: Types.ADVISER_LOADING,
  } as IAdviserLoading);

export const AdviserFailed = () =>
  ({
    type: Types.ADVISER_FAILED,
  } as IAdviserFailed);

export const AdviserInquiryLoaded = () =>
  ({
    type: Types.ADVISER_INQUIRY_LOADED,
  } as IAdviserInquiryLoaded);

export const CreateAdviserInquiry = (message) => async (dispatch) => {
  dispatch(AdviserLoading());
  try {
    await createAdviserInquiry({ message });
    dispatch(AdviserInquiryLoaded());
  } catch {
    ErrorToast.error({ message: INQUIRY_ERROR });
    dispatch(AdviserFailed());
  }
};

export const CreateCalendlyEvent = (
  calendlyEvent: ICalendlyIFrameEvent,
) => async (dispatch, getState) => {
  const { adviser, user } = getState();
  const adviserConnectInfo = adviser.adviserConnectInfo || {};
  const payload = {
    email: user.email,
    eventUrl: calendlyEvent.payload.event.uri,
    ...adviserConnectInfo,
  };

  dispatch(AdviserSubmitting());
  dispatch(CreateAdviserConnect(payload as IConnectAdviserBody));
  dispatch(
    CalendlyEventCreated({
      eventUrl: calendlyEvent.payload.event.uri,
    }),
  );
};

export const UploadAdviserUserConnectAttachments = (
  id: string,
  sk: string,
  files: IFile[],
) => async (dispatch) => {
  dispatch(AdviserLoading());
  try {
    await uploadAdviserConnectAttachments(files, id);
    // Fetch adviser connect again to get new policies
    const adRes = await getAdviserConnectByIdSk(id, sk);
    dispatch(GetAdviserConnectLoaded(adRes.data));
  } catch {
    dispatch(AdviserFailed());
  }
};

export const GetAdviserConnectByIdSk = (id: string, sk: string) => async (
  dispatch,
) => {
  dispatch(AdviserLoading());
  try {
    const adRes = await getAdviserConnectByIdSk(id, sk);
    dispatch(GetAdviserConnectLoaded(adRes.data));
  } catch {
    dispatch(AdviserFailed());
  }
};

export const AddToCart = (data) => async (dispatch, getState) => {
  dispatch(AdviserLoading());
  try {
    const {
      adviser: { premiumAdviserConnect },
    } = getState();

    if (premiumAdviserConnect) {
      const isNotRecommended = !data.selectedProvider.recommended;
      const cart = { ...premiumAdviserConnect.addedToCart };

      if (
        cart[data.id]?.policyProviderId ===
          data.selectedProvider.policyProviderId ||
        isNotRecommended
      ) {
        delete cart[data.id];
      } else {
        cart[data.id] = {
          ...data.selectedProvider,
          policyScanId: data.id,
          policyId: data.sk,
          policyType: data.policyType,
        };
      }

      const payload = {
        addedToCart: cart,
        id: premiumAdviserConnect.id,
        sk: premiumAdviserConnect.sk,
      };

      // Update selectedProvider of MS
      await updatePolicyScan(data);
      await updateAdviserConnectUser(payload);

      dispatch(
        GetAdviserConnectByIdSk(
          premiumAdviserConnect.id,
          premiumAdviserConnect.sk,
        ),
      );

      // Go back to premium page and refresh when selected is not recommended
      if (isNotRecommended) {
        history.push(PREMIUM_ROUTE);
      } else if (cart[data.id]) {
        dispatch(AddToCartLoaded(cart[data.id]));
      }
    }
  } catch {
    dispatch(AdviserFailed());
  }
};

export const RemoveFromCart = (policyScanId: string) => async (
  dispatch,
  getState,
) => {
  dispatch(AdviserLoading());
  try {
    const {
      adviser: { premiumAdviserConnect },
    } = getState();

    if (premiumAdviserConnect) {
      const addedToCart = R.dissoc(
        policyScanId,
        premiumAdviserConnect.addedToCart,
      );

      const payload = {
        addedToCart,
        id: premiumAdviserConnect.id,
        sk: premiumAdviserConnect.sk,
      };

      if (R.isEmpty(addedToCart) || R.isNil(addedToCart)) {
        history.push(PREMIUM_ROUTE);
      }

      const result = await updateAdviserConnectUser(payload);

      dispatch(
        UpdateAdviserConnectLoaded({
          id: payload.id,
          sk: payload.sk,
          ...result.data.Attributes,
        }),
      );
    }
  } catch {
    dispatch(AdviserFailed());
  }
};
