import Loadable from 'react-loadable';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/loading';
import {
  CONSENTS_ROUTE,
  WINDCAVE_CALLBACK,
  CLIENTS_ROUTE,
  CLIENTS_NEW_ROUTE,
  CLIENTS_DETAILS_POLICY_ROUTE,
  ADD_CLIENT_POLICY_ROUTE,
  EDIT_CLIENT_POLICY_ROUTE,
  ADD_POLICY_ROUTE,
  EDIT_POLICY_ROUTE,
  EXPLORE_COVERAGE_ROUTE,
  EXPLORE_ADVISER,
  SHARE_ROUTE,
  OPERATIONS_ROUTE,
  RENEWAL_ROUTE,
  POLICY_DETAILS_ROUTE,
  MARKET_SCAN_PURCHASE_ROUTE,
  MARKET_SCAN_ROUTE,
  MARKET_SCAN_RESULTS_ROUTE,
  PREMIUM_ROUTE,
  PREMIUM_QUOTE_ROUTE,
  CHECKOUT_ROUTE,
} from 'constants/routes';
import PremiumContainer from 'containers/premium';
import user from 'containers/user';
import WindcaveCallback from 'containers/windcave-callback';
import { UserRoles } from 'constants/user';
import { useAuth0 } from '../react-auth0-spa';

export const AdviserDashboardRoute = Loadable({
  loader: () => import('containers/adviser-dashboard'),
  loading: Loading,
});

export const UserRoute = Loadable({
  loader: () => import('containers/user'),
  loading: Loading,
});

export const NotificationRoute = Loadable({
  loader: () => import('containers/notifications'),
  loading: Loading,
});

export const AdminRoute = Loadable({
  loader: () => import('containers/admin'),
  loading: Loading,
});
export const OperationsRoute = Loadable({
  loader: () => import('containers/operations'),
  loading: Loading,
});

export const AddEditPolicyRoute = Loadable({
  loader: () => import('containers/add-edit-policy/AddEditPolicyContainer'),
  loading: Loading,
});

export const ExporeRoute = Loadable({
  loader: () => import('containers/explore'),
  loading: Loading,
});

export const InvitationRoute = Loadable({
  loader: () => import('containers/invitation'),
  loading: Loading,
});

export const ShareRoute = Loadable({
  loader: () => import('containers/share'),
  loading: Loading,
});

export const RenewalUpdateRoute = Loadable({
  loader: () => import('containers/renewal-update'),
  loading: Loading,
});

export const PoliciesRoute = Loadable({
  loader: () => import('containers/policies'),
  loading: Loading,
});

export const MarketScanRoute = Loadable({
  loader: () => import('containers/market-scan'),
  loading: Loading,
});

export const MarketScanResultsRoute = Loadable({
  loader: () => import('containers/market-scan-results'),
  loading: Loading,
});

export const AddClientsRoute = Loadable({
  loader: () => import('containers/add-clients'),
  loading: Loading,
});

export const ClientDetailsRoute = Loadable({
  loader: () => import('containers/client-details'),
  loading: Loading,
});

export const AddEditClientsPolicyRoute = Loadable({
  loader: () =>
    import('containers/add-edit-policy/AddEditClientPolicyContainer'),
  loading: Loading,
});

export const ConsentsRoute = Loadable({
  loader: () => import('containers/consent'),
  loading: Loading,
});

export const GetAdviceRoute = Loadable({
  loader: () => import('containers/add-clients/get-advice'),
  loading: Loading,
});

export const InsuranceCheckupRoute = Loadable({
  loader: () => import('containers/insurance-checkup'),
  loading: Loading,
});

export const MarketScanPurchaseRoute = Loadable({
  loader: () => import('containers/market-scan-purchase'),
  loading: Loading,
});

export const PolicyDetailsRoute = Loadable({
  loader: () => import('containers/policy-details'),
  loading: Loading,
});

export const PremiumRoute = Loadable({
  loader: () => import('containers/premium'),
  loading: Loading,
});

export const CheckoutRoute = Loadable({
  loader: () => import('containers/checkout'),
  loading: Loading,
});

export const OnboardingRoute = Loadable({
  loader: () => import('containers/onboarding'),
  loading: Loading,
});

export const DefaultRoutes = () => {
  const { hasRole } = useAuth0();
  return (
    <Switch>
      <Route
        exact={true}
        path="/"
        render={() => {
          if (hasRole(UserRoles.ADVISER)) {
            return <AdviserDashboardRoute summary={true} />;
          }
          return <PoliciesRoute />;
        }}
      />
      <Route path={CONSENTS_ROUTE}>
        <ConsentsRoute />
      </Route>
      <Route path={WINDCAVE_CALLBACK}>
        <WindcaveCallback />
      </Route>
      <Route exact={true} path={CLIENTS_ROUTE}>
        <AdviserDashboardRoute />
      </Route>
      <Route exact={true} path={CLIENTS_NEW_ROUTE}>
        <AddClientsRoute />
      </Route>
      <Route path={CLIENTS_DETAILS_POLICY_ROUTE}>
        <ClientDetailsRoute />
      </Route>
      <Route path={[ADD_CLIENT_POLICY_ROUTE, EDIT_CLIENT_POLICY_ROUTE]}>
        <AddEditClientsPolicyRoute />
      </Route>
      <Route path="/profile">
        <UserRoute user={user} />
      </Route>
      <Route path="/notification">
        <NotificationRoute />
      </Route>
      <Route path={[ADD_POLICY_ROUTE, EDIT_POLICY_ROUTE]}>
        <AddEditPolicyRoute />
      </Route>
      <Route path={EXPLORE_COVERAGE_ROUTE}>
        <InsuranceCheckupRoute />
      </Route>
      <Route path={EXPLORE_ADVISER}>
        <GetAdviceRoute />
      </Route>
      <Route path="/explore">
        <ExporeRoute />
      </Route>
      <Route path="/invitation">
        <InvitationRoute />
      </Route>
      <Route path={SHARE_ROUTE}>
        <ShareRoute />
      </Route>
      <Route
        path="/admin"
        render={() => {
          if (hasRole(UserRoles.ADMIN)) {
            return <AdminRoute />;
          }
          return <Redirect to="/" />;
        }}
      />
      <Route
        path={OPERATIONS_ROUTE}
        render={() => {
          if (hasRole(UserRoles.ADMIN)) {
            return <OperationsRoute />;
          }
          return <Redirect to="/" />;
        }}
      />
      <Route path={RENEWAL_ROUTE}>
        <RenewalUpdateRoute />
      </Route>
      <Route path={POLICY_DETAILS_ROUTE}>
        <PolicyDetailsRoute />
      </Route>
      <Route path={MARKET_SCAN_PURCHASE_ROUTE}>
        <MarketScanPurchaseRoute />
      </Route>
      <Route path={MARKET_SCAN_ROUTE}>
        <MarketScanRoute />
      </Route>
      <Route path={CHECKOUT_ROUTE}>
        <CheckoutRoute />
      </Route>
      <Route path={[MARKET_SCAN_RESULTS_ROUTE, PREMIUM_QUOTE_ROUTE]}>
        <MarketScanResultsRoute />
      </Route>
      <Route path={PREMIUM_ROUTE}>
        <PremiumContainer />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export const PremiumOnboardingIncompleteRoutes = () => (
  <Switch>
    <Route path={CHECKOUT_ROUTE}>
      <CheckoutRoute />
    </Route>
    <Route path={[MARKET_SCAN_RESULTS_ROUTE, PREMIUM_QUOTE_ROUTE]}>
      <MarketScanResultsRoute />
    </Route>
    <Route path={PREMIUM_ROUTE}>
      <PremiumContainer />
    </Route>
    <Redirect to={PREMIUM_ROUTE} />
  </Switch>
);
