// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3xKVPHahnSYqbVfEpOKO65{width:40px;height:40px;border-radius:50%;background-color:#34363e;display:flex;align-items:center;justify-content:center;cursor:pointer}._3xKVPHahnSYqbVfEpOKO65 svg{stroke:#fff}._3xKVPHahnSYqbVfEpOKO65:hover{opacity:.9}", ""]);
// Exports
exports.locals = {
	"button": "_3xKVPHahnSYqbVfEpOKO65"
};
module.exports = exports;
