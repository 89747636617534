import React from 'react';
import DatePicker from 'components/date-picker';
import styles from './formField.scss';

export interface IDatePickerBordered {
  required?: boolean;
  value: string;
  onChange?: (e: React.SyntheticEvent) => void;
  className?: string;
  dateClassName?: string;
  labelClassName?: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  min?: Date;
  max?: Date;
}

const DatePickerBordered = ({
  required,
  value,
  onChange,
  className,
  min,
  max,
  labelClassName,
  label,
  disabled,
  error,
  name,
  placeholder,
}) => {
  return (
    <div className={classnames(styles.datepickerContainer, error && styles.error, className)}>
      <span className={classnames(styles.label, labelClassName)}>
        {required && <span className={styles.required}>{"* "}</span>}
        {label}
      </span>
      <DatePicker
        className={styles.datepicker}
        name={name}
        onChange={onChange}
        value={value}
        inputProps={{ required }}
        disabled={disabled}
        min={min}
        max={max}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DatePickerBordered;