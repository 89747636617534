import axios from 'axios';
import { getClaimsToken } from '../helpers';

declare const AUTH0_DOMAIN: string;
declare const AUTH0_CLIENT_ID: string;
declare const BASE_URL: string;
declare const EXTERNAL_URL: string;

const logoutAndClearSession = () => {
  localStorage.removeItem('USER_CLAIMS_TOKEN');
  // Auth0 endpoint to logout user: https://auth0.com/docs/api/authentication#logout
  window.location.href = `https://${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${window.location.origin}`;
};

export interface IIdSkPayload {
  id: string;
  sk: string;
}

export const getHttpService = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutAndClearSession();
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export const getHttpServiceAdmin = () => {
  const instance = axios.create({
    baseURL: BASE_URL + '/admin',
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutAndClearSession();
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export interface IHeaderOption {
  'content-type': string;
  Authorization: string;
}

export const getHeaderOptions = () => {
  const claims = getClaimsToken();
  const headerOption: IHeaderOption = {
    'content-type': 'application/json',
    Authorization: `Bearer ${claims.__raw}`,
  };
  return headerOption;
};

export const getHttpServiceExternal = () => {
  const instance = axios.create({
    baseURL: EXTERNAL_URL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logoutAndClearSession();
      }
      return Promise.reject(error);
    },
  );

  return instance;
};
