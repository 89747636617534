// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._1spz-NKKgsV5a7mjoizQsn{width:80%}._1spz-NKKgsV5a7mjoizQsn.ui.modal .content{padding:48px 0}._20dntJ2MZncO3RTMMDdVpu{display:flex;flex-direction:column;align-items:center}._1jtaoKdB1GZKhd-4QbwO10,.VTyAaH_Rv_qhSA2WdGkLA,._1i7VfKEnamlMyywsJUlCzL{width:60%;text-align:center}._1jtaoKdB1GZKhd-4QbwO10{display:flex;flex-direction:column;align-items:center;font-family:\"Open Sans\";font-weight:400;color:#34363e;font-size:14px}._1jtaoKdB1GZKhd-4QbwO10>svg{width:80px;height:auto}._1jtaoKdB1GZKhd-4QbwO10>h2{font-family:\"DM Sans\";font-weight:700;color:#4187f5;font-size:24px;margin-top:16px}.VTyAaH_Rv_qhSA2WdGkLA{margin:8px 0}.VTyAaH_Rv_qhSA2WdGkLA>div{margin:8px 0}.VTyAaH_Rv_qhSA2WdGkLA i{margin-left:8px}._4FzEe0m1OFigwX4HbFQm8 input{border:1px solid #000;border-radius:10px;height:35px;text-align:left !important;width:100% !important;padding:0 24px !important}._4FzEe0m1OFigwX4HbFQm8 input::placeholder{text-align:left !important}._3LsRgEk3GFktd6__tf2pSn.ui.button{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"DM Sans\";font-weight:700;font-size:14px;background-color:#34363e;color:#fafafa;width:100%}@media(max-width: 576px){._3LsRgEk3GFktd6__tf2pSn.ui.button{font-size:14px;padding-top:13px;padding-bottom:13px}}@media(max-width: 820px){._1spz-NKKgsV5a7mjoizQsn.ui.modal{width:95%}._1jtaoKdB1GZKhd-4QbwO10,.VTyAaH_Rv_qhSA2WdGkLA,._1i7VfKEnamlMyywsJUlCzL{width:95%}}", ""]);
// Exports
exports.locals = {
	"modal": "_1spz-NKKgsV5a7mjoizQsn",
	"container": "_20dntJ2MZncO3RTMMDdVpu",
	"header": "_1jtaoKdB1GZKhd-4QbwO10",
	"questions": "VTyAaH_Rv_qhSA2WdGkLA",
	"actions": "_1i7VfKEnamlMyywsJUlCzL",
	"field": "_4FzEe0m1OFigwX4HbFQm8",
	"submit": "_3LsRgEk3GFktd6__tf2pSn"
};
module.exports = exports;
