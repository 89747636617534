// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._3POa9Cps0BDDbtakt80bvE{background-color:#fff;padding:16px;border-radius:5px}._3VjGMJ5UGFC_dYH1Dfobs6{display:flex;justify-content:flex-end}._3WhppGdL3V4onLrIGmwIka{padding:8px 16px;margin-top:16px;width:auto}._1L1_1Ab22RbtT2EI83AUIn{display:inline;margin-left:8px;text-decoration:underline !important;color:#3d67ab;cursor:pointer}@media(max-width: 576px){._1L1_1Ab22RbtT2EI83AUIn{display:block;margin-left:0}}", ""]);
// Exports
exports.locals = {
	"calcContainer": "_3POa9Cps0BDDbtakt80bvE",
	"btnContainer": "_3VjGMJ5UGFC_dYH1Dfobs6",
	"back": "_3WhppGdL3V4onLrIGmwIka",
	"calcLabel": "_1L1_1Ab22RbtT2EI83AUIn"
};
module.exports = exports;
