import React from 'react';
import DOMPurify from 'dompurify';
import { ChevronDown } from 'react-feather';
import { Dropdown } from 'semantic-ui-react';
import FormField from 'components/form-field';
import { IPolicyProvider, PolicyArrangementType } from 'types';
import styles from './styles/q4e.scss';
import { INCOME_FREQUENCY_OPTIONS } from 'constants/options';

const getMultiplierText = (multiplier) => {
  if (multiplier < 1) {
    return `${multiplier * 100}%`;
  }
  return `${multiplier}x`;
};

const FreePolicy = ({
  policy,
  provider,
  values,
  onInputChange,
  onDropdownChange,
}) => {
  const {
    note,
    showIncomeFrequency,
    multiplier,
    policyTypeName,
    hideInput,
    benefit,
  } = policy;
  return (
    <div className={styles.policy}>
      <img className={styles.logo} alt={provider.name} src={provider.logoUrl} />
      <div className={styles.policyTitle}>{`${policyTypeName} insurance`}</div>
      <div className={styles.policyFields}>
        {!hideInput && (
          <FormField
            name="income"
            className={styles.policyField}
            labelClassName={styles.policyLabel}
            inputClassName={styles.policyInput}
            value={values.income}
            onChange={onInputChange}
            placeholder={`Enter your ${
              showIncomeFrequency ? '' : 'annual '
            }salary`}
            prefix="$"
            inputType="number"
          />
        )}
        {showIncomeFrequency && (
          <Dropdown
            name="incomeFrequency"
            placeholder="Select pay frequency"
            selection={true}
            options={INCOME_FREQUENCY_OPTIONS}
            className={styles.policyDropdown}
            icon={<ChevronDown size={16} />}
            onChange={onDropdownChange}
            value={values.incomeFrequency}
          />
        )}
        {benefit && (
          <div className={styles.benefit}>
            <b>Benefit: </b>
            {benefit}
          </div>
        )}
      </div>
      {note !== false && (
        <div className={styles.note}>
          {note ? (
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note) }}
            />
          ) : (
            `Your free ${policyTypeName} insurance cover is for ${getMultiplierText(
              multiplier,
            )} your yearly salary`
          )}
        </div>
      )}
    </div>
  );
};

const DiscountPolicy = ({ policy, provider }) => {
  const { policyTypeName, note } = policy;
  const { name } = provider;
  return (
    <div className={styles.policy}>
      <img className={styles.logo} alt={provider.name} src={provider.logoUrl} />
      <div className={styles.bold}>{`${policyTypeName} insurance`}</div>
      <div>{`Get discounted ${name} ${policyTypeName.toLowerCase()} insurance.`}</div>
      {note && <div>{note}</div>}
    </div>
  );
};

const PolicyComponentMap = {
  [PolicyArrangementType.DISCOUNT]: DiscountPolicy,
  [PolicyArrangementType.FREE]: FreePolicy,
};

interface IProps {
  policy;
  provider: IPolicyProvider;
  values: any;
  onInputChange(e): void;
  onDropdownChange(_, data): void;
}

const Q4EPolicy = (props: IProps) => {
  if (
    !props.provider ||
    props.policy.arrangementType === PolicyArrangementType.DISCOUNT
  ) {
    return null;
  }

  const Component = PolicyComponentMap[props.policy.arrangementType];
  return Component && <Component {...props} />;
};

export default Q4EPolicy;
