import { Action } from 'redux';
import { resolveReferenceData } from 'helpers';
import {
  createPolicySubType,
  createPolicyType,
  getAllForms,
  getAllPolicyProviders,
  getAllPolicySubTypes,
  getAllPolicyTypes,
  getCreditCardBenefits,
  getInsuranceCheckupReference,
  getPolicyScanReference,
  ICreatePolicySubTypeProps,
  ICreatePolicyTypeProps,
} from 'services';
import {
  ICreditCard,
  IAppState,
  IPolicyProvider,
  IPolicyScanReference,
  IPolicyType,
  IPolicySubType,
  IEmployer,
  IWiki,
  ICheckupReference,
  IFormDefinition,
} from 'types';

export enum Types {
  GET_CREDIT_CARD_BENEFITS_LOADED = 'GET_CREDIT_CARD_BENEFITS_LOADED',
  GET_ALL_POLICY_PROVIDERS_LOADED = 'GET_ALL_POLICY_PROVIDERS_LOADED',
  GET_ALL_POLICY_TYPES_LOADED = 'GET_ALL_POLICY_TYPES_LOADED',
  GET_ALL_POLICY_SUB_TYPES_LOADED = 'GET_ALL_POLICY_SUB_TYPES_LOADED',
  GET_FORM_DEFINITION_REFERENCE_LOADED = 'GET_FORM_DEFINITION_REFERENCE_LOADED',
  GET_EMPLOYERS_LOADED = 'GET_EMPLOYERS_LOADED',
  GET_WIKI_LOADED = 'GET_WIKI_LOADED',
  GET_POLICY_SCAN_REFERENCE_LOADED = 'GET_POLICY_SCAN_REFERENCE_LOADED',
  GET_INURANCE_CHECKUP_REFERENCE_LOADED = 'GET_INURANCE_CHECKUP_REFERENCE_LOADED',
  CREATE_POLICY_TYPE_LOADED = 'CREATE_POLICY_TYPE_LOADED',
  CREATE_POLICY_SUB_TYPE_LOADED = 'CREATE_POLICY_SUB_TYPE_LOADED',
}

export interface IGetCreditCardBenefitsDataLoaded extends Action {
  type: Types.GET_CREDIT_CARD_BENEFITS_LOADED;
  data: ICreditCard[];
}

export interface IGetAllPolicyProvidersLoaded extends Action {
  type: Types.GET_ALL_POLICY_PROVIDERS_LOADED;
  data: IPolicyProvider[];
}

export interface IGetAllPolicyTypesLoaded extends Action {
  type: Types.GET_ALL_POLICY_TYPES_LOADED;
  data: IPolicyType[];
}

export interface IGetAllPolicySubTypesLoaded extends Action {
  type: Types.GET_ALL_POLICY_SUB_TYPES_LOADED;
  data: IPolicySubType[];
}

export interface IGetEmployersLoaded extends Action {
  type: Types.GET_EMPLOYERS_LOADED;
  data: IEmployer[];
}

export interface IGetWikiLoaded extends Action {
  type: Types.GET_WIKI_LOADED;
  data: IWiki[];
}

export interface IGetPolicyScanReferenceLoaded extends Action {
  type: Types.GET_POLICY_SCAN_REFERENCE_LOADED;
  data: IPolicyScanReference;
}

export interface IInsuranceCheckupReferenceLoaded extends Action {
  type: Types.GET_INURANCE_CHECKUP_REFERENCE_LOADED;
  data: ICheckupReference;
}

export interface ICeatePolicyTypeLoaded extends Action {
  type: Types.CREATE_POLICY_TYPE_LOADED;
  data: IPolicyType;
}

export interface ICreatePolicySubTypeLoaded extends Action {
  type: Types.CREATE_POLICY_SUB_TYPE_LOADED;
  data: IPolicySubType;
}

export interface IGetFormDefinitionReferenceLoaded extends Action {
  type: Types.GET_FORM_DEFINITION_REFERENCE_LOADED;
  data: IFormDefinition[];
}

export const GetCreditCardBenefitsDataLoaded = (data: ICreditCard[]) =>
  ({
    type: Types.GET_CREDIT_CARD_BENEFITS_LOADED,
    data,
  } as IGetCreditCardBenefitsDataLoaded);

export const GetAllPolicyProvidersLoaded = (data: IPolicyProvider[]) =>
  ({
    type: Types.GET_ALL_POLICY_PROVIDERS_LOADED,
    data,
  } as IGetAllPolicyProvidersLoaded);

export const GetAllPolicyTypesLoaded = (data: IPolicyType[]) =>
  ({
    type: Types.GET_ALL_POLICY_TYPES_LOADED,
    data,
  } as IGetAllPolicyTypesLoaded);

export const GetAllPolicySubTypesLoaded = (data: IPolicySubType[]) =>
  ({
    type: Types.GET_ALL_POLICY_SUB_TYPES_LOADED,
    data,
  } as IGetAllPolicySubTypesLoaded);

export const GetEmployersLoaded = (data: IEmployer[]) =>
  ({
    type: Types.GET_EMPLOYERS_LOADED,
    data,
  } as IGetEmployersLoaded);

export const GetWikiLoaded = (data: IWiki[]) =>
  ({
    type: Types.GET_WIKI_LOADED,
    data,
  } as IGetWikiLoaded);

export const GetInsuranceCheckupReferenceLoaded = (data: ICheckupReference) =>
  ({
    type: Types.GET_INURANCE_CHECKUP_REFERENCE_LOADED,
    data,
  } as IInsuranceCheckupReferenceLoaded);

export const GetCreditCardBenefitsData = () => async (
  dispatch: any,
  getState: any,
) => {
  const { config }: IAppState = getState();
  const data: ICreditCard[] = await resolveReferenceData(
    config,
    'creditCards',
    getCreditCardBenefits,
  );
  dispatch(GetCreditCardBenefitsDataLoaded(data));
};

export const GetPolicyScanReferenceLoaded = (data: IPolicyScanReference) =>
  ({
    type: Types.GET_POLICY_SCAN_REFERENCE_LOADED,
    data,
  } as IGetPolicyScanReferenceLoaded);

export const GetFormDefinitionReferenceLoaded = (data: IFormDefinition[]) =>
  ({
    type: Types.GET_FORM_DEFINITION_REFERENCE_LOADED,
    data,
  } as IGetFormDefinitionReferenceLoaded);

export const CeatePolicyTypeLoaded = (data: IPolicyType) =>
  ({
    type: Types.CREATE_POLICY_TYPE_LOADED,
    data,
  } as ICeatePolicyTypeLoaded);

export const CeatePolicySubTypeLoaded = (data: IPolicySubType) =>
  ({
    type: Types.CREATE_POLICY_SUB_TYPE_LOADED,
    data,
  } as ICreatePolicySubTypeLoaded);

export const GetAllPolicyProvider = () => async (
  dispatch: any,
  getState: any,
) => {
  const { config }: IAppState = getState();
  const data: IPolicyProvider[] = await resolveReferenceData(
    config,
    'policyProviders',
    getAllPolicyProviders,
  );
  dispatch(GetAllPolicyProvidersLoaded(data));
};

export const GetAllPolicyTypes = () => async (dispatch, getState) => {
  const { config }: IAppState = getState();
  const data: IPolicyType[] = await resolveReferenceData(
    config,
    'policyTypes',
    getAllPolicyTypes,
  );
  dispatch(GetAllPolicyTypesLoaded(data));
};

export const GetAllPolicySubTypes = () => async (dispatch) => {
  const result = await getAllPolicySubTypes();
  const data: IPolicySubType[] = result.data.Items;
  dispatch(GetAllPolicySubTypesLoaded(data));
};

export const GetEmployers = () => async (dispatch: any, getState: any) => {
  const { config }: IAppState = getState();
  const asyncFn = () =>
    Promise.resolve(
      R.assocPath(['data', 'Items'], config.q4eEmployers, config),
    );
  const data: IEmployer[] = await resolveReferenceData(
    config,
    'q4eEmployers',
    asyncFn,
  );
  dispatch(GetEmployersLoaded(data));
};

export const GetWiki = () => async (dispatch: any, getState: any) => {
  const { config }: IAppState = getState();
  const asyncFn = () =>
    Promise.resolve(R.assocPath(['data', 'Items'], config.wiki, config));
  const data: IWiki[] = await resolveReferenceData(config, 'wiki', asyncFn);
  dispatch(GetWikiLoaded(data));
};

export const GetInsuranceCheckupReference = () => async (
  dispatch: any,
  getState: any,
) => {
  const { config }: IAppState = getState();
  const data: ICheckupReference = await resolveReferenceData(
    config,
    'checkup',
    getInsuranceCheckupReference,
  );
  dispatch(GetInsuranceCheckupReferenceLoaded(data));
};

export const GetPolicyScanReference = () => async (
  dispatch: any,
  getState: any,
) => {
  const { config }: IAppState = getState();
  const data: IPolicyScanReference = await resolveReferenceData(
    config,
    'policyScan',
    getPolicyScanReference,
  );
  dispatch(GetPolicyScanReferenceLoaded(data));
};

export const CeatePolicyType = (data: ICreatePolicyTypeProps) => async (
  dispatch,
) => {
  const result = await createPolicyType(data);
  const newType: IPolicyType = result.data;
  dispatch(CeatePolicyTypeLoaded(newType));
};

export const CreatePolicySubType = (data: ICreatePolicySubTypeProps) => async (
  dispatch,
) => {
  const result = await createPolicySubType(data);
  const newType: IPolicySubType = result.data;
  dispatch(CeatePolicySubTypeLoaded(newType));
};

export const GetFormDefinitionReference = () => async (
  dispatch: any,
  getState: any,
) => {
  const { config }: IAppState = getState();
  const data: IFormDefinition[] = await resolveReferenceData(
    config,
    'formDefinition',
    getAllForms,
  );
  dispatch(GetFormDefinitionReferenceLoaded(data));
};
