// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Open+Sans:wght@300;400;600;700&display=swap);"]);
// Module
exports.push([module.id, "._2RKNf_GxaJr223eO5bxwKS{font-family:\"DM Sans\";font-weight:700;font-size:16px;color:#fff;padding:12px;border:none;border-radius:10px;width:135px;vertical-align:middle;margin-right:0;font-family:\"Open Sans\";font-weight:700;font-size:14px;font-size:14px;width:250px;background-color:#3d67ab;border-radius:5px;cursor:pointer;display:inline-flex;align-items:center;justify-content:center}@media(max-width: 576px){._2RKNf_GxaJr223eO5bxwKS{font-size:14px;padding-top:13px;padding-bottom:13px}}._2RKNf_GxaJr223eO5bxwKS._3co-TALdmxOTzlpRwWFYBP{background-color:rgba(0,0,0,0);color:#3d67ab}._2RKNf_GxaJr223eO5bxwKS:disabled{opacity:.5}.-rildgBg6vyJdX-y9ZQ9f{background-color:#3d67ab;line-height:1.15;text-align:center}.-rildgBg6vyJdX-y9ZQ9f:hover{color:#fff}", ""]);
// Exports
exports.locals = {
	"button": "_2RKNf_GxaJr223eO5bxwKS",
	"inverted": "_3co-TALdmxOTzlpRwWFYBP",
	"link": "-rildgBg6vyJdX-y9ZQ9f _2RKNf_GxaJr223eO5bxwKS"
};
module.exports = exports;
