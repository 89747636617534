export const capitalize = (str: string = '') =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const camelize = (str: string = '') => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, char) => char.toUpperCase());
};

export const splitTextIntoLines = (
  text: string,
  lineStarters: string[],
): string[] => {
  const starters = R.take(lineStarters.length - 1, lineStarters);
  const lastStarter = R.last(lineStarters);
  const parsed = [];

  for (let i = 1; i <= lineStarters.length; i++) {
    const currentWord = starters[i];
    const nextWord = text.includes(currentWord) ? currentWord : lastStarter;
    const regex = new RegExp(`${starters[i - 1]}\s*(.*?)\s*${nextWord}`, 'g');
    const separated = regex.exec(text);
    if (separated) {
      parsed.push(separated[1].trim());
    } else {
      const last = new RegExp(`${lastStarter}(.*)`).exec(text);
      if (last) {
        parsed.push(last[1].trim());
        break;
      }
    }
  }

  return parsed.length === 0 ? [text] : parsed;
};

export const base64ToString = (base64String) => {
  const buff = Buffer.from(base64String, 'base64');
  return buff.toString('ascii');
};

export const getFileExtensionFromString = (text) => {
  const array = text.split('.');
  if (array.length === 1) {
    return '';
  }
  return array[array.length - 1];
};

export const boolToYesNo = (bool: boolean) => (bool ? 'Yes' : 'No');
