import React from 'react';
import { Checkbox as SemanticCheckbox, CheckboxProps } from 'semantic-ui-react';
import { IFormFieldProps } from './common';
import InputWrap from './InputWrap';
import styles from './styles/Checkbox.module.scss';

const Checkbox = ({
  basic,
  label,
  description,
  className,
  inputClassName,
  labelClassName,
  leftIcon,
  rightIcon,
  error,
  name,
  tooltip,
  value,
  onChange,
  ...inputProps
}: IFormFieldProps & CheckboxProps) => {
  const handleChange = (_, data) => {
    const target = {
      name,
      value: data.checked,
      checked: data.checked,
    };
    onChange({ target });
  };

  return (
    <InputWrap
      className={classnames(styles.inputWrap, 'checkbox', className)}
      errorClassName={styles.error}
      error={error}
      label={label}
      labelClassName={labelClassName}
      name={name}
      rightIcon={rightIcon}
      description={description}
      tooltip={tooltip}
    >
      <SemanticCheckbox
        id={name}
        name={name}
        checked={value}
        {...inputProps}
        className={classnames(styles.input, inputClassName)}
        onChange={handleChange}
      />
    </InputWrap>
  );
};

export default Checkbox;
