import React, { useEffect } from 'react';
import styles from '../styles/PremiumIntro.module.scss';
import cx from 'classnames';
import { Back } from 'components/quashed';
import ProviderLogos from '../components/ProviderLogos';
import BulletPoint from '../components/BulletPoints';
import InsuranceTypes from '../components/InsuranceTypes';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import history from 'helpers/history';
import { Button } from 'components/quashed';
import { logPageView, PAGE_VIEW_EVENTS } from 'helpers';

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: `slick-dots ${styles.dots}`,
};
const PremiumIntro = ({ next, user }) => {
  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 0, premium: !!user.premium });
  }, []);
  return (
    <div>
      <div className={styles.paddingB20}>
        <div className={styles.header}>
          <span className={styles.hideText}>Quashed Premium - </span>A new
          broker experience
        </div>
        <div className={styles.content}>
          Providing you with multi-policy quotes and premium policies in a
          quick, hassle-free service.
        </div>
      </div>

      <div className={styles.container}>
        <div className={cx(styles.flexCol, styles.leftSide)}>
          <div className={styles.leftContainer}>
            <div className={cx(styles.content, styles.paddingB20)}>
              Quashed Premium (previously ‘Get Advice’) brings you a new
              insurance advisory service. Here is what to expect:
            </div>
            <BulletPoint
              number={1}
              heading={'All online, fast turnaround'}
              content={
                'Our online process keeps this much quicker than a standard broker service.'
              }
            />
            <BulletPoint
              number={2}
              heading={'Multiple quotes, personalised recommendation'}
              content={
                'Our adviser will often provide three quotes and a recommendation, so that you know you’re getting the best deal and policy for you.'
              }
            />
            <BulletPoint
              number={3}
              heading={'Your interactive online dashboard'}
              content={
                'Our dashboard allows you to keep all of your policies in one place. You can also share your dashboard with loved ones so that you’re all on the same page, literally!'
              }
            />
            <div
              className={cx(
                styles.content,
                styles.weight400,
                styles.paddingB20,
              )}
            >
              Still unsure? Check out our helpful guide.{' '}
              <a
                href={'https://quashed.co.nz/guides/insurance-adviser'}
                target={'_blank'}
                rel="noreferrer"
                className={styles.link}
              >
                Click here
              </a>
            </div>
            <div className={styles.grey}>
              Please note: Quashed Premium works with insurers who are not
              directly available to you. We <b>cannot</b> give you advice about
              "direct" insurers, e.g. AA, AMI, State, Tower etc.
            </div>
          </div>
          <div className={styles.bottomButtons}>
            <Back className={styles.back} onClick={() => history.goBack()} />

            <Button className={styles.continueButton} onClick={next}>
              Let's get started
            </Button>
          </div>
        </div>

        <div className={styles.rightContainer}>
          <ProviderLogos className={styles.paddingB40} />
          <div className={cx(styles.ourPromise, styles.paddingB4)}>
            Our promise
          </div>

          <Slider {...settings} className={styles.slider}>
            <div>
              <div className={styles.cardHeader}>
                A <span className={styles.blue}>premium</span> insurance
                experience shaped for you.
              </div>
            </div>

            <div>
              <div className={styles.cardHeader}>
                Helping Kiwis{' '}
                <span className={styles.blue}>gain confidence </span>
                with their insurance.
              </div>
            </div>

            <div>
              <div className={styles.cardHeader}>
                Experience a <span className={styles.blue}>personalised </span>
                online service that is easy to use.
              </div>
            </div>
          </Slider>

          <InsuranceTypes />
        </div>
      </div>
    </div>
  );
};

export default PremiumIntro;
