import { Features } from 'constants/features';
import {
  INSURANCE_DETAILS_FIELDS_BY_NAME,
  INSURANCE_PROVIDER_QUESTION,
  INSURANCE_TYPES_NAMES,
  NAME_FIELD,
  POLICY_PROVIDER_QUESTION,
} from 'constants/insurance';
import {
  MARKET_SCAN_FIELDS_TO_IGNORE,
  MARKET_SCAN_POLICIES,
  MARKET_SCAN_POLICIES_BROKER_FIRST,
} from 'constants/marketScan';
import {
  MotorcycleRegoStatus,
  MOTORCYCLE_COVER_TYPE_NAMES,
} from 'constants/options';
import {
  ADDRESS_QUESTION,
  FULL_ADDRESS_OTHER_QUESTIONS,
  MS_ADDRESS_PROPS,
} from 'constants/questions';
import { MARKET_SCAN_QUESTIONS_BY_TYPE } from 'constants/typeform-questions';
import {
  convertToNZDate,
  prettifyNumber,
  capitalize,
  checkCompletePolicy,
  checkIsMissingInformation,
  canRequestMarketScanByDate,
  arrayOrderByDateKey,
  shouldShowQuestion,
  getFieldsByPolicyType,
  getFieldKeysForMarketScan,
  validateForm,
  getDifferentKeys,
  isValueInOptions,
  getQuestionWithOption,
  clearAccidentHistory,
  hasFeature,
  getFullAddress,
  getAllQuestions,
  CONTENTS_MEDIUM_AMOUNT,
} from 'helpers';
import { getSubTypeOptionsAsync } from 'hooks/usePolicySubTypes';
import {
  checkPolicyScanAddress,
  getAttachmentsForPolicyWithLinks,
  getPolicyRenewalsByPolicyId,
  getVehicleInfo,
} from 'services';
import { IPolicy, IReferenceData } from 'types';
import { SUBTITLES, DEFAULT_POLICY_DATA_MAP } from '../constants';
import { MarketScanPath } from '../reducer';
import { findValueFromPolicies } from './broker';

// Gets fields we show in policy details screen minus some we dont need
export const getPolicyFields = (policyType) => {
  const fields =
    INSURANCE_DETAILS_FIELDS_BY_NAME[policyType] ||
    INSURANCE_DETAILS_FIELDS_BY_NAME.default;
  return fields.filter(
    (f) => !R.includes(f.fieldKey, MARKET_SCAN_FIELDS_TO_IGNORE),
  );
};

// Get questions for policy information
export const getPolicyQuestions = (policy, subTypes) => {
  const fieldKeys = getFieldKeysForMarketScan(policy, subTypes);

  return [
    ...getFieldsByPolicyType(policy.policyType),
    ...getPolicyFields(policy.policyType),
  ].filter((q) => R.includes(q.valueKey || q.fieldKey, fieldKeys));
};

// Get policy page questions and arrange them into 2 columns
export const getPolicyPageQuestions = (policy, optionsMap) => {
  const policyQuestions = getPolicyQuestions(policy, optionsMap.policySubType);

  let line = 0;
  let order = 0;

  return policyQuestions.reduce((acc, question) => {
    if (shouldShowQuestion(question, policyQuestions, policy)) {
      const options = optionsMap[question.fieldKey];
      const props = question.key === 'address' ? MS_ADDRESS_PROPS : null;
      acc.push({
        ...getQuestionWithOption(question, options),
        before: question.label,
        line,
        order,
        ...props,
      });

      // Go to next line if its full width or order: 1
      if (question.fullWidth || order === 1) {
        line = line + 1;
        order = 0;
      } else {
        order = 1;
      }
    }
    return acc;
  }, []);
};

export const getPolicyQuestionsWithProvider = (
  policy,
  subTypes,
  insuranceProviders = [],
) => {
  return [
    R.assoc('options', insuranceProviders, INSURANCE_PROVIDER_QUESTION),
    ...getPolicyQuestions(policy, subTypes),
  ];
};

// Convert values to display correctly
export const getValue = (field, data) => {
  const type = field.inputType || field.type;
  let value = data[field.valueKey || field.key || field.fieldKey];

  if (type === 'date' || type === 'birthday') {
    return convertToNZDate(value);
  }

  if (type === 'dropdown' && data[`${field.fieldKey}Name`]) {
    value = data[`${field.fieldKey}Name`];
  }

  if (type === 'number') {
    return field.prefix && value
      ? `${field.prefix}${prettifyNumber(value)}`
      : value;
  }

  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  return typeof value === 'string' ? capitalize(value) : value;
};

const toDropdown = (option) => {
  return typeof option === 'string'
    ? {
        key: option,
        text: capitalize(option),
        value: option,
      }
    : option;
};

// Get fields for last page. Based on design, left side is user questions, while right is policy summary
// so we arrange them alternately to show it like that
export const getFieldsForSummary = (policy, user, subTypes) => {
  const policyFields = R.reject(
    (p) =>
      [ADDRESS_QUESTION.fieldKey, POLICY_PROVIDER_QUESTION.fieldKey].includes(
        p.fieldKey,
      ),
    getPolicyFields(policy.policyType),
  );
  const policyTypeFields = getFieldsByPolicyType(policy.policyType);

  const fields = [{ ...ADDRESS_QUESTION, fullWidth: true }, NAME_FIELD];

  const questions = MARKET_SCAN_QUESTIONS_BY_TYPE[policy.policyType];
  const rows = R.max(policyFields.length, questions.length);

  for (let i = 0; i < rows; i++) {
    const policyField = policyFields[i];

    if (policyField) {
      // We do not show sub type field when there is none
      if (
        policyField.fieldKey === 'policySubType'
          ? subTypes.length > 0
          : shouldShowQuestion(policyField, questions, policy)
      ) {
        fields.push(policyField);
      }
    }

    if (questions) {
      const userField = questions[i];
      if (
        userField &&
        shouldShowQuestion(
          userField,
          questions,
          userField.source === 'user' ? user : policy,
        )
      ) {
        fields.push({
          ...userField,
          fieldKey: userField.key,
          options: userField.options?.map(toDropdown),
        });
      }
    }
  }

  const uniqueFields = R.uniqBy(
    R.prop('fieldKey'),
    R.insertAll(2, policyTypeFields, fields),
  );

  if (policy.address?.trim()) {
    return uniqueFields;
  }
  const addressIdx = R.findIndex(R.propEq('fieldKey', 'address'), uniqueFields);
  const removed = R.remove(addressIdx, 1, uniqueFields);
  return R.insertAll(
    addressIdx,
    FULL_ADDRESS_OTHER_QUESTIONS.map(
      R.mergeLeft({
        fullWidth: false,
        showKeyValue: undefined, // Remove showKeyValue to allow validation to work
      }),
    ),
    removed,
  );
};

export const shouldShowPolicyForMarketScan = (p) => {
  return (
    R.includes(p.policyType, MARKET_SCAN_POLICIES) &&
    (checkCompletePolicy(p) || checkIsMissingInformation(p)) &&
    canRequestMarketScanByDate(p) &&
    !p?.quashedBrokerCover
  );
};

export const getDefaultFormValues = (
  questions,
  user,
  selectedPolicy,
  policies,
) => {
  // Find similar policy types and sort them by recent date
  const filtered = policies.filter(
    R.propEq('policyTypeId', selectedPolicy.policyTypeId),
  );
  const sorted = arrayOrderByDateKey('createdAt', filtered, true);

  return questions.reduce(
    (acc, q) => {
      const src = q.source === 'user' ? user : selectedPolicy;
      const value = src[q.key] || q.defaultValue;

      if (R.isNil(value) && q.source !== 'user') {
        // Try to get default values from other similar policies
        // when selected one doesnt have a value
        if (sorted.length > 0) {
          const found = sorted.find((p) => !R.isNil(p[q.key]));
          acc[q.key] = found ? found[q.key] : '';
        } else {
          acc[q.key] = '';
        }
      } else {
        acc[q.key] = value;
      }
      return acc;
    },
    { name: `${user.firstName} ${user.lastName}` },
  );
};

export const getValuesBySource = (questions, values) => {
  return questions.reduce(
    (acc, q) => {
      const key = q.key || q.fieldKey;
      const source = q.source || 'policy';
      const value = values[key];

      if (key === 'address') {
        return {
          ...acc,
          [source]: {
            ...acc[source],
            [key]: value,
            ...getFullAddress(values),
          },
        };
      }

      if (!(R.isNil(value) || R.isEmpty(value))) {
        acc[source][key] = value;
      }
      return acc;
    },
    { user: {}, policy: {} },
  );
};

export const getErrorMessage = (errors) => {
  if (R.prop('dateOfBirth', errors)) {
    return 'Please check your date of birth';
  }
  return '';
};

export const validateSummary = (fields, policyForm, userForm) => {
  const { user: userFields, policy: policyFields } = R.groupBy(
    (f) => f.source || 'policy',
    fields,
  );
  const policyErrors = validateForm(policyFields, policyForm.values);
  const userErrors = validateForm(userFields, userForm.values);
  return (
    (policyErrors || userErrors) && { policy: policyErrors, user: userErrors }
  );
};

export const getPolicyDefaultValues = (policy, user) => {
  return {
    name: `${user.firstName} ${user.lastName}`,
    ...policy,
    ...policy?.fullAddress,
    showFullAddress: !policy.address && !!policy?.fullAddress,
  };
};

export const getPolicyReferenceData = ({
  id,
  policyProviderId,
  policyType,
}: IPolicy) => {
  // Get subtypes and attachments of selected policy
  return Promise.all([
    getSubTypeOptionsAsync(policyProviderId, policyType),
    // TODO: Remove this when we get attachment link on click
    getAttachmentsForPolicyWithLinks(id),
  ]);
};

export const genericSubTypeAdditionalFilter = (subtypes, policy) => {
  if (
    policy.policyType === INSURANCE_TYPES_NAMES.MOTORCYCLE &&
    policy.registrationStatus !== MotorcycleRegoStatus.NOT_REQUIRED
  ) {
    return subtypes.filter(
      (sub) => sub.name !== MOTORCYCLE_COVER_TYPE_NAMES.FIRE_THEFT_TRANSIT,
    );
  }
  return subtypes;
};

export const addOptionsToQuestions = (questions, values, referenceData) => {
  return questions.map((q) => {
    if (q.type === 'dropdown' && !q.options) {
      q.options = R.pathOr(
        [],
        ['policyScan', values.policyType, q.optionKey || q.fieldKey],
        referenceData,
      );
    }
    return q;
  });
};

export const MARKET_SCAN_TITLE = 'Market Scan';

export const isLastPage = (path, page) => {
  return (
    (path === MarketScanPath.NEW_POLICY && page >= 5) ||
    (path === MarketScanPath.ADD_POLICY && page > 3) ||
    (path === MarketScanPath.EXISTING_POLICY && page > 0)
  );
};

export const getTitle = (path, page, { policy, policyType }) => {
  if (path === MarketScanPath.ADD_POLICY && page > 0 && page <= 3) {
    const name = policy?.policyType || policyType?.name || '';
    return `Add your ${name.toLowerCase()} policy`;
  }
  return MARKET_SCAN_TITLE;
};

export const getSubtitle = ({ path, page, policy, policyType }) => {
  const pathSubs = SUBTITLES[path];
  if (
    path === MarketScanPath.EXISTING_POLICY ||
    path === MarketScanPath.NEW_POLICY
  ) {
    const policyTypeName = policyType?.name || policy?.policyType;
    const subMap = pathSubs[policyTypeName] || pathSubs.default;
    return subMap[page];
  } else if (path === MarketScanPath.ADD_POLICY) {
    if (policy) {
      const subMap = policy?.isUpload ? pathSubs.default : pathSubs.manual;
      return subMap[page];
    }
    return pathSubs.default[page];
  }
  return R.prop(page, pathSubs);
};

export const getPremiumLabel = ({ premium = 0, frequency }) => {
  const prem = `$${prettifyNumber(premium)}`;
  if (frequency) {
    const freq = frequency.replace('ly', '').toLowerCase();
    return `${prem}/${freq}`;
  }
  return prem;
};

// We do this to quickly clear vehicle make/model to show
// the error to the user without them having to click next
const clearVehicleMakeModel = (data, options) => {
  const { make } = data;
  // Remove make when its not in the options
  // When we get the wrong make, we can assume the model is wrong too
  if (make && !isValueInOptions(options.make, make)) {
    return { ...data, make: '', model: '' };
  }
  return data;
};

// Checks address field or number plate if they are valid
export const validatePolicyFields = async (policy, options, form) => {
  let valuesToSave = form.values;

  if (policy.policyType === INSURANCE_TYPES_NAMES.HOME) {
    const { data } = await checkPolicyScanAddress(
      valuesToSave.address,
      getFullAddress(valuesToSave),
    );
    valuesToSave = {
      ...valuesToSave,
      ...data,
      addressValid: data.valid,
    };
    form.setValues(valuesToSave);

    const newQs = getPolicyPageQuestions(valuesToSave, options);
    const fe = validateForm(newQs, valuesToSave);
    if (fe) {
      return { errors: fe };
    }
  } else if (
    policy.policyType === INSURANCE_TYPES_NAMES.VEHICLE &&
    R.isNil(valuesToSave.vehicleFound)
  ) {
    const { data } = await getVehicleInfo(valuesToSave.numberPlate);
    valuesToSave = clearVehicleMakeModel(
      {
        ...data,
        ...valuesToSave,
      },
      options,
    );
    form.setValues(valuesToSave);

    if (
      !valuesToSave.make ||
      !valuesToSave.model ||
      !valuesToSave.subModel ||
      !valuesToSave.vehicleYear
    ) {
      const newQs = getPolicyPageQuestions(valuesToSave, options);
      const fe = validateForm(newQs, valuesToSave);
      if (fe) {
        return { errors: fe };
      }
    }
  }

  return {
    errors: null,
    values: valuesToSave,
  };
};

export const getPolicyRenewal = async (policyId: string) => {
  const { data } = await getPolicyRenewalsByPolicyId(policyId);
  return data.Items.find((pr) => !pr.completed) || null;
};

// Use renewal data as form values
export const mergePolicyWithRenewal = (
  policy: IPolicy,
  renewalData: IPolicy,
) => {
  if (renewalData) {
    const merged = { ...policy };
    [].forEach((key) => {
      merged[key] = renewalData[key];
    });
    return merged;
  }
  return policy;
};

export const revertToOrigPolicy = (
  currentPolicy: IPolicy,
  origPolicy: IPolicy,
  renewalData: IPolicy,
) => {
  const keysToRemove = getDifferentKeys(renewalData, origPolicy);

  if (origPolicy) {
    const updated = { ...currentPolicy };
    keysToRemove.forEach((key) => {
      const val = origPolicy[key];
      if (val) {
        updated[key] = val;
      }
    });
    return updated;
  }
  return currentPolicy;
};

export const getUserSourceKeys = (fields) =>
  fields.reduce((acc, f) => {
    if (f.source === 'user') {
      acc.push(f.fieldKey);
    }
    return acc;
  }, []);

export const getPolicyToSave = (currentPolicy, formValues) => {
  const defaultData = DEFAULT_POLICY_DATA_MAP[formValues.policyType] || {};
  const policyMerged = {
    ...defaultData,
    ...currentPolicy,
    ...formValues,
    amount: formValues.amount || currentPolicy.amount,
    claimType1: formValues.claimType1 || 'none',
    fullAddress: getFullAddress(formValues) || currentPolicy.fullAddress,
  };
  return clearAccidentHistory(policyMerged);
};

export const getPolicyTypeByName = (
  policyTypeName: string,
  referenceData: IReferenceData,
) => {
  return referenceData.policyTypes.find(R.propEq('name', policyTypeName));
};

export const getBrokerPolicies = (config) => {
  const toHide = hasFeature(config, Features.HIDDEN_BROKER_MS) || [];
  return MARKET_SCAN_POLICIES_BROKER_FIRST.filter((p) => !toHide.includes(p));
};

export const getDefaultDirectMSValues = ({
  formDefinition,
  policyType,
  policiesList = [],
  user,
  policy = null,
}) => {
  const questions = getAllQuestions(formDefinition);

  const sorted = arrayOrderByDateKey('createdAt', policiesList, true);

  const defaultFormValues = questions.reduce(
    (acc, q) => {
      let value = policy?.[q.key] || user[q.key] || q.defaultValue;

      // Try to get default values from other similar policies
      // when selected one doesnt have a value
      if (
        R.isNil(value) &&
        sorted.length > 0 &&
        // These props should not be shared between policies
        !R.includes(q.key, ['policySubTypeId', 'propertyOccupants', 'premium'])
      ) {
        const found = findValueFromPolicies(sorted, policyType?.name, q.key); // sorted.find((p) => !R.isNil(p[q.key]));
        if (!R.isNil(found)) {
          value = found[q.key];
        }
      }

      if (!R.isNil(value)) {
        // Driving exp question is a dropdown in current version
        if (q.key === 'drivingExp') {
          acc[q.key] = R.min(5, value);
        } else {
          acc[q.key] = typeof value === 'boolean' ? String(value) : value;
        }
      }

      return acc;
    },
    {
      policyType: policyType?.name,
      // We assume user owns the house for house policies
      isPropertyOwner: policyType?.name === INSURANCE_TYPES_NAMES.HOME,
      // Currently used in form validation for amount
      // If direct is true, we should also validate max amount
      direct: true,
      // When policyId exists, we show frequency and premium fields
      policyId: policy?.id,
    },
  );

  return defaultFormValues;
};

export const getSubTypeOptions = (policyTypeName, data, options) => {
  if (policyTypeName === INSURANCE_TYPES_NAMES.MOTORCYCLE) {
    return data.registrationStatus === MotorcycleRegoStatus.NOT_REQUIRED
      ? options
      : R.reject(
          R.propEq('key', MOTORCYCLE_COVER_TYPE_NAMES.FIRE_THEFT_TRANSIT),
          options,
        );
  }

  if (policyTypeName === INSURANCE_TYPES_NAMES.CONTENT) {
    return data.amount >= CONTENTS_MEDIUM_AMOUNT
      ? options
      : R.reject(R.propEq('key', 'Medium'), options);
  }
};
