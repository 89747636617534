import { useEffect, useRef } from 'react';
import { PolicyUploadToast } from 'components/toasts';
import {
  POLICY_CHECK_MAX_COUNT,
  POLICY_CHECK_INTERVAL,
  PolicyStatus,
} from 'constants/policy';
import { getPolicyById } from 'services';
import {
  checkCompletePolicy,
  checkIsMissingInformation,
  getPolicyDetailsRoute,
  getPolicyProgressCount,
  trackEvent,
} from 'helpers';
import { IAppState, IPolicy } from 'types';
import history from 'helpers/history';
import { MARKET_SCAN_ROUTE } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateClientPolicyLoaded, UpdatePolicyLoaded } from 'actions';

const notifyAdviser = (policy) => {
  if (policy.status === PolicyStatus.ACTIVE) {
    PolicyUploadToast.successAdviser({ policy });
  } else {
    PolicyUploadToast.errorAdviser();
  }
};

const notifyByInformation = (policy, onClick) => {
  if (policy.status === PolicyStatus.MARKET_SCAN_DRAFT) {
    if (location.pathname === MARKET_SCAN_ROUTE) {
      history.push({
        pathname: MARKET_SCAN_ROUTE,
        state: { policy },
      });
    } else {
      PolicyUploadToast.marketScanSuccess({ policy, onClick });
    }
  }
};

const usePolicyNotification = () => {
  const dispatch = useDispatch();
  const {
    policies: { policies },
  } = useSelector(R.identity) as IAppState;

  // Keep track of notifications so we do not keep creating multiple intervals for the same policy
  const notifications = useRef({});

  const removeNotification = (id, intervalId) => {
    notifications.current = R.dissoc(id, notifications.current);
    clearInterval(intervalId);
  };

  const addNotification = (id, intervalId) => {
    notifications.current = R.assoc(id, intervalId, notifications.current);
  };

  const onSelect = (policy) => {
    if (policy.status === PolicyStatus.MARKET_SCAN_DRAFT) {
      trackEvent('Web-App-Market-Scan-Add-Upload-Notification-Click', policy);
      history.push({
        pathname: MARKET_SCAN_ROUTE,
        state: { policy },
      });
    } else {
      history.push(getPolicyDetailsRoute(policy.id));
    }
  };

  useEffect(() => {
    if (policies.length > 0) {
      policies.forEach((policy: IPolicy) => {
        const { id, sk, processed, status, uploadedAt } = policy;
        const currentCount = getPolicyProgressCount(uploadedAt);
        const policyInterval = notifications.current[id];
        if (
          !processed &&
          !policyInterval &&
          status !== PolicyStatus.FINISH_SETUP &&
          currentCount < POLICY_CHECK_MAX_COUNT
        ) {
          const interval = setInterval(async () => {
            const count = getPolicyProgressCount(uploadedAt);
            if (count >= POLICY_CHECK_MAX_COUNT) {
              removeNotification(id, interval);
            } else {
              const { data } = await getPolicyById(id, sk);
              if (data.Count > 0) {
                const p = data.Items[0];
                if (p.processed) {
                  removeNotification(id, interval);
                  if (p.adviserOrgId) {
                    dispatch(
                      UpdateClientPolicyLoaded(R.assoc('submitted', false, p)),
                    );
                    notifyAdviser(p);
                  } else {
                    dispatch(UpdatePolicyLoaded(p));
                    notifyByInformation(p, onSelect);
                  }
                }
              } else {
                // Theres something wrong when policy cant be found so stopping it early
                removeNotification(id, interval);
              }
            }
          }, POLICY_CHECK_INTERVAL);

          if (!policyInterval) {
            addNotification(id, interval);
          }
        }
      });
    }
  }, [policies]);
};

export default usePolicyNotification;
