import { toast, ToastOptions } from 'react-toastify';
import { Icon } from 'semantic-ui-react';
import styles from './styles/generic.scss';

const options: ToastOptions = {
  autoClose: 5000,
  closeButton: true,
  closeOnClick: true,
};

interface IProps {
  text: any;
  filename: string;
  closeToast(): void;
}

const uploadErrorText = (
  <>
    <b>There seems to be something wrong with the file you uploaded.</b> Please
    check that the file is not corrupt and <b>try uploading it a again!</b>{' '}
    Contact us if the problem persists.
  </>
);

const FileUploadErrorToast = ({
  text = uploadErrorText,
  filename,
  closeToast,
}: IProps) => {
  const handleClose = () => closeToast();
  return (
    <div className={styles.container}>
      <Icon name="close" onClick={handleClose} />
      <div className={styles.title}>{filename}</div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

const controller = {
  error: (props?) => toast(props.text, { ...options, className: styles.error }),
  fileUploadErrorToast: (props?) => toast(<FileUploadErrorToast {...props} />),
};

export default controller;
