import React, { useMemo } from 'react';
import Button from 'components/button';
import { getEmployerPolicies } from '../utils';
import styles from '../styles/q4e.scss';
import Q4EPolicy from '../Q4EPolicy';
import Q4EOffers from '../Q4EOffers';
import Back from 'components/back';

const LastPage = ({
  employers,
  isUpdate,
  policyProviders,
  values,
  onSubmit,
  loading,
  onBack,
  setValues,
}) => {
  const policies = getEmployerPolicies(employers, values);
  const offers = policies.reduce((acc, p) => R.concat(p.offers || [], acc), []);

  const isSubmitDisabled = useMemo(() => {
    if (loading) {
      return true;
    }

    const noIncome = !values.income;
    const showIncomeFrequency = !!policies.find(R.prop('showIncomeFrequency'));
    return showIncomeFrequency ? noIncome || !values.incomeFrequency : noIncome;
  }, [values, policies, loading]);

  const handleInputChange = (e) => {
    setValues(R.mergeLeft({ [e.target.name]: e.target.value }));
  };

  const handleDropdownChange = (_, data) => {
    setValues(R.mergeLeft({ [data.name]: data.value }));
  };

  const handleSubmit = () => onSubmit(values);

  return (
    <>
      <div className={styles.policies}>
        {policies.map((policy) => {
          const provider = policyProviders.find(
            R.propEq('id', policy.policyProviderId),
          );
          return (
            <Q4EPolicy
              key={policy.policyTypeId}
              policy={policy}
              provider={provider}
              values={values}
              onInputChange={handleInputChange}
              onDropdownChange={handleDropdownChange}
            />
          );
        })}
      </div>
      <div className={styles.btnContainer}>
        <Back className={styles.back} handleBack={onBack} />
        <Button
          className={styles.nextBtn}
          text={isUpdate ? 'Update' : 'Add'}
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        />
      </div>
      {offers.length > 0 && (
        <Q4EOffers policyProviders={policyProviders} offers={offers} />
      )}
    </>
  );
};

export default LastPage;
