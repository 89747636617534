export const windowScrollTop = (smooth?: boolean) =>
  document
    .getElementById('main')
    ?.scrollTo({ top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto' });

export const windowScrollBottom = (smooth?: boolean) =>
  document
    .getElementById('main')
    ?.scrollTo({ top: 100000, left: 0, behavior: smooth ? 'smooth' : 'auto' });

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const isSafari = () => {
  const ua = navigator?.userAgent;
  const chromeRegex = /chrome|crios|crmo/i;
  const safariRegex = /safari|applewebkit/i;
  return !ua?.match(chromeRegex) && ua?.match(safariRegex);
};
