import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { X } from 'react-feather';
import Button from 'components/button';
import styles from './styles/profileImageModal.scss';
import { IFile } from 'services';

const ProfileImageModal = ({ onClose, onUpload, ...modalProps }) => {
  const [picture, setPicture] = useState<IFile>(null);

  const handleChange = (e) => {
    const target = e.target.files[0];
    setPicture(target);
  };

  const handleClose = () =>  {
    setPicture(null);
    onClose();
  };

  const handleUpload = () => {
    onUpload(picture);
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      closeIcon={<X className={styles.close} />}
      {...modalProps}
      onClose={handleClose}
    >
      <Modal.Content>
        <div className={styles.title}>Upload your photo</div>
        <div className={styles.subtitle}>
          We want your photo to look amazing, so please ensure your photo fits in this <b>circle</b>.
        </div>
        <div className={classnames(styles.imgWrap, !picture && styles.withPadding)}>
          {
            picture ? (
              <img
                alt="Profile pic"
                src={URL.createObjectURL(picture)}
              />
            ) : (
              <div className={styles.placeholder}/>
            )
          }
        </div>
        {
          picture && (
            <Button
              className={styles.upload}
              text="Upload"
              onClick={handleUpload}
            />
          )
        }
        <label className={classnames(styles.browse, picture && styles.change)}>
          {picture ? 'Change' : 'Browse'}
          <input type="file" onChange={handleChange} accept="image/*" />
        </label>
      </Modal.Content>
    </Modal>
  );
};

export default ProfileImageModal;
