import { TASK_TYPES } from 'constants/notification';
import moment from 'moment';

export const MIN_AGE = 16;
const STANDARD_FORMAT_TIME = 'DD-MM-YYYY, h:mm:ss a';

// Timeframe in hours to show the new label
const NEW_TIME_FRAME = 24;

export const convertToFullDate = (date) => moment(date).format('DD MMMM YYYY');
export const convertToMediumDate = (date) => moment(date).format('DD MMM YYYY');

export const convertToNotificationDate = (date) =>
  moment(date).format('ddd D MMM, h:mma');

export const standardFormatWithTime = (date) => {
  return moment(date).format(STANDARD_FORMAT_TIME);
};

export const isNewByMins = (date: string, mins: number) => {
  return new Date().getTime() - new Date(date).getTime() < 1000 * 60 * mins;
};

export const timeFromNow = (date) => moment(date).fromNow();

export const parseStandardFormat = (date) => moment(date, STANDARD_FORMAT_TIME);

export const convertToDateOnlyString = (date: string, format?: string) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format('YYYY-MM-DD');
};

export const convertToNZDate = (date) => moment(date).format('DD/MM/YYYY');

export const sortDate = (key: string, asc?: boolean) => (a: any, b: any) => {
  if (asc) {
    return new Date(a[key]).getTime() - new Date(b[key]).getTime();
  }
  return new Date(b[key]).getTime() - new Date(a[key]).getTime();
};

export const arrayOrderByDateKey = (
  key: string,
  array: any[],
  isDecending?: boolean,
) => {
  return array.sort((a, b) => {
    if (isDecending) {
      return (
        moment(b[key]).toDate().getTime() - moment(a[key]).toDate().getTime()
      );
    }
    return (
      moment(a[key]).toDate().getTime() - moment(b[key]).toDate().getTime()
    );
  });
};

export const dateLessThanOrEqualToDays = (date: moment.Moment, days) => {
  const now = moment();
  const diff = now.diff(date, 'days', true);
  return Math.round(diff) <= days;
};

export const getMostRecentDate = (items, prop) => {
  return items.reduce((max, item) => {
    const parsed = moment(item[prop]);
    if (max) {
      return parsed.isAfter(max) ? parsed : max;
    }
    return parsed;
  }, null);
};

export const getItemsCreatedToday = (items) => {
  return items.filter((item) => {
    return moment(item.startDate || item.createdAt || item.data).isAfter(
      moment().startOf('D'),
    );
  });
};

export const getItemsCreatedYesterday = (items) => {
  return items.filter((item) => {
    return (
      moment(item.startDate || item.createdAt || item.data).isAfter(
        moment().startOf('D').subtract(1, 'days'),
      ) &&
      moment(item.startDate || item.createdAt || item.data).isBefore(
        moment().startOf('D'),
      )
    );
  });
};

export const getItemsCreatedMoreThanTwoDaysAgo = (items) => {
  return items.filter((item) => {
    return moment(item.startDate || item.createdAt || item.data).isBefore(
      moment().endOf('D').subtract(2, 'days'),
    );
  });
};

export const getItemsCreatedMonth = (items) => {
  return items.filter((item) => {
    return moment(item.createdAt || item.data).isAfter(moment().startOf('M'));
  });
};

export const getItemsCreatedWeek = (items) => {
  return items.filter((item) => {
    return moment(item.createdAt || item.data).isAfter(moment().startOf('W'));
  });
};

export const getUtcDateXDaysAgo = (days: number) =>
  moment().subtract(days, 'days').utc().format();

export const getUtcDateNow = (format?: string) => moment().utc().format(format);

export const getNzDateNow = (format?: string) => moment().format(format);

export const getMaxYear = (min = MIN_AGE) =>
  moment().subtract(min, 'years').year();

export const minAgeDateValidator = (date) => {
  const val = moment().diff(date, 'years');
  return val >= MIN_AGE;
};

export const dateValidator = (date) => moment(date).isValid();

export const getYearOptions = (
  max = moment().year(),
  valueType: 'string' | 'number' = 'string',
) => {
  return [...Array(100)].map((_, idx) => {
    const val = max - idx;
    const value = valueType === 'string' ? val.toString() : val;
    return {
      key: value,
      text: value,
      value,
    };
  });
};

export const checkIsNewItem = (
  t: any,
  dateKey: string,
  timeFrame = NEW_TIME_FRAME,
) => t[dateKey] && moment().diff(moment(t[dateKey]), 'hours', true) < timeFrame;

export const sortDateDesc = sortDate('createdAt');

export const findDayDifference = (date1, date2) => {
  return moment(date2).diff(moment(date1), 'days', true);
};

export const checkIsOfficeHours = () => {
  const hour = moment().hour();
  const day = moment().day();
  // day >= monday && day <= friday && hour >= 7am && hour <= 6pm
  return day >= 1 && day <= 5 && hour >= 7 && hour <= 18;
};

export const getYear = () => moment().utc().year();

export const getAge = (dob: string) => moment().diff(dob, 'years');

export const getRelativeTime = (date: string) => {
  return moment.duration(moment().diff(date)).humanize();
};

export const getTimeOnly = (date: string) => {
  return moment(date).format('h:mm A');
};
