import { useEffect, useRef, useState } from 'react';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { FREQUENCY_OPTIONS } from 'constants/options';
import {
  CONTENTS_MEDIUM_AMOUNT,
  getFullAddress,
  getGenericSubTypes,
  highPremiumWarning,
  isMissingAddressFields,
  largeOutbuildsAreaWarning,
} from 'helpers';
import { useSelector } from 'react-redux';
import { getExcessOptions } from 'selectors';
import {
  AddressLookupAction,
  checkPolicyScanAddress,
  getVehicleInfo,
} from 'services';
import { IPolicy, IPolicyType } from 'types';
import { getSubTypeOptionsAsync } from './usePolicySubTypes';
import useVehicleInfo from './useVehicleInfoOptions';
import { FULL_ADDRESS_OTHER_QUESTIONS } from 'constants/questions';

const HOME_BUILD_FIELDS = [
  { key: 'addressValid', as: 'valid' },
  { key: 'yearBuilt' },
  { key: 'constructionType' },
  { key: 'livingArea' },
  { key: 'numberOfStories' },
  { key: 'quality' },
  { key: 'roofType' },
  { key: 'slope' },
];

const isMakeInOptions = (opts, make) =>
  opts.find((m) => m.value.toUpperCase() === make.toUpperCase());

const fetchSubTypes = async (
  policy,
  policyTypeName,
  referenceData,
  formRef,
  setState,
) => {
  const subTypes = policy?.id
    ? await getSubTypeOptionsAsync(policy.policyProviderId, policyTypeName)
    : getGenericSubTypes(referenceData, {
        policyType: policyTypeName,
      }).map(({ name }) => ({
        key: name,
        text: name,
        value: name,
      }));

  setState(subTypes);

  // Clear policySubType field when policySubTypeId doesnt exist as an option
  const currentSubType = subTypes.find(
    R.propEq('policySubTypeId', policy?.policySubTypeId),
  );
  if (!currentSubType) {
    formRef.current.setValue('policySubTypeId', '');
    formRef.current.setValue('policySubTypeName', '');
  }
};

interface IProps {
  policyType: IPolicyType;
  policy?: IPolicy;
  onNextPage(...args): void;
}

const useMarketScanForm = ({ policyType, policy, onNextPage }: IProps) => {
  const formRef = useRef(null);
  const [vehicleInfo, setVehicleInfo] = useState({
    make: policy?.make,
    model: policy?.model,
  });
  const [searching, setSearching] = useState(false);
  const [subTypes, setSubTypes] = useState([]);
  const { referenceData } = useSelector(R.pick(['referenceData']));

  useEffect(() => {
    fetchSubTypes(
      policy,
      policyType?.name,
      referenceData,
      formRef,
      setSubTypes,
    );
  }, [policy?.id, policyType?.name]);

  const vehicleOptions = useVehicleInfo(
    policyType?.name,
    vehicleInfo?.make,
    vehicleInfo?.model,
  );

  const claimTypes = R.pathOr(
    [],
    ['policyScan', policyType?.name, 'claimType'],
    referenceData,
  );

  const options = {
    excess: getExcessOptions(policyType?.name, referenceData),
    policySubTypeId: subTypes,
    claimType1: claimTypes,
    frequency: FREQUENCY_OPTIONS,
    ...vehicleOptions,
  };

  const searchRego = async (form, formValues) => {
    setSearching(true);

    try {
      const res = await getVehicleInfo(formValues.numberPlate);
      const {
        vehicleFound,
        make = '',
        model = '',
        vehicleYear = '',
      } = res.data;

      const hasMake = isMakeInOptions(options.make, make);

      // Clear fields when make is not in options
      // to force users to reselect
      form.setValue('make', hasMake ? make : '');
      form.setValue('model', hasMake ? model : '');
      form.setValue('vehicleYear', vehicleYear);
      form.setValue('vehicleFound', vehicleFound);

      if (vehicleFound && hasMake) {
        setVehicleInfo(res.data);
      }
    } catch {
      // Stop searching when there is error
      form.setValue('vehicleFound', false);
    }

    setSearching(false);
  };

  const handleInputSearch = async (question, formValues, form) => {
    const isValid = await form.trigger(question.key);
    if (!isValid) {
      return;
    }
    if (question.key === 'numberPlate') {
      await searchRego(form, formValues);
    } else if (question.key === 'address') {
      await handleAddressSelect(form, formValues);
    }
  };

  const searchAddress = async (form, formValues) => {
    setSearching(true);

    try {
      const res = await checkPolicyScanAddress(
        formValues.address,
        getFullAddress(formValues),
        AddressLookupAction.INFORMATION,
      );

      HOME_BUILD_FIELDS.forEach(({ key, as }) => {
        const val = res.data?.[as || key];
        form.setValue(key, val || '');

        // Only re-validate when there is an error for that field
        if (form.formState?.errors?.[key] && !R.isNil(val)) {
          form.trigger(key);
        }
      });

      // check prefilled externalArea
      const externalArea = form.getValues().externalArea;

      if (externalArea) {
        form.setValue(
          'largeOutbuildsAreaWarning',
          largeOutbuildsAreaWarning(externalArea),
        );
      }
    } catch (ex) {
      console.error('MS search address', ex);
    } finally {
      setSearching(false);
    }
  };

  const handleAddressSelect = async (form, formValues) => {
    if (isMissingAddressFields(formValues)) {
      form.setValue('showFullAddress', true);
      FULL_ADDRESS_OTHER_QUESTIONS.forEach(({ key }) => {
        form.trigger(key);
      });
    } else if (
      policyType.name === INSURANCE_TYPES_NAMES.HOME ||
      policyType.name === INSURANCE_TYPES_NAMES.LANDLORD
    ) {
      await searchAddress(form, formValues);
    }
  };

  const handleNext = async (page, data, form, pageNum, next) => {
    onNextPage(page, data, form, pageNum, next);
    if (page.type === 'rego' && R.isNil(data.vehicleFound)) {
      return searchRego(form, data);
    } else if (page.type === 'house' && R.isNil(data.addressValid)) {
      return searchAddress(form, data);
    }
    next();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      // Clear address when filling the the full address details
      case 'flatNo':
      case 'streetNo':
      case 'streetName':
      case 'suburb':
      case 'city':
      case 'postCode':
        formRef.current.setValue('address', '');
        formRef.current.trigger('address');
        break;
      // Update vehicleInfo to update options and
      // clears model/subModel depending on the field changed
      case 'make':
        setVehicleInfo(R.assoc('make', value));
        formRef.current.setValue('model', '');
        formRef.current.setValue('subModel', '');
        break;
      case 'model':
        setVehicleInfo(R.assoc('model', value));
        formRef.current.setValue('subModel', '');
        break;
      case 'subModel':
        setVehicleInfo(R.assoc('subModel', value));
        break;
      case 'isPropertyOwner':
        formRef.current.setValue('propertyOccupants', '');
      case 'amount': {
        if (policyType?.name === INSURANCE_TYPES_NAMES.CONTENT) {
          const formValues = formRef.current.getValues();
          // Clear sub type if contents amount doesn't meet medium amount
          if (
            formValues?.policySubTypeId === 'Medium' &&
            value < CONTENTS_MEDIUM_AMOUNT
          ) {
            formRef.current.setValue('policySubTypeId', '');
          }
        }
        break;
      }
      case 'externalArea': {
        formRef.current.setValue(
          'largeOutbuildsAreaWarning',
          largeOutbuildsAreaWarning(value),
        );
        break;
      }
      case 'premium': {
        formRef.current.setValue(
          'highPremium',
          highPremiumWarning(
            value,
            formRef.current.getValues('frequency') || 'Yearly',
          ),
        );
        break;
      }

      case 'frequency': {
        formRef.current.setValue(
          'highPremium',
          highPremiumWarning(formRef.current.getValues('premium') || 0, value),
        );
        break;
      }
    }
  };

  return {
    formRef,
    formOptions: options,
    searching,
    onInputSearch: handleInputSearch,
    onFormChange: handleChange,
    onNext: handleNext,
    onAddressSelect: handleAddressSelect,
    setVehicleInfo,
  };
};

export default useMarketScanForm;
