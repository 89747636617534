import moment from 'moment';
import {
  IPolicy,
  IPolicyRenewal,
  IPolicyScan,
  PolicyArrangementType,
  POLICY_SCAN_STATUS,
} from 'types';
import { FREQUENCY, YEARLY_MULTIPLIERS } from 'constants/options';
import {
  FIELD_KEYS_FOR_COMPLETION_BY_NAME,
  INSURANCE_QUESTIONS_BY_TYPE,
  INSURANCE_QUESTIONS_COMMON,
  INSURANCE_QUESTIONS_DATES,
  INSURANCE_QUESTIONS_OPTIONAL_BY_NAME,
  INSURANCE_QUESTIONS_PAYMENTS,
  INSURANCE_TYPES_NAMES,
} from 'constants/insurance';
import {
  POLICY_CHECK_MAX_LENGTH,
  POLICY_CHECK_MAX_COUNT,
  POLICY_CHECK_INTERVAL,
  PolicyStatus,
} from 'constants/policy';
import { parseAmount } from './number';
import { getFieldByPolicyType } from './form';
import { getAdviserOrgId } from './user';
import { compareListWithPriorities, sortByCreatedAtDesc } from './sorting';
import { VehicleCoverTypes } from 'services';
import { arrayOrderByDateKey, findDayDifference } from './date';

export const ATTENTION_REQUIRED_DAYS = 42;

export const getUpcomingRenewal = (reenwalHistory: IPolicyRenewal[] = []) => {
  return R.find(R.complement(R.prop('completed')), reenwalHistory);
};

export const checkShouldRenewPolicy = (renewalDate: string) => {
  // Renewal date is falsy when policy is credit card or is processing
  if (!renewalDate) {
    return false;
  }

  const now = moment();
  const date = moment(renewalDate);
  const diffDays = date.diff(now, 'days');
  return diffDays <= ATTENTION_REQUIRED_DAYS;
};

export const checkPolicyExpired = (renewalDate: string) => {
  // Renewal date is falsy when policy is credit card or is processing
  if (!renewalDate) {
    return false;
  }
  const now = moment();
  const date = moment(renewalDate);
  return now.isAfter(date);
};

export const checkShouldShowRenewalStatus = (
  renewalDate: string,
  renewalAdded: boolean,
) => !renewalAdded && checkShouldRenewPolicy(renewalDate);

export const calculatePriceIncrease = (
  lastPremium: number,
  upcomingPremium: number,
  lastFrequency: string,
  upcomingFrequency: string,
) => {
  const lastYearlyPremium = lastPremium * YEARLY_MULTIPLIERS[lastFrequency];
  const upcomingYearlyPremium =
    upcomingPremium * YEARLY_MULTIPLIERS[upcomingFrequency];
  const result =
    ((upcomingYearlyPremium - lastYearlyPremium) / lastYearlyPremium) * 100;
  return Number.isNaN(result) ? 0 : result;
};

export const calcPercentagePremiumIncrease = (
  lastPremium: number,
  upcomingPremium: number,
  lastFrequency: string,
  upcomingFrequency: string,
) => {
  if (lastPremium === 0) {
    return {
      value: 'N/A',
    };
  }

  const percent = calculatePriceIncrease(
    lastPremium,
    upcomingPremium,
    lastFrequency,
    upcomingFrequency,
  );
  const percentString = percent.toFixed(1);
  const isIncrease = percent > 0;
  return {
    isIncrease,
    value: `${isIncrease ? '+' : ''}${percentString}%`,
  };
};

export const getUniquePolicyTypes = (policies) => {
  const sortMethod = compareListWithPriorities(
    Object.values(INSURANCE_TYPES_NAMES),
  );
  return policies
    .reduce((acc, { policyType, insured = [] }) => {
      // Get policy types from Life policy
      if (policyType === INSURANCE_TYPES_NAMES.LIFE) {
        insured.forEach(({ additionalCovers = [] }) => {
          additionalCovers.forEach((cover) => {
            if (cover.insuredAmount > 0 && !acc.includes(cover.policyType)) {
              acc.push(cover.policyType);
            }
          });
        });
      } else if (!acc.includes(policyType)) {
        acc.push(policyType);
      }
      return acc;
    }, [])
    .sort(sortMethod);
};

export const getMissingFields = (data, keys) =>
  keys.reduce((acc, k) => {
    if (R.isNil(data[k])) {
      acc.push(k);
    }
    return acc;
  }, []);

export const getRequiredInsuranceQuestionsByType = (policyType) =>
  R.propOr([], policyType, INSURANCE_QUESTIONS_BY_TYPE)
    .filter(R.prop('required'))
    .map(R.prop('fieldKey'));

export const checkIsMissingInformation = (policy) =>
  policy.status === PolicyStatus.INCOMPLETE;

export const getMissingKeysForCompletion = (policy) => {
  const { policyType } = policy;
  const fieldKeysCommon =
    FIELD_KEYS_FOR_COMPLETION_BY_NAME[policyType] ||
    FIELD_KEYS_FOR_COMPLETION_BY_NAME.default;
  const fieldKeysByType = getRequiredInsuranceQuestionsByType(policyType);

  const fieldKeys = [...fieldKeysCommon, ...fieldKeysByType];

  return getMissingFields(policy, fieldKeys);
};

export const checkCompletePolicy = (policy) =>
  policy.status === PolicyStatus.ACTIVE;

export const getPolicyProgressCount = (uploadedAt) => {
  const createdAtMs = new Date(uploadedAt).getTime();
  const now = new Date().getTime();
  const diff = now - createdAtMs;
  if (diff > POLICY_CHECK_MAX_LENGTH) {
    return POLICY_CHECK_MAX_COUNT;
  }
  return Math.floor(diff / POLICY_CHECK_INTERVAL);
};

export const isPolicyProgressError = (uploadedAt) => {
  const count = getPolicyProgressCount(uploadedAt);
  return count >= POLICY_CHECK_MAX_COUNT;
};

export const isPolicyInReview = (policy) =>
  policy && policy.status === PolicyStatus.IN_REVIEW;

export const getPolicyStatus = (policy: IPolicy): PolicyStatus => {
  const { policyRenewalAdded, renewalDate, status, uploadedAt } = policy;

  // Manually check if MARKET_SCAN_DRAFT policy is incomplete
  if (status === PolicyStatus.MARKET_SCAN_DRAFT) {
    const missingKeys = getMissingKeysForCompletion(policy);
    if (missingKeys.length > 0) {
      return PolicyStatus.INCOMPLETE;
    }
  }

  if (
    status === PolicyStatus.INCOMPLETE ||
    status === PolicyStatus.ACTIVE ||
    status === PolicyStatus.MARKET_SCAN_DRAFT
  ) {
    if (checkShouldShowRenewalStatus(renewalDate, policyRenewalAdded)) {
      return PolicyStatus.EXPIRED;
    }

    if (policyRenewalAdded) {
      return PolicyStatus.RENEWING;
    }
  }

  if (status === PolicyStatus.PROCESSING && isPolicyProgressError(uploadedAt)) {
    return PolicyStatus.IN_REVIEW;
  }

  return status;
};

export const getClosestRenewalPolicy = (policies: IPolicy[]) => {
  let renewal;
  let policy;

  policies.forEach((p) => {
    const { frequency, renewalDate } = p;
    if (frequency !== FREQUENCY.ONE_OFF && renewalDate) {
      const diff = findDayDifference(undefined, renewalDate);
      if (diff < renewal || !renewal) {
        renewal = diff;
        policy = p;
      }
    }
  });

  return policy;
};

export const getClosestRenewalDateDays = (policies: IPolicy[]) => {
  const policy = getClosestRenewalPolicy(policies);
  const diff = findDayDifference(undefined, policy?.renewalDate);
  return Math.floor(diff);
};

const POLICIES_TO_IGNORE = [
  INSURANCE_TYPES_NAMES.INCOME,
  INSURANCE_TYPES_NAMES.MORTGAGE,
  INSURANCE_TYPES_NAMES.REDUNDANCY,
  INSURANCE_TYPES_NAMES.RENT_PROTECTION,
  INSURANCE_TYPES_NAMES.PET,
  INSURANCE_TYPES_NAMES.HEALTH,
  INSURANCE_TYPES_NAMES.TRAVEL,
];

export const calculateTotalInsured = (policies) => {
  let totalPremiums = 0;
  const totalPerType = {};
  const totalPerEmployer = {};
  const totalInsured = policies.reduce((acc, policy) => {
    const {
      amount: rawAmount,
      policyType,
      insured,
      premium,
      employerId,
    } = policy;
    const amount = parseAmount(rawAmount);
    totalPremiums += parseAmount(premium);
    if (POLICIES_TO_IGNORE.includes(policyType)) {
      return acc;
    } else if (
      policyType === INSURANCE_TYPES_NAMES.LIFE &&
      insured &&
      insured.length > 0
    ) {
      // Accumulate life insurance additional covers
      insured.forEach(({ additionalCovers = [] }) =>
        additionalCovers.forEach(
          ({ policyType: lifePolicyType, insuredAmount: rawInsuredAmount }) => {
            if (POLICIES_TO_IGNORE.includes(lifePolicyType)) {
              return;
            }
            const insuredAmount = parseAmount(rawInsuredAmount);
            if (insuredAmount > 0) {
              const value = employerId
                ? totalPerEmployer[employerId]
                : totalPerType[lifePolicyType];
              const totalValue = value ? value + insuredAmount : insuredAmount;
              if (employerId) {
                totalPerEmployer[employerId] = totalValue;
              } else {
                totalPerType[lifePolicyType] = totalValue;
              }
              acc += insuredAmount;
            }
          },
        ),
      );
    } else if (
      policyType === INSURANCE_TYPES_NAMES.HOME_CONTENTS ||
      policyType === INSURANCE_TYPES_NAMES.LANDLORD
    ) {
      const {
        amountContents: rawAmountContents,
        amountHome: rawAmountHome,
      } = policy;
      const amountContents = parseAmount(rawAmountContents);
      const amountHome = parseAmount(rawAmountHome);
      if (amountContents) {
        const value = employerId
          ? totalPerEmployer[employerId]
          : totalPerType[INSURANCE_TYPES_NAMES.CONTENT];
        const totalValue = value ? value + amountContents : amountContents;
        if (employerId) {
          totalPerEmployer[employerId] = totalValue;
        } else {
          totalPerType[INSURANCE_TYPES_NAMES.CONTENT] = totalValue;
        }
        acc += amountContents;
      }
      if (amountHome) {
        const value = employerId
          ? totalPerEmployer[employerId]
          : totalPerType[INSURANCE_TYPES_NAMES.HOME];
        const totalValue = value ? value + amountHome : amountHome;
        if (employerId) {
          totalPerEmployer[employerId] = totalValue;
        } else {
          totalPerType[INSURANCE_TYPES_NAMES.HOME] = totalValue;
        }
        acc += amountHome;
      }
    } else if (amount > 0) {
      const value = totalPerType[policyType];
      const totalValue = value ? value + amount : amount;
      if (employerId) {
        totalPerEmployer[employerId] = totalValue;
      } else {
        totalPerType[policyType] = totalValue;
      }
      return (acc += amount);
    }
    return acc;
  }, 0);
  return {
    totalInsured,
    totalPerType,
    totalPremiums,
    totalPerEmployer,
  };
};

export const createFinishSetupPolicyTemplate = (policy, user, employer) => {
  return {
    ...policy,
    name: `${user.firstName} ${user.lastName}`,
    employerId: employer.id,
    employerName: employer.name,
    processed: true,
    status: PolicyStatus.FINISH_SETUP,
  };
};

export const createEmployerPolicyTemplate = ({
  firstName,
  lastName,
  isLife,
  ...policy
}) => {
  const {
    policyTypeId,
    policyType,
    amount,
    payoutFrequency,
    payoutWaitPeriod,
  } = policy;

  const name = `${firstName} ${lastName}`;

  const newPolicy: IPolicy = {
    ...policy,
    processed: true,
    effective: true,
    status: PolicyStatus.ACTIVE,
  };

  const field = getFieldByPolicyType(policy.policyType);

  if (isLife) {
    newPolicy.insured = [
      {
        order: 0,
        name,
        additionalCovers: [
          {
            policyTypeId,
            policyType,
            payoutFrequency,
            payoutWaitPeriod,
            insuredAmount: amount,
          },
        ],
      },
    ];
  } else if (field.fieldKey === 'name') {
    newPolicy.name = name;
  }

  return newPolicy;
};

export const findDiscountedEmployerPolicy = (currentPolicies, policyToAdd) =>
  currentPolicies.find(
    (p) =>
      p.policyTypeId === policyToAdd.policyTypeId &&
      (p.status === PolicyStatus.FINISH_SETUP ||
        p.policyProviderId === policyToAdd.policyProviderId),
  );

export const findFreeEmployerPolicy = (
  currentPolicies,
  policyToAdd,
  employerId,
) =>
  currentPolicies.find(
    (p) =>
      (policyToAdd.isLife
        ? p.policyType === INSURANCE_TYPES_NAMES.LIFE
        : p.policyTypeId === policyToAdd.policyTypeId) &&
      p.policyProviderId === policyToAdd.policyProviderId &&
      R.propEq('employerId', employerId, p),
  );

export const hasEmployerPolicy = (policies, employer) => {
  if (employer) {
    return !!employer.policies.find(
      (p) =>
        p.arrangementType === PolicyArrangementType.FREE &&
        !!findFreeEmployerPolicy(policies, p, employer.id),
    );
  }
  return false;
};

export const getEmployerPolicies = (policies, employer) => {
  if (employer) {
    return policies.filter((p) =>
      employer.policies.find((ep) => {
        return (
          ep.arrangementType === PolicyArrangementType.FREE &&
          ep.policyTypeId === p.policyTypeId &&
          ep.policyProviderId === p.policyProviderId &&
          R.propEq('employerId', employer.id, p)
        );
      }),
    );
  }
  return [];
};

export const getLifeCovers = (insured = []) =>
  R.uniq(
    insured.reduce((acc, { additionalCovers = [] }) => {
      const coverNames = additionalCovers.map(R.prop('policyType'));
      return [...acc, ...coverNames];
    }, []),
  ).sort();

export const getPolicyTypeName = ({
  employerId,
  employerName,
  policyType,
  insured = [],
  data,
}: IPolicy) => {
  if (data === 'bundle') {
    return 'Policy documents uploaded';
  }

  if (employerId) {
    return R.includes(policyType, [
      INSURANCE_TYPES_NAMES.LIFE,
      INSURANCE_TYPES_NAMES.INCOME,
    ])
      ? employerName
      : policyType;
  }

  if (policyType === INSURANCE_TYPES_NAMES.LIFE && insured.length > 0) {
    // Get unique cover names
    const names = getLifeCovers(insured);

    const name = R.includes(INSURANCE_TYPES_NAMES.LIFE, names)
      ? INSURANCE_TYPES_NAMES.LIFE
      : names[0] || INSURANCE_TYPES_NAMES.LIFE;

    const otherTypes = R.reject(R.equals(name), names).join(', ');

    return `${name}${otherTypes ? `, ${otherTypes}` : ''}`;
  }

  return policyType;
};

export const getOtherLifeCovers = (insured = []) => {
  const names = getLifeCovers(insured);
  return R.reject(R.equals(INSURANCE_TYPES_NAMES.LIFE), names);
};

export const shouldDeletePolicy = (policy, user, isOwner) => {
  return (
    (policy?.adviserOrgId
      ? policy.adviserOrgId === getAdviserOrgId(user)
      : isOwner) &&
    policy?.status !== PolicyStatus.IN_REVIEW &&
    policy?.status !== PolicyStatus.PROCESSING &&
    !policy?.quashedBrokerCover
  );
};

export const hasPolicyWithPremium = (policies: IPolicy[]) =>
  !!policies.find((p) => p.premium);

export const getPolicyFromRenewal = (
  policies: IPolicy[],
  renewal: IPolicyRenewal,
) => {
  return policies.find(R.propEq('id', renewal.sk));
};

export const getPolicyComparison = (renewals, policy) => {
  if (renewals.length > 0) {
    const [renewal] = sortByCreatedAtDesc(renewals);

    return {
      prevPremium: R.pathOr(
        policy.premium,
        ['oldPolicyData', 'premium'],
        renewal,
      ),
      prevFrequency: R.pathOr(
        policy.frequency,
        ['oldPolicyData', 'frequency'],
        renewal,
      ),
      nextPremium: renewal.newPolicyData.premium,
      nextFrequency: renewal.newPolicyData.frequency,
      nextRenewalDate: renewal.newPolicyData.renewalDate,
      renewal,
    };
  }
  return null;
};

/**
 * Takes a list of policy scans and an ID then searches for that ID in the policyScans. If it finds one that
 * is pending, viewed or ready a particular string will be returned to be displayed on the 'delete' policy popup.
 * @param policyScans List of the users policy scans
 * @param id ID of the policy that is to be deleted
 * @returns Two strings of the action header and its content
 */
export const getActionHeaderAndContent = (
  policyScans: IPolicyScan[],
  id: string,
) => {
  const matchingPendingPolicyScan =
    policyScans &&
    policyScans.find(
      (item) =>
        item.sk === id &&
        [POLICY_SCAN_STATUS.PENDING, POLICY_SCAN_STATUS.IN_REVIEW].includes(
          item.status,
        ),
    );

  const matchingPolicyScan =
    policyScans &&
    policyScans.find(
      (item) =>
        item.sk === id &&
        [POLICY_SCAN_STATUS.VIEWED, POLICY_SCAN_STATUS.READY].includes(
          item.status,
        ),
    );

  let header = matchingPendingPolicyScan
    ? 'You have a pending Market Scan for this policy, are you sure you want to delete this?'
    : 'Delete policy';
  let content = matchingPendingPolicyScan
    ? 'If you delete this policy, your pending Market Scan will also be deleted.'
    : 'Are you sure you would like to delete this policy?';
  header = matchingPolicyScan
    ? 'You have a Market Scan result for this policy, are you sure you want to delete this?'
    : header;
  content = matchingPolicyScan
    ? 'If you delete this policy, your Market Scan results will also be deleted'
    : content;

  return { header, content };
};

export const getVehicleTypeByPolicyType = (policyType) => {
  if (policyType === INSURANCE_TYPES_NAMES.VEHICLE) {
    return VehicleCoverTypes.CAR;
  }

  if (policyType === INSURANCE_TYPES_NAMES.MOTORCYCLE) {
    return VehicleCoverTypes.MOTORCYCLE;
  }

  return null;
};

export const getPolicyQuestionsByPolicyType = (
  policyTypeName: string,
  quashedBrokerCover: boolean,
) => {
  const questionsByType = INSURANCE_QUESTIONS_BY_TYPE[policyTypeName] || [];

  const optionalQuestions =
    INSURANCE_QUESTIONS_OPTIONAL_BY_NAME[policyTypeName] ||
    INSURANCE_QUESTIONS_OPTIONAL_BY_NAME.default;

  const questions = [
    {
      fieldKey: 'status',
      placeholder: 'Status',
      type: 'dropdown',
    },
    {
      fieldKey: 'policyTypeId',
      placeholder: 'policyType',
      required: true,
      type: 'dropdown',
      validationKey: 'policyTypeId',
      valueKey: 'policyTypeId',
    },
    {
      fieldKey: 'policySubTypeId',
      placeholder: 'policySubType',
      required: false,
      type: 'dropdown',
      valueKey: 'policySubTypeId',
    },
    ...questionsByType,
    ...INSURANCE_QUESTIONS_COMMON.map((q) => {
      if (q.fieldKey === 'policyProvider') {
        return {
          ...q,
          fieldKey: 'policyProviderName',
        };
      }
      return q;
    }),
    ...INSURANCE_QUESTIONS_DATES,
    ...INSURANCE_QUESTIONS_PAYMENTS,
    ...optionalQuestions,
  ];

  if (quashedBrokerCover) {
    questions.push({
      key: 'brokerPolicyYearlyPremium',
      fieldKey: 'brokerPolicyYearlyPremium',
      placeholder:
        'Yearly Premium Amount (only applicable to Quashed broker policy)',
      label: 'Yearly Premium',
      before: 'Yearly Premium',
      required: false,
      inputType: 'number',
      type: 'number',
      prefix: '$',
    });
  }

  return questions;
};

export const getRecentPolicyScanBySk = (policyScans: IPolicy[], sk: string) => {
  const filtered = policyScans.filter(R.propEq('sk', sk));

  if (filtered.length > 0) {
    return arrayOrderByDateKey('createdAt', filtered, true)[0];
  }

  return null;
};

// Get non MS policies
export const getNonMSPolicies = (policies: IPolicy[]) =>
  policies.filter((p: IPolicy) => {
    return (
      !p.hide &&
      p.status !== PolicyStatus.READY_SCAN &&
      p.status !== PolicyStatus.PENDING_SCAN &&
      p.status !== PolicyStatus.MARKET_SCAN_HOLDER
    );
  });
