import React from 'react';
import styles from '../styles/ProviderLogos.module.scss';
import ImageCarousel from 'components/image-carousel/ImageCarousel';

const ProviderImgs = [
  'https://app.quashed.co.nz/provider-logos/SouthernCross.png',
  'https://app.quashed.co.nz/provider-logos/AIA.png',
  'https://app.quashed.co.nz/provider-logos/Cigna.png',
  'https://app.quashed.co.nz/provider-logos/Vero.png',
  'https://app.quashed.co.nz/provider-logos/Ando.png',
  'https://app.quashed.co.nz/provider-logos/NZI.png',
  'https://app.quashed.co.nz/provider-logos/Star.png',
  'https://app.quashed.co.nz/provider-logos/Chubb.png',
  'https://app.quashed.co.nz/provider-logos/PartnerLife.png',
];

const ProviderLogos = ({ className }) => {
  return (
    <div className={styles.container}>
      <ImageCarousel className={styles.images} images={ProviderImgs} />
    </div>
  );
};

export default ProviderLogos;
