import { Features } from 'constants/features';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { FREQUENCY } from 'constants/options';
import { BROKER_POLICY_TYPES } from 'constants/policy';
import {
  convertFrequency,
  hasFeature,
  isSafari,
  prettifyNumber,
} from 'helpers';
import {
  IPolicyProvider,
  IPolicyScan,
  IQuote,
  POLICY_SCAN_STATUS,
} from 'types';

/**
 * @param policyScan IPolicyScan
 * @returns {
 *   mainBenefits: [
 *    {
 *      name: benefit.name,
 *      order: benefit.order,
 *      [quote.policyProviderId]: benefit.value,
 *    },
 *    ...
 *   ],
 *   hiddenBenefits: [
 *    {
 *      name: benefit.name,
 *      order: benefit.order,
 *      [quote.policyProviderId]: benefit.value,
 *    },
 *    ...
 *   ]
 * }
 */
export const getQuoteBenefitsMap = ({ quotes = [] }: IPolicyScan, isMobile) => {
  const main = [];
  const hidden = [];

  const map = quotes.reduce((acc, q) => {
    q.benefitsHighlights.forEach((b) => {
      acc = R.assocPath([b.name, q.policyProviderId], b.value, acc);
      acc = R.assocPath([b.name, 'benefitTooltip'], b.benefitTooltip, acc);
      acc = R.assocPath([b.name, 'componentType'], b.componentType, acc);

      // Assoc an order attribute for sorting
      if (!R.path([b.name, 'order'], acc) && !R.isNil(b.order)) {
        acc = R.assocPath([b.name, 'order'], parseFloat(b.order), acc);
      }

      if (b.tooltip) {
        acc = R.assocPath(
          [b.name, 'tooltips', q.policyProviderId],
          b.tooltip,
          acc,
        );
      }
    });

    return acc;
  }, {});

  Object.keys(map).forEach((k) => {
    const benefit = R.assoc('name', k, map[k]);
    if (R.isNil(benefit?.order)) {
      hidden.push(benefit);
    } else {
      main.push(benefit);
    }
  });

  const mainBenefits = R.sortBy(R.prop('order'), main);

  return isMobile && isSafari()
    ? mainBenefits
    : [...mainBenefits, ...R.sortBy(R.prop('name'), hidden)];
};

export const getDefaultDropdownState = ({ quotes = [] }: IPolicyScan) => {
  return quotes.reduce((acc, q) => {
    const { premium, frequency } =
      q.premiums.find(R.propEq('frequency', FREQUENCY.YEARLY)) || q.premiums[0];
    acc[q.policyProviderId] = {
      premium,
      frequency,
      value: `${q.policyProviderId}_${premium}_${frequency}`,
    };
    return acc;
  }, {});
};

export const sortQuotes = (
  { quotes = [] }: IPolicyScan,
  policyProviders: IPolicyProvider[],
) => {
  const normalQuotes = quotes.filter((q) => !q.brokerCover);
  const currentProviderIdx = normalQuotes.findIndex(R.prop('currentProvider'));
  let sorted = [];
  if (currentProviderIdx < 0) {
    sorted = R.sortBy(R.prop('policyProviderName'), normalQuotes);
  } else {
    const filtered = normalQuotes.filter((q) => !q.currentProvider);
    sorted = R.prepend(normalQuotes[currentProviderIdx], filtered);
  }

  return sorted.map((q) => {
    const provider = policyProviders.find(R.propEq('id', q.policyProviderId));
    return R.assoc('provider', provider, q);
  });
};

export const getSavings = (premiums = [], frequency) => {
  const selectedPremium =
    premiums.find(R.propEq('frequency', frequency)) || '0';
  const selectedYearly = convertFrequency(
    parseFloat(selectedPremium.premium),
    frequency,
    FREQUENCY.YEARLY,
  );

  if (Number.isNaN(selectedYearly)) {
    return selectedYearly;
  }

  const yearlyPremium = premiums.find(R.propEq('frequency', FREQUENCY.YEARLY));
  if (yearlyPremium) {
    const val = selectedYearly - parseFloat(yearlyPremium.premium);

    // No negative savings
    if (val <= 0) {
      return 0;
    }

    return prettifyNumber(val);
  }
  return 0;
};

export const getSavingsMessage = (premiums = []) => {
  const hasYearly = !!premiums.find(R.propEq('frequency', FREQUENCY.YEARLY));
  return hasYearly ? 'Same price as yearly.' : '';
};

export const getBuyHandler = (policyScan, onBuy, quote, frequency) => {
  return () => {
    const selectedPremium =
      quote.premiums.find(R.propEq('frequency', frequency)) ||
      // Choose first premium when selecting not available option
      quote.premiums[0];
    if (policyScan.isBrokerFirst) {
      onBuy({
        id: policyScan.id,
        sk: policyScan.sk,
        createdBy: policyScan.createdBy,
        status: POLICY_SCAN_STATUS.VIEWED,
        isBrokerFirst: policyScan.isBrokerFirst,
        policyType: policyScan.policyType,
        selectedProvider: {
          selectedDate: new Date().toISOString(),
          policyProviderId: quote.policyProviderId,
          policyProviderName: quote.policyProviderName,
          recommended: quote.recommended,
          excess: quote.excess,
          amount: quote.amount,
          ...selectedPremium,
        },
      });
    } else {
      onBuy({
        ...policyScan,
        status: POLICY_SCAN_STATUS.VIEWED,
        selectedProvider: {
          selectedDate: new Date().toISOString(),
          policyProviderId: quote.policyProviderId,
          policyProviderName: quote.policyProviderName,
          recommended: quote.recommended,
          excess: quote.excess,
          amount: quote.amount,
          ...selectedPremium,
        },
      });
    }
  };
};

export const getDropdownValue = (currentFrequency, premiums) => {
  const hasOption = !!premiums.find(R.propEq('frequency', currentFrequency));
  return hasOption ? currentFrequency : null;
};

export const getDefaultFrequency = (
  currentProvider,
  { isBrokerFirst, selectedProvider }: IPolicyScan,
) => {
  if (selectedProvider) {
    return selectedProvider.frequency;
  } else if (currentProvider) {
    return R.pathOr('', ['premiums', 0, 'frequency'], currentProvider);
  }
  return isBrokerFirst ? FREQUENCY.YEARLY : FREQUENCY.MONTHLY;
};

export const getSummaryPremium = (premiums = []) => {
  const yearlyPremium = premiums.find(R.propEq('frequency', FREQUENCY.YEARLY));
  if (yearlyPremium) {
    return yearlyPremium.premium;
  }
  const nextPremium = premiums[0];
  return nextPremium
    ? prettifyNumber(
        convertFrequency(
          parseFloat(nextPremium.premium),
          nextPremium.frequency,
          FREQUENCY.YEARLY,
        ),
      )
    : 0;
};

export const findCurrentProvider = R.find(R.prop('currentProvider'));

export const getSelectedFrequency = (
  selectedProvider,
  quotes,
  currentFrequency,
) => {
  if (selectedProvider) {
    if (selectedProvider.frequency) {
      return selectedProvider.frequency;
    }
    // Select the first premium frequency if selected has none
    const provider = quotes.find(
      R.propEq('policyProviderId', selectedProvider.policyProviderId),
    );
    const result = R.pathOr(
      FREQUENCY.YEARLY,
      ['premiums', 0, 'frequency'],
      provider,
    );
    return result;
  }
  return currentFrequency;
};

export const getProviderNavigationText = (
  { disabled, max, policyProviderName }: IQuote,
  policyScan: IPolicyScan,
  isAddedToCart?: boolean,
  isNotRecommended?: boolean,
) => {
  if (
    policyScan.brokerPolicyProviderName === policyProviderName &&
    policyScan.policyType !== INSURANCE_TYPES_NAMES.LIFE
  ) {
    return 'Purchased';
  }

  if (isNotRecommended) {
    return 'Selected';
  }

  if (policyScan?.isBrokerFirst) {
    return `Add${isAddedToCart ? 'ed' : ''} to Cart`;
  }

  if (disabled) {
    return `$${prettifyNumber(max)} max limit`;
  }

  switch (policyProviderName) {
    case 'Protecta':
      return 'Purchase now';
    case 'Cove':
      return 'Get 1st month free';
    default:
      return `Get started`;
  }
};

export const getPolicyWordingLink = (
  providerName: string,
  policyType: string,
) => {
  const providerNameStr = providerName.toLowerCase().split(' ').join('-');
  const policyTypeStr = policyType.toLowerCase();
  return `https://quashed.co.nz/documents/policy-wordings/${providerNameStr}-${policyTypeStr}.pdf`;
};

export const calcBrokerAmounts = (quotes, providerId) => {
  const quote = quotes.find(R.propEq('policyProviderId', providerId));
  let year = 0;
  let month = 0;

  if (quote) {
    const yearlyPremium = quote.premiums.find(
      R.propEq('frequency', FREQUENCY.YEARLY),
    );
    year = yearlyPremium?.premium;
    // Monthly formula is:
    // (Annual premium + 6.5% of annual premium) / 11 months
    month = (year * 1.065) / 11;
  }

  return { year, month };
};

export const isMarketValueProvider = (policyProviderName: string) =>
  policyProviderName === 'Vero';

export const isMarketValue = (
  policyProviderName: string,
  { isBrokerFirst, policyType }: IPolicyScan,
) => {
  return (
    isBrokerFirst &&
    policyType === INSURANCE_TYPES_NAMES.VEHICLE &&
    isMarketValueProvider(policyProviderName)
  );
};

export const shouldShowSeeMore = (config, policyScan) => {
  return (
    hasFeature(config, Features.MS_RESULTS_SEE_MORE) &&
    !policyScan.isBrokerFirst &&
    !policyScan.brokerFormCreatedAt &&
    BROKER_POLICY_TYPES.includes(policyScan.policyType)
  );
};
