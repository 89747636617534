import { getHeaderOptions, getHttpService, IIdSkPayload } from './http-service';

const httpClient = getHttpService();

export interface ICreateCreditCardPolicy {
  creditCardId: string;
  creditCardProviderId: string;
}

export const getCreditCardPolicies = (userId) => {
  return httpClient.post('/creditcard/user', { userId }, {
    headers: getHeaderOptions()
  });
};

export const createCreditCardPolicy = (payload: ICreateCreditCardPolicy) => {
  return httpClient.post('/creditcard', payload, {
    headers: getHeaderOptions()
  });
};

export const deleteCreditCardPolicy = (data: IIdSkPayload) => {
  return httpClient.delete('/creditcard', {
    headers: getHeaderOptions(),
    data
  });
};