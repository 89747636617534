import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUser } from 'actions';
import { Back } from 'components/quashed';
import { INSURANCE_TYPES_NAMES } from 'constants/insurance';
import { IUser } from 'types';
import CalculatorMap from 'components/policy-wiki/calculators';
import styles from './styles/MarketScanCalculator.module.scss';

const MarketScanCalculator = (formRef, policyTypeName, user) => {
  const dispatch = useDispatch();
  const [calcOpen, setCalcOpen] = useState(false);
  const Calculator = CalculatorMap[policyTypeName];

  const openCalc = () => setCalcOpen(true);

  const handleCalcBack = (total: number) => {
    formRef.current.setValue('amount', total);
    setCalcOpen(false);
  };

  const updateUser = (u: IUser) => dispatch(UpdateUser(u));

  const handleRenderPage = (content) => {
    return (
      <>
        {content}
        {calcOpen && (
          <>
            <div className={styles.calcContainer}>
              <Calculator
                user={user}
                updateUser={updateUser}
                data={undefined}
                backToForm={handleCalcBack}
              />
            </div>
            <div className={styles.btnContainer}>
              <Back
                className={styles.back}
                onClick={() => setCalcOpen(false)}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const handleRenderLabel = (question, values) => {
    const label = question.label;
    if (question.calculator) {
      if (values.policyType === INSURANCE_TYPES_NAMES.HOME) {
        return (
          <span className={styles.label}>
            {label}
            <a
              className={styles.calcLabel}
              href="https://sumsure.corelogic.co.nz/#/products/8/profiles/219"
              target="_blank"
            >
              Use calculator
            </a>
          </span>
        );
      } else if (values.policyType === INSURANCE_TYPES_NAMES.CONTENT) {
        return (
          <span className={styles.label}>
            {label}
            <div className={styles.calcLabel} onClick={openCalc}>
              Use calculator
            </div>
          </span>
        );
      }
    }
    return label;
  };

  return {
    isOpen: calcOpen,
    onRenderLabel: handleRenderLabel,
    onRenderPage: handleRenderPage,
  };
};

export default MarketScanCalculator;
