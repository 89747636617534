import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { GetLoggedInUser, UpdateUser, InitApp } from 'actions';
import { IAppState, IUser } from 'types';
import history from './helpers/history';
import { useAuth0 } from './react-auth0-spa';
import useIdle from 'hooks/useIdle';
import usePolicyNotification from 'hooks/usePolicyNotification';
import useProcessUrlQuery from 'hooks/useProcessUrlQuery';
import { ProcessReferral } from 'actions/referral-actions';
import RootModalContainer from 'containers/root-modals';
import LayoutContainer from 'containers/layout';
import useUserLoaded from 'hooks/useUserLoaded';
import useBundleNotification from 'hooks/useBundleNotification';
import useRenewalNotification from 'hooks/useRenewalNotification';
import AppBanner from 'components/app-banner';
import Loading from 'components/loading';
import { growthBook, growthBookInit } from './services/growthbook-service';
import {
  DefaultRoutes,
  OnboardingRoute,
  PremiumOnboardingIncompleteRoutes,
} from 'routes';
import {
  isOnboardingUser,
  isPremiumOnboardingIncomplete,
} from './helpers/user';
import { windowScrollTop } from './helpers/window';

interface IDispatchProps {
  initApp(): void;
  getLoggedInUser(): void;
  processReferral(id: string): void;
  updateUser(user: IUser): void;
}

const App = ({
  config,
  adviser,
  user,
  getLoggedInUser,
  processReferral,
  updateUser,
  initApp,
}: IAppState & IDispatchProps) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  useUserLoaded();
  useIdle(R.path(['settings', 'sessionTimeout'], config), logout);
  useBundleNotification();
  usePolicyNotification();
  useRenewalNotification();
  useProcessUrlQuery({ processReferral, updateUser, user });

  useEffect(() => {
    if (isAuthenticated) {
      getLoggedInUser();
      initApp();
    } else {
      loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user.loaded) {
      growthBookInit(user);
    }
  }, [user.loaded]);

  useEffect(() => {
    // Scroll to top every route change except when we press back
    const unlisten = history.listen((_, action) => {
      if (action !== 'POP') {
        windowScrollTop();
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  if (!user.loaded || !adviser.loaded) {
    return <Loading full={true} />;
  }

  if (isOnboardingUser(user, adviser)) {
    return <OnboardingRoute />;
  }

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <Router history={history}>
        <RootModalContainer />
        <AppBanner />
        {isAuthenticated && (
          <LayoutContainer>
            {isPremiumOnboardingIncomplete(user) ? (
              <PremiumOnboardingIncompleteRoutes />
            ) : (
              <DefaultRoutes />
            )}
          </LayoutContainer>
        )}
      </Router>
    </GrowthBookProvider>
  );
};

const mapStateToProps = (state: IAppState) => ({
  ...state,
});

const mapDispatchToProps = {
  getLoggedInUser: GetLoggedInUser,
  processReferral: ProcessReferral,
  updateUser: UpdateUser,
  initApp: InitApp,
};

export default connect<IAppState, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(App);
