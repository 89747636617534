import { getHeaderOptions, getHttpService } from "./http-service";
import { ICode, ICodeSent } from "types";
import { standardFormatWithTime, sortDate } from "helpers";
const httpClient = getHttpService();

export const getAllCodes = () => {
  return httpClient.get<ICode[]>(`/code`, {
    headers: getHeaderOptions(),
  });
};

export const getAllCodeSents = () => {
  return httpClient
    .get<ICodeSent[]>(`/code/code-sent`, {
      headers: getHeaderOptions(),
    })
    .then((res) => {
      res.data.forEach((d) => {
        d.createdAt = standardFormatWithTime(d.createdAt);
      });
      res.data = res.data.sort(sortDate("createdAt"));
      return res;
    });
};
