import React, { useMemo, useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import styles from './accordionList.scss';

const getPanels = (props) => (item, index, list) => {
  const {
    onTitleClick,
    titleClickToExpand = true,
    titleComponent: Title,
    titleClassName,
    titleIcon,
    contentComponent: Content,
    contentClassName,
    componentProps,
    keyProp = 'id',
  } = props;
  const isLast = index === list.length - 1;

  const titleClass = classnames(
    styles.title,
    titleClassName,
    isLast && styles.noBoarder,
  );

  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onTitleClick({ item, index });
  };

  return {
    key: item[keyProp],
    title: {
      content: (
        <Title
          index={index}
          item={item}
          toggleExpand={handleToggle}
          {...componentProps}
        />
      ),
      className: titleClass,
      icon: titleIcon,
      onClick: titleClickToExpand ? handleToggle : undefined,
    },
    content: {
      content: <Content index={index} item={item} {...componentProps} />,
      className: classnames(styles.content, contentClassName),
    },
  };
};

interface IProps {
  id?: string;
  className?: string;
  items: any[];
  titleComponent: any;
  contentComponent: any;
  titleIcon: any;
  titleClassName?: string;
  contentClassName?: string;
  componentProps?: object;
  keyProp?: string;
  titleClickToExpand?: boolean;
}

const AccordionList = ({ className, items, ...otherProps }: IProps) => {
  const [activeIndex, setActiveIndex] = useState([]);

  const onTitleClick = (titleProps) => {
    const idx = titleProps.index;
    const updated = R.includes(idx, activeIndex)
      ? R.without([idx], activeIndex)
      : [...activeIndex, idx];
    setActiveIndex(updated);
  };

  const panels = useMemo(
    () => items.map(getPanels({ ...otherProps, onTitleClick })),
    [items, otherProps, onTitleClick],
  );

  return (
    <Accordion
      id={otherProps.id}
      activeIndex={activeIndex}
      className={classnames(styles.accordion, className)}
      panels={panels}
      exclusive={false}
      fluid={true}
    />
  );
};

export default AccordionList;
