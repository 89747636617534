import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MarketScanList from 'components/market-scan-list';
import Title from 'components/title';
import styles from '../styles/MarketScanResults.module.scss';
import { logPageView, PAGE_VIEW_EVENTS } from 'helpers';
import { Button } from 'components/quashed';
import { CHECKOUT_ROUTE } from 'constants/routes';
import { selectUnsoldAdviserScans } from 'selectors/adviserConnectSelectors';
import CartSvg from '-!svg-react-loader!icons/shared/cart.svg';
import history from 'helpers/history';
import ReviewReminderModal from '../components/ReviewReminderModal';

const shouldShowCheckout = (adviserConnect) => {
  return adviserConnect.addedToCart && !R.isEmpty(adviserConnect.addedToCart);
};

const MarketScanResults = ({ adviserConnect, referenceData }) => {
  const items = useSelector(selectUnsoldAdviserScans);
  useEffect(() => {
    logPageView(PAGE_VIEW_EVENTS.Premium, { page: 6 });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const showCheckoutBtn = shouldShowCheckout(adviserConnect);

  const goToCheckout = () => {
    setShowModal(false);
    history.push(CHECKOUT_ROUTE);
  };

  const handleCheckout = () => {
    const hasInReview =
      items.filter(
        (ps) => ps.selectedProvider && !ps.selectedProvider.recommended,
      ).length > 0;

    if (hasInReview) {
      setShowModal(true);
    } else {
      goToCheckout();
    }
  };

  return (
    <div className={styles.container}>
      <Title
        title="Quotes are ready to view"
        subtitle="Scan your results, select your provider and purchase your policies."
      />
      <MarketScanList
        titleClassName={styles.title}
        items={items}
        addedToCart={adviserConnect.addedToCart}
        policyProviders={referenceData.policyProviders}
        isPremium={true}
      />
      {showCheckoutBtn && (
        <div className={styles.btnContainer}>
          <Button className={styles.checkout} onClick={handleCheckout}>
            <CartSvg />
            Go to checkout
          </Button>
        </div>
      )}
      <ReviewReminderModal open={showModal} onClose={goToCheckout} />
    </div>
  );
};

export default MarketScanResults;
