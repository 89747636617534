export const HOME_ROUTE = '/';
export const ADD_POLICY_ROUTE = '/new';
export const EDIT_POLICY_ROUTE = '/edit';
export const ADMIN_ROUTE = '/admin';
export const CHECKOUT_ROUTE = '/checkout';
export const CONSENTS_ROUTE = '/invites';
export const CONSENTS_BULK_ROUTE = '/invites/bulk';
export const CONSENTS_INVITE_ROUTE = '/invites/client';
export const CLIENTS_ROUTE = '/clients';
export const CLIENTS_NEW_ROUTE = '/clients/add';
export const CLIENTS_DETAILS_ROUTE = '/clients/details/:id';
export const CLIENTS_DETAILS_POLICY_ROUTE = '/clients/details/:id/:policyId?';
export const ADD_CLIENT_POLICY_ROUTE = '/clients/policy/new';
export const EDIT_CLIENT_POLICY_ROUTE = '/clients/policy/edit';
export const OPERATIONS_ROUTE = '/operations';
export const OPERATIONS_ROUTE_POLICY_SCAN = '/operations/policyScan/:id/:sk?';
export const OPERATIONS_ROUTE_USER_DETAILS = '/operations/user/:id/:sk';
export const OPERATIONS_VIEW_DOC = '/operations/viewdoc';
export const OPERATIONS_ADVISER_CONNECT_DETAILS =
  '/operations/adviserConnect/:id/:sk';
export const OPERATIONS_POLICY_RENEWAL_DETAILS =
  '/operations/policyrenewal/:id/:sk/:familyId';
export const EXPLORE_ROUTE = '/explore';
export const EXPLORE_ADVISER = '/explore/adviser';
export const EXPLORE_CC_ROUTE = '/explore/credit-cards';
export const EXPLORE_COVERAGE_ROUTE = '/explore/coverage'; // Insurance checkup
export const EXPLORE_DISCOVER_POLICY_SELECT_ROUTE = '/explore/policy';
export const EXPLORE_DISCOVER_POLICY_ROUTE = '/explore/policy/discover';
export const INVITATION_ROUTE = '/invitation';
export const MARKET_SCAN_ROUTE = '/market-scan';
export const MARKET_SCAN_NEW_DEFAULT_ROUTE = '/market-scan/new';
export const MARKET_SCAN_NEW_ROUTE = '/market-scan/new/:policyTypeName?';
export const MARKET_SCAN_BROKER_DEFAULT_ROUTE = '/market-scan/broker';
export const MARKET_SCAN_BROKER_ROUTE = '/market-scan/broker/:policyTypeName?';
export const MARKET_SCAN_PURCHASE_ROUTE = '/market-scan/purchase';
export const MARKET_SCAN_ONBOARDING_ROUTE = '/market-scan/onboarding';
export const MARKET_SCAN_RESULTS_ROUTE = '/market-scan-results';
export const POLICIES_ROUTE = '/policies';
export const POLICY_DETAILS_ROUTE = '/policy/:id';
export const PREMIUM_ROUTE = '/premium';
export const PREMIUM_QUOTE_ROUTE = '/premium/quote';
export const PROFILE_ROUTE = '/profile';
export const SHARE_ROUTE = '/share';
export const WORKPLACE_ROUTE = '/profile/work';
export const RENEWAL_ROUTE = '/policy/:id/renewal';
export const WINDCAVE_CALLBACK_BASE_ROUTE = '/windcaveCallback';
export const WINDCAVE_CALLBACK = `${WINDCAVE_CALLBACK_BASE_ROUTE}/:policyProvider/:status`;
export const NOTIFICATIONS_ROUTE = '/notification';
export const BROKER_FORM_ROUTE = '/broker-form/:policyType';

export const EXPLORE_COVERAGE_RESULTS_ROUTE = {
  pathname: EXPLORE_COVERAGE_ROUTE,
  state: {
    showResults: true,
  },
};

export const PROFILE_ADVISER_TAB_ROUTE = {
  pathname: PROFILE_ROUTE,
  state: {
    tab: 'My Adviser',
  },
};

export const GET_ADVICE_CALENDAR_OPENED_ROUTE = {
  pathname: EXPLORE_ADVISER,
  state: {
    openCalendly: true,
  },
};

export const GET_ADVICE_END_PAGE_ROUTE = {
  pathname: EXPLORE_ADVISER,
  state: {
    endPage: true,
  },
};
