import { IAppState } from '../../types';
import { AppActionTypes } from '../actions';
import referenceDataReducer from './referencedata-reducer';
import userReducer from './user-reducer';
import policiesReducer from './policies-reducer';
import adminReducer from './admin-reducer';
import invitationReducer from './invitation-reducer';
import configReducer from './config-reducer';
import adviserReduce from './adviser-reducer';
import operationsReducer from './operations-reducer';
import referralReducer from './referral-reducer';
import paymentReducer from './payment-reducer';
import creditCardReducer from './credit-card-reducer';
import policyRenewalReducer from './policy-renewal-reducer';
import notificationReducer from './notification-reducer';
import layoutReducer from './layout-reducer';

const appReducer = (state: IAppState, action: AppActionTypes): IAppState => {
  return {
    user: userReducer(state.user, action),
    referenceData: referenceDataReducer(state.referenceData, action),
    policies: policiesReducer(state.policies, action),
    admin: adminReducer(state.admin, action),
    invitation: invitationReducer(state.invitation, action),
    config: configReducer(state.config, action),
    adviser: adviserReduce(state.adviser, action),
    operations: operationsReducer(state.operations, action),
    referral: referralReducer(state.referral, action),
    payment: paymentReducer(state.payment, action),
    creditCard: creditCardReducer(state.creditCard, action),
    policyRenewal: policyRenewalReducer(state.policyRenewal, action),
    notification: notificationReducer(state.notification, action),
    layout: layoutReducer(state.layout, action),
  };
};

export default appReducer;
