import React, { useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { UseFormReturn } from 'react-hook-form';
import DOMPurify from 'dompurify';
import Button from 'components/button';
import ImageCarousel from 'components/image-carousel';
import { getQuestionsToDisplay, trackMSDisclosureClick } from 'helpers';
import { IFormDefinitionPage, IFormQuestion } from 'types';
import FormEngineController from '../components/FormEngineController';
import {
  getChangeHandler,
  getClickHandler,
  getFieldComponent,
  onMountFns,
} from '../utils';
import { getValidator } from '../validation';
import styles from './styles/MarketScanSummary.module.scss';
import StarImg from 'images/providers/star.png';
import NZIImg from 'images/providers/nzi.png';
import ChubbImg from 'images/providers/chubb.png';
import AndoImg from 'images/providers/ando.png';
import VeroImg from 'images/providers/vero.png';
import AaImg from 'images/providers/aa.png';
import AmiImg from 'images/providers/ami.png';
import CoveImg from 'images/providers/cove.png';
import StateImg from 'images/providers/state.png';
import TowerImg from 'images/providers/tower.png';
import ProtectaImg from 'images/providers/protecta.png';
import Back from 'components/back';

const BrokerImages = [StarImg, NZIImg, ChubbImg, AndoImg, VeroImg];
const DirectImages = [AaImg, AmiImg, CoveImg, StateImg, TowerImg, ProtectaImg];

interface IProps {
  fieldClassName?: string;
  fieldContainerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  options?: any;
  form?: UseFormReturn;
  loading?: boolean;
  formValues?: any;
  questionProps?: any;
  page: IFormDefinitionPage;
  getOptions?(question: IFormQuestion, formValues: any): any;
  onBack(): void;
}

interface ISectionProps extends Omit<IProps, 'page'> {
  section?: any;
}

const Section = ({
  fieldClassName,
  fieldContainerClassName,
  inputClassName,
  labelClassName,
  options,
  getOptions,
  form,
  formValues,
  section,
  questionProps,
  ...props
}: ISectionProps) => {
  const [showSection, setShowSection] = useState(true);
  const { control, formState } = form;
  const { questions, title, collapsible } = section;
  const errorKeys = Object.keys(formState.errors);

  // All questions in this component should be "basic"
  const questionsToDisplay = useMemo(() => {
    // Hide address field when user entered address manually
    const toOmit = formValues.showFullAddress ? ['address'] : [];
    return getQuestionsToDisplay(
      questions,
      formValues,
      toOmit,
      questionProps,
    ).map(R.assoc('basic', true));
  }, [questions, questionProps, formValues]);

  const toggleSection = () => setShowSection(R.not);

  return (
    <>
      {collapsible && title && (
        <div className={styles.sectionTitle} onClick={toggleSection}>
          {title} <ChevronDown />
        </div>
      )}
      {showSection && (
        <div className={styles.section}>
          {questionsToDisplay?.map((question) => {
            const { key, type, required, className: qClassName } = question;
            const FieldComponent = getFieldComponent(type);
            const validate = getValidator(type, question, formValues);
            const error =
              formState.errors[key]?.message || formState.errors[key];
            const opt =
              (getOptions && getOptions(question, formValues)) ||
              options?.[key] ||
              question.options;

            return (
              <FormEngineController
                key={key}
                control={control}
                name={key}
                rules={{ required, validate }}
                render={({ field }) => (
                  <FieldComponent
                    {...question}
                    {...field}
                    options={opt}
                    onChange={getChangeHandler(field, form, props, question)}
                    onClick={getClickHandler(question, form, props)}
                    error={error}
                    scrollIntoView={errorKeys[0] === key}
                    containerClassName={classnames(
                      styles.fieldContainer,
                      fieldContainerClassName,
                    )}
                    className={classnames(
                      styles.field,
                      qClassName,
                      fieldClassName,
                    )}
                    inputClassName={classnames(styles.input, inputClassName)}
                    labelClassName={classnames(
                      styles.fieldLabel,
                      labelClassName,
                    )}
                  />
                )}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

const RightSection = ({
  form,
  formValues,
  loading,
  options,
  page,
  onBack,
}: IProps) => {
  const { control, formState } = form;
  const questions = page.rightSection?.questions || [];
  const buttonText = page.rightSection?.buttonText || 'Scan';
  const disclaimer = page.rightSection?.disclaimer;
  const notes = page.rightSection?.notes;
  const images = page.rightSection?.images;

  const handleDisclosureClick = (e) => {
    // Only track when clicked element is a link
    if (e.target.parentElement?.tagName === 'A') {
      trackMSDisclosureClick();
    }
  };

  return (
    <div className={styles.right}>
      <ImageCarousel
        className={styles.images}
        images={images === 'direct' ? DirectImages : BrokerImages}
      />
      <div className={styles.rightQuestions}>
        {questions.map((question) => {
          const {
            key,
            type,
            required,
            subtitle,
            title,
            className: qClassName,
          } = question;
          const FieldComponent = getFieldComponent(type);
          const validate = getValidator(type, question, formValues);
          const error = formState.errors[key]?.message || formState.errors[key];

          return (
            <div className={styles.rightField} key={key}>
              {title && <h4>{title}</h4>}
              {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
              <FormEngineController
                key={key}
                control={control}
                name={key}
                rules={{ required, validate }}
                render={({ field }) => (
                  <FieldComponent
                    {...question}
                    {...field}
                    options={options?.[key] || question.options}
                    error={error}
                    className={classnames(styles.rightFieldInput, qClassName)}
                    placeholder={question.placeholder}
                  />
                )}
              />
            </div>
          );
        })}
        {notes && (
          <div
            className={styles.notes}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(notes, {
                ADD_ATTR: ['target', 'rel'],
              }),
            }}
          />
        )}
      </div>
      <div className={styles.buttonContainer}>
        <h4>Ready to go</h4>
        {disclaimer && (
          <div
            className={styles.disclaimer}
            onClick={handleDisclosureClick}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(disclaimer, {
                ADD_ATTR: ['target', 'rel'],
              }),
            }}
          />
        )}
        <Button
          className={styles.submit}
          type="submit"
          disabled={loading}
          loading={loading}
          text={buttonText}
        />
        <Back className={styles.back} handleBack={onBack} />
      </div>
    </div>
  );
};

const MarketScanSummary = ({ page, loading, ...props }: IProps) => {
  useEffect(() => {
    // Update form value if there is an onMountFn for a question
    page.questions.forEach((question) => {
      if (question.onMountFn) {
        const fn = onMountFns[question.onMountFn];
        const val = fn(props.formValues);
        if (!R.isNil(val)) {
          props.form.setValue(question.key, val);
        }
      }
    });
  }, []);

  return (
    <div className={classnames('market-scan-summary', styles.container)}>
      <div className={styles.left}>
        {page.sections.map((section, idx) => (
          <Section key={`section_${idx}`} {...props} section={section} />
        ))}
      </div>
      <RightSection page={page} loading={loading} {...props} />
    </div>
  );
};

export default MarketScanSummary;
